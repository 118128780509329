import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BuildPolarity, BuildPolarityPair, PolarityConfig, PolarityMap, PolarityState, getPolarityMap, initialPolarityState } from "./polarity";
import { IConnectorType } from "../../../../../store/overlay/wizard/wizard";
import { IFiberMapData } from "./fiber-map";

const setAppliedPolarityAction = (state: PolarityState, action: PayloadAction<BuildPolarity>) => {
    const { payload } = action;
    const { id, description, from, to, polarityMap } = payload;
    const { configs } = state;
    if (configs) {
        const buildConfig = configs.find((c) => c.from!.key === from!.key && c.to!.key === to!.key);
        if (buildConfig) {
            buildConfig.description = description;
            buildConfig.id = id;
            buildConfig.from = from;
            buildConfig.to = to;
            buildConfig.polarityMap = polarityMap;
        } else {
            configs.push(payload);
        }
    } else {
        state.configs = [payload];
    }
};

const setBuildPolarityAction = (state: PolarityState, action: PayloadAction<BuildPolarity[]>) => {
    state.configs = action.payload;
};

const setFilteredPolarityListAction = (
    state: PolarityState,
    action: PayloadAction<{
        feederConnectorTypes: IConnectorType[];
        distributionConnectorTypes: IConnectorType[];
        polarityConfigs: PolarityConfig[];
    }>
) => {
    const { feederConnectorTypes, distributionConnectorTypes, polarityConfigs } = action.payload;
    const buildMaps = polarityConfigs.flatMap((c) => c.polarityMaps).filter((m): m is PolarityMap => !!m)
    const polarityMaps = getPolarityMap(feederConnectorTypes, distributionConnectorTypes, buildMaps);
    if (polarityMaps.length) {
        const polarityMap = polarityMaps[0];
        const config: PolarityConfig = {
            from: polarityMap.sourceConnectors[0],
            to: polarityMap.destinationConnectors[0],
            polarityMaps: polarityMaps,
        };
        state.selectedConfig = config;
    } else {
        state.selectedConfig = undefined;
    }
};

const setUserPolaritiesAction = (state: PolarityState, action: PayloadAction<BuildPolarityPair[]>) => {
    state.buildConfigList = action.payload;
};

const setFiberMapsAction = (state: PolarityState, action: PayloadAction<IFiberMapData[] | undefined>) => {
    state.fiberMaps = action.payload;
};

const polarityConfigurationSlice = createSlice({
    name: "polarityConfiguration",
    initialState: initialPolarityState,
    reducers: {
        setAppliedPolarity: setAppliedPolarityAction,
        setBuildPolarity: setBuildPolarityAction,
        setUserPolarities: setUserPolaritiesAction,
        setFilteredPolarityList: setFilteredPolarityListAction,
        setFiberMaps: setFiberMapsAction,
    },
});

export const {
    setAppliedPolarity,
    setBuildPolarity,
    setUserPolarities,
    setFilteredPolarityList,
    setFiberMaps,
} = polarityConfigurationSlice.actions;

export const PolarityConfigurationReducer = polarityConfigurationSlice.reducer;

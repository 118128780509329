import React from "react";
import { IGenericDialogProps } from "../../../models/ui/dialog/generic-dialog";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../locales/keys";
import { ContainedButton, TextButton, Dialog, IconButton, Typography } from "@corning-ctcm/silica-react";
import { Close } from "@mui/icons-material";

import "../../../css/ui/dialog/generic-dialog.scss";

export const GenericDialog: React.FC<IGenericDialogProps> = ({
    id,
    display,
    title,
    message,
    onClose,
    confirmText,
    cancelText,
    confirmDisabled,
    onConfirm,
    onCancel,
    critical,
    closable,
}) => {
    const { t } = useTranslation();
    const titleClassName = critical ? "generic-dialog-title critical" : "generic-dialog-title";
    const cancelClassName = critical ? "generic-dialog-cancel-button critical" : "generic-dialog-cancel-button";
    const confirmClassName = "generic-dialog-confirm-button";
    const confirmPalette = critical ? "error" : "primary";

    return display ? (
        <Dialog
            id={id}
            className="generic-dialog-container"
            open={display}
            onClose={onClose}
            preventOutsideDismiss={true}
        >
            {title ? (
                <div className="generic-dialog-header">
                    <div id={`${id}-title`} className={titleClassName}>
                        {title}
                    </div>
                    {closable ? (
                        <IconButton
                            id={`${id}-close-icon`}
                            onClick={onClose}
                            placement="bottom"
                            palette="primary"
                            mode="main"
                            title={t(LocalizationKeys.Close)}
                            icon={<Close />}
                        />
                    ) : null}
                </div>
            ) : null}
            <div className="generic-dialog-body">
                <Typography variant="body1">
                    {message}
                </Typography>
            </div>
            <div className="generic-dialog-footer">
                <div className="generic-dialog-button-container">
                    {closable && (
                        <TextButton
                            id={`${id}-cancel-button`}
                            className={cancelClassName}
                            onClick={onCancel ?? onClose}
                        >
                            {cancelText ?? t(LocalizationKeys.Cancel)}
                        </TextButton>
                    )}
                    <ContainedButton
                        id={`${id}-confirm-button`}
                        disabled={confirmDisabled}
                        palette={confirmPalette}
                        className={confirmClassName}
                        onClick={!confirmDisabled ? onConfirm : undefined}
                    >
                        {confirmText ?? t(LocalizationKeys.Confirm)}
                    </ContainedButton>
                </div>
            </div>
        </Dialog>
    ) : null;
};

import React from "react";
import { IFiberMapRowProps } from "../../../models/off-screen/fiber-map-table";
import { TableCell, TableRow } from "@mui/material";

export const FiberMapRow: React.FC<IFiberMapRowProps> = ({ sourceFiber, fiberIndex, destinationFiber }) => {
    return (
        <TableRow>
            <TableCell>{sourceFiber.location}</TableCell>
            <TableCell>{sourceFiber.connectorIndex}</TableCell>
            <TableCell>{sourceFiber.connectorType}</TableCell>
            <TableCell>{sourceFiber.row}</TableCell>
            <TableCell>{sourceFiber.pinIndex}</TableCell>
            <TableCell>{fiberIndex}</TableCell>
            <TableCell>{destinationFiber.location}</TableCell>
            <TableCell>{destinationFiber.connectorIndex}</TableCell>
            <TableCell>{destinationFiber.connectorType}</TableCell>
            <TableCell>{destinationFiber.row}</TableCell>
            <TableCell>{destinationFiber.pinIndex}</TableCell>
        </TableRow>
    );
};

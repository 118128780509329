import { useConnectorReport } from "./connector-report.hooks";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { NavigationBar } from "../../../ui/navigation-bar/navigation-bar";
import { Checkbox, IconButton, TextButton, Typography } from "@corning-ctcm/silica-react";
import { ConnectorRow } from "./connector-row/connector-row";
import { ColorDialog } from "../../../ui/dialog/color-dialog/color-dialog";
import { CollapsibleDialog } from "../../../ui/dialog/collapsible-dialog/collapsible-dialog";
import { LinearProgressIndeterminate } from "@corning-ctcm/silica-react";
import { Palette } from "@mui/icons-material";

import "../../../../css/overlay/reports/connector-report.scss";

export const ConnectorReport = () => {
    const {
        footnote,
        manageLabelScheme,
        rows,
        navigationBarProps,
        masterCheckboxProps,
        tableHeaderProps,
        colorDialogProps,
        labelColorDialogProps,
        collapsibleDialogProps,
        loading,
        triggerManagementProps,
        labelSchemeButtonProps
    } = useConnectorReport();
    return (
        <CollapsibleDialog {...collapsibleDialogProps}>
            <ColorDialog {...colorDialogProps} />
            <ColorDialog {...labelColorDialogProps} />
            {loading ? <LinearProgressIndeterminate palette="primary" /> : null}
            <div className="table-container">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" className="selection-column">
                                <div id="master-checkbox">
                                    <Checkbox palette="primary" {...masterCheckboxProps} />
                                </div>
                            </TableCell>
                            <TableCell align="right" className="connector-column">
                                {tableHeaderProps.connectorTitle}
                            </TableCell>
                            <TableCell align="right" className="group-column">
                                {tableHeaderProps.groupTitle}
                            </TableCell>
                            <TableCell align="left" className="connector-color-column">
                                <div className="table-header-container">
                                    {tableHeaderProps.connectorColorTitle}
                                    <IconButton
                                        {...tableHeaderProps.connectorButtonProps}
                                        icon={<Palette />}
                                    />
                                </div>
                            </TableCell>
                            <TableCell align="left" className="label-column">
                                {tableHeaderProps.labelTitle}
                            </TableCell>
                            <TableCell align="left">
                                <div className="table-header-container">
                                    {tableHeaderProps.labelColorTitle}
                                    <IconButton
                                        {...tableHeaderProps.labelButtonProps}
                                        icon={<Palette />}
                                    />
                                </div>
                            </TableCell>
                            <TableCell align="right" className="b-length-column">
                                {tableHeaderProps.bTitle}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <ConnectorRow key={row.data.id} {...row} />
                        ))}
                    </TableBody>
                </Table>
            </div>
            <NavigationBar {...navigationBarProps} />
            <div className="connector-report-footer">
                <Typography variant="helperText">
                    {footnote}
                </Typography>
                <div>
                    {triggerManagementProps.display && 
                    <TextButton {...triggerManagementProps.buttonProps}>
                        {triggerManagementProps.buttonText}
                    </TextButton>}
                    <TextButton {...labelSchemeButtonProps}>
                        {manageLabelScheme}
                    </TextButton>
                </div>
            </div>
        </CollapsibleDialog>
    );
};

import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../locales/keys";
import { useRadioGroup, useRadioInput } from "../../../../ui/radio/radio.hooks";
import { setCustomBLength, setGroupBLength } from "../../../../../store/workspace/build/build.reducers";
import { wizardSelector } from "../../../../../store/overlay/wizard/wizard.selectors";
import { DestinationSetupContext } from "../../../../../store/overlay/wizard/setup/destination-setup/destination-setup.reducers";
import { SourceSetupContext } from "../../../../../store/overlay/wizard/setup/source-setup/source-setup.reducers";
import { getGroupBLength } from "../../../../../store/workspace/build/build.actions";
import { IBLengthProps } from "../../../../../models/overlay/wizard/setup/b-length-section/b-length-section";

export const useBLengthSection = ({ drop, disabled: bLengthDisabled, onChange }: IBLengthProps) => {
    const disabled = !!bLengthDisabled;
    const { side, position, groups, lengthB } = drop;
    const { display, currentStep } = useSelector(wizardSelector);
    const {
        state: {
            bLengthType: feederBLengthType,
            isBLengthValid: feederBLengthValid,
            sLength: feederStagger,
            isSLengthValid: feederSLengthValid,
            reverseStaggering: feederReverse,
        },
    } = useContext(SourceSetupContext);
    const {
        state: {
            bLengthType: distributionBLengthType,
            isBLengthValid: distributionBLengthValid,
            sLength: distributionStagger,
            isSLengthValid: distributionSLengthValid,
            reverseStaggering: distributionReverse,
            applyToAll,
        },
    } = useContext(DestinationSetupContext);
    let bLengthType = feederBLengthType;
    let stagger = feederStagger;
    let reverseStaggering = feederReverse;
    let isValidSetup = feederBLengthValid && feederSLengthValid;
    if (side === "distribution") {
        bLengthType = distributionBLengthType;
        stagger = distributionStagger;
        reverseStaggering = distributionReverse;
        isValidSetup = distributionBLengthValid && distributionSLengthValid;
    }
    const applyToAllDst = display && currentStep === 2 && applyToAll;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { onRadioGroupChange: onCustomBLengthRadioChange, ...bLengthRadioGroup } = useRadioGroup(
        "bLength",
        bLengthType,
        disabled
    );
    const isCustom = bLengthRadioGroup.value === "custom";
    const uniformRadio = useRadioInput("uniform", t(LocalizationKeys.UniformLength), bLengthRadioGroup.value, disabled);
    const customRadio = useRadioInput("custom", t(LocalizationKeys.CustomLength), bLengthRadioGroup.value, disabled);
    const onRadioGroupChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onChange(e);
            onCustomBLengthRadioChange(e);
        },
        [onChange, onCustomBLengthRadioChange]
    );

    useEffect(() => {
        dispatch(setCustomBLength({ dropSide: side, dropPosition: position, customBLength: isCustom, applyToAllDst }));
    }, [side, position, dispatch, applyToAllDst, isCustom]);

    useEffect(() => {
        if (bLengthType === "uniform" && isValidSetup) {
            groups.forEach((g, groupIndex) => {
                const length = getGroupBLength(
                    lengthB.value,
                    stagger.value,
                    groups.length,
                    groupIndex,
                    reverseStaggering
                );
                if (g.lengthB?.value !== length) {
                    dispatch(
                        setGroupBLength({
                            dropSide: side,
                            dropPosition: position,
                            groupPosition: groupIndex,
                            length: { ...lengthB, value: length },
                            applyToAllDst,
                        })
                    );
                }
            });
        }
    }, [
        applyToAllDst,
        isValidSetup,
        bLengthType,
        dispatch,
        groups,
        lengthB,
        position,
        reverseStaggering,
        side,
        stagger.value,
    ]);

    return {
        bLengthRadioGroup: { ...bLengthRadioGroup, onRadioGroupChange },
        radioOptions: [uniformRadio, customRadio],
        isCustom,
    };
};

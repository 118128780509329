import { Drop, Feeder, SectionContext } from "../../../models/pixi/build/build";
import { DestinationContent } from "./setup/destination/destination-setup";
import { SourceContent } from "./setup/source/source-setup";
import { WizardErrorSnackbar } from "./wizard-error-snackbar/wizard-error-snackbar";
import { WizardFooter } from "./wizard-footer/wizard-footer";
import { WizardHeader } from "./wizard-header/wizard-header";
import { useWizard } from "./wizard.hooks";

import "../../../css/overlay/wizard/wizard.scss";

export const Wizard: React.FC = () => {
    const { display, currentStep, sourceState, destinationState } = useWizard();

    if (!display) return null;

    const isSource = currentStep === 1;
    const section = isSource ? Feeder : Drop;
    const content = isSource ? (
        <SourceContent initialState={sourceState} />
    ) : (
        <DestinationContent initialState={destinationState} />
    );

    return (
        <div className="card-container toggle-pointer-events">
            <div id="wizard" className="wizard">
                <SectionContext.Provider value={section}>
                    <WizardHeader />
                    {content}
                    <WizardFooter />
                </SectionContext.Provider>
                <WizardErrorSnackbar />
            </div>
        </div>
    );
};

import { Aqua, Yellow } from "../../../models/ui/dialog/color-dialog";
import { IConnectorGroupData, initialDestinationGroups, initialSourceGroups } from "./connector/connector";
import { IUnitOfMeasure, UoMInches } from "../../../models/overlay/header/units-of-measure";
export interface IDrop {
    id?: number;
    buildId?: number;
    position: number;
    lengthA: IUnitOfMeasure;
    lengthB: IUnitOfMeasure;
    customBLength: boolean;
    mesh: boolean;
    meshOffset?: IUnitOfMeasure;
    meshColor?: string;
    cablesTied?: boolean;
    shell?: string;
    fiberCountInUse?: number;
    groups: IConnectorGroupData[];
    side: DropSide;
    groupsCollapsed: boolean;
    connectorsCollapsed: boolean;
    reverseStaggering: boolean;
}

const DropSides = {
    feeder: "feeder",
    distribution: "distribution",
} as const;

export type DropSide = (typeof DropSides)[keyof typeof DropSides];

export interface DropInfo {
    position: number;
    side: string;
}

const dropLengthA: IUnitOfMeasure = { unit: UoMInches, value: 80 };
export const initialFeederLengthB: IUnitOfMeasure = { unit: UoMInches, value: 48 };
export const initialDistributionLengthB: IUnitOfMeasure = { unit: UoMInches, value: 172 };
export const initialMeshOffset: IUnitOfMeasure = { unit: UoMInches, value: 36 };
export const initialMeshColor = Yellow.name;
const initialFeederDrop: IDrop = {
    position: 0,
    lengthA: { unit: UoMInches, value: 46 },
    lengthB: initialFeederLengthB,
    groups: initialSourceGroups,
    mesh: false,
    side: "feeder",
    customBLength: false,
    shell: "soft",
    groupsCollapsed: false,
    connectorsCollapsed: false,
    reverseStaggering: false,
};
const initialDistributionDrop: IDrop = {
    position: 0,
    lengthA: dropLengthA,
    lengthB: initialDistributionLengthB,
    groups: [],
    customBLength: false,
    mesh: true,
    meshOffset: initialMeshOffset,
    meshColor: initialMeshColor,
    side: "distribution",
    shell: "soft",
    groupsCollapsed: false,
    connectorsCollapsed: false,
    reverseStaggering: false,
};

export const initialDrops: IDrop[] = [
    initialFeederDrop,
    { ...initialDistributionDrop, position: 0, groups: initialDestinationGroups(0) },
    { ...initialDistributionDrop, position: 1, groups: initialDestinationGroups(1) },
    { ...initialDistributionDrop, position: 2, groups: initialDestinationGroups(2) },
    { ...initialDistributionDrop, position: 3, groups: initialDestinationGroups(3) },
    { ...initialDistributionDrop, position: 4, groups: initialDestinationGroups(4) },
    { ...initialDistributionDrop, position: 5, groups: initialDestinationGroups(5) },
    {
        ...initialDistributionDrop,
        position: 6,
        lengthA: { unit: UoMInches, value: 12 },
        groups: initialDestinationGroups(6),
    },
];

export const getDefaultMeshColor = (fiberType: string) => {
    return fiberType === "ULTRA" ? Yellow : Aqua;
};

import { Typography } from "@corning-ctcm/silica-react";

import "../../../../css/overlay/reports/label-scheme/label.scss";

export const Label = (props: { label: string }) => {
    return (
        <div className="label-container">
            <div className="label">
                <Typography variant="body1">
                    {props.label}
                </Typography>
            </div>
        </div>
    );
};

import { useTranslation } from "react-i18next";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Divider } from "@mui/material";
import { ContainedButton, OutlinedButton } from "@corning-ctcm/silica-react";
import { Drawer } from "../../../ui/drawer/drawer";
import { LocalizationKeys } from "../../../../locales/keys";
import { Units } from "../../../../models/overlay/header/units-of-measure";
import CorningLogo from "../../../../resources/svgs/overlay/Corning_Logo_301Grey.svg";
import { DrawerPageTitle } from "../drawer-page-title";
import { useCableDetails } from "./cable-details.hooks";

import "../../../../css/overlay/project-drawer/cable-details.scss";

export const CableDetails = () => {
    const { pageTitle, catalogCode, open, onReturn, onClose, loadDisabled, onLoad, feederParams, dropParams, units } =
        useCableDetails();
    const { t } = useTranslation();
    return (
        <Drawer id="cable-details" className="cable-details" open={open} onClose={onClose} logo={CorningLogo}>
            <DrawerPageTitle title={pageTitle} catalogCode={catalogCode} />
            <div className="details-section">
                <div className="details-section-label">{`${t(LocalizationKeys.TrunkSide, {
                    trunk: t(LocalizationKeys.Feeder),
                })}`}</div>
                <Divider variant="middle" />
                <div className="details-content">
                    <div className="details-row">
                        <div className="details-column">
                            <div className="details-label">{t(LocalizationKeys.FiberCount)}</div>
                            <div className="details-value">{feederParams.fiberCount}</div>
                        </div>
                        <div className="details-column">
                            <div className="details-label">{t(LocalizationKeys.FlameRating)}</div>
                            <div className="details-value">{feederParams.flameRating}</div>
                        </div>
                        <div className="details-column">
                            <div className="details-label">{t(LocalizationKeys.ConnectorType)}</div>
                            <div className="details-value">{feederParams.connectorType}</div>
                        </div>
                    </div>
                    <div className="details-row">
                        <div className="details-column">
                            <div className="details-label">{t(LocalizationKeys.Groups)}</div>
                            <div className="details-value">{feederParams.bundles}</div>
                        </div>
                        <div className="details-column">
                            <div className="details-label">{t(LocalizationKeys.ConnectorsPerGroup)}</div>
                            <div className="details-value">{feederParams.connectorsPerBundle}</div>
                        </div>
                        <div className="details-column">
                            <div className="details-label">{t(LocalizationKeys.AllConnectors)}</div>
                            <div className="details-value">{feederParams.nbConnectors}</div>
                        </div>
                    </div>
                    <div className="details-row">
                        <div className="details-column">
                            <div className="details-label">
                                {t(LocalizationKeys.CableDetailsLengthA0, { units: units })}
                            </div>
                            <div className="details-value">{feederParams.lengthA}</div>
                        </div>
                        <div className="details-column">
                            <div className="details-label">
                                {t(LocalizationKeys.CableDetailsLengthB0, { units: units })}
                            </div>
                            <div className="details-value">{feederParams.lengthB}</div>
                        </div>
                        <div className="details-column">
                            <div className="details-label">
                                {t(LocalizationKeys.CableDetailsStaggerS0, { units: units })}
                            </div>
                            <div className="details-value">{feederParams.stagger}</div>
                        </div>
                    </div>
                    <div className="details-row">
                        <div className="details-column">
                            <div className="details-label">{t(LocalizationKeys.PullingGrip)}</div>
                            <div className="details-value">
                                {feederParams.pullingGrip ? t(LocalizationKeys.Yes) : t(LocalizationKeys.No)}
                            </div>
                        </div>
                        <div className="details-column">
                            <div className="details-label">
                                {t(LocalizationKeys.CableOuterDiameterWithUnits, { units: Units.UoMMillimeters })}
                            </div>
                            <div className="details-value">{feederParams.cableOuterDiameter.toFixed(1)}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="details-section">
                <div className="details-section-label">{t(LocalizationKeys.TetherAPSide)}</div>
                <Divider variant="middle" />
                <div className="details-content">
                    <div className="details-row">
                        <div className="details-column">
                            <div className="details-label">{t(LocalizationKeys.NbAPs)}</div>
                            <div className="details-value">{dropParams.nbAccessPoints}</div>
                        </div>
                        <div className="details-column">
                            <div className="details-label">{t(LocalizationKeys.ConnectorType)}</div>
                            <div className="details-value">{dropParams.connectorType}</div>
                        </div>
                        <div className="details-column">
                            <div className="details-label">{t(LocalizationKeys.Groups)}</div>
                            <div className="details-value">{dropParams.bundles}</div>
                        </div>
                    </div>
                    <div className="details-row">
                        <div className="details-column">
                            <div className="details-label">{t(LocalizationKeys.ConnectorsPerGroup)}</div>
                            <div className="details-value">{dropParams.connectorsPerBundle}</div>
                        </div>
                        <div className="details-column">
                            <div className="details-label">{t(LocalizationKeys.AllConnectors)}</div>
                            <div className="details-value">{dropParams.nbConnectors}</div>
                        </div>
                        <div className="details-column">
                            <div className="details-label">
                                {t(LocalizationKeys.CableDetailsLengthAX, { units: units })}
                            </div>
                            <div className="details-value">{dropParams.lengthA}</div>
                        </div>
                    </div>
                    <div className="details-row">
                        <div className="details-column">
                            <div className="details-label">
                                {t(LocalizationKeys.CableDetailsLengthBX, { units: units })}
                            </div>
                            <div className="details-value">{dropParams.lengthB}</div>
                        </div>
                        <div className="details-column">
                            <div className="details-label">
                                {t(LocalizationKeys.CableDetailsStaggerSX, { units: units })}
                            </div>
                            <div className="details-value">{dropParams.stagger}</div>
                        </div>
                        <div className="details-column">
                            <div className="details-label">{t(LocalizationKeys.PullingGrip)}</div>
                            <div className="details-value">
                                {dropParams.pullingGrip ? t(LocalizationKeys.Yes) : t(LocalizationKeys.No)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="details-buttons">
                <OutlinedButton
                    id="cable-details-back-button"
                    className="back-button"
                    onClick={onReturn}
                    startIcon={<ChevronLeftIcon />}
                >
                    {t(LocalizationKeys.Back)}
                </OutlinedButton>
                <ContainedButton
                    id="cable-details-load-button"
                    className="load-button"
                    onClick={onLoad}
                    disabled={loadDisabled}
                >
                    {t(LocalizationKeys.LoadCable)}
                </ContainedButton>
            </div>
        </Drawer>
    );
};

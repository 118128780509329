import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { boundariesOptionsSelector, showBoundariesSettingsSelector } from "../../../store/workspace/boundaries/boundaries.selectors";
import { LocalizationKeys } from "../../../locales/keys";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { showBoundariesSettings } from "../../../store/workspace/boundaries/boundaries.reducers";
import {
    Boundaries,
    DEFAULT_BOUNDARIES_DATA_KEYS,
    DEFAULT_BOUNDARIES_NAME,
    IBoundariesData,
    initialBoundaries,
} from "../../../store/workspace/boundaries/boundaries";
import { TypographyVariant, IDialogActions, AlertPalettes, TextFieldProps, DialogProps, ButtonProps, InlineTextProps } from "@corning-ctcm/silica-react";
import { IBoundariesRowProps } from "./boundaries-row";
import { useBoundaries } from "../../workspace/boundaries.hooks";
import { setNotification } from "../../../store/overlay/notification/notification.reducers";
import { toCapitalize } from "../../../models/overlay/header/units-of-measure";
import { t } from "i18next";
import { getCurrentISODate } from "../../../services/build.service";
import { IDialogHeaderProps } from "../../../models/ui/dialog/dialog-header";
import { DialogProps as MUIDialogProps } from "@mui/material";

const TEXTFIELD_LINE_LIMIT = 40;

export const useBoundariesSettings = () => {
    const { t } = useTranslation();
    const { addOrUpdateBoundaries, deleteBoundaries } = useBoundaries();
    const options = useSelector(boundariesOptionsSelector);
    const display = useSelector(showBoundariesSettingsSelector);
    const dispatch = useDispatch();

    const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
    const [isModifying, setIsModifying] = useState(false);
    const [currentData, setCurrentData] = useState<string | undefined>();
    const [currentName, setCurrentName] = useState<string | undefined>();
    const [selectedBoundaries, setSelectedBoundaries] = useState<Boundaries | undefined>(undefined);

    const resetSettings = useCallback(() => {
        setSelectedBoundaries(undefined);
        setCurrentData(undefined);
        setCurrentName(undefined);
        setIsModifying(false);
    }, []);

    const onCloseSettings = useCallback(() => {
        if (!isModifying) {
            dispatch(showBoundariesSettings(false));
        }
        resetSettings();
    }, [isModifying, dispatch, resetSettings]);

    const onApplyChanges = useCallback(async () => {
        if (selectedBoundaries && currentData && currentName) {
            try {
                const data = parseString<IBoundariesData>(currentData, DEFAULT_BOUNDARIES_DATA_KEYS);
                const boundaries = await addOrUpdateBoundaries({
                    ...selectedBoundaries,
                    data,
                    name: currentName,
                    lastModified: getCurrentISODate(),
                });
                setCurrentData(getJSONString(boundaries.data));
                setCurrentName(boundaries.name);
                setSelectedBoundaries(boundaries);
            } catch (ex) {
                const fileName = currentName;
                const error = (ex as Error).message;
                dispatch(
                    setNotification({
                        palette: AlertPalettes.error,
                        message: t(LocalizationKeys.ImportErrorMessage, { fileName, error }),
                    })
                );
            }
        }
    }, [selectedBoundaries, currentData, currentName, t, addOrUpdateBoundaries, dispatch]);

    const confirmText = selectedBoundaries?.id ? t(LocalizationKeys.ApplyChanges) : t(LocalizationKeys.Create);
    const actions: IDialogActions | undefined = isModifying
        ? {
              confirmText,
              cancelText: t(LocalizationKeys.Cancel),
              disableConfirm: !hasChanges(currentName, currentData, selectedBoundaries),
              onConfirmClick: onApplyChanges,
              onCancelClick: onCloseSettings,
          }
        : undefined;

    const dialogHeaders: IDialogHeaderProps = {
        title: t(LocalizationKeys.BoundariesSettings),
        closable: true,
        collapsible: false,
        onClose: onCloseSettings,
    };

    const dialogProps: MUIDialogProps = {
        open: display,
        className: "boundaries-settings",
        onClose: onCloseSettings,
    };

    const onEditBoundariesData = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentData(e.target.value);
    }, []);

    const editDataProps: TextFieldProps = {
        palette: "primary",
        value: currentData,
        onChange: onEditBoundariesData,
    };

    const onEditBoundariesClick = useCallback((boundaries: Boundaries) => {
        setCurrentName(boundaries.name);
        setCurrentData(getJSONString(boundaries.data));
        setSelectedBoundaries(boundaries);
        setIsModifying(true);
    }, []);

    const onDeleteBoundariesClick = useCallback((boundaries: Boundaries) => {
        setShowDeleteConfirmDialog(true);
        setSelectedBoundaries(boundaries);
        setCurrentName(boundaries.name);
    }, []);

    const rows: IBoundariesRowProps[] = options.map((b) => ({
        label: b.name,
        isDefault: b.isDefault,
        onEditClick: () => onEditBoundariesClick(b),
        onDeleteClick: () => onDeleteBoundariesClick(b),
    }));

    const onNameChangeApply = useCallback(() => {
        if (currentName && selectedBoundaries?.id) {
            onApplyChanges();
        }
    }, [currentName, selectedBoundaries?.id, onApplyChanges]);

    const onNameChange = useCallback((value: string) => {
        setCurrentName(value);
    }, []);

    const onNameChangeCancel = useCallback(
        (event?: React.MouseEvent<HTMLButtonElement>) => {
            if (event && selectedBoundaries?.name) {
                setCurrentName(selectedBoundaries.name);
            }
        },
        [selectedBoundaries?.name]
    );

    const inlineTextProps: InlineTextProps = {
        palette: "primary",
        value: currentName ?? initialBoundaries.name,
        onConfirm: onNameChangeApply,
        onCancel: onNameChangeCancel,
        onInputChange: onNameChange,
    };

    const editNameProps = {
        isEditable: currentName !== DEFAULT_BOUNDARIES_NAME,
        props: inlineTextProps,
    };

    const onFileLoad = useCallback(
        async (fileName: string, { target }: ProgressEvent<FileReader>) => {
            try {
                const value = target ? (target.result as string) : "";
                const data = parseString<IBoundariesData>(value, DEFAULT_BOUNDARIES_DATA_KEYS);
                const defaultName = currentName ?? initialBoundaries.name;
                const name = currentName === initialBoundaries.name ? fileName : defaultName;
                setCurrentData(getJSONString(data));
                setCurrentName(name);
            } catch (ex) {
                const error = (ex as Error).message;
                dispatch(
                    setNotification({
                        palette: AlertPalettes.error,
                        message: t(LocalizationKeys.ImportErrorMessage, { fileName, error }),
                    })
                );
            }
        },
        [currentName, t, dispatch]
    );

    const onImportChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];
                const reader = new FileReader();
                const fileName = file.name.split(".")[0];
                if (file.type !== "application/json") {
                    const error = t(LocalizationKeys.ImportFileTypeErrorMessage, { type: "JSON" });
                    dispatch(
                        setNotification({
                            palette: AlertPalettes.error,
                            message: t(LocalizationKeys.ImportErrorMessage, { fileName, error }),
                        })
                    );
                } else {
                    reader.readAsText(file, "UTF-8");
                    reader.onload = (event) => onFileLoad(fileName, event);
                }
            }
        },
        [t, dispatch, onFileLoad]
    );

    const onImportClick = useCallback((e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const target = e.target as HTMLInputElement;
        target.value = "";
    }, []);

    const importButton = {
        label: t(LocalizationKeys.Import),
        onClick: onImportClick,
        onChange: onImportChange,
    };

    const onCreateClick = useCallback(() => {
        setIsModifying(true);
        setSelectedBoundaries(initialBoundaries);
        setCurrentName(initialBoundaries.name);
    }, []);

    const buttonProps: ButtonProps = {
        palette: "primary",
        mode: "main",
        onClick: onCreateClick,
    }

    const createButton = {
        label: t(LocalizationKeys.BoundariesCreationLabel),
        props: buttonProps,
    };

    const onDeleteClose = useCallback(() => {
        setSelectedBoundaries(undefined);
        setShowDeleteConfirmDialog(false);
    }, []);

    const onDeleteConfirm = useCallback(async () => {
        if (selectedBoundaries?.id) {
            await deleteBoundaries(selectedBoundaries.id);
            onDeleteClose();
        }
    }, [selectedBoundaries?.id, onDeleteClose, deleteBoundaries]);

    const deleteConfirmDialog: DialogProps = {
        open: showDeleteConfirmDialog,
        modal: true,
        className: "delete-boundaries-confirmation",
        title: t(LocalizationKeys.BoundariesDeleteTitle),
        message: t(LocalizationKeys.BoundariesDeleteMessage, { name: currentName }),
        actions: {
            confirmText: t(LocalizationKeys.Delete),
            cancelText: t(LocalizationKeys.Cancel),
            actionGuidance: {
                button: "confirm",
                severity: "critical",
            },
            onConfirmClick: onDeleteConfirm,
            onCancelClick: onDeleteClose,
        },
        onClose: onDeleteClose,
    };



    let subtitleClassName = "subtitle-container";
    let subtitleLabel = t(LocalizationKeys.BoundariesDefaultSubtitle);
    let subtitleVariant: TypographyVariant = "body1";

    if (isModifying) {
        subtitleClassName += " modifying";
        subtitleLabel = t(LocalizationKeys.BoundariesModifySubtitle);
        subtitleVariant = "subtitle2";
    }

    const subtitle = {
        className: subtitleClassName,
        variant: subtitleVariant,
        label: subtitleLabel,
    };

    return {
        dialogProps,
        dialogHeaders,
        actions,
        deleteConfirmDialog,
        editDataProps,
        rows,
        subtitle,
        isModifying,
        createButton,
        editNameProps,
        importButton,
    };
};

const hasChanges = (
    currentName: string | undefined,
    currentData: string | undefined,
    previous: Boundaries | undefined
) => {
    if (currentName === undefined || currentData === undefined || previous === undefined) return false;
    if (currentName.length === 0 || currentData.length === 0) return false;

    const name = currentName !== previous.name;
    const data = currentData !== getJSONString(previous.data);

    return name || data;
};

const getFormattedJSON = (v: any, revert?: boolean): any => {
    if (v instanceof Array) {
        if (v.every((i) => i instanceof Object)) {
            return v.map((i) => getFormattedJSON(i, revert));
        } else {
            const value = JSON.stringify(v);
            return value.length > TEXTFIELD_LINE_LIMIT ? v : value;
        }
    }

    let output: { [key: string]: any } = {};
    for (let k in v) {
        const key = revert ? k[0].toLowerCase() + k.slice(1) : toCapitalize(k);
        const current = v[k];
        if (current instanceof Object) {
            output[key] = getFormattedJSON(current, revert);
        } else {
            output[key] = current;
        }
    }

    return output;
};

const getJSONString = (value: any): string => {
    if (value === undefined) return "";
    return JSON.stringify(getFormattedJSON(value), null, 4)
        .replace(/\\/g, "")
        .replace(/"\[/g, "[")
        .replace(/\]"/g, "]")
        .replace(/"""/g, '"\\""'); // Handles escaping the " character for inches
};

function parseString<T = any>(value: string, defaultKeys: string[]): T {
    if (value.length === 0) throw new SyntaxError(t(LocalizationKeys.ImportSyntaxErrorEmptyObject));

    let object = {};
    try {
        object = JSON.parse(value);
    } catch {
        throw new SyntaxError(t(LocalizationKeys.ImportSyntaxErrorInvalidJson));
    }

    const keys = Object.keys(object).map((c) => c.toLowerCase());
    const missing: string[] = [];
    for (const defaultKey of defaultKeys) {
        if (keys.indexOf(defaultKey.toLowerCase()) === -1) {
            missing.push(defaultKey);
        }
    }

    if (missing.length > 0)
        throw new Error(t(LocalizationKeys.ImportSyntaxErrorMessage, { attributes: missing.join(", ") }));

    return object as T;
}

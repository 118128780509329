import { IPoint } from "../../../pixi/pixi";
import { FitOption } from "./pan-and-zoom-toolbar";

export interface ICanvasProps {
    src: string;
    width?: number;
    height?: number;
    onZoom?: (zoom: number) => void;
}

export interface ICanvasState {
    canvas?: HTMLCanvasElement;
    context?: CanvasRenderingContext2D;
    image?: HTMLImageElement;
    isDragging: boolean;
    origin: IPoint;
    pointer: IPoint;
    scale: number;
}

export const initialCanvasState: ICanvasState = {
    isDragging: false,
    origin: { x: 0, y: 0 },
    pointer: { x: 0, y: 0 },
    scale: 1,
} as const;

export interface ICanvasHandle {
    zoomIn: () => void;
    zoomOut: () => void;
    setFitOption: (option: FitOption) => void;
}

export const initializeCanvasState = () => ({
    ...initialCanvasState,
    origin: { ...initialCanvasState.origin },
    pointer: { ...initialCanvasState.pointer },
});

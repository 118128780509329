import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/workspace/workspace.reducers";
import { useCallback } from "react";
import { setShowPrintDialog } from "../../../../../store/overlay/overlay.reducers";
import { WorkspaceStatus, WorkspaceStatusTypes } from "../../../../../store/overlay/header/status-icon/status-icon";
import { showConnectorReportSelector } from "../../../../../store/overlay/overlay.selectors";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import {
    currentStatusSelector,
    disabledUISelector,
} from "../../../../../store/overlay/header/status-icon/status-icon.selectors";
import { IconButtonProps } from "@corning-ctcm/silica-react";

export const useExportButton = () => {
    const { t } = useTranslation();
    const currentStatus = useSelector(currentStatusSelector);
    const connectorReportDisplay = useSelector(showConnectorReportSelector);
    const disabledUI = useSelector(disabledUISelector);
    const dispatch = useDispatch<AppDispatch>();

    const onClick = useCallback(() => {
        dispatch(setShowPrintDialog(true));
    }, [dispatch]);

    const disabled = !IsReportReady(currentStatus) || connectorReportDisplay || disabledUI;
    const buttonProps: Omit<IconButtonProps, "icon"> = {
        id: "print-icon",
        palette: "primary",
        mode: "main",
        disabled,
        title: t(LocalizationKeys.PrintReport),
        placement: "bottom",
        onClick,
    };

    return { buttonProps };
};

export function IsReportReady(status: WorkspaceStatusTypes) {
    return (
        status === WorkspaceStatus.Ready ||
        status === WorkspaceStatus.Saved ||
        status === WorkspaceStatus.Saving ||
        status === WorkspaceStatus.Printed ||
        status === WorkspaceStatus.Printing ||
        status === WorkspaceStatus.Reporting
    );
}

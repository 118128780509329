import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ILabelCustomizationState, initialLabelCustomizationState } from "./customization";

const labelCustomizationSlice = createSlice({
    initialState: initialLabelCustomizationState,
    name: "labelCustomization",
    reducers: {
        setRackNumber: (state: ILabelCustomizationState, action: PayloadAction<number>) => {
            state.rackNumber = action.payload;
        },
        setReverseOrder: (state: ILabelCustomizationState, action: PayloadAction<boolean>) => {
            state.reverseOrder = action.payload;
        },
        setAllowPasting: (state: ILabelCustomizationState, action: PayloadAction<boolean>) => {
            state.allowPasting = action.payload;
        },
        setPastedContent: (state: ILabelCustomizationState, action: PayloadAction<string>) => {
            state.pastedContent = action.payload;
        },
        resetCustomization: (state: ILabelCustomizationState) => {
            state.rackNumber = 1;
            state.reverseOrder = false;
            state.pastedContent = "";
        },
    },
});

export const LabelCustomizationReducer = labelCustomizationSlice.reducer;
export const { setRackNumber, setReverseOrder, setAllowPasting, setPastedContent, resetCustomization } =
    labelCustomizationSlice.actions;

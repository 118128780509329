import * as Pixi from "pixi.js";
import React from "react";
import { Close, Check } from "@mui/icons-material";
import { SilicaThemeProvider, IconButton, TextField } from "@corning-ctcm/silica-react";
import { useCustomTextField } from "./text-input.hooks";
import { PixiComponent, applyDefaultProps } from "@pixi/react";
import { createRoot } from "react-dom/client";
import { PRIMARY_MAIN, DEFAULT_SIZE, ICustomTextFieldProps, TextInputProps, getDefaultProps } from "../../../models/pixi/text-input";

import "../../../css/pixi/text-input.scss";

const CustomTextField: React.FC<ICustomTextFieldProps> = (props) => {
    const { ref, textFieldProps, cancelProps, confirmProps } = useCustomTextField(props);

    const actions = (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <IconButton {...cancelProps} icon={<Close />} />
            <IconButton {...confirmProps} icon={<Check />} />
        </div>
    );

    return (
        <SilicaThemeProvider>
            <div className="textfield-container" ref={ref}>
                <TextField
                    inputRef={(input: { focus: () => any; }) => input?.focus()}
                    InputProps={{ endAdornment: actions }}
                    {...textFieldProps}
                />
            </div>
        </SilicaThemeProvider>
    );
};

export const TextInput: React.FC<TextInputProps> = PixiComponent("TextInput", {
    config: {
        destroy: false,
        destroyChildren: false,
    },
    create: (props: TextInputProps) => {
        const container = new Pixi.Container();
        container.eventMode = props.eventMode;
        container.x = props.x ?? container.x;
        container.y = props.y ?? container.y;
        container.name = props.name;
        return container;
    },
    applyProps: (
        instance: Pixi.Container<Pixi.DisplayObject>, 
        prev: Readonly<TextInputProps>, 
        curr: Readonly<TextInputProps>
    ) => {
        const oldProps = getDefaultProps(prev);
        const newProps = getDefaultProps(curr);
        applyDefaultProps(instance, oldProps, newProps);

        const onCancel = () => {
            if (curr.name === null) return;
            if (curr.onCancel) curr.onCancel();

            const div = document.getElementById(curr.name);
            if (div) document.body.removeChild(div);
        };

        const onConfirm = (value: string) => {
            curr.onConfirm(value);
            onCancel();
        };

        const render = () => {
            if (curr.name === null || document.getElementById(curr.name)) return;

            const global = instance.getGlobalPosition();
            const div = document.createElement("div");
            const width = curr.width ?? DEFAULT_SIZE.width;
            const height = curr.height ?? DEFAULT_SIZE.height;
            const top = global.y - height * 0.5;
            const left = global.x - width * 0.25;
            div.id = curr.name;
            div.style.position = "absolute";
            div.style.backgroundColor = "white";
            div.style.top = `${top}px`;
            div.style.left = `${left}px`;
            div.style.width = `${width}px`;
            div.style.height = `auto`;
            div.style.border = `3px solid ${PRIMARY_MAIN}`;
            div.style.borderRadius = "8px";

            document.body.appendChild(div);
            const divContainer = createRoot(div);

            const element = <CustomTextField value={curr.value} onConfirm={onConfirm} onCancel={onCancel} />;
            divContainer.render(element);
        };

        instance.onpointerdown = () => {
            if (curr.onClick) curr.onClick();
            if (curr.eventType === "mousedown") render();
        };

        instance.onpointerover = () => {
            if (curr.onMouseOver) curr.onMouseOver();
            if (curr.eventType === "mouseover") render();
        };

        instance.onpointerout = () => {
            if (curr.eventMode === "auto") return;
            if (curr.onMouseOut) curr.onMouseOut();
        };
    },
});

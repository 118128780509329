import { ConnLC, IConnectorType, Stagger0 } from "../../wizard";
import { IUnitOfMeasure } from "../../../../../models/overlay/header/units-of-measure";
import { Dispatch } from "react";
import { Action } from "@reduxjs/toolkit";
import { Yellow } from "../../../../../models/ui/dialog/color-dialog";
import { IBuildData } from "../../../../../store/workspace/build/build";
import { getNbAccessPoints } from "../../../../../store/workspace/build/connector/connector";
import { BLengthType } from "../../../../../models/overlay/wizard/setup/b-length-section/b-length-section";

export interface IDestinationSetupContentProps {
    initialState: IDestinationSetupState;
}

export interface IDestinationSetupState {
    applyToAll?: boolean;
    applyToAllEnabled?: boolean;

    fiberCount: number;
    position?: number;
    connectorType: IConnectorType;
    shell?: string;

    fiberCountPerConnector?: number;
    fiberCountPerConnectorValidValues?: number[],
    fiberCountPerConnectorField?: string;
    isFiberCountPerConnectorValid?: boolean;
    fiberCountPerConnectorErrorText?: string;

    accessPoints?: number;
    accessPointsFieldValue?: string;
    isAccessPointsValid?: boolean;
    accessPointsErrorText?: string;

    groupCount?: number;
    groupCountFieldValue?: string;
    isGroupCountValid?: boolean;
    groupCountErrorText?: string;

    nbConnectorsPerGroup?: number;
    nbConnectorsPerGroupFieldFieldValue?: string;
    isNbConnectorsPerGroupValid?: boolean;
    nbConnectorsPerGroupErrorText?: string;

    mesh: boolean;

    meshOffset?: IUnitOfMeasure;
    meshOffsetField: string;
    isMeshOffsetValid?: boolean;
    meshOffsetErrorText?: string;

    meshColor?: string;

    aLength: IUnitOfMeasure;
    aLengthField?: string;
    isALengthValid?: boolean;
    aLengthErrorText?: string;

    bLength: IUnitOfMeasure;
    bLengthField?: string;
    isBLengthValid?: boolean;
    bLengthErrorText?: string;

    sLength: IUnitOfMeasure;
    sLengthField?: string[];
    sLengthInputValue?: string;
    isSLengthValid?: boolean;
    isSLengthEnabled?: boolean;
    sLengthErrorText?: string;
    reverseStaggering: boolean;

    bLengthType: BLengthType;
}

export interface IDestinationSetupContext {
    state: IDestinationSetupState;
    dispatch: Dispatch<Action>;
}

export const initialDestinationSetupState: IDestinationSetupState = {
    applyToAll: false,
    fiberCount: 0,
    connectorType: ConnLC,
    mesh: false,
    meshOffsetField: "",
    meshColor: Yellow.name,
    aLength: Stagger0.value,
    bLength: Stagger0.value,
    sLength: Stagger0.value,
    isFiberCountPerConnectorValid: true,
    isAccessPointsValid: true,
    isALengthValid: true,
    isBLengthValid: true,
    isGroupCountValid: true,
    isMeshOffsetValid: true,
    isNbConnectorsPerGroupValid: true,
    isSLengthEnabled: true,
    isSLengthValid: true,
    accessPointsErrorText: "",
    groupCountErrorText: "",
    nbConnectorsPerGroupErrorText: "",
    meshOffsetErrorText: "",
    aLengthErrorText: "",
    bLengthErrorText: "",
    sLengthErrorText: "",
    reverseStaggering: false,
    bLengthType: "uniform",
    shell: "soft",
};

export const hasDestinationChanges = (selectedBuild: IBuildData | undefined, setup: IDestinationSetupState) => {
    if (!selectedBuild) return false;

    const [, ...distribution] = selectedBuild.drops;
    const drop = distribution.find((d) => d.position === setup.position);
    if (drop) {
        const currentTypeIsNewType = drop.groups[0].connectors[0].type === setup.connectorType.type;
        const currentTypeIsDiffFromAll = distribution.some(
            (d) => d.groups[0].connectors[0].type !== drop.groups[0].connectors[0].type
        );
        const connectorType = setup.applyToAll
            ? currentTypeIsDiffFromAll || !currentTypeIsNewType
            : !currentTypeIsNewType;

        const accessPoints = getNbAccessPoints(distribution) !== setup.accessPoints;
        const groupCount = drop.groups.length !== setup.groupCount;
        const mesh = drop.mesh !== setup.mesh;
        const meshColor = setup.mesh && drop.meshColor !== setup.meshColor;
        const meshOffset = setup.mesh && drop.meshOffset?.value !== setup.meshOffset?.value;
        const aLength = drop.lengthA.value !== setup.aLength.value;
        const bLength = drop.lengthB.value !== setup.bLength.value;
        const sLength = drop.groups[0].stagger?.value !== setup.sLength.value;
        const reverseStaggering = drop.reverseStaggering !== setup.reverseStaggering;
        const bLengthType =
            (drop.customBLength && setup.bLengthType === "uniform") ||
            (!drop.customBLength && setup.bLengthType === "custom");

        return (
            connectorType ||
            accessPoints ||
            groupCount ||
            mesh ||
            meshColor ||
            meshOffset ||
            aLength ||
            bLength ||
            sLength ||
            reverseStaggering ||
            bLengthType
        );
    }

    return false;
};

export const initialDestinationSetupContext: IDestinationSetupContext = {
    state: initialDestinationSetupState,
    dispatch: () => {},
};

export function getAsymmetricAPCount(
    groupCount: number,
    connectorsPerGroup: number,
    fiberCount: number,
    fiberCountInUse: number,
    minValidAPCount: number,
    maxValidAPCount: number,
    maxDropFiber: number
) {
    const maxAPCount = getMaxAsymmetricAPCount(
        groupCount,
        connectorsPerGroup,
        fiberCount,
        fiberCountInUse,
        maxDropFiber
    );
    const accessPoints = adjustValue(
        maxAPCount,
        minValidAPCount,
        maxValidAPCount,
        Math.max(minValidAPCount, maxAPCount)
    );
    return accessPoints;
}

function adjustValue(value: number, minValidValue: number, maxValidValue: number, maxValue: number) {
    const maxPossibleValue = Math.min(maxValue, maxValidValue);
    const adjustedValue = Math.min(Math.max(value, minValidValue), maxPossibleValue);
    return adjustedValue;
}

export function getMaxAsymmetricAPCount(
    groupCount: number,
    connectorsPerGroup: number,
    fiberCount: number,
    fiberCountInuse: number,
    maxDropFiber: number
) {
    const dropFiber = groupCount * connectorsPerGroup * fiberCountInuse;
    const validDropFiber = Math.min(maxDropFiber, dropFiber);
    const apCount = Math.ceil(fiberCount / validDropFiber);
    return apCount;
}

export function getMaxGroupCount(accessPoints: number, fiberCount: number, fiberCountInUse: number) {
    if (!accessPoints || !fiberCount) return 1;
    const maxGroupCount = Math.floor(fiberCount / (fiberCountInUse * accessPoints));

    return maxGroupCount;
}

export function getConnectorsPerGroupCount(
    accessPoints: number,
    groupCount: number,
    fiberCount: number,
    fiberCountInUse: number
) {
    const maxValidConnectorsPerGroup = Math.floor(fiberCount / (accessPoints * groupCount * fiberCountInUse));
    return maxValidConnectorsPerGroup;
}

export function getMaxValidConnectorsPerGroupCount(groupCount: number, fiberCount: number, fiberCountInUse: number) {
    return Math.floor(fiberCount / (groupCount * fiberCountInUse));
}

export interface IConnectorHighlightsState {
    highlights: IHighlight[];
    hoveredConnectorId: number;
    selectedConnectorId: number;
}

export interface IHighlight {
    connectorId: number;
    assigned: boolean;
    selected: boolean;
}

export const initialHighlightsState: IConnectorHighlightsState = {
    highlights: [],
    hoveredConnectorId: -1,
    selectedConnectorId: -1
};

export interface IHighlightColor {
    fill: string;
    stroke?: string;
}

export const DEFAULT_BLUE: IHighlightColor = { fill: "#0098DB" };
export const DEFAULT_GREEN: IHighlightColor = { fill: "#4D8809" };

export const WHITE: IHighlightColor = { fill: "#FFFFFF" };
export const GREY: IHighlightColor = { fill: "#9E9E9E" };
export const BLUE: IHighlightColor = { fill: "#3867C1", stroke: "#2545B2" };
export const GREEN: IHighlightColor = { fill: "#59C795", stroke: "#3DB486" };
export const ORANGE: IHighlightColor = { fill: "#F89B1D", stroke: "#F89B1D" };
export const RED: IHighlightColor = { fill: "#BC3131", stroke: "#953333" };

export const DistributedColors: IHighlightColor[] = [BLUE, GREEN, ORANGE, RED];

export const toDecimalHex = (color: string | undefined) => {
    return color ? parseInt(color.replace(/^#/, ""), 16) : 0xffffff;
};

import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Utils } from "../../../../utils";
import { WarningSeverity, IWarning } from "../../../../store/overlay/header/warnings/warnings";
import { allWarningsSelector } from "../../../../store/overlay/header/warnings/warnings.selectors";
import { setCatalogCode } from "../../../../store/workspace/build/build.reducers";
import { setSessionWarnings } from "../../../../store/workspace/ssc/ssc.reducer";
import { currentBuildSelector } from "../../../../store/workspace/root.selectors";
import { IconButtonProps } from "@corning-ctcm/silica-react";

const sampleError = {
    severity: WarningSeverity.Error,
    componentName: "CONNECTOR 1",
    problemText: "This text describes an error",
    solutionText: "This text offers a solution to the error",
};

const sampleWarning = {
    severity: WarningSeverity.Warning,
    componentName: "CONNECTOR 1",
    problemText: "This text describes a warning",
    solutionText: "This text offers a solution to the warning",
};

export const useDebugMenu = () => {
    const currentBuild = useSelector(currentBuildSelector);
    const allWarnings = useSelector(allWarningsSelector);
    const [show] = useState(Utils.isDevelopmentEnvironment());
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const toggleOpen = useCallback(() => {
        setOpen(!open);
    }, [open]);

    const showSoldTo = useCallback(() => {
        const defaultSoldTo = (window as any).soldTo ?? "";
        const soldTo = prompt("Enter Sold To:", defaultSoldTo);
        (window as any).soldTo = soldTo;
    }, []);

    const addErrorsAndWarnings = useCallback(() => {
        const input = prompt(
            "Enter the number of errors and warnings you want to add, respectively, separated by a space.\n\nExamples:\n  0 1   ...   add one warning\n  1 0   ...   add one error\n  2 3   ...   add two errors and three warnings"
        );
        if (!input) {
            return;
        }

        const values = input.split(" ");
        const errorCount = parseInt(values[0]);
        if (isNaN(errorCount)) {
            alert(`Invalid number of errors '${values[0]}'`);
            return;
        }
        const warningCount = parseInt(values[1]);
        if (isNaN(warningCount)) {
            alert(`Invalid number of warnings '${values[1]}'`);
            return;
        }

        AppendWarnings(allWarnings, errorCount, sampleError);
        AppendWarnings(allWarnings, warningCount, sampleWarning);
        dispatch(setSessionWarnings({ buildId: currentBuild?.id, warnings: allWarnings }));
    }, [allWarnings, currentBuild, dispatch]);

    const generateCatalogCode = useCallback(() => {
        dispatch(setCatalogCode("RAB-00000001"));
    }, [dispatch]);

    const buttonProps: Omit<IconButtonProps, "icon"> = {
        id: "tolerances-icon",
        palette: "primary",
        mode: "main",
        placement: "bottom",
        onClick: toggleOpen,
    };

    return {
        show,
        open,
        buttonProps,
        showSoldTo,
        addErrorsAndWarnings,
        generateCatalogCode,
    };
};

function AppendWarnings(
    array: IWarning[],
    count: number,
    template: { severity: WarningSeverity; componentName: string; problemText: string; solutionText: string }
) {
    for (let i = 0; i < count; i++) {
        array.push({
            ...template,
            id: array.length.toString(),
        });
    }
}

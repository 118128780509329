import { Dispatch } from "react";
import { Action } from "redux";
import { Blue, DEFAULT_LABEL_COLOR, IColor } from "../../../models/ui/dialog/color-dialog";

export interface IConnectorReportState {
    selectedRowIds: number[];
    labelChanged: boolean;
    triggerColorsChanged: boolean;
}

export interface IConnectorRowData {
    id: number;
    position: number;
    groupIndex: number;
    connectorIndex: number;
    group: number;
    color: IColor;
    label?: string;
    labelColor: IColor;
    lengthB: string;
    stagger: string;
    totalLength: string;
}

export const initialState: IConnectorReportState = {
    selectedRowIds: [],
    labelChanged: false,
    triggerColorsChanged: false,
};

export const initialConnectorRow: IConnectorRowData = {
    id: -1,
    position: -1,
    groupIndex: -1,
    connectorIndex: -1,
    group: 0,
    color: Blue,
    label: "",
    labelColor: DEFAULT_LABEL_COLOR,
    lengthB: "",
    stagger: "",
    totalLength: "",
};

export interface IConnectorReportContext {
    state: IConnectorReportState;
    dispatch: Dispatch<Action>;
}

export const initialConnectorReportContext: IConnectorReportContext = {
    state: initialState,
    dispatch: () => {},
};

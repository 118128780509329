import { IProjectManagerState } from "../../../store/overlay/project/project";
import { PayloadAction } from "@reduxjs/toolkit";
import { IProjectManagerData } from "./project-manager";
import { IBuildData } from "../build/build";

export function setProjectManagerAction(state: IProjectManagerState, action: PayloadAction<IProjectManagerData>) {
    const { sortType } = action.payload;
    state.data = { ...action.payload, sortType: sortType && sortType.length > 0 ? sortType : state.data.sortType };
}

export function setRecentProjectAction(state: IProjectManagerState, action: PayloadAction<IBuildData>) {
    state.recentBuild = action.payload;
    if (state.data) state.data.recentProjectId = action.payload.id;
}

export function setRecentProjectIdAction(state: IProjectManagerState, action: PayloadAction<number>) {
    if (state.data) state.data.recentProjectId = action.payload;
}

export function setProjectsAction(state: IProjectManagerState, action: PayloadAction<number[]>) {
    if (state.data) state.data.projectIds = action.payload;
}

export function removeProjectAction(state: IProjectManagerState, action: PayloadAction<number>) {
    if (state.data && state.data.projectIds) {
        state.data.projectIds = state.data.projectIds.filter((id) => id !== action.payload);
        state.data.totalBuildCount && state.data.totalBuildCount--;
    }
}

export function addProjectAction(state: IProjectManagerState, action: PayloadAction<number>) {
    if (state.data && state.data.projectIds) {
        state.data.projectIds = state.data.projectIds.find((id) => id === action.payload)
            ? state.data.projectIds
            : [...state.data.projectIds, action.payload];
        state.data.totalBuildCount = state.data.totalBuildCount ? state.data.totalBuildCount + 1 : 1;
    }
}

const Information = "info";
const Warning = "warning";
const Error = "error";
const Success = "success";

export const SnackbarTypes = {
    Information,
    Warning,
    Error,
    Success,
} as const;

export type SnackbarType = (typeof SnackbarTypes)[keyof typeof SnackbarTypes];

export interface IGenericSnackbarProps {
    id: string;
    open?: boolean;
    type?: SnackbarType;
    hideIcon?: boolean;
    message?: string;
    hideCancelButton?: boolean;
    hideActionButton?: boolean;
    hideCloseButton?: boolean;
    timeout?: number;
    onClose?: () => void;
    onCancel?: () => void;
    onConfirm?: () => void;
}

import { createSelector } from "@reduxjs/toolkit";
import { WorkspaceState } from "../../../workspace/workspace.reducers";

export const warningsSelector = (state: WorkspaceState) => state.warnings;
export const warningsCardDisplaySelector = (state: WorkspaceState) => state.warnings.displayCard;
export const acknowledgedWarningsSelector = (state: WorkspaceState) => state.warnings.acknowledgedWarnings;
export const unacknowledgedWarningGroupsSelector = (state: WorkspaceState) => state.warnings.unacknowledgedWarningGroups;

export const allWarningsSelector = createSelector(
    acknowledgedWarningsSelector,
    unacknowledgedWarningGroupsSelector,
    (acknowledgedWarnings, unacknowledgedWarnings) => [
        ...acknowledgedWarnings,
        ...unacknowledgedWarnings.flatMap((g) => g.warnings)
    ]
);
import { Container } from "@pixi/react";
import { BezierMarker } from "./bezier-marker/bezier-marker";
import { ICurvedLengthProps } from "../../../../models/pixi/decorators/dimension-lines/curved-length";
import { LineMarker } from "./line-marker/line-marker";

export const CurvedLength: React.FC<ICurvedLengthProps> = ({
    name,
    start,
    left,
    leftCurve,
    line,
    rightCurve,
    right,
    end,
    mesh,
}: ICurvedLengthProps) => {
    return (
        <Container name={name}>
            <LineMarker {...start} />
            <LineMarker {...left} />
            <BezierMarker {...leftCurve} />
            <LineMarker {...line} />
            <BezierMarker {...rightCurve} />
            <LineMarker {...right} />
            {mesh?.marker && <LineMarker {...mesh.marker} />}
            {mesh?.extension && <LineMarker {...mesh.extension} />}
            <LineMarker {...end} />
        </Container>
    );
};

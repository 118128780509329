import { useReducer } from "react";
import { ConnectorReportReducer } from "../../../store/overlay/reports/reports.reducers";
import { initialState } from "../../../store/overlay/reports/reports";

export const useReports = () => {
    const [state, dispatch] = useReducer(ConnectorReportReducer, initialState);
    const context = { state, dispatch };

    return { context };
};

import React from "react";
import { IStaggerAutoCompleteProps } from "../../../../../models/overlay/wizard/setup/stagger-autocomplete";
import { GenericAutocomplete } from "../../../../ui/generic-autocomplete/generic-autocomplete";
import { useStaggerAutocomplete } from "./stagger-autocomplete.hooks";

export const StaggerAutocomplete = (props: IStaggerAutoCompleteProps) => {
    const {
        open,
        disabled,
        options,
        staggerValue,
        inputValue,
        type,
        onOpen,
        onClose,
        onValueChanged,
        onInputChanged,
        label,
        isInputValid,
        inputErrorText,
        onInputKeyDown,
        units,
        inputRef,
    } = useStaggerAutocomplete(props);
    return (
        <GenericAutocomplete
            id="stagger"
            type={type}
            open={open}
            disabled={disabled}
            options={options}
            value={staggerValue}
            inputValue={inputValue}
            onOpen={onOpen}
            onClose={onClose}
            onValueChanged={onValueChanged}
            onInputChanged={onInputChanged}
            label={label}
            isInputValid={isInputValid}
            inputErrorText={inputErrorText}
            onInputKeyDown={onInputKeyDown}
            units={units}
            freeSolo={true}
            inputRef={inputRef}
        />
    );
};

export type ITolerances = {
    showTolerances: boolean;
    overallLengthTolerance: ITolerance;
    legLengthTolerance: ITolerance;
    meshLengthTolerance: ITolerance;
    labelDistanceTolerance: ITolerance;
};

export interface ITolerance {
    min: number;
    max: number;
}

// in inches
export const DEFAULT_TOLERANCE: ITolerance = {
    min: 0,
    max: 3,
};

// in inches
export const DEFAULT_LABEL_DISTANCE_TOLERANCE: ITolerance = {
    min: -1,
    max: 1,
};

export const TOLERANCE_FONT_SIZE = 32;

export const DEFAULT_TOLERANCES: ITolerances = {
    showTolerances: false,
    overallLengthTolerance: DEFAULT_TOLERANCE,
    legLengthTolerance: DEFAULT_TOLERANCE,
    meshLengthTolerance: DEFAULT_TOLERANCE,
    labelDistanceTolerance: DEFAULT_LABEL_DISTANCE_TOLERANCE,
};

export const areToleranceEquals = (t1: ITolerance, t2: ITolerance) => {
    if (t1 === undefined && t2 === undefined) return true;
    else if (t1 === undefined || t2 === undefined) return false;

    const min1 = t1.min;
    const min2 = t2.min;

    const max1 = t1.max;
    const max2 = t2.max;

    return min1 === min2 && max1 === max2;
};

export const areTolerancesEquals = (t1: ITolerances | undefined, t2: ITolerances | undefined) => {
    if (t1 === undefined && t2 === undefined) return true;
    else if (t1 === undefined || t2 === undefined) return false;

    const show = t1.showTolerances === t2.showTolerances;
    const overall = areToleranceEquals(t1.overallLengthTolerance, t2.overallLengthTolerance);
    const leg = areToleranceEquals(t1.legLengthTolerance, t2.legLengthTolerance);
    const mesh = areToleranceEquals(t1.meshLengthTolerance, t2.meshLengthTolerance);
    const label = areToleranceEquals(t1.labelDistanceTolerance, t2.labelDistanceTolerance);

    return show && overall && leg && mesh && label;
};

const isToleranceValid = (tolerance: ITolerance) => !isNaN(tolerance.min) && !isNaN(tolerance.max);

export const isTolerancesValid = (tolerances: ITolerances) => {
    const overall = isToleranceValid(tolerances.overallLengthTolerance);
    const leg = isToleranceValid(tolerances.legLengthTolerance);
    const mesh = isToleranceValid(tolerances.meshLengthTolerance);
    const label = isToleranceValid(tolerances.labelDistanceTolerance);

    return overall && leg && mesh && label;
};

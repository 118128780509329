import { Provider } from "react-redux";
import { usePixiComponent } from "./pixi-component.hooks";
import { StageContainer } from "../../models/pixi/pixi";
import { Stage } from "@pixi/react";
import { Background } from "./background/background";
import { Viewport } from "./viewport/viewport";
import { DocumentGraphics } from "./document-graphics/document-graphics";
import { ResourceManager } from "./manager/resource-manager/resource-manager";
import { BuildContext } from "../../store/workspace/build/build";
import { Cable } from "./build/cable";
import { DimensionLineContext, defaultStyle } from "../../models/pixi/decorators/dimension-lines/dimension-lines";

import "../../css/pixi/pixi-component.scss";

export const PixiComponent = () => {
    const { loaded, cableProps, buildContext, windowSize, store } = usePixiComponent();

    return (
        <div className={StageContainer} id="stage">
            <Stage options={{ backgroundColor: 0xffffff, antialias: true }} {...windowSize}>
                <Provider store={store}>
                    <Background />
                    <ResourceManager />
                    {loaded && (
                        <Viewport>
                            <DimensionLineContext.Provider value={defaultStyle}>
                                <BuildContext.Provider value={buildContext}>
                                    <Cable {...cableProps} />
                                </BuildContext.Provider>
                            </DimensionLineContext.Provider>
                        </Viewport>
                    )}
                    <DocumentGraphics />
                </Provider>
            </Stage>
        </div>
    );
};

export default PixiComponent;

import React from "react";
import { ICollapseMarkerProps } from "../../../../models/pixi/decorators/markers/collapse-marker";
import { useCollapseMarker } from "./collapse-marker.hooks";
import { Graphics } from "@pixi/react";

export const CollapseMarker: React.FC<ICollapseMarkerProps> = (props) => {
    const { display, draw } = useCollapseMarker(props);
    if (!display) return null;
    return <Graphics draw={draw} />;
};

import { t } from "i18next";
import {
    DEFAULT_TOLERANCES,
    ITolerances,
} from "../../../models/overlay/header/tolerances/tolerances";
import {
    convertTo,
    PrimaryUnit,
    Unit,
    Units,
} from "../../../models/overlay/header/units-of-measure";
import { IFlameRating } from "../../../models/overlay/wizard/setup/flame-rating";
import { IConnectorType } from "../../../store/overlay/wizard/wizard";
import { Identifiable } from "../../../models/services/services";
import { LocalizationKeys } from "../../../locales/keys";
import { getCurrentISODate } from "../../../services/build.service";

export interface IBoundariesData {
    flameRatings: IFlameRating[];
    pullingGrips: string[];
    fiberTypes: IFiberType[];
    bundleCountRanges: Record<string, Record<number, IRange>>;
    defaultConnectorColors: Record<string, Record<string, string>>;
    connectorColors: Record<string, Record<string, string[]>>;
    meshColors: Record<string, string[]>;
    defaultPrimaryMeasurementSystem: PrimaryUnit;
    defaultUnit: Unit;
    defaultShellType: string;
    defaultMeasurementsPosition: string;
    defaultOverallLengthType: string;
    defaultTolerances: ITolerances;
    defaultMesh: boolean;
    defaultMeshValue: number;
    defaultTrunkColors: Record<string, string>;
    defaultBootColors: Record<string, Record<string, string>>;
    fiberCountRange: IRange;
    feederEndLegRange: IRange;
    feederEndStaggerRange: IRange;
    feederEndConnectorTypes: IConnectorType[];
    feederEndGroupCountRange: IRange;
    feederALengthRanges: Record<string, IRange>;
    cableOuterDiameters: number[];
    distributionLegRange: IRange;
    distributionStaggerRange: IRange;
    distributionConnectorTypes: IConnectorType[];
    distributionGroupCountRange: IRange;
    accessPointRange: IRange;
    distributionConnectorCountPerGroupRange: IRange;
    distributionALengthRanges: Record<string, IRange>;
    mainFurcationToEndFurcationRange: IRange;
    meshOffsetRange: IRange;
    insertionLoss: Record<string, Record<string, string>>;
    defaultMeshColors: Record<string, string>;
}

interface IBoundaries {
    name: string;
    isDefault: boolean;
    lastModified?: string;
    data: IBoundariesData;
}

export type Boundaries = IBoundaries & Identifiable;

export interface IRABBoundariesState {
    displaySettings: boolean;
    options: Boundaries[];
}

export interface IRange {
    min: number;
    max: number;
}

export interface IFiberType {
    id: string;
    description: string;
}

export const initialBoundariesData: IBoundariesData = {
    flameRatings: [],
    pullingGrips: [],
    fiberTypes: [],
    bundleCountRanges: {},
    defaultConnectorColors: {},
    connectorColors: {},
    meshColors: {},
    defaultPrimaryMeasurementSystem: "imperial",
    defaultUnit: '"',
    defaultShellType: "soft",
    defaultMeasurementsPosition: "interior",
    defaultOverallLengthType: "furcation",
    defaultTolerances: DEFAULT_TOLERANCES,
    defaultMesh: false,
    defaultMeshValue: 0,
    defaultTrunkColors: {},
    defaultBootColors: {},
    fiberCountRange: { min: 0, max: 0 },
    feederEndLegRange: { min: 0, max: 0 },
    feederEndStaggerRange: { min: 0, max: 0 },
    feederEndConnectorTypes: [],
    feederEndGroupCountRange: { min: 0, max: 0 },
    feederALengthRanges: {},
    cableOuterDiameters: [],
    distributionLegRange: { min: 0, max: 0 },
    distributionStaggerRange: { min: 0, max: 0 },
    distributionConnectorTypes: [],
    distributionGroupCountRange: { min: 0, max: 0 },
    accessPointRange: { min: 0, max: 0 },
    distributionConnectorCountPerGroupRange: { min: 0, max: 0 },
    distributionALengthRanges: {},
    mainFurcationToEndFurcationRange: { min: 0, max: 0 },
    meshOffsetRange: { min: 0, max: 0 },
    insertionLoss: {},
    defaultMeshColors: {},
};

export const DEFAULT_BOUNDARIES_DATA_KEYS = Object.keys(initialBoundariesData);
export const DEFAULT_BOUNDARIES_NAME = "Drawing"; // This won't change

export const initialBoundaries: Boundaries = {
    name: t(LocalizationKeys.BoundariesDefaultName),
    isDefault: false,
    data: initialBoundariesData,
    lastModified: getCurrentISODate(),
};

export const initialRABBoundariesState: IRABBoundariesState = {
    displaySettings: false,
    options: [],
};

export const convertRangeUnit = (range: IRange | undefined, convertUnit: Unit) => {
    const unit = Units.UoMInches;
    const convertedRange: IRange = {
        min: convertTo({ value: range?.min ?? 0, unit }, convertUnit).value,
        max: convertTo({ value: range?.max ?? 0, unit }, convertUnit).value,
    };
    return convertedRange;
};

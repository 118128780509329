import { DialogReducer, showDialog } from "../../../../../store/workspace/dialog/dialog.reducer";
import { IColor, IColorDialogProps } from "../../../../../models/ui/dialog/color-dialog";
import { updateConnectors } from "../../../../../store/workspace/build/build.reducers";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/workspace/workspace.reducers";
import { IBuildData } from "../../../../../store/workspace/build/build";
import { IConnectorGroupData } from "../../../../../store/workspace/build/connector/connector";
import { IGenericDialogProps } from "../../../../../models/ui/dialog/generic-dialog";
import { LocalizationKeys } from "../../../../../locales/keys";
import { commonConnectorColorsSelector } from "../../../../../store/workspace/build.selectors";
import { currentBuildSelector } from "../../../../../store/workspace/root.selectors";
import { initialDialogState } from "../../../../../store/workspace/dialog/dialog";
import { showActionToolbarSelector } from "../../../../../store/overlay/footer/toolbar/toolbar.selectors";
import { showConnectorReportSelector } from "../../../../../store/overlay/overlay.selectors";
import { sscSessionBusySelector } from "../../../../../store/workspace/ssc/ssc.selectors";
import { useSscBuildSession } from "../../../../workspace/ssc.hooks";
import { useTranslation } from "react-i18next";
import { IconButtonProps } from "@corning-ctcm/silica-react";

export const useActionToolbar = () => {
    const currentBuild = useSelector(currentBuildSelector);
    const sscBusy = useSelector(sscSessionBusySelector);
    const showConnectorReport = useSelector(showConnectorReportSelector);
    const showToolbar = useSelector(showActionToolbarSelector);
    const { colorDialogProps, confirmResetDialogProps, onColorPaletteClick } = useColorResetDialog();

    const buttonProps: Omit<IconButtonProps, "icon"> = {
        id: "cable-connector-colors-icon",
        palette: "primary",
        mode: "main",
        disabled: sscBusy || !!currentBuild?.catalogCode?.length || showConnectorReport,
        placement: "top",
        onClick: onColorPaletteClick,
    };

    const actionToolbarProps = {
        showToolbar,
        buttonProps,
        colorDialogProps,
        confirmResetDialogProps,
    };

    return actionToolbarProps;
};

const useColorResetDialog = () => {
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();
    const currentBuild = useSelector(currentBuildSelector);
    const colors = useSelector(commonConnectorColorsSelector);
    const { updateBuildSession } = useSscBuildSession();

    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);

    const [currentColor, setCurrentColor] = useState<IColor | undefined>(undefined);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    useEffect(() => {
        if (!state.props.open && !showConfirmDialog && currentColor) {
            setCurrentColor(undefined);
        }
    }, [currentColor, showConfirmDialog, state.props.open]);

    const onColorButtonClick = useCallback(
        (color: IColor) => {
            setCurrentColor(color);
            setShowConfirmDialog(true);
            dispatch(showDialog(false));
        },
        [dispatch]
    );

    const onResetButtonClick = useCallback(() => {
        setCurrentColor(undefined);
        setShowConfirmDialog(true);
        dispatch(showDialog(false));
    }, [dispatch]);

    const onResetConfirm = useCallback(() => {
        const feeder = currentBuild?.drops.find((d) => d.side === "feeder");
        if (currentBuild && feeder) {
            const updatedDrops = currentBuild.drops.map((d) => ({
                ...d,
                lengthA: d.lengthA,
                lengthB: d.lengthB,
                groups: resetColors(d.groups, currentColor),
            }));
            const updatedConnectors = updatedDrops.flatMap((d) => d.groups.flatMap((g) => g.connectors));

            storeDispatch(updateConnectors(updatedConnectors));

            const updatedBuild: IBuildData = {
                ...currentBuild,
                drops: updatedDrops,
            };

            updateBuildSession(updatedBuild);
        }
        setShowConfirmDialog(false);
    }, [currentBuild, currentColor, storeDispatch, updateBuildSession]);

    const onConfirmResetDialogClose = useCallback(() => {
        setShowConfirmDialog(false);
    }, []);

    const onColorPaletteClick = useCallback(() => {
        dispatch(showDialog(true));
    }, [dispatch]);

    const confirmResetDialogProps: IGenericDialogProps = useMemo(() => {
        return {
            id: "confirm-color-reset",
            title: t(LocalizationKeys.ConnectorSelection),
            closable: true,
            display: showConfirmDialog,
            message: t(LocalizationKeys.ResetConnectorColors),
            onClose: onConfirmResetDialogClose,
            confirmText: t(LocalizationKeys.Apply),
            onConfirm: onResetConfirm,
        };
    }, [showConfirmDialog, t, onResetConfirm, onConfirmResetDialogClose]);

    const colorDialogProps: IColorDialogProps = useMemo(() => {
        return {
            context: { state, dispatch },
            preventOutsideDismiss: true,
            className: "rapid-bundle",
            displayFooter: true,
            colors: colors,
            currentColor,
            onColorButtonClick,
            onResetButtonClick,
        };
    }, [currentColor, state, dispatch, colors, onColorButtonClick, onResetButtonClick]);

    return { colorDialogProps, confirmResetDialogProps, onColorPaletteClick };
};

const resetColors = (groups: IConnectorGroupData[], color?: IColor): IConnectorGroupData[] => {
    return groups.map((g) => {
        return {
            ...g,
            position: g.position,
            stagger: g.stagger,
            lengthB: g.lengthB,
            connectors: g.connectors.map((c) => {
                return {
                    ...c,
                    stagger: c.stagger,
                    color: color && color.name !== c.color ? color.name : c.defaultColor,
                };
            }),
        };
    });
};

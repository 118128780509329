import React from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { useDialogFooter } from "./dialog-footer.hooks";
import { IDialogFooterProps } from "../../../../models/ui/dialog/dialog-footer";
import { ContainedButton, OutlinedButton } from "@corning-ctcm/silica-react";

import "../../../../css/ui/dialog/dialog-footer.scss";

export const DialogFooter: React.FC<IDialogFooterProps> = (props) => {
    const { t } = useTranslation();
    const { actionButtonText, onAction, onCancel } = useDialogFooter(props);

    return (
        <div className="dialog-footer">
            <div className="dialog-footer-button-container">
                <OutlinedButton id="footer-cancel-button" className="cancel-button" onClick={onCancel}>
                    {t(LocalizationKeys.Cancel)}
                </OutlinedButton>
                <ContainedButton id="footer-action-button" className="action-button" onClick={onAction}>
                    {actionButtonText}
                </ContainedButton>
            </div>
        </div>
    );
};

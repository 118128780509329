import { createContext, Dispatch } from "react";
import { Action } from "redux";
import { PolarityAssignments } from "../../../../models/overlay/polarity/connector-assignment/connector-assigment";

export interface IConnectorAssignmentState {
    selectAll: boolean;
    polarityAssignment: string;
    polarityKey: number;
    feederSelection: number[];
    distributionSelection: number[];
    assignedSelection: number[];
}

export const initialConnectorAssignmentState: IConnectorAssignmentState = {
    selectAll: false,
    polarityAssignment: PolarityAssignments.Standard,
    polarityKey: -1,
    feederSelection: [],
    distributionSelection: [],
    assignedSelection: [],
};

export interface IConnectorAssignmentContext {
    state: IConnectorAssignmentState;
    dispatch: Dispatch<Action>;
}

export const initialConnectorAssignmentContext: IConnectorAssignmentContext = {
    state: initialConnectorAssignmentState,
    dispatch: () => {},
};

export const ConnectorAssignmentContext = createContext(initialConnectorAssignmentContext);
import i18n from "i18next";
import { LocalizationKeys } from "../../../locales/keys";
import {
    getConnectorTypeString,
    isLCDuplex,
    isLCSimplex,
    isMMC,
    isMPO,
    isMTP,
    isSCSimplex,
    LC,
    LC_DUPLEX,
    LC_SIMPLEX,
    MMC16F_NP,
    MMC16F_P,
    MMC24F_NP,
    MMC24F_P,
    MPO12F_NP,
    MPO12F_P,
    MTP12F_FP_NP,
    MTP12F_FP_P,
    MTP12F_NP,
    MTP12F_P,
    MTP24F_FP_NP,
    MTP24F_FP_P,
    MTP24F_NP,
    MTP24F_P,
    MTP8F_NP,
    MTP8F_P,
    SC_APC_SIMPLEX,
} from "../../../components/pixi/factories/texture";
import { IBuildData } from "../../workspace/build/build";
import { IRange } from "../../workspace/ssc/ssc";
import {
    convertTo,
    IUnitOfMeasure,
    roundToDecimalBasedOnUnit,
    Unit,
    UoMInches,
} from "../../../models/overlay/header/units-of-measure";
import { ISourceSetupState } from "../../../store/overlay/wizard/setup/source-setup/source-setup";
import { IDestinationSetupState } from "../../../store/overlay/wizard/setup/destination-setup/destination-setup";
import { IDrop } from "../../workspace/build/drop";
import { IConnectorData } from "../../workspace/build/connector/connector";

export interface IWizardState {
    display: boolean;
    stepCount: number;
    currentStep: number;
    isNextEnabled: boolean;
    applyEditChanges: boolean;
    dropCheckboxDisabled: boolean;
    savedBuildData?: IBuildData;
    savedSourceSetup?: ISourceSetupState;
    savedDestinationSetup?: IDestinationSetupState;
    isDynamicUpdateDisabled: boolean;
    focusOnError: boolean;
}

export const initialState: IWizardState = {
    display: false,
    stepCount: 2,
    currentStep: 1,
    isNextEnabled: false,
    applyEditChanges: false,
    dropCheckboxDisabled: false,
    isDynamicUpdateDisabled: false,
    focusOnError: false
};

export interface IFiberCount {
    key: string;
    description: string;
    count: number;
    code?: string;
}

export interface IFiberCountRange {
    min: number;
    max: number;
    description: string;
}

export interface IStaggerLength {
    key: string;
    value: IUnitOfMeasure;
}

export const Stagger0: IStaggerLength = { key: "0", value: { unit: UoMInches, value: 0 } };
export const Stagger6: IStaggerLength = { key: "6", value: { unit: UoMInches, value: 6 } };
export const Stagger12: IStaggerLength = { key: "12", value: { unit: UoMInches, value: 12 } };
export const Stagger18: IStaggerLength = { key: "18", value: { unit: UoMInches, value: 18 } };
export const Stagger24: IStaggerLength = { key: "24", value: { unit: UoMInches, value: 24 } };
export const Stagger36: IStaggerLength = { key: "36", value: { unit: UoMInches, value: 36 } };

export const StaggerLengthTypes = [Stagger0, Stagger6, Stagger12, Stagger18, Stagger24, Stagger36];

export const StaggerLength = {
    Stagger0,
    Stagger6,
    Stagger12,
    Stagger18,
    Stagger24,
    Stagger36,
};

export type StaggerLengths = (typeof StaggerLength)[keyof typeof StaggerLength];

export interface IConnectorType {
    id?: number;
    key: string;
    description: string | null;
    type?: string;
    fiberCount: number;
    fiberCountInUse?: number;
    smCode?: string;
    mmCode?: string;
    pinned?: boolean;
}

// Originally we were going to use the part # as the key but some connectors do not have a part #, assigning incrementing key values
export const ConnLCSimplex: IConnectorType = {
    key: "1",
    description: LC_SIMPLEX,
    type: LC_SIMPLEX,
    fiberCount: 1,
    pinned: false,
};
export const ConnLC: IConnectorType = {
    key: "2",
    description: LC,
    type: LC,
    fiberCount: 2,
    smCode: "78",
    mmCode: "79",
};
export const ConnLCDuplex: IConnectorType = {
    key: "3",
    description: LC_DUPLEX,
    type: LC_DUPLEX,
    fiberCount: 2,
    pinned: false,
};
export const ConnMTP8_NP: IConnectorType = {
    key: "4",
    description: MTP8F_NP,
    type: MTP8F_NP,
    fiberCount: 8,
    smCode: "E8",
    pinned: false,
};
export const ConnMTP8_P: IConnectorType = {
    key: "5",
    description: MTP8F_P,
    type: MTP8F_P,
    fiberCount: 8,
    smCode: "E7",
    pinned: true,
};
export const ConnMTP12_NP: IConnectorType = {
    key: "6",
    description: MTP12F_NP,
    type: MTP12F_NP,
    fiberCount: 12,
    smCode: "90",
    mmCode: "75",
    pinned: false,
};
export const ConnMTP12_P: IConnectorType = {
    key: "7",
    description: MTP12F_P,
    type: MTP12F_P,
    fiberCount: 12,
    smCode: "89",
    mmCode: "93",
    pinned: true,
};
export const ConnMTP12_FP_NP: IConnectorType = {
    key: "8",
    description: MTP12F_FP_NP,
    type: MTP12F_FP_NP,
    fiberCount: 12,
    smCode: "F4",
    pinned: false,
};
export const ConnMTP12_FP_P: IConnectorType = {
    key: "9",
    description: MTP12F_FP_P,
    type: MTP12F_FP_P,
    fiberCount: 12,
    smCode: "F5",
    pinned: true,
};
export const ConnMPO12_NP: IConnectorType = {
    key: "10",
    description: MPO12F_NP,
    type: MPO12F_NP,
    fiberCount: 12,
    pinned: false,
};
export const ConnMPO12_P: IConnectorType = {
    key: "11",
    description: MPO12F_P,
    type: MPO12F_P,
    fiberCount: 12,
    pinned: true,
};
export const ConnMMC16_NP: IConnectorType = {
    key: "12",
    description: MMC16F_NP,
    type: MMC16F_NP,
    fiberCount: 16,
    pinned: false,
};
export const ConnMMC16_P: IConnectorType = {
    key: "13",
    description: MMC16F_P,
    type: MMC16F_P,
    fiberCount: 16,
    pinned: true,
};
export const ConnMTP24_P: IConnectorType = {
    key: "14",
    description: MTP24F_P,
    type: MTP24F_P,
    fiberCount: 24,
    smCode: "A8",
    mmCode: "A7",
    pinned: true,
};
export const ConnMTP24_NP: IConnectorType = {
    key: "15",
    description: MTP24F_NP,
    type: MTP24F_NP,
    fiberCount: 24,
    smCode: "A9",
    mmCode: "A6",
    pinned: false,
};
export const ConnMTP24_FP_NP: IConnectorType = {
    key: "16",
    description: MTP24F_FP_NP,
    type: MTP24F_FP_NP,
    fiberCount: 24,
    smCode: "F6",
    pinned: false,
};
export const ConnMTP24_FP_P: IConnectorType = {
    key: "17",
    description: MTP24F_FP_P,
    type: MTP24F_FP_P,
    fiberCount: 24,
    smCode: "F7",
    pinned: true,
};
export const ConnMMC24_NP: IConnectorType = {
    key: "18",
    description: MMC24F_NP,
    type: MMC24F_NP,
    fiberCount: 24,
    pinned: false,
};
export const ConnMMC24_P: IConnectorType = {
    key: "19",
    description: MMC24F_P,
    type: MMC24F_P,
    fiberCount: 24,
    pinned: true,
};
export const ConnSCAPCSimplex: IConnectorType = {
    key: "20",
    description: SC_APC_SIMPLEX,
    type: SC_APC_SIMPLEX,
    fiberCount: 1,
    pinned: false,
}

export const ConnectorTypes = [
    ConnLC,
    ConnMTP8_NP,
    ConnMTP8_P,
    ConnMTP12_NP,
    ConnMTP12_P,
    ConnMTP12_FP_NP,
    ConnMTP12_FP_P,
    ConnMMC16_NP,
    ConnMMC16_P,
    ConnMTP24_NP,
    ConnMTP24_P,
    ConnMTP24_FP_NP,
    ConnMTP24_FP_P,
    ConnMMC24_NP,
    ConnMMC24_P,
    ConnSCAPCSimplex,
    ConnLCSimplex,
    ConnLCDuplex,
    ConnMPO12_NP,
    ConnMPO12_P,
];

export const getConnectorTypeFromDrop = ({ groups, fiberCountInUse }: IDrop): IConnectorType => {
    let type = "";
    if (groups) {
        const { connectors } = groups[0];
        type = connectors[0].type ?? type;
    }

    return { ...getConnectorType(type), fiberCountInUse };
};

export const getConnectorTypeFromConnectorData = ({
    type,
    fiberCountInUse,
}: IConnectorData): IConnectorType => ({ ...getConnectorType(type), fiberCountInUse });

export const getConnectorTypeDescription = (connectorType: IConnectorType) => {
    let description = connectorType.description ?? "";

    const fiberCount = connectorType.fiberCount;
    const fiberCountInUse = connectorType.fiberCountInUse;
    const type = connectorType.type ?? "";
    if (fiberCountInUse && fiberCountInUse !== fiberCount) {
        switch (type) {
            case ConnMTP8_NP.type:
            case ConnMTP12_NP.type:
            case ConnMTP24_NP.type:
                description = `${fiberCountInUse} F, MTP${fiberCount}, non-pinned`;
                break;
            case ConnMTP8_P.type:
            case ConnMTP12_P.type:
            case ConnMTP24_P.type:
                description = `${fiberCountInUse} F, MTP${fiberCount}, pinned`;
                break;
            case ConnMTP12_FP_NP.type:
            case ConnMTP24_FP_NP.type:
                description = `${fiberCountInUse} F, MTP${fiberCount} Ferrule Push, non-pinned`;
                break;
            case ConnMTP12_FP_P.type:
            case ConnMTP24_FP_P.type:
                description = `${fiberCountInUse} F, MTP${fiberCount} Ferrule Push, pinned`;
                break;
            case ConnMMC16_NP.type:
            case ConnMMC24_NP.type:
                description = `${fiberCountInUse} F, MMC${fiberCount}, non-pinned`;
                break;
            case ConnMMC16_P.type:
            case ConnMMC24_P.type:
                description = `${fiberCountInUse} F, MMC${fiberCount}, pinned`;
                break;
            case ConnMPO12_NP.type:
                description = `${fiberCountInUse} F, MPO${fiberCount}, non-pinned`;
                break;
            case ConnMPO12_P.type:
                description = `${fiberCountInUse} F, MPO${fiberCount}, pinned`;
                break;
        }
    }

    return description;
};

export const getConnectorType = (type: string | undefined): IConnectorType => {
    switch (type) {
        case ConnSCAPCSimplex.type:
            return ConnSCAPCSimplex;
        case ConnLC.type:
            return ConnLC;
        case ConnMTP8_NP.type:
            return ConnMTP8_NP;
        case ConnMTP8_P.type:
            return ConnMTP8_P;
        case ConnMTP12_NP.type:
            return ConnMTP12_NP;
        case ConnMTP12_P.type:
            return ConnMTP12_P;
        case ConnMTP12_FP_NP.type:
            return ConnMTP12_FP_NP;
        case ConnMTP12_FP_P.type:
            return ConnMTP12_FP_P;
        case ConnMMC16_NP.type:
            return ConnMMC16_NP;
        case ConnMMC16_P.type:
            return ConnMMC16_P;
        case ConnMTP24_NP.type:
            return ConnMTP24_NP;
        case ConnMTP24_P.type:
            return ConnMTP24_P;
        case ConnMTP24_FP_NP.type:
            return ConnMTP24_FP_NP;
        case ConnMTP24_FP_P.type:
            return ConnMTP24_FP_P;
        case ConnMMC24_NP.type:
            return ConnMMC24_NP;
        case ConnMMC24_P.type:
            return ConnMMC24_P;
        case ConnLCDuplex.type:
            return ConnLCDuplex;
        case ConnLCSimplex.type:
            return ConnLCSimplex;
        case ConnMPO12_NP.type:
            return ConnMPO12_NP;
        case ConnMPO12_P.type:
            return ConnMPO12_P;
        default:
            return { key: "-1", description: "ConnectorNotFound", fiberCount: -1 };
    }
};

export const getConnectorFamilyString = ({ type, fiberCount }: IConnectorType) => {
    let familyStr = "Unknown";
    
    if (type) {
        const typeStr = getConnectorTypeString(type);
        if (isMTP(type)) {
            familyStr = isMPO(type) ? `MPO${fiberCount}` : `MTP${fiberCount}`;
        } else if (isMMC(typeStr)) {
            familyStr = `MMC${fiberCount}`;
        } else if (isSCSimplex(typeStr)) {
            return "SC Simplex";
        } else if (isLCSimplex(typeStr)) {
            familyStr = "LC Simplex";
        } else if (isLCDuplex(typeStr)) {
            familyStr = "LC Duplex";
        } else {
            familyStr = "LC";
        }
    }

    return familyStr;
};

export interface ICableType {
    key: string;
    description: string | null;
}

export const ConfigurationType = {
    Patching: "E",
    Structured: "S",
};

export const Fiber48 = { key: "48", description: "48 F", count: 48, code: "48" };
export const Fiber60 = { key: "60", description: "60 F", count: 60 };
export const Fiber72 = { key: "72", description: "72 F", count: 72 };
export const Fiber84 = { key: "84", description: "84 F", count: 84 };
export const Fiber96 = { key: "96", description: "96 F", count: 96 };
export const Fiber144 = { key: "144", description: "144 F", count: 144, code: "E4" };
export const Fiber216 = { key: "216", description: "216 F", count: 216 };
export const Fiber288 = { key: "288", description: "288 F", count: 288, code: "U8" };
export const Fiber576 = { key: "576", description: "576 F", count: 576, code: "AZ" };
export const Fiber864 = { key: "864", description: "864 F", count: 864, code: "CE" };
export const Fiber1728 = { key: "1728", description: "1728 F", count: 1728, code: "H2" };
export const Fiber3456 = { key: "3456", description: "3456 F", count: 3456 };

export const FiberCounts: IFiberCount[] = [
    Fiber48,
    Fiber60,
    Fiber72,
    Fiber84,
    Fiber96,
    Fiber144,
    Fiber288,
    Fiber576,
    Fiber864,
    Fiber1728,
];

export interface IStaggerValidationArgs {
    sLengthValue: number;
    groupCount: number;
    bLengthValue: number;
    bLengthRange: IRange;
    sLengthRange: IRange;
    reverseStaggering: boolean;
    unit: Unit;
}

export function validateStaggerValue(staggerArgs: IStaggerValidationArgs) {
    const { sLengthValue, groupCount, bLengthValue, bLengthRange, sLengthRange, reverseStaggering, unit } = staggerArgs;
    const staggerBounds = getStaggerRange(
        sLengthValue,
        groupCount,
        bLengthValue,
        bLengthRange,
        sLengthRange,
        reverseStaggering,
        unit
    );
    let valid = true;
    let sLengthErrorText = "";
    if (Number.isNaN(sLengthValue)) {
        valid = false;
        sLengthErrorText = i18n.t(LocalizationKeys.MissingValue);
    } else if (staggerBounds.minSLength > staggerBounds.maxSLength) {
        // max is based on group count and b length
        valid = false;
        sLengthErrorText = i18n.t(LocalizationKeys.InvalidEntry);
    } else if (staggerBounds.totalLegLengthValue > staggerBounds.maxLegLength) {
        sLengthErrorText =
            i18n.t(LocalizationKeys.ValueGreaterThan, {
                value: roundToDecimalBasedOnUnit(staggerBounds.maxSLength, unit),
            }) + unit;
        valid = false;
    } else if (sLengthValue < staggerBounds.minSLength && sLengthValue !== 0) {
        // non required field
        sLengthErrorText =
            i18n.t(LocalizationKeys.ValueLesserThan, {
                value: roundToDecimalBasedOnUnit(staggerBounds.minSLength, unit),
            }) + unit;
        valid = false;
    }

    return { valid, sLengthErrorText, ...staggerBounds };
}

export function getStaggerRange(
    value: number,
    groupCount: number,
    bLengthValue: number,
    bLengthRange: IRange,
    sLengthRange: IRange,
    reverseStaggering: boolean,
    unit: Unit
) {
    const totalLegLengthValue =
        !Number.isNaN(value) && !reverseStaggering ? value * Math.max(groupCount - 1, 1) + bLengthValue : bLengthValue;
    const maxLegLength = bLengthRange.max;
    const maxSLength = groupCount > 1 ? (maxLegLength - bLengthValue) / (groupCount - 1) : 0;
    const minSLength = groupCount > 1 ? sLengthRange.min : 0;
    const options = getStaggerOptions(minSLength, maxSLength, unit);
    return { totalLegLengthValue, maxLegLength, maxSLength, minSLength, options };
}
export function getStaggerOptions(minSLength: number, maxSLength: number, unit: Unit) {
    return StaggerLengthTypes.map((s) => convertTo(s.value, unit))
        .filter((s) => s.value >= minSLength && s.value <= maxSLength)
        .map((s) => roundToDecimalBasedOnUnit(s.value, s.unit));
}

function regexCompare(rawString: string, regex: string): boolean {
    return rawString.length === 0 || rawString.match(regex) ? true : false;
}

export function validateWholeNumber(rawString: string): boolean {
    return regexCompare(rawString, "^[0-9]{0,4}$");
}

export function findDivisors(value: number): number[] {
    const factors: number[] = [];
    for (let i = 1; i <= Math.sqrt(value); i++) {
        if (value % i === 0) {
            factors.push(i);
            if (i !== value / i) {
                factors.push(value / i);
            }
        }
    }

    return factors.sort((a, b) => a - b);
}

export function validateUnSignedDecimal(rawString: string, maxCharLength?: number): boolean {
    const charRestriction = maxCharLength ? `{0,${maxCharLength}}` : "*";
    const numericRegex = `^[0-9]${charRestriction}`;
    return regexCompare(rawString, `${numericRegex}$|(${numericRegex}[.]{1}[0-9]{0,4})$`);
}

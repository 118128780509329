import { createSlice } from "@reduxjs/toolkit";
import { initialNavigationBarState, initialNavigationBarContext } from "./navigation-bar";
import { setCurrentIndexAction, setDisabledAction } from "./navigation-bar.actions";
import { createContext } from "react";

const navigationBarSlice = createSlice({
    name: "navigationBar",
    initialState: initialNavigationBarState,
    reducers: {
        setCurrentIndex: setCurrentIndexAction,
        setDisabled: setDisabledAction,
    },
});

export const NavigationBarReducer = navigationBarSlice.reducer;
export const { setCurrentIndex, setDisabled } = navigationBarSlice.actions;

export const NavigationBarContext = createContext(initialNavigationBarContext);

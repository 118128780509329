import { ConnectorCasing } from "./connector-casing";
import { ConnectorPin } from "./connector-pin";
import { useSimplexTemplate } from "./fiber-mapping-connector-templates.hooks";
import { ConnectorTemplateProps } from "../../../../../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";

export const SimplexTemplate: React.FC<ConnectorTemplateProps> = (props) => {
    const { label, pin, connectorType: type, disabled, onClick } = useSimplexTemplate(props);
    return (
        <svg width="292" height="134" viewBox="0 0 340 109" onClick={onClick}>
            <defs>
                <filter id="simplexTemplateFilter" x="0" y="19" width="292" height="90" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" in="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="b" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="b" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g transform="translate(130 1)">
                {disabled ? null : (
                    <ConnectorCasing width={64} height={72} fill="#e5ebeb" filter="url(#simplexTemplateFilter)" />
                )}
                <text
                    transform="translate(28 17)"
                    stroke="rgba(0,0,0,0)"
                    fontSize="16px"
                    fontFamily="Inter"
                    fontWeight="700"
                >
                    <tspan x="0" y="0">
                        A
                    </tspan>
                </text>
                <text
                    transform="translate(40 115)"
                    stroke="rgba(0,0,0,0)"
                    fontSize="16px"
                    fontFamily="Inter"
                    fontWeight="700"
                    textAnchor="middle"
                >
                    <tspan x="0" y="0">
                        {label}
                    </tspan>
                </text>
                <g key={pin.index} transform={`translate(${pin.position.x}, ${pin.position.y})`}>
                    <ConnectorPin connectorType={type} side={pin.side} pinIndex={pin.index} disabled={pin.disabled} />
                </g>
                {disabled ? (
                    <ConnectorCasing
                        width={64}
                        height={72}
                        fill="#202020"
                        opacity={0.4}
                        filter="url(#simplexTemplateFilter)"
                    />
                ) : null}
            </g>
        </svg>
    );
};

import Overlay from "./overlay/overlay";
import PixiComponent from "./pixi/pixi-component";
import { OffScreen } from "./off-screen/off-screen";
import { SilicaThemeProvider } from "@corning-ctcm/silica-react";

import "../css/workspace.scss";

export const Workspace = () => {
    return (
        <div id="workspace" className="workspace">
            <SilicaThemeProvider>
                <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
                <PixiComponent />
                <Overlay />
                <OffScreen />
            </SilicaThemeProvider>
        </div>
    );
};
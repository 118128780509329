import i18n from "../../../../../locales/i18n";
import { LocalizationKeys } from "../../../../../locales/keys";
import { Identifiable } from "../../../../../models/services/services";

export interface IOrgLabelBundle {
    schemeBundle: LabelBundle;
}

export interface ILabelBundle {
    labelSchemes: LabelScheme[];
}

export interface ILabelScheme {
    bundleId: number;
    side: string;
    name: string;
    sequences: LabelSequence[];
}

export interface ILabelSequence {
    schemeId: number;
    prefix: string;
    suffix: string;
    name: string;
    symbol: string | number;
    increment: number;
    position: number;
    isEditable?: boolean;
}

export type LabelSequence = ILabelSequence & Identifiable;
export type LabelScheme = ILabelScheme & Identifiable;
export type LabelBundle = ILabelBundle & Identifiable;
export type OrgLabelBundle = IOrgLabelBundle & Identifiable;

export interface IAppLabelScheme {
    orgBundles: OrgLabelBundle[];
    appBundle: LabelBundle;
    userBundle: LabelBundle;
}

export const initialScheme: LabelScheme = {
    id: -1,
    bundleId: -1,
    side: "",
    name: "",
    sequences: [],
};

export const initialBundle: LabelBundle = {
    id: -1,
    labelSchemes: [],
};

export const customScheme: LabelScheme = {
    id: 9999,
    bundleId: 9999,
    side: "none",
    name: i18n.t(LocalizationKeys.Custom),
    sequences: [
        {
            schemeId: 9999,
            prefix: "",
            suffix: "",
            name: i18n.t(LocalizationKeys.CustomizedLabels),
            symbol: "",
            increment: 0,
            position: 0,
        },
    ],
};

export const initialAppLabelScheme: IAppLabelScheme = {
    orgBundles: [],
    appBundle: initialBundle,
    userBundle: initialBundle,
};

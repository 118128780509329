import React from "react";
import { GenericDialog } from "../../../../ui/dialog/generic-dialog";
import { useAnonymousUserWarningDialog } from "./anonymous-user-warning-dialog.hooks";

export const AnonymousUserWarningDialog = () => {
    const { display, title, message, alternateText, confirmText, closeDialog, showPrintDialog, showViewProductDialog } =
        useAnonymousUserWarningDialog();

    return (
        <GenericDialog
            id="anonymous-user-warning"
            display={display}
            title={title}
            closable={true}
            message={message}
            onClose={closeDialog}
            onCancel={showPrintDialog}
            cancelText={alternateText}
            onConfirm={showViewProductDialog}
            confirmText={confirmText}
        />
    );
};

import { createSelector } from "reselect";
import { viewportContextSelector } from "../../../pixi/viewport/viewport.selectors";
import { wizardDisplaySelector } from "../../../../store/overlay/wizard/wizard.selectors";
import { WorkspaceState } from "../../../workspace/workspace.reducers";

export const statusSelector = (state: WorkspaceState) => state.status;
export const currentStatusSelector = (state: WorkspaceState) => state.status.currentStatus;
export const texturesLoadedSelector = (state: WorkspaceState) => state.status.textureStatus === "ready";
export const isLockedSelector = (state: WorkspaceState) => state.status.isLocked;

export const disabledUISelector = createSelector(
    currentStatusSelector,
    viewportContextSelector,
    wizardDisplaySelector,
    (currentStatus, { context }, wizardDisplay) =>
        currentStatus === "initial load" ||
        currentStatus === "busy" ||
        currentStatus === "loading" ||
        currentStatus === "printing" ||
        context === "editing" ||
        wizardDisplay
);

import { createContext } from "react";
import { Rectangle } from "../interaction";

export interface IBoundingBoxProps {
    buffer: Rectangle;
}

export interface IBoundingBoxContext {
    bounds: Rectangle;
    isMouseOver: boolean;
}

const initialBounds: IBoundingBoxContext = {
    bounds: { x: 0, y: 0, width: 0, height: 0 },
    isMouseOver: false,
};

export const BoundingBoxContext = createContext(initialBounds);

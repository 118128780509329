import { createSelector } from "reselect";
import { WorkspaceState } from "../workspace/workspace.reducers";

export const overlaySelector = (state: WorkspaceState) => state.overlay;
export const showConnectorAssignmentSelector = (state: WorkspaceState) => state.overlay.showConnectorAssignment;
export const showFiberMappingSelector = (state: WorkspaceState) => state.overlay.showFiberMapping;
export const showPolarityConfigSelector = (state: WorkspaceState) => state.overlay.showPolarity;
export const printSettingsSelector = (state: WorkspaceState) => state.overlay.printSettings;
export const showThirdPartyCopyrightSelector = (state: WorkspaceState) => state.overlay.showThirdPartyCopyright;
export const showAboutApplicationSelector = (state: WorkspaceState) => state.overlay.showAboutApplication;
export const showCablePresetSnackbarSelector = (state: WorkspaceState) => state.overlay.showCablePresetSnackbar;
export const showConnectorReportSelector = (state: WorkspaceState) => state.overlay.showConnectorReport;
export const showMoreMenuSelector = (state: WorkspaceState) => state.overlay.showMoreMenu;
export const showIdleSelector = (state: WorkspaceState) => state.overlay.showIdle;
export const showTimeoutSelector = (state: WorkspaceState) => state.overlay.showTimeout;
export const showViewProductSelector = (state: WorkspaceState) => state.overlay.showViewProduct;
export const showAnonymousUserWarningSelector = (state: WorkspaceState) => state.overlay.showAnonymousUserWarning;
export const showLabelSchemeSelector = (state: WorkspaceState) => state.overlay.showLabelScheme;
export const showTriggerManagementSelector = (state: WorkspaceState) => state.overlay.showTriggerManagement;
export const showShellEditSelector = (state: WorkspaceState) => state.overlay.showShellEdit;
export const showAssemblyTypeEditSelector = (state: WorkspaceState) => state.overlay.showAssemblyTypeEdit;
export const showEditTolerancesSelector = (state: WorkspaceState) => state.overlay.showEditTolerances;
export const showCollapseOptionsSelector = (state: WorkspaceState) => state.overlay.showCollapseOptions;
export const showSettingsSelector = (state: WorkspaceState) => state.overlay.showSettings;

export const showPrintDialogSelector = (state: WorkspaceState) => state.overlay.printSettings.showPrintDialog;

export const isPolarityModeSelector = (state: WorkspaceState) => state.overlay.showConnectorAssignment || state.overlay.showFiberMapping || state.overlay.showPolarity;

export const isOverlayShownSelector = createSelector(
    showPrintDialogSelector,
    showConnectorReportSelector,
    isPolarityModeSelector,
    (showPrint, showConnector, isPolarityMode) => showPrint || showConnector || isPolarityMode
);

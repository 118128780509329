import { IconButtonProps } from "@corning-ctcm/silica-react";
import { initialProductLabel, LabelsSectionProps, ProductLabel } from "../../../../../models/overlay/reports/report-settings/labels-section";
import { isLockedSelector } from "../../../../../store/overlay/header/status-icon/status-icon.selectors";
import { useSelector } from "react-redux";

export const useLabelsSection = ({ labels, setLabels }: LabelsSectionProps) => {
    const locked = useSelector(isLockedSelector);

    const setLabel = (index: number, newLabel: ProductLabel) => {
        const newLabels = [...labels];
        newLabels[index] = newLabel;
        setLabels(newLabels);
    };

    const addIcon: Omit<IconButtonProps, "icon"> = {
        id: "add-icon",
        className: "add-icon",
        palette: "primary",
        mode: "main",
        placement: "bottom",
        disabled: labels.length >= 5 || locked,
        onClick: () => {
            const newLabels = [...labels];
            newLabels.push({ ...initialProductLabel });
            setLabels(newLabels);
        },
    };

    const removeIcon: Omit<IconButtonProps, "icon"> = {
        id: "remove-icon",
        className: "remove-icon",
        palette: "primary",
        mode: "main",
        placement: "bottom",
        disabled: labels.length <= 1 || locked,
        onClick: () => {
            const newLabels = [...labels];
            newLabels.pop();
            setLabels(newLabels);
        },
    };

    return { setLabel, addIcon, removeIcon };
};

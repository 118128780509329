import { DropData, IBuildData, initialBuildData } from "../../../store/workspace/build/build";
import { currentBuildSelector } from "../../../store/workspace/root.selectors";
import {
    currentMaskLengthsSelector,
    currentMeasurementsPositionSelector,
    currentTolerancesSelector,
    dropsSelector,
    getDrops,
    getReportDrop,
    nbDropsSelector,
} from "../../../store/workspace/build.selectors";
import { CableProps } from "../../../models/pixi/build/cable";
import { DropProps, RABReportContainer } from "../../../models/pixi/build/build";
import { IPoint } from "../../../models/pixi/pixi";
import { reportDocumentSelector } from "../../../store/workspace/document/document.selectors";
import {
    unitsOfMeasureContainerPrimaryUnitSelector,
    unitsOfMeasureContainerUnitSelector,
} from "../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { useSelector } from "react-redux";
import { useWindowSize } from "../pixi-component.hooks";
import { texturesLoadedSelector } from "../../../store/overlay/header/status-icon/status-icon.selectors";

export const useDocumentGraphics = () => {
    const display = useSelector(texturesLoadedSelector);
    const currentBuild = useSelector(currentBuildSelector);

    const overviewGraphics: CableProps = useOverviewGraphics();
    const dropGraphics: DropProps[] = useDropGraphics();
    const buildContext = currentBuild ?? initialBuildData;

    return {
        display,
        buildContext,
        overviewGraphics,
        dropGraphics,
    };
};

export const useOverviewGraphics = () => {
    const { overview } = useSelector(reportDocumentSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const primaryUnit = useSelector(unitsOfMeasureContainerPrimaryUnitSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const windowSize = useWindowSize();

    const build: IBuildData =
        overview && currentBuild
            ? { ...currentBuild, drops: overview.drops ?? [], availability: overview.availability }
            : { ...initialBuildData };

    const cablePosition: IPoint = { x: 210, y: 200 };
    const cableOffset: IPoint = { x: windowSize.width * 2, y: windowSize.height };
    const drops = getDrops(build, primaryUnit, unit, cablePosition, cableOffset);
    for (const d of drops) {
        d.context.isReportOverviewDrop = true;
    }

    return {
        name: RABReportContainer,
        display: !!overview,
        x: cablePosition.x,
        y: cablePosition.y,
        drops,
    };
};

export const useDropGraphics = () => {
    const { drops: documentDrops } = useSelector(reportDocumentSelector);
    const { feeder } = useSelector(reportDocumentSelector);
    const { legLengthTolerance } = useSelector(currentTolerancesSelector);
    const measurementsPosition = useSelector(currentMeasurementsPositionSelector);
    const currentDrops = useSelector(dropsSelector);
    const primaryUnit = useSelector(unitsOfMeasureContainerPrimaryUnitSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const nbDrops = useSelector(nbDropsSelector);
    const maskLengths = useSelector(currentMaskLengthsSelector);
    const windowSize = useWindowSize();

    const source: DropData = feeder ? { ...feeder } : { ...currentDrops[0] };
    const distribution: DropData[] = documentDrops
        ? documentDrops.map((d) => ({ ...d.drop }))
        : currentDrops.filter((d) => d.side === "distribution");

    const point: IPoint = { x: windowSize.width * 2, y: windowSize.height * 2 };
    const drops: DropData[] = [source, ...distribution];
    const dropsProps: DropProps[] = drops.map((d, i, a) => {
        const nextIndex = i + 1;
        const nextDrop = nextIndex < drops.length ? a[nextIndex] : undefined;
        return getReportDrop(
            d,
            primaryUnit,
            unit,
            point,
            measurementsPosition,
            nbDrops,
            maskLengths,
            nextDrop,
            legLengthTolerance
        );
    });

    return dropsProps;
};

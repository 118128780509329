import { Graphics } from "pixi.js";
import { useCallback, useContext } from "react";
import { DropContext } from "../../../../store/workspace/build/build";
import { IPoint } from "../../../../models/pixi/pixi";
import { ILengthProps } from "../../../../models/pixi/decorators/dimension-lines/length";
import { ILineMarkerProps, Side, Sides } from "../../../../models/pixi/decorators/dimension-lines/line-marker";
import {
    DIMENSION_LINE_DASH_SIZE,
    DimensionLineContext,
    IHorizontalDimensionLine,
    IPath,
    IVerticalDimensionLine,
} from "../../../../models/pixi/decorators/dimension-lines/dimension-lines";
import { ALengthContainer, LabelLengthContainer, LegLengthContainer } from "../../../../models/pixi/build/build";
import { ITextPath } from "../../../../models/pixi/decorators/text";
import { useDispatch, useSelector } from "react-redux";
import { dropsSelector } from "../../../../store/workspace/build.selectors";
import {
    IUnitOfMeasure,
    Unit,
    Units,
    convertTo,
    decimalPlacesBasedOnUnit,
    getDecimalPlaces,
    roundToDecimalPoint,
} from "../../../../models/overlay/header/units-of-measure";
import { setNotification } from "../../../../store/overlay/notification/notification.reducers";
import { AlertPalettes } from "@corning-ctcm/silica-react";
import { unitsOfMeasureContainerUnitSelector } from "../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { useWebSave } from "../../../overlay/header/status/status.hooks";
import { currentBuildSelector } from "../../../../store/workspace/root.selectors";
import { LocalizationKeys } from "../../../../locales/keys";
import { validateUnSignedDecimal, Stagger0 } from "../../../../store/overlay/wizard/wizard";
import {
    distributionOptionsSelector,
    feederOptionsSelector,
} from "../../../../store/workspace/boundaries/boundaries.selectors";
import { IDrop } from "../../../../store/workspace/build/drop";
import { IConnectorGroupData } from "../../../../store/workspace/build/connector/connector";
import { IRange } from "../../../../store/workspace/boundaries/boundaries";
import { t } from "i18next";
import { updateDrops } from "../../../../store/workspace/build/build.reducers";

export const useDimensionLines = () => {
    const { index, dimensionLines, containerName } = useContext(DropContext);
    const { thickness, color: markerColor } = useContext(DimensionLineContext);
    const { lengthA, lengthB, staggerMarkerPaths, staggerLinePaths, lengthLabel } = dimensionLines;
    const aLengthProps = useALengthProps(lengthA);
    const bLengthProps = useBLengthProps(lengthB);
    const staggerLines: ILineMarkerProps[] = useStaggerLinesProps(staggerLinePaths);

    const staggerMarkers: ILineMarkerProps[] =
        staggerMarkerPaths && staggerMarkerPaths.length > 0
            ? staggerMarkerPaths.map((m, i) => ({
                  key: `stagger-marker-${i}`,
                  path: m,
                  color: markerColor,
                  thickness,
                  orientation: "vertical",
                  direction: "end",
                  textScale: m.text?.scale,
                  hideUnits: m.text?.hideUnits,
              }))
            : [];

    const startLabel: ILineMarkerProps | undefined = lengthLabel?.topExtensionLine
        ? {
              path: lengthLabel.topExtensionLine,
              orientation: "horizontal",
              thickness,
              color: markerColor,
          }
        : undefined;

    const label: ILineMarkerProps | undefined = lengthLabel?.dimensionLine
        ? {
              path: lengthLabel.dimensionLine,
              orientation: "vertical",
              direction: "bidirectional",
              thickness,
              color: markerColor,
          }
        : undefined;

    const endLabel: ILineMarkerProps | undefined = lengthLabel?.bottomExtensionLine
        ? {
              path: lengthLabel.bottomExtensionLine,
              orientation: "horizontal",
              thickness,
              color: markerColor,
          }
        : undefined;

    const labelLengthProps: ILengthProps = {
        name: `${containerName}_${LabelLengthContainer}`,
        position: index,
        start: startLabel,
        line: label,
        end: endLabel,
    };
    return { aLengthProps, bLengthProps, staggerLines, staggerMarkers, labelLengthProps };
};

const useALengthProps = (lengthA: IHorizontalDimensionLine | undefined): ILengthProps | undefined => {
    const { thickness, color: markerColor } = useContext(DimensionLineContext);
    const { index, nbDrops, containerName } = useContext(DropContext);
    const { aLengthRange, mainFurcationToEndFurcationRange } = useSelector(distributionOptionsSelector);
    const { saveBuild } = useWebSave();
    const currentBuild = useSelector(currentBuildSelector);
    const currentUnit = useSelector(unitsOfMeasureContainerUnitSelector);
    const currentDrops = useSelector(dropsSelector);

    const dropIndex = index + 1;
    const currentDrop = dropIndex < currentDrops.length ? currentDrops[dropIndex] : undefined;

    const dispatch = useDispatch();

    const start: ILineMarkerProps | undefined = lengthA?.leftExtensionLine
        ? {
              path: lengthA.leftExtensionLine,
              orientation: "vertical",
              thickness,
              color: markerColor,
          }
        : undefined;

    const updateALength = useCallback(
        async (value: string) => {
            if (!currentBuild || !currentDrop) return;

            const drops = [...currentDrops];
            drops[dropIndex] = {
                ...currentDrop,
                lengthA: { ...currentDrop.lengthA, value: Number(value), unit: currentUnit },
            };

            await saveBuild({ ...currentBuild, drops });
            dispatch(updateDrops(drops));
        },
        [currentBuild, currentDrop, currentDrops, currentUnit, dropIndex, dispatch, saveBuild]
    );

    const onConfirm = useCallback(
        async (value: string) => {
            const sourceALength = convertTo(currentDrops[0].lengthA ?? Stagger0.value, currentUnit).value;
            const min = aLengthRange.min;
            const max =
                nbDrops > 1
                    ? Math.min(aLengthRange.max, (mainFurcationToEndFurcationRange.max - sourceALength) / (nbDrops - 1))
                    : aLengthRange.max;
            const message: string = validate(value, currentUnit, { min, max });
            if (message.length > 0) dispatch(setNotification({ palette: AlertPalettes.error, message }));
            else await updateALength(value);
        },
        [aLengthRange, currentUnit, currentDrops, mainFurcationToEndFurcationRange, nbDrops, dispatch, updateALength]
    );

    let line: ILineMarkerProps | undefined = undefined;
    if (lengthA?.dimensionLine) {
        const text: ITextPath | undefined = lengthA.dimensionLine.text?.inputProps
            ? {
                  ...lengthA.dimensionLine.text,
                  inputProps: {
                      ...lengthA.dimensionLine.text?.inputProps,
                      onConfirm,
                  },
              }
            : lengthA.dimensionLine.text;
        const path: IPath = { ...lengthA.dimensionLine, text };
        line = {
            path,
            orientation: "horizontal",
            direction: "bidirectional",
            thickness,
            color: markerColor,
            hideUnits: lengthA.dimensionLine.text?.hideUnits,
            maskLengths: lengthA.dimensionLine.text?.maskLengths,
        };
    }

    const end: ILineMarkerProps | undefined = lengthA?.rightExtensionLine
        ? {
              path: lengthA.rightExtensionLine,
              orientation: "vertical",
              thickness,
              color: markerColor,
          }
        : undefined;

    return nbDrops > 2
        ? {
              name: `${containerName}_${ALengthContainer}`,
              position: index,
              start,
              line,
              end,
          }
        : undefined;
};

const useBLengthProps = (lengthB: IVerticalDimensionLine | undefined): ILengthProps => {
    const { thickness, color: markerColor } = useContext(DimensionLineContext);
    const { side, index: dropIndex, containerName } = useContext(DropContext);
    const { saveBuild } = useWebSave();
    const currentBuild = useSelector(currentBuildSelector);
    const currentUnit = useSelector(unitsOfMeasureContainerUnitSelector);
    const currentDrops = useSelector(dropsSelector);
    const feederOptions = useSelector(feederOptionsSelector);
    const distributionOptions = useSelector(distributionOptionsSelector);
    const legRange = side === "distribution" ? distributionOptions.legRange : feederOptions.legRange;
    const currentDrop = currentDrops[dropIndex];

    const dispatch = useDispatch();

    const updateBLength = useCallback(
        async (index: number, value: string) => {
            if (!currentBuild) return;

            const group = currentDrop.groups[index];
            const hasStagger = !!(group.stagger && group.stagger?.value > 0);
            const convertedValue = convertTo({ value: Number(value), unit: currentUnit }, Units.UoMInches);
            let updatedGroups: IConnectorGroupData[] = [...currentDrop.groups];
            const lengthB: IUnitOfMeasure = group.lengthB
                ? { id: group.lengthB.id, ...convertedValue }
                : convertedValue;

            if (hasStagger) {
                updatedGroups[index] = { ...updatedGroups[index], lengthB };
            } else {
                updatedGroups = updatedGroups.map((g) => ({ ...g, lengthB }));
            }

            const drops: IDrop[] = [...currentDrops];
            drops[dropIndex] = {
                ...currentDrop,
                lengthB,
                groups: updatedGroups,
                customBLength: hasStagger,
            };

            await saveBuild({ ...currentBuild, drops });
            dispatch(updateDrops(drops));
        },
        [currentBuild, currentDrop, currentUnit, dropIndex, currentDrops, dispatch, saveBuild]
    );

    const onConfirm = useCallback(
        async (index: number, value: string) => {
            const range = getUpdatedLegRange(legRange, currentDrop, index);
            const message = validate(value, currentUnit, range);

            if (message.length > 0) dispatch(setNotification({ palette: AlertPalettes.error, message }));
            else await updateBLength(index, value);
        },
        [currentUnit, currentDrop, legRange, dispatch, updateBLength]
    );

    const start: ILineMarkerProps | undefined = lengthB?.topExtensionLine
        ? {
              path: lengthB.topExtensionLine,
              orientation: "horizontal",
              thickness,
              color: markerColor,
          }
        : undefined;

    const line: ILineMarkerProps | undefined = lengthB?.dimensionLine
        ? {
              path: lengthB.dimensionLine,
              orientation: "vertical",
              direction: "bidirectional",
              thickness,
              color: markerColor,
          }
        : undefined;

    let end: ILineMarkerProps | undefined = undefined;
    if (lengthB?.bottomExtensionLine) {
        const index = lengthB.bottomExtensionLine.text?.inputProps?.index ?? 0;
        const text: ITextPath | undefined = lengthB.bottomExtensionLine.text?.inputProps
            ? {
                  ...lengthB.bottomExtensionLine.text,
                  inputProps: {
                      ...lengthB.bottomExtensionLine.text.inputProps,
                      onConfirm: (value) => onConfirm(index, value),
                  },
              }
            : lengthB.bottomExtensionLine.text;
        const path: IPath = { ...lengthB.bottomExtensionLine, text };
        end = {
            path,
            orientation: "horizontal",
            thickness,
            color: markerColor,
        };
    }

    const mesh: ILineMarkerProps | undefined = lengthB?.meshDimensionLine
        ? {
              path: lengthB.meshDimensionLine,
              orientation: "vertical",
              direction: "end",
              thickness,
              color: markerColor,
          }
        : undefined;

    const meshExtension: ILineMarkerProps | undefined = lengthB?.meshExtensionLine
        ? {
              path: lengthB.meshExtensionLine,
              orientation: "horizontal",
              thickness,
              color: markerColor,
          }
        : undefined;

    return {
        name: `${containerName}_${LegLengthContainer}`,
        position: dropIndex,
        start: start,
        line: line,
        end: end,
        mesh: {
            marker: mesh,
            extension: meshExtension,
        },
    };
};

const useStaggerLinesProps = (staggerLinePaths: IPath[] | undefined): ILineMarkerProps[] => {
    const { thickness, color: markerColor } = useContext(DimensionLineContext);
    const { side, index: dropIndex } = useContext(DropContext);
    const { saveBuild } = useWebSave();
    const currentBuild = useSelector(currentBuildSelector);
    const currentDrops = useSelector(dropsSelector);
    const currentUnit = useSelector(unitsOfMeasureContainerUnitSelector);
    const feederOptions = useSelector(feederOptionsSelector);
    const distributionOptions = useSelector(distributionOptionsSelector);
    const currentDrop = currentDrops[dropIndex];
    const legRange = side === "distribution" ? distributionOptions.legRange : feederOptions.legRange;

    const dispatch = useDispatch();

    const updateStaggerLine = useCallback(
        async (index: number, value: string) => {
            if (!currentBuild) return;

            const group = currentDrop.groups[index];
            const convertedValue = convertTo({ value: Number(value), unit: currentUnit }, Units.UoMInches);
            const updatedGroups: IConnectorGroupData[] = [...currentDrop.groups];
            updatedGroups[index] = {
                ...updatedGroups[index],
                lengthB: group.lengthB ? { id: group.lengthB.id, ...convertedValue } : convertedValue,
            };

            const drops: IDrop[] = [...currentDrops];
            drops[dropIndex] = {
                ...currentDrop,
                groups: updatedGroups,
                customBLength: true,
            };

            await saveBuild({ ...currentBuild, drops });
            dispatch(updateDrops(drops));
        },
        [currentBuild, currentDrop, currentDrops, currentUnit, dropIndex, dispatch, saveBuild]
    );

    const onConfirm = useCallback(
        async (index: number, value: string) => {
            const range = getUpdatedLegRange(legRange, currentDrop, index);
            const message = validate(value, currentUnit, range);

            if (message.length > 0) dispatch(setNotification({ palette: AlertPalettes.error, message }));
            else await updateStaggerLine(index, value);
        },
        [currentUnit, currentDrop, legRange, dispatch, updateStaggerLine]
    );

    const staggerLines: ILineMarkerProps[] =
        staggerLinePaths && staggerLinePaths.length > 0
            ? staggerLinePaths.map((m, i) => {
                  const key = `stagger-marker-${dropIndex}-${i}`;
                  const index = m.text?.inputProps?.index ?? 0;
                  const text: ITextPath | undefined = m.text?.inputProps
                      ? {
                            ...m.text,
                            inputProps: {
                                ...m.text.inputProps,
                                onConfirm: (value) => onConfirm(index, value),
                            },
                        }
                      : m.text;

                  return {
                      key,
                      path: { ...m, text },
                      color: markerColor,
                      thickness,
                      orientation: "horizontal",
                      textScale: m.text?.scale,
                      hideUnits: m.text?.hideUnits,
                  };
              })
            : [];

    return staggerLines;
};

const validate = (value: string, unit: Unit, range: IRange) => {
    let message = "";
    const maxCharLength = range.max.toString().length;
    const maxDecimalPlaces = decimalPlacesBasedOnUnit(unit);
    const invalidValue = getDecimalPlaces(value) > maxDecimalPlaces || !validateUnSignedDecimal(value, maxCharLength);
    const lengthValue = Number(value);
    message = invalidValue ? t(LocalizationKeys.InvalidEntry) : message;
    message = value.length === 0 ? t(LocalizationKeys.MissingValue) : message;
    message =
        lengthValue < range.min
            ? `${t(LocalizationKeys.ValueLesserThan, {
                  value: roundToDecimalPoint(range.min, 4, false),
              })} ${unit}`
            : message;
    message =
        lengthValue > range.max
            ? `${t(LocalizationKeys.ValueGreaterThan, {
                  value: roundToDecimalPoint(range.max, 4, false),
              })} ${unit}`
            : message;

    return message;
};

export const drawLine = (g: Graphics, path: IPoint[], thickness: number, color: number, dashed?: boolean) => {
    const [first, second] = path;

    g.lineStyle(thickness, color);
    g.moveTo(first.x, first.y);

    const dashedDirection = first.x === second.x ? 1 : first.y === second.y ? 0 : -1;
    if (dashed && dashedDirection >= 0) {
        // Pixi does not provided "dashed" line. Only draws dashes on horizontal and vertical lines. If diagonal dashed lines are needed, this needs to be modified. The following library seems to implement dashed lines for more complex shapes https://github.com/davidfig/pixi-dashed-line
        const dashedPosition = [first.x, first.y];
        const dashedEndPosition = [second.x, second.y];
        dashedPosition[dashedDirection] += DIMENSION_LINE_DASH_SIZE;
        while (dashedPosition[dashedDirection] < dashedEndPosition[dashedDirection]) {
            g.lineTo(dashedPosition[0], dashedPosition[1]);
            dashedPosition[dashedDirection] += DIMENSION_LINE_DASH_SIZE / 2;
            g.moveTo(dashedPosition[0], dashedPosition[1]);
            dashedPosition[dashedDirection] += DIMENSION_LINE_DASH_SIZE;
        }
    } else {
        g.lineTo(second.x, second.y);
    }
};

export const drawArrow = (g: Graphics, point: IPoint, side: Side, thickness: number, color: number) => {
    const offX = 6;
    const offY = 3;
    const { x, y } = point;
    let start = { x, y };
    let end = { x, y };

    calculateArrowPoints(side, start, offY, end, offX);

    g.lineStyle(thickness, color);
    g.moveTo(start.x, start.y);
    g.lineTo(x, y);
    g.lineTo(end.x, end.y);
};

const calculateArrowPoints = (side: string, start: IPoint, offY: number, end: IPoint, offX: number) => {
    if (side === Sides.Left || side === Sides.Right) {
        start.y += offY;
        end.y -= offY;
        if (side === Sides.Right) {
            start.x -= offX;
            end.x -= offX;
        } else {
            start.x += offX;
            end.x += offX;
        }
    } else if (side === Sides.Top || side === Sides.Bottom) {
        start.x += offY;
        end.x -= offY;
        if (side === Sides.Bottom) {
            start.y -= offX;
            end.y -= offX;
        } else {
            start.y += offX;
            end.y += offX;
        }
    }
};

const getUpdatedLegRange = (legRange: IRange, drop: IDrop, index: number) => {
    let range = legRange;
    const stagger = drop.groups[index].stagger?.value ?? 0;
    if (drop.mesh && drop.meshOffset) {
        const minExpectedValue = drop.reverseStaggering
            ? drop.meshOffset.value + stagger + 1
            : drop.meshOffset.value + 1;
        range.min = Math.max(legRange.min, minExpectedValue);
    }
    return range;
};

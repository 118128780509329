import { useApp } from "@pixi/react";
import { Viewport as PixiViewport } from "pixi-viewport";
import * as Pixi from "pixi.js";
import { Container } from "pixi.js";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageResizeArgs } from "../../../models/overlay/footer/page-resize";
import { SOURCE_FURCATION_CONTAINER_NAME } from "../../../models/pixi/build/build";
import { IPoint } from "../../../models/pixi/pixi";
import { clearSelection } from "../../../store/overlay/footer/toolbar/toolbar.reducers";
import {
    selectedPositionSelector,
    toolbarSelectionSelector,
} from "../../../store/overlay/footer/toolbar/toolbar.selectors";
import { setUnitsOfMeasureContainerDisplay } from "../../../store/overlay/header/units-of-measure-container/units-of-measure-container.reducers";
import { unitsOfMeasureContainerDisplaySelector } from "../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { wizardDisplaySelector } from "../../../store/overlay/wizard/wizard.selectors";
import { SOURCE_PADDING, VIEWPORT_PADDING, ViewportStatus } from "../../../store/pixi/viewport/viewport";
import { setIncrement, setIsDragging, setPosition, setScale } from "../../../store/pixi/viewport/viewport.reducers";
import {
    viewportContextSelector,
    viewportIncrementSelector,
    viewportSelector,
} from "../../../store/pixi/viewport/viewport.selectors";
import { getChildrenRecursive } from "../manager/storage-manager/storage-manager";
import { useWindowSize } from "../pixi-component.hooks";

export const useViewport = () => {
    const { context } = useSelector(viewportSelector);
    const app = useApp();
    const viewportRef = useRef<PixiViewport>(initializeViewport(app));
    const displayWizard = useSelector(wizardDisplaySelector);
    viewportRef.current.pause = context === "editing" && !displayWizard;

    const viewport = viewportRef.current;
    useWindowResize(viewport);
    useWindowKeyDown();
    usePageResize(viewport);
    useViewportClick();
    useViewportZoom(viewport);
    useDrag(viewport);

    return { viewport, displayObjects: getDisplayObjects(viewport) };
};

const useResetSelection = () => {
    const dispatch = useDispatch();
    const { isDragging, context } = useSelector(viewportContextSelector);
    const { selected, hovered } = useSelector(toolbarSelectionSelector);
    const unitDisplay = useSelector(unitsOfMeasureContainerDisplaySelector);

    const resetSelection = useCallback(() => {
        if (unitDisplay) {
            dispatch(setUnitsOfMeasureContainerDisplay(false));
        }
        if (
            !isDragging &&
            hovered === -1 &&
            selected !== -1 &&
            context !== ViewportStatus.Editing &&
            context !== ViewportStatus.ConnectorReport
        ) {
            dispatch(clearSelection());
        }
    }, [dispatch, hovered, isDragging, selected, unitDisplay, context]);
    return resetSelection;
};

const useEmergencyClear = () => {
    const dispatch = useDispatch();
    const selected = useSelector(selectedPositionSelector);
    const { context } = useSelector(viewportContextSelector);

    const emergencyClear = useCallback(() => {
        if (selected !== -1 && context !== ViewportStatus.Editing && context !== ViewportStatus.ConnectorReport) {
            dispatch(clearSelection());
        }
    }, [selected, context, dispatch]);

    return emergencyClear;
};

const useWindowResize = (viewport: PixiViewport) => {
    const application = useApp();
    const windowSize = useWindowSize();
    useEffect(() => {
        application.renderer.resize(windowSize.width, windowSize.height);
        viewport.resize(windowSize.width, windowSize.height);
    }, [windowSize, application, viewport]);
};

const useWindowKeyDown = () => {
    const emergencyClear = useEmergencyClear();

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                emergencyClear();
            }
        };

        window.addEventListener("keydown", onKeyDown);
        return () => window.removeEventListener("keydown", onKeyDown);
    }, [emergencyClear]);
};

const usePageResize = (viewport: PixiViewport) => {
    const { resize, minScale } = useSelector(viewportSelector);
    const storeDispatch = useDispatch();

    useEffect(() => {
        const source = viewport.getChildByName(SOURCE_FURCATION_CONTAINER_NAME, true) ?? undefined;
        const newScale = getScaleAfterResize(resize, minScale, viewport, source);
        viewport.scale.set(newScale.x, newScale.y);
        const newPosition = getPositionAfterResize(resize, viewport, source);
        viewport.position.set(newPosition.x, newPosition.y);
        storeDispatch(setScale(newScale)); // Update viewport scale in state to force update display objects
        storeDispatch(setPosition(newPosition)); // Update viewport position in state to force update display objects
    }, [minScale, resize, storeDispatch, viewport]);

    return viewport;
};

const useViewportClick = () => {
    const app = useApp();
    const resetSelection = useResetSelection();
    const emergencyClear = useEmergencyClear();

    const onClick = useCallback(
        (e: Pixi.FederatedPointerEvent) => {
            resetSelection();
            if (e.detail === 2) {
                emergencyClear();
            }
        },
        [resetSelection, emergencyClear]
    );

    useEffect(() => {
        app.stage.addListener("click", onClick);
        return () => {
            app?.stage?.removeListener("click", onClick);
        };
    }, [app, onClick]);
};

const useViewportZoom = (viewport: PixiViewport) => {
    const increment = useSelector(viewportIncrementSelector);
    const storeDispatch = useDispatch();

    useEffect(() => {
        const onWheel = (e: WheelEvent) => {
            e.preventDefault();
            if (e.deltaY) {
                viewport.wheel({
                    percent: e.deltaY > 0 ? -0.1 : 0.1,
                    smooth: 5,
                    wheelZoom: true,
                });
            }
        };

        const onZoomEnd = () => {
            storeDispatch(setScale({ x: viewport.scale.x, y: viewport.scale.y }));
        };

        viewport.addListener("zoomed-end", onZoomEnd);
        viewport.addEventListener("wheel", onWheel);

        return () => {
            viewport.removeListener("zoomed-end", onZoomEnd);
            viewport.removeEventListener("wheel", onWheel);
        };
    }, [viewport, storeDispatch]);

    useEffect(() => {
        if (increment !== 0) {
            const width = viewport.worldScreenWidth;
            const scale = 1 - increment;
            viewport.snapZoom({
                width: scale * width,
                time: 500,
                removeOnInterrupt: true,
                removeOnComplete: true,
            });
            storeDispatch(setIncrement(0));
        }
    }, [increment, viewport, storeDispatch]);
};

const useDrag = (viewport: PixiViewport) => {
    const storeDispatch = useDispatch();

    const dragStart = useCallback(() => {
        toggleOverlayPointerEvents(false);
        storeDispatch(setIsDragging(true));
    }, [storeDispatch]);

    const dragEnd = useCallback(
        (e: any) => {
            toggleOverlayPointerEvents(true);
            storeDispatch(setPosition({ x: e.viewport.x, y: e.viewport.y })); // We need to update the viewport position in the state, otherwise interactions that rely only on the state's values (wheelZoom, zoomTool, pageResize) won't have the updated viewport position
            storeDispatch(setIsDragging(false));
        },
        [storeDispatch]
    );

    useEffect(() => {
        viewport.on("drag-start", dragStart);
        viewport.on("drag-end", dragEnd);

        return () => {
            viewport.removeListener("drag-start", dragStart);
            viewport.removeListener("drag-end", dragEnd);
        };
    }, [viewport, dragStart, dragEnd]);
};

const initializeViewport = (app: Pixi.Application) => {
    app.stage.eventMode = "static";
    app.stage.hitArea = app.screen;

    const events = new Pixi.EventSystem(app.renderer);
    events.domElement = app.renderer.view as any;
    const viewport = new PixiViewport({
        events,
    });

    viewport
        .drag({
            wheel: false,
        })
        .pinch();

    return viewport;
};

const toggleOverlayPointerEvents = (enabled: boolean) => {
    const overlayComponents = document.getElementsByClassName("toggle-pointer-events") as HTMLCollectionOf<HTMLElement>;
    const pointerEventsValue = enabled ? "auto" : "none";
    for (let i = 0; i < overlayComponents.length; i++) {
        overlayComponents[i].style.pointerEvents = pointerEventsValue;
    }
};

const getDisplayObjects = (viewport: PixiViewport): Container[] => {
    return viewport.children.map((c) => c as Container).flatMap((c) => getChildrenRecursive(c));
};

const findFitHeight = (height: number, padding: number = 0): number => {
    return (window.innerHeight - padding) / height;
};

const findFitWidth = (width: number, padding: number = 0): number => {
    return (window.innerWidth - padding) / width;
};

const findFit = (width: number, height: number, padding: number = 0): number => {
    return height / width > (window.innerHeight - padding) / (window.innerWidth - padding)
        ? findFitHeight(height, padding)
        : findFitWidth(width, padding);
};

const getScaleAfterResize = (
    args: PageResizeArgs,
    minZoom: number,
    viewport: PixiViewport,
    source?: Pixi.DisplayObject
): IPoint => {
    const viewportBounds = viewport.getLocalBounds();
    const sourceBounds = source?.getLocalBounds() ?? viewportBounds;

    const width = args.value === "fit-to-source" ? sourceBounds.width : viewportBounds.width;
    const height = args.value === "fit-to-source" ? sourceBounds.height : viewportBounds.height;

    const padding = args.value === "fit-to-source" ? SOURCE_PADDING : VIEWPORT_PADDING;
    const zoom = args.value === "fit-to-height" ? findFitHeight(height, padding) : findFit(width, height, padding);

    const clampedZoom = Math.max(zoom, minZoom);
    return { x: clampedZoom, y: clampedZoom };
};

const getPositionAfterResize = (args: PageResizeArgs, viewport: PixiViewport, source?: Pixi.DisplayObject): IPoint => {
    const viewportBounds = viewport.getBounds();
    const sourceBounds = source?.getBounds() ?? viewportBounds;

    const offset = {
        x: viewportBounds.x - viewport.x,
        y: viewportBounds.y - viewport.y,
    };

    const sourceOffset = {
        x: sourceBounds.x - viewport.x,
        y: sourceBounds.y - viewport.y,
    };

    const newPosition: IPoint = { x: 1, y: 1 };

    switch (args.value) {
        case "fit-to-page":
            newPosition.x = (window.innerWidth - viewportBounds.width) * 0.5 - offset.x;
            newPosition.y = (window.innerHeight - viewportBounds.height) * 0.5 - offset.y;
            break;
        case "fit-to-height":
            if (viewportBounds.width > window.innerWidth) {
                newPosition.x = -offset.x; // Snap to the left side of the cable instead of center
            } else {
                newPosition.x = (window.innerWidth - viewportBounds.width) * 0.5 - offset.x;
            }
            newPosition.y = (window.innerHeight - viewportBounds.height) * 0.5 - offset.y;
            break;
        case "fit-to-source":
            newPosition.x = (window.innerWidth - sourceBounds.width) * 0.5 - sourceOffset.x;
            newPosition.y = (window.innerHeight - sourceBounds.height) * 0.5 - sourceOffset.y;
            break;
    }

    return newPosition;
};

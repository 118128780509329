import useResizeObserver from "@react-hook/resize-observer";
import { useLayoutEffect, useState } from "react";
import { useSelector, useStore } from "react-redux";
import { CableProps } from "../../models/pixi/build/cable";
import { IPoint } from "../../models/pixi/pixi";
import { texturesLoadedSelector } from "../../store/overlay/header/status-icon/status-icon.selectors";
import { dropsSelectorFactory } from "../../store/workspace/build.selectors";
import { initialBuildData } from "../../store/workspace/build/build";
import { buildStateSelector } from "../../store/workspace/root.selectors";
import { RABContainer } from "../../models/pixi/build/build";

export const usePixiComponent = () => {
    const { currentBuild } = useSelector(buildStateSelector);
    const loaded = useSelector(texturesLoadedSelector);
    const windowSize = useWindowSize();
    const store = useStore();

    const cablePosition: IPoint = { x: 210, y: 200 };
    const drops = useSelector(dropsSelectorFactory(cablePosition));

    const cableProps: CableProps = {
        name: RABContainer,
        display: loaded && currentBuild !== undefined,
        x: cablePosition.x,
        y: cablePosition.y,
        drops,
    };

    const buildContext = currentBuild ?? initialBuildData;

    return {
        loaded,
        cableProps,
        buildContext,
        windowSize,
        store,
    };
};

export const useWindowSize = () => {
    const [size, setSize] = useState({ width: 0, height: 0 });
    const [workspace, setWorkspace] = useState<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        const workspaces = document.getElementsByClassName("workspace");
        if (workspaces.length) {
            const workspace = workspaces[0] as HTMLDivElement;
            setWorkspace(workspace);

            const width = Math.min(workspace.clientWidth, window.innerWidth);
            const height = Math.min(workspace.clientHeight, window.innerHeight);
            setSize({ width, height });
        }
    }, []);

    useResizeObserver(workspace, (entry) => {
        setSize(entry.contentRect);
    });

    return size;
};

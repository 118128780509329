import React from "react";
import { IMenuItemProps } from "../../../../models/overlay/header/menu-item";
import { Typography } from "@corning-ctcm/silica-react";

export const MenuItem = ({ id, label, icon, onClick }: IMenuItemProps) => {
    return (
        <li id={id} className="menu-item" onClick={onClick}>
            <span className="menu-item-icon material-icons">{icon}</span>
            <Typography variant="body1">
                {label}
            </Typography>
        </li>
    );
};

import { DrawerHeaderProps } from "../../../models/ui/drawer/drawer-header";
import { IconButton } from "@corning-ctcm/silica-react";
import { Close } from "@mui/icons-material";

import "../../../css/ui/drawer/drawer-header.scss";

export const DrawerHeader = (props: DrawerHeaderProps) => {
    const { id, onClose, logo: logoSrc } = props;
    return (
        <div className="drawer-header">
            <div className="corning-logo">{logoSrc ? <img alt={"logo"} src={logoSrc} /> : null}</div>
            <IconButton 
                id={`${id}-close-icon`}
                onClick={onClose}
                placement="bottom"
                palette="primary"
                mode="main"
                title="Close"
                icon={<Close />}
            />
        </div>
    );
};

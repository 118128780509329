import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { flameRatingsSelector } from "../../../../store/workspace/boundaries/boundaries.selectors";
import { getNbConnectors } from "../../../../store/workspace/build/connector/connector";
import { IDrop } from "../../../../store/workspace/build/drop";
import { projectManagerSelector } from "../../../../store/workspace/project-manager/project-manager.selectors";
import { currentBuildSelector } from "../../../../store/workspace/root.selectors";
import { unitsOfMeasureContainerUnitSelector } from "../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import {
    convertTo,
    convertToDisplay,
    getUnitsName,
    Unit,
} from "../../../../models/overlay/header/units-of-measure";
import { getLegacyFlameRating } from "../../../../models/overlay/wizard/setup/flame-rating";
import { closeAll, showCableDetails } from "../../../../store/overlay/project/project.reducers";
import { getName } from "../../../../components/overlay/project-drawer/project-manager-row/build-info/build-info.hooks";
import { useLoadProject } from "../project-manager-row/drag-handle/drag-handle.hooks";
import { PullingGrip } from "../../../../models/pixi/decorators/markers/pulling-grip";
import { currentPullingGripSelector } from "../../../../store/workspace/build.selectors";

export const useCableDetails = () => {
    const { showDetails: open, selectedBuild } = useSelector(projectManagerSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const pullingGrip = useSelector(currentPullingGripSelector);
    const flameRatingOptions = useSelector(flameRatingsSelector);
    const build = selectedBuild ? selectedBuild : currentBuild;
    const catalogCode = build?.catalogCode ?? "";
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const flameRating = getLegacyFlameRating(build!.flameRating!, flameRatingOptions);
    const [source, ...destinations] = build!.drops;
    const feederParams = getFeederParameters(
        source,
        unit,
        flameRating!.description,
        currentBuild?.cableOuterDiameter ?? 0,
        currentBuild?.fiberCount ?? 0,
        pullingGrip
    );
    const dropParams = getDropParameters(destinations, pullingGrip, unit);
    const units = getUnitsName(unit);
    const { onLoad: triggerLoad } = useLoadProject(build!.id!);

    const [pageTitle, setPageTitle] = useState("Cable Attributes");
    const [loadDisabled, setLoadDisabled] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (build) {
            setPageTitle(getName(build) + " Attributes");
        }
    }, [build]);

    const onReturn = useCallback(() => {
        dispatch(showCableDetails(false));
    }, [dispatch]);

    const onClose = useCallback(() => {
        if (open) {
            dispatch(closeAll());
        }
    }, [open, dispatch]);

    useEffect(() => {
        if (selectedBuild && currentBuild) {
            setLoadDisabled(selectedBuild.id === currentBuild.id);
        }
    }, [currentBuild, selectedBuild]);

    const onLoad = useCallback(async () => {
        triggerLoad();
        onClose();
    }, [triggerLoad, onClose]);

    return { pageTitle, catalogCode, open, onReturn, onClose, loadDisabled, onLoad, feederParams, dropParams, units };
};

function getFeederParameters(
    source: IDrop,
    unit: Unit,
    flameRating: string,
    cableOuterDiameter: number,
    fiberCount: number,
    pullingGrip: PullingGrip
) {
    const nbConnectors = getNbConnectors(source.groups);
    const nbGroups = source.groups.length;
    const connectorsPerGroups = nbConnectors / nbGroups;
    const lengthA = convertTo(source.lengthA!, unit);
    const lengthB = convertTo(source.lengthB!, unit);
    const stagger = convertTo(source.groups[0].stagger!, unit);
    return {
        fiberCount,
        flameRating,
        cableOuterDiameter,
        connectorType: source.groups[0].type!,
        bundles: nbGroups,
        connectorsPerBundle: connectorsPerGroups,
        nbConnectors: nbConnectors,
        lengthA: convertToDisplay(lengthA, unit),
        lengthB: convertToDisplay(lengthB, unit),
        stagger: convertToDisplay(stagger, unit),
        pullingGrip: pullingGrip === "feeder" || pullingGrip === "both",
    };
}

function getDropParameters(destinations: IDrop[], pullingGrip: PullingGrip, unit: Unit) {
    const destination = destinations[0];
    const nbConnectors = getNbConnectors(destination.groups);
    const nbGroups = destination.groups.length;
    const connectorsPerGroups = nbConnectors / nbGroups;
    const lengthA = convertTo(destination.lengthA!, unit);
    const lengthB = convertTo(destination.lengthB!, unit);
    const stagger = convertTo(destination.groups[0].stagger!, unit);
    return {
        nbAccessPoints: destinations.length,
        connectorType: destination.groups[0].type!,
        bundles: nbGroups,
        connectorsPerBundle: connectorsPerGroups,
        nbConnectors: nbConnectors,
        lengthA: convertToDisplay(lengthA, unit),
        lengthB: convertToDisplay(lengthB, unit),
        stagger: convertToDisplay(stagger, unit),
        pullingGrip: pullingGrip === "distribution" || pullingGrip === "both",
    };
}

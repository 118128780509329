import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isPolarityModeSelector, showConnectorReportSelector } from "../../../../store/overlay/overlay.selectors";
import { LocalizationKeys } from "../../../../locales/keys";

export const useRepresentativeExample = () => {
    const { t } = useTranslation();
    const isPolarityMode = useSelector(isPolarityModeSelector);
    const showConnectorReport = useSelector(showConnectorReportSelector);

    return {
        label: t(LocalizationKeys.RepresentativeExample),
        show: !isPolarityMode && !showConnectorReport,
    };
};

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setResize } from "../../../store/pixi/viewport/viewport.reducers";
import { viewportSelector } from "../../../store/pixi/viewport/viewport.selectors";
import { ViewportStatus } from "../../../store/pixi/viewport/viewport";
import {
    currentBuildBoundariesSelector,
    distributionOptionsSelector,
    feederOptionsSelector,
    meshColorSelector,
} from "../../../store/workspace/boundaries/boundaries.selectors";
import { getNbAccessPoints, getNbConnectors } from "../../../store/workspace/build/connector/connector";
import { DEFAULT_CABLE_OUTER_DIAMETER } from "../../../store/workspace/build/build";
import { toolbarSelector } from "../../../store/overlay/footer/toolbar/toolbar.selectors";
import { IUnitOfMeasure, convertToDisplay, getDecimalPlaces } from "../../../models/overlay/header/units-of-measure";
import { FlameRatings } from "../../../models/overlay/wizard/setup/flame-rating";
import {
    IDestinationSetupState,
    initialDestinationSetupState,
} from "../../../store/overlay/wizard/setup/destination-setup/destination-setup";
import {
    initialSourceSetupState,
    ISourceSetupState,
} from "../../../store/overlay/wizard/setup/source-setup/source-setup";
import { wizardSelector } from "../../../store/overlay/wizard/wizard.selectors";
import { ConfigurationType, ConnLC, getConnectorTypeFromDrop, Stagger0, validateUnSignedDecimal } from "../../../store/overlay/wizard/wizard";
import { currentBuildSelector } from "../../../store/workspace/root.selectors";
import { initialMeshOffset } from "../../../store/workspace/build/drop";
import {
    unitsOfMeasureContainerPrimaryUnitSelector,
    unitsOfMeasureContainerUnitSelector,
} from "../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { currentPullingGripSelector } from "../../../store/workspace/build.selectors";
import { useDestinationValidation } from "./setup/destination/destination-setup.hooks";
import { getValidFiberCountInUseValues } from "../../../models/overlay/wizard/setup/destination-setup";

export const useWizard = () => {
    const { display, currentStep } = useSelector(wizardSelector);
    const { context } = useSelector(viewportSelector);
    const sourceState: ISourceSetupState = useSourceSetupState();
    const destinationState: IDestinationSetupState = useDestinationSetupState();

    const dispatch = useDispatch();

    useEffect(() => {
        if (display && context === ViewportStatus.Editing && currentStep === 1) {
            dispatch(setResize({ update: true, value: "fit-to-source" }));
        }
    }, [display, context, currentStep, dispatch]);

    return {
        display,
        currentStep,
        sourceState,
        destinationState,
    };
};

export const useSourceSetupState = (): ISourceSetupState => {
    const { connectorTypes, fiberTypes } = useSelector(feederOptionsSelector);
    const { selection } = useSelector(toolbarSelector);
    const { name: defaultMeshColor } = useSelector(meshColorSelector);
    const {
        id: modeId,
        data: { defaultMeasurementsPosition, defaultOverallLengthType },
    } = useSelector(currentBuildBoundariesSelector);

    const primaryUnit = useSelector(unitsOfMeasureContainerPrimaryUnitSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const pullingGrip = useSelector(currentPullingGripSelector);

    const configurationType = currentBuild?.configurationType ?? ConfigurationType.Patching;
    const drops = currentBuild?.drops ?? [];
    const drop = drops.find((d) => d.side === selection.side && d.position === selection.selected) ?? drops[0];
    const connectorType = connectorTypes.find((x) => x.type === drop.groups[0].type) ?? ConnLC;
    const fiberCountPerConnector = drop.fiberCountInUse ?? connectorType.fiberCount;
    const bundleCount = currentBuild?.bundleCount ?? getNbConnectors(drop.groups);
    const fiberType =
        currentBuild?.fiberType && currentBuild.fiberType.length ? currentBuild.fiberType : fiberTypes[0]?.id ?? "";
    const flameRating = currentBuild?.flameRating ?? FlameRatings.Riser;

    const aLengthField = convertToDisplay(drop.lengthA, unit);
    const bLengthField = convertToDisplay(
        drop.customBLength ? { value: Math.min(...drop.groups.map((g) => g.lengthB!.value)), unit } : drop.lengthB,
        unit
    );
    const sLengthInputValue = convertToDisplay(drop.groups[0].stagger ?? Stagger0.value, unit);
    const meshOffsetField = convertToDisplay(drop.meshOffset ?? initialMeshOffset, unit);
    const overallLengthType = currentBuild?.overallLengthType ?? defaultOverallLengthType;
    const aLength: IUnitOfMeasure = { id: drop.lengthA.id, value: Number(aLengthField), unit };
    const bLength: IUnitOfMeasure = { id: drop.lengthB.id, value: Number(bLengthField), unit };
    const sLength: IUnitOfMeasure = { id: drop.groups[0].stagger?.id, value: Number(sLengthInputValue), unit };
    const meshOffset: IUnitOfMeasure = { id: drop.meshOffset?.id, value: Number(meshOffsetField), unit };
    const meshColor = drop.meshColor ?? defaultMeshColor;

    let groupCount = drop.groups.length;
    const expectedCPG = Math.floor(bundleCount / groupCount);
    if (!drop.groups.every((g) => g.connectors.length === expectedCPG)) {
        groupCount--; // We had uneven groups, so we will adjust for display purpose when editing
    }

    const bLengthType = drop.customBLength ? "custom" : "uniform";

    return {
        ...initialSourceSetupState,
        modeId,
        primaryUnit,
        unit,
        configurationType,
        connectorTypes,
        connectorType,
        fiberType,
        cableOuterDiameter: currentBuild?.cableOuterDiameter ?? DEFAULT_CABLE_OUTER_DIAMETER,
        flameRating,
        pullingGrip,
        fiberCountPerConnector,
        fiberCountPerConnectorField: fiberCountPerConnector.toString(),
        bundleCount,
        bundleCountInputValue: bundleCount.toString(),
        groupCount,
        groupCountField: groupCount.toString(),
        mesh: drop.mesh,
        meshOffset: drop.mesh ? meshOffset : undefined,
        meshOffsetField: drop.mesh ? meshOffsetField : "",
        meshColor: drop.mesh ? meshColor : undefined,
        overallLengthType,
        aLength,
        bLength,
        sLength,
        aLengthField,
        bLengthField,
        sLengthInputValue,
        sLengthField: [sLengthInputValue],
        isSLengthEnabled: groupCount > 1,
        unitOfMeasure: unit,
        reverseStaggering: drop.reverseStaggering,
        bLengthType,
        measurementsPosition: defaultMeasurementsPosition,
    };
};

export const useDestinationSetupState = (): IDestinationSetupState => {
    const { selection } = useSelector(toolbarSelector);
    const { connectorTypes } = useSelector(distributionOptionsSelector);
    const { name: defaultMeshColor } = useSelector(meshColorSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const {
        data: { defaultShellType },
    } = useSelector(currentBuildBoundariesSelector);

    const drops = currentBuild?.drops ?? [];
    const [feeder, ...distribution] = drops;
    const drop = distribution.find((d) => d.position === selection.selected) ?? distribution[0];
    const { validate } = useDestinationValidation(drop, feeder.lengthA);

    const connectorType = connectorTypes.find((c) => c.type === drop.groups[0].type) ?? ConnLC;
    const groupCount = drop.groups.length;
    const nbConnectorsPerGroup = drop.groups[0].connectors.length;
    const applyToAllEnabled = distribution.some(
        (d) => d.position !== drop.position && getNbConnectors(d.groups) === getNbConnectors(drop.groups)
    );

    const feederFiberCountInUse = feeder.fiberCountInUse ?? getConnectorTypeFromDrop(feeder).fiberCount;
    const max = connectorType.fiberCount;
    const fiberCount = currentBuild?.fiberCount ?? 0;
    const fiberCountPerConnector = drop.fiberCountInUse ?? connectorType.fiberCount;
    const fiberCountPerConnectorValidValues = getValidFiberCountInUseValues(max, fiberCount, feederFiberCountInUse);
    const accessPoints = getNbAccessPoints(distribution);

    const aLengthField = convertToDisplay(drop.lengthA, unit);
    const bLengthField = convertToDisplay(
        drop.customBLength ? { value: Math.min(...drop.groups.map((g) => g.lengthB!.value)), unit } : drop.lengthB,
        unit
    );
    const sLengthInputValue = convertToDisplay(
        groupCount > 1 ? drop.groups[0].stagger ?? Stagger0.value : Stagger0.value,
        unit
    );
    const meshOffsetField = convertToDisplay(drop.meshOffset ?? initialMeshOffset, unit);

    const aLength: IUnitOfMeasure = { id: drop.lengthA.id, value: Number(aLengthField), unit };
    const bLength: IUnitOfMeasure = { id: drop.lengthB.id, value: Number(bLengthField), unit };
    const sLength: IUnitOfMeasure = { id: drop.groups[0].stagger?.id, value: Number(sLengthInputValue), unit };
    const meshOffset: IUnitOfMeasure = { id: drop.meshOffset?.id, value: Number(meshOffsetField), unit };
    const meshColor = drop.meshColor ?? defaultMeshColor;
    const bLengthType = drop.customBLength ? "custom" : "uniform";

    const destinationSetupState: IDestinationSetupState = {
        ...initialDestinationSetupState,
        applyToAllEnabled,
        fiberCount,
        position: drop.position,
        connectorType,
        fiberCountPerConnector,
        fiberCountPerConnectorValidValues,
        fiberCountPerConnectorField: fiberCountPerConnector.toString(),
        accessPoints,
        accessPointsFieldValue: accessPoints.toString(),
        groupCount,
        groupCountFieldValue: groupCount.toString(),
        nbConnectorsPerGroup: nbConnectorsPerGroup,
        nbConnectorsPerGroupFieldFieldValue: nbConnectorsPerGroup.toString(),
        mesh: drop.mesh,
        meshOffset: drop.mesh ? meshOffset : undefined,
        meshOffsetField: drop.mesh ? meshOffsetField : "",
        meshColor: drop.mesh ? meshColor : undefined,
        aLength,
        bLength,
        sLength,
        aLengthField,
        bLengthField,
        sLengthInputValue,
        sLengthField: [sLengthInputValue],
        isSLengthEnabled: groupCount > 1,
        reverseStaggering: drop.reverseStaggering,
        bLengthType,
        shell: defaultShellType,
    };

    return validate(destinationSetupState);
};

export const validateValue = (value: string, maxDecimalPlaces: number, maxCharLength?: number) => {
    return validateUnSignedDecimal(value, maxCharLength) && getDecimalPlaces(value) <= maxDecimalPlaces;
};

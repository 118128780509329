import { Graphics } from "@pixi/react";
import { useAccessPoint } from "./access-point.hooks";
import { SelectFilter } from "../../../decorators/bounds/select-filter";

export const AccessPoint: React.FC = () => {
    const { filters, apProps } = useAccessPoint();
    return (
        <SelectFilter adjust={filters.hover} glow={filters.selection}>
            <Graphics {...apProps} />
        </SelectFilter>
    );
};

import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { useDispatch, useSelector } from "react-redux";
import {
    isPolarityModeSelector,
    showConnectorAssignmentSelector,
    showFiberMappingSelector,
} from "../../../../store/overlay/overlay.selectors";
import { useCallback, useContext } from "react";
import { setShowConnectorAssignment, setShowFiberMapping } from "../../../../store/overlay/overlay.reducers";
import { PolarityContext } from "../../../../store/overlay/polarity/polarity";
import {
    currentTAPsFibersSelector,
    defaultConnectorAssignmentData,
    nbAssignedFibersSelector,
} from "../../../../store/workspace/build.selectors";
import { resetHighlights } from "../../../../store/pixi/connector-highlights/connector-highlights.reducers";
import { setConnectorAssignment } from "../../../../store/overlay/polarity/polarity.reducer";
import { IconButtonProps } from "@corning-ctcm/silica-react";
import { autoCollapse } from "../../../../store/workspace/build/build.reducers";

export const usePolarityToolbar = () => {
    const display = useSelector(isPolarityModeSelector);
    const nbAssignedFibers = useSelector(nbAssignedFibersSelector);
    const totalFibers = useSelector(currentTAPsFibersSelector);
    const isConnectorAssignment = useSelector(showConnectorAssignmentSelector);
    const isFiberMapping = useSelector(showFiberMappingSelector);
    const defaultConnectorAssignment = useSelector(defaultConnectorAssignmentData);
    const { dispatch: polarityDispatch } = useContext(PolarityContext);

    const storeDispatch = useDispatch();
    const { t } = useTranslation();

    const progress = {
        title: t(LocalizationKeys.AssignedFibers),
        percentage: (nbAssignedFibers / totalFibers) * 100,
        percentageText: `${nbAssignedFibers}/${totalFibers}`,
    };

    const fiberMapLabel = t(LocalizationKeys.FiberPosition);
    const connectorAssignmentLabel = t(LocalizationKeys.ConnectorAssignment);
    const selectedButton = isFiberMapping ? fiberMapLabel : connectorAssignmentLabel;

    const onConnectorAssignmentClick = useCallback(() => {
        if (!isConnectorAssignment) {
            storeDispatch(setShowConnectorAssignment(true));
            storeDispatch(setShowFiberMapping(false));
        }
    }, [isConnectorAssignment, storeDispatch]);

    const connectorAssignmentClassName = "connector-assignment-button polarity-toolbar-button";
    const connectorAssignment = {
        button: {
            id: "connector-assignment-button",
            className: isConnectorAssignment ? `${connectorAssignmentClassName} active` : connectorAssignmentClassName,
            value: connectorAssignmentLabel,
            onClick: onConnectorAssignmentClick,
        },
        label: connectorAssignmentLabel,
    };

    const onFiberMappingClick = useCallback(() => {
        if (!isFiberMapping && defaultConnectorAssignment) {
            const fromConnector = defaultConnectorAssignment.feederConnectors[0];
            if (fromConnector.id) {
                storeDispatch(setShowFiberMapping(true));
                storeDispatch(setShowConnectorAssignment(false));
                polarityDispatch(
                    setConnectorAssignment({ connectorId: fromConnector.id, assignment: defaultConnectorAssignment })
                );
            }
        }
    }, [isFiberMapping, defaultConnectorAssignment, polarityDispatch, storeDispatch]);

    const fiberMappingClassName = "fiber-mapping-button polarity-toolbar-button";
    const fiberMapping = {
        button: {
            id: "fiber-mapping-button",
            className: isFiberMapping ? `${fiberMappingClassName} active` : fiberMappingClassName,
            value: fiberMapLabel,
            disabled: !!!defaultConnectorAssignment,
            onClick: onFiberMappingClick,
        },
        label: fiberMapLabel,
    };

    const onClose = useCallback(() => {
        storeDispatch(setShowConnectorAssignment(false));
        storeDispatch(setShowFiberMapping(false));
        storeDispatch(resetHighlights());
        storeDispatch(autoCollapse());
    }, [storeDispatch]);

    const close: Omit<IconButtonProps, "icon"> = {
        id: "polarity-close-icon",
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.Close),
        placement: "bottom",
        onClick: onClose,
    };

    return {
        display,
        progress,
        actions: {
            selected: selectedButton,
            connectorAssignment,
            fiberMapping,
            close,
        },
    };
};

import { IPoint } from "../pixi";
import { DropProps } from "./build";

export type CableProps = {
    name: string;
    display: boolean;
    drops: DropProps[];
} & IPoint;

export const BEND_RADIUS = 250;

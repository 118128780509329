import { useCallback, useContext, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../locales/keys";
import { getBuildInfo, setBuildLoaded } from "../../../../../store/workspace/build/build.reducers";
import { DialogReducer, showDialog } from "../../../../../store/workspace/dialog/dialog.reducer";
import { IDialogContext, initialDialogState } from "../../../../../store/workspace/dialog/dialog";
import { totalBuildCountSelector } from "../../../../../store/workspace/project-manager/project-manager.selectors";
import { AppDispatch } from "../../../../../store/workspace/workspace.reducers";
import { sscSessionIdsSelector } from "../../../../../store/workspace/ssc/ssc.selectors";
import { buildIdSelector, buildsPageIndexSelector } from "../../../../../store/workspace/build.selectors";
import { rabSessionService } from "../../../../../services/rab-session.service";
import { useWebLoad } from "../../../header/status/status.hooks";
import { setStatusState } from "../../../../../store/overlay/header/status-icon/status-icon.reducer";
import { WorkspaceStatus } from "../../../../../store/overlay/header/status-icon/status-icon";
import {
    deleteProject,
    setRecentProjectId,
    setSelectedBuildId,
    showCableInfo,
} from "../../../../../store/overlay/project/project.reducers";
import { useProjectDrawer } from "../../project-drawer.hooks";
import { setReloading } from "../../../../../store/overlay/project/project-drawer/project-drawer.reducers";
import { ProjectDrawerContext } from "../../../../../store/overlay/project/project-drawer/project-drawer";
import { useCreateButton } from "../../drawer-sub-header/drawer-sub-header.hooks";
import { useBuildInfo } from "../build-info/build-info.hooks";
import { IconButtonProps, Palettes } from "@corning-ctcm/silica-react";

export const useBuildToolbar = (projectId: number) => {
    const { triggerDuplicate, dialogContext } = useDuplicate();
    const { triggerDetails } = useDetails();
    const { triggerInfo } = useInfo();
    const { triggerDelete } = useDelete();
    const { displayDialog, dialogMessage, dialogTitle, onDialogOpen, onDialogClose } = useDeleteDialog(projectId);
    const id = `project-${projectId}`;

    const duplicateProps: Omit<IconButtonProps, "icon"> = {
        id: `${id}-duplication-icon`,
        palette: "primary",
        mode: "main",
        title: "Duplicate",
        placement: "top",
        onClick: triggerDuplicate,
    };

    const detailsProps: Omit<IconButtonProps, "icon"> = {
        id: `${id}-details-icon`,
        palette: "primary",
        mode: "main",
        title: "Cable Attributes",
        placement: "top",
        onClick: () => triggerDetails(projectId),
    };

    const editProps: Omit<IconButtonProps, "icon"> = {
        id: `${id}-edit-icon`,
        palette: "primary",
        mode: "main",
        title: "Edit",
        placement: "top",
        onClick: () => triggerInfo(projectId),
    };

    const deleteProps: Omit<IconButtonProps, "icon"> = {
        id: `${id}-delete-icon`,
        palette: Palettes.error,
        mode: "main",
        title: "Delete",
        placement: "top",
        onClick: onDialogOpen,
    };

    return {
        dialogContext,
        duplicateProps,
        detailsProps,
        editProps,
        deleteProps,
        triggerDelete,
        displayDialog,
        dialogMessage,
        dialogTitle,
        onDialogClose,
    };
};

const useDuplicate = () => {
    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);
    const dialogContext: IDialogContext = { state, dispatch };

    const triggerDuplicate = useCallback(() => {
        dispatch(showDialog(true));
    }, []);

    return { dialogContext, triggerDuplicate };
};

const useDetails = () => {
    const dispatch = useDispatch<AppDispatch>();

    const triggerDetails = useCallback(
        async (buildId: number) => {
            await dispatch(getBuildInfo(buildId));
        },
        [dispatch]
    );

    return { triggerDetails };
};

const useInfo = () => {
    const dispatch = useDispatch();

    const triggerInfo = useCallback(
        (buildId: number) => {
            dispatch(setSelectedBuildId(buildId));
            dispatch(showCableInfo(true));
        },
        [dispatch]
    );

    return { triggerInfo };
};

const useDelete = () => {
    const currentBuildId = useSelector(buildIdSelector);
    const totalBuildCount = useSelector(totalBuildCountSelector);
    const pageIndex = useSelector(buildsPageIndexSelector);
    const sessionIds = useSelector(sscSessionIdsSelector);
    const {
        state: { search },
        dispatch,
    } = useContext(ProjectDrawerContext);
    const { triggerCreate } = useCreateButton();
    const { onDrawerClose } = useProjectDrawer();
    const { dispatchBuild } = useWebLoad();
    const storeDispatch = useDispatch<AppDispatch>();

    const triggerDelete = useCallback(
        async (buildId: number) => {
            if (currentBuildId === buildId && totalBuildCount > 1) {
                storeDispatch(setStatusState(WorkspaceStatus.Busy));
                storeDispatch(setBuildLoaded(false));
                onDrawerClose();
            }

            const buildSessionId = sessionIds[buildId]?.sessionId ?? "";
            if (buildSessionId) {
                rabSessionService.endSession(buildSessionId);
            }

            dispatch(setReloading(true));
            const recentBuild = await storeDispatch(deleteProject(buildId, pageIndex, search));
            if (recentBuild) {
                dispatch(setRecentProjectId(recentBuild.id ?? 0));
                dispatchBuild(recentBuild, !buildSessionId);
            } else {
                triggerCreate();
            }
            dispatch(setReloading(false));
        },
        [
            currentBuildId,
            totalBuildCount,
            sessionIds,
            dispatch,
            storeDispatch,
            pageIndex,
            search,
            onDrawerClose,
            dispatchBuild,
            triggerCreate,
        ]
    );

    return { triggerDelete };
};

const useDeleteDialog = (projectId: number) => {
    const { t } = useTranslation();
    const [displayDialog, setDisplayDialog] = useState(false);
    const { name } = useBuildInfo(projectId);

    const onDialogOpen = useCallback(() => {
        setDisplayDialog(true);
    }, []);

    const onDialogClose = useCallback(() => {
        setDisplayDialog(false);
    }, []);

    const dialogMessage = t(LocalizationKeys.DeleteMessage, { content: name });
    const dialogTitle = t(LocalizationKeys.DeleteCable);

    return { displayDialog, dialogMessage, onDialogOpen, onDialogClose, dialogTitle };
};

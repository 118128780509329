import { useDispatch, useSelector } from "react-redux";
import {
    currentMaskLengthsSelector,
    currentMeasurementsPositionSelector,
    currentPullingGripSelector,
} from "../../../../store/workspace/build.selectors";
import { showSettingsSelector } from "../../../../store/overlay/overlay.selectors";
import { useWebSave } from "../status/status.hooks";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRadioGroup, useRadioInput } from "../../../ui/radio/radio.hooks";
import { LocalizationKeys } from "../../../../locales/keys";
import { setMaskLengths, updateMeasurementsPosition } from "../../../../store/workspace/build/build.reducers";
import { setShowSettings } from "../../../../store/overlay/overlay.reducers";
import { showPullingGripSelector, userIdSelector } from "../../../../store/authentication/authentication.selectors";
import { setShowPullingGripForUser } from "../../../../store/authentication/authentication.reducers";
import { AppDispatch } from "../../../../store/workspace/workspace.reducers";
import { CheckboxProps, DialogProps } from "@corning-ctcm/silica-react";
import { isLockedSelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";

export const useSettingsDialog = () => {
    const open = useSelector(showSettingsSelector);
    const userId = useSelector(userIdSelector);
    const showPullingGrip = useSelector(showPullingGripSelector);
    const pullingGrip = useSelector(currentPullingGripSelector);
    const maskLengths = useSelector(currentMaskLengthsSelector);
    const measurementsPosition = useSelector(currentMeasurementsPositionSelector);
    const locked = useSelector(isLockedSelector);
    const { saveBuild } = useWebSave();
    const [settingsChanged, setSettingsChanged] = useState(false);
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();

    const onPullingGripCheckboxChanged = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (userId) {
                storeDispatch(setShowPullingGripForUser(userId, !showPullingGrip));
            }
        },
        [storeDispatch, userId, showPullingGrip]
    );

    const onMaskLengthsCheckboxChanged = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            storeDispatch(setMaskLengths(!maskLengths));
            setSettingsChanged(true);
        },
        [maskLengths, storeDispatch]
    );

    const pullingGripCheckboxProps: CheckboxProps = {
        id: "pulling-grip",
        palette: "primary",
        placement: "end",
        label: t(LocalizationKeys.ShowPullingGrip),
        checked: showPullingGrip,
        disabled: pullingGrip === "none",
        onChange: onPullingGripCheckboxChanged,
    };

    const maskLengthsCheckboxProps: CheckboxProps = {
        id: "mask-lengths",
        palette: "primary",
        placement: "end",
        label: t(LocalizationKeys.MaskLengths),
        checked: maskLengths,
        onChange: onMaskLengthsCheckboxChanged,
    };

    const { onRadioGroupChange, ...radioGroup } = useRadioGroup("measurements-position", measurementsPosition, locked);
    const interior = useRadioInput("interior", t(LocalizationKeys.Interior), measurementsPosition, locked);
    const exterior = useRadioInput("exterior", t(LocalizationKeys.Exterior), measurementsPosition, locked);

    const onSave = useCallback(async () => {
        await saveBuild();
    }, [saveBuild]);

    const onMeasurementsPositionChange = useCallback(
        (e: any) => {
            const measurementsPosition = e.currentTarget.value;
            onRadioGroupChange(e);
            storeDispatch(updateMeasurementsPosition(measurementsPosition));
            setSettingsChanged(true);
        },
        [onRadioGroupChange, storeDispatch]
    );

    const onClose = useCallback(() => {
        storeDispatch(setShowSettings(false));
    }, [storeDispatch]);

    const dialogProps: DialogProps = {
        id: "settings",
        open,
        modal: false,
        className: "settings",
        title: t(LocalizationKeys.Settings),
        header: {
            id: "settings-header",
            closeable: true,
            onClose,
        },
        onClose,
    };

    useEffect(() => {
        if (open && settingsChanged) {
            onSave();
            setSettingsChanged(false);
        }
    }, [open, settingsChanged, storeDispatch, onSave]);

    return {
        dialogProps,
        drawingSpecs: {
            label: t(LocalizationKeys.DrawingSpecs),
            pullingGrip: pullingGripCheckboxProps,
            maskLengths: maskLengthsCheckboxProps,
        },
        radioGroup: {
            props: {
                ...radioGroup,
                label: t(LocalizationKeys.MeasurementsPosition),
                onRadioGroupChange: onMeasurementsPositionChange,
            },
            options: [interior, exterior],
        },
    };
};

import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./warnings";
import { setWarningsCardDisplayAction, acknowledgeWarningGroupAction } from "./warnings.actions";

const warningsSlice = createSlice({
    name: "warnings",
    initialState,
    reducers: {
        setWarningsCardDisplay: setWarningsCardDisplayAction,
        acknowledgeWarningGroup: acknowledgeWarningGroupAction,
    },
});

export const WarningsReducer = warningsSlice.reducer;
export const { setWarningsCardDisplay, acknowledgeWarningGroup } = warningsSlice.actions;

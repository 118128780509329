import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../locales/keys";
import { setViewportContext } from "../../../store/pixi/viewport/viewport.reducers";
import { ViewportStatus } from "../../../store/pixi/viewport/viewport";
import { IDialogHeaderProps } from "../../../models/ui/dialog/dialog-header";
import { setShowAboutApplication, setShowThirdPartyCopyright } from "../../../store/overlay/overlay.reducers";
import { showAboutApplicationSelector } from "../../../store/overlay/overlay.selectors";
import { IAboutApplicationDialogProps } from "../../../models/overlay/about-this-application";

import config from "../../../config.json";
const versionNumber = config.buildDate;

export const useAboutThisApplication = () => {
    const showAboutApplication = useSelector(showAboutApplicationSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onThirdPartyButtonClick = useCallback(() => {
        dispatch(setShowAboutApplication(false));
        dispatch(setShowThirdPartyCopyright(true));
    }, [dispatch])

    const closeDialog = useCallback(() => {
        if (showAboutApplication) {
            dispatch(setShowAboutApplication(false));
        }
        dispatch(setViewportContext(ViewportStatus.Active))
    }, [showAboutApplication, dispatch])

    const { dialogBodyProps, dialogHeaderProps } = useMemo(() => {
        const version = versionNumber && versionNumber.length > 0 ? versionNumber : t(LocalizationKeys.Unknown);
        const lastBuild = t(LocalizationKeys.LastBuild, { version });
        const dialogBodyProps: IAboutApplicationDialogProps = {
            title: t(LocalizationKeys.RABTitle),
            lastBuild,
            thirdPartyButtonTitle: t(LocalizationKeys.ThirdPartyOSSCopyright),
            corningCopyright: t(LocalizationKeys.CorningCopyright),
            onThirdPartyButtonClick,
        };

        const dialogHeaderProps: IDialogHeaderProps = {
            id: "about-this-application",
            title: t(LocalizationKeys.AboutThisApplication),
            closable: true,
            onClose: closeDialog,
        };
        return { dialogBodyProps, dialogHeaderProps };
    }, [t, onThirdPartyButtonClick, closeDialog]);

    return { showAboutApplication, dialogBodyProps, dialogHeaderProps, closeDialog };
};

import { useDispatch, useSelector } from "react-redux";
import { notificationSelector, showNotificationSelector } from "../../../store/overlay/notification/notification.selectors";
import { useCallback } from "react";
import { showNotification } from "../../../store/overlay/notification/notification.reducers";
import { FIVE_SEC_MS } from "../../../models/timer";
import { IconButtonProps, TooltipPlacement, AlertProps, AlertVariant } from "@corning-ctcm/silica-react";

export const useNotificationSnackbar = () => {
    const { message, palette } = useSelector(notificationSelector);
    const display = useSelector(showNotificationSelector);
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        dispatch(showNotification(false));
    }, [dispatch]);

    const notificationProps: AlertProps = {
        palette,
        className: "notification-snackbar",
        variant: "filled" as AlertVariant,
        snackbarProps: {
            open: display,
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            message,
            autoHideDuration: FIVE_SEC_MS,
            onClose,
        },
    };

    const closeIconProps: Omit<IconButtonProps, "icon"> = {
        palette: "primary",
        mode: "main",
        placement: "top" as TooltipPlacement,
        onClick: onClose,
    };

    return {
        notificationProps,
        closeIconProps,
    };
};

import { createSelector } from "@reduxjs/toolkit";
import { currentBuildFinalizedSelector } from "../../../store/workspace/build.selectors";
import { currentStatusSelector, isLockedSelector } from "../../../store/overlay/header/status-icon/status-icon.selectors";
import { sscSessionBusySelector } from "../ssc/ssc.selectors";
import { WorkspaceStatus } from "../../../store/overlay/header/status-icon/status-icon";

export const buildDisabledSelector = createSelector(
    currentBuildFinalizedSelector,
    currentStatusSelector,
    isLockedSelector,
    sscSessionBusySelector,
    (buildFinalized, currentStatus, locked, sscSessionBusy) => 
    buildFinalized ||
    locked ||
    currentStatus === WorkspaceStatus.Saving ||
    currentStatus === WorkspaceStatus.Loading ||
    currentStatus === WorkspaceStatus.InitialLoad ||
    sscSessionBusy
);
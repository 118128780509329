import ReactDOM from "react-dom";
import { useNotificationSnackbar } from "./notification-snackbar.hooks";
import { IconButton, Alert } from "@corning-ctcm/silica-react";
import { Close } from "@mui/icons-material";

import "../../../css/overlay/notification-snackbar.scss";

export const NotificationSnackbar: React.FC = () => {
    const { notificationProps, closeIconProps } = useNotificationSnackbar();
    return ReactDOM.createPortal(
        <Alert {...notificationProps} action={<IconButton {...closeIconProps} icon={<Close />} />} />,
        document.body
    );
};

import React from "react";
import { usePolarityAssignmentRow } from "./polarity-assignment-row.hooks";
import { IPolarityAssignmentRowProps } from "../../../../../models/overlay/polarity/polarity-assignment-row";
import { DataTableCell, DataTableRow } from "@rmwc/data-table";
import { PadlockIcon } from "../../../../icons/padlock-icon";
import { IconButton } from "@corning-ctcm/silica-react";
import { Edit, Delete } from "@mui/icons-material";

export const PolarityAssignmentRow: React.FC<IPolarityAssignmentRowProps> = (props) => {
    const {
        id,
        data,
        tableClassName,
        editButtonClassName,
        deleteButtonClassName,
        editProps,
        deleteProps,
        onRowClick,
        onButtonCellClick,
    } = usePolarityAssignmentRow(props);

    if (!data) return null;

    return (
        <DataTableRow id={`${id}-button`} onClick={onRowClick} className={tableClassName} key={data.key}>
            <DataTableCell>
                <div className="polarity-row">
                    <div className="polarity-icon">
                        {data.applied ? (
                            <i className="material-icons applied">{"check_circle"}</i>
                        ) : (
                            <PadlockIcon locked={!data.editable} />
                        )}
                    </div>
                    <div className="polarity-description">
                        <span className="polarity-name">{data.description}</span>
                        <span className="polarity-revision">{data.creationInfo}</span>
                    </div>
                </div>
            </DataTableCell>
            <DataTableCell
                id={`${id}-edit-button`}
                className={editButtonClassName}
                onClick={data.editable ? onButtonCellClick : undefined}
            >
                <IconButton {...editProps} icon={<Edit />}/>
            </DataTableCell>
            <DataTableCell
                id={`${id}-cancel-button`}
                className={deleteButtonClassName}
                onClick={data.editable ? onButtonCellClick : undefined}
            >
                <IconButton {...deleteProps} icon={<Delete />}/>
            </DataTableCell>
        </DataTableRow>
    );
};

import React from "react";
import { useSettingsDialog } from "./settings-dialog.hooks";
import { Typography, Checkbox, Dialog, Radio, RadioGroup  } from "@corning-ctcm/silica-react";

import "../../../../css/overlay/header/settings-dialog.scss";

export const SettingsDialog: React.FC = () => {
    const { dialogProps, drawingSpecs, radioGroup } = useSettingsDialog();
    return (
        <Dialog {...dialogProps}>
            <div className="drawing-specs">
                <div className="section-title">
                    <Typography variant="subtitle1">
                        {drawingSpecs.label}
                    </Typography>
                </div>
                <Checkbox {...drawingSpecs.pullingGrip} />
                <Checkbox {...drawingSpecs.maskLengths} />
            </div>
            <div className="measurements-position">
                <RadioGroup
                    label={radioGroup.props.label}
                    value={radioGroup.props.value}
                    onChange={radioGroup.props.onRadioGroupChange}
                >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {radioGroup.options.map((r, i) => (
                            <Radio
                                id={`measurement-option-${i}`}
                                key={`measurement-option-${i}`}
                                label={r.label}
                                value={r.sectionName}
                                disabled={r.disabled}
                            />
                        ))}
                    </div>
                </RadioGroup>
            </div>
        </Dialog>
    );
};

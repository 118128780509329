import { InputFieldValue, InputField } from "../../../models/ui/input";
import { useState, useCallback, useEffect } from "react";

export function useInputField(
    label: string,
    value: InputFieldValue,
    maxLength?: number,
    parse?: (value: InputFieldValue, defaultValue?: InputFieldValue) => InputFieldValue,
    validate?: (value: InputFieldValue) => boolean
): InputField {
    const [fieldValue, setFieldValue] = useState(value);
    const [isValid, setIsValid] = useState(true);

    const parseValue = useCallback(
        (input: InputFieldValue) => {
            let parsedValue = input;
            if (parse) {
                parsedValue = parse(input, value);
                setFieldValue(parsedValue);
            } else {
                setFieldValue(input);
            }

            return parsedValue;
        },
        [parse, value]
    );

    const validateValue = useCallback(
        (input: InputFieldValue) => {
            const parsedValue = parseValue(input);
            if (validate) {
                const valid = validate(parsedValue);
                setIsValid(valid);
            }
        },
        [parseValue, validate]
    );

    const onChange = useCallback(
        (e: React.ChangeEvent<any>) => {
            validateValue(e.currentTarget.value);
        },
        [validateValue]
    );

    useEffect(() => {
        if (maxLength) {
            let stringValue = value as string;
            let intValue = value as number;
            if (intValue) {
                stringValue = intValue.toString();
            }

            if (stringValue && stringValue.length > maxLength) {
                const lastchar = stringValue[maxLength - 1];
                let sliceRange = lastchar === "," ? maxLength - 1 : maxLength;
                stringValue = stringValue.slice(0, sliceRange);
            }

            validateValue(stringValue);
        } else {
            validateValue(value);
        }
    }, [value, validateValue, maxLength]);

    return { label, value: fieldValue, onChange, maxLength, isValid };
}

export function getDateNowString(dateStr?: string) {
    const date = dateStr ? new Date(dateStr) : new Date();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}

import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { useSelector, useDispatch } from "react-redux";
import { useEditCallback } from "../wizard-footer/wizard-footer.hooks";
import { useState, useEffect, useCallback } from "react";
import { ViewportStatus } from "../../../../store/pixi/viewport/viewport";
import { setCurrentBuild } from "../../../../store/workspace/build/build.reducers";
import { setToolbarDisplay } from "../../../../store/overlay/footer/toolbar/toolbar.reducers";
import { clearSavedData, setWizardDisplay } from "../../../../store/overlay/wizard/wizard.reducers";
import { setViewportContext } from "../../../../store/pixi/viewport/viewport.reducers";
import { userIdSelector } from "../../../../store/authentication/authentication.selectors";
import { setIsLocked } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import { buildsSelector } from "../../../../store/workspace/root.selectors";
import { restoreBuildSession } from "../../../../store/workspace/ssc/ssc.reducer";
import { viewportContextSelector } from "../../../../store/pixi/viewport/viewport.selectors";
import { toolbarSelectionSelector } from "../../../../store/overlay/footer/toolbar/toolbar.selectors";
import { wizardSelector } from "../../../../store/overlay/wizard/wizard.selectors";
import { IconButtonProps } from "@corning-ctcm/silica-react";

export const useWizardHeader = () => {
    const { t } = useTranslation();
    const userId = useSelector(userIdSelector);
    const { context } = useSelector(viewportContextSelector);
    const { side } = useSelector(toolbarSelectionSelector);
    const { currentStep, stepCount, savedBuildData } = useSelector(wizardSelector);
    const nbBuilds = useSelector(buildsSelector).length;
    const { setIsEditing } = useEditCallback();
    const dispatch = useDispatch();

    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [isClosable, setIsClosable] = useState(true);

    useEffect(() => {
        if (context === ViewportStatus.Editing) {
            if (side === "feeder") {
                setTitle(t(LocalizationKeys.Source));
            } else {
                setTitle(t(LocalizationKeys.Destination));
            }
        } else {
            setTitle(t(LocalizationKeys.CableSetup));
        }
    }, [context, t, side]);

    useEffect(() => {
        if (context === ViewportStatus.Editing) {
            setSubtitle(t(LocalizationKeys.EditProperties));
        } else {
            setSubtitle(t(LocalizationKeys.WizardSubtitle, { currentStep: currentStep, stepCount: stepCount }));
        }
    }, [context, t, currentStep, stepCount]);

    useEffect(() => {
        if (nbBuilds > 0) {
            setIsClosable(true);
        } else {
            setIsClosable(false);
        }
    }, [nbBuilds]);

    const onClose = useCallback(async () => {
        if (savedBuildData) {
            if (context === ViewportStatus.Editing) {
                setIsEditing(false);
                dispatch(setToolbarDisplay(true));
            }
            dispatch(restoreBuildSession());
            dispatch(setCurrentBuild(savedBuildData));
            dispatch(setWizardDisplay(false));
            dispatch(clearSavedData());
            dispatch(setViewportContext(ViewportStatus.Active));

            if (savedBuildData.lockedById !== -1 && savedBuildData.lockedById !== userId) {
                dispatch(setIsLocked(true));
            }
        }
    }, [context, dispatch, setIsEditing, savedBuildData, userId]);

    const closeProps: Omit<IconButtonProps, "icon"> = {
        id: "wizard-close-icon",
        palette: "primary",
        mode: "main",
        title: "Close",
        placement: "top",
        onClick: onClose,
    };

    return { title, subtitle, isClosable, closeProps };
};

import { DropData, initialBuildData } from "../../../../../store/workspace/build/build";
import { ChangeEvent, createContext } from "react";

export type BLengthType = "uniform" | "custom";

export interface IBLengthProps {
    drop: DropData;
    disabled?: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface ICustomBLengthContext {
    drop: DropData;
    isCustom: boolean;
}

const initialContext: ICustomBLengthContext = {
    drop: initialBuildData.drops[0],
    isCustom: false,
};

export const BLengthContext = createContext(initialContext);

import { useBuildInfo } from "./build-info.hooks";
import { Typography } from "@corning-ctcm/silica-react";

import "../../../../../css/overlay/project-drawer/project-manager-row/build-info.scss";

export const BuildInfo = (props: { projectId: number }) => {
    const { projectId } = props;
    const { name, catalogCode, description, polarity } = useBuildInfo(projectId);

    return (
        <div className="build-info">
            <Typography variant="body1" className="build-name">{name}</Typography>
            <Typography variant="caption" color="text.primary" className="build-part-number">{catalogCode}</Typography>
            <Typography variant="caption" color="text.secondary" className="build-description">{description}</Typography>
            <Typography variant="caption" color="text.secondary" className="build-polarity">{polarity}</Typography>
        </div>
    );
};

import { useDispatch, useSelector } from "react-redux";
import { disabledUISelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";
import { useCallback } from "react";
import { useCreateButton } from "../../project-drawer/drawer-sub-header/drawer-sub-header.hooks";
import { setUnitsOfMeasureContainerDisplay } from "../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.reducers";
import { LocalizationKeys } from "../../../../locales/keys";
import { showConnectorReportSelector, showMoreMenuSelector } from "../../../../store/overlay/overlay.selectors";
import { warningsCardDisplaySelector } from "../../../../store/overlay/header/warnings/warnings.selectors";
import { WarningSeverity } from "../../../../store/overlay/header/warnings/warnings";
import { setWarningsCardDisplay } from "../../../../store/overlay/header/warnings/warnings.reducer";
import { sscConfigSessionBusySelector, sscAllWarningsSelector } from "../../../../store/workspace/ssc/ssc.selectors";
import { unitsOfMeasureContainerDisplaySelector, unitsOfMeasureContainerUnitSelector } from "../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { hybrisCustomerSelector } from "../../../../store/authentication/authentication.selectors";
import { setShowMoreMenu } from "../../../../store/overlay/overlay.reducers";
import { wizardDisplaySelector } from "../../../../store/overlay/wizard/wizard.selectors";
import { IconButtonProps, Palettes } from "@corning-ctcm/silica-react";
import { useTranslation } from "react-i18next";

export const useToolsContainer = () => {
    const displayWarningsCard = useSelector(warningsCardDisplaySelector);
    const unitsDisplay = useSelector(unitsOfMeasureContainerDisplaySelector);
    const currentUnits = useSelector(unitsOfMeasureContainerUnitSelector);
    const disableUI = useSelector(disabledUISelector);
    const connectorReportDisplay = useSelector(showConnectorReportSelector);
    const warnings = useSelector(sscAllWarningsSelector);
    const displayMoreMenu = useSelector(showMoreMenuSelector);
    const sessionBusy = useSelector(sscConfigSessionBusySelector);
    const isCustomer = !!useSelector(hybrisCustomerSelector);
    const wizardDisplay = useSelector(wizardDisplaySelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const warningCount = warnings.length;
    const badgePalette = warnings.some((w) => w.severity === WarningSeverity.Error) ? Palettes.error : Palettes.warning;

    const { triggerCreate } = useCreateButton();

    const unitsOfMeasureDisplay = useCallback(() => {
        dispatch(setUnitsOfMeasureContainerDisplay(!unitsDisplay));
    }, [dispatch, unitsDisplay]);

    const warningsListDisplay = useCallback(() => {
        dispatch(setWarningsCardDisplay(!displayWarningsCard));
    }, [dispatch, displayWarningsCard]);

    const moreMenuDisplay = useCallback(() => {
        dispatch(setShowMoreMenu(!displayMoreMenu));
    }, [dispatch, displayMoreMenu]);

    const disableAll = disableUI || connectorReportDisplay;
    const disableNewCable = disableAll || sessionBusy
    const disableUnits = disableNewCable && !wizardDisplay;

    const warningProps: Omit<IconButtonProps, "icon"> = {
        id: "warnings-icon",
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.Warnings),
        disabled: disableAll,
        placement: "bottom",
        onClick: warningsListDisplay,
    };

    const unitsProps: Omit<IconButtonProps, "icon"> = {
        id: "units-icon",
        palette: "primary",
        mode: "main",
        disabled: disableUnits,
        placement: "bottom",
        onClick: unitsOfMeasureDisplay,
    };

    const newCableProps: Omit<IconButtonProps, "icon"> = {
        id: "new-cable-icon",
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.NewCable),
        disabled: disableNewCable,
        placement: "bottom",
        onClick: triggerCreate,
    };

    const moreMenuProps: Omit<IconButtonProps, "icon"> = {
        id: "more-actions-icon",
        palette: "primary",
        mode: "main",
        placement: "bottom",
        onClick: moreMenuDisplay,
    };

    return {
        disableNewCable,
        isCustomer,
        unitsProps,
        warningProps,
        warningCount,
        badgePalette,
        newCableProps,
        moreMenuProps,
        currentUnits,
    };
};

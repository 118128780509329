import { Blue, IColor, IColorDialogProps } from "../../../../models/ui/dialog/color-dialog";
import { ConnectorReportContext, setTriggerColorsChanged } from "../../../../store/overlay/reports/reports.reducers";
import { DialogReducer, showDialog } from "../../../../store/workspace/dialog/dialog.reducer";
import { IDialogContext, initialDialogState } from "../../../../store/workspace/dialog/dialog";
import { ITriggerInfo, initialTriggerManagementState } from "../../../../store/overlay/reports/trigger/trigger";
import { TriggerManagementReducer, setSelectedTrigger, setTriggers, updateTrigger } from "../../../../store/overlay/reports/trigger/trigger.reducers";
import {
    colorSelector,
    connectorAssignmentsSelector,
    connectorTypesSelector,
    selectedDropSelector,
    triggerColorsInfoSelector,
} from "../../../../store/workspace/build.selectors";
import { setShowConnectorReport, showTriggerManagement } from "../../../../store/overlay/overlay.reducers";
import { useCallback, useContext, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/workspace/workspace.reducers";
import { ICollapsibleDialogProps } from "../../../../models/ui/dialog/collapsible-dialog";
import { IConnectorData } from "../../../../store/workspace/build/connector/connector";
import { IGenericDialogProps } from "../../../../models/ui/dialog/generic-dialog";
import { LC } from "../../../pixi/factories/texture";
import { LocalizationKeys } from "../../../../locales/keys";
import { MTPExclusiveColors } from "../../../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";
import { WorkspaceStatus } from "../../../../store/overlay/header/status-icon/status-icon";
import { currentStatusSelector, isLockedSelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";
import { defaultTriggerColorSelectorFactory } from "../../../../store/workspace/boundaries/boundaries.selectors";
import { setStatusState } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import { showTriggerManagementSelector } from "../../../../store/overlay/overlay.selectors";
import { updatePositionedConnectors } from "../../../../store/workspace/build/build.reducers";
import { useTranslation } from "react-i18next";
import { propagationSelector } from "../../../../store/overlay/polarity/propagation/propagation.selectors";

export const useTriggerColorManagement = () => {
    const display = useSelector(showTriggerManagementSelector);
    const [state, dispatch] = useReducer(TriggerManagementReducer, initialTriggerManagementState);
    const { triggers, selectedTrigger } = state;
    const [colorDialogState, colorDialogDispatch] = useReducer(DialogReducer, initialDialogState);
    const colorDialogContext: IDialogContext = { state: colorDialogState, dispatch: colorDialogDispatch };
    const { dispatch: connectorReportDispatch } = useContext(ConnectorReportContext);
    const currentStatus = useSelector(currentStatusSelector);
    const locked = useSelector(isLockedSelector);
    const disabled =
        locked ||
        currentStatus === WorkspaceStatus.Saving ||
        currentStatus === WorkspaceStatus.Busy;
    const triggerInfos = useSelector(triggerColorsInfoSelector);
    const selectedDrop = useSelector(selectedDropSelector);
    const connectorAssignments = useSelector(connectorAssignmentsSelector);
    const connectorTypes = useSelector(connectorTypesSelector);
    const defaultColor = useSelector(defaultTriggerColorSelectorFactory(selectedTrigger?.connectorType));
    const colors = useSelector(colorSelector);
    const propagation = useSelector(propagationSelector);
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();

    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        if (display) {
            dispatch(setTriggers(triggerInfos));
        }
    }, [display, triggerInfos, connectorAssignments.length]);

    useEffect(() => {
        if (!colorDialogState.props.open) {
            dispatch(setSelectedTrigger());
        }
    }, [colorDialogState.props.open]);

    const onClose = useCallback(() => {
        dispatch(setTriggers([]));
        storeDispatch(showTriggerManagement(false));
        storeDispatch(setShowConnectorReport(true));
        colorDialogDispatch(showDialog(false));
    }, [storeDispatch, colorDialogDispatch]);

    const dialogProps: ICollapsibleDialogProps = {
        id: "trigger-management",
        className: "trigger-management-dialog",
        display,
        headerProps: {
            title: t(LocalizationKeys.ManageConnectorColor),
            closable: true,
            onClose,
        },
    };

    const onTriggerClick = useCallback(
        (trigger: ITriggerInfo) => {
            dispatch(setSelectedTrigger(trigger));
            colorDialogDispatch(showDialog(true));
        },
        [dispatch, colorDialogDispatch]
    );

    const onColorButtonClick = useCallback(
        (color: IColor) => {
            if (selectedTrigger) {
                const trigger = { ...selectedTrigger, color };
                dispatch(updateTrigger(trigger));
            }
            colorDialogDispatch(showDialog(false));
        },
        [selectedTrigger, dispatch]
    );

    const onResetButtonClick = useCallback(() => {
        if (selectedTrigger) {
            const trigger: ITriggerInfo = {
                ...selectedTrigger,
                color: colors.find((c) => c.name === defaultColor) ?? Blue,
            };
            dispatch(updateTrigger(trigger));
        }
        dispatch(showDialog(false));
    }, [dispatch, selectedTrigger, colors, defaultColor]);

    const colorDialogProps: IColorDialogProps = {
        context: colorDialogContext,
        preventOutsideDismiss: true,
        currentColor: selectedTrigger?.color,
        className: "trigger-management",
        displayFooter: true,
        colors: selectedTrigger ? colors : [],
        onColorButtonClick,
        onResetButtonClick,
    };

    const groupColumn = {
        className: "group-column",
        label: t(LocalizationKeys.Group),
    };
    const connectorColumn = {
        className: "connector-column",
        label: t(LocalizationKeys.Connector),
    };
    const triggerColumn = {
        className: "trigger-column",
        label: t(LocalizationKeys.TriggerHousing),
    };
    const tableProps = {
        header: [groupColumn, connectorColumn, triggerColumn],
        body: triggers,
    };

    const onCancel = useCallback(() => {
        onClose();
    }, [onClose]);

    const cancelButtonProps = {
        disabled,
        onClick: onCancel,
    };

    const applyColor = useCallback(
        async (isExclusive: boolean) => {
            const groups = selectedDrop?.groups ?? [];
            if (groups) {
                storeDispatch(setStatusState(WorkspaceStatus.Saving));
                try {
                    const connectors: IConnectorData[] = [];
                    for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
                        const group = groups[groupIndex];
                        const trigger = triggers[groupIndex];
                        const feederGroupConnectors: IConnectorData[] = group.connectors.map((c) => ({
                            ...c,
                            color: trigger.color.name,
                        }));
                        connectors.push(...feederGroupConnectors);
                    }
                    await storeDispatch(
                        updatePositionedConnectors(connectors, propagation)
                    );
                } finally {
                    storeDispatch(setStatusState(WorkspaceStatus.Saved));
                    connectorReportDispatch(setTriggerColorsChanged(true));
                }
            }
            onClose();
        },
        [propagation, selectedDrop, triggers, onClose, connectorReportDispatch, storeDispatch]
    );

    const onApplyScheme = useCallback(() => {
        if (
            triggers.map((t) => t.color.id).some((c) => MTPExclusiveColors.map((c) => c.id).includes(c)) &&
            connectorTypes.find((c) => c.type?.includes(LC))
        ) {
            setShowWarning(true);
        } else {
            applyColor(false);
        }
    }, [connectorTypes, triggers, applyColor]);

    const applyButtonProps = {
        disabled,
        onClick: onApplyScheme,
    };

    const onCloseWarning = useCallback(async () => {
        setShowWarning(false);
    }, []);

    const onConfirm = useCallback(() => {
        onCloseWarning();
        applyColor(true);
    }, [onCloseWarning, applyColor]);

    const warningDialogProps: IGenericDialogProps = {
        id: "trigger-management-warning",
        title: t(LocalizationKeys.ApplyTriggerColors),
        display: showWarning,
        message: t(LocalizationKeys.MatchAssignmentColorsWarning),
        onClose: onCloseWarning,
        confirmText: t(LocalizationKeys.ApplyColor),
        onCancel: onCloseWarning,
        onConfirm,
        closable: true,
    };

    return {
        dialogProps,
        colorDialogProps,
        tableProps,
        selectedTrigger,
        onTriggerClick,
        cancelProps: {
            buttonProps: cancelButtonProps,
            label: t(LocalizationKeys.Cancel),
        },
        applyProps: {
            buttonProps: applyButtonProps,
            label: t(LocalizationKeys.ApplyScheme),
        },
        warningDialogProps,
    };
};

import React from "react";
import { Graphics, Text, withFilters } from "@pixi/react";
import { useLineMarker } from "./line-marker.hooks";
import { ILineMarkerProps, initialState } from "../../../../../models/pixi/decorators/dimension-lines/line-marker";
import { TextInput } from "../../../text-input/text-input";
import { OutlineFilter } from "pixi-filters";

const TextInputWithFilter = withFilters(TextInput, {
    outline: OutlineFilter,
});

export const LineMarker: React.FC<ILineMarkerProps> = (props: ILineMarkerProps = initialState) => {
    const {
        display,
        showTolerances,
        startLine,
        endLine,
        filters,
        container,
        textProps,
        minProps,
        maxProps,
        primaryProps,
        secondaryProps,
    } = useLineMarker(props);

    return (
        <>
            {startLine.display ? <Graphics draw={startLine.draw} /> : null}
            {endLine.display ? <Graphics draw={endLine.draw} /> : null}
            {display && (
                <TextInputWithFilter outline={filters} {...container}>
                    <Text {...textProps} />
                    {showTolerances && (
                        <>
                            <Text {...minProps} />
                            <Text {...maxProps} />
                        </>
                    )}
                    <Text {...primaryProps} />
                    <Text {...secondaryProps} />
                </TextInputWithFilter>
            )}
        </>
    );
};

import { useWizardHeader } from "./wizard-header.hooks";
import { IconButton, Typography } from "@corning-ctcm/silica-react";
import { Close } from "@mui/icons-material";

import "../../../../css/overlay/wizard/wizard-header.scss";

export const WizardHeader = () => {
    const { title, subtitle, isClosable, closeProps } = useWizardHeader();
    return (
        <div id="wizard-header" className="wizard-header">
            <div className={"wizard-header-title-container"}>
                <div id="wizard-header-title" className="wizard-header-title">
                    <Typography variant="h5">
                        {title}
                    </Typography>
                </div>
                <div id="wizard-header-subtitle" className="wizard-header-subtitle">
                    <Typography variant="caption">
                        {subtitle}
                    </Typography>
                </div>
            </div>
            {isClosable ? <IconButton {...closeProps} icon={<Close />} /> : null}
        </div>
    );
};

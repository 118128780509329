import { useSelector } from "react-redux";
import { useState, useEffect, useCallback, useMemo } from "react";
import {
    convertToDisplay,
} from "../../../../../models/overlay/header/units-of-measure";
import { StaggerLengthTypes } from "../../../../../store/overlay/wizard/wizard";
import { IStaggerAutoCompleteProps } from "../../../../../models/overlay/wizard/setup/stagger-autocomplete";
import { useTranslation } from "react-i18next";
import { AutocompleteCloseReason } from "@mui/material";
import { LocalizationKeys } from "../../../../../locales/keys";
import { unitsOfMeasureContainerUnitSelector } from "../../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";

export const useStaggerAutocomplete = (props: IStaggerAutoCompleteProps) => {
    const {
        value: staggerValue,
        inputValue,
        type,
        onValueChanged,
        onInputChanged,
        isInputValid,
        inputErrorText,
        disabled: disable,
        position,
        options: staggerOptions,
        units,
        inputRef,
    } = props;
    const { t } = useTranslation();
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);

    const [open, setOpen] = useState(false);
    const [label, setLabel] = useState(``);

    const enterKeyCode = 13;

    let options = useMemo(() => {
        return staggerOptions ?? StaggerLengthTypes.map((s) => convertToDisplay(s.value, unit));
    }, [unit, staggerOptions]);

    useEffect(() => {
        setOpen(false);
    }, [staggerValue]);

    useEffect(() => {
        setLabel(t(LocalizationKeys.Stagger, { position: position }));
    }, [unit, position, t]);

    const onOpen = useCallback((e: React.ChangeEvent<{}>) => {
        setOpen(true);
    }, []);

    const onClose = useCallback((e: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
        setOpen(false);
    }, []);

    const onInputKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === enterKeyCode) {
            setOpen(false);
        }
    }, []);

    return {
        open,
        disabled: disable,
        type,
        options,
        staggerValue,
        inputValue,
        onOpen,
        onClose,
        onValueChanged,
        onInputChanged,
        label,
        isInputValid,
        inputErrorText,
        onInputKeyDown,
        units,
        inputRef,
    };
};

import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { hybrisCustomerSelector } from "../../../../../store/authentication/authentication.selectors";
import { IconButtonProps } from "@corning-ctcm/silica-react";

export const useFullscreenButton = () => {
    const hybrisCustomer = useSelector(hybrisCustomerSelector);
    const showButton = !!hybrisCustomer;
    const [isFullscreen, setIsFullscreen] = useState(true);

    useEffect(() => {
        setIsFullscreen(!!(window as any).designerFullScreen);
    }, []);

    const toggleFullscreen = useCallback(() => {
        window.parent.postMessage("ctcm.designer.toggleFullscreen", window.location.origin);
        setIsFullscreen(!isFullscreen);
    }, [isFullscreen]);

    const buttonProps: Omit<IconButtonProps, "icon"> = {
        id: "fullscreen-icon",
        palette: "primary",
        mode: "main",
        placement: "bottom",
        onClick: toggleFullscreen,
    };

    return { buttonProps, showButton, isFullscreen };
};

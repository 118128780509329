import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../locales/keys";
import { ICollapsibleDialogProps } from "../../../../models/ui/dialog/collapsible-dialog";
import { DEFAULT_LABEL_COLOR, IColor, IColorDialogProps } from "../../../../models/ui/dialog/color-dialog";
import { NavigationBarReducer, setCurrentIndex } from "../../../../store/ui/navigation-bar/navigation-bar.reducer";
import { INavigationBarProps } from "../../../../models/ui/navigation-bar";
import {
    defaultTriggerColorSelectorFactory,
    defaultTriggersColorSelector,
} from "../../../../store/workspace/boundaries/boundaries.selectors";
import { IConnectorData } from "../../../../store/workspace/build/connector/connector";
import { updatePositionedConnectors } from "../../../../store/workspace/build/build.reducers";
import { DropData } from "../../../../store/workspace/build/build";
import { DialogReducer, showDialog } from "../../../../store/workspace/dialog/dialog.reducer";
import { initialDialogState } from "../../../../store/workspace/dialog/dialog";
import { AppDispatch } from "../../../../store/workspace/workspace.reducers";
import { useSscBuildSession } from "../../../workspace/ssc.hooks";
import { sscSessionBusySelector } from "../../../../store/workspace/ssc/ssc.selectors";
import {
    buildPositionsSelector,
    colorSelector,
    currentBuildFinalizedSelector,
    selectedDropSelector,
} from "../../../../store/workspace/build.selectors";
import { currentBuildSelector } from "../../../../store/workspace/root.selectors";
import {
    setShowConnectorReport,
    setShowLabelScheme,
    showTriggerManagement,
} from "../../../../store/overlay/overlay.reducers";
import { showConnectorReportSelector } from "../../../../store/overlay/overlay.selectors";
import { setSelection, setToolbarDisplay } from "../../../../store/overlay/footer/toolbar/toolbar.reducers";
import { isLockedSelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";
import { unitsOfMeasureContainerUnitSelector } from "../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import {
    convertTo,
    getUnitsName,
    roundToDecimalBasedOnUnit,
    Unit,
} from "../../../../models/overlay/header/units-of-measure";
import {
    clearSelection,
    ConnectorReportContext,
    setLabelChanged,
    setSelectedRows,
    setTriggerColorsChanged,
} from "../../../../store/overlay/reports/reports.reducers";
import { IConnectorRowData } from "../../../../store/overlay/reports/reports";
import { IConnectorRowProps } from "../../../../models/overlay/reports/connector-row";
import { setViewportContext } from "../../../../store/pixi/viewport/viewport.reducers";
import {
    AllColors,
    FiberColors,
} from "../../../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";
import { propagationSelector } from "../../../../store/overlay/polarity/propagation/propagation.selectors";
import { ButtonProps, IconButtonProps } from "@corning-ctcm/silica-react";

export const useConnectorReport = () => {
    const open = useSelector(showConnectorReportSelector);
    const drop = useSelector(selectedDropSelector);
    const position = drop?.side === "distribution" ? drop.position + 1 : 0;
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const colors = useSelector(colorSelector);
    const defaultColor = useSelector(defaultTriggerColorSelectorFactory(drop?.groups ? drop.groups[0].type : ""));
    const propagation = useSelector(propagationSelector);

    const currentBuild = useSelector(currentBuildSelector);
    const { state: reportState, dispatch: reportDispatch } = useContext(ConnectorReportContext);
    const { selectedRowIds } = reportState;

    const { navigationBarProps } = useNavigationBar(position);
    const { dispatch: navigationBarDispatch } = navigationBarProps.context;
    const { updateBuildSession } = useSscBuildSession();
    const storeDispatch = useDispatch<AppDispatch>();

    const [focusedRow, setFocusedRow] = useState<IConnectorRowData | undefined>();
    const buildFinalized = useSelector(currentBuildFinalizedSelector);
    const sscSessionBusy = useSelector(sscSessionBusySelector);
    const locked = useSelector(isLockedSelector);
    const currentBuildEditable = !buildFinalized && !locked && !sscSessionBusy;

    const rows: IConnectorRowProps[] = useMemo(() => {
        if (!drop || !Object.keys(drop).length) {
            return [];
        }
        const rows = getRowsData(drop, unit, colors, defaultColor);
        const focusedConnector = focusedRow
            ? getConnectorFromDrop(drop, focusedRow.groupIndex, focusedRow.connectorIndex)
            : undefined;

        const onRowFocus = (e: React.FocusEvent<HTMLTableRowElement>) => {
            const rowIndex = e.currentTarget.rowIndex;
            if (rows[rowIndex - 1].id === focusedRow?.id) {
                return;
            }
            setFocusedRow(rows[rowIndex - 1]);
        };

        const onRowBlur = () => {
            setFocusedRow(undefined);
        };

        const labelCallback = async (e: React.FormEvent<HTMLInputElement>) => {
            const { value } = e.currentTarget;
            if (!focusedRow) return;
            if (value === focusedRow.label) return;

            const updatedConnector: IConnectorData = { ...focusedConnector, label: value };
            if (currentBuild?.id && focusedRow) {
                await storeDispatch(updatePositionedConnectors([updatedConnector], propagation));
                reportDispatch(setLabelChanged(true));
            }
        };

        return rows.map((r) => {
            return {
                data: r,
                disabled: !currentBuildEditable,
                onFocus: onRowFocus,
                onBlur: onRowBlur,
                labelCallback,
            };
        });
    }, [
        drop,
        unit,
        focusedRow,
        currentBuild,
        currentBuildEditable,
        colors,
        defaultColor,
        propagation,
        storeDispatch,
        reportDispatch,
    ]);

    const { onPaletteClick, closePaletteDialog, colorDialogProps, colorsUpdated } = useColorDialog(
        open,
        drop!,
        rows,
        colors
    );
    const { onLabelPaletteClick, closeLabelPaletteDialog, labelColorDialogProps, labelColorsUpdated } =
        useLabelColorDialog(open, drop!, rows);
    const mutexPaletteClick = () => {
        closeLabelPaletteDialog();
        onPaletteClick();
    };
    const mutexLabelPaletteClick = () => {
        closePaletteDialog();
        onLabelPaletteClick();
    };
    const { t } = useTranslation();

    const onClose = useCallback(() => {
        if (open) {
            storeDispatch(setShowConnectorReport(false));
            storeDispatch(setViewportContext("active"));
            storeDispatch(setToolbarDisplay(true));

            if (currentBuild && !sscSessionBusy) {
                if (
                    colorsUpdated ||
                    labelColorsUpdated ||
                    reportState.labelChanged ||
                    reportState.triggerColorsChanged
                ) {
                    updateBuildSession(currentBuild);
                }
            }
        }
        reportDispatch(setLabelChanged(false));
        reportDispatch(setTriggerColorsChanged(false));
    }, [
        currentBuild,
        updateBuildSession,
        open,
        sscSessionBusy,
        storeDispatch,
        colorsUpdated,
        labelColorsUpdated,
        reportState.labelChanged,
        reportState.triggerColorsChanged,
        reportDispatch,
    ]);

    const onManageClick = useCallback(() => {
        storeDispatch(setShowLabelScheme(true));
        storeDispatch(setShowConnectorReport(false));
    }, [storeDispatch]);

    const title =
        drop?.side === "feeder" ? t(LocalizationKeys.Feeder) : t(LocalizationKeys.AccessPointNumber, { position });
    const footnote = t(LocalizationKeys.StaggeringFootnote);
    const manageLabelScheme = t(LocalizationKeys.ManageLabelScheme).toLocaleUpperCase();
    const hasSelection = selectedRowIds.length > 0;
    const unitName = getUnitsName(unit, false, true);

    const connectorButtonProps: Omit<IconButtonProps, "icon"> = {
        id: "connector-color-icon",
        palette: "primary",
        mode: "main",
        disabled: !hasSelection,
        placement: "bottom",
        onClick: mutexPaletteClick,
    };

    const labelButtonProps: Omit<IconButtonProps, "icon"> = {
        id: "label-color-icon",
        palette: "primary",
        mode: "main",
        disabled: !hasSelection,
        placement: "bottom",
        onClick: mutexLabelPaletteClick,
    };

    const tableHeaderProps = {
        connectorTitle: t(LocalizationKeys.Connector),
        groupTitle: t(LocalizationKeys.Group),
        connectorColorTitle: t(LocalizationKeys.ConnColor),
        connectorButtonProps,
        labelColorTitle: t(LocalizationKeys.LabelColor),
        labelButtonProps,
        labelTitle: t(LocalizationKeys.Label),
        bTitle: `B${position} (${unitName})`,
    };

    const onMasterCheckboxChanged = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            if (e.currentTarget.checked) {
                reportDispatch(setSelectedRows(rows.map((r) => r.data.id)));
            } else {
                reportDispatch(clearSelection());
            }
        },
        [reportDispatch, rows]
    );

    const isMasterChecked = selectedRowIds.length === rows.length;
    const masterCheckboxProps = {
        checked: isMasterChecked,
        className: isMasterChecked ? "checked" : "",
        indeterminate: !isMasterChecked && hasSelection,
        onChange: onMasterCheckboxChanged,
        disabled: !currentBuildEditable,
    };

    const collapsibleDialogProps: ICollapsibleDialogProps = {
        id: "connector-report",
        display: open,
        className: "connector-report-dialog",

        headerProps: {
            title,
            collapsible: true,
            closable: true,
            onClose,
        },
    };

    useEffect(() => {
        if (position < 0) {
            onClose();
        } else {
            navigationBarDispatch(setCurrentIndex(position));
        }
    }, [currentBuild, updateBuildSession, navigationBarDispatch, onClose, position]);

    const onTriggerManagementClick = useCallback(() => {
        storeDispatch(showTriggerManagement(true));
        storeDispatch(setShowConnectorReport(false));
    }, [storeDispatch]);

    const triggerManagementButtonProps: ButtonProps = {
        id: "trigger-management-button",
        className: "trigger-management-button",
        palette: "primary",
        mode: "main",
        disabled: !currentBuildEditable,
        onClick: onTriggerManagementClick,
    };

    const labelSchemeButtonProps: ButtonProps = {
        id: "label-management-button",
        className: "manage-button",
        palette: "primary",
        mode: "main",
        disabled: !currentBuildEditable,
        onClick: onManageClick,
    };

    return {
        footnote,
        manageLabelScheme,
        tableHeaderProps,
        rows,
        masterCheckboxProps,
        colorDialogProps,
        labelColorDialogProps,
        navigationBarProps,
        collapsibleDialogProps,
        loading: sscSessionBusy,
        triggerManagementProps: {
            display: drop?.side === "feeder",
            buttonProps: triggerManagementButtonProps,
            buttonText: t(LocalizationKeys.ManageConnectorColor),
        },
        labelSchemeButtonProps,
    };
};

function getRowsData(data: DropData, unit: Unit, colors: IColor[], defaultColor: string): IConnectorRowData[] {
    const { customBLength } = data;
    const groups = data.groups!;

    const rows: IConnectorRowData[] = [];

    let connectorId: number = 1;
    let groupStagger = 0;
    for (let i = 0; i < groups.length; i++) {
        let connectorGroup = groups[i];
        const lengthB =
            customBLength && connectorGroup.lengthB
                ? convertTo(connectorGroup.lengthB, unit).value!
                : convertTo(data.lengthB!, unit).value;
        for (let j = 0; j < connectorGroup.connectors.length!; j++) {
            let connector = connectorGroup.connectors[j];
            rows.push({
                id: connectorId,
                position: data.position!,
                groupIndex: connectorGroup.position!,
                connectorIndex: connector.position!,
                group: i + 1,
                color: colors.find((c) => c.name === connector.color) ?? colors.find((c) => c.name === defaultColor)!,
                label: connector.label,
                labelColor: AllColors.find((c) => c.name === connector.labelColor) ?? DEFAULT_LABEL_COLOR,
                lengthB: roundToDecimalBasedOnUnit(lengthB, unit!),
                stagger: roundToDecimalBasedOnUnit(groupStagger, unit!),
                totalLength: roundToDecimalBasedOnUnit(lengthB + groupStagger, unit!),
            });
            connectorId++;
        }
        if (!customBLength) {
            groupStagger += convertTo(connectorGroup!.stagger!, unit).value;
        }
    }

    return rows;
}

const useColorDialog = (open: boolean, drop: DropData, rows: IConnectorRowProps[], colors: IColor[]) => {
    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);

    const { state: reportState, dispatch: reportDispatch } = useContext(ConnectorReportContext);
    const { selectedRowIds } = reportState;
    const storeDispatch = useDispatch<AppDispatch>();
    const defaultTriggerColors = useSelector(defaultTriggersColorSelector);
    const propagation = useSelector(propagationSelector);

    const selectedRows = rows.filter((r) => selectedRowIds.includes(r.data.id));
    const connectorColors = selectedRows
        .map((r) => r.data.color)
        .filter((value, index, self) => self.indexOf(value) === index);
    const currentColor = connectorColors.length === 1 ? connectorColors[0] : undefined;

    const [colorsUpdated, setColorsUpdated] = useState(false);

    useEffect(() => {
        if (!open) {
            if (colorsUpdated) {
                setColorsUpdated(false);
            }
            if (state.props.open) {
                dispatch(showDialog(false));
            }
            if (reportState.selectedRowIds.length > 0) {
                reportDispatch(clearSelection());
            }
        }
    }, [open, colorsUpdated, state.props.open, reportDispatch, reportState.selectedRowIds]);

    const onPaletteClick = useCallback(() => {
        dispatch(showDialog(true));
    }, [dispatch]);

    const updateConnectorColors = useCallback(
        async (connectorsToUpdate: IConnectorData[]) => {
            await storeDispatch(updatePositionedConnectors(connectorsToUpdate, propagation));
            setColorsUpdated(true);
            dispatch(showDialog(false));
            reportDispatch(clearSelection());
        },
        [propagation, dispatch, storeDispatch, reportDispatch]
    );

    const onColorButtonClick = useCallback(
        (color: IColor) => {
            if (selectedRows.length) {
                const connectorsToUpdate: IConnectorData[] = [];
                for (const selectedRow of selectedRows) {
                    const { groupIndex, connectorIndex } = selectedRow.data;
                    const updatedConnector: IConnectorData = {
                        ...getConnectorFromDrop(drop, groupIndex, connectorIndex),
                        color: color.name,
                    };
                    connectorsToUpdate.push(updatedConnector);
                }

                updateConnectorColors(connectorsToUpdate);
            }
        },
        [updateConnectorColors, selectedRows, drop]
    );

    const onResetButtonClick = useCallback(() => {
        if (selectedRows.length) {
            const connectorsToUpdate: IConnectorData[] = [];

            for (const selectedRow of selectedRows) {
                const { groupIndex, connectorIndex } = selectedRow.data;
                const connectorType = drop.groups?.length && drop.groups[0]?.type ? drop.groups[0].type : "";
                const defaultColor = defaultTriggerColors[connectorType]?.name ?? "";
                const currentConnector = getConnectorFromDrop(drop, groupIndex, connectorIndex);
                const updatedConnector: IConnectorData = {
                    ...currentConnector,
                    color: defaultColor,
                };
                connectorsToUpdate.push(updatedConnector);
            }

            updateConnectorColors(connectorsToUpdate);
        }
    }, [selectedRows, drop, defaultTriggerColors, updateConnectorColors]);

    const closePaletteDialog = useCallback(() => dispatch(showDialog(false)), []);

    const colorDialogProps: IColorDialogProps = useMemo(() => {
        return {
            context: { state, dispatch },
            preventOutsideDismiss: true,
            currentColor,
            className: "connector-color",
            displayFooter: true,
            colors: colors,
            onColorButtonClick,
            onResetButtonClick,
        };
    }, [currentColor, colors, onColorButtonClick, onResetButtonClick, state]);

    return { colorsUpdated, closePaletteDialog, onPaletteClick, colorDialogProps };
};

const useLabelColorDialog = (open: boolean, drop: DropData, rows: IConnectorRowProps[]) => {
    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);

    const { state: reportState, dispatch: reportDispatch } = useContext(ConnectorReportContext);
    const { selectedRowIds } = reportState;
    const storeDispatch = useDispatch<AppDispatch>();

    const selectedRows = rows.filter((r) => selectedRowIds.includes(r.data.id));
    const labelColors = selectedRows
        .map((r) => r.data.labelColor)
        .filter((value, index, self) => self.indexOf(value) === index);
    const currentColor = labelColors.length === 1 ? labelColors[0] : undefined;
    const propagation = useSelector(propagationSelector);

    const [labelColorsUpdated, setLabelColorsUpdated] = useState(false);

    useEffect(() => {
        if (!open) {
            if (labelColorsUpdated) {
                setLabelColorsUpdated(false);
            }
            if (state.props.open) {
                dispatch(showDialog(false));
            }
            if (reportState.selectedRowIds.length > 0) {
                reportDispatch(clearSelection());
            }
        }
    }, [open, labelColorsUpdated, state.props.open, reportDispatch, reportState.selectedRowIds]);

    const onLabelPaletteClick = useCallback(() => {
        dispatch(showDialog(true));
    }, [dispatch]);

    const updateConnectorColors = useCallback(
        async (connectorsToUpdate: IConnectorData[]) => {
            await storeDispatch(updatePositionedConnectors(connectorsToUpdate, propagation));
            dispatch(showDialog(false));
            reportDispatch(clearSelection());
            setLabelColorsUpdated(true);
        },
        [propagation, dispatch, storeDispatch, reportDispatch]
    );

    const onColorButtonClick = useCallback(
        (color: IColor) => {
            if (selectedRows.length) {
                const connectorsToUpdate: IConnectorData[] = [];
                for (const selectedRow of selectedRows) {
                    const { groupIndex, connectorIndex } = selectedRow.data;
                    const updatedConnector: IConnectorData = {
                        ...getConnectorFromDrop(drop, groupIndex, connectorIndex),
                        labelColor: color.name,
                    };
                    connectorsToUpdate.push(updatedConnector);
                }

                updateConnectorColors(connectorsToUpdate);
            }
        },
        [updateConnectorColors, selectedRows, drop]
    );

    const onResetButtonClick = useCallback(() => {
        if (selectedRows.length) {
            const connectorsToUpdate: IConnectorData[] = [];

            for (const selectedRow of selectedRows) {
                const { groupIndex, connectorIndex } = selectedRow.data;
                const defaultColor = DEFAULT_LABEL_COLOR;
                const currentConnector = getConnectorFromDrop(drop, groupIndex, connectorIndex);
                const updatedConnector: IConnectorData = {
                    ...currentConnector,
                    labelColor: defaultColor.name,
                };
                connectorsToUpdate.push(updatedConnector);
            }

            updateConnectorColors(connectorsToUpdate);
        }
    }, [selectedRows, drop, updateConnectorColors]);

    const closeLabelPaletteDialog = useCallback(() => dispatch(showDialog(false)), []);

    const labelColorDialogProps: IColorDialogProps = useMemo(() => {
        return {
            context: { state, dispatch },
            preventOutsideDismiss: true,
            currentColor,
            className: "label-color",
            displayFooter: true,
            colors: FiberColors,
            onColorButtonClick,
            onResetButtonClick,
        };
    }, [currentColor, onColorButtonClick, onResetButtonClick, state]);

    return { labelColorsUpdated, closeLabelPaletteDialog, onLabelPaletteClick, labelColorDialogProps };
};

const useNavigationBar = (initialIndex: number) => {
    const storeDispatch = useDispatch();
    const [state, dispatch] = useReducer(NavigationBarReducer, { currentIndex: initialIndex, disabled: false });
    const { currentIndex, disabled } = state;
    const positions = useSelector(buildPositionsSelector);
    const { t } = useTranslation();
    const length = positions.length;

    const onNavigationButtonClick = useCallback(
        (index: number) => {
            dispatch(setCurrentIndex(index));
            const position = index > 0 ? index - 1 : 0;
            const side = index > 0 ? "distribution" : "feeder";
            storeDispatch(setSelection({ position, side }));
        },
        [storeDispatch, dispatch]
    );

    const onFirstClick = useCallback(() => {
        const firstIndex = 0;
        onNavigationButtonClick(firstIndex);
    }, [onNavigationButtonClick]);

    const onPreviousClick = useCallback(() => {
        const previousIndex = currentIndex ? currentIndex - 1 : 0;
        onNavigationButtonClick(previousIndex);
    }, [currentIndex, onNavigationButtonClick]);

    const onNextClick = useCallback(() => {
        const nextIndex = (currentIndex + 1) % length;
        onNavigationButtonClick(nextIndex);
    }, [currentIndex, onNavigationButtonClick, length]);

    const onLastClick = useCallback(() => {
        const lastIndex = length - 1;
        onNavigationButtonClick(lastIndex);
    }, [onNavigationButtonClick, length]);

    const buttons = positions.map((p) => {
        let id = `distribution-${p}`;
        let label = p.toString();
        if (p === 0) {
            label = "F";
            id = t(LocalizationKeys.Feeder).toLowerCase();
        }
        return {
            buttonProps: {
                id,
                onClick: () => onNavigationButtonClick(p),
            },
            label,
        };
    });

    const navigationBarProps: INavigationBarProps = {
        context: { state, dispatch },
        displayThreshold: 8,
        onFirstClick,
        onPreviousClick,
        buttons,
        onNextClick,
        onLastClick,
    };

    const previousDisabled = currentIndex === 0 || disabled;
    const nextDisabled = buttons ? currentIndex + 1 === buttons.length : disabled;

    return { onPreviousClick, onNextClick, previousDisabled, nextDisabled, navigationBarProps };
};

const getConnectorFromDrop = (
    drop: DropData,
    groupIndex: number,
    connectorIndex: number
): IConnectorData | undefined => {
    const group = drop.groups?.find((g) => g.position === groupIndex);
    if (!group) {
        return undefined;
    }

    return {
        ...group.connectors.find((c) => c.position === connectorIndex),
        side: drop.side,
        tapPosition: drop.position,
        groupPosition: groupIndex,
    };
};

import { useCallback, useMemo, useRef, useState } from "react";
import { IPanAndZoomToolbarProps, FitOption, getButtonLabel } from "../../../../../../models/overlay/polarity/polarity-diagram/pan-and-zoom-toolbar";

export const usePanAndZoomToolbar = ({ currentZoom, canvas, container }: IPanAndZoomToolbarProps) => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [fitOption, setFitOption] = useState<FitOption>("page");
    const menuButtonRef = useRef<HTMLButtonElement>(null);
    const fullscreen = document.fullscreenElement === container;

    const onZoomIn = useCallback(() => {
        if (canvas) {
            canvas.zoomIn();
        }
    }, [canvas]);

    const onZoomOut = useCallback(() => {
        if (canvas) {
            canvas.zoomOut();
        }
    }, [canvas]);

    const onMenuOpen = useCallback(() => setMenuOpen(true), []);
    const onMenuClose = useCallback(() => setMenuOpen(false), []);

    const onMenuClick = useCallback(
        (option: FitOption) => {
            if (canvas) {
                canvas.setFitOption(option);
                setFitOption(option);
            }
        },
        [canvas]
    );

    const onResizeClick = useCallback(() => {
        if (canvas) {
            canvas.setFitOption(fitOption);
            setFitOption(fitOption);
        }
    }, [canvas, fitOption]);

    const onToggleFullscreen = useCallback(() => {
        if (container) {
            if (fullscreen) {
                document.exitFullscreen();
            } else {
                container.requestFullscreen();
            }
        }
    }, [container, fullscreen]);

    const buttonLabel = useMemo(() => getButtonLabel(fitOption), [fitOption]);

    return {
        currentZoom,
        onZoomIn,
        onZoomOut,
        fitOption,
        onResizeClick,
        fullscreen,
        container,
        onToggleFullscreen,
        isMenuOpen,
        menuButtonRef,
        onMenuOpen,
        onMenuClose,
        onMenuClick,
        buttonLabel,
    };
};

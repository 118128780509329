import { Matrix, Texture } from "pixi.js";

import { Yellow } from "../../../ui/dialog/color-dialog";
import { MeshColors } from "../../../overlay/wizard/setup/mesh-properties";
import { getMaxNumberOfCablesPerGroup } from "../build";
import { AP_SIZE } from "./access-point";
import { IPoint } from "../../pixi";

export const DROP_MAX_CONNECTORS_PREEXPAND = 0;

export const TETHER_BORDER_WIDTH = 14;
export const TETHER_CABLE_WIDTH = 12;
export const TETHER_SHADOW_WIDTH = 4;

export const TRUNK_LENGTH_ADJUSTMENT = 2;
export const TRUNK_BORDER_WIDTH = 0.5;
export const TRUNK_SHADOW_WIDTH = 5;
export const TRUNK_SHADOW_ALPHA = 0.18;
export const TRUNK_WIDTH = AP_SIZE.height * 0.45;

export const FEEDER_MAX_CONNECTORS_PREEXPAND = 9;

const MESH_TEXTURE_SCALE = 0.1;

export const getCableStartOffset = (numberOfCables: number, cableWidth: number, connectorType: string): number => {
    // Assumes cables will be centrally aligned and distributes around a central axis
    if (numberOfCables === 1) return 0;

    numberOfCables = Math.min(numberOfCables, getMaxNumberOfCablesPerGroup(connectorType));
    return -(~~(numberOfCables / 2) * cableWidth + (numberOfCables % 2) * (cableWidth / 2) - cableWidth / 2);
};

export const getMeshColor = (dropMeshColorName?: string): number => {
    const selectedMeshColor = (MeshColors.find((c) => c.name === dropMeshColorName)?.hex ?? Yellow.hex).replace(
        "#",
        "0x"
    );
    return Number(selectedMeshColor);
};

// The matrix value is used to scale down the texture used.
// The initial texture's dimensions are larger than needed, then scaled down, so that it appears sharper after being rasterized by pixi
export const getLineMeshTextureOptions = (width: number, meshTexture: Texture, scale: IPoint) => {
    const scaleMultiplier = scale.x > 1 ? 1 : 1 / scale.x;
    const scaleAmount = MESH_TEXTURE_SCALE * scaleMultiplier;
    return {
        width: width,
        texture: meshTexture,
        alpha: 1,
        matrix: Matrix.IDENTITY.clone().scale(scaleAmount, scaleAmount),
    };
};

import { Typography, TextField } from "@corning-ctcm/silica-react";
import React from "react";
import { ITolerancesField } from "../../../../../models/overlay/header/tolerances/tolerances-field";
import { useTolerancesField } from "./tolerances-field.hooks";

import "../../../../../css/overlay/header/tolerances/tolerances-field.scss";

export const TolerancesField: React.FC<ITolerancesField> = (props) => {
    const { label, minProps, maxProps } = useTolerancesField(props);

    return (
        <div className="tolerance-container label-distance">
            <Typography variant="body1">
                {label}
            </Typography>
            <div className="field-container">
                <TextField {...minProps} />
                <Typography variant="body1">
                    /
                </Typography>
                <TextField {...maxProps} />
            </div>
        </div>
    );
};

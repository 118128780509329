import { Graphics } from "pixi.js";
import { useCallback, useContext } from "react";
import { DimensionLineContext } from "../../../../models/pixi/decorators/dimension-lines/dimension-lines";
import { ICollapseMarkerProps } from "../../../../models/pixi/decorators/markers/collapse-marker";
import { drawCollapseMarker } from "../../../../models/pixi/decorators/markers/markers";
import { DropContext } from "../../../../store/workspace/build/build";
import { CollapseMarkerContainer } from "../../../../models/pixi/build/build";

export const useCollapseMarker = ({ display, location }: ICollapseMarkerProps) => {
    const { color } = useContext(DimensionLineContext);
    const { containerName } = useContext(DropContext);
    const draw = useCallback(
        (grfx: Graphics) => {
            grfx.clear();
            drawCollapseMarker(grfx, location, color);
        },
        [location, color]
    );

    return {
        display,
        containerName: `${containerName}_${CollapseMarkerContainer}`,
        draw,
    };
};

import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./edit-toolbar";
import {
    setToolbarDisplayReducer,
    setSelectedPositionReducer,
    setHoveredPositionReducer,
    clearSelectionReducer,
    setSideReducer,
    setSelectionReducer,
} from "./toolbar.actions";

const editToolbarSlice = createSlice({
    name: "editToolbar",
    initialState,
    reducers: {
        setToolbarDisplay: setToolbarDisplayReducer,
        setHoveredPosition: setHoveredPositionReducer,
        setSelectedPosition: setSelectedPositionReducer,
        setSide: setSideReducer,
        setSelection: setSelectionReducer,
        clearSelection: clearSelectionReducer,
    },
});

export const EditToolbarReducer = editToolbarSlice.reducer;
export const { setToolbarDisplay, setHoveredPosition, setSelectedPosition, setSide, setSelection, clearSelection } =
    editToolbarSlice.actions;

const ContainerNames = {
    ReportTableContainer: "report-table-container",
    CorningSecondaryLogo: "corning-secondary-logo",
    PolarityContainer: "polarity-container",
    ConnectorChipContainer: "connector-chip-container",
    FIberChipContainer: "fiber-chip-container"
} as const;

type ContainerName = (typeof ContainerNames)[keyof typeof ContainerNames];

class _OffscreenExtractor {
    public static _tableExtractor: _OffscreenExtractor | null = null;
    private elements: { [id: string]: Element } = {};

    private constructor() {}

    public getElementById = (id: string) => this.elements[id];
    public getElements = () => this.elements;
    public hasElement = (id: string) => this.elements[id] !== undefined;

    public extractElements = (...exclusions: ContainerName[]) => {
        this.elements = {}; // Make sure to clear before extracting for a new report

        this.extract("report-table-container", exclusions);
        this.extract("corning-secondary-logo", exclusions);
        this.extract("polarity-container", exclusions);
        this.extract("connector-chip-container", exclusions);
        this.extract("fiber-chip-container", exclusions);

        const values = Object.values(ContainerNames);
        if (values.every((v) => exclusions.includes(v) || this.elements[v] === undefined || this.elements[v] === null))
            throw new Error("No elements were extracted");
    };

    private extract = (containerName: ContainerName, exclusions: ContainerName[]) => {
        const offscreen = document.body.querySelector("#offscreen-container");
        const container = offscreen?.querySelector(`#${containerName}`);
        if (!offscreen || !container || exclusions.includes(containerName)) return;

        if (container.children.length > 0) {
            for (const children of container.children) {
                this.elements[children.id] = children;
            }
        } else {
            this.elements[container.id] = container;
        }
    };

    public static GetInstance() {
        if (this._tableExtractor === null) {
            this._tableExtractor = new _OffscreenExtractor();
        }

        return this._tableExtractor;
    }
}

export const OffscreenExtractor = _OffscreenExtractor.GetInstance();
export const extractOffscreen = (...exclusions: ContainerName[]) =>
    _OffscreenExtractor.GetInstance().extractElements(...exclusions);
export const getElements = () => _OffscreenExtractor.GetInstance().getElements();
export const hasElement = (id: string) => _OffscreenExtractor.GetInstance().hasElement(id);
export const getElementById = (id: string) => _OffscreenExtractor.GetInstance().getElementById(id);

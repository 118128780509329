import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setShowPolarity, setShowConnectorAssignment } from "../../../store/overlay/overlay.reducers";
import { showPolarityConfigSelector } from "../../../store/overlay/overlay.selectors"
import { IconButtonProps } from "@corning-ctcm/silica-react";
import { LocalizationKeys } from "../../../locales/keys";
import { useTranslation } from "react-i18next";

export const usePolarityDialog = () => {
    const showPolarity = useSelector(showPolarityConfigSelector)
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const closeDialog = useCallback(() => {
        dispatch(setShowPolarity(false));
        dispatch(setShowConnectorAssignment(true));
    }, [dispatch]);

    const closeProps: Omit<IconButtonProps, "icon"> = {
        id: "polarity-diagram-close-icon",
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.Close),
        placement: "bottom",
        onClick: closeDialog,
    };

    return { showPolarity, closeProps, closeDialog };
};

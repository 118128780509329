import { SecuredService } from "./abstract-secured-v2.service";
import { WizardPreset } from "../store/workspace/build/wizard-preset/wizard-preset";

export class WizardPresetService extends SecuredService {
    private readonly baseUrl = "/api/wizardpreset";

    public async getLatestWizardPreset(): Promise<WizardPreset | undefined> {
        return await this.get<WizardPreset>(`${this.baseUrl}`);
    }

    public async addWizardPreset(wizardPreset: WizardPreset): Promise<WizardPreset | undefined> {
        return await this.post<WizardPreset>(`${this.baseUrl}`, wizardPreset);
    }

    public async updateWizardPreset(userWizardPreset: WizardPreset): Promise<WizardPreset | undefined> {
        return await this.put<WizardPreset>(this.baseUrl, userWizardPreset);
    }

    public async deleteWizardPreset(wizardPresetId: number): Promise<void> {
        return await this.delete<void>(`${this.baseUrl}/${wizardPresetId}`);
    }
}

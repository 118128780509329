import * as Pixi from "pixi.js";
import React from "react";
import { Container, withFilters, Text } from "@pixi/react";
import { DropContext } from "../../../../store/workspace/build/build";
import { BoundingBox } from "../../decorators/bounds/bounding-box";
import { SelectionBox } from "../../decorators/bounds/selection/selection-box";
import { CollapseMarker } from "../../decorators/markers/collapse-marker";
import { DropProps } from "../../../../models/pixi/build/build";
import { ConnectorFurcation } from "../connectors/connector-furcation/connector-furcation";
import { AccessPoint } from "../drop-base/access-point/access-point";
import { DropBase } from "../drop-base/drop-base";
import { useDrop } from "./drop.hooks";
import { DimensionLines } from "../../decorators/dimension-lines/dimension-lines";

const Greyscale = withFilters(Container, { matrix: Pixi.ColorMatrixFilter });

export const Drop: React.FC<DropProps> = (props: DropProps) => {
    const { x, y, context, greyScaleFilter, buffer, furcationProps, furcationLabelProps, collapseMarker } =
        useDrop(props);
    return (
        <DropContext.Provider value={context}>
            <Greyscale x={x} y={y} matrix={greyScaleFilter}>
                <DimensionLines />
                <DropBase />
                <BoundingBox buffer={buffer}>
                    <ConnectorFurcation {...furcationProps} />
                    <SelectionBox />
                </BoundingBox>
                <CollapseMarker {...collapseMarker} />
                <Text {...furcationLabelProps} />
                <AccessPoint />
            </Greyscale>
        </DropContext.Provider>
    );
};

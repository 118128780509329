import { WorkspaceState } from "../../workspace/workspace.reducers";
import { createSelector } from "@reduxjs/toolkit";
export const viewportSelector = (state: WorkspaceState) => state.viewport;
export const viewportIncrementSelector = (state: WorkspaceState) => state.viewport.increment;
export const pageResizeSelector = (state: WorkspaceState) => state.viewport.resize.value;

export const viewportContextSelector = createSelector(viewportSelector, ({ context, isDragging, scale }) => ({
    context,
    isDragging,
    scale,
}));

import { IconButton, OutlinedButton } from "@corning-ctcm/silica-react";
import { useDebugMenu } from "./debug-menu.hooks";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

import "../../../../css/overlay/project-drawer/debug-menu.scss";

export const DebugMenu = () => {
    const { show, open, buttonProps, showSoldTo, addErrorsAndWarnings, generateCatalogCode } = useDebugMenu();
    return !show ? null : (
        <div className="debug-menu">
            <IconButton {...buttonProps} icon={open ? <ExpandLess /> : <ExpandMore />} />
            {!open ? null : (
                <div className="debug-menu-items">
                    <OutlinedButton onClick={showSoldTo}>Set Sold To</OutlinedButton>
                    <OutlinedButton onClick={addErrorsAndWarnings}>Add Errors and Warnings</OutlinedButton>
                    <OutlinedButton onClick={generateCatalogCode}>Generate Catalog Code</OutlinedButton>
                </div>
            )}
        </div>
    );
};

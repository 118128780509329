import { CheckboxProps, DialogProps } from "@corning-ctcm/silica-react";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../locales/keys";
import { setResize } from "../../../../store/pixi/viewport/viewport.reducers";
import { DropInfo } from "../../../../store/workspace/build/drop";
import { setDropsConnectorsCollapse, setDropsGroupsCollapse } from "../../../../store/workspace/build/build.reducers";
import {
    allConnectorsCollapsedSelector,
    allGroupsCollapsedSelector,
    currentCollapseOptionsSelector,
    selectedDropSelector,
} from "../../../../store/workspace/build.selectors";
import { setShowCollapseOptions } from "../../../../store/overlay/overlay.reducers";
import { showCollapseOptionsSelector } from "../../../../store/overlay/overlay.selectors";
import { isLockedSelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";

export const useCollapseOptions = () => {
    const { groups, connectors } = useSelector(currentCollapseOptionsSelector);
    const open = useSelector(showCollapseOptionsSelector);
    const selectedDrop = useSelector(selectedDropSelector);
    const allGroupsCollapsed = useSelector(allGroupsCollapsedSelector);
    const groupsIndeterminate = selectedDrop === undefined && groups && !allGroupsCollapsed;
    const allConnectorsCollapsed = useSelector(allConnectorsCollapsedSelector);
    const connectorsIndeterminate = selectedDrop === undefined && connectors && !allConnectorsCollapsed;
    const locked = useSelector(isLockedSelector);
    const { t } = useTranslation();
    const storeDispatch = useDispatch();

    useEffect(() => {
        storeDispatch(setResize({ update: true, value: "fit-to-page" }));
    }, [groups, connectors, storeDispatch]);

    const onGroupsCheckboxChange = useCallback(
        (e: any) => {
            const collapsed = e.currentTarget.checked || groupsIndeterminate;
            const specificDrop: DropInfo | undefined = selectedDrop
                ? { side: selectedDrop.side, position: selectedDrop.position }
                : undefined;
            storeDispatch(setDropsGroupsCollapse({ specificDrop, collapsed }));
        },
        [groupsIndeterminate, selectedDrop, storeDispatch]
    );

    const groupsCheckbox: CheckboxProps = {
        id: "across-groups-checkbox",
        palette: "primary",
        label: t(LocalizationKeys.AcrossGroups),
        value: groups,
        checked: groups,
        indeterminate: groupsIndeterminate,
        onChange: onGroupsCheckboxChange,
        disabled: locked
    };

    const onConnectorsCheckboxChange = useCallback(
        (e: any) => {
            const collapsed = e.currentTarget.checked || connectorsIndeterminate;
            const specificDrop: DropInfo | undefined = selectedDrop
                ? { side: selectedDrop.side, position: selectedDrop.position }
                : undefined;
            storeDispatch(setDropsConnectorsCollapse({ specificDrop, collapsed }));
        },
        [connectorsIndeterminate, selectedDrop, storeDispatch]
    );

    const connectorsCheckbox: CheckboxProps = {
        id: "within-groups-checkbox",
        palette: "primary",
        label: t(LocalizationKeys.WithinGroups),
        value: connectors,
        checked: connectors,
        indeterminate: connectorsIndeterminate,
        onChange: onConnectorsCheckboxChange,
        disabled: locked
    };

    const onClose = useCallback(() => {
        storeDispatch(setShowCollapseOptions(false));
    }, [storeDispatch]);

    const title = useMemo(() => {
        let title = t(LocalizationKeys.AllCollapseOptions);
        if (selectedDrop) {
            const drop =
                selectedDrop.side === "feeder"
                    ? t(LocalizationKeys.Feeder)
                    : t(LocalizationKeys.DropPosition, { position: selectedDrop.position + 1 });
            title = t(LocalizationKeys.DropCollapseOptions, { drop });
        }
        return title;
    }, [t, selectedDrop]);
    const dialogProps: DialogProps = {
        id: "collapse-options",
        open,
        modal: false,
        className: "collapse-options",
        title,
        header: {
            id: "collapse-options-header",
            closeable: true,
            onClose,
        },
        onClose,
    };

    return {
        dialogProps,
        checkboxes: {
            across: groupsCheckbox,
            within: connectorsCheckbox,
        },
    };
};

import { RefObject } from "react";
import { Aqua, Black, IColor, Red, Yellow } from "../../../ui/dialog/color-dialog";

export interface IMeshOffsetProps {
    value: string;
    helperText?: string;
    isValid?: boolean;
    units?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    inputRef?: RefObject<HTMLInputElement>;
}

export interface IMeshColorProps {
    value: string;
    disabled?: boolean;
    onChange: (color: IColor) => void;
}

export interface IMeshProps {
    mesh: boolean;
    onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    offset: IMeshOffsetProps;
    color: IMeshColorProps;
    disabled?: boolean;
}

export const MeshColors = [Yellow, Aqua, Red, Black];

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setStatusState } from "../../../store/overlay/header/status-icon/status-icon.reducer";
import { WorkspaceStatus } from "../../../store/overlay/header/status-icon/status-icon";
import { IUnitOfMeasure } from "../../../models/overlay/header/units-of-measure";
import { setSelectedBuild, showCableDetails } from "../../../store/overlay/project/project.reducers";
import { BuildService, ToBuildData } from "../../../services/build.service";
import { ProjectManagerService } from "../../../services/project-manager.service";
import { Identifiable } from "../../../models/services/services";
import { createAsyncAction } from "../../../store/workspace/workspace.actions";
import {
    addBuildReducer,
    addBuildsReducer,
    addConnectorAssignmentsAction,
    addEnabledDestinationReducer,
    autoCollapseAction,
    deleteAllConnectorAssignmentAction,
    deleteBuildReducer,
    deleteConnectorAssignmentAction,
    expandAllAction,
    getBuildReducer,
    moveDestinationLeftReducer,
    moveDestinationRightReducer,
    removeEnabledDestinationReducer,
    setAllCablesTiedReducer,
    setAllLabelsReducer,
    setAssemblyTypeReducer,
    setBuildInfoAction,
    setBuildLoadedReducer,
    setBuildsReducer,
    setCablesTiedReducer,
    setCatalogCodeAction,
    setConfigurationTypeAction,
    setConnectorAction,
    setCurrentBuildAutoAccessPointsAction,
    setCurrentBuildReducer,
    setCustomBLengthAction,
    setDestinationsEnabledReducer,
    setDropsConnectorsCollapseAction,
    setDropsGroupsCollapseAction,
    setFlameRatingAction,
    setGroupBLengthAction,
    setLabelDistanceToleranceReducer,
    setLegLengthToleranceReducer,
    setMaskLengthsAction,
    setMeshColorsReducer,
    setMeshLengthToleranceReducer,
    setOverallLengthToleranceReducer,
    setPrintSettingsAction,
    setSourceEnabledReducer,
    setTolerancesReducer,
    updateAllConnectorsAction,
    updateAllDestinationsReducer,
    updateBuildListEntryReducer,
    updateBuildReducer,
    updateConnectorGroupsAction,
    updatedBuildInfoReducer,
    updateDestinationReducer,
    updateDropsReducer,
    updateMeasurementsPositionAction,
    updatePositionedConnectorsAction,
    updateShellTypeAction,
    updateSourceReducer,
} from "./build.actions";
import { IConnectorData } from "./connector/connector";
import { IBuildData, IBuildInfo, initialState } from "./build";
import { Propagation } from "../../../store/overlay/polarity/propagation/propagation";
import { setPropagationNotification } from "../../../store/overlay/notification/notification.reducers";

export const updateConnectorsAsync = createAsyncThunk(
    "build/updateConnectorsAsync",
    async (connectors: IConnectorData[], thunkApi) => {
        const service = new BuildService();
        const connectorUpdateResponse = await service.updateConnectors(connectors);
        return connectorUpdateResponse ? connectorUpdateResponse.connectors : connectors;
    }
);

const buildSlice = createSlice({
    name: "build",
    initialState,
    reducers: {
        getBuild: getBuildReducer,
        addBuild: addBuildReducer,
        addBuilds: addBuildsReducer,
        setBuilds: setBuildsReducer,
        updateAllConnectors: updateAllConnectorsAction,
        updatePositionedConnectors: updatePositionedConnectorsAction,
        updateBuildInfo: updatedBuildInfoReducer,
        updateBuild: updateBuildReducer,
        updateBuildListEntry: updateBuildListEntryReducer,
        updateDrops: updateDropsReducer,
        deleteBuild: deleteBuildReducer,
        setCurrentBuild: setCurrentBuildReducer,
        setSourceEnabled: setSourceEnabledReducer,
        setDestinationEnabled: setDestinationsEnabledReducer,
        setTolerances: setTolerancesReducer,
        setOverallLengthTolerance: setOverallLengthToleranceReducer,
        setLegLengthTolerance: setLegLengthToleranceReducer,
        setMeshLengthTolerance: setMeshLengthToleranceReducer,
        setLabelDistanceTolerance: setLabelDistanceToleranceReducer,
        addEnabledDestination: addEnabledDestinationReducer,
        removeEnabledDestination: removeEnabledDestinationReducer,
        setupSource: updateSourceReducer,
        setupDestination: updateDestinationReducer,
        setupAllDestinations: updateAllDestinationsReducer,
        moveDestinationLeft: moveDestinationLeftReducer,
        moveDestinationRight: moveDestinationRightReducer,
        setBuildLoaded: setBuildLoadedReducer,
        setCablesTied: setCablesTiedReducer,
        setAllCablesTied: setAllCablesTiedReducer,
        autoCollapse: autoCollapseAction,
        expandAll: expandAllAction,
        setBuildInfo: setBuildInfoAction,
        setConnector: setConnectorAction,
        setCurrentBuildAutoAccessPoints: setCurrentBuildAutoAccessPointsAction,
        updateAllConnectorLabels: updateConnectorGroupsAction,
        setGroupBLength: setGroupBLengthAction,
        setCustomBLength: setCustomBLengthAction,
        setFlameRating: setFlameRatingAction,
        setConfigurationType: setConfigurationTypeAction,
        setCatalogCode: setCatalogCodeAction,
        updateShellType: updateShellTypeAction,
        setDropsGroupsCollapse: setDropsGroupsCollapseAction,
        setDropsConnectorsCollapse: setDropsConnectorsCollapseAction,
        updateMeasurementsPosition: updateMeasurementsPositionAction,
        setAssemblyType: setAssemblyTypeReducer,
        setMeshColors: setMeshColorsReducer,
        setAllLabels: setAllLabelsReducer,
        setPrintSettings: setPrintSettingsAction,
        setMaskLengths: setMaskLengthsAction,
        addConnectorAssignments: addConnectorAssignmentsAction,
        deleteConnectorAssignment: deleteConnectorAssignmentAction,
        deleteAllConnectorAssignments: deleteAllConnectorAssignmentAction
    },
    extraReducers: (builder) => {
        builder.addCase(
            updateConnectorsAsync.pending,
            (state, action) =>
                action.payload && updateAllConnectorsAction(state, { payload: action.payload, type: action.type })
        );
        builder.addCase(
            updateConnectorsAsync.fulfilled,
            (state, action) =>
                action.payload && updateAllConnectorsAction(state, { payload: action.payload, type: action.type })
        );
    },
});

export const BuildReducer = buildSlice.reducer;

export const getBuild = (id: number) => {
    return createAsyncAction(async (dispatch) => {
        const service = new BuildService();
        const buildData = await service.getBuild(id);
        if (buildData) {
            dispatch(buildSlice.actions.getBuild(buildData));
        }
    });
};

export const getBuildInfo = (buildId: number) => {
    return createAsyncAction(async (dispatch) => {
        const buildData = await new BuildService().getBuild(buildId);
        if (buildData) {
            dispatch(setSelectedBuild(buildData));
            dispatch(showCableDetails(true));
        }
    });
};

export const updateBuildInfo = (buildInfo: IBuildInfo) => {
    return createAsyncAction(async (dispatch) => {
        const service = new ProjectManagerService();
        const buildInfoDTO = await service.updatedBuildInfo(buildInfo);
        if (buildInfoDTO) {
            dispatch(buildSlice.actions.updateBuildInfo(buildInfoDTO));
        }
    });
};

export const updateBuild = (build: IBuildData) => {
    return createAsyncAction(async (dispatch) => {
        const service = new BuildService();
        const newIds = !hasBuildIds(build);
        const buildDto = await service.updateBuild(build);
        if (buildDto) {
            const buildResponse = ToBuildData(buildDto);
            const updatedBuild: IBuildData = { ...buildResponse };
            if (newIds) {
                dispatch(buildSlice.actions.updateBuild(updatedBuild));
            }
            dispatch(updateBuildListEntry(updatedBuild));
        }
    });
};

export const updateConnectors = (connectors: IConnectorData[]) => {
    return createAsyncAction(async (dispatch) => {
        if (connectors.length > 0) {
            const service = new BuildService();
            const connectorsUpdateResponse = await service.updateConnectors(connectors);
            if (connectorsUpdateResponse) {
                dispatch(buildSlice.actions.updateAllConnectors(connectorsUpdateResponse.connectors));
            }
        }
    });
};

export const updatePositionedConnectors = (connectors: IConnectorData[], propagation?: Propagation) => {
    return createAsyncAction(async (dispatch) => {
        if (connectors.length > 0) {
            dispatch(setStatusState(WorkspaceStatus.Saving));
            const service = new BuildService();
            try {
                const connectorsUpdateResponse = await service.updateConnectors(connectors, propagation);
                if (connectorsUpdateResponse) {
                    const { connectors, propagationResult } = connectorsUpdateResponse;
                    const updatedConnectors = connectors;
                    if (propagationResult) {
                        dispatch(setPropagationNotification(propagationResult));
                        updatedConnectors.push(...propagationResult.connectors);
                    }
                    dispatch(updateSpecificConnectors(updatedConnectors));
                }
            } finally {
                dispatch(setStatusState(WorkspaceStatus.Saved));
            }
        }
    });
};

export const applyPropagation = (propagation: Propagation) => {
    return createAsyncAction(async (dispatch) => {
        dispatch(setStatusState(WorkspaceStatus.Saving));
        const propagationResult = await new BuildService().applyPropagation(propagation);
        if (propagationResult) {
            dispatch(setPropagationNotification(propagationResult));
            if (propagationResult.connectors.length > 0) {
                dispatch(updateSpecificConnectors(propagationResult.connectors));
            }
        }
        dispatch(setStatusState(WorkspaceStatus.Saved));
    });
};

const hasBuildIds = (build: IBuildData) => {
    const [source, ...destinations] = build.drops;
    const sourceGroups = source.groups;
    const destinationGroups = destinations.map((d) => d.groups).reduce((a, b) => [...a, ...b], []);
    const groups = [...sourceGroups, ...destinationGroups];
    const connectors = groups.map((g) => g.connectors).reduce((a, b) => [...a, ...b], []);

    const lengths = [
        source.lengthA,
        ...destinations.map((d) => d.lengthA),
        source.lengthB,
        ...destinations.map((d) => d.lengthB),
        ...groups.map((g) => g.lengthB),
        ...groups.map((g) => g.stagger),
    ]
        .filter((l) => l !== undefined)
        .map((l) => l as IUnitOfMeasure);

    return hasIds(build, source, ...destinations, ...groups, ...connectors, ...lengths);
};

export const hasIds = (...ids: Partial<Identifiable>[]) => {
    return ids.map((i) => i.id).every((a) => !!a);
};

export const {
    setCurrentBuild,
    setSourceEnabled,
    setDestinationEnabled,
    addEnabledDestination,
    removeEnabledDestination,
    setupSource,
    setupDestination,
    setupAllDestinations,
    moveDestinationLeft,
    moveDestinationRight,
    setBuildLoaded,
    setCablesTied,
    autoCollapse,
    expandAll,
    setAllCablesTied,
    setBuildInfo,
    addBuild,
    setBuilds,
    addBuilds,
    setConnector,
    setCurrentBuildAutoAccessPoints,
    updateAllConnectorLabels,
    updateBuildListEntry,
    setGroupBLength,
    setCustomBLength,
    setFlameRating,
    setConfigurationType,
    setCatalogCode,
    updateDrops,
    deleteBuild,
    updatePositionedConnectors: updateSpecificConnectors,
    updateShellType,
    setTolerances,
    setOverallLengthTolerance,
    setLegLengthTolerance,
    setMeshLengthTolerance,
    setLabelDistanceTolerance,
    setDropsGroupsCollapse,
    setDropsConnectorsCollapse,
    updateMeasurementsPosition,
    setAssemblyType,
    setMeshColors,
    setAllLabels,
    setPrintSettings,
    setMaskLengths,
    addConnectorAssignments,
    deleteConnectorAssignment,
    deleteAllConnectorAssignments
} = buildSlice.actions;

export const BuildActions = buildSlice.actions;

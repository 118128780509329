import React from "react";
import { useFiberMappingDialog } from "./fiber-mapping-dialog.hooks";
import { CollapsibleDialog } from "../../../ui/dialog/collapsible-dialog/collapsible-dialog";
import { ContainedButton, OutlinedButton, Typography } from "@corning-ctcm/silica-react";
import { FiberMappingContext } from "../../../../store/overlay/polarity/fiber-mapping-save/fiber-mapping-save";
import { FiberMappingConnector } from "./fiber-mapping-connector/fiber-mapping-connector";
import { GenericDialog } from "../../../ui/dialog/generic-dialog";
import { SectionContext, Feeder, Drop } from "../../../../models/pixi/build/build";
import { Select, SelectItem as Option } from "@corning-ctcm/silica-react";

import "../../../../css/overlay/polarity/fiber-mapping/fiber-mapping-dialog.scss";

export const FiberMappingDialog: React.FC = () => {
    const { dialog, fiberMapName, clearAll, fiberMappingContext, data, actions } = useFiberMappingDialog();
    if (!dialog.display) {
        return null;
    }
    return (
        <CollapsibleDialog {...dialog}>
            <div className="horizontal-divider" />
            <div className="subheader-container">
                <Typography variant="subtitle1">
                    {fiberMapName}
                </Typography>
                <OutlinedButton {...clearAll.button}>
                    {clearAll.label}
                </OutlinedButton>
            </div>
            <FiberMappingContext.Provider value={fiberMappingContext}>
                <SectionContext.Provider value={Feeder}>
                    <div className="mapping-container">
                        <Select {...data.feeder.select}>
                            {
                                data.feeder.options.map((o, i) =>
                                    <Option key={i + 1} value={o.value}>
                                        {o.label}
                                    </Option>
                                )
                            }
                        </Select>
                        <FiberMappingConnector side="feeder" connectorType={data.feederConnectorType} disabled={true} />
                    </div>
                </SectionContext.Provider>
                <SectionContext.Provider value={Drop}>
                    <div className="mapping-container">
                        <div className="selection-container">
                            <Select {...data.distribution.drop.select}>
                                {
                                    data.distribution.drop.options.map((o, i) =>
                                        <Option key={i + 1} value={o.value}>
                                            {o.label}
                                        </Option>
                                    )
                                }
                            </Select>
                            <Select {...data.distribution.connectors.select}>
                                {
                                    data.distribution.connectors.options.map((o, i) =>
                                        <Option key={i + 1} value={o.value}>
                                            {o.label}
                                        </Option>
                                    )
                                }
                            </Select>
                        </div>
                        <FiberMappingConnector side="distribution" connectorType={data.distributionConnectorType} disabled={true} />
                    </div>
                </SectionContext.Provider>
            </FiberMappingContext.Provider>
            <div className="fiber-mapping-footer">
                <div className="fiber-mapping-actions">
                    <ContainedButton {...actions.back.button}>
                        {actions.back.label}
                    </ContainedButton>
                </div>
            </div>
            <GenericDialog {...clearAll.dialog} />
        </CollapsibleDialog>
    );
};

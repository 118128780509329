import MMC24ExtendedTrunk from "../../../../../resources/imgs/polarity/MMC24 Extender Trunk.png";
import MMC24Jumper from "../../../../../resources/imgs/polarity/MMC24 Jumper.png";
import MMC24Trunk from "../../../../../resources/imgs/polarity/MMC24 Trunk.png";
import Mesh4x4 from "../../../../../resources/imgs/polarity/Type 4x4 Mesh MTP_MTP.png";
import TypeAMMC16 from "../../../../../resources/imgs/polarity/Type A MMC16_MMC16.png";
import TypeAMTP24MTP12 from "../../../../../resources/imgs/polarity/Type A MTP24_MTP12.png";
import TypeAMTP24MTP4 from "../../../../../resources/imgs/polarity/Type A MTP24_MTP24.png";
import TypeAMTP24MTP8 from "../../../../../resources/imgs/polarity/Type A MTP24_MTP8.png";
import TypeAMTP24 from "../../../../../resources/imgs/polarity/Type A MTP_LC 24f Breakout.png";
import TypeAMTP8 from "../../../../../resources/imgs/polarity/Type A MTP_MTP 8f.png";
import TypeAMTP12 from "../../../../../resources/imgs/polarity/Type A MTP_MTP.png";
import AtoA from "../../../../../resources/imgs/polarity/Type A-A LC-LC.png";
import AtoB from "../../../../../resources/imgs/polarity/Type A-B LC-LC.png";
import TypeBMMC16 from "../../../../../resources/imgs/polarity/Type B MMC16_MMC16.png";
import TypeBMTP24MTP12 from "../../../../../resources/imgs/polarity/Type B MTP24_MTP12.png";
import TypeBMTP24MTP24 from "../../../../../resources/imgs/polarity/Type B MTP24_MTP24.png";
import {
    default as TypeBMTP12,
    default as TypeBMTP8,
} from "../../../../../resources/imgs/polarity/Type B MTP_MTP 8f.png";
import TypeLMTP24MTP12 from "../../../../../resources/imgs/polarity/Type L MTP24_MTP12.png";
import TypeUMTP12 from "../../../../../resources/imgs/polarity/Type U MTP_LC 12f Breakout.png";
import TypeYMTP24MTP24 from "../../../../../resources/imgs/polarity/Type Y MTP24_MTP24.png";
import TypeZMTP24MTP24 from "../../../../../resources/imgs/polarity/Type Z MTP24_MTP24.png";
import TypeUMTP8 from "../../../../../resources/imgs/polarity/Type-U MTP-LC 8f.png";

import { TFunction } from "i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import { Identifiable } from "../../../../../models/services/services";
import { getCurrentISODate } from "../../../../../services/build.service";
import {
    ConnLC,
    ConnLCSimplex,
    ConnMMC16_NP,
    ConnMMC24_NP,
    ConnMMC24_P,
    ConnMTP12_NP,
    ConnMTP24_P,
    ConnMTP8_NP,
    ConnSCAPCSimplex,
    getConnectorFamilyString,
    IConnectorType,
} from "../../../../../store/overlay/wizard/wizard";
import {
    AssignedIndexCodes,
    CustomFiberMapData,
    IFiberMapData,
    IFiberMapIndex,
    connectorModelToConnectorType,
} from "./fiber-map";
import { isMPO } from "../../../../../components/pixi/factories/texture";

interface IPolarityConfig {
    from: IConnectorType;
    to: IConnectorType;
    description: string;
    polarityMaps: PolarityMap[];
}

interface IBuildPolarity {
    from: IConnectorType;
    to: IConnectorType;
    description: string;
    polarityMap: PolarityMap;
}

export interface IPolarityMap {
    userId: number;
    key: number;
    customKey: string; // Used in the backend + display purposes
    description: string;
    descriptionLocalizationKey: string;
    imageUri: string;
    sourceConnectors: IConnectorType[];
    destinationConnectors: IConnectorType[];
    versionDate: string;
    sessionId: string;
    demoKey: string; // Used for display purposes
    sessionKey: string;
}

interface IRequiredPolarityMapFields {
    sourceConnectors: IConnectorType[];
    destinationConnectors: IConnectorType[];
}

const mtp8 = { ...ConnMTP8_NP };
const mtp12 = { ...ConnMTP12_NP };
const mmc16 = { ...ConnMMC16_NP };
const mmc24_np = { ...ConnMMC24_NP };
const mmc24_p = { ...ConnMMC24_P };
const lc_simplex = { ...ConnLCSimplex };
const sc_simplex = { ...ConnSCAPCSimplex };
const lc = { ...ConnLC };
const mtp24 = { ...ConnMTP24_P };

interface IBuildPolarityPair {
    buildId: number;
    polarities: BuildPolarity[];
}

export interface IConnectoryCombination {
    source: IConnectorType;
    destination: IConnectorType;
}

export type PolarityConfig = Partial<IPolarityConfig & Identifiable>;
export type PolarityMap = Partial<IPolarityMap & Identifiable> & Required<IRequiredPolarityMapFields>;
export type BuildPolarity = Partial<IBuildPolarity & Identifiable>;
export type BuildPolarityPair = Partial<IBuildPolarityPair>;

export const initialPolarityMap: PolarityMap = {
    key: -1,
    description: "",
    imageUri: "",
    sourceConnectors: [],
    destinationConnectors: [],
};

export interface IPolarityFiberMapDTO {
    id?: number;
    userId?: number;
    groupId?: number;
    name: string;
    connectorFiberPinMapping: IConnectorFiberPinMapDTO[];
    versionDate: string;
    polarityKey?: string;
}

export interface IConnectorFiberPinMapDTO {
    id?: number;
    assignedIndex: number;
    index: number;
    blocked: boolean;
    connectorType: string;
    side?: string;
    position?: number;
}

export function findPolarityConfig<T = BuildPolarity | PolarityConfig>(
    source: IConnectorType | undefined,
    destination: IConnectorType | undefined,
    polarityConfigs: BuildPolarity[] | PolarityConfig[]
) {
    return polarityConfigs.find((lc) => matchConnectorType(lc.from, lc.to, source, destination)) as T;
}

export function matchConnectorType(
    s1: IConnectorType | undefined,
    d1: IConnectorType | undefined,
    s2: IConnectorType | undefined,
    d2: IConnectorType | undefined
) {
    if (!s1 || !d1 || !s2 || !d2) return false;

    const s1FiberCount = s1.fiberCount;
    const s2FiberCount = s2.fiberCount;
    const d1FiberCount = d1.fiberCount;
    const d2FiberCount = d2.fiberCount;
    const fiberCountMatch = s1FiberCount === s2FiberCount && d1FiberCount === d2FiberCount;

    const s1Family = getConnectorFamilyString(s1);
    const s2Family = getConnectorFamilyString(s2);
    const d1Family = getConnectorFamilyString(d1);
    const d2Family = getConnectorFamilyString(d2);
    const connectorTypeMatch = s1Family === s2Family && d1Family === d2Family;

    return [s1Family, s2Family, s1Family, d2Family].some(isMPO) 
        ? fiberCountMatch 
        : fiberCountMatch && connectorTypeMatch;
}

export function toPolarityFiberMapDTO(fiberMap?: CustomFiberMapData): IPolarityFiberMapDTO | undefined {
    if (!fiberMap) return undefined;

    return {
        id: fiberMap.id ?? 0,
        name: fiberMap.name.length > 0 ? fiberMap.name : "Custom",
        versionDate: fiberMap.versionDate ?? getCurrentISODate(),
        userId: fiberMap.userId,
        groupId: fiberMap.groupId,
        connectorFiberPinMapping: toConnectorFiberPinMappingListDTO(fiberMap),
        polarityKey: GetPolarityKey(fiberMap.key.toString()),
    };
}

export function toConnectorFiberPinMappingListDTO(fiberMap: CustomFiberMapData): IConnectorFiberPinMapDTO[] {
    const { sourceIndices, destinationIndices } = fiberMap;

    if (sourceIndices.length === 0 && destinationIndices.length === 0) return [];

    const sourcePinMaps = sourceIndices.map((s) => toFiberPinMappingDTO(s));
    const destinationPinMaps = destinationIndices.map((d) => toFiberPinMappingDTO(d));

    return [...sourcePinMaps, ...destinationPinMaps];
}

function toFiberPinMappingDTO(fiberIndex: IFiberMapIndex): IConnectorFiberPinMapDTO {
    const { connector } = fiberIndex;
    const connectorType = connectorModelToConnectorType(connector);
    const connectorTypeString = connectorType && connectorType.type ? connectorType.type : "";

    return {
        id: fiberIndex.id,
        blocked: fiberIndex.assignedIndex === AssignedIndexCodes.Blocked,
        assignedIndex: fiberIndex.assignedIndex,
        index: fiberIndex.index,
        connectorType: fiberIndex.connectorType || connectorTypeString,
        side: fiberIndex.side,
        position: fiberIndex.transceiver,
    };
}

export const SELECT_MAP_KEY = -1;
export const ATOA_KEY = 0;
export const ATOB_KEY = 1;
export const TYPEU_MTP8_KEY = 2;
export const TYPEU_MTP12_KEY = 3;
export const TYPEA_MTP24 = 4;
export const TYPEB_MTP8 = 5;
export const TYPEA_MTP8 = 6;
export const MESH_4x4_MAP = 7;
export const TYPEB_MTP12 = 8;
export const TYPEA_MTP12 = 9;
export const TYPEB_MTP8ToLC = 10;
export const TYPEB_MTP12ToLC = 11;
export const TYPEA_MTP24ToMTP4 = 12;
export const TYPEB_MTP24ToMTP24 = 13;
export const TYPEY_MTP24ToMTP24 = 14;
export const TYPEZ_MTP24ToMTP24 = 15;
export const TYPEA_MTP24ToMTP8 = 16;
export const TYPEA_MTP24ToMTP12 = 17;
export const TYPEB_MTP24ToMTP12 = 18;
export const TYPEL_MTP24ToMTP12 = 19;
export const TYPEA_MMC16 = 20;
export const TYPEB_MMC16 = 21;
export const EXTENDER_TRUNK_MMC24 = 22;
export const TRUNK_MMC24 = 23;
export const JUMPER_MMC24 = 24;
export const TYPEA_LCSimplex = 25;
export const TYPEA_SCSimplex = 26;
export const CUSTOM_MAP_KEY = 9999;
export const SESSION_MAP_KEY = 9000;

const Type_A_A = "Type A-A";
const Type_A_B = "Type A-B";
const MTP8_LC_Type_U = "MTP8_LC Type U";
const MTP12_LC_Type_U = "MTP12_LC Type U";
const MTP24_Type_A = "MTP24 Type A";
const MTP8_Type_B = "MTP8 Type B";
const MTP8_Type_A = "MTP8 Type A";
const MTP8_Type_4x4_Mesh = "MTP8 Type 4x4 Mesh";
const MTP12_Type_B = "MTP12 Type B";
const MTP12_Type_A = "MTP12 Type A";
const MTP8_LC_Type_B = "MTP8_LC Type B";
const MTP12_LC_Type_B = "MTP12_LC Type B";
const MTP24_MTP24_Type_A = "MTP24_MTP24 Type A";
const MTP24_MTP24_Type_B = "MTP24_MTP24 Type B";
const MTP24_MTP24_Type_Y = "MTP24_MTP24 Type Y";
const MTP24_MTP24_Type_Z = "MTP24_MTP24 Type Z";
const MTP24_MTP8_Type_A = "MTP24_MTP8 Type A";
const MTP24_MTP12_TYPEA = "MTP24_MTP12 Type A";
const MTP24_MTP12_TYPEB = "MTP24_MTP12 Type B";
const MTP24_MTP12_TYPEL = "MTP24_MTP12 Type L";
const MMC16_TYPE_A = "MMC16 Type A";
const MMC16_TYPE_B = "MMC16 Type B";
const MMC24_EXTENDER_TRUNK = "MMC24 Extender Trunk";
const MMC24_TRUNK = "MMC24 Trunk";
const MMC24_JUMPER = "MMC24 Jumper";
const LC_SIMPLEX_TYPE_A = "LCSimplex Type A";
const SC_SIMPLEX_TYPE_A = "SCSimplex Type A";

export const SelectMap: PolarityMap = {
    descriptionLocalizationKey: LocalizationKeys.SelectPolarity,
    key: SELECT_MAP_KEY,
    destinationConnectors: [],
    sourceConnectors: [],
};
export const AtoAmap: PolarityMap = {
    description: "Type A-A",
    imageUri: AtoA,
    key: ATOA_KEY,
    sourceConnectors: [lc],
    destinationConnectors: [lc],
    sessionId: "02_02_A",
    sessionKey: "02F-to-02F (Type A-A)",
    demoKey: Type_A_A,
};
export const AtoBMap: PolarityMap = {
    description: "Type A-B",
    imageUri: AtoB,
    key: ATOB_KEY,
    sourceConnectors: [lc],
    destinationConnectors: [lc],
    sessionId: "02_02_B",
    sessionKey: "02F-to-02F (Type A-B)",
    demoKey: Type_A_B,
};
export const TypeUMTP8Map: PolarityMap = {
    description: "Type U",
    imageUri: TypeUMTP8,
    key: TYPEU_MTP8_KEY,
    sourceConnectors: [mtp8],
    destinationConnectors: new Array(4).fill(lc),
    sessionId: "08_02X4_U",
    sessionKey: "08F-to-02x4F (Type U)",
    demoKey: MTP8_LC_Type_U,
};
export const TypeUMTP12Map: PolarityMap = {
    description: "Type U",
    imageUri: TypeUMTP12,
    key: TYPEU_MTP12_KEY,
    sourceConnectors: [mtp12],
    destinationConnectors: new Array(6).fill(lc),
    sessionId: "12_02X6_U",
    sessionKey: "12F-to-02x6F (Type U)",
    demoKey: MTP12_LC_Type_U,
};
export const TypeAMTP24Map: PolarityMap = {
    description: "Type A",
    imageUri: TypeAMTP24,
    key: TYPEA_MTP24,
    sourceConnectors: [mtp24],
    destinationConnectors: new Array(12).fill(lc),
    sessionId: "24_02X12_A",
    sessionKey: "24F-to-02Fx12 (Type A)",
    demoKey: MTP24_Type_A,
};
export const TypeBMTP8Map: PolarityMap = {
    description: "Type B",
    imageUri: TypeBMTP8,
    key: TYPEB_MTP8,
    sourceConnectors: [mtp8],
    destinationConnectors: [mtp8],
    sessionId: "08_08_B",
    sessionKey: "08F-to-08F (Type B)",
    demoKey: MTP8_Type_B,
};
export const TypeAMTP8Map: PolarityMap = {
    description: "Type A",
    imageUri: TypeAMTP8,
    key: TYPEA_MTP8,
    sourceConnectors: [mtp8],
    destinationConnectors: [mtp8],
    sessionId: "08_08_A",
    sessionKey: "08F-to-08F (Type A)",
    demoKey: MTP8_Type_A,
};
export const Mesh4x4Map: PolarityMap = {
    description: "Type 4x4 Mesh",
    imageUri: Mesh4x4,
    key: MESH_4x4_MAP,
    sourceConnectors: new Array(4).fill(mtp8),
    destinationConnectors: new Array(4).fill(mtp8),
    sessionId: "08X4_08X4_B_4X4",
    sessionKey: "08Fx4-to-08Fx4 (Type B, 4x4 Mesh)",
    demoKey: MTP8_Type_4x4_Mesh,
};
export const TypeBMTP12Map: PolarityMap = {
    description: "Type B",
    imageUri: TypeBMTP12,
    key: TYPEB_MTP12,
    sourceConnectors: [mtp12],
    destinationConnectors: [mtp12],
    sessionId: "12_12_A",
    sessionKey: "12F-to-12F (Type A)",
    demoKey: MTP12_Type_B,
};
export const TypeAMTP12Map: PolarityMap = {
    description: "Type A",
    imageUri: TypeAMTP12,
    key: TYPEA_MTP12,
    sourceConnectors: [mtp12],
    destinationConnectors: [mtp12],
    sessionId: "12_12_B",
    sessionKey: "12F-to-12F (Type B)",
    demoKey: MTP12_Type_A,
};
export const TypeBMTP8ToLC: PolarityMap = {
    description: "Type B",
    key: TYPEB_MTP8ToLC,
    sourceConnectors: [mtp8],
    destinationConnectors: new Array(4).fill(lc),
    demoKey: MTP8_LC_Type_B,
};
export const TypeBMTP12ToLC: PolarityMap = {
    description: "Type B",
    key: TYPEB_MTP12ToLC,
    sourceConnectors: [mtp12],
    destinationConnectors: new Array(6).fill(lc),
    demoKey: MTP12_LC_Type_B,
};
export const TypeAMTP24ToMTP24: PolarityMap = {
    description: "Type A",
    imageUri: TypeAMTP24MTP4,
    key: TYPEA_MTP24ToMTP4,
    sourceConnectors: [mtp24],
    destinationConnectors: [mtp24],
    sessionId: "24_24_A",
    sessionKey: "24F-to-24F (Type A)",
    demoKey: MTP24_MTP24_Type_A,
};
export const ExtenderTrunkMMC24: PolarityMap = {
    description: "Extender Trunk",
    imageUri: MMC24ExtendedTrunk,
    key: EXTENDER_TRUNK_MMC24,
    sourceConnectors: [mmc24_np],
    destinationConnectors: [mmc24_p],
    sessionId: "24_24_A",
    sessionKey: "24F-to-24F (Type A)",
    demoKey: MTP24_MTP24_Type_A,
    customKey: MMC24_EXTENDER_TRUNK,
};
export const TrunkMMC24: PolarityMap = {
    description: "Trunk",
    imageUri: MMC24Trunk,
    key: TRUNK_MMC24,
    sourceConnectors: [mmc24_p],
    destinationConnectors: [mmc24_p],
    sessionId: "24_24_Z",
    sessionKey: "24F-to-24F (Type Z)",
    demoKey: MTP24_MTP24_Type_Z,
    customKey: MMC24_TRUNK,
};
export const JumperMMC24: PolarityMap = {
    description: "Jumper",
    imageUri: MMC24Jumper,
    key: JUMPER_MMC24,
    sourceConnectors: [mmc24_np],
    destinationConnectors: [mmc24_p],
    sessionId: "24_24_Z",
    sessionKey: "24F-to-24F (Type Z)",
    demoKey: MTP24_MTP24_Type_Z,
    customKey: MMC24_JUMPER,
};
export const TypeBMTP24ToMTP24: PolarityMap = {
    description: "Type B",
    imageUri: TypeBMTP24MTP24,
    key: TYPEB_MTP24ToMTP24,
    sourceConnectors: [mtp24],
    destinationConnectors: [mtp24],
    sessionId: "24_24_B",
    sessionKey: "24F-to-24F (Type B)",
    demoKey: MTP24_MTP24_Type_B,
};
export const TypeYMTP24ToMTP24: PolarityMap = {
    description: "Type Y",
    imageUri: TypeYMTP24MTP24,
    key: TYPEY_MTP24ToMTP24,
    sourceConnectors: [mtp24],
    destinationConnectors: [mtp24],
    sessionId: "24_24_Y",
    sessionKey: "24F-to-24F (Type Y)",
    demoKey: MTP24_MTP24_Type_Y,
};
export const TypeZMTP24ToMTP24: PolarityMap = {
    description: "Type Z",
    imageUri: TypeZMTP24MTP24,
    key: TYPEZ_MTP24ToMTP24,
    sourceConnectors: [mtp24],
    destinationConnectors: [mtp24],
    sessionId: "24_24_Z",
    sessionKey: "24F-to-24F (Type Z)",
    demoKey: MTP24_MTP24_Type_Z,
};
export const TypeAMTP24ToMTP8: PolarityMap = {
    description: "Type A",
    imageUri: TypeAMTP24MTP8,
    key: TYPEA_MTP24ToMTP8,
    sourceConnectors: [mtp24],
    destinationConnectors: new Array(3).fill(mtp8),
    sessionId: "24_08X03_A",
    sessionKey: "24F-to-08Fx3 (Type A)",
    demoKey: MTP24_MTP8_Type_A,
};
export const TypeAMTP24ToMTP12: PolarityMap = {
    description: "Type A",
    imageUri: TypeAMTP24MTP12,
    key: TYPEA_MTP24ToMTP12,
    sourceConnectors: [mtp24],
    destinationConnectors: new Array(2).fill(mtp12),
    sessionId: "24_12X02_A",
    sessionKey: "24F-to-12Fx2 (Type A)",
    demoKey: MTP24_MTP12_TYPEA,
};
export const TypeBMTP24ToMTP12: PolarityMap = {
    description: "Type B",
    imageUri: TypeBMTP24MTP12,
    key: TYPEB_MTP24ToMTP12,
    sourceConnectors: [mtp24],
    destinationConnectors: new Array(2).fill(mtp12),
    sessionId: "24_12X02_B",
    sessionKey: "24F-to-12Fx2 (Type B)",
    demoKey: MTP24_MTP12_TYPEB,
};
export const TypeLMTP24ToMTP12: PolarityMap = {
    description: "Type L",
    imageUri: TypeLMTP24MTP12,
    key: TYPEL_MTP24ToMTP12,
    sourceConnectors: [mtp24],
    destinationConnectors: new Array(2).fill(mtp12),
    sessionId: "24_12X02_L",
    sessionKey: "24F-to-12Fx2 (Type L)",
    demoKey: MTP24_MTP12_TYPEL,
};
export const TypeAMMC16Map: PolarityMap = {
    description: "Type A",
    imageUri: TypeAMMC16,
    key: TYPEA_MMC16,
    sourceConnectors: [mmc16],
    destinationConnectors: [mmc16],
    sessionId: "16_16_A",
    sessionKey: "16F-to-16F (Type A)",
    demoKey: MMC16_TYPE_A,
};
export const TypeBMMC16Map: PolarityMap = {
    description: "Type B",
    imageUri: TypeBMMC16,
    key: TYPEB_MMC16,
    sourceConnectors: [mmc16],
    destinationConnectors: [mmc16],
    sessionId: "16_16_B",
    sessionKey: "16F-to-16F (Type B)",
    demoKey: MMC16_TYPE_B,
};
export const TypeALCSimplexMap: PolarityMap = {
    description: "Type A",
    key: TYPEA_LCSimplex,
    sourceConnectors: [lc_simplex],
    destinationConnectors: [lc_simplex],
    sessionId: "1_1_A",
    sessionKey: "1F-to-1F (Type A)",
    demoKey: LC_SIMPLEX_TYPE_A,
};
export const TypeASCSimplexMap: PolarityMap = {
    description: "Type A",
    key: TYPEA_SCSimplex,
    sourceConnectors: [sc_simplex],
    destinationConnectors: [sc_simplex],
    sessionId: "1_1_A",
    sessionKey: "1F-to-1F (Type A)",
    demoKey: SC_SIMPLEX_TYPE_A,
};
export const CustomMap: PolarityMap = {
    descriptionLocalizationKey: LocalizationKeys.Custom,
    key: CUSTOM_MAP_KEY,
    destinationConnectors: [],
    sourceConnectors: [],
    demoKey: `${CUSTOM_MAP_KEY}`,
};

interface IPolarityState {
    configs: BuildPolarity[];
    buildConfigList: BuildPolarityPair[];
    selectedConfig: PolarityConfig;
    fiberMaps: IFiberMapData[];
}

export type PolarityState = Partial<IPolarityState & Identifiable>;

export const initialPolarityState: PolarityState = {
    configs: [],
    buildConfigList: [],
    selectedConfig: undefined,
    fiberMaps: [],
};

export const LCToLCMapList: PolarityMap[] = [AtoAmap, AtoBMap, TypeALCSimplexMap];

export const SCToSCMapList: PolarityMap[] = [TypeASCSimplexMap];

export const MTPtoLCMapList: PolarityMap[] = [
    TypeUMTP8Map,
    TypeUMTP12Map,
    TypeAMTP24Map,
    TypeBMTP8ToLC,
    TypeBMTP12ToLC,
];

export const MTPtoMTPMapList: PolarityMap[] = [
    TypeBMTP8Map,
    TypeAMTP8Map,
    Mesh4x4Map,
    TypeBMTP12Map,
    TypeAMTP12Map,
    TypeAMTP24ToMTP24,
    TypeBMTP24ToMTP24,
    TypeYMTP24ToMTP24,
    TypeZMTP24ToMTP24,
    TypeAMTP24ToMTP8,
    TypeAMTP24ToMTP12,
    TypeBMTP24ToMTP12,
    TypeLMTP24ToMTP12,
];

export const MMCtoMMCMapList: PolarityMap[] = [
    TypeAMMC16Map,
    TypeBMMC16Map,
    ExtenderTrunkMMC24,
    TrunkMMC24,
    JumperMMC24,
];

export const PolarityMapList: PolarityMap[] = [
    ...LCToLCMapList,
    ...SCToSCMapList,
    ...MTPtoLCMapList,
    ...MTPtoMTPMapList,
    ...MMCtoMMCMapList,
];
export const PolarityMaps: { [key: string]: PolarityMap } = {};
PolarityMapList.forEach((m) => (PolarityMaps[m.sessionId!] = m));

export function getPolarityMap(source: IConnectorType[], destination: IConnectorType[], customList?: PolarityMap[]) {
    const polarityMapList = customList ? customList : PolarityMapList;
    const polarityMaps =
        source.length && destination.length
            ? polarityMapList.filter((m) => {
                  const validOneToOneMatch =
                      matchConnectorTypeSequence(source, destination) &&
                      m.sourceConnectors.length === 1 &&
                      m.destinationConnectors.length === 1;
                  if (!validOneToOneMatch) return validOneToOneMatch;
                  const validSourceMatch = matchConnectorTypeSequence(source, m.sourceConnectors);
                  if (!validSourceMatch) return validSourceMatch;
                  const validDestinationMatch = matchConnectorTypeSequence(destination, m.destinationConnectors);
                  if (!validDestinationMatch) return validDestinationMatch;
                  return true;
              })
            : [];

    return polarityMaps;
}

export function getPolarityMaps(polarityMaps: PolarityMap[]): PolarityMap[] {
    return PolarityMapList.map((m) => {
        let polarityMap = polarityMaps.find((p) => p.customKey === m.demoKey) ?? m;
        if (m.customKey) {
            polarityMap = polarityMaps.find((p) => p.customKey === m.customKey) ?? m;
        }
        return { ...polarityMap, customKey: polarityMap.customKey ?? polarityMap.demoKey, description: m.description };
    });
}

export function matchConnectorTypeSequence(connectors: IConnectorType[], mapConnectors: IConnectorType[]) {
    let validMatch = true;
    for (let i = 0; i < connectors.length; i++) {
        const sourceConnectorType = connectors[i];
        const sourceConnectorMapType = mapConnectors[i % mapConnectors.length];
        const isMatch = matchConnectorType(
            sourceConnectorType,
            sourceConnectorType,
            sourceConnectorMapType,
            sourceConnectorMapType
        );

        if (!isMatch) {
            validMatch = false;
            break;
        }
    }

    return validMatch;
}

export function GetPolarityImgURL(key: number) {
    return PolarityMaps[key]?.imageUri;
}

export function GetPolarityMapSessionImgUrl(id: string) {
    return PolarityMapList.find((m) => m.sessionId === id || id === m.demoKey || id === m.sessionKey)?.imageUri;
}

export const GetPolarityKey = (key: string) => {
    if (key.length === 0) return `${CUSTOM_MAP_KEY}`;

    const map = PolarityMapList.find((p) => p.customKey === key || p.demoKey === key || p.sessionKey === key);
    return map ? `${map.key}` : key === `${CUSTOM_MAP_KEY}` ? key : `${SESSION_MAP_KEY}`;
};

export const getPolarityId = (polarityMap: PolarityMap | undefined) => {
    const defaultPolarityId = `${CUSTOM_MAP_KEY}`;
    if (!polarityMap || polarityMap?.customKey?.length === 0) return defaultPolarityId;

    const defaultPolarityMap = PolarityMapList.find(
        (p) => p.customKey === polarityMap.customKey || p.demoKey === polarityMap.customKey
    );

    return defaultPolarityMap?.demoKey ?? defaultPolarityId;
};

export const GetPolaritySessionId = (key: string) => {
    if (key.length === 0) return `${SESSION_MAP_KEY}`;

    return (
        PolarityMapList.find((p) => p.customKey === key || p.demoKey === key || p.sessionKey === key)?.sessionId ?? key
    );
};

export const generateConnectorType = (fiberCount: number): IConnectorType => {
    switch (fiberCount) {
        case 24:
            return mtp24;
        case 12:
            return mtp12;
        case 16:
            return mmc16;
        case 8:
            return mtp8;
        case 2:
            return lc;
        case 1:
            return lc_simplex;
        default:
            return {
                description: "",
                fiberCount,
                key: "",
            };
    }
};

export const getPolarityMapPolarityType = (polarityMap: PolarityMap): string => {
    if (polarityMap.key === CUSTOM_MAP_KEY || polarityMap.key === SESSION_MAP_KEY) {
        return polarityMap.key?.toString();
    }

    return polarityMap.customKey ?? polarityMap.key?.toString() ?? "";
};

export const getPolarityMapDescription = (polarityMap: PolarityMap, t: TFunction): string => {
    if (polarityMap.description) return polarityMap.description;
    if (polarityMap.descriptionLocalizationKey) return t(polarityMap.descriptionLocalizationKey);
    return "";
};

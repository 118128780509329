import { createContext } from "react";
import { setDisplayAction } from "./drawer.actions";
import { DrawerActions, DrawerActionTypes, DrawerContextProps, initialDrawerContext } from "../../../models/ui/drawer/drawer";

export function DrawerReducer(state: DrawerContextProps, action: DrawerActions): DrawerContextProps {
    switch (action.type) {
        case DrawerActionTypes.SetDisplay:
            return setDisplayAction(state, action.payload);
        default:
            return { ...state };
    }
}

export const DrawerContext = createContext(initialDrawerContext);
import React from "react";
import { CollapsibleDialog } from "../../../ui/dialog/collapsible-dialog/collapsible-dialog";
import { useEditTolerancesDialog } from "./edit-tolerances.hooks";
import { ContainedButton, OutlinedButton, Checkbox } from "@corning-ctcm/silica-react";
import { TolerancesField } from "./tolerances-field/tolerances-field";
import { FormHelperText } from "@mui/material";

import "../../../../css/overlay/header/tolerances/edit-tolerances-dialog.scss";

export const EditTolerancesDialog: React.FC = () => {
    const {
        dialogProps,
        overallLength,
        legLength,
        meshLength,
        labelDistance,
        cancel,
        save,
        checkboxProps,
        helperText,
    } = useEditTolerancesDialog();

    return (
        <CollapsibleDialog {...dialogProps}>
            <TolerancesField {...overallLength} />
            <TolerancesField {...legLength} />
            <TolerancesField {...meshLength} />
            <TolerancesField {...labelDistance} />
            <div className="action-container">
                <div className="toggle-tolerances">
                    <Checkbox {...checkboxProps} />
                    <FormHelperText>{helperText}</FormHelperText>
                </div>
                <div className="actions">
                    <OutlinedButton {...cancel.props}>{cancel.label}</OutlinedButton>
                    <ContainedButton {...save.props}>{save.label}</ContainedButton>
                </div>
            </div>
        </CollapsibleDialog>
    );
};

import { Dialog } from "@corning-ctcm/silica-react";
import { usePolarityDialog } from "./polarity-dialog.hooks";
import { AssignPolarity } from "./assign-polarity/assign-polarity";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../locales/keys";

import "../../../css/overlay/polarity/polarity-dialog.scss";

export const PolarityDialog: React.FC = () => {
    const { t } = useTranslation();
    const { showPolarity, closeDialog } = usePolarityDialog();
    return showPolarity ? (
        <Dialog
            className="toggle-pointer-events polarity-card"
            open={showPolarity}
            onClose={closeDialog}
            title={t(LocalizationKeys.Polarity)}
        >
            <AssignPolarity />
        </Dialog>
    ) : null;
};

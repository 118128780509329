import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { useUnitsOfMeasureContainer } from "./units-of-measure-container.hooks";
import { Typography, Select, SelectItem as Option, IconButton } from "@corning-ctcm/silica-react";
import { Close } from "@mui/icons-material";

import "../../../../css/overlay/header/units-of-measure-container.scss";

export const UnitsOfMeasureContainer = () => {
    const { t } = useTranslation();
    const { display, primary, secondary, closeProps } = useUnitsOfMeasureContainer();

    if (!display) return null;

    return (
        <div id="units-dialog" className="units-panel toggle-pointer-events">
            <div className="units-header">
                <div id="units-title">
                    <Typography variant="h6">
                        {t(LocalizationKeys.UnitsSetup)}
                    </Typography>
                </div>
                <IconButton {...closeProps} icon={<Close />} />
            </div>
            <div className="units-container">
                <div id="measurement-system-dropdown">
                    <Select {...primary.props}>
                        {primary.options.map((c) => (
                            <Option id={`${c.name}-system`} key={c.key} value={c.value}>
                                {c.name}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div id="unit-dropdown">
                    <Select {...secondary.props}>
                        {secondary.options.map((c) => (
                            <Option id={c.name} key={c.key} value={c.value}>
                                {c.name}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
        </div>
    );
};

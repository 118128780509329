import { Graphics } from "pixi.js";
import { IPoint } from "../../pixi";

export const drawCollapseMarker = (grfx: Graphics, { x, y }: IPoint, color: number) => {
    const dotOffset = 5;
    const dotRadius = 0.7;

    grfx.lineStyle(1, color);
    grfx.drawCircle(x, y, 14);
    grfx.beginFill(color);
    grfx.drawCircle(x - dotOffset, y + dotOffset, dotRadius);
    grfx.drawCircle(x, y + dotOffset, dotRadius);
    grfx.drawCircle(x + dotOffset, y + dotOffset, dotRadius);
    grfx.endFill();
};

import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { ZoomReducer } from "../overlay/footer/zoom-legacy/zoom-legacy.reducers";
import { PageResizeReducer } from "../overlay/footer/page-resize-legacy/page-resize-legacy.reducers";
import { BuildReducer } from "./build/build.reducers";
import { AuthenticationReducer } from "../authentication/authentication.reducers";
import { WizardReducer } from "../overlay/wizard/wizard.reducers";
import { EditToolbarReducer } from "../overlay/footer/toolbar/toolbar.reducers";
import { ViewportReducer } from "../pixi/viewport/viewport.reducers";
import { UnitOfMeasureContainerReducer } from "../overlay/header/units-of-measure-container/units-of-measure-container.reducers";
import { StatusReducer } from "../overlay/header/status-icon/status-icon.reducer";
import { ProjectManagerReducer } from "../overlay/project/project.reducers";
import { OverlayReducer } from "../overlay/overlay.reducers";
import { ReportDocumentReducer } from "./document/document.reducers";
import { LabelSchemeReducer } from "./build/connector/label-scheme/label-scheme.reducers";
import { PolarityConfigurationReducer } from "./build/connector/polarity/polarity.reducer";
import { UserWizardPresetReducer } from "./build/wizard-preset/wizard-preset.reducers";
import { SSCSessionReducer } from "./ssc/ssc.reducer";
import { WarningsReducer } from "../overlay/header/warnings/warnings.reducer";
import { BoundariesReducer } from "./boundaries/boundaries.reducers";
import { ConnectorHighlightsReducer } from "../pixi/connector-highlights/connector-highlights.reducers";
import { PropagationReducer } from "../overlay/polarity/propagation/propagation.reducers";
import { NotificationManagerReducer } from "../overlay/notification/notification.reducers";

export { connect } from "react-redux";

export const WorkspaceReducer = combineReducers({
    authentication: AuthenticationReducer,
    zoom: ZoomReducer,
    resize: PageResizeReducer,
    builds: BuildReducer,
    wizard: WizardReducer,
    toolbar: EditToolbarReducer,
    viewport: ViewportReducer,
    unitsOfMeasureContainer: UnitOfMeasureContainerReducer,
    status: StatusReducer,
    projectManager: ProjectManagerReducer,
    overlay: OverlayReducer,
    reportDocument: ReportDocumentReducer,
    labelScheme: LabelSchemeReducer,
    polarity: PolarityConfigurationReducer,
    userWizardPreset: UserWizardPresetReducer,
    connectorHighlights: ConnectorHighlightsReducer,
    ssc: SSCSessionReducer,
    warnings: WarningsReducer,
    boundaries: BoundariesReducer,
    propagation: PropagationReducer,
    notification: NotificationManagerReducer,
});

export type WorkspaceState = ReturnType<typeof WorkspaceReducer>;

export const store = configureStore({
    reducer: WorkspaceReducer,
});

export type AppDispatch = typeof store.dispatch;

import "../../../../css/overlay/footer/footer.scss";

import React, { Component } from "react";

import { setViewportContext } from "../../../../store/pixi/viewport/viewport.reducers";
import { ViewportStatus } from "../../../../store/pixi/viewport/viewport";
import { moveDestinationLeft, moveDestinationRight } from "../../../../store/workspace/build/build.reducers";
import { connect, WorkspaceState } from "../../../../store/workspace/workspace.reducers";
import { setConfigSessionBusy, setConfigurationSession } from "../../../../store/workspace/ssc/ssc.reducer";
import {
    sscAllConfigSessionSelector,
    sscConfigSessionBusySelector,
    sscConfigSessionIdSelector,
} from "../../../../store/workspace/ssc/ssc.selectors";
import { RABSessionService } from "../../../../services/rab-session.service";
import { setShowConnectorReport } from "../../../../store/overlay/overlay.reducers";
import { setStatusState } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import {
    enableNextStep,
    saveBuildData,
    setApplyEditChanges,
    setCurrentStep,
    setWizardDisplay,
} from "../../../../store/overlay/wizard/wizard.reducers";
import { CollapseButton } from "./buttons/collapse-buttons/collapse-button";
import { ReactComponent as IconCloneLeft } from "../../../../resources/svgs/overlay/footer/icon_clone_left.svg";
import { ReactComponent as IconCloneLeftDisabled } from "../../../../resources/svgs/overlay/footer/icon_clone_left_disabled.svg";
import { ReactComponent as IconCloneRight } from "../../../../resources/svgs/overlay/footer/icon_clone_right.svg";
import { ReactComponent as IconCloneRightDisabled } from "../../../../resources/svgs/overlay/footer/icon_clone_right_disabled.svg";
import { ReactComponent as IconDelete } from "../../../../resources/svgs/overlay/footer/icon_delete.svg";
import { ReactComponent as IconDeleteDisabled } from "../../../../resources/svgs/overlay/footer/icon_delete_disabled.svg";
import IconDetails from "../../../../resources/svgs/overlay/footer/icon_details.svg";
import IconDetailsDisabled from "../../../../resources/svgs/overlay/footer/icon_details_disabled.svg";
import IconEdit from "../../../../resources/svgs/overlay/footer/icon_edit.svg";
import IconEditDisabled from "../../../../resources/svgs/overlay/footer/icon_edit_disabled.svg";
import { ReactComponent as IconMoveLeft } from "../../../../resources/svgs/overlay/footer/icon_move_left.svg";
import { ReactComponent as IconMoveLeftDisabled } from "../../../../resources/svgs/overlay/footer/icon_move_left_disabled.svg";
import { ReactComponent as IconMoveRight } from "../../../../resources/svgs/overlay/footer/icon_move_right.svg";
import { ReactComponent as IconMoveRightDisabled } from "../../../../resources/svgs/overlay/footer/icon_move_right_disabled.svg";
import { TieCablesButton } from "./buttons/tie-cables-button/tie-cables-button";
import { clearSelection, setSelectedPosition, setToolbarDisplay } from "../../../../store/overlay/footer/toolbar/toolbar.reducers";
import { WorkspaceStatus } from "../../../../store/overlay/header/status-icon/status-icon";
import { IconButton } from "@corning-ctcm/silica-react";

interface IEditToolbarLocalState {
    moveLeftDisabled?: boolean;
    moveRightDisabled?: boolean;
    deleteDisabled?: boolean;
    editDisabled?: boolean;
    detailsDisabled?: boolean;
    cloneLeftDisabled?: boolean;
    cloneRightDisabled?: boolean;
}

const mapStateToProps = (state: WorkspaceState) => {
    const { display, selection } = state.toolbar;
    const { showConnectorReport } = state.overlay;
    const { currentBuild } = state.builds;
    const { context } = state.viewport;
    const { stepCount } = state.wizard;
    const { currentStatus } = state.status;
    const { context: viewportStatus } = state.viewport;
    const sessionBusy = sscConfigSessionBusySelector(state);
    const sessionId = sscConfigSessionIdSelector(state);
    const configSessions = sscAllConfigSessionSelector(state);
    return {
        display: display && currentStatus !== WorkspaceStatus.Reporting && viewportStatus !== ViewportStatus.Editing,
        selection,
        showConnectorReport,
        currentBuild,
        context,
        stepCount,
        sessionBusy,
        sessionId,
        configSessions,
    };
};

const mapDispatchToProps = {
    setShowConnectorReport,
    moveDestinationLeft,
    moveDestinationRight,
    setToolbarDisplay,
    setSelectedPosition,
    clearSelection,
    setViewportContext,
    setWizardDisplay,
    enableNextStep,
    setApplyEditChanges,
    setCurrentStep,
    saveBuildData,
    setStatusState,
    setConfigurationSession,
    setConfigSessionBusy,
};

type props = Partial<typeof mapDispatchToProps> & Partial<ReturnType<typeof mapStateToProps>>;

class EditToolbarComponent extends Component<props, IEditToolbarLocalState> {
    public state: IEditToolbarLocalState = {
        moveLeftDisabled: false,
        moveRightDisabled: false,
        deleteDisabled: true,
        editDisabled: false,
        detailsDisabled: false,
        cloneLeftDisabled: true,
        cloneRightDisabled: true,
    };

    private moveSaveTimeOut?: NodeJS.Timeout;

    public componentDidUpdate(prevProps: props) {
        let selectedIndex = this.props.selection!.selected;
        if (selectedIndex !== -1) {
            const selectionChanged = prevProps.selection!.selected !== this.props.selection!.selected;
            const buildCatalogCodeChanged =
                prevProps.currentBuild?.catalogCode !== this.props.currentBuild?.catalogCode;

            let referenceChanged = false;
            if (selectedIndex === 0) {
                const prevSource = prevProps.currentBuild!.drops[0];
                const currSource = this.props.currentBuild!.drops[0];
                referenceChanged = prevSource !== currSource;
            } else {
                let prevSelectedDestination = prevProps.currentBuild!.drops.find((x) => x.position === selectedIndex);
                let selectedDestination = this.props.currentBuild!.drops.find((x) => x.position === selectedIndex);
                referenceChanged = prevSelectedDestination! !== selectedDestination!;
            }

            if (selectionChanged || referenceChanged || buildCatalogCodeChanged) {
                this.resetToolbarAvailabilities();
                this.setToolbarAvailabilities();
            }

            if (prevProps.sessionBusy !== this.props.sessionBusy) {
                this.setState({
                    editDisabled: this.props.sessionBusy,
                });
            }
        }
    }

    private setToolbarAvailabilities = () => {
        const selectedIndex = this.props.selection!.selected;
        const currentBuild = this.props.currentBuild!;

        const [, ...destinations] = currentBuild.drops;
        if (selectedIndex === 1) {
            this.setState({ moveLeftDisabled: true, cloneLeftDisabled: true });
        }

        if (selectedIndex === destinations.length - 1 && currentBuild.autoAccessPoints) {
            this.setState({ moveRightDisabled: true, cloneRightDisabled: true });
        }

        if (selectedIndex === destinations.length) {
            if (currentBuild.autoAccessPoints) {
                this.setState({
                    moveLeftDisabled: true,
                    cloneLeftDisabled: true,
                    moveRightDisabled: true,
                    cloneRightDisabled: true,
                });
            } else {
                this.setState({ moveRightDisabled: true, cloneRightDisabled: true });
            }
        }
        if (!!currentBuild.catalogCode) {
            this.setState({
                moveLeftDisabled: true,
                cloneLeftDisabled: true,
                moveRightDisabled: true,
                cloneRightDisabled: true,
                editDisabled: false,
            });
        }

        // Leaving it commented when we want to re-enable deleting
        // if (currentBuild.destinations.length <= 2 || this.props.selection!.selected === 1) {
        //     this.setState({ deleteDisabled: true });
        // }
    };

    private resetToolbarAvailabilities = () => {
        this.setState({
            moveLeftDisabled: false,
            moveRightDisabled: false,
            deleteDisabled: true,
            editDisabled: false,
            detailsDisabled: false,
            cloneLeftDisabled: true,
            cloneRightDisabled: true,
        });
    };

    private getMoveLeftIcon = (): JSX.Element => {
        return this.state.moveLeftDisabled ? <IconMoveLeftDisabled /> : <IconMoveLeft />;
    };

    private getMoveRightIcon = (): JSX.Element => {
        return this.state.moveRightDisabled ? <IconMoveRightDisabled /> : <IconMoveRight />;
    };

    private getDeleteIcon = (): JSX.Element => {
        return this.state.deleteDisabled ? <IconDeleteDisabled /> : <IconDelete />;
    };

    private getEditIcon = (): JSX.Element => {
        return this.state.editDisabled ? <img src={IconEditDisabled} alt="" /> : <img src={IconEdit} alt="" />;
    };

    private getDetailsIcon = (): JSX.Element => {
        return this.state.detailsDisabled ? <img src={IconDetailsDisabled} alt="" /> : <img src={IconDetails} alt="" />;
    };

    private getCloneLeftIcon = (): JSX.Element => {
        return this.state.cloneLeftDisabled ? <IconCloneLeftDisabled /> : <IconCloneLeft />;
    };

    private getCloneRightIcon = (): JSX.Element => {
        return this.state.cloneRightDisabled ? <IconCloneRightDisabled /> : <IconCloneRight />;
    };

    // duplicate handles for left and right to prevent creating anonymous click handle functions
    private handleMoveLeft = () => {
        this.triggerMoveLeft();
        if (this.moveSaveTimeOut) {
            clearTimeout(this.moveSaveTimeOut);
        }
        this.moveSaveTimeOut = setTimeout(this.save, 500);
    };

    private handleMoveRight = () => {
        this.triggerMoveRight();
        if (this.moveSaveTimeOut) {
            clearTimeout(this.moveSaveTimeOut);
        }
        this.moveSaveTimeOut = setTimeout(this.save, 500);
    };

    private save = () => {
        this.props.setStatusState!("saving");
    };

    private triggerMoveLeft = () => {
        const selectedIndex = this.props.selection!.selected;
        if (selectedIndex > 1) {
            this.props.moveDestinationLeft!(selectedIndex);
            this.props.setSelectedPosition!(selectedIndex - 1);
        }
    };

    private triggerMoveRight = () => {
        const selectedIndex = this.props.selection!.selected;
        const [, ...destinations] = this.props.currentBuild!.drops;
        if (selectedIndex < destinations.length) {
            this.props.moveDestinationRight!(selectedIndex);
            this.props.setSelectedPosition!(selectedIndex + 1);
        }
    };

    private triggerDelete = () => {
        const selectedIndex = this.props.selection!.selected;
        if (selectedIndex > 0) {
            this.props.clearSelection!();
            this.props.setStatusState!("saving");
        }
    };

    private triggerEdit = async () => {
        if (this.props.context === ViewportStatus.Editing) {
            this.setIsEditing(false);
        } else {
            const side = this.props.selection!.side;
            if (side === "feeder") {
                this.props.setCurrentStep!(1);
            } else {
                this.props.setCurrentStep!(2);
            }

            const configSessions = this.props.configSessions!;
            const sessionId = this.props.sessionId ?? "";
            const configurationType = this.props.currentBuild!.configurationType!;
            const session = configSessions[this.props.currentBuild!.configurationType!];
            if (!session && sessionId.length) {
                const service = new RABSessionService();
                this.props!.setConfigSessionBusy!(true);

                await service
                    .updateConfigurationType({ sessionId, configurationType })
                    .then((configSession) => {
                        if (configSession) {
                            this.props.setConfigurationSession!(configSession);
                        }
                        this.props.setConfigSessionBusy!(false);
                    })
                    .catch((err) => {
                        this.props.setConfigSessionBusy!(false);
                    });
            }

            this.setIsEditing(true);
            this.props.saveBuildData!(this.props.currentBuild!);
            this.props.setToolbarDisplay!(false);
        }
    };

    private setIsEditing = (isEditing: boolean) => {
        if (isEditing) {
            this.props.setViewportContext!(ViewportStatus.Editing);
            this.props.setWizardDisplay!(true);
            this.props.enableNextStep!(false);
        } else {
            this.props.setViewportContext!(ViewportStatus.Active);
            this.props.setApplyEditChanges!(false);
            this.props.setCurrentStep!(this.props.stepCount! + 1);
            this.props.setWizardDisplay!(false);
            this.props.saveBuildData!(undefined);
        }
    };

    private triggerDetails = () => {
        const { showConnectorReport } = this.props;
        this.props.setShowConnectorReport!(!showConnectorReport);
        this.props.setViewportContext!(ViewportStatus.ConnectorReport);
        this.props.setToolbarDisplay!(false);
    };

    public render() {
        const selectedIndex = this.props.selection!.selected;
        if (!this.props.display || selectedIndex === -1) {
            return null;
        }

        if (selectedIndex === 0) {
            return (
                <div className="controls-container toolbar-container background-blur toggle-pointer-events">
                    <IconButton
                        id="drop-edit-icon"
                        onClick={this.triggerEdit}
                        placement="top"
                        palette="primary"
                        mode="main"
                        title="Edit"
                        disabled={this.state.editDisabled}
                        icon={this.getEditIcon()}
                    />
                    <IconButton
                        onClick={this.triggerDetails}
                        placement="top"
                        palette="primary"
                        mode="main"
                        title="Show Details"
                        disabled={this.state.detailsDisabled}
                        icon={this.getDetailsIcon()}
                    />
                    <div className="tool-vertical-divider" />
                    <CollapseButton />
                    <TieCablesButton />
                </div>
            );
        } else {
            return (
                <div className="controls-container toolbar-container background-blur toggle-pointer-events">
                    <IconButton
                        id="drop-edit-icon"
                        onClick={this.triggerEdit}
                        placement="top"
                        palette="primary"
                        mode="main"
                        title="Edit"
                        disabled={this.state.editDisabled}
                        icon={this.getEditIcon()}
                    />
                    <IconButton
                        id="drop-details-icon"
                        onClick={this.triggerDetails}
                        placement="top"
                        palette="primary"
                        mode="main"
                        title="Show Details"
                        disabled={this.state.detailsDisabled}
                        icon={this.getDetailsIcon()}
                    />
                    <div className="tool-vertical-divider" />
                    <CollapseButton />
                    <TieCablesButton />
                </div>
            );
        }
    }
}

export const EditToolbar = connect(mapStateToProps, mapDispatchToProps)(EditToolbarComponent);

import React from "react";
import { DialogContext } from "../../../../../store/workspace/dialog/dialog.reducer";
import { InputDialog } from "../../../project-drawer/project-manager-row/input-dialog/input-dialog";
import { useEditPolarityNameDialog } from "./edit-polarity-name-dialog.hooks";
import { IEditPolarityDialogProps } from "../../../../../models/overlay/polarity/edit-polarity-dialog";

export const EditPolarityNameDialog: React.FC<IEditPolarityDialogProps> = (props) => {
    const { context } = useEditPolarityNameDialog(props);
    const { open } = context.state.props;

    if (!open) return null;

    return (
        <DialogContext.Provider value={context}>
            <InputDialog {...props} />
        </DialogContext.Provider>
    );
};

import { useSelector } from "react-redux";
import classNames from "classnames";
import { hybrisCustomerSelector } from "../../../store/authentication/authentication.selectors";

export const useHeader = () => {
    const hybrisCustomer = useSelector(hybrisCustomerSelector);
    const headerClassName = classNames("workspace-header", "toggle-pointer-events", {
        "hybris-header": !!hybrisCustomer,
    });
    return { headerClassName };
};

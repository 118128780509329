import {
    IBuildSession,
    IGenerateCatalogCodeResponse,
    IDeletePolarityMapResponse,
    IConfigSession,
    IUpdateSessionResponse,
} from "../store/workspace/ssc/ssc";
import { SecuredService } from "./abstract-secured-v2.service";
import {
    IUpdateRABRequest,
    IUpdateConfigurationRequest,
    IApplyConnectorLabelSchemesRequest,
    IApplyConnectorLabelSchemesResponse,
    IUpdateBuildInfoRequest,
} from "../models/services/rab-session";

export const defaultLabelScheme = "DEFAULT";

export class RABSessionService extends SecuredService {
    private readonly baseUrl = "/api/ssc/session";

    public async createConfigSession(): Promise<IConfigSession | undefined> {
        return await this.get(`${this.baseUrl}/configuration/options`);
    }

    public async updateConfigurationType(request: IUpdateConfigurationRequest): Promise<IConfigSession | undefined> {
        return await this.put(`${this.baseUrl}/configuration/rab`, request);
    }

    public async updateBuildInfo(request: IUpdateBuildInfoRequest): Promise<IBuildSession | undefined> {
        return await this.put(`${this.baseUrl}/info/rab`, request);
    }

    public async finalizeBuild(sessionId: string): Promise<IGenerateCatalogCodeResponse | undefined> {
        return await this.put(`${this.baseUrl}/${sessionId}/finalize`, null);
    }

    public async updateRABCharacteristic(request: IUpdateRABRequest): Promise<IUpdateSessionResponse | undefined> {
        return await this.put(`${this.baseUrl}/material/rab`, request);
    }

    public async deletePolarityMap(
        sessionId: string,
        connectorIndex: number
    ): Promise<IDeletePolarityMapResponse | undefined> {
        return await this.delete(`${this.baseUrl}/${sessionId}/polarity/set/${connectorIndex}`);
    }

    public async deletePolarityAllMap(sessionId: string): Promise<IDeletePolarityMapResponse | undefined> {
        return await this.delete(`${this.baseUrl}/${sessionId}/polarity/set/all`);
    }

    public async applyConnectorLabelSchemes(
        request: IApplyConnectorLabelSchemesRequest
    ): Promise<IApplyConnectorLabelSchemesResponse | undefined> {
        return await this.post(`${this.baseUrl}/label/label-schemes`, request);
    }

    public async endSession(sessionId: string): Promise<void> {
        return await this.post(`${this.baseUrl}/${sessionId}/destroy`);
    }
}

export const rabSessionService = new RABSessionService();
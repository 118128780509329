import { useState, useCallback, useContext } from "react";
import { setHoveredPosition } from "../../../../store/overlay/footer/toolbar/toolbar.reducers";
import { useDispatch, useSelector } from "react-redux";
import { DropContext } from "../../../../store/workspace/build/build";
import { toolbarSelectionSelector } from "../../../../store/overlay/footer/toolbar/toolbar.selectors";
import { IBoundingBoxContext, IBoundingBoxProps } from "../../../../models/pixi/decorators/bounds/bounding-box";

export const useBoundingBox = ({ buffer: bounds }: IBoundingBoxProps) => {
    const { index } = useContext(DropContext);
    const { selected } = useSelector(toolbarSelectionSelector);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const dispatch = useDispatch();

    const onMouseOver = useCallback(() => {
        setIsMouseOver(true);
        if (selected === index) {
            dispatch(setHoveredPosition(index));
        }
    }, [dispatch, selected, index]);

    const onMouseOut = useCallback(() => {
        setIsMouseOver(false);
        if (selected === index) {
            dispatch(setHoveredPosition(-1));
        }
    }, [dispatch, selected, index]);

    const context: IBoundingBoxContext = { bounds, isMouseOver };
    return {
        context,
        onMouseOver,
        onMouseOut,
    };
};

import { PayloadAction } from "@reduxjs/toolkit";
import { BuildDocument, IUserProvidedImage } from "./document";

export const setDocumentAction = (state: BuildDocument, action: PayloadAction<BuildDocument>) => {
    updateUserProvidedImages(state, action.payload.userProvidedImages ?? []);
    Object.assign(state, action.payload);
};

export const setUserProvidedImagesAction = (state: BuildDocument, action: PayloadAction<IUserProvidedImage[]>) => {
    updateUserProvidedImages(state, action.payload);
}

const updateUserProvidedImages = (state: BuildDocument, userProvidedImages: IUserProvidedImage[]) => {
    const objectUrls = userProvidedImages.map(image => image.objectUrl);
    const removed = state.userProvidedImages?.filter(({objectUrl}) => !objectUrls.includes(objectUrl));
    removed?.forEach(image => URL.revokeObjectURL(image.objectUrl));
    state.userProvidedImages = userProvidedImages;
}
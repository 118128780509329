export const WorkspaceStatus = {
    Inactive: "inactive",
    Loading: "loading",
    Ready: "ready",
    Saving: "saving",
    Saved: "saved",
    Reporting: "reporting",
    Printing: "printing",
    Printed: "printed",
    Busy: "busy",
    InitialLoad: "initial load",
} as const;

export type WorkspaceStatusTypes = (typeof WorkspaceStatus)[keyof typeof WorkspaceStatus];

export interface StatusState {
    currentStatus: WorkspaceStatusTypes;
    textureStatus: WorkspaceStatusTypes;
    isLocked: boolean;
}

export const initialStatusState: StatusState = {
    currentStatus: WorkspaceStatus.Ready,
    textureStatus: WorkspaceStatus.InitialLoad,
    isLocked: false,
};

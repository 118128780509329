import { IPoint } from "../../../models/pixi/pixi";
import {
    initialState as initialResizeState,
    PageResizeArgs,
} from "../../../models/overlay/footer/page-resize";
import { initialState as initialZoomState } from "../../../models/overlay/footer/zoom";

export const ViewportStatus = {
    Inactive: "inactive",
    Active: "active",
    Editing: "editing",
    Creating: "creating",
    ConnectorReport: "connector-report",
} as const;

export type ViewportStatusTypes = typeof ViewportStatus[keyof typeof ViewportStatus];

export interface IViewportState {
    isDragging: boolean;
    context: ViewportStatusTypes;
    position: IPoint;
    scale: IPoint;
    resize: PageResizeArgs;
    increment: number;
    readonly minScale: number;
}

export const initialState: IViewportState = {
    isDragging: false,
    context: ViewportStatus.Inactive,
    position: { x: 819.8495, y: -73.0261 },
    scale: initialZoomState.zoom,
    resize: initialResizeState.resize,
    increment: 0,
    minScale: 0.02,
};

export const VIEWPORT_PADDING = 100;
export const SOURCE_PADDING = 250;
import { useDragHandle } from "./drag-handle.hooks";
import { IDragHandleProps } from "../../../../../models/overlay/project-drawer/project-manager-row/project-manager-row";
import { IconButton } from "@corning-ctcm/silica-react";

import "../../../../../css/overlay/project-drawer/project-manager-row/drag-handle.scss";

export const DragHandle = (props: IDragHandleProps) => {
    const { id, className, onLoad } = useDragHandle(props);
    return (
        <IconButton
            id={id} 
            palette="primary" 
            mode="main" 
            placement="top" 
            className="drag-button" 
            disableRipple={true} 
            onClick={onLoad}
            icon={<img className={className} alt="Drag" />}
        />
    );
};

import React from "react";
import { Container, Graphics, Text } from "@pixi/react";
import { useBezierMarker } from "./bezier-marker.hooks";
import { IBezierLineMarkerProps } from "../../../../../models/pixi/decorators/dimension-lines/line-marker";

export const BezierMarker: React.FC<IBezierLineMarkerProps> = (props: IBezierLineMarkerProps) => {
    const { textProps, drawBezierMarker } = useBezierMarker(props);

    return (
        <Container>
            <Graphics draw={drawBezierMarker} />
            <Text {...textProps} />
        </Container>
    );
};

import React from "react";
import { useLabelScheme } from "./label-scheme.hooks";
import { CollapsibleDialog } from "../../../ui/dialog/collapsible-dialog/collapsible-dialog";
import { Template } from "./template";
import { Divider, FormControlLabel, Tab } from "@mui/material";
import { Label } from "./label";
import { Customization } from "./customization/customization";
import { LabelCustomizationContext } from "../../../../store/overlay/reports/label-customization/customization";
import { ContainedButton, OutlinedButton, Checkbox, Tabs, Typography } from "@corning-ctcm/silica-react";

import "../../../../css/overlay/reports/label-scheme/label-scheme.scss";

export const LabelScheme: React.FC = () => {
    const { dialogProps, schemeProps, exampleProps, customizationContext, applyAllProps, cancelProps, applyProps } =
        useLabelScheme();
    return (
        <CollapsibleDialog {...dialogProps}>
            <div className="scheme-content">
                <div className="templates-container">
                    <div className="templates-title">
                        <Typography variant="body1">
                            {schemeProps.title}
                        </Typography>
                    </div>
                    <div className="templates">
                        {schemeProps.templates.map((t, i) => {
                            return (
                                <div key={`template-${i}`}>
                                    <Template {...t} />
                                    <Divider />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="examples-container">
                    <Tabs {...exampleProps.tabNavigation}>
                        {exampleProps.tabs.map((t, i) => (
                            <Tab id={`${t.label}-tab`} key={`example-tab-${i}`} {...t} value={t.value} />
                        ))}
                    </Tabs>
                    <div className="examples-panel">
                        {exampleProps.showLabels ? (
                            <div className="labels">
                                {exampleProps.labels.map((l, i) => (
                                    <Label key={`example-label-${i}`} label={l} />
                                ))}
                            </div>
                        ) : (
                            <LabelCustomizationContext.Provider value={customizationContext}>
                                <Customization />
                            </LabelCustomizationContext.Provider>
                        )}
                    </div>
                </div>
            </div>
            <div className="scheme-footer">
                <FormControlLabel label={applyAllProps.label} control={<Checkbox {...applyAllProps.checkboxProps} />} />
                <div className="button-column">
                    <div className="button-container">
                        <OutlinedButton id="label-scheme-cancel-button" {...cancelProps.buttonProps}>
                            {cancelProps.label}
                        </OutlinedButton>
                        <ContainedButton id="label-scheme-apply-button" {...applyProps.buttonProps}>
                            {applyProps.label}
                        </ContainedButton>
                    </div>
                </div>
            </div>
        </CollapsibleDialog>
    );
};

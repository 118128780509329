import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import { IDialogHeaderProps } from "../../../../../models/ui/dialog/dialog-header";
import { DialogContext, showDialog } from "../../../../../store/workspace/dialog/dialog.reducer";
import { IInputDialogProps, IRestrictedInputDialogProps } from "../../../../../models/overlay/project-drawer/project-manager-row/input-dialog";

export const useInputDialog = ({
    id,
    value,
    maxCharacterCount,
    cancelProps,
    saveProps,
    inputFieldLabel,
    title,
}: IInputDialogProps) => {
    const [inputValue, setInputValue] = useState(value);
    const { state, dispatch } = useContext(DialogContext);
    const { t } = useTranslation();
    const open = state.props.open;
    const [inputDisabled, setInputDisabled] = useState(
        inputValue.length > maxCharacterCount || inputValue.length === 0
    );
    const initialHelperText =
        inputValue.length === 0 ? t(LocalizationKeys.EmptyInputField) : t(LocalizationKeys.LimitExceeded);
    const [helperText, setHelperText] = useState(initialHelperText);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const onInputChanged = useCallback(
        (e: React.ChangeEvent<any>) => {
            const inputValue = e.currentTarget.value;
            const disabled = inputValue.length > maxCharacterCount || inputValue.length === 0;
            const text =
                inputValue.length === 0 ? t(LocalizationKeys.EmptyInputField) : t(LocalizationKeys.LimitExceeded);
            setHelperText(text);
            setInputDisabled(disabled);
            setInputValue(inputValue);
        },
        [maxCharacterCount, t]
    );

    const onClose = useCallback(() => {
        if (open) {
            dispatch(showDialog(false));
        }
    }, [open, dispatch]);

    const onCancelClick = useCallback(async () => {
        if (cancelProps.onClick) {
            if (cancelProps.onClick as () => Promise<void>) {
                await cancelProps.onClick();
            } else {
                cancelProps.onClick();
            }
        }
        onClose();
    }, [cancelProps, onClose]);

    const headerProps: IDialogHeaderProps = useMemo(() => {
        return {
            id,
            title: title ?? "",
            closable: true,
            collapsible: false,
            onClose,
        };
    }, [id, onClose, title]);

    const onAcceptClick = useCallback(async () => {
        if (saveProps.onClick as (value: string) => Promise<void>) {
            await saveProps.onClick(inputValue);
        } else {
            saveProps.onClick(inputValue);
        }
        dispatch(showDialog(false));
    }, [saveProps, inputValue, dispatch]);

    return {
        id,
        open,
        onClose,
        inputDisabled,
        maxCharacterCount,
        inputValue,
        onInputChanged,
        cancelProps,
        onCancelClick,
        saveProps,
        onAcceptClick,
        headerProps,
        helperText,
        inputFieldLabel,
        title,
        setHelperText,
        setInputDisabled,
    };
};

export const useRestrictedInputDialog = (props: IRestrictedInputDialogProps) => {
    const { restrictions, ...useInputDialogProps } = props;
    const { t } = useTranslation();
    const {
        onInputChanged: onInputDialogChanged,
        setHelperText,
        setInputDisabled,
        ...inputDialogProps
    } = useInputDialog(useInputDialogProps);

    const onRestrictedInputChange = useCallback(
        (e: React.ChangeEvent<any>) => {
            onInputDialogChanged(e);
            restrictions?.forEach(({ restrictedWords, message, isCaseSensitive }) => {
                const inputValue = (isCaseSensitive ? e.target.value : e.target.value.toLowerCase()).replace(/\s/g, "");
                const isValid = restrictedWords.findIndex((w) => w.replace(/\s/g, "") === inputValue) === -1;
                if (!isValid) {
                    setHelperText(message.showValue ? t(message.key, { value: e.target.value }) : t(message.key));
                    setInputDisabled(!isValid);
                }
            });
        },
        [restrictions, onInputDialogChanged, setHelperText, setInputDisabled, t]
    );

    return {
        ...inputDialogProps,
        onInputChanged: onRestrictedInputChange,
    };
};

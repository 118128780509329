import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IConnectorAssignmentState, initialConnectorAssignmentState } from "./connector-assignment";
import { IConnectorData } from "../../../workspace/build/connector/connector";
import { getGroupsConnectors } from "../../../workspace/build.selectors";
import { PolarityAssignment, PolarityAssignments } from "../../../../models/overlay/polarity/connector-assignment/connector-assigment";
import { IDrop } from "../../../workspace/build/drop";

const setSelectAllAction = (state: IConnectorAssignmentState, action: PayloadAction<boolean>) => {
    const selectAll = action.payload;
    state.selectAll = selectAll;
    if (selectAll) {
        state.feederSelection = [];
        state.distributionSelection = [];
    } else {
        resetAllAction(state);
    }
};

const setPolarityAssignmentAction = (state: IConnectorAssignmentState, action: PayloadAction<PolarityAssignment>) => {
    state.polarityAssignment = action.payload;
};

const setPolarityKeyAction = (state: IConnectorAssignmentState, action: PayloadAction<number>) => {
    state.polarityKey = action.payload;
};

const selectRowsAction = (state: IConnectorAssignmentState, action: PayloadAction<IConnectorData[]>) => {
    const connectors = action.payload;
    for (const connector of connectors) {
        handleRowSelection(state, connector);
    }
    state.assignedSelection = [];
}

const handleRowSelection = (state: IConnectorAssignmentState, { side, id }: IConnectorData) => {
    if (side && id) {
        if (side === "feeder") {
            const feederIndex = state.feederSelection.findIndex(s => s === id);
            if (feederIndex > -1) {
                state.feederSelection = state.feederSelection.filter(s => s !== id);
            } else {
                state.feederSelection.push(id);
            }
        } else {
            const distributionIndex = state.distributionSelection.findIndex(s => s === id);
            if (distributionIndex > -1) {
                state.distributionSelection = state.distributionSelection.filter(s => s !== id);
            } else {
                state.distributionSelection.push(id);
            }
        }
    }
}

const selectAssignmentAction = (state: IConnectorAssignmentState, action: PayloadAction<number[]>) => {
    const connectorIds = action.payload;
    if (state.assignedSelection.length > 0 && state.assignedSelection.every(id => connectorIds.includes(id))) {
        state.assignedSelection = [];
    } else {
        state.assignedSelection = connectorIds;
    }
    resetSelectionAction(state);
}

const resetAllAction = (state: IConnectorAssignmentState) => {
    state.polarityKey = -1;
    state.polarityAssignment = PolarityAssignments.Standard;
    resetSelectionAction(state);
};

const resetSelectionAction = (state: IConnectorAssignmentState) => {
    state.selectAll = false;
    state.feederSelection = [];
    state.distributionSelection = [];
};

// #legacy This can be used once we implement the distributed assignment pattern
export const filterPolarityAssignments = (state: IConnectorAssignmentState, source: IDrop, destination: IDrop[]) => {
    const nbrSrcConnectorsPerGroup = source.groups[0].connectors.length;
    const nbrSourceConnectors = getGroupsConnectors(source.groups).length;
    const nbrDestinationConnectors = getGroupsConnectors(destination.flatMap((d) => d.groups)).length;

    const fiberMatch = nbrSourceConnectors === nbrDestinationConnectors;
    const groupMatch = destination.every((d) => d.groups.length <= nbrSrcConnectorsPerGroup);
    const connectorMatch = destination.every((d) => d.groups[0].connectors.length === source.groups.length);
    const filter = fiberMatch && groupMatch && connectorMatch

    return Object.values(PolarityAssignments).filter((assignment) => {
        return !filter ? assignment !== PolarityAssignments.Distributed : true;
    });
}

const connectorAssignmentSlice = createSlice({
    initialState: initialConnectorAssignmentState,
    name: "connectorAssignment",
    reducers: {
        setSelectAll: setSelectAllAction,
        setPolarityAssignment: setPolarityAssignmentAction,
        setPolarityKey: setPolarityKeyAction,
        selectRows: selectRowsAction,
        selectAssignment: selectAssignmentAction,
        resetAll: resetAllAction,
        resetSelection: resetSelectionAction,
    },
});

export const ConnectorAssignmentReducer = connectorAssignmentSlice.reducer;
export const {
    setSelectAll,
    setPolarityAssignment,
    setPolarityKey,
    selectRows,
    selectAssignment,
    resetAll,
    resetSelection,
} = connectorAssignmentSlice.actions;

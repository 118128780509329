import React from "react";
import { Checkbox, Radio, RadioGroup, Select, SelectItem as Option, TextField } from "@corning-ctcm/silica-react";
import { ICheckboxInput } from "../../../../../models/ui/checkbox";
import { IRadioLabel, IRadioGroup } from "../../../../../models/ui/radio";
import { InputField } from "../../../../../models/ui/input";
import { useSelector } from "react-redux";
import { nbDistributionSelector } from "../../../../../store/workspace/build.selectors";
import { ISelect } from "../../../../ui/select/select.hooks";

interface IDropSubSectionProps {
    checkbox: ICheckboxInput;
    select: ISelect;
    printGroup: IRadioGroup;
    printAllRadio: IRadioLabel;
    printDropRadio: IRadioLabel;
    printDropField: InputField;
    disabled?: boolean;
}

export const DropSubSection = (props: IDropSubSectionProps) => {
    const { checkbox, select, printGroup, printAllRadio, printDropRadio, printDropField, disabled } = props;
    const nbDistribution = useSelector(nbDistributionSelector);

    return (
        <div className={"report-section"}>
            <div className={checkbox.className}>
                <Checkbox
                    palette="primary"
                    onChange={checkbox.onCheckBoxChange}
                    checked={!disabled && checkbox.checked}
                    disabled={disabled}
                    label={checkbox.label}
                />
            </div>
            <div>
                <div className="section-options">
                    <Select {...select.props}>
                        {select.options.map((o, i) => (
                            <Option id={`section-option-${i}`} key={o.key} value={o.value}>
                                {o.name}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="print-container">
                <div className="radio-group">
                    <RadioGroup value={printGroup.value} onChange={printGroup.onRadioGroupChange}>
                        <div className="radio-group-container">
                            <Radio
                                className="print-all"
                                label={printAllRadio.label}
                                value={printAllRadio.label}
                                checked={printAllRadio.label === printGroup.value}
                                disabled={checkbox.disabledChildren}
                            />
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Radio
                                    label={""}
                                    value={printDropRadio.label}
                                    checked={printDropRadio.label === printGroup.value}
                                    disabled={checkbox.disabledChildren || nbDistribution === 1}
                                />
                                <TextField
                                    palette="primary"
                                    label={printDropField.label}
                                    helperText={""}
                                    error={!printDropField.isValid}
                                    disabled={checkbox.disabledChildren || printDropField.disabled}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        printDropField.onChange && printDropField.onChange(e)
                                    }
                                />
                            </div>
                        </div>
                    </RadioGroup>
                </div>
            </div>
        </div>
    );
};

import { Palette } from "@mui/icons-material";
import { Dialog, IconButton, Radio, RadioGroup } from "@corning-ctcm/silica-react";
import React from "react";
import ReactDOM from "react-dom";
import { ColorDialog } from "../../../ui/dialog/color-dialog/color-dialog";
import { useAssemblyTypeEdit } from "./assembly-type-edit.hooks";

import "../../../../css/overlay/header/assembly-type-edit.scss";

export const AssemblyTypeEdit: React.FC = () => {
    const { dialogProps, radioGroup, color } = useAssemblyTypeEdit();
    return (
        <>
            <Dialog {...dialogProps}>
                <RadioGroup value={radioGroup.props.value} onChange={radioGroup.props.onRadioGroupChange}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {radioGroup.options.map((r, i) => (
                            <span className="option-container" key={`assembly-type-${i}`}>
                                <Radio id={`assembly-type-option-${i}`} label={r.label} value={r.sectionName} disabled={r.disabled} />
                                {r.sectionName === radioGroup.props.value && (
                                    <IconButton {...color.mainMeshColorIcon} icon={<Palette />} />
                                )}
                            </span>
                        ))}
                    </div>
                </RadioGroup>
            </Dialog>
            {ReactDOM.createPortal(<ColorDialog {...color.colorDialogProps} />, document.body)}
        </>
    );
};

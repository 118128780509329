import React from "react";
import { useCablePresetSnackbar } from "./cable-preset-snackbar.hooks";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../locales/keys";
import { GenericSnackbar } from "../../ui/generic-snackbar/generic-snackbar";
import { SnackbarTypes } from "../../../models/ui/generic-snackbar";

export const CablePresetSnackbar = () => {
    const { open, handleClose, handleSavePreset } = useCablePresetSnackbar();
    const { t } = useTranslation();

    return (
        <GenericSnackbar
            id="cable-preset-snackbar"
            type={SnackbarTypes.Information}
            open={open}
            message={t(LocalizationKeys.CablePresetConfirmation)}
            onConfirm={handleSavePreset}
            onCancel={handleClose}
            hideCloseButton={true}
        />
    );
};

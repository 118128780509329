import { PixiComponent } from "@pixi/react";
import { Action } from "@reduxjs/toolkit";
import { Viewport as PixiViewport } from "pixi-viewport";
import React, { PropsWithChildren, ReactNode, createContext } from "react";
import { StorageManager } from "../manager/storage-manager/storage-manager";
import { useViewport } from "./viewport.hooks";

export interface IViewportState {
    scale: number;
    increment?: { scale: number };
    isDragging: boolean;
}
export const initialViewportState: IViewportState = {
    scale: 1,
    isDragging: false,
};

interface IViewportInstance {
    viewport?: PixiViewport;
}

export interface IViewportComponentProps {
    children?: ReactNode;
}

export type ViewportProps = IViewportInstance & Partial<IViewportState> & React.PropsWithChildren<{}>;

export interface IViewportContext {
    state: IViewportState;
    dispatch: React.Dispatch<Action>;
}

const initialViewportContext: IViewportContext = {
    state: initialViewportState,
    dispatch: (_: Action) => {},
};

export const ViewportContext = createContext<IViewportContext>(initialViewportContext);

const ReactViewport = PixiComponent<ViewportProps, PixiViewport>("Viewport", {
    create: (props) => {
        return props.viewport!;
    },
});

export const Viewport: React.FC<PropsWithChildren<IViewportComponentProps>> = ({ children }) => {
    const { viewport, displayObjects } = useViewport();
    return (
        <ReactViewport viewport={viewport}>
            <StorageManager displayObjects={displayObjects} />
            {children}
        </ReactViewport>
    );
};

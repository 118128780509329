import { useEffect, useState } from "react";
import { ITimer, initialTimer, convert, ONE_SEC } from "../../models/timer";

export const useTimer = (start: boolean, seconds: number, callback: () => void) => {
    const [counter, setCounter] = useState<ITimer>(initialTimer);
    useEffect(() => {
        if (start) {
            let countdown = seconds;
            setCounter(initialTimer);
            const id = setInterval(() => {
                if (countdown === 0) {
                    callback();
                } else {
                    countdown = countdown - 1;
                    setCounter(convert(countdown));
                }
            }, ONE_SEC);
            return () => clearInterval(id);
        }
    }, [start, seconds, callback]);

    return { counter };
};

import { Unit } from "../../models/overlay/header/units-of-measure";
import { Identifiable } from "../../models/services/services";

export interface IAuthenticationState {
    loggedIn: boolean;
    tokenExpiration?: number;
    user: User
    roles?: string[];
    customer?: HybrisCustomer;
}

export interface IUser {
    platformId: number;
    groupId: number;
    org: string;
    email: string;
    firstName: string;
    lastName: string;
    settings: IUserSettings;
}

export type User = IUser & Identifiable

export interface IUserSettings {
    showPullingGrip: boolean;
}

const initialUserSettings: IUserSettings = {
    showPullingGrip: true,
}

export const initialUser: User = {
    platformId: -1,
    groupId: -1,
    org: "",
    email: "",
    firstName: "",
    lastName: "",
    settings: initialUserSettings
}

export const initialState: IAuthenticationState = {
    loggedIn: false,
    user: initialUser
};

interface HybrisAccount {
    uid: string;
    name: string;
    soldTo: string;
    unitOfMeasure: Unit;
}

interface HybrisCustomer {
    uid: string | undefined;
    name: string;
    email: string;
    account: HybrisAccount;
    isAnonymous: boolean;
}
import React from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../locales/keys";
import { BuildPlanTableProps } from "../../../models/off-screen/build-plan-table";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

export const BuildPlanTable: React.FC<BuildPlanTableProps> = (props) => {
    const { t } = useTranslation();
    const {
        buildPlanTables: [table1, table2],
    } = props;

    return (
        <>
            <Table id="build-plan-table1">
                <TableHead>
                    <TableRow>
                        <TableCell>{t(LocalizationKeys.Description)}</TableCell>
                        <TableCell>{t(LocalizationKeys.Quantity)}</TableCell>
                        <TableCell>{t(LocalizationKeys.UOM)}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {table1.map((r, i) => (
                        <TableRow key={`table1row${i}`} className={r.className}>
                            {Object.values(r.data).map((d, j) => (
                                <TableCell key={`table1${d}${i}${j}`} className={r.className}>{`${d}`}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {table2.length ? (
                <Table id="build-plan-table2">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t(LocalizationKeys.Description)}</TableCell>
                            <TableCell>{t(LocalizationKeys.Quantity)}</TableCell>
                            <TableCell>{t(LocalizationKeys.UOM)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {table2.map((r, i) => (
                            <TableRow key={`table2row${i}`}>
                                {Object.values(r.data).map((d, j) => (
                                    <TableCell key={`table2${d}${i}${j}`} className={r.className}>
                                        {d}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : null}
        </>
    );
};

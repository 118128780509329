import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../locales/keys";
import { IGenericDialogProps } from "../../../models/ui/dialog/generic-dialog";
import { AppDispatch } from "../../../store/workspace/workspace.reducers";
import { useTimer } from "../../tracker/timer.hooks";
import { FIVE_MINS } from "../../../models/timer";
import { setShowIdle, setShowTimeout } from "../../../store/overlay/overlay.reducers";
import { showIdleSelector } from "../../../store/overlay/overlay.selectors";
import { setStatusState } from "../../../store/overlay/header/status-icon/status-icon.reducer";
import { WorkspaceStatus } from "../../../store/overlay/header/status-icon/status-icon";
import { usePlatformService } from "@corning-ctcm/platform-client";

export const useIdleDialog = () => {
    const display = useSelector(showIdleSelector);
    const platformService = usePlatformService();
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();

    const onTimerEnd = useCallback(() => {
        storeDispatch(setShowIdle(false));
        storeDispatch(setShowTimeout(true));
    }, [storeDispatch]);

    const { counter } = useTimer(display, FIVE_MINS, onTimerEnd);

    const onClick = useCallback(async () => {
        await platformService.refreshToken();
        storeDispatch(setShowIdle(false));
        storeDispatch(setStatusState(WorkspaceStatus.Ready));
    }, [platformService, storeDispatch]);

    const dialogProps: IGenericDialogProps = {
        id: "idle-session",
        display,
        title: t(LocalizationKeys.IdleSessionTitle),
        message: t(LocalizationKeys.IdleSessionMessage, { countdown: `${counter.min}:${counter.sec}` }),
        closable: false,
        onConfirm: onClick,
        confirmText: t(LocalizationKeys.BackToWork),
    };

    return { dialogProps };
};

import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../locales/keys";
import { ICollapsibleDialogProps } from "../../../../models/ui/dialog/collapsible-dialog";
import { schemesSelector } from "../../../../store/workspace/build/connector/label-scheme/label-scheme.selectors";
import { AppDispatch } from "../../../../store/workspace/workspace.reducers";
import { selectedDropSelector } from "../../../../store/workspace/build.selectors";
import { currentBuildSelector } from "../../../../store/workspace/root.selectors";
import { setShowConnectorReport, setShowLabelScheme } from "../../../../store/overlay/overlay.reducers";
import { showLabelSchemeSelector } from "../../../../store/overlay/overlay.selectors";
import { setStatusState } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import { currentStatusSelector, isLockedSelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";
import { WorkspaceStatus } from "../../../../store/overlay/header/status-icon/status-icon";
import { applyLabelScheme } from "../../../../store/overlay/project/project.reducers";
import { ConnectorReportContext, setLabelChanged } from "../../../../store/overlay/reports/reports.reducers";
import {
    LabelCustomizationReducer,
    resetCustomization,
    setAllowPasting,
} from "../../../../store/overlay/reports/label-customization/customization.reducer";
import { ILabelCustomizationContext, initialLabelCustomizationState } from "../../../../store/overlay/reports/label-customization/customization";
import { ITemplateProps } from "../../../../models/overlay/reports/label-scheme/template";
import { DefaultSequences, getCharFromNumber, LabelCustomizationOptions, SchemeTabs } from "../../../../models/overlay/reports/label-scheme/label-scheme";
import { propagationOptionsSelector } from "../../../../store/overlay/polarity/propagation/propagation.selectors";

export const useLabelScheme = () => {
    const display = useSelector(showLabelSchemeSelector);
    const schemes = useSelector(schemesSelector);
    const isFeeder = !!(schemes[0]?.side === "feeder");
    const currentBuild = useSelector(currentBuildSelector);
    const drop = useSelector(selectedDropSelector);
    const currentStatus = useSelector(currentStatusSelector);
    const locked = useSelector(isLockedSelector);
    const disabled =
        locked ||
        currentStatus === WorkspaceStatus.Saving ||
        currentStatus === WorkspaceStatus.Busy;
    const propagationOptions = useSelector(propagationOptionsSelector);
    const [state, dispatch] = useReducer(LabelCustomizationReducer, initialLabelCustomizationState);
    const { rackNumber, reverseOrder, allowPasting, pastedContent } = state;
    const customizationContext: ILabelCustomizationContext = { state, dispatch };
    const { dispatch: connectorReportDispatch } = useContext(ConnectorReportContext);
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();

    const [selectedScheme, setSelectedScheme] = useState(-1);
    const [currentTab, setCurrentTab] = useState<string>(SchemeTabs.Examples);
    const [applyAll, setApplyAll] = useState(true);

    useEffect(() => {
        if (display && schemes[0].id) {
            setSelectedScheme(schemes[0].id);
        }
    }, [display, schemes]);

    useEffect(() => {
        dispatch(resetCustomization());
    }, [selectedScheme, currentTab]);

    const onClose = useCallback(() => {
        dispatch(resetCustomization());
        setSelectedScheme(-1);
        setCurrentTab(SchemeTabs.Examples);
        storeDispatch(setShowLabelScheme(false));
        storeDispatch(setShowConnectorReport(true));
    }, [dispatch, storeDispatch]);

    const dialogProps: ICollapsibleDialogProps = {
        id: "label-scheme",
        className: "label-scheme-dialog",
        display,
        headerProps: {
            title: isFeeder
                ? `${t(LocalizationKeys.Feeder)} ${t(LocalizationKeys.LabelScheme)}`
                : `${t(LocalizationKeys.AccessPoint)} ${t(LocalizationKeys.LabelScheme)}`,
            closable: true,
            onClose,
        },
    };

    const onSelectTemplate = useCallback((templateId: number) => {
        setSelectedScheme(templateId);
        if (templateId === 9999) {
            setCurrentTab(SchemeTabs.Customization);
            dispatch(setAllowPasting(true));
        } else {
            setCurrentTab(SchemeTabs.Examples);
            dispatch(setAllowPasting(false));
        }
    }, []);

    const templates: ITemplateProps[] = schemes.map((s) => {
        const id = s.id ?? 0;
        const desc = s.sequences.map((s) => s.name).join(" + ");
        return { id, name: s.name, desc, selected: selectedScheme === s.id, onClick: onSelectTemplate };
    });
    const schemeProps = {
        title: t(LocalizationKeys.SchemeTemplates),
        templates,
    };

    const onTabChange = useCallback((e: any, currentTab: string) => {
        setCurrentTab(currentTab);
    }, []);

    const exampleTab = {
        value: SchemeTabs.Examples,
        label: t(LocalizationKeys.Examples),
        disabled: selectedScheme === 9999,
        disableRipple: true,
    };

    const schemeIndex = schemes.findIndex((s) => s.id === selectedScheme);
    const customizationTab = {
        value: SchemeTabs.Customization,
        label: t(LocalizationKeys.Customization),
        disabled: schemeIndex === 0 || schemeIndex === schemes.length - 2,
        disableRipple: true,
    };

    // Generates basic hardcoded examples to give a general idea to the user
    const labels = useMemo(() => {
        const labels: string[] = [];
        const scheme = schemes.find((s) => s.id === selectedScheme);
        if (scheme && drop) {
            for (let i = 0; i < 6; i++) {
                let label = "";
                for (const sequence of scheme.sequences.slice().sort((a, b) => a.position - b.position)) {
                    if (sequence.symbol.toString().length > 1) {
                        switch (sequence.symbol) {
                            case DefaultSequences.AccessPoint:
                                let ap = drop.position?.toString() ?? "0";
                                if (sequence.position === 0 && ap.length > 1) {
                                    ap = `0${ap}`;
                                }
                                label += ap;
                                break;
                            case DefaultSequences.Rack:
                                let rack = 0;
                                if (scheme.side === "feeder") {
                                    rack = reverseOrder ? rackNumber - i : rackNumber + i;
                                } else {
                                    rack = reverseOrder
                                        ? rackNumber - Math.floor(i * 0.5)
                                        : Math.floor(i * 0.5) + rackNumber;
                                }
                                rack = Math.max(rack, 1);
                                label += rack >= 10 ? rack : `0${rack}`;
                                break;
                            case DefaultSequences.LegABCD:
                            case DefaultSequences.LegAC:
                                let acNumber = 0;
                                if (scheme.side === "feeder") {
                                    acNumber = Math.floor(i / 2) * sequence.increment;
                                } else {
                                    acNumber = i > 0 ? sequence.increment * (i % 2) : i;
                                }
                                label += `${sequence.prefix}${getCharFromNumber(acNumber)}`;
                                break;
                            case DefaultSequences.LegBD:
                                let bdNumber = 0;
                                if (scheme.side === "feeder") {
                                    bdNumber = Math.floor(i / 2) * sequence.increment;
                                } else {
                                    bdNumber = i > 0 ? sequence.increment * (i % 2) : i;
                                }
                                label += `${sequence.prefix}${getCharFromNumber(bdNumber + 1)}`;
                                break;
                            default: // Connector
                                let prefix = "";
                                let suffix = "";
                                if (sequence.position === 0) {
                                    prefix = "000";
                                } else {
                                    if (scheme.side === "feeder") {
                                        prefix = "00";
                                    } else {
                                        if (sequence.position === scheme.sequences.length - 1) {
                                            prefix = "0";
                                        }
                                    }
                                }
                                label += `${prefix}${i + 1}${suffix}`;
                                break;
                        }
                    } else {
                        label += sequence.prefix;
                    }
                }
                labels.push(label);
            }
        }
        return labels;
    }, [schemes, selectedScheme, drop, reverseOrder, rackNumber]);

    const onApplyAllChange = useCallback((e: any, checked: boolean) => {
        setApplyAll(checked);
    }, []);

    const onCancel = useCallback(() => {
        onClose();
    }, [onClose]);

    const cancelButtonProps = {
        disabled,
        onClick: onCancel,
    };

    const onApplyScheme = useCallback(async () => {
        if (currentBuild?.id && drop && drop.position !== undefined) {
            storeDispatch(setStatusState(WorkspaceStatus.Saving));
            try {
                const content = allowPasting ? pastedContent : undefined;
                const options: LabelCustomizationOptions = { applyAll, rackNumber, reverseOrder, content };
                await storeDispatch(
                    applyLabelScheme(
                        currentBuild.id,
                        selectedScheme,
                        drop.side,
                        drop.position,
                        options,
                        propagationOptions
                    )
                );
            } finally {
                storeDispatch(setStatusState(WorkspaceStatus.Saved));
                dispatch(resetCustomization());
                connectorReportDispatch(setLabelChanged(true));
            }
        }
        onClose();
    }, [
        currentBuild,
        selectedScheme,
        drop,
        applyAll,
        rackNumber,
        reverseOrder,
        allowPasting,
        pastedContent,
        propagationOptions,
        storeDispatch,
        connectorReportDispatch,
        onClose,
    ]);

    const applyButtonProps = {
        disabled,
        onClick: onApplyScheme,
    };

    return {
        dialogProps,
        schemeProps,
        exampleProps: {
            tabNavigation: {
                value: currentTab,
                onChange: onTabChange,
            },
            tabs: [exampleTab, customizationTab],
            showLabels: currentTab === SchemeTabs.Examples && selectedScheme !== 9999,
            labels,
        },
        customizationContext,
        applyAllProps: {
            label: isFeeder ? t(LocalizationKeys.ApplyToAssembly) : t(LocalizationKeys.ApplyToAllAPs),
            checkboxProps: {
                disabled,
                value: applyAll,
                checked: applyAll,
                onChange: onApplyAllChange,
            },
        },
        cancelProps: {
            buttonProps: cancelButtonProps,
            label: t(LocalizationKeys.Cancel),
        },
        applyProps: {
            buttonProps: applyButtonProps,
            label: t(LocalizationKeys.ApplyScheme),
        },
    };
};

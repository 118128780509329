import React from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../locales/keys";
import { useFiberMapTable } from "./fiber-map-table.hooks";
import { FiberMapRow } from "./fiber-map-row";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

export const FiberMapTable: React.FC = () => {
    const { t } = useTranslation();
    const { rows } = useFiberMapTable();

    if (rows.length === 0) return null;
    return (
        <Table id="fiber-map-table">
            <TableHead>
                <TableRow>
                    <TableCell className="source-location">{t(LocalizationKeys.Location)}</TableCell>
                    <TableCell className="source-connector-index">Conn. #</TableCell>
                    <TableCell className="source-connector-type">{t(LocalizationKeys.Type)}</TableCell>
                    <TableCell className="source-row">{t(LocalizationKeys.Row)}</TableCell>
                    <TableCell className="source-position">{t(LocalizationKeys.Position)}</TableCell>
                    <TableCell className="fiber-index">
                        {t(LocalizationKeys.FiberNumber, { fiberIndex: "#" })}
                    </TableCell>
                    <TableCell className="drop-location">{t(LocalizationKeys.Location)}</TableCell>
                    <TableCell className="drop-connector-index">Conn. #</TableCell>
                    <TableCell className="drop-connector-type">{t(LocalizationKeys.Type)}</TableCell>
                    <TableCell className="drop-row">{t(LocalizationKeys.Row)}</TableCell>
                    <TableCell className="drop-position">{t(LocalizationKeys.Position)}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, i) => (
                    <FiberMapRow
                        key={`row${i}`}
                        sourceFiber={row.sourceFiber}
                        destinationFiber={row.destinationFiber}
                        fiberIndex={i + 1}
                    />
                ))}
            </TableBody>
        </Table>
    );
};

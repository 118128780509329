import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import { ViewportStatus } from "../../../../store/pixi/viewport/viewport";
import {
    setCurrentStep,
    setWizardDisplay,
    enableNextStep,
    setApplyEditChanges,
    saveBuildData,
    clearSavedData,
} from "../../../../store/overlay/wizard/wizard.reducers";
import { deleteAllConnectorAssignments, setCurrentBuild } from "../../../../store/workspace/build/build.reducers";
import { setToolbarDisplay } from "../../../../store/overlay/footer/toolbar/toolbar.reducers";
import { setViewportContext } from "../../../../store/pixi/viewport/viewport.reducers";
import { useCablePresetSnackbar } from "../../cable-preset/cable-preset-snackbar.hooks";
import { BuildService } from "../../../../services/build.service";
import {
    buildIdSelector,
    connectorAssignmentsSelector,
    currentBuildFinalizedSelector,
} from "../../../../store/workspace/build.selectors";
import { IGenericDialogProps } from "../../../../models/ui/dialog/generic-dialog";
import {
    connectorAssignmentResetRequiredSelector,
    wizardSelector,
} from "../../../../store/overlay/wizard/wizard.selectors";
import { useWebSave } from "../../header/status/status.hooks";
import { isLockedSelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";
import { WorkspaceStatus } from "../../../../store/overlay/header/status-icon/status-icon";
import {
    sscConfigSessionBusySelector,
    sscCurrentSessionSelector,
    sscSessionBusySelector,
} from "../../../../store/workspace/ssc/ssc.selectors";
import { viewportContextSelector } from "../../../../store/pixi/viewport/viewport.selectors";
import { RABSessionService } from "../../../../services/rab-session.service";

import { setStatusState } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import { setConfigStatus, setSessionWarnings } from "../../../../store/workspace/ssc/ssc.reducer";
import { LabelCustomizationOptions } from "../../../../models/overlay/reports/label-scheme/label-scheme";
import { applyLabelScheme } from "../../../../store/overlay/project/project.reducers";
import {
    destinationSchemesSelector,
    feederSchemesSelector,
} from "../../../../store/workspace/build/connector/label-scheme/label-scheme.selectors";
import { AppDispatch } from "../../../../store/workspace/workspace.reducers";
import { IPropagationOptions } from "../../../../store/overlay/polarity/propagation/propagation";
import { setPropagation } from "../../../../store/overlay/polarity/propagation/propagation.reducers";

export const useWizardFooter = () => {
    const { t } = useTranslation();
    const sessionId = useSelector(sscCurrentSessionSelector);
    const { context } = useSelector(viewportContextSelector);
    const { currentStep, isNextEnabled } = useSelector(wizardSelector);
    const { movePrevious, cancelEdit, moveNext, connectorAssignmentResetRequired, applyChanges, disabled } =
        useButtonEvents();
    const connectorAssignments = useSelector(connectorAssignmentsSelector);
    const buildId = useSelector(buildIdSelector);
    const feederSchemes = useSelector(feederSchemesSelector);
    const destinationSchemes = useSelector(destinationSchemesSelector);
    const dispatch = useDispatch<AppDispatch>();

    const [footerClassName, setFooterClassName] = useState("");

    const [previousClassName, setPreviousClassName] = useState("");
    const [previousHidden, setPreviousHidden] = useState(false);
    const [previousOutlined, setPreviousOutlined] = useState(true);
    const [previousText, setPreviousText] = useState("");

    const [nextClassName, setNextClassName] = useState("");
    const [nextText, setNextText] = useState("");

    useEffect(() => {
        if (context === ViewportStatus.Editing) {
            setPreviousHidden(false);
            setNextText(t(LocalizationKeys.ApplyChanges));
            setFooterClassName("wizard-footer edit-mode");
            setPreviousClassName("cancel-button");
            setPreviousOutlined(false);
            setPreviousText(t(LocalizationKeys.Cancel));
            setNextClassName("apply-button");
        } else {
            setFooterClassName("wizard-footer");
            setPreviousClassName("back-button");
            setPreviousOutlined(true);
            setPreviousText(t(LocalizationKeys.Back));
            setNextClassName("next-button");
            if (currentStep === 1) {
                setPreviousHidden(true);
                setNextText(t(LocalizationKeys.Continue));
            } else {
                setPreviousHidden(false);
                setNextText(t(LocalizationKeys.Confirm));
            }
        }
    }, [context, t, currentStep]);

    const onPreviousClick = useCallback(() => {
        if (context === ViewportStatus.Editing) {
            cancelEdit();
        } else {
            movePrevious();
        }
    }, [context, cancelEdit, movePrevious]);

    const [showResetDialog, setShowResetDialog] = useState(false);

    const onNextClick = useCallback(async () => {
        if (context === ViewportStatus.Editing) {
            if (connectorAssignments.length && connectorAssignmentResetRequired) {
                setShowResetDialog(true);
            } else {
                applyChanges();
            }
        } else {
            moveNext();
        }
    }, [context, applyChanges, moveNext, connectorAssignmentResetRequired, connectorAssignments]);

    const onResetClose = useCallback(() => {
        setShowResetDialog(false);
    }, []);

    const onResetConfirm = useCallback(async () => {
        setShowResetDialog(false);
        dispatch(deleteAllConnectorAssignments());
        await new BuildService().deleteAllConnectorAssignments(buildId);
        buildId > 0 &&
            (await applyChanges().then(async (r) => {
                // Resetting label scheme to default standard and turn connector propagation off
                const options: LabelCustomizationOptions = { applyAll: true, rackNumber: 1, reverseOrder: false };
                const propagationOptions: IPropagationOptions = {
                    propagateColors: false,
                    propagateLabelText: false,
                    propagateLabelColor: false,
                };
                dispatch(setPropagation(propagationOptions));
                await dispatch(
                    applyLabelScheme(buildId, feederSchemes[0].id ?? 0, "feeder", 0, options, propagationOptions)
                );
                await dispatch(
                    applyLabelScheme(
                        buildId,
                        destinationSchemes[0].id ?? 0,
                        "distribution",
                        0,
                        options,
                        propagationOptions
                    )
                );
            }));

        if (sessionId.sessionId.length) {
            dispatch(setStatusState(WorkspaceStatus.Saving));
            const deletePolarityMapResponse = await new RABSessionService().deletePolarityAllMap(sessionId.sessionId);
            if (deletePolarityMapResponse) {
                const { warnings, configStatus } = deletePolarityMapResponse;
                dispatch(setSessionWarnings({ buildId, warnings }));
                dispatch(setConfigStatus({ buildId, configStatus }));
            }

            dispatch(setStatusState(WorkspaceStatus.Saved));
        }
    }, [applyChanges, buildId, sessionId, feederSchemes, destinationSchemes, dispatch]);

    const resetDialogProps: IGenericDialogProps = {
        id: "wizard-reset-connector-assignment",
        title: t(LocalizationKeys.ResetConnectorAssignment),
        display: showResetDialog,
        message: t(LocalizationKeys.ResetConnectorAssignmentOnEdit),
        onClose: onResetClose,
        closable: true,
        confirmText: t(LocalizationKeys.Continue),
        onConfirm: onResetConfirm,
        critical: true,
    };

    return {
        footerClassName,
        previousClassName,
        previousHidden,
        previousOutlined,
        onPreviousClick,
        previousText,
        nextClassName,
        onNextClick,
        nextText,
        isNextEnabled: isNextEnabled && !disabled,
        disabled,
        resetDialogProps,
    };
};

export const useButtonEvents = () => {
    const wizard = useSelector(wizardSelector);
    const { currentStep, stepCount, savedBuildData } = wizard;
    const connectorAssignmentResetRequired = useSelector(connectorAssignmentResetRequiredSelector);
    const buildFinalized = useSelector(currentBuildFinalizedSelector);
    const configSessionBusy = useSelector(sscConfigSessionBusySelector);
    const sessionBusy = useSelector(sscSessionBusySelector);
    const { context: viewportContext } = useSelector(viewportContextSelector);
    const isSessionBusy = viewportContext === ViewportStatus.Editing ? sessionBusy : configSessionBusy;
    const locked = useSelector(isLockedSelector);
    const disabled = buildFinalized || locked || isSessionBusy;

    const { setIsEditing } = useEditCallback();
    const { handleOpen: displayPresetSnackbar } = useCablePresetSnackbar();
    const { saveBuild } = useWebSave();
    const dispatch = useDispatch();

    const movePrevious = useCallback(() => {
        dispatch(setCurrentStep(currentStep! - 1));
    }, [dispatch, currentStep]);

    const cancelEdit = useCallback(() => {
        setIsEditing(false);
        dispatch(setCurrentBuild(savedBuildData!));
        dispatch(clearSavedData());
        dispatch(setToolbarDisplay(true));
    }, [setIsEditing, dispatch, savedBuildData]);

    const moveNext = useCallback(async () => {
        dispatch(setCurrentStep(currentStep! + 1));
        if (currentStep! >= stepCount!) {
            setIsEditing(false);
            displayPresetSnackbar();
            await saveBuild();
        }
    }, [dispatch, currentStep, stepCount, setIsEditing, displayPresetSnackbar, saveBuild]);

    const applyChanges = useCallback(async () => {
        setIsEditing(false);
        dispatch(setToolbarDisplay(true));
        await saveBuild();
    }, [dispatch, setIsEditing, saveBuild]);

    return { movePrevious, cancelEdit, moveNext, connectorAssignmentResetRequired, applyChanges, disabled };
};

export const useEditCallback = () => {
    const dispatch = useDispatch();
    const { stepCount } = useSelector(wizardSelector);

    const setIsEditing = useCallback(
        (isEditing: boolean) => {
            if (isEditing) {
                dispatch(setViewportContext(ViewportStatus.Editing));
                dispatch(setWizardDisplay(true));
                dispatch(enableNextStep(false));
            } else {
                dispatch(setViewportContext(ViewportStatus.Active));
                dispatch(setApplyEditChanges(false));
                dispatch(setCurrentStep(stepCount + 1));
                dispatch(setWizardDisplay(false));
                dispatch(saveBuildData(undefined));
            }
        },
        [dispatch, stepCount]
    );

    return { setIsEditing };
};

import { ConnectorPin } from "./connector-pin";
import { useMMCTemplate } from "./fiber-mapping-connector-templates.hooks";
import { ConnectorTemplateProps } from "../../../../../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";

export const MMCTemplate: React.FC<ConnectorTemplateProps> = (props) => {
    const { side, onClick, isMMC24, pins, label, disabled, connectorType } = useMMCTemplate(props);
    let faceFillColor = "#e5ebeb";
    let opacity = 1;
    if (disabled) {
        faceFillColor = "#202020";
        opacity = 0.4;
    }
    return (
        <svg
            className="mmc_connector"
            xmlns="http://www.w3.org/2000/svg"
            width="940.01"
            height="178.27"
            viewBox="0 0 940.01 178.27"
            onClick={onClick}
        >
            <defs>
                <filter id="mmcTemplateFilter" x="0" y="19" width="940.01" height="178.27" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" in="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="b" />
                    <feFlood floodOpacity="0.161" />
                    <feComposite operator="in" in2="b" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g id="Connector_Diagram" filter="url(#mmcTemplateFilter)">
                <g id="Connector_Face_MMC">
                    <g id="Rectangle_1056-2">
                        <path
                            d="m32.01,20.49h891.99c8.84,0,16.01,7.16,16.01,16v96c0,8.84-7.17,16-16.01,16H32.01c-8.84,0-16.01-7.16-16.01-16V36.49c0-8.84,7.17-16,16.01-16Z"
                            fill={faceFillColor}
                            opacity={opacity}
                        />
                        <path
                            d="m32.01,22.49h891.99c7.74,0,14.01,6.27,14.01,14v96c0,7.73-6.27,14-14.01,14H32.01c-7.74,0-14.01-6.27-14.01-14V36.49c0-7.73,6.27-14,14.01-14Z"
                            fill="none"
                            stroke="#000010"
                            strokeWidth="4px"
                        />
                    </g>
                </g>
            </g>
            <path
                id="Rectangle_1057"
                d="m0,121.49V47.49c0-4.42,3.58-8,8-8h8v90h-8c-4.42,0-8-3.58-8-8Z"
                fill="#000010"
            />
            <g>
                <text transform="translate(49.36 13.4)" fontSize="16px" fontFamily="Inter" fontWeight="bold">
                    <tspan x="0" y="0">
                        1
                    </tspan>
                </text>
                <text transform="translate(885.27 13.4)" fontSize="16px" fontFamily="Inter" fontWeight="bold">
                    <tspan x="0" y="0">
                        {isMMC24 ? 12 : 16}
                    </tspan>
                </text>
                <text
                    transform="translate(380.96 172.51)"
                    fontSize="16px"
                    fontFamily="Inter"
                    stroke="rgba(0,0,0,0)"
                >
                    <tspan x="0" y="0">
                        {label}
                    </tspan>
                </text>
                {isMMC24 ? (
                    <>
                        <text
                            transform="translate(49.36 172.51)"
                            stroke="rgba(0,0,0,0)"
                            fontSize="16px"
                            fontFamily="Inter"
                            fontWeight="bold"
                        >
                            <tspan x="0" y="0">
                                13
                            </tspan>
                        </text>
                        <text
                            transform="translate(885.27 172.51)"
                            stroke="rgba(0,0,0,0)"
                            fontSize="16px"
                            fontFamily="Inter"
                            fontWeight="bold"
                        >
                            <tspan x="0" y="0">
                                24
                            </tspan>
                        </text>
                    </>
                ) : null}
                {pins.map((p) => {
                    return (
                        <g key={p.index} transform={`translate(${p.position.x}, ${p.position.y})`}>
                            <ConnectorPin side={side} connectorType={connectorType} pinIndex={p.index} disabled={p.disabled} />
                        </g>
                    );
                })}
            </g>
        </svg>
    );
};

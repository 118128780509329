export enum PageResizeEnum {
    FitToPage,
    FitToHeight,
    FitToSource,
}

export interface IPageResizeState {
    resize: IPageResizeData;
}

export interface IPageResizeData {
    update?: boolean;
    value: PageResizeEnum;
}

export const initialState: IPageResizeState = {
    resize: {
        update: true,
        value: PageResizeEnum.FitToSource,
    },
};

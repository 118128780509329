import { createContext } from "react";

import { IUnitOfMeasure, Units } from "../../overlay/header/units-of-measure";
import {
    ConnLC,
    ConnMMC16_NP,
    ConnMMC16_P,
    ConnMMC24_NP,
    ConnMMC24_P,
    ConnMPO12_NP,
    ConnMPO12_P,
    ConnMTP12_FP_NP,
    ConnMTP12_FP_P,
    ConnMTP12_NP,
    ConnMTP12_P,
    ConnMTP24_FP_NP,
    ConnMTP24_FP_P,
    ConnMTP24_NP,
    ConnMTP24_P,
    ConnMTP8_NP,
    ConnMTP8_P,
} from "../../../store/overlay/wizard/wizard";
import { IDropContext } from "../../../store/workspace/build/build";
import { IPoint, ISize } from "../pixi";
import { DocumentDropContainer, DocumentGraphicsContainer } from "../document-graphics";
import { IConnectorLegProps } from "./connectors/connector-furcation";
import { DropSide } from "../../../store/workspace/build/drop";

export const Feeder = "Feeder";
export const Drop = "Drop";

export const getMaxNumberOfCablesPerGroup = (connectorType: string): number => {
    switch (connectorType) {
        case ConnLC.type:
        case ConnMMC16_NP.type:
        case ConnMMC16_P.type:
        case ConnMMC24_NP.type:
        case ConnMMC24_P.type:
            return 6;
        case ConnMTP8_NP.type:
        case ConnMTP8_P.type:
        case ConnMTP12_NP.type:
        case ConnMTP12_P.type:
        case ConnMTP12_FP_NP.type:
        case ConnMTP12_FP_P.type:
        case ConnMPO12_NP.type:
        case ConnMPO12_P.type:
        case ConnMTP24_NP.type:
        case ConnMTP24_P.type:
        case ConnMTP24_FP_NP.type:
        case ConnMTP24_FP_P.type:
            return 3;
        default:
            return 6;
    }
};

export const DISTANCE_LABEL_TO_CONNECTOR_END: IUnitOfMeasure = {
    unit: Units.UoMMillimeters,
    value: 100,
};

export type Section = typeof Feeder | typeof Drop;
export const SectionContext = createContext(Feeder);

export const RABReportContainer = "report-rapid-bundle";
export const RABContainer = "rapid-bundle";
export const OverallLengthContainer = "overall-length";
export const PullingGripContainer = "pulling-grip";
export const DropContainer = "drop";
export const GroupContainer = "group";
export const ReportDropContainer = "report-drop";
export const BoundingBoxContainer = "bounding-box";
export const LegLengthContainer = "leg-length";
export const LegLengthMarkerContainer = "leg-length-marker";
export const ALengthContainer = "a-length";
export const ALengthMarkerContainer = "a-length-marker";
export const LabelLengthContainer = "label-length";
export const FurcationContainer = "furcation";
export const FurcationLabelContainer = "furcation-label";
export const CollapseMarkerContainer = "collapse-marker";
export const ShellContainer = "shell";
export const VerticalTrunkContainer = "vertical-trunk";
export const HorizontalTrunkContainer = "horizontal-trunk";

export const DropComponents = {
    DropContainer,
    FurcationContainer,
    ShellContainer,
    VerticalTrunkContainer,
    HorizontalTrunkContainer,
} as const;

export const MarkerComponents = {
    OverallLengthContainer,
    LegLengthContainer,
    ALengthContainer,
    LabelLengthContainer,
    FurcationLabelContainer,
    CollapseMarkerContainer,
} as const;

export const InteractiveComponents = {
    ShellContainer,
    BoundingBoxContainer,
    HorizontalTrunkContainer,
    LegLengthMarkerContainer,
    ALengthMarkerContainer,
} as const;

export const ComponentNames = {
    RABContainer,
    RABReportContainer,
    PullingGripContainer,
    ...DropComponents,
    ...MarkerComponents,
    ...InteractiveComponents,
    DocumentGraphicsContainer,
    DocumentDropContainer,
    ReportDropContainer,
} as const;

export const Exclusions = [BoundingBoxContainer];

const ACTIVE_CONTAINER = "active";
export const getContainerName = (containerName: string, active: boolean) =>
    active ? `${containerName}-${ACTIVE_CONTAINER}` : containerName;
export const isActiveContainer = (containerName: string) => containerName.includes(ACTIVE_CONTAINER);

export const getDropContainerName = (isReportDrop: boolean, side: DropSide, position: number) => {
    const dropContainerName = isReportDrop ? ReportDropContainer : DropContainer;
    return `${side}-${dropContainerName}-${position}`;
};
export const getFurcationContainerName = (containerName: string) => `${containerName}_${FurcationContainer}`;

export const SOURCE_CONTAINER_NAME = getDropContainerName(false, "feeder", 0);
export const SOURCE_FURCATION_CONTAINER_NAME = getFurcationContainerName(SOURCE_CONTAINER_NAME);

export type DropComponent = (typeof DropComponents)[keyof typeof DropComponents];
export type MarkerComponent = (typeof MarkerComponents)[keyof typeof MarkerComponents];
export type InteractiveComponent = (typeof InteractiveComponents)[keyof typeof InteractiveComponents];
export type ComponentName = (typeof ComponentNames)[keyof typeof ComponentNames];

export type DropProps = {
    key?: string;
    context: IDropContext;
    legProps: IConnectorLegProps[];
} & IPoint &
    Partial<ISize>;

import { RefObject } from "react";
import { IDestinationSetupState } from "../../../../store/overlay/wizard/setup/destination-setup/destination-setup";
import { findDivisors } from "../../../../store/overlay/wizard/wizard";

export interface DestinationFieldProps {
    disabled?: boolean;
    applyChanges: (state: IDestinationSetupState) => IDestinationSetupState;
    validate: (newState: IDestinationSetupState) => IDestinationSetupState;
    inputRef?: RefObject<HTMLInputElement>;
}

export type ConnectorTypeFieldProps = {
    dropMaxFiber: number;
} & DestinationFieldProps;

export type AccessPointsFieldProps = {} & DestinationFieldProps;

export type GroupsFieldProps = {
    enableSLength: (newState: IDestinationSetupState) => IDestinationSetupState;
} & DestinationFieldProps;

export type CPGFieldProps = {
    enableSLength: (newState: IDestinationSetupState) => IDestinationSetupState;
} & DestinationFieldProps;

export type ApplyToAllFieldProps = {
    resetChanges: (newState: IDestinationSetupState) => IDestinationSetupState;
} & DestinationFieldProps;

export const isValidDestinationSetup = (state: IDestinationSetupState): boolean => {
    return destinationSetupValidationFields.every((k) => state[k]);
};

export const getValidFiberCountInUseValues = (max: number, fiberCount: number, fiberCountInUse: number) => {
    return findDivisors(fiberCount)
        .filter(d => d <= max)
        .filter(d => d > fiberCountInUse ? d % fiberCountInUse === 0 : fiberCountInUse % d === 0);
};

export const destinationSetupValidationFields = [
    "isALengthValid",
    "isAccessPointsValid",
    "isBLengthValid",
    "isGroupCountValid",
    "isMeshOffsetValid",
    "isNbConnectorsPerGroupValid",
    "isSLengthValid",
    "isFiberCountPerConnectorValid",
] as const satisfies (keyof IDestinationSetupState)[];

type ValidationField = (typeof destinationSetupValidationFields)[number];

export const getValidationErrorField = (state: IDestinationSetupState): ValidationField | undefined => {
    return destinationSetupValidationFields.find((k) => !state[k]);
};

import React, { PropsWithChildren, useReducer } from "react";
import { ModalDrawer } from "./modal-drawer/modal-drawer";
import { DrawerReducer, DrawerContext } from "../../../store/ui/drawer/drawer.reducer";
import { initialDrawerContextProps } from "../../../models/ui/drawer/drawer";
import { DrawerProps } from "../../../models/ui/drawer/drawer";

export const Drawer: React.FC<PropsWithChildren<DrawerProps>> = (props) => {
    const [state, dispatch] = useReducer(DrawerReducer, initialDrawerContextProps);
    return (
        <DrawerContext.Provider value={[state, dispatch]}>
            <ModalDrawer
                id={props.id}
                className={props.className}
                open={props.open}
                anchor={props.anchor}
                onClose={props.onClose}
                logo={props.logo}
                style={props.style}
            >
                {props.children}
            </ModalDrawer>
        </DrawerContext.Provider>
    );
};

import { useEffect, useReducer } from "react";
import { DialogReducer, setDialogState } from "../../../../../store/workspace/dialog/dialog.reducer";
import { initialDialogState } from "../../../../../store/workspace/dialog/dialog";
import { IEditPolarityDialogProps } from "../../../../../models/overlay/polarity/edit-polarity-dialog";

export const useEditPolarityNameDialog = ({ props: dialogProps, type }: IEditPolarityDialogProps) => {
    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);

    useEffect(() => {
        dispatch(setDialogState({ props: dialogProps, type }));
    }, [dialogProps, type, dispatch]);

    return {
        context: { state, dispatch },
    };
};

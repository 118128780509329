import { DialogProps } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createPDF } from "../../../../helpers/pdf-exporter.helper";
import { LocalizationKeys } from "../../../../locales/keys";
import {
    Unit,
    Units,
    convertTo,
    convertToDisplay,
    getUnitsName,
} from "../../../../models/overlay/header/units-of-measure";
import { ACCEPTED_FILE_FORMATS } from "../../../../models/overlay/reports/report-settings/file-upload-dialog";
import { ProductLabel } from "../../../../models/overlay/reports/report-settings/labels-section";
import {
    ReportSectionOption,
    ReportSettings,
    ReportType,
    initialReportSettings,
} from "../../../../models/overlay/reports/report-settings/report-settings";
import { getLegacyFlameRating } from "../../../../models/overlay/wizard/setup/flame-rating";
import { MASKED_VALUE } from "../../../../models/pixi/build/overall-length";
import { IDialogHeaderProps } from "../../../../models/ui/dialog/dialog-header";
import { isAnonymousUserSelector } from "../../../../store/authentication/authentication.selectors";
import { unitsOfMeasureContainerUnitSelector } from "../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { setNotification } from "../../../../store/overlay/notification/notification.reducers";
import { setShowPrintDialog } from "../../../../store/overlay/overlay.reducers";
import { showPrintDialogSelector } from "../../../../store/overlay/overlay.selectors";
import {
    Stagger0,
    getConnectorFamilyString,
    getConnectorType,
    getConnectorTypeFromConnectorData,
    getConnectorTypeFromDrop,
} from "../../../../store/overlay/wizard/wizard";
import { DEFAULT_BOUNDARIES_NAME } from "../../../../store/workspace/boundaries/boundaries";
import {
    boundariesOptionsSelector,
    cableColorSelector,
    fiberCountOptionsSelector,
    flameRatingsSelector,
    insertionLossSelector,
} from "../../../../store/workspace/boundaries/boundaries.selectors";
import {
    bundleCountSelector,
    currentAssemblyTypeSelector,
    currentBuildPolarityDescriptionSelector,
    currentMaskLengthsSelector,
    currentOverallLengthTypeSelector,
    currentPrintSettingsSelector,
    dropsSelector,
} from "../../../../store/workspace/build.selectors";
import {
    DEFAULT_CABLE_OUTER_DIAMETER,
    DropData,
    IBuildData,
    IBuildPrintSettings,
} from "../../../../store/workspace/build/build";
import { IConnectorData, getNbConnectors } from "../../../../store/workspace/build/connector/connector";
import { IDrop, initialMeshOffset } from "../../../../store/workspace/build/drop";
import {
    BuildDocument,
    DocumentDrop,
    DocumentFiberMap,
    IUserProvidedImage,
} from "../../../../store/workspace/document/document";
import { setDocument, setUserProvidedImages } from "../../../../store/workspace/document/document.reducers";
import { userProvidedImagesSelector } from "../../../../store/workspace/document/document.selectors";
import { currentBuildSelector } from "../../../../store/workspace/root.selectors";
import { AppDispatch } from "../../../../store/workspace/workspace.reducers";
import { useDiagramSectionFields } from "./diagrams-section/diagram-section.hooks";
import { useDrawingSection } from "./drawing-section/drawing-section.hooks";
import { printDropRadioOptions, useDropSection } from "./drop-settings/drop-settings.hooks";
import { useFooterFields } from "./footer-fields/footer-fields.hooks";
import { updateBuild } from "../../../../store/workspace/build/build.reducers";
import { setStatusState } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import { extractImages } from "../../../pixi/canvas-extractor/canvas-extractor";
import { extractOffscreen } from "../../../off-screen/extractor/offscreen-extractor";

export const useFileUploadDialog = () => {
    const [display, setDisplay] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const userProvidedImages = useSelector(userProvidedImagesSelector);
    const dispatch = useDispatch();

    const onUpload = useCallback((selected: File[]) => setFiles([...files, ...selected]), [files]);
    const onDelete = useCallback((index: number) => setFiles(files.toSpliced(index, 1)), [files]);
    const isValidFile = useCallback((file: File) => ACCEPTED_FILE_FORMATS.includes(file.type), []);

    const invalidFiles = useMemo(() => files.filter((file) => !isValidFile(file)), [files, isValidFile]);
    const accept = useMemo(() => ACCEPTED_FILE_FORMATS.join(","), []);

    const onShow = useCallback(() => {
        setFiles([]);
        setDisplay(true);
    }, []);

    const onClose = useCallback(() => setDisplay(false), []);

    const onConfirm = useCallback(() => {
        onClose();

        const validFiles = files.filter((file) => !invalidFiles.includes(file));
        const newUserProvidedImages: IUserProvidedImage[] = validFiles.map((file) => ({
            fileName: file.name,
            objectUrl: URL.createObjectURL(file),
        }));

        dispatch(
            setUserProvidedImages(
                userProvidedImages ? userProvidedImages.concat(newUserProvidedImages) : newUserProvidedImages
            )
        );
    }, [dispatch, files, invalidFiles, onClose, userProvidedImages]);

    return { display, files, invalidFiles, accept, onUpload, onDelete, onConfirm, onShow, onClose };
};

export const useReportSettings = () => {
    const showPrintDialog = useSelector(showPrintDialogSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const { overviewNotes, productLabels } = useSelector(currentPrintSettingsSelector);
    const assemblyTypeId = useSelector(currentAssemblyTypeSelector);
    const cableColor = useSelector(cableColorSelector);
    const { diagramSectionFields, fieldValues: diagramFieldValues, validDiagramsSection } = useDiagramSectionFields();
    const { footerFields, fieldValues } = useFooterFields();
    const { closeWindow } = useCloseButton();
    const { print, isPrinting, setIsPrinting } = useBuildDocument();
    const fileUploadDialog = useFileUploadDialog();
    const userProvidedImages = useSelector(userProvidedImagesSelector);

    const [connectorLabelField, fiberMapField, buildPlanField, cableOverViewField, tolerancesField] =
        diagramSectionFields;

    const { connectorLabel, buildPlan, fiberMap, cableOverview, tolerances } = diagramFieldValues;

    const { siteLocation, drawnBy, revisionNumber, approvalDate, inServiceDate, notes: footerNotes } = fieldValues;

    const storeDispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const [reportType, setReportType] = useState(ReportType.TwoPage);

    const diagramFields = useMemo(() => {
        return reportType === ReportType.TwoPage
            ? [cableOverViewField, tolerancesField]
            : [cableOverViewField, tolerancesField, connectorLabelField, fiberMapField, buildPlanField];
    }, [reportType, cableOverViewField, tolerancesField, connectorLabelField, fiberMapField, buildPlanField]);

    const reportTypeSection = { reportType, setReportType, onUploadButtonClick: fileUploadDialog.onShow };

    const [overviewSettings, setOverviewSettings] = useState(initialReportSettings.cableDrawing);
    const [feederSettings, setFeederSettings] = useState(initialReportSettings.feeder);
    const [dropSettings, setDropSettings] = useState(initialReportSettings.drops);

    const overviewSection = useDrawingSection("overview", t(LocalizationKeys.CableDrawing), overviewSettings!, false);
    const feederSection = useDrawingSection(
        "feeder",
        t(LocalizationKeys.TrunkSide, { trunk: t(LocalizationKeys.Feeder) }),
        feederSettings!,
        reportType !== ReportType.Detailed
    );
    const dropSection = useDropSection({ ...dropSettings!, disabled: reportType !== ReportType.Detailed });

    const assemblyType =
        assemblyTypeId === "mesh" ? t(LocalizationKeys.AssemblyTypeMesh) : t(LocalizationKeys.AssemblyTypeJacket);
    const defaultJacketNotes = t(LocalizationKeys.InitialNotes, { assemblyType, meshColor: cableColor?.name });
    const initialDrawingNotes = overviewNotes.length > 0 ? overviewNotes : defaultJacketNotes;
    const [drawingNotes, setDrawingNotes] = useState(initialDrawingNotes);
    const [labels, setLabels] = useState<ProductLabel[]>(productLabels);
    const [disablePrint, setDisablePrint] = useState(false);

    useEffect(() => {
        const assemblyType =
            assemblyTypeId === "mesh" ? t(LocalizationKeys.AssemblyTypeMesh) : t(LocalizationKeys.AssemblyTypeJacket);
        setDrawingNotes(t(LocalizationKeys.InitialNotes, { assemblyType, meshColor: cableColor?.name }));
    }, [assemblyTypeId, t, cableColor?.name]);

    // section settings
    useEffect(() => {
        setOverviewSettings({
            include: overviewSection.checkbox.checked,
            option: overviewSection.select.value,
        });
    }, [overviewSection.checkbox.checked, overviewSection.select.value]);

    useEffect(() => {
        setFeederSettings({
            include: feederSection.checkbox.checked,
            option: feederSection.select.value,
        });
    }, [feederSection.checkbox.checked, feederSection.select.value]);

    useEffect(() => {
        if (showPrintDialog) {
            setDropSettings({
                include: dropSection.checkbox.checked,
                option: dropSection.select.value,
                includeAll: dropSection.printGroup.value === t(printDropRadioOptions[0]),
            });

            setDrawingNotes(initialDrawingNotes);
            setLabels(productLabels);
        }
    }, [
        dropSection.checkbox.checked,
        dropSection.select.value,
        dropSection.printGroup.value,
        showPrintDialog,
        t,
        initialDrawingNotes,
        productLabels,
    ]);

    useEffect(() => {
        const dropFieldValue = dropSection.printDropField.value as string;
        const validDropSection =
            dropSection.printGroup.value === t(printDropRadioOptions[0]) ||
            (dropSection.printGroup.value === t(printDropRadioOptions[1]) &&
                dropSection.printDropField.isValid &&
                dropFieldValue &&
                dropFieldValue.length > 0);

        setDisablePrint(
            !(
                (overviewSection.checkbox.checked ||
                    feederSection.checkbox.checked ||
                    dropSection.checkbox.checked ||
                    validDiagramsSection) &&
                validDropSection
            )
        );
    }, [overviewSection, validDiagramsSection, t, dropSection, feederSection]);

    const printSelection = useCallback(async () => {
        setIsPrinting(true);
        if (currentBuild) {
            const printSettings: IBuildPrintSettings = {
                overviewNotes: drawingNotes,
                productLabels: labels,
                location: siteLocation,
                drawnBy,
                revisionNumber,
                approvalDate,
                inServiceDate,
                footerNotes,
            };
            storeDispatch(updateBuild({ ...currentBuild, ...printSettings }));
        }
        const docSettings: ReportSettings = {
            reportType,
            inServiceDate,
            approvalDate,
            revision: revisionNumber,
            siteLocation,
            drawn: drawnBy,
            drawingNotes: drawingNotes,
            footerNotes: footerNotes,
            cableDrawing: overviewSettings,
            feeder: feederSettings,
            drops: { ...dropSettings!, dropPositions: dropSection.printDropField.selectedDrops },
            connectorLabel: connectorLabel,
            fiberMap: fiberMap,
            buildPlan: buildPlan,
            productLabels: labels,
            cableOverview: cableOverview,
            tolerances: tolerances,
            userProvidedImages,
        };
        try {
            await print(docSettings);
            storeDispatch(setShowPrintDialog(false));
        } catch (e: any) {
            storeDispatch(
                setNotification({
                    message: t(LocalizationKeys.PrintReportError, { message: e.message }),
                    palette: "error",
                })
            );
        } finally {
            setIsPrinting(false);
        }
    }, [
        setIsPrinting,
        currentBuild,
        reportType,
        inServiceDate,
        approvalDate,
        revisionNumber,
        siteLocation,
        drawnBy,
        drawingNotes,
        footerNotes,
        overviewSettings,
        feederSettings,
        dropSettings,
        dropSection.printDropField.selectedDrops,
        cableOverview,
        connectorLabel,
        fiberMap,
        buildPlan,
        labels,
        tolerances,
        userProvidedImages,
        storeDispatch,
        print,
        t,
    ]);

    const headerProps: IDialogHeaderProps = {
        title: t(LocalizationKeys.PrintSettings),
        closable: true,
        collapsible: false,
        onClose: closeWindow,
    };

    const dialogProps: DialogProps = {
        open: showPrintDialog,
        hideBackdrop: true,
        className: "report-settings",
    };

    return {
        dialogProps,
        footerFields,
        drawingFields: [overviewSection, feederSection],
        dropSection,
        showPrintDialog,
        drawingNotes,
        setDrawingNotes,
        labelsSection: {
            labels,
            setLabels,
        },
        diagramFields,
        reportTypeSection,
        headerProps,
        actions: {
            confirmText: isPrinting ? t(LocalizationKeys.Printing) : t(LocalizationKeys.PrintReport),
            disableConfirm: disablePrint || isPrinting,
            cancelText: t(LocalizationKeys.Cancel),
            onCancelClick: closeWindow,
            onConfirmClick: printSelection,
        },
        fileUploadDialog,
        isPrinting,
    };
};

const useBuildDocument = () => {
    const { t } = useTranslation();
    const overalLLengthType = useSelector(currentOverallLengthTypeSelector);
    const bundleCount = useSelector(bundleCountSelector);
    const isAnonymous = useSelector(isAnonymousUserSelector);
    const currentDrops = useSelector(dropsSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const polarityDescription = useSelector(currentBuildPolarityDescriptionSelector);
    const flameRatingOptions = useSelector(flameRatingsSelector);
    const fiberCountOptions = useSelector(fiberCountOptionsSelector);
    const insertionLoss = useSelector(insertionLossSelector);
    const maskLengths = useSelector(currentMaskLengthsSelector);
    const fiberType = currentBuild?.fiberType;
    const connectorTypeString = currentBuild?.drops[0].groups[0].connectors[0].type;
    const connectorTypeObj = connectorTypeString ? getConnectorType(connectorTypeString) : undefined;
    const modes = useSelector(boundariesOptionsSelector);
    const dispatch = useDispatch<AppDispatch>();

    const [isPrinting, setIsPrinting] = useState(false);

    const print = useCallback(
        async (settings: ReportSettings) => {
            dispatch(setStatusState("printing"));
            const {
                reportType,
                cableDrawing,
                feeder,
                inServiceDate,
                approvalDate,
                drawn,
                footerNotes,
                drawingNotes,
                revision,
                siteLocation,
                drops,
                connectorLabel,
                buildPlan,
                fiberMap,
                productLabels,
                tolerances,
                cableOverview,
                userProvidedImages,
            } = settings;
            const units = getUnitsName(unit, false, true);
            const fileName = !isAnonymous
                ? currentBuild?.name?.length
                    ? currentBuild?.name?.replace(/[^a-z0-9]/gi, "")
                    : String(currentBuild!.id)
                : "";
            const catalogCode = currentBuild?.catalogCode;
            const draft = !currentBuild?.catalogCode?.length;
            const doc: BuildDocument = {
                reportType,
                inServiceDate,
                approvalDate,
                drawn,
                revision,
                siteLocation,
                footerNotes,
                drawingNotes,
                units,
                fileName,
                draft,
                catalogCode,
                productLabels,
                userProvidedImages,
            };

            // apply report settings to document
            if (cableDrawing?.include && currentBuild) {
                const overviewBuild = JSON.parse(JSON.stringify(currentBuild)) as IBuildData; // create deep copy
                const collapsed = cableDrawing.option === ReportSectionOption.Collapsed;
                overviewBuild.drops = overviewBuild.drops.map((d) => {
                    return cableDrawing.option === ReportSectionOption.Workspace
                        ? d
                        : { ...d, groupsCollapsed: collapsed, connectorsCollapsed: collapsed };
                });
                overviewBuild.tolerances = currentBuild?.tolerances;
                overviewBuild.availability = currentBuild.availability;
                const connectorTypeString = overviewBuild.drops
                    .flatMap((d) =>
                        d.groups.flatMap((g) => g.connectors.map((c) => getConnectorTypeFromConnectorData(c)))
                    )
                    .filter((c, i, self) => c.type !== undefined && self.findIndex((s) => s.type === c.type) === i)
                    .map((c) => c.type)
                    .join(", ");
                const distribution = currentDrops.filter(c => c.side === "distribution");
                const distributionBundleCount = distribution.map((d) => getNbConnectors(d.groups)).reduce((a, b) => a + b, 0);
                const bundleCountValue = Math.min(bundleCount, distributionBundleCount);
                const bundleCountString = `${bundleCountValue} ${t(LocalizationKeys.Bundle)}`;
                const flameRating = getLegacyFlameRating(overviewBuild.flameRating!, flameRatingOptions)!;
                const cableOuterDiameter = currentBuild?.cableOuterDiameter ?? DEFAULT_CABLE_OUTER_DIAMETER;
                const flameRatingString = `${flameRating.description}`;
                const cableOuterDiameterString = `${t(LocalizationKeys.CableOD)}${t(
                    LocalizationKeys.Colon
                )}${cableOuterDiameter.toFixed(1)}${Units.UoMMillimeters}`;
                const catalogCodeString = !draft
                    ? `${t(LocalizationKeys.AssemblyNumber)}${t(LocalizationKeys.Colon)}${currentBuild!.catalogCode}`
                    : "";

                let insertionLossString = t(LocalizationKeys.UnknownInsertionLoss);
                if (fiberType && connectorTypeObj) {
                    const boundariesInsertionLoss =
                        insertionLoss[fiberType][getConnectorFamilyString(connectorTypeObj)];
                    if (boundariesInsertionLoss) {
                        insertionLossString = boundariesInsertionLoss;
                    }
                }

                const mode = modes.find((d) => d.id === currentBuild?.modeId)?.name ?? DEFAULT_BOUNDARIES_NAME;
                const modeString = `${t(LocalizationKeys.Mode)}${t(LocalizationKeys.Colon)}${mode}`;

                const description = [
                    bundleCountString,
                    connectorTypeString,
                    flameRatingString,
                    cableOuterDiameterString,
                    insertionLossString,
                    modeString,
                    catalogCodeString,
                ]
                    .filter((d) => d.length > 0)
                    .join(" | ");

                doc.overview = { ...overviewBuild, description }; // Consumed in DocumentGraphics hooks (const { overview } = useSelector(reportDocumentSelector);) to draw the pixi cable and take the image to add to the document
            }

            const feederDrop = currentDrops.find((d) => d.side === "feeder");
            if (feeder?.include && feederDrop) {
                const buildFeeder = JSON.parse(JSON.stringify(feederDrop)) as IDrop; // create deep copy
                const lengthB = buildFeeder.customBLength
                    ? buildFeeder.groups.map((g) => g.lengthB!).reduce((a, b) => (a.value < b.value ? a : b))
                    : buildFeeder.lengthB;

                const bEndStaggerValue =
                    buildFeeder.lengthB!.value +
                    (buildFeeder.groups.length - 1) * buildFeeder.groups[0].stagger!.value!;
                const maxLengthB = buildFeeder.customBLength
                    ? buildFeeder.groups.map((g) => g.lengthB!).reduce((a, b) => (a.value > b.value ? a : b))
                    : { ...buildFeeder.lengthB!, value: bEndStaggerValue };

                const hasRange = buildFeeder.customBLength
                    ? !buildFeeder.groups.every((g) => g.lengthB!.value === buildFeeder.groups[0].lengthB!.value)
                    : buildFeeder.groups.length > 1;

                const bStaggerStartString = convertToDisplay(lengthB!, unit);
                const bEndStaggerString = convertToDisplay(maxLengthB, unit);

                const bStaggerRange = hasRange
                    ? `${bStaggerStartString + unit} to ${bEndStaggerString + unit}`
                    : `${bStaggerStartString + unit}`;

                const connectorType = getConnectorType(buildFeeder.groups[0].type);
                const connectorCount = getNbConnectors(buildFeeder.groups);
                const connectorsCountString = `${connectorCount} ${t(LocalizationKeys.Connectors)}`;
                const connectorTypeString = `${t(LocalizationKeys.ConnectorType)}${t(LocalizationKeys.Colon)}${
                    connectorType.description
                }`;

                const fiberCount = buildFeeder.fiberCountInUse
                    ? buildFeeder.fiberCountInUse * connectorCount
                    : connectorType.fiberCount * connectorCount;
                const fiberCountString = `${t(LocalizationKeys.FiberCount)}${t(LocalizationKeys.Colon)}${fiberCount}F`;
                let polarityString = "";
                if (polarityDescription && polarityDescription.length > 0) {
                    polarityString = ` | ${t(LocalizationKeys.Polarity)}${t(
                        LocalizationKeys.Colon
                    )}${polarityDescription}`;
                }

                const bString = `B0${t(LocalizationKeys.Colon)}${bStaggerRange}`;
                const meshOffsetValue = buildFeeder.meshOffset?.value ?? initialMeshOffset.value;
                const meshOffsetString = buildFeeder.mesh
                    ? `${t(LocalizationKeys.MeshOffset)}${t(LocalizationKeys.Colon)}${meshOffsetValue}${unit}`
                    : t(LocalizationKeys.NoMesh);

                const description = [
                    connectorsCountString,
                    connectorTypeString,
                    `${fiberCountString}${polarityString}`,
                    bString,
                    meshOffsetString,
                ].join(" | ");

                const collapsed = feeder.option === ReportSectionOption.Collapsed;
                doc.feeder =
                    feeder.option === ReportSectionOption.Workspace
                        ? { ...buildFeeder, description }
                        : { ...buildFeeder, description, groupsCollapsed: collapsed, connectorsCollapsed: collapsed };
            }

            const fiberMapNames: string[] = polarityDescription.length > 0 ? polarityDescription.split(", ") : [];
            if (drops?.include && feederDrop) {
                const destinations = currentDrops.filter((d) => d.side === "distribution");
                const dropPositions = drops.includeAll ? destinations.map((d) => d.position + 1) : drops.dropPositions;
                if (dropPositions) {
                    const documentDrops = dropPositions
                        .filter(
                            (value, index, self) =>
                                self.indexOf(value) === index && destinations.find((d) => d.position + 1 === value)
                        ) // unique and real drops
                        .sort((p, c) => (p > c ? 1 : -1))
                        .map((position) => {
                            const destination = destinations.find((d) => d.position === position - 1)!;
                            const connectorCount = getNbConnectors(destination.groups);
                            const collapsed = drops.option === "collapsed";
                            const drop = JSON.parse(JSON.stringify(destination)) as IDrop; // Create deep copy;
                            const destinationData: IDrop =
                                drops.option === ReportSectionOption.Workspace
                                    ? drop
                                    : { ...drop, groupsCollapsed: collapsed, connectorsCollapsed: collapsed };

                            const lengthB = destination.customBLength
                                ? destination.groups
                                      .map((g) => g.lengthB!)
                                      .reduce((a, b) => (a.value < b.value ? a : b))
                                : destination.lengthB;

                            const bEndStaggerValue =
                                destination.lengthB!.value +
                                (destination.groups.length - 1) * destination.groups[0].stagger!.value!;
                            const maxLengthB = destination.customBLength
                                ? destination.groups
                                      .map((g) => g.lengthB!)
                                      .reduce((a, b) => (a.value > b.value ? a : b))
                                : { ...destination.lengthB!, value: bEndStaggerValue };

                            const hasRange = destination.customBLength
                                ? !destination.groups.every(
                                      (g) => g.lengthB!.value === destination.groups[0].lengthB!.value
                                  )
                                : destination.groups.length > 1;

                            const bStaggerStartString = convertToDisplay(lengthB!, unit);
                            const bEndStaggerString = convertToDisplay(maxLengthB, unit);

                            const bStaggerRange = hasRange
                                ? `${bStaggerStartString + unit} to ${bEndStaggerString + unit}`
                                : `${bStaggerStartString + unit}`;

                            const dropConnectorType = getConnectorType(destination.groups[0].type);
                            const fiberCount = drop.fiberCountInUse
                                ? drop.fiberCountInUse * connectorCount
                                : connectorCount * dropConnectorType.fiberCount;
                            const a = convertToDisplay(destination.lengthA, unit);

                            const connectorsCountString = `${connectorCount} ${t(LocalizationKeys.Connectors)}`;
                            const connectorTypeString = `${t(LocalizationKeys.ConnectorType)}${t(
                                LocalizationKeys.Colon
                            )}${getConnectorType(destination.groups[0].type!).description}`;
                            const fiberCountString = `${t(LocalizationKeys.FiberCount)}${t(
                                LocalizationKeys.Colon
                            )}${fiberCount}F`;

                            let polarityName: string | undefined = undefined;
                            if (fiberMapNames.length === 1) {
                                polarityName = fiberMapNames[0];
                            } else if (fiberMapNames.length > 1) {
                                polarityName = t(LocalizationKeys.Multiple);
                            }
                            const polarityString = polarityName
                                ? ` | ${t(LocalizationKeys.Polarity)}${t(LocalizationKeys.Colon)}${polarityName} | `
                                : " |";

                            const displayedPosition = destination.position + 1;
                            const aString = maskLengths
                                ? ""
                                : `A${displayedPosition}${t(LocalizationKeys.Colon)}${a + unit} `;
                            const bString = `B${displayedPosition}${t(LocalizationKeys.Colon)}${bStaggerRange}`;
                            const meshOffsetValue = destination.meshOffset?.value ?? initialMeshOffset.value;
                            const meshOffsetString = destination.mesh
                                ? `${t(LocalizationKeys.MeshOffset)}${t(
                                      LocalizationKeys.Colon
                                  )}${meshOffsetValue}${unit}`
                                : t(LocalizationKeys.NoMesh);

                            const description = [
                                connectorsCountString,
                                connectorTypeString,
                                `${fiberCountString}${polarityString} ${aString}${bString}`,
                                meshOffsetString,
                            ].join(" | ");

                            const documentDrop: DocumentDrop = {
                                drop: destinationData,
                                description,
                            };

                            return documentDrop;
                        });
                    doc.drops = documentDrops;
                }
            }

            if (fiberMapNames.length > 0) {
                doc.polarity = {
                    fiberMapNames,
                };
            }

            if (fiberMap?.include && !fiberMap.disabled) {
                const documentFiberMap: DocumentFiberMap = {
                    ...fiberMap,
                    type: getConnectorTypeFromDrop(currentDrops[0]).type,
                };

                doc.fiberMap = documentFiberMap;
            }

            if (connectorLabel?.include && !connectorLabel.disabled) {
                doc.connector = { include: true };
            }

            if (tolerances?.include && !tolerances.disabled) {
                doc.tolerances = { include: true };
            }

            if (cableOverview?.include && !cableOverview.disabled) {
                doc.cableOverview = { include: true };
            }

            if (buildPlan?.include && !buildPlan.disabled && feederDrop) {
                const customFiberCount = fiberCountOptions.find((f) => f === currentBuild!.fiberCount!)
                    ? `${currentBuild!.fiberCount!}`
                    : t(LocalizationKeys.RoundBracket, {
                          subject: `${currentBuild!.fiberCount!}`,
                          extra: t(LocalizationKeys.Custom).toLocaleLowerCase(),
                      });

                const connectors = currentDrops
                    .filter((d) => d.side === "distribution")
                    .map((d) => d.groups)
                    .flat()
                    .map((g) => g.connectors)
                    .flat();
                let distinctConnectors = getDistinctConnectors(connectors);
                const tapsConnectorTypes = distinctConnectors.join(", ");
                const taps = currentDrops.filter((d) => d.side === "distribution");
                const tapsCount = taps.length;
                const tapsBundleCount = taps.map((t) => getNbConnectors(t.groups)).reduce((a, b) => a + b, 0);
                const bundleCountValue = Math.min(bundleCount, tapsBundleCount);
                const bundleCountString =  `${t(LocalizationKeys.BundleCount)}${t(
                    LocalizationKeys.Colon
                )}${bundleCountValue}`;
                const fiberCountString =
                    t(LocalizationKeys.TotalFiberCount) + t(LocalizationKeys.Colon) + customFiberCount;
                const feederConnectorTypeString =
                    t(LocalizationKeys.FeederConnectorType) +
                    t(LocalizationKeys.Colon) +
                    getConnectorType(feederDrop.groups[0].type!).description!;
                const tapsConnectorTypesString =
                    t(LocalizationKeys.TAPsConnectorTypes) + t(LocalizationKeys.Colon) + tapsConnectorTypes;
                const tapCountString = t(LocalizationKeys.NbTAPs) + t(LocalizationKeys.Colon) + tapsCount;
                const overallLengthTypeString =
                    overalLLengthType === "connector"
                        ? t(LocalizationKeys.OverallLengthTypeConnector)
                        : t(LocalizationKeys.OverallLengthTypeFurcation);
                const overallLengthString = `${t(
                    LocalizationKeys.OverallLength
                )} (${overallLengthTypeString.toLowerCase()})${t(LocalizationKeys.Colon)}${getOverallLengthString(
                    currentDrops,
                    overalLLengthType,
                    unit,
                    maskLengths
                )}`;

                doc.buildPlan = {
                    ...buildPlan,
                    summary: {
                        bundleCount: bundleCountString,
                        fiberCount: fiberCountString,
                        feederConnectorType: feederConnectorTypeString,
                        tapsConnectorTypes: tapsConnectorTypesString,
                        tapsCount: tapCountString,
                        overallLength: overallLengthString,
                    },
                };
            }

            dispatch(setDocument(doc));
            await timeout(500);
            extractImages("report-rapid-bundle", "report-drop");
            extractOffscreen();
            await timeout(500);
            await createPDF(doc, t);
            dispatch(setUserProvidedImages([]));
            dispatch(setStatusState("printed"));
            dispatch(setShowPrintDialog(false));
        },
        [
            unit,
            isAnonymous,
            currentBuild,
            currentDrops,
            dispatch,
            t,
            bundleCount,
            flameRatingOptions,
            fiberType,
            connectorTypeObj,
            insertionLoss,
            polarityDescription,
            fiberCountOptions,
            overalLLengthType,
            modes,
            maskLengths,
        ]
    );

    return { print, isPrinting, setIsPrinting };
};

function getDistinctConnectors(connectors: IConnectorData[]) {
    const distinct: { [key: string]: { val: string } } = {};

    for (const { type } of connectors) {
        if (!distinct[type!]) {
            distinct[type!] = { val: type! };
        }
    }

    return Object.keys(distinct);
}

function getOverallLengthString(drops: IDrop[], type: string, unit: Unit, maskLengths: boolean) {
    if (maskLengths) {
        return MASKED_VALUE;
    }

    const [feeder, ...distribution] = drops;
    let value = distribution.map((t) => convertTo(t.lengthA, unit).value).reduce((prev, curr) => prev + curr);
    if (type === "connector") {
        value += Math.max(...feeder.groups.map((g) => convertTo(g.lengthB ?? Stagger0.value, unit).value));
        value += Math.max(
            ...distribution.flatMap((d) => d.groups.map((g) => convertTo(g.lengthB ?? Stagger0.value, unit).value))
        );
    }

    return `${convertToDisplay({ value, unit }, unit)} ${getUnitsName(unit, false, true)}`;
}

export function getMaxFurcationLegLength(drop: DropData) {
    let assemblyLength = drop.lengthB!.value + (drop.groups!.length - 1) * drop.groups![0].stagger!.value;
    return assemblyLength;
}

function timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

const useCloseButton = () => {
    const dispatch = useDispatch<AppDispatch>();

    const closeWindow = useCallback(() => {
        dispatch(setShowPrintDialog(false));
        dispatch(setUserProvidedImages([]));
    }, [dispatch]);

    return { closeWindow };
};

import { currentBuildSelector } from "../../workspace/root.selectors";
import { ViewportStatus } from "../../pixi/viewport/viewport";
import { createSelector } from "reselect";
import { getConnectorType } from "./wizard";
import { viewportContextSelector } from "../../pixi/viewport/viewport.selectors";
import { getNbConnectors } from "../../workspace/build/connector/connector";
import { WorkspaceState } from "../../workspace/workspace.reducers";

export const wizardSelector = (state: WorkspaceState) => state.wizard;
export const wizardSavedBuildDataSelector = (state: WorkspaceState) => state.wizard.savedBuildData;
export const wizardDisplaySelector = (state: WorkspaceState) => state.wizard.display;
export const wizardApplyEditChangesSelector = (state: WorkspaceState) => state.wizard.applyEditChanges;
export const isDynamicUpdateDisabledSelector = (state: WorkspaceState) => state.wizard.isDynamicUpdateDisabled;
export const wizardFocusOnErrorSelector = (state: WorkspaceState) => state.wizard.focusOnError;

export const connectorAssignmentResetRequiredSelector = createSelector(
    wizardSavedBuildDataSelector,
    currentBuildSelector,
    (savedBuildData, currentBuild): boolean => {
        if (savedBuildData && currentBuild) {
            const [savedSource, ...savedDestinations] = savedBuildData.drops;
            const [currentSource, ...currentDestinations] = currentBuild.drops;
            const savedSourceGroups = savedSource.groups;
            const currentSourceGroups = currentSource.groups;

            if (savedDestinations.length !== currentDestinations.length) {
                return true;
            }
            const bundleCountChanged = getNbConnectors(savedSourceGroups) !== getNbConnectors(currentSourceGroups);
            const savedConnectorType = savedSourceGroups[0].connectors[0].type!;
            const currentConnectorType = currentSourceGroups[0].connectors[0].type!;
            const sourceGroupChanged = savedSourceGroups.length !== currentSourceGroups.length;
            const sourceFiberDensityChanged =
                getConnectorType(savedConnectorType).fiberCount !== getConnectorType(currentConnectorType).fiberCount;
            const apCountChanged = savedDestinations.length !== currentDestinations.length;
            const dstGroupChanged = savedDestinations.some(
                (d, i) => d.groups.length !== currentDestinations[i].groups.length
            );
            const dstFiberDensityChanged = savedDestinations.some(
                (d, i) =>
                    d.groups.length &&
                    d.groups[0].connectors.length &&
                    getConnectorType(d.groups[0].connectors[0].type!).fiberCount !==
                        getConnectorType(currentDestinations[i].groups[0].type!).fiberCount
            );
            return [
                bundleCountChanged,
                sourceGroupChanged,
                sourceFiberDensityChanged,
                apCountChanged,
                dstGroupChanged,
                dstFiberDensityChanged,
            ].some((b) => b);
        }
        return false;
    }
);

export const wizardActiveSelector = createSelector(
    wizardDisplaySelector,
    viewportContextSelector,
    (wizardOpen, { context: viewportContext }) => wizardOpen && viewportContext === ViewportStatus.Inactive
);

import {
    IDestinationSetupContentProps,
    IDestinationSetupContext,
} from "../../../../../store/overlay/wizard/setup/destination-setup/destination-setup";
import { BLengthSection } from "../b-length-section/b-length-section";
import { CustomBLength } from "../b-length-section/custom-b-length/custom-b-length";
import { DropCheckbox } from "../drop-checkbox/drop-checkbox";
import { MeshProperties } from "../mesh-properties/mesh-properties";
import React, { useMemo, useReducer } from "react";
import { StaggerAutocomplete } from "../stagger/stagger-autocomplete";
import { UniformBLength } from "../b-length-section/uniform-b-length";
import { useDestinationSetup } from "./destination-setup.hooks";
import {
    DestinationSetupContext,
    DestinationSetupReducer,
} from "../../../../../store/overlay/wizard/setup/destination-setup/destination-setup.reducers";
import { Checkbox, Select, SelectItem as Option, TextField } from "@corning-ctcm/silica-react";

const DestinationSetup: React.FC = () => {
    const {
        disabled,
        connectorType,
        fiberCountPerConnectorProps,
        accessPointProps,
        groupProps,
        cpgProps,
        nbDstProps,
        meshProps,
        aLengthProps,
        bLengthTypeProps,
        bLengthProps,
        sLengthProps,
        applyToAll,
        reverseStaggeringProps,
    } = useDestinationSetup();

    return (
        <div className="setup">
            <div className="field-container">
                <Select {...connectorType.props}>
                    {connectorType.options.map((c, i) => (
                        <Option id={`connector-type-option-${i}`} key={c.key} value={c.type}>
                            {c.description}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="field-container">
                <TextField {...fiberCountPerConnectorProps} />
            </div>
            <div className="field-container horizontal">
                <div className="ap-field field">
                    <TextField {...accessPointProps} />
                </div>
                <DropCheckbox />
            </div>
            <div className="field-container horizontal">
                <div className="group-field field">
                    <TextField {...groupProps} />
                </div>
                <div className="connector-separator">X</div>
                <div className="cpg-field field">
                    <TextField {...cpgProps} />
                </div>
            </div>
            <div className="field-container">
                <TextField {...nbDstProps} />
            </div>
            <MeshProperties {...meshProps} />
            <div className="field-container">
                <TextField {...aLengthProps} />
            </div>
            <div className="field-container">
                <BLengthSection {...bLengthTypeProps}>
                    <UniformBLength>
                        <div className="field-container horizontal">
                            <div className="field">
                                <TextField {...bLengthProps.textField} />
                            </div>
                            <StaggerAutocomplete {...sLengthProps} />
                        </div>
                        <div className="field-container reverse-staggering">
                            {applyToAll.display && (
                                <div className="apply-to-all">
                                    <Checkbox {...applyToAll.props} />
                                </div>
                            )}
                            <Checkbox {...reverseStaggeringProps} />
                        </div>
                    </UniformBLength>
                    <CustomBLength disabled={disabled} />
                </BLengthSection>
            </div>
        </div>
    );
};

export const DestinationContent: React.FC<IDestinationSetupContentProps> = ({ initialState }) => {
    const [state, dispatch] = useReducer(DestinationSetupReducer, initialState);
    const context: IDestinationSetupContext = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return (
        <DestinationSetupContext.Provider value={context}>
            <DestinationSetup />
        </DestinationSetupContext.Provider>
    );
};

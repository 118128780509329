import { IFiberMapData } from "../../../../store/workspace/build/connector/polarity/fiber-map";
import {
    CUSTOM_MAP_KEY,
    PolarityMap,
    PolarityMapList,
} from "../../../../store/workspace/build/connector/polarity/polarity";
import {
    ConnectorAssignmentReducer,
    resetAll,
    resetSelection,
    selectAssignment,
    selectRows,
    setPolarityAssignment,
    setPolarityKey,
    setSelectAll,
} from "../../../../store/overlay/polarity/connector-assignment/connector-assignment.reducer";
import {
    IConnectorAssignmentContext,
    IConnectorAssignmentState,
    initialConnectorAssignmentState,
} from "../../../../store/overlay/polarity/connector-assignment/connector-assignment";
import { INavigationBarState, initialNavigationBarState } from "../../../../store/ui/navigation-bar/navigation-bar";
import {
    ITAPNavigationBarProps,
    ITAPNavigationMap,
    PolarityAssignments,
} from "../../../../models/overlay/polarity/connector-assignment/connector-assigment";
import { NavigationBarReducer, setCurrentIndex } from "../../../../store/ui/navigation-bar/navigation-bar.reducer";
import React, { ChangeEvent, Dispatch, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    buildIdSelector,
    connectorAssignmentsSelector,
    currentSourceSelector,
    currentTAPsSelector,
    dropsSelector,
    unassignedBuildConnectorsSelector,
} from "../../../../store/workspace/build.selectors";
import { showConnectorAssignmentSelector } from "../../../../store/overlay/overlay.selectors";
import {
    fiberMapSelectorFactory,
    filteredPolarityMapsSelector,
} from "../../../../store/workspace/build/connector/polarity/polarity.selectors";
import {
    addConnectorAssignments,
    deleteAllConnectorAssignments,
    updateSpecificConnectors,
} from "../../../../store/workspace/build/build.reducers";
import { setShowConnectorAssignment, setShowPolarity } from "../../../../store/overlay/overlay.reducers";
import {
    sscBuildPolaritySelector,
    sscDefaultBuildPolaritiesSelector,
} from "../../../../store/workspace/ssc/ssc.selectors";
import { Action } from "redux";
import { BuildService } from "../../../../services/build.service";
import { ICollapsibleDialogProps } from "../../../../models/ui/dialog/collapsible-dialog";
import { IDialogHeaderProps } from "../../../../models/ui/dialog/dialog-header";
import { IDrop } from "../../../../store/workspace/build/drop";
import { IGenericDialogProps } from "../../../../models/ui/dialog/generic-dialog";
import { INavigationBarProps } from "../../../../models/ui/navigation-bar";
import { LocalizationKeys } from "../../../../locales/keys";
import { setStatusState } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import { useCheckBoxInput } from "../../../ui/checkbox/checkbox.hooks";
import { useTranslation } from "react-i18next";
import { setPropagation, showPropagation } from "../../../../store/overlay/polarity/propagation/propagation.reducers";
import { ButtonProps, IconButtonProps, SelectProps } from "@corning-ctcm/silica-react";
import {
    isPropagationActiveSelector,
    propagationOptionsSelector,
} from "../../../../store/overlay/polarity/propagation/propagation.selectors";
import {
    setNotification,
    setPropagationNotification,
} from "../../../../store/overlay/notification/notification.reducers";
import { buildDisabledSelector } from "../../../../store/workspace/build/build.selector";
import { IConnectorData } from "../../../../store/workspace/build/connector/connector";
import { setFilteredPolarityList } from "../../../../store/workspace/build/connector/polarity/polarity.reducer";
import { getConnectorTypeFromConnectorData } from "../../../../store/overlay/wizard/wizard";
import { CheckboxProps } from "@corning-ctcm/silica-react";
import { IConnectorAssignment } from "../../../../store/overlay/polarity/polarity";
import {
    setHighlights,
    setSelectedConnectorId,
} from "../../../../store/pixi/connector-highlights/connector-highlights.reducers";
import { IHighlight } from "../../../../store/pixi/connector-highlights/connector-highlights";
import { selectedConnectorIdSelector } from "../../../../store/pixi/connector-highlights/connector-highlights.selectors";

export const useConnectorAssignmentDialog = () => {
    const filteredPolarityMaps = useSelector(filteredPolarityMapsSelector);
    const display = useSelector(showConnectorAssignmentSelector);
    const disabled = useSelector(buildDisabledSelector);
    const connectorAssignments = useSelector(connectorAssignmentsSelector);

    const [localState, localDispatch] = useReducer(ConnectorAssignmentReducer, initialConnectorAssignmentState);
    const [navigationBarState, navigationBarDispatch] = useReducer(NavigationBarReducer, initialNavigationBarState);
    const assignmentContext: IConnectorAssignmentContext = {
        state: localState,
        dispatch: localDispatch,
    };
    const { t } = useTranslation();
    const storeDispatch = useDispatch();

    useEffect(() => {
        if (!display) {
            navigationBarDispatch(setCurrentIndex(0));
            localDispatch(resetSelection());
        }
    }, [display]);

    const headerProps: IDialogHeaderProps = {
        title: t(LocalizationKeys.ConnectorAssignment),
        collapsible: true,
    };

    const dialog: ICollapsibleDialogProps = {
        id: "connector-assignment",
        display: display,
        className: "connector-assignment-dialog",
        headerProps,
    };

    useHighlights(localState, localDispatch, display, connectorAssignments);

    const selectAll = useSelectAll(localState.selectAll, disabled, localDispatch);

    const polarityAssignment = usePolarityAssignment(disabled, localState, localDispatch);
    const { selectedPolarityKey, polarityMaps, polarityTypeProps } = usePolarityType(
        disabled,
        localState,
        localDispatch
    );

    const onShowPolarityConfigs = useCallback(() => {
        storeDispatch(setShowConnectorAssignment(false));
        storeDispatch(setShowPolarity(true));
    }, [storeDispatch]);

    const configIcon: Omit<IconButtonProps, "icon"> = {
        id: "polarity-diagram-icon",
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.PolarityDiagram),
        placement: "bottom",
        onClick: onShowPolarityConfigs,
    };

    const apply = useApply(selectedPolarityKey, polarityMaps, disabled, localState, localDispatch);

    const resetAllDisabled = connectorAssignments.length === 0 || disabled;
    const resetAll = useResetAll(resetAllDisabled, localDispatch);

    const hasCustom = filteredPolarityMaps.length === 1 && filteredPolarityMaps[0].demoKey === `${CUSTOM_MAP_KEY}`; // remove once custom polarity schemes are implemented
    const propagate = usePropagation(disabled || hasCustom);

    const { leftTAP, rightTAP, previousDisabled, nextDisabled, navigationBarProps } = useTAPNavigationBar(
        navigationBarState,
        navigationBarDispatch
    );
    const { feederConnectors, leftContainerTitle, leftTAPConnectors, rightContainerTitle, rightTAPConnectors } =
        useAssignmentData(leftTAP, rightTAP);

    return {
        dialog,
        assignmentContext,
        selectAll,
        polarityAssignment,
        polarityType: polarityTypeProps,
        configIcon,
        apply,
        sourceProps: { rows: feederConnectors },
        leftTAPProps: {
            previousDisabled,
            onPreviousClick: navigationBarProps.onPreviousClick,
            title: leftContainerTitle,
            rows: leftTAPConnectors,
        },
        rightTAPProps: {
            nextDisabled,
            onNextClick: navigationBarProps.onNextClick,
            title: rightContainerTitle,
            rows: rightTAPConnectors,
            display: rightTAPConnectors.length > 0,
        },
        resetAll,
        propagate,
        navigationBarProps,
    };
};

const useHighlights = (
    { assignedSelection, feederSelection, distributionSelection }: IConnectorAssignmentState,
    localDispatch: Dispatch<Action>,
    display: boolean,
    connectorAssignments: IConnectorAssignment[]
) => {
    const selectedConnectorId = useSelector(selectedConnectorIdSelector);
    const storeDispatch = useDispatch();

    useEffect(() => {
        if (selectedConnectorId > -1) {
            const assignmentConnectorIds = connectorAssignments.map((a) => [
                ...a.feederConnectors,
                ...a.distributionConnectors,
            ]);
            const assignmentIndex = assignmentConnectorIds.findIndex((ids) => ids.includes(selectedConnectorId));
            if (assignmentIndex > -1) {
                localDispatch(selectAssignment(assignmentConnectorIds[assignmentIndex]));
            }
        }
    }, [selectedConnectorId, connectorAssignments, localDispatch, storeDispatch]);

    useEffect(() => {
        if (selectedConnectorId > -1 && assignedSelection.length === 0) {
            storeDispatch(setSelectedConnectorId(-1));
        }
    }, [selectedConnectorId, assignedSelection.length, storeDispatch]);

    useEffect(() => {
        if (display) {
            const highlights: IHighlight[] = assignedSelection.map((id) => ({
                connectorId: id,
                assigned: true,
                selected: true,
            }));
            const assigned = connectorAssignments
                .flatMap((a) => [...a.feederConnectors, ...a.distributionConnectors])
                .filter((id) => !assignedSelection.includes(id));
            highlights.push(...assigned.map((a) => ({ connectorId: a, assigned: true, selected: false })));
            const selected = [...feederSelection, ...distributionSelection];
            highlights.push(...selected.map((a) => ({ connectorId: a, assigned: false, selected: true })));
            storeDispatch(setHighlights(highlights));
        }
    }, [display, assignedSelection, connectorAssignments, feederSelection, distributionSelection, storeDispatch]);
};

const useSelectAll = (selectAll: boolean, disabled: boolean, localDispatch: Dispatch<Action>) => {
    const { feederConnectors, distributionConnectors } = useSelector(unassignedBuildConnectorsSelector);
    const { t } = useTranslation();

    const label = t(LocalizationKeys.SelectAllConnectors);
    const selectAllDisabled = disabled || feederConnectors.length === 0;
    const { onCheckBoxChange, ...selectAllProps } = useCheckBoxInput(
        "select-all-connectors",
        label,
        selectAll,
        selectAllDisabled
    );

    const onSelectAllChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            onCheckBoxChange(e);
            const selectAll = e.currentTarget.checked;
            localDispatch(setSelectAll(e.currentTarget.checked));
            if (selectAll) {
                localDispatch(selectRows([...feederConnectors, ...distributionConnectors]));
            }
        },
        [onCheckBoxChange, localDispatch, feederConnectors, distributionConnectors]
    );

    const selectAllCheckboxProps: CheckboxProps = {
        id: `checkbox-container ${selectAllProps.className}`,
        className: selectAllProps.name,
        palette: "primary",
        label: selectAllProps.label,
        value: selectAllProps.checked,
        checked: selectAllProps.checked,
        disabled: selectAllProps.disabled,
        onChange: onSelectAllChange,
    };

    return selectAllCheckboxProps;
};

const useAssignmentData = (leftTAP: IDrop | undefined, rightTAP: IDrop | undefined) => {
    const feeder = useSelector(currentSourceSelector);
    const { t } = useTranslation();

    const feederConnectors = feeder?.groups.flatMap((c) => c.connectors) ?? [];
    let leftTAPConnectors: IConnectorData[] = [];
    let leftContainerTitle = "";
    if (leftTAP) {
        leftContainerTitle = t(LocalizationKeys.TapNumber, { tapNumber: leftTAP.position + 1 });
        leftTAPConnectors = leftTAP.groups.flatMap((c) => c.connectors);
    }
    let rightTAPConnectors: IConnectorData[] = [];
    let rightContainerTitle = "";
    if (rightTAP) {
        rightContainerTitle = t(LocalizationKeys.TapNumber, { tapNumber: rightTAP.position + 1 });
        rightTAPConnectors = rightTAP.groups.flatMap((c) => c.connectors);
    }

    return {
        feederConnectors,
        leftContainerTitle,
        leftTAPConnectors,
        rightContainerTitle,
        rightTAPConnectors,
    };
};

const useTAPNavigationBar = (state: INavigationBarState, dispatch: React.Dispatch<Action>) => {
    const taps = useSelector(currentTAPsSelector);
    const { currentIndex, disabled } = state;
    const { t } = useTranslation();

    const tapsNavigationMapping = useMemo(() => {
        const tapsNavigationMapping: ITAPNavigationMap[] = [];
        for (let i = 0; i < taps.length; i++) {
            const tap = taps[i];
            const tapPosition = tap.position + 1;
            if (tapPosition % 2 === 0) {
                continue;
            }
            const nextTap = taps[i + 1];
            tapsNavigationMapping.push({
                leftTAP: tap,
                rightTAP: nextTap,
                label: nextTap ? `${tapPosition}-${nextTap.position + 1}` : tapPosition.toString(),
            });
        }
        return tapsNavigationMapping;
    }, [taps]);

    const onNavigationButtonClick = useCallback(
        (index: number) => {
            dispatch(setCurrentIndex(index));
        },
        [dispatch]
    );

    const onFirstClick = useCallback(() => {
        const firstIndex = 0;
        onNavigationButtonClick(firstIndex);
    }, [onNavigationButtonClick]);

    const onPreviousClick = useCallback(() => {
        const previousIndex = currentIndex ? currentIndex - 1 : 0;
        onNavigationButtonClick(previousIndex);
    }, [currentIndex, onNavigationButtonClick]);

    const onNextClick = useCallback(() => {
        const nextIndex = (currentIndex + 1) % tapsNavigationMapping.length;
        onNavigationButtonClick(nextIndex);
    }, [currentIndex, tapsNavigationMapping, onNavigationButtonClick]);

    const onLastClick = useCallback(() => {
        const lastIndex = tapsNavigationMapping.length - 1;
        onNavigationButtonClick(lastIndex);
    }, [tapsNavigationMapping, onNavigationButtonClick]);

    const buttons = useMemo(
        () =>
            tapsNavigationMapping.map((b, i) => {
                const tooltip = t(LocalizationKeys.TapsNumber, { tapNumber: b.label });
                return {
                    buttonProps: {
                        id: tooltip.replaceAll(" ", "-").toLowerCase(),
                        onClick: () => onNavigationButtonClick(i),
                    },
                    label: b.label,
                };
            }),
        [tapsNavigationMapping, onNavigationButtonClick, t]
    );

    const navigationBarProps: INavigationBarProps = useMemo(
        () => ({
            context: { state, dispatch },
            displayThreshold: 10,
            onFirstClick,
            onPreviousClick,
            buttons,
            onNextClick,
            onLastClick,
        }),
        [state, dispatch, onFirstClick, onPreviousClick, buttons, onNextClick, onLastClick]
    );

    const tapNavigationBarProps: ITAPNavigationBarProps = useMemo(() => {
        const previousDisabled = currentIndex === 0 || disabled;
        const nextDisabled = buttons ? currentIndex + 1 === buttons.length : disabled;

        const tapMap = tapsNavigationMapping[currentIndex];

        return {
            navigationBarProps,
            leftTAP: tapMap.leftTAP,
            rightTAP: tapMap.rightTAP,
            previousDisabled,
            nextDisabled,
        };
    }, [navigationBarProps, tapsNavigationMapping, buttons, currentIndex, disabled]);

    return tapNavigationBarProps;
};

const usePolarityType = (
    disabled: boolean,
    { polarityKey, feederSelection, distributionSelection, assignedSelection }: IConnectorAssignmentState,
    localDispatch: React.Dispatch<Action>
) => {
    const filteredPolarityMaps = useSelector(filteredPolarityMapsSelector);
    const defaultBuildPolarities = useSelector(sscDefaultBuildPolaritiesSelector);
    const display = useSelector(showConnectorAssignmentSelector);
    const drops = useSelector(dropsSelector);
    const polarityConfigs = useSelector(sscBuildPolaritySelector);
    const storeDispatch = useDispatch();

    const selectedPolarityKey = useMemo(() => {
        if (polarityKey !== -1) return polarityKey;

        let defaultPolarityKey: number = -1;
        const defaultConfig = defaultBuildPolarities.find((p) =>
            filteredPolarityMaps.some((f) => f.customKey === p.polarityMap?.customKey)
        );
        if (defaultConfig && defaultConfig.polarityMap && defaultConfig.polarityMap.key !== undefined) {
            defaultPolarityKey = defaultConfig.polarityMap.key;
        } else {
            if (filteredPolarityMaps.length > 0) {
                const defaultMap = filteredPolarityMaps[0];
                if (defaultMap.key !== undefined) {
                    defaultPolarityKey = defaultMap.key;
                }
            }
        }
        return defaultPolarityKey;
    }, [polarityKey, defaultBuildPolarities, filteredPolarityMaps]);

    const hasSelection = feederSelection.length > 0 && distributionSelection.length > 0;
    const hasCustom = filteredPolarityMaps.length === 1 && filteredPolarityMaps[0].demoKey === `${CUSTOM_MAP_KEY}`; // for now we'll keep it disabled
    const polarityDisabled =
        disabled || !hasSelection || filteredPolarityMaps.length === 0 || assignedSelection.length > 0 || hasCustom; // remove 'hasCustom' once custom polarities are implemented
    const className = polarityDisabled ? "dropdown disabled" : "dropdown";

    const onPolarityChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const polarity = Number(e.target.value);
            localDispatch(setPolarityKey(polarity));
        },
        [localDispatch]
    );

    useEffect(() => {
        if (!display) return;

        // filter list based on selection or current drop connectors
        const [feeder, ...distribution] = drops;
        let feederConnectors = feeder.groups.flatMap((g) =>
            g.connectors.map((c) => ({ ...c, fiberCountInUse: feeder.fiberCountInUse }))
        );
        let distributionConnectors = distribution.flatMap((d) =>
            d.groups.flatMap((g) => g.connectors.map((c) => ({ ...c, fiberCountInUse: d.fiberCountInUse })))
        );
        if (feederSelection.length > 0) {
            feederConnectors = feederConnectors.filter((c) => c.id && feederSelection.includes(c.id));
        }
        if (distributionSelection.length > 0) {
            distributionConnectors = distributionConnectors.filter((c) => c.id && distributionSelection.includes(c.id));
        }
        const feederConnectorTypes = feederConnectors.map((c) => getConnectorTypeFromConnectorData(c));
        const distributionConnectorTypes = distributionConnectors.map((c) => getConnectorTypeFromConnectorData(c));
        storeDispatch(
            setFilteredPolarityList({
                feederConnectorTypes,
                distributionConnectorTypes,
                polarityConfigs,
            })
        );
    }, [display, drops, feederSelection, distributionSelection, polarityConfigs, storeDispatch]);

    const polaritySchemeSelectProps: SelectProps = {
        id: "polarity-scheme-dropdown",
        className,
        palette: "primary",
        value: selectedPolarityKey,
        disabled: polarityDisabled,
        onChange: onPolarityChange,
    };

    return {
        selectedPolarityKey,
        polarityMaps: filteredPolarityMaps,
        polarityTypeProps: {
            select: polaritySchemeSelectProps,
            options: filteredPolarityMaps,
        },
    };
};

const usePolarityAssignment = (
    disabled: boolean,
    { polarityAssignment, selectAll, assignedSelection }: IConnectorAssignmentState,
    localDispatch: Dispatch<Action>
) => {
    const assignmentDisabled = disabled || selectAll || assignedSelection.length > 0;
    const className = assignmentDisabled ? "dropdown method disabled" : "dropdown method";

    const onPolarityAssignmentChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const assignment = e.currentTarget.value;
            localDispatch(setPolarityAssignment(assignment));
        },
        [localDispatch]
    );

    const polarityAssignmentSelectProps: SelectProps = {
        id: "polarity-assignment-dropdown",
        className,
        palette: "primary",
        value: polarityAssignment,
        onChange: onPolarityAssignmentChange,
        disabled: assignmentDisabled,
    };

    return {
        select: polarityAssignmentSelectProps,
        options: [PolarityAssignments.Standard],
    };
};

const useApply = (
    polarityKey: number,
    polarityMaps: PolarityMap[],
    disabled: boolean,
    { feederSelection, distributionSelection, assignedSelection }: IConnectorAssignmentState,
    localDispatch: Dispatch<Action>
) => {
    const filteredPolarityMaps = useSelector(filteredPolarityMapsSelector);
    const buildId = useSelector(buildIdSelector);
    const fiberMap = useSelector(fiberMapSelectorFactory(polarityKey));
    const propagationOptions = useSelector(propagationOptionsSelector);
    const hasSelection = feederSelection.length > 0 && distributionSelection.length > 0;
    const hasCustom = filteredPolarityMaps.length === 1 && filteredPolarityMaps[0].demoKey === `${CUSTOM_MAP_KEY}`; // remove once custom polarities are implemented
    const applyDisabled =
        disabled ||
        !hasSelection ||
        polarityKey === -1 ||
        polarityMaps.length === 0 ||
        assignedSelection.length > 0 ||
        hasCustom;

    const { t } = useTranslation();
    const storeDispatch = useDispatch();

    const onApplyClick = useCallback(async () => {
        if (!hasSelection) return;

        const connectorAssignments = generateConnectorAssignments(feederSelection, distributionSelection, fiberMap);
        localDispatch(resetSelection());
        try {
            storeDispatch(setStatusState("saving"));
            const addConnectorAssignementsResponse = await new BuildService().addConnectorAssignments(
                buildId,
                connectorAssignments,
                propagationOptions
            );
            if (addConnectorAssignementsResponse) {
                const { connectorAssignments, propagationResult } = addConnectorAssignementsResponse;
                if (connectorAssignments.length > 0) {
                    storeDispatch(addConnectorAssignments(connectorAssignments));
                }
                if (propagationResult) {
                    storeDispatch(setPropagationNotification(propagationResult));
                    if (propagationResult.connectors.length > 0) {
                        storeDispatch(updateSpecificConnectors(propagationResult.connectors));
                    }
                }
            }
        } finally {
            localDispatch(resetAll());
            storeDispatch(setStatusState("saved"));
        }
    }, [
        hasSelection,
        feederSelection,
        distributionSelection,
        fiberMap,
        localDispatch,
        storeDispatch,
        buildId,
        propagationOptions,
    ]);

    const applyButtonProps: ButtonProps = {
        id: "connector-asignment-apply-button",
        className: "apply-button",
        disabled: applyDisabled,
        onClick: onApplyClick,
    };

    return {
        button: applyButtonProps,
        label: t(LocalizationKeys.Apply),
    };
};

const generateConnectorAssignments = (
    feederSelection: number[],
    distributionSelection: number[],
    fiberMap: IFiberMapData
): IConnectorAssignment[] => {
    let nbFeederConnectors = fiberMap.sourceConnectors.length;
    let nbDistributionConnectors = fiberMap.destinationConnectors.length;
    if (fiberMap.key === CUSTOM_MAP_KEY) {
        nbFeederConnectors = feederSelection.length;
        nbDistributionConnectors = distributionSelection.length;
    }

    const feederRatio = feederSelection.length / nbFeederConnectors;
    const distributionRatio = distributionSelection.length / nbDistributionConnectors;
    const nbAssignments = Math.ceil(Math.min(feederRatio, distributionRatio));

    const connectorAssignments: IConnectorAssignment[] = [];
    for (let assignmentIndex = 0; assignmentIndex < nbAssignments; assignmentIndex++) {
        const feederStart = assignmentIndex * nbFeederConnectors;
        const feederEnd = feederStart + nbFeederConnectors;
        const feederConnectorIds = feederSelection.slice(feederStart, feederEnd);
        const distributionStart = assignmentIndex * nbDistributionConnectors;
        const distributionEnd = distributionStart + nbDistributionConnectors;
        const distributionConnectorIds = distributionSelection.slice(distributionStart, distributionEnd);
        connectorAssignments.push({
            fiberMapId: fiberMap.key,
            feederConnectors: feederConnectorIds,
            distributionConnectors: distributionConnectorIds,
        });
    }

    return connectorAssignments;
};

const useResetAll = (disabled: boolean, connectorAssignmentDispatch: React.Dispatch<Action>) => {
    const buildId = useSelector(buildIdSelector);
    const { t } = useTranslation();
    const storeDispatch = useDispatch();

    const [showResetDialog, setShowResetDialog] = useState(false);

    const onResetAll = useCallback(() => {
        setShowResetDialog(true);
    }, []);

    const resetAllButtonProps: ButtonProps = {
        id: "connector-assignment-reset-button",
        palette: "error",
        disabled,
        onClick: onResetAll,
    };

    const onResetClose = useCallback(() => {
        setShowResetDialog(false);
    }, []);

    const onResetConfirm = useCallback(async () => {
        setShowResetDialog(false);
        storeDispatch(setStatusState("saving"));
        storeDispatch(deleteAllConnectorAssignments());
        try {
            const deleted = await new BuildService().deleteAllConnectorAssignments(buildId);
            if (deleted) {
                storeDispatch(
                    setPropagation({
                        propagateColors: false,
                        propagateLabelText: false,
                        propagateLabelColor: false,
                    })
                ); // Turn off connector propagation
                connectorAssignmentDispatch(resetAll());
                storeDispatch(
                    setNotification({
                        palette: "success",
                        message: t(LocalizationKeys.DeleteConnectorAssignmentsSuccess),
                    })
                );
            }
        } catch {
            storeDispatch(
                setNotification({
                    palette: "error",
                    message: t(LocalizationKeys.DeleteConnectorAssignmentsError),
                })
            );
        } finally {
            onResetClose();
            storeDispatch(setStatusState("saved"));
        }
    }, [storeDispatch, buildId, connectorAssignmentDispatch, onResetClose, t]);

    const resetAllDialogProps: IGenericDialogProps = {
        id: "reset-connector-assignment",
        title: t(LocalizationKeys.ResetConnectorAssignment),
        display: showResetDialog,
        message: t(LocalizationKeys.ResetConnectorAssignmentMessage),
        closable: true,
        onClose: onResetClose,
        confirmText: t(LocalizationKeys.Reset),
        onConfirm: onResetConfirm,
        critical: true,
    };
    return {
        button: resetAllButtonProps,
        label: t(LocalizationKeys.ResetAll),
        dialog: resetAllDialogProps,
    };
};

const usePropagation = (disabled: boolean) => {
    const isPropagationActive = useSelector(isPropagationActiveSelector);
    const { t } = useTranslation();
    const storeDispatch = useDispatch();

    const onPropagateClick = useCallback(() => {
        storeDispatch(showPropagation(true));
    }, [storeDispatch]);

    const propagationButtonProps: ButtonProps = {
        className: "propagate-button",
        disabled,
        onClick: onPropagateClick,
    };

    const propagationStatus = isPropagationActive ? t(LocalizationKeys.On) : t(LocalizationKeys.Off);
    return {
        button: propagationButtonProps,
        label: t(LocalizationKeys.PropagateWithStatus, { status: propagationStatus }),
    };
};

export function getFiberMapName(demoKey: string): string {
    return PolarityMapList.find((p) => p.demoKey === demoKey || p.customKey === demoKey)?.description ?? demoKey;
}

import { useSelector } from "react-redux";
import { IConnectorType } from "../../../store/overlay/wizard/wizard";
import { unitsOfMeasureContainerUnitNameSelector } from "../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { LocalizationKeys } from "../../../locales/keys";
import { t } from "i18next";

type DropConnectorRow = {
    connectorId: number;
    groupNumber: number;
    label: string | undefined;
    colorIndex: number;
    b: string;
    connectorType: IConnectorType;
    comment: string;
};

export type DropConnectorTableProps = {
    id: string;
    rows: DropConnectorRow[];
};

export const DropConnectorTable: React.FC<DropConnectorTableProps> = ({ id, rows }: DropConnectorTableProps) => {
    const unitName = useSelector(unitsOfMeasureContainerUnitNameSelector);
    if (rows.length === 0) return null;

    return (
        <Table id={id}>
            <TableHead>
                <TableRow>
                    <TableCell>{t(LocalizationKeys.Connector)}</TableCell>
                    <TableCell>{t(LocalizationKeys.Group)}</TableCell>
                    <TableCell>{t(LocalizationKeys.Label)}</TableCell>
                    <TableCell>{t(LocalizationKeys.ConnectorColor)}</TableCell>
                    <TableCell>{t(LocalizationKeys.B0Length, { unitName })}</TableCell>
                    <TableCell>{t(LocalizationKeys.ConnectorType)}</TableCell>
                    <TableCell>{t(LocalizationKeys.Comment)}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((r) => (
                    <TableRow key={r.connectorId}>
                        <TableCell>{r.connectorId}</TableCell>
                        <TableCell>{r.groupNumber}</TableCell>
                        <TableCell>{r.label}</TableCell>
                        <TableCell>{r.colorIndex}</TableCell>
                        <TableCell>{r.b}</TableCell>
                        <TableCell>{r.connectorType.description}</TableCell>
                        <TableCell>{r.comment}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

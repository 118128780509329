import { Length } from "./length";
import { LineMarker } from "./line-marker/line-marker";
import { useDimensionLines } from "./dimension-lines.hooks";

export const DimensionLines: React.FC = () => {
    const { aLengthProps, bLengthProps, staggerLines, staggerMarkers, labelLengthProps } = useDimensionLines();

    return (
        <>
            {aLengthProps && <Length {...aLengthProps} />}
            <Length {...bLengthProps} />
            {staggerLines.map(({ key, ...c }) => (
                <LineMarker key={key} {...c} />
            ))}
            {staggerMarkers.map(({ key, ...c }) => (
                <LineMarker key={key} {...c} />
            ))}
            <Length {...labelLengthProps} />
        </>
    );
};

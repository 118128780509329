export const LocalizationKeys = {
    AboutThisApplication: "about-this-application",
    AccessPoint: "access-point",
    AccessPointConnectorTypes: "access-point-connector-types",
    AccessPointNumber: "access-point-number",
    AccessPoints: "access-points",
    AccessPointsConnectorError: "access-points-connector-count-error",
    AcrossGroups: "across-groups",
    AddMesh: "add-mesh",
    ALengthCumulative: "a-length-cumulative",
    ALengthIncremental: "a-length-incremental",
    AllCollapseOptions: "all-collapse-options",
    AllConnectors: "all-connectors",
    AnonymousUserWarning: "anonymous-user-warning",
    Apply: "apply",
    ApplyColor: "apply-color",
    ApplyChanges: "apply-changes",
    ApplyPolarity: "apply-polarity",
    ApplyScheme: "apply-scheme",
    ApplyToAllAPs: "apply-to-all-aps",
    ApplyToAssembly: "apply-to-assembly",
    ApplyTriggerColors: "apply-trigger-colors",
    APPosition: "ap-position",
    ApprovedBy: "approved-by",
    AssemblyNewName: "assembly-new-name",
    AssemblyNumber: "assembly-number",
    AssemblyTypeMesh: "assembly-type-mesh",
    AssemblyTypeJacket: "assembly-type-jacket",
    AssignedFibers: "assigned-fibers",
    AssignPolarity: "assign-polarity",
    AsShownOnWorkspace: "as-shown-on-workspace",
    Back: "back",
    BackToWork: "back-to-work",
    BuildPlan: "build-plan",
    BuildPlanFurcationSpillout: "buildplan-furcation-spillout",
    BoundariesCreationLabel: "boundaries-creation-label",
    BoundariesDefaultName: "boundaries-default-name",
    BoundariesDefaultSubtitle: "boundaries-default-subtitle",
    BoundariesDeleteMessage: "boundaries-delete-message",
    BoundariesDeleteTitle: "boundaries-delete-title",
    BoundariesModifySubtitle: "boundaries-modify-subtitle",
    BoundariesSettings: "boundaries-settings",
    BothEnds: "both-ends",
    Bundle: "bundle",
    BundleCount: "bundle-count",
    BundleLengthReportRepresentation: "bundle-length-report-representation",
    B0Length: "b0-length",
    Cables: "cables",
    CableDetailsLengthA0: "cable-details-lenght-a0",
    CableDetailsLengthAX: "cable-details-lenght-ax",
    CableDetailsLengthB0: "cable-details-lenght-b0",
    CableDetailsLengthBX: "cable-details-lenght-bx",
    CableDetailsStaggerS0: "cable-details-stagger-s0",
    CableDetailsStaggerSX: "cable-details-stagger-sx",
    CableDrawing: "cable-drawing",
    CableLockedWarning: "cable-locked-warning",
    CableName: "cable-name",
    CableNewName: "cable-new-name",
    CableNumber: "cable-number",
    CableOverview: "cable-overview",
    CablePresetConfirmation: "cable-preset-confirmation",
    CableSetup: "cable-setup",
    CableTrunkLengths: "cable-trunk-lengths",
    CableOuterDiameter: "cable-outer-diameter",
    CableOuterDiameterWithUnits: "cable-outer-diameter-with-units",
    CableOD: "cable-od",
    Cancel: "cancel",
    CatalogCode: "catalog-code",
    Centimeters: "centimeters",
    CheckedBy: "checked-by",
    Clear: "clear",
    ClearAll: "clear-all",
    ClearFiberMapping: "clear-fiber-mapping",
    ClearFiberMappingMessage: "clear-fiber-mapping-message",
    ClientSignature: "client-signature",
    Close: "close",
    Collapse: "collapse",
    CollapseOptions: "collapse-options",
    CollapsedTether: "collapsed-tether",
    Colon: "colon",
    ColorSelection: "color-selection",
    Comment: "comment",
    Components: "components",
    ConfigConnNumber: "config-conn-number",
    ConfigurationType: "configuration-type",
    Confirm: "confirm",
    ConnColor: "conn-color",
    Connector: "connector",
    ConnectorColor: "connector-color",
    ConnectorAndLabelSummary: "connector-label-summary",
    ConnectorAssignment: "connector-assignment",
    ConnectorNumber: "connector-number",
    Connectors: "connectors",
    ConnectorsPerGroup: "connectors-per-group",
    ConnectorSelection: "connector-selection",
    ConnectorType: "connector-type",
    ConnType: "conn-type",
    Continue: "continue",
    CopyNumber: "copy-number",
    CorningCopyright: "corning-copyright",
    Create: "create",
    Custom: "custom",
    CustomFiberCount: "custom-fiber-count",
    CustomFiberCountValue: "custom-fiber-count-value",
    CustomPasteInfo: "custom-paste-info",
    Customization: "customization",
    CustomizedLabels: "customized-labels",
    CustomizeLabelScheme: "customize-label-scheme",
    CustomLength: "custom-length",
    CustomPolarityUniqueName: "custom-polarity-unique-name",
    CustomTag: "custom-tag",
    Date: "date",
    DateApproval: "date-approval",
    DateInService: "date-service",
    DateModified: "date-modified",
    DecimalsProhibited: "decimals-prohibited",
    Decrements: "decrements",
    DefaultCustomTag: "default-custom-tag",
    DefaultDate: "default-date",
    DefaultDescription: "default-description",
    DefaultPartNumber: "default-part-number",
    DefaultPolarityApplied: "default-polarity-applied",
    Delete: "delete",
    DeleteCable: "delete-cable",
    DeleteConnectorAssignmentsError: "delete-connector-assignments-error",
    DeleteConnectorAssignmentsSuccess: "delete-connector-assignments-success",
    DeleteCustomPolarity: "delete-custom-polarity",
    DeleteCustomPolarityMessage: "delete-custom-polarity-message",
    DeleteCustomPolarityWarning: "delete-custom-polarity-warning",
    DeleteCustomPolarityError: "delete-custom-polarity-error",
    DeleteCustomPolaritySnackBarMessage: "delete-custom-polarity-snackbar-message",
    DeleteMessage: "delete-message",
    Description: "description",
    Destination: "destination",
    DetailedReport: "detailed-report",
    Disclaimer: "disclaimer",
    Distribution: "distribution",
    DragYourFileHere: "drag-your-file-here",
    DrawingBy: "drawn-by",
    DrawingFeatures: "drawing-features",
    DrawingSpecs: "drawing-specs",
    DrawingStoppedUpdating: "drawing-stopped-updating",
    Drop: "drop",
    DropCollapseOptions: "drop-collapse-options",
    DropPosition: "drop-position",
    DuplicateCable: "duplicate-cable",
    EditAssemblyType: "edit-assembly-type",
    EditPolarityName: "edit-polarity-name",
    EditProperties: "edit-properties",
    EditShellType: "edit-shell-type",
    Ellipsis: "ellipsis",
    EmptyInputField: "empty-input-field",
    EnableCustomAccesPoints: "enable-custom-access-points",
    EnterFullscreen: "enter-fullscreen",
    ErrorRetrievingCatalogCode: "error-retrieving-catalog-code",
    Errors: "errors",
    ErrorsAndWarnings: "errors-and-warnings",
    ErrorUpdatingBuildInfo: "error-updating-build-info",
    Examples: "examples",
    ExcessiveDecimalPlaces: "excessive-decimal-places",
    ExitFullscreen: "exit-fullscreen",
    ExitToProductPage: "exit-to-product-page",
    Expand: "expand",
    ExpandedTether: "expanded-tether",
    Exterior: "exterior",
    Feeder: "feeder",
    FeederConnector: "feeder-connector",
    FeederConnectorType: "feeder-connector-type",
    Feet: "feet",
    FiberCount: "fiber-count",
    FiberCountPerConnector: "fiber-count-per-connector",
    FiberMap: "fiber-map",
    FiberPosition: "fiber-position",
    FiberNumber: "fiber-number",
    FiberType: "fiber-type",
    FieldLengthValidation: "field-length-validation",
    FinalizeCable: "finalize-cable",
    First: "first",
    FitToHeight: "fit-to-height",
    FitToPage: "fit-to-page",
    FitToSource: "fit-to-source",
    FitToWidth: "fit-to-width",
    FlameRating: "flame-rating",
    Footer: "footer",
    Fullscreen: "fullscreen",
    GetCatalogCode: "get-catalog-code",
    GroupPosition: "group-position",
    Group: "group",
    Groups: "groups",
    HeatShrink: "heat-shrink",
    IdleSessionTitle: "idle-session-timeout",
    IdleSessionMessage: "idled-session-message",
    Imperial: "imperial",
    Import: "import",
    ImportErrorMessage: "import-error-message",
    ImportFileEmptyErrorMessage: "import-file-empty-error-message",
    ImportFileTypeErrorMessage: "import-file-type-error-message",
    ImportSyntaxErrorEmptyObject: "import-syntax-error-empty-object",
    ImportSyntaxErrorInvalidJson: "import-syntax-error-invalid-json",
    ImportSyntaxErrorMessage: "import-syntax-error-message",
    Important: "important",
    Inches: "inches",
    InclusionsTwoPage: "inclusions-two-page",
    InclusionsDetailed: "inclusions-detailed",
    Increments: "increments",
    InitialNotes: "initial-notes",
    Interior: "interior",
    InvalidAPs: "invalid-aps",
    InvalidEntry: "invalid-entry",
    InvalidEntryWithValidEntries: "invalid-entry-with-valid-entries",
    InvalidFiberDistribution: "invalid-fiber-distribution",
    InvalidFiberEntry: "invalid-fiber-entry",
    InvalidWord: "invalid-word",
    KeepAssemblyCollapsedWarning: "keep-assembly-collapsed-warning",
    Label: "label",
    LabelTitle: "label-title",
    LabelType: "label-type",
    LabelTypeHelp: "label-type-help",
    LabelDescription: "label-description",
    LabelDescriptionHelp: "label-description-help",
    LabelSize: "label-size",
    LabelSizeNotSpecified: "label-size-not-specified",
    LabelSizeHelp: "label-size-help",
    LabelOneBarcode: "label-one-barcode",
    LabelNotes: "label-notes",
    Labels: "labels",
    LabelReportNote: "label-report-note",
    LabelReportDefaultType: "label-report-default-type",
    LabelReportSize: "label-report-size",
    LabelColor: "label-color",
    LabelText: "label-text",
    LabelDistanceToleranceLabel: "label-distance-tolerance-label",
    LabelScheme: "label-scheme",
    LabelSchemeApplyAllWarningMessage: "label-scheme-apply-all-warning-message",
    LabelSchemeEditLabel: "label-scheme-edit-label",
    LabelSchemeWarningMessage: "label-scheme-warning-message",
    LabelSequenceDescription: "label-sequence-description",
    Last: "last",
    LastBuild: "last-build",
    LCUniboot: "lc-uniboot",
    LCUnibootViewOnly: "lc-uniboot-view-only",
    LCSimplex: "lc-simplex",
    LCSimplexViewOnly: "lc-simplex-view-only",
    LCDuplex: "lc-duplex",
    LCDuplexViewOnly: "lc-duplex-view-only",
    LegLengthToleranceLabel: "leg-length-tolerance-label",
    LengthA: "lengthA",
    LengthAWithUnit: "lengthA-with-unit",
    LengthB: "lengthB",
    LengthBWithUnit: "lengthB-with-unit",
    LimitExceeded: "limit-exceeded",
    LoadCable: "load-cable",
    Loading: "loading",
    Location: "location",
    Locked: "locked",
    LoginAgain: "login-again",
    LogOut: "log-out",
    LoadMoreCables: "load-more-cables",
    ManageConnectorColor: "manage-connector-color",
    ManageLabelScheme: "manage-label-scheme",
    Manual: "manual",
    ManufConnNumber: "manuf-conn-number",
    ManufTapNumber: "manuf-tap-number",
    MaskLengths: "mask-lengths",
    MatchAssignmentColors: "match-assignment-colors",
    MatchAssignmentColorsWarning: "match-assignment-colors-warning",
    Max: "max",
    MeasurementsPosition: "measurements-position",
    Mesh: "mesh",
    MeshColor: "mesh-color",
    MeshLengthToleranceLabel: "mesh-length-tolerance-label",
    MeshOffset: "mesh-offset",
    MeshOffsetBLengthError: "mesh-offset-blength-error",
    Meters: "meters",
    Metric: "metric",
    Millimeters: "millimeters",
    Min: "min",
    MissingFibers: "missing-fibers",
    MissingValue: "missing-value",
    MmcFiberCount: "mmc-fiber-count",
    MmcFiberCountViewOnly: "mmc-fiber-count-view-only",
    Mode: "mode",
    MpoFiberCount: "mpo-fiber-count",
    MpoFiberCountViewOnly: "mpo-fiber-count-view-only",
    MtpFiberCount: "mtp-fiber-count",
    MtpFiberCountViewOnly: "mtp-fiber-count-view-only",
    Multiple: "multiple",
    NA: "n-a",
    Name: "name",
    NbAccessPoints: "nb-access-points",
    NbAPs: "nb-aps",
    NbCPG: "nb-cpg",
    NbDstConnectors: "nb-dst-connectors",
    NbGroups: "nb-groups",
    NbSrcConnectors: "nb-src-connectors",
    NbTAPs: "nb-taps",
    NewCable: "new-cable",
    Next: "next",
    No: "no",
    NoConnectorsAvailable: "no-connectors-available",
    NoImageAvailable: "no-image-available",
    NoMesh: "no-mesh",
    None: "none",
    Notes: "notes",
    OK: "ok",
    Or: "or",
    OpenIdFailed: "open-id-failed",
    OverallLengthToleranceLabel: "overall-length-tolerance-label",
    Pages: "pages",
    Off: "off",
    On: "on",
    OverallLength: "overall-length",
    OverallLengthWithUnit: "overall-length-with-unit",
    OverallLengthTypeConnector: "overall-length-type-connector",
    OverallLengthTypeFurcation: "overall-length-type-furcation",
    OverallLengthTypeLabel: "overall-length-type-label",
    PageResize: "page-resize",
    PartNumber: "part-number",
    PartNumberAbr: "part-number-abr",
    PartNumberOverride: "part-number-override",
    PCE: "pce",
    Polarity: "polarity",
    PolarityConfig: "polarity-config",
    PolarityDiagram: "polarity-diagram",
    PolarityImportantDescription: "polarity-important-description",
    PolarityInstructionsDescription: "polarity-instructions-description",
    PolarityInstructionsTitle: "polarity-instructions-title",
    PolarityNameExists: "polarity-name-exists",
    PolarityNewName: "polarity-new-name",
    PolarityType: "polarity-type",
    PolarityUnavailable: "polarity-unavailable",
    Position: "position",
    Previous: "previous",
    Print: "print",
    Printing: "printing",
    PrintAllAP: "print-all-ap",
    PrintAPHint: "print-ap-hint",
    Printed: "printed",
    PrintReport: "print-report",
    PrintReportError: "print-report-error",
    PrintSettings: "print-settings",
    PrimaryMeasurementSystem: "primary-measurement-system",
    Processing: "processing",
    ProductConfiguration: "product-configuration",
    PropagateWithStatus: "propagate-with-status",
    PropagateConnectorAttributes: "propagate-connector-attributes",
    PropagationColorError: "propagation-color-error",
    PropagationErrorMessage: "propagation-error-message",
    PropagationIdenticalMessage: "propagation-identical-message",
    PropagationLabelTextError: "propagation-label-text-error",
    PropagationLabelColorError: "propagation-label-color-error",
    PropagationMessage: "propagation-message",
    PropagationResultPending: "propagation-result-pending",
    PropagationSuccessful: "propagation-successful",
    PropagationSuccessfulMessage: "propagation-successful-message",
    PullingGrip: "pulling-grip",
    Quantity: "quantity",
    QuestionMark: "question-mark",
    RABTitle: "rab-title",
    Racks: "racks",
    RackStartNumber: "rack-start-number",
    ReferenceFootNote: "reference-footnote",
    ReportDraft: "report-draft",
    ReportLenghtB0: "report-lenght-b0",
    ReportLenghtBx: "report-lenght-bx",
    ReportPageTitle: "report-page-title",
    ReportRepresentativeExample: "report-representative-example",
    ReportUserProvidedImagesNote: "report-user-provided-images-note",
    RepresentativeExample: "representative-example",
    Reset: "reset",
    ResetAll: "reset-all",
    ResetConnectorAssignment: "reset-connector-assignment",
    ResetConnectorAssignmentMessage: "reset-connector-assignment-message",
    ResetConnectorAssignmentOnEdit: "reset-connector-assignment-on-edit",
    ResetConnectorColors: "reset-connector-colors",
    RestrictedFiberCount: "restricted-fiber-count",
    RestrictedFiberCountOptions: "restricted-fiber-count-options",
    ReverseRackOrder: "reverse-rack-order",
    ReverseStaggering: "reverse-staggering",
    Revision: "revision",
    RevisionNumber: "revision-number",
    RoundBracket: "RoundBracket",
    Row: "row",
    Save: "save",
    SaveAs: "save-as",
    Saved: "saved",
    Saving: "saving",
    SaveNewVersion: "save-new-version",
    SaveNewVersionMessage: "save-new-version-message",
    SchemeTemplates: "scheme-templates",
    SCSimplex: "sc-simplex",
    SCSimplexViewOnly: "sc-simplex-view-only",
    SearchByNameOfDesigner: "search-by-name-of-designer",
    SecondaryUnitHelperText: "secondary-unit-helper-text",
    Section: "section",
    SeeError: "see-error",
    SelectAllConnectors: "select-all-connectors",
    SelectPolarity: "select-polarity",
    SelectTheFileFromYourDevice: "select-the-file-from-your-device",
    SelectionReport: "selection-report",
    SetAsDefault: "set-as-default",
    SetPolarity: "set-polarity",
    Settings: "settings",
    SetTolerances: "set-tolerances",
    Setup: "setup",
    ShowConnectorNumber: "show-connector-number",
    ShownOnFirstPage: "shown-first-page",
    ShowPullingGrip: "show-pulling-grip",
    ShowTolerances: "show-tolerances",
    Side: "side",
    SingleModeDrops: "single-mode-drops",
    SortAscending: "sort-ascending",
    SortBy: "sort-by",
    SortDescending: "sort-descending",
    Source: "source",
    SpecificAP: "specific-aps",
    SscModel: "ssc-model",
    SscModelError: "ssc-model-error",
    SScModelErrorSolution: "ssc-model-error-solution",
    StaggeringFootnote: "staggering-footnote",
    Stagger: "stagger",
    StaggerWithUnit: "stagger-with-unit",
    Summary: "summary",
    Table: "table",
    TapNumber: "tap-number",
    Taps: "taps",
    TapsNumber: "taps-number",
    TAPsConnectorTypes: "taps-connector-types",
    TAPConnectorsUpdatedSnackBarMessage: "tap-connectors-updated-snackbar-message",
    TetherAPSide: "tether-ap-side",
    TextfieldTemplate: "textfield-template",
    ThirdPartyOSSCopyright: "third-party-oss-copyright",
    TieButtonTie: "tie-button-tie",
    TieButtonUntie: "tie-button-untie",
    TieButtonDisabled: "tie-button-disabled",
    TimeoutMessage: "timeout-message",
    TimeoutTitle: "timeout-title",
    TJunction: "t-junction",
    Tolerances: "tolerances",
    ToleranceTable: "tolerance-table",
    TolerancesHelperText: "tolerances-helper-text",
    TotalAssemblyLength: "total-assembly-length",
    TotalFiberCount: "total-fiber-count",
    TriggerHousing: "trigger-housing",
    TriggerHousingColor: "trigger-housing-color",
    TrunkConnectorTable: "trunk-connectors-table",
    TrunkDrawing: "trunk-drawing",
    TrunkSide: "trunk-side",
    TwoPage: "two-page",
    Type: "type",
    UniformLength: "uniform-length",
    Units: "units",
    UnitsOfMeasurement: "units-of-measurement",
    UnitsSetup: "units-setup",
    Unknown: "unknown",
    UnknownCode: "unknown-code",
    UnknownTAPPosition: "unknown-tap-position",
    UnknownInsertionLoss: "unknown-insertion-loss",
    UOM: "uom",
    Upload: "upload",
    UploadImage: "upload-image",
    UploadAnImageToAppendToTheReport: "upload-an-image-to-append-to-the-report",
    UTC: "utc",
    ValueGreaterThan: "value-greater-than",
    ValueLesserThan: "value-lesser-than",
    ViewProduct: "view-product",
    ViewProductDialogMessage: "view-product-dialog-message",
    ViewProductErrorMessage: "view-product-error-message",
    Version: "version",
    Warnings: "warnings",
    WarningSnackbarMessageErrorsAndWarnings: "warning-snackbar-message-errors-and-warnings",
    WarningSnackbarMessageMultipleErrors: "warning-snackbar-message-multiple-errors",
    WarningSnackbarMessageMultipleWarnings: "warning-snackbar-message-multiple-warnings",
    WarningTitle: "warning-title",
    WithinGroups: "within-groups",
    WizardSubtitle: "wizard-subtitle",
    Yes: "yes",
    Zero: "zero",
    ZoomIn: "zoom-in",
    ZoomOut: "zoom-out",
    Zulu: "zulu",
} as const;

export type LocalizationKey = (typeof LocalizationKeys)[keyof typeof LocalizationKeys];

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBoundaries } from "../workspace/boundaries.hooks";
import { useLabelSchemes } from "../workspace/build/connector/label-scheme.hooks";
import { useWizardPresetLoad } from "../workspace/build/wizard-preset.hooks";
import { useProjectManager } from "../workspace/project-manager.hooks";
import { loggedInSelector } from "../../store/authentication/authentication.selectors";
import { setStatusState } from "../../store/overlay/header/status-icon/status-icon.reducer";
import { currentStatusSelector } from "../../store/overlay/header/status-icon/status-icon.selectors";
import { useSscConfigSession } from "../workspace/ssc.hooks";
import { buildIdSelector } from "../../store/workspace/build.selectors";
import { setResize } from "../../store/pixi/viewport/viewport.reducers";
import { useFiberMaps } from "../workspace/build/connector/polarity/fiber-map.hooks";

export const useWebWorkspace = () => {
    const loggedIn = useSelector(loggedInSelector);
    const currentStatus = useSelector(currentStatusSelector);
    const buildId = useSelector(buildIdSelector);
    const previousBuildIdRef = useRef(buildId);

    const { loadFiberMaps } = useFiberMaps();
    const { loadBoundaries } = useBoundaries();
    const { createConfigSession } = useSscConfigSession();
    const { loadOrgSchemes } = useLabelSchemes();
    const { loadWizardPresets } = useWizardPresetLoad();
    const { 
        loadProjectManager, 
        loadRecentProject 
    } = useProjectManager();

    const dispatch = useDispatch();

    useEffect(() => {
        if (loggedIn) {
            dispatch(setStatusState("initial load"));
        }
    }, [loggedIn, dispatch]);

    useEffect(() => {
        if (currentStatus === "initial load") {
            loadFiberMaps();
            loadBoundaries();
            createConfigSession();
        }
        if (currentStatus === "loading") {
            loadOrgSchemes();
            loadWizardPresets();
            loadProjectManager();
            loadRecentProject();
        }
        // eslint-disable-next-line
    }, [currentStatus]);

    useEffect(() => {
        if (currentStatus === "saved" && previousBuildIdRef.current !== buildId) {
            previousBuildIdRef.current = buildId;
            dispatch(setResize({ update: true, value: "fit-to-page" }));
        }
    }, [currentStatus, buildId, dispatch]);

    return { currentStatus };
};

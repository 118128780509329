import { useSelector, useDispatch } from "react-redux";
import { useBuildInfo } from "../../../../components/overlay/project-drawer/project-manager-row/build-info/build-info.hooks";
import { useCallback } from "react";
import { setDrawerDisplay } from "../../../../store/overlay/project/project.reducers";
import { currentBuildSelector } from "../../../../store/workspace/root.selectors";
import { disabledUISelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";
import { showConnectorAssignmentSelector, showFiberMappingSelector } from "../../../../store/overlay/overlay.selectors";
import { setShowConnectorAssignment, setShowFiberMapping } from "../../../../store/overlay/overlay.reducers";
import { warningsCardDisplaySelector } from "../../../../store/overlay/header/warnings/warnings.selectors";
import { setWarningsCardDisplay } from "../../../../store/overlay/header/warnings/warnings.reducer";
import { unitsOfMeasureContainerDisplaySelector } from "../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { setUnitsOfMeasureContainerDisplay } from "../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.reducers";
import { isAnonymousUserSelector } from "../../../../store/authentication/authentication.selectors";
import {
    currentBuildFinalizedSelector,
    currentBuildPartNumberSelector,
} from "../../../../store/workspace/build.selectors";
import { IconButtonProps } from "@corning-ctcm/silica-react";

export const useProjectButton = () => {
    const connectorAssignmentDisplayed = useSelector(showConnectorAssignmentSelector);
    const fiberMappingDisplayed = useSelector(showFiberMappingSelector);
    const warningsDisplayed = useSelector(warningsCardDisplaySelector);
    const unitsOfMeasureContainerDisplayed = useSelector(unitsOfMeasureContainerDisplaySelector);
    const currentBuild = useSelector(currentBuildSelector);
    const disabledUI = useSelector(disabledUISelector);
    const isAnonymous = useSelector(isAnonymousUserSelector);
    const partNumber = useSelector(currentBuildPartNumberSelector);
    const buildFinalized = useSelector(currentBuildFinalizedSelector);
    const disabled = disabledUI || isAnonymous;
    const { name } = useBuildInfo(currentBuild!.id!);
    const dispatch = useDispatch();

    const closeOtherDialogs = useCallback(() => {
        if (connectorAssignmentDisplayed) {
            dispatch(setShowConnectorAssignment(false));
        }

        if (fiberMappingDisplayed) {
            dispatch(setShowFiberMapping(false));
        }

        if (warningsDisplayed) {
            dispatch(setWarningsCardDisplay(false));
        }

        if (unitsOfMeasureContainerDisplayed) {
            dispatch(setUnitsOfMeasureContainerDisplay(false));
        }
    }, [
        connectorAssignmentDisplayed,
        fiberMappingDisplayed,
        warningsDisplayed,
        unitsOfMeasureContainerDisplayed,
        dispatch,
    ]);

    const openDrawer = useCallback(async () => {
        dispatch(setDrawerDisplay!(true));
        closeOtherDialogs();
    }, [closeOtherDialogs, dispatch]);

    const lockIcon: Omit<IconButtonProps, "icon"> = {
        className: "lock-icon",
        palette: "primary",
        mode: "main",
        placement: "bottom",
        disabled: true,
    };

    const menuProps: Omit<IconButtonProps, "icon"> = {
        id: "project-menu-icon",
        palette: "primary",
        mode: "main",
        disabled,
        title: "Menu",
        placement: "bottom",
        onClick: openDrawer,
    };

    return {
        name,
        partNumber,
        lock: {
            display: buildFinalized,
            icon: lockIcon,
        },
        menuProps,
        isAnonymous,
    };
};

import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { buildIdSelector, currentBuildFinalizedSelector } from "../../../../store/workspace/build.selectors";
import { BuildService } from "../../../../services/build.service";
import {
    PrimaryUnit,
    PrimaryUnits,
    Unit,
    getSecondaryUnit,
    getUnits,
    getUnitsName,
    toCapitalize,
} from "../../../../models/overlay/header/units-of-measure";
import {
    setUnitsOfMeasureContainerDisplay,
    setUnit,
    setPrimaryUnit,
} from "../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.reducers";
import {
    unitsOfMeasureContainerPrimaryUnitSelector,
    unitsOfMeasureContainerSelector,
    unitsOfMeasureContainerUnitSelector,
} from "../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { IconButtonProps, SelectProps } from "@corning-ctcm/silica-react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { setCurrentBuild } from "../../../../store/workspace/build/build.reducers";
import { currentBuildSelector } from "../../../../store/workspace/root.selectors";

export const useUnitsOfMeasureContainer = () => {
    const { t } = useTranslation();
    const { display } = useSelector(unitsOfMeasureContainerSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const primaryUnit = useSelector(unitsOfMeasureContainerPrimaryUnitSelector);
    const secondaryUnit = useSelector(unitsOfMeasureContainerUnitSelector);
    const buildFinalized = useSelector(currentBuildFinalizedSelector);
    const buildId = useSelector(buildIdSelector);
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        dispatch(setUnitsOfMeasureContainerDisplay(false));
    }, [dispatch]);

    const onPrimaryUnitChange = useCallback(
        async (event: React.ChangeEvent<any>) => {
            if (!currentBuild) return;

            const primary = event.target.value as PrimaryUnit;
            const secondary = getSecondaryUnit(primaryUnit, secondaryUnit);
            await new BuildService().updatePrimaryUnit(buildId, primary, secondary);
            dispatch(setCurrentBuild({ ...currentBuild, primaryUnit: primary, unit: secondary }));
            dispatch(setPrimaryUnit(primary));
            dispatch(setUnit(secondary));
        },
        [buildId, currentBuild, secondaryUnit, primaryUnit, dispatch]
    );

    const primaryUnitProps: SelectProps = {
        palette: "primary",
        label: t(LocalizationKeys.PrimaryMeasurementSystem),
        value: primaryUnit,
        units: "",
        helperText: "",
        disabled: buildFinalized,
        onChange: onPrimaryUnitChange,
    };

    const primaryUnitOptions = Object.values(PrimaryUnits).map((u) => ({ key: u, value: u, name: toCapitalize(u) }));

    const onSecondaryUnitChange = useCallback(
        async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (!currentBuild) return;

            const unit = event.target.value as Unit;
            await new BuildService().updateUnit(buildId, unit);
            dispatch(setUnit(unit));
            dispatch(setCurrentBuild({ ...currentBuild, unit }));
        },
        [dispatch, buildId, currentBuild]
    );

    const secondaryUnitProps: SelectProps = {
        palette: "primary",
        label: t(LocalizationKeys.UnitsOfMeasurement),
        value: secondaryUnit,
        units: "",
        helperText: t(LocalizationKeys.SecondaryUnitHelperText),
        disabled: buildFinalized,
        onChange: onSecondaryUnitChange,
    };

    const secondaryUnitOptions = Object.values(getUnits(primaryUnit)).map((u) => ({
        key: u,
        value: u,
        name: `${getUnitsName(u, true)} (${getUnitsName(getSecondaryUnit(primaryUnit, u), true)})`,
    }));

    const closeProps: Omit<IconButtonProps, "icon"> = {
        id: "units-close-icon",
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.Close),
        placement: "bottom",
        onClick: onClose,
    };

    return {
        display,
        primary: {
            props: primaryUnitProps,
            options: primaryUnitOptions,
        },
        secondary: {
            props: secondaryUnitProps,
            options: secondaryUnitOptions,
        },
        closeProps,
    };
};

import { CheckboxProps, TooltipPlacement, TextFieldProps, IconButtonProps } from "@corning-ctcm/silica-react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import { useCallback, useReducer } from "react";
import { IColor, IColorDialogProps } from "../../../../../models/ui/dialog/color-dialog";
import { DialogReducer, showDialog } from "../../../../../store/workspace/dialog/dialog.reducer";
import { initialDialogState } from "../../../../../store/workspace/dialog/dialog";
import { IMeshColorProps, IMeshProps, MeshColors } from "../../../../../models/overlay/wizard/setup/mesh-properties";

export const useMeshProperties = ({ mesh, onCheckboxChange, offset, color, disabled }: IMeshProps) => {
    const { meshColor, colorDialogProps } = useMeshColor(color);
    const { t } = useTranslation();

    const checkbox: CheckboxProps = {
        id: "mesh-checkbox",
        palette: "primary",
        placement: "end",
        label: t(LocalizationKeys.AddMesh),
        value: mesh,
        checked: mesh,
        disabled,
        onChange: onCheckboxChange,
    };

    const meshOffsetField: TextFieldProps = {
        id: "mesh-offset-field",
        type: "number",
        label: t(LocalizationKeys.MeshOffset),
        value: offset.value,
        palette: "primary",
        helperText: offset.helperText,
        error: !offset.isValid,
        disabled,
        onChange: offset.onChange,
        units: offset.units,
        inputRef: offset.inputRef
    };

    return {
        mesh: {
            checkbox,
            offset: meshOffsetField,
            color: meshColor,
        },
        colorDialogProps,
    };
};

const useMeshColor = ({ value, disabled, onChange }: IMeshColorProps) => {
    const meshColor = MeshColors.find((c) => c.name === value);
    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);
    const { t } = useTranslation();

    const openColorDialog = useCallback(() => {
        dispatch(showDialog(true));
    }, []);

    const meshColorField: TextFieldProps = {
        id: "mesh-color-field",
        className: "color-picker",
        label: t(LocalizationKeys.MeshColor),
        value,
        palette: "primary",
        disabled,
    };

    const meshColorIcon: Omit<IconButtonProps, "icon"> = {
        id: "mesh-color-icon",
        className: "palette-icon",
        palette: "primary",
        mode: "main",
        placement: "bottom" as TooltipPlacement,
        disabled,
        onClick: openColorDialog,
    };

    const meshColorProps = {
        field: meshColorField,
        icon: meshColorIcon,
    };

    const onColorButtonClick = useCallback(
        (color: IColor) => {
            onChange(color);
            dispatch(showDialog(false));
        },
        [onChange]
    );

    const colorDialogProps: IColorDialogProps = {
        context: { state, dispatch },
        className: "mesh",
        currentColor: meshColor,
        colors: MeshColors,
        preventOutsideDismiss: true,
        displayFooter: false,
        onColorButtonClick,
    };

    return { meshColor: meshColorProps, colorDialogProps };
};

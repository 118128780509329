export interface IEditToolbarState {
    display: boolean;
    selection: ISelectionData;
}

export interface ISelectionData {
    hovered: number;
    selected: number;
    side?: string;
}

export const initialState: IEditToolbarState = {
    display: false,
    selection: {
        hovered: -1,
        selected: -1,
    },
};

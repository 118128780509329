import { TFunction } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../locales/keys";
import { useCheckBoxInput } from "../../../../ui/checkbox/checkbox.hooks";
import { printSettingsSelector } from "../../../../../store/overlay/overlay.selectors";
import {
    IDiagramSection,
    initialReportSettings,
} from "../../../../../models/overlay/reports/report-settings/report-settings";
import { IDiagramSectionField } from "../../../../../models/overlay/reports/report-settings/diagram-section";
import { connectorAssignmentsSelector } from "../../../../../store/workspace/build.selectors";

function useDiagramSectionField(name: string, diagram: IDiagramSection, translate: TFunction): IDiagramSectionField {
    const { include, disabled } = diagram;
    const checkbox = useCheckBoxInput(`${name}-checkbox`, translate(name), include, disabled);
    return {
        checkbox: checkbox,
    };
}

export const useDiagramSectionFields = () => {
    const printSettings = useSelector(printSettingsSelector);
    const connectorAssignments = useSelector(connectorAssignmentsSelector);
    const { showPrintDialog } = printSettings;
    const enableFiberMapsOption = connectorAssignments.length > 0;

    const { t } = useTranslation();

    const [cableOverview, setCableOverView] = useState(initialReportSettings.cableOverview!);
    const [tolerances, setTolerances] = useState(initialReportSettings.tolerances!);
    const [buildPlan, setBuildPlan] = useState(initialReportSettings.buildPlan!);
    const [connectorLabel, setConnectorLabel] = useState(initialReportSettings.connectorLabel!);
    const [fiberMap, setFiberMap] = useState(initialReportSettings.fiberMap!);
    const [validDiagramsSection, setValidDiagramsSection] = useState(true);

    const cableOverViewField = useDiagramSectionField(LocalizationKeys.CableOverview, cableOverview!, t);
    const tolerancesField = useDiagramSectionField(LocalizationKeys.ToleranceTable, tolerances!, t);
    const connectorLabelField = useDiagramSectionField(LocalizationKeys.ConnectorAndLabelSummary, connectorLabel!, t);
    const fiberMapField = useDiagramSectionField(LocalizationKeys.FiberMap, fiberMap!, t);
    const buildPlanField = useDiagramSectionField(LocalizationKeys.BuildPlan, buildPlan!, t);

    useEffect(() => {
        const checked =
            connectorLabelField.checkbox.checked ||
            buildPlanField.checkbox.checked ||
            fiberMapField.checkbox.checked ||
            cableOverViewField.checkbox.checked ||
            tolerancesField.checkbox.checked;
        if (validDiagramsSection !== checked) {
            setValidDiagramsSection(checked);
        }
    }, [
        cableOverViewField.checkbox.checked,
        tolerancesField.checkbox.checked,
        connectorLabelField.checkbox.checked,
        fiberMapField.checkbox.checked,
        buildPlanField.checkbox.checked,
        validDiagramsSection,
    ]);

    useEffect(() => {
        if (showPrintDialog) {
            let include = fiberMap.include;
            let disabled = fiberMap.disabled;
            if (enableFiberMapsOption) {
                disabled = false;
            } else {
                disabled = true;
                include = false;
            }

            if (include !== fiberMap.include || disabled !== fiberMap.disabled) {
                setFiberMap({
                    include,
                    disabled,
                });
            }
        }
    }, [showPrintDialog, enableFiberMapsOption, fiberMap]);

    useEffect(() => {
        if (showPrintDialog && !fiberMap.disabled && fiberMap.include !== fiberMapField.checkbox.checked) {
            setFiberMap({
                include: fiberMapField.checkbox.checked,
                disabled: fiberMap.disabled,
            });
        }
    }, [fiberMap, fiberMapField.checkbox.checked, showPrintDialog]);

    useEffect(() => {
        if (showPrintDialog) {
            setCableOverView({
                include: cableOverViewField.checkbox.checked,
                disabled: false,
            });
        }
    }, [showPrintDialog, cableOverViewField.checkbox.checked]);

    useEffect(() => {
        if (showPrintDialog) {
            setTolerances({
                include: tolerancesField.checkbox.checked,
                disabled: false,
            });
        }
    }, [showPrintDialog, tolerancesField.checkbox.checked]);

    useEffect(() => {
        if (showPrintDialog) {
            setConnectorLabel({
                include: connectorLabelField.checkbox.checked,
                disabled: false,
            });
        }
    }, [showPrintDialog, connectorLabelField.checkbox.checked]);

    useEffect(() => {
        if (showPrintDialog) {
            if (connectorLabel.include && connectorLabel.disabled) {
                setConnectorLabel({
                    include: false,
                    disabled: true,
                });
            }
        }
    }, [showPrintDialog, connectorLabel]);

    useEffect(() => {
        if (showPrintDialog) {
            setBuildPlan({
                include: buildPlanField.checkbox.checked,
                disabled: false,
            });
        }
    }, [buildPlanField.checkbox.checked, showPrintDialog]);

    return {
        validDiagramsSection,
        diagramSectionFields: [connectorLabelField, fiberMapField, buildPlanField, cableOverViewField, tolerancesField],
        fieldValues: { buildPlan, connectorLabel, fiberMap, cableOverview, tolerances },
    };
};

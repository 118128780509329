import { IGenericSnackbarProps } from "../../../models/ui/generic-snackbar";
import { useGenericSnackbar } from "./generic-snackbar.hooks";
import { Alert, TextButton } from "@corning-ctcm/silica-react";
import { AlertPalette } from "@corning-ctcm/silica-react/build/components/snackbar/snackbar";

import "../../../css/ui/generic-snackbar.scss";

export const GenericSnackbar = (props: IGenericSnackbarProps) => {
    const { type, snackBarButtonProps, snackbarProps } = useGenericSnackbar(props);

    return (
        <div className="snackbar-container">
            <Alert
                palette={type as AlertPalette}
                variant="filled"
                snackbarProps={snackbarProps}
                action={
                    <div className="snackbar-actions">
                        {snackBarButtonProps.map((b, i) => (
                            <TextButton key={i} palette={type} {...b} />
                        ))}
                    </div>
                }
            />
        </div>
    );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Boundaries, initialRABBoundariesState, IRABBoundariesState } from "./boundaries";

const showBoundariesSettingsReducer = (state: IRABBoundariesState, action: PayloadAction<boolean>) => {
    state.displaySettings = action.payload;
};

const addBoundariesOptionsReducer = (state: IRABBoundariesState, action: PayloadAction<Boundaries>) => {
    const boundaries = action.payload;
    const index = state.options.findIndex((o) => o.id === boundaries.id);
    if (index === -1) {
        state.options.push(boundaries);
    } else {
        state.options[index] = boundaries;
    }
};

const removeBoundariesOptionsReducer = (state: IRABBoundariesState, action: PayloadAction<number>) => {
    const id = action.payload;
    state.options = state.options.filter((o) => o.id !== id);
};

const setBoundariesOptionsReducer = (state: IRABBoundariesState, action: PayloadAction<Boundaries[]>) => {
    state.options = action.payload;
};

const boundariesSlice = createSlice({
    initialState: initialRABBoundariesState,
    name: "boundaries",
    reducers: {
        showBoundariesSettings: showBoundariesSettingsReducer,
        addBoundariesOptions: addBoundariesOptionsReducer,
        removeBoundariesOptions: removeBoundariesOptionsReducer,
        setBoundariesOptions: setBoundariesOptionsReducer,
    },
});

export const BoundariesReducer = boundariesSlice.reducer;
export const { showBoundariesSettings, addBoundariesOptions, removeBoundariesOptions, setBoundariesOptions } =
    boundariesSlice.actions;

import React from "react";
import { IColorDialogProps } from "../../../../models/ui/dialog/color-dialog";
import { useColorDialog } from "./color-dialog.hooks";
import { DialogHeader } from "../dialog-header/dialog-header";
import { ColorButton } from "../../color-button";
import { DialogContext } from "../../../../store/workspace/dialog/dialog.reducer";
import { DialogFooter } from "../dialog-footer/dialog-footer";

import "../../../../css/ui/dialog/color-dialog.scss";

export const ColorDialog: React.FC<IColorDialogProps> = (props) => {
    const {
        context,
        open,
        headerProps,
        footerProps,
        colors,
        currentColor,
        onColorButtonClick,
        displayFooter,
        className,
    } = useColorDialog(props);
    if (!open) {
        return null;
    }
    return (
        <DialogContext.Provider value={context}>
            <div id={`${className}-colors`} className={`color-dialog ${className}`}>
                <DialogHeader {...headerProps} />
                <div className="color-palette">
                    {colors.map((c) => (
                        <ColorButton
                            key={`color-${c.name}`}
                            color={c}
                            selected={currentColor !== undefined && c.name === currentColor.name}
                            onClick={onColorButtonClick}
                        />
                    ))}
                </div>
                {displayFooter ? <DialogFooter {...footerProps} /> : null}
            </div>
        </DialogContext.Provider>
    );
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getPolarityConfigString } from "../../../../helpers/workspace/polarity.helper";
import { LocalizationKeys } from "../../../../locales/keys";
import { IPolarityButton } from "../../../../models/overlay/polarity/assign-polarity";
import { IEditPolarityDialogProps } from "../../../../models/overlay/polarity/edit-polarity-dialog";
import { POLARITY_MAX_CHARACTER } from "../../../../models/overlay/polarity/fiber-mapping/fiber-mapping-save";
import { IPolarityAssignmentRowProps } from "../../../../models/overlay/polarity/polarity-assignment-row";
import { IGenericDialogProps } from "../../../../models/ui/dialog/generic-dialog";
import { userIdSelector } from "../../../../store/authentication/authentication.selectors";
import { getBuildOwnerByUserId } from "../../../../store/workspace/build/build";
import {
    BuildPolarity,
    CUSTOM_MAP_KEY,
    PolarityConfig,
    PolarityMap,
    findPolarityConfig,
    getPolarityMapPolarityType,
    initialPolarityMap,
    matchConnectorType,
} from "../../../../store/workspace/build/connector/polarity/polarity";
import { DialogTypes } from "../../../../store/workspace/dialog/dialog";
import { buildsSelector } from "../../../../store/workspace/root.selectors";
import { sscBuildPolaritySelector, sscDefaultBuildPolaritiesSelector } from "../../../../store/workspace/ssc/ssc.selectors";

export const useAssignPolarity = () => {
    const configs = useSelector(sscDefaultBuildPolaritiesSelector);

    const { polarityConfigs, selectedConfig, polarityButtons, appliedMap } = usePolarityConfigButtons(configs || []);
    const { polarityAssignmentRowProps, editPolarityDialogProps, deletePolarityDialogProps, selectedMap } =
        usePolarityMapList(selectedConfig, appliedMap);

    return {
        polarityAssignmentRowProps,
        editPolarityDialogProps,
        deletePolarityDialogProps,
        polarityConfigs,
        selectedConfig,
        selectedMap,
        polarityButtons,
    };
};

export const usePolarityConfigButtons = (appliedConfigs: BuildPolarity[]) => {
    const polarityConfigs = useSelector(sscBuildPolaritySelector);
    const [selectedConfig, setSelectedConfig] = useState(polarityConfigs[0]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [appliedMap, setAppliedMap] = useState<PolarityMap>();
    const { t } = useTranslation();

    useEffect(() => {
        if (selectedConfig !== polarityConfigs[currentIndex]) {
            setSelectedConfig(polarityConfigs[currentIndex]);
        }
    }, [currentIndex, selectedConfig, polarityConfigs]);

    const onButtonClick = useCallback((pc: PolarityConfig, index: number) => {
        setSelectedConfig(pc);
        setCurrentIndex(index);
    }, []);

    const polarityButtons: IPolarityButton[] = useMemo(() => {
        return polarityConfigs.map((pc, index) => {
            const applied = findPolarityConfig<BuildPolarity>(pc.from, pc.to, appliedConfigs);
            const onClick = () => {
                onButtonClick(pc, index);
            };
            let className = "polarity-button";
            let selected = false;
            if (applied) {
                className += "-applied";
            } else if (matchConnectorType(selectedConfig.from, selectedConfig.to, pc.from, pc.to)) {
                className += "-selected";
                selected = true;
            }
            const description = getPolarityConfigString(pc.from!, pc.to!, t);
            const id = `${description?.replaceAll(" ", "-").toLowerCase()}-button`;
            return { ...pc, id, onClick, applied, className, description, selected };
        });
    }, [polarityConfigs, appliedConfigs, selectedConfig, onButtonClick, t]);

    useEffect(() => {
        const appliedConfig = findPolarityConfig<BuildPolarity>(selectedConfig.from, selectedConfig.to, appliedConfigs);
        if (appliedConfig) {
            setAppliedMap(appliedConfig.polarityMap);
        } else {
            setAppliedMap(undefined);
        }
    }, [selectedConfig, appliedConfigs]);

    return { polarityButtons, selectedConfig, polarityConfigs, appliedMap };
};

export const usePolarityMapList = (selectedConfig: PolarityConfig, appliedMap?: PolarityMap) => {
    const userId = useSelector(userIdSelector);
    const initialPolarityMaps = selectedConfig && selectedConfig.polarityMaps ? selectedConfig.polarityMaps : [];
    const [polarityMaps, setPolarityMaps] = useState(initialPolarityMaps);
    const [selectedMap, setSelectedMap] = useState(initialPolarityMap);

    const { editPolarityDialogProps, onClick: onEditPolarity } = useEditPolarity(polarityMaps);
    const { deletePolarityDialogProps } = useDeletePolarity(polarityMaps);

    useEffect(() => {
        if (selectedConfig && selectedConfig.polarityMaps) {
            const index = selectedConfig.polarityMaps.findIndex((c) => c === selectedMap);
            if (index === -1) {
                setSelectedMap(initialPolarityMap);
            }
            setPolarityMaps(selectedConfig.polarityMaps || []);
        } else {
            setSelectedMap(initialPolarityMap);
            setPolarityMaps([]);
        }
    }, [selectedConfig, selectedMap]);

    const onRowClick = useCallback(
        (polarityMap: PolarityMap) => {
            if (polarityMap === selectedMap) {
                setSelectedMap(initialPolarityMap);
            } else {
                setSelectedMap(polarityMap);
            }
        },
        [selectedMap]
    );

    const builds = useSelector(buildsSelector);
    const polarityAssignmentRowProps: IPolarityAssignmentRowProps[] = useMemo(() => {
        return polarityMaps.map((pm) => {
            const owner = pm.userId ? getBuildOwnerByUserId(pm.userId, builds!) : "";
            const canDelete = pm.userId && userId ? pm.userId === userId : false;
            const editable = pm.key ? pm.key.toString() === CUSTOM_MAP_KEY.toString() : false;
            const applied = appliedMap && getPolarityMapPolarityType(appliedMap) === getPolarityMapPolarityType(pm);

            const selected = editable && selectedMap.customKey === pm.customKey;
            const formatedDate = pm.versionDate && pm.versionDate.length ? formatDate(pm.versionDate) : "Standard";
            const creationInfo = owner.length > 0 ? `${owner}, ${formatedDate}` : formatedDate;

            return {
                key: (pm.key?.toString() === CUSTOM_MAP_KEY.toString() ? pm.customKey : pm.key?.toString()) ?? "",
                data: { ...pm, owner, applied, selected, editable, canDelete, creationInfo },
                onRowClick: () => onRowClick(pm),
                onEditPolarity,
                onDeletePolarity: () => {},
            };
        });
    }, [userId, builds, polarityMaps, selectedMap, appliedMap, onRowClick, onEditPolarity]);

    return { polarityAssignmentRowProps, editPolarityDialogProps, deletePolarityDialogProps, selectedMap };
};

export const usePolarityMap = (configMapping: PolarityMap[], selectedMapKey?: number) => {
    const [selectedMap, setSelectedMap] = useState(configMapping.find((c) => c.key === selectedMapKey));

    useEffect(() => {
        setSelectedMap(configMapping.find((c) => c.key === selectedMapKey));
    }, [selectedMapKey, configMapping]);

    return { selectedMap };
};

export const useEditPolarity = (configMapping: PolarityMap[]) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [polarityName, setPolarityName] = useState("");

    const onClick = useCallback(
        (id: number) => {
            const index = configMapping.findIndex((c) => c.id === id);
            if (index !== -1) {
                setPolarityName(configMapping[index].description!);
            }
            setOpen(true);
        },
        [configMapping]
    );

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    const editPolarityDialogProps: IEditPolarityDialogProps = useMemo(() => {
        return {
            id: "edit-polarity-name",
            saveProps: {
                label: t(LocalizationKeys.Save),
                onClick: () => {}
            },
            cancelProps: {
                label: t(LocalizationKeys.Cancel),
                onClick: onClose,
            },
            title: t(LocalizationKeys.EditPolarityName),
            inputFieldLabel: t(LocalizationKeys.PolarityNewName),
            maxCharacterCount: POLARITY_MAX_CHARACTER,
            value: polarityName,
            type: DialogTypes.Confirm,
            props: {
                open,
                onClose,
            },
        };
    }, [polarityName, open, onClose, t]);

    return { editPolarityDialogProps, onClick };
};

export const useDeletePolarity = (configMapping: PolarityMap[]) => {
    const { t } = useTranslation();
    const [error, setError] = useState(false);
    const [display, setDisplay] = useState(false);

    const onClose = useCallback(() => {
        setDisplay(false);
        setError(false);
    }, []);

    const deletePolarityDialogProps: IGenericDialogProps = useMemo(() => {
        return {
            id: "delete-custom-polarity",
            display,
            title: t(LocalizationKeys.DeleteCustomPolarity),
            message: "",
            onClose: onClose,
            confirmDisabled: error,
            confirmText: t(LocalizationKeys.Delete),
            closable: true,
            critical: true,
        };
    }, [display, error, onClose, t]);

    return { deletePolarityDialogProps };
};

export const formatDate = (strDate: string) => {
    const date = new Date(strDate);
    const formatedTime = date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", second: "2-digit" });
    const formatedDate = date.toLocaleDateString();

    return `${formatedDate} ${formatedTime}`;
};

import { Action } from "@reduxjs/toolkit";
import { createContext, Dispatch } from "react";

export interface IProjectDrawerState {
    search: string;
    reachedLastPage: boolean;
    loadingMore: boolean;
    reloading: boolean;
}

export const initialProjectDrawerState: IProjectDrawerState = {
    search: "",
    reachedLastPage: false,
    loadingMore: false,
    reloading: false,
};

export interface IProjectDrawerContext {
    state: IProjectDrawerState;
    dispatch: Dispatch<Action>;
}

export const initialProjectDrawerContext: IProjectDrawerContext = {
    state: initialProjectDrawerState,
    dispatch: () => {},
};

export const ProjectDrawerContext = createContext<IProjectDrawerContext>(initialProjectDrawerContext);

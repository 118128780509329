import { Dispatch } from "react";
import { PayloadAction, UnknownAction, createSlice } from "@reduxjs/toolkit";
import { IOverlayState, initialOverlayState } from "./overlay";
import { setStatusState } from "./header/status-icon/status-icon.reducer";
import { WorkspaceStatus } from "./header/status-icon/status-icon";
import { setViewportContext } from "../pixi/viewport/viewport.reducers";
import { ViewportStatus } from "../pixi/viewport/viewport";

const setDisplayPrintAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.printSettings.showPrintDialog = action.payload;
};

const setShowThirdPartyCopyrightAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showThirdPartyCopyright = action.payload;
};

const setShowAboutApplicationAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showAboutApplication = action.payload;
};

const setShowConnectorReportAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showConnectorReport = action.payload;
};

const setShowLabelSchemeAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showLabelScheme = action.payload;
};

const setShowTriggerManagementAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showTriggerManagement = action.payload;
};

const setShowPolarityAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showPolarity = action.payload;
};

const setDisablePolarityAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.disablePolarity = action.payload;
};

const setShowCablePresetSnackbarAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showCablePresetSnackbar = action.payload;
};

const setShowConnectorAssignmentAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showConnectorAssignment = action.payload;
};

const setShowFiberMappingAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showFiberMapping = action.payload;
};

const setShowViewProductAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showViewProduct = action.payload;
};

const setShowMoreMenuAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showMoreMenu = action.payload;
};

const setShowIdleAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showIdle = action.payload;
};

const setShowTimeoutAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showTimeout = action.payload;
};

const setShowAnonymousUserWarningAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showAnonymousUserWarning = action.payload;
};

const setShowShellEditAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showShellEdit = action.payload;
};

const setShowAssemblyTypeEditAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showAssemblyTypeEdit = action.payload;
};

const setShowEditTolerancesAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showEditTolerances = action.payload;
};

const setShowCollapseOptionsAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showCollapseOptions = action.payload;
};

const setShowSettingsAction = (state: IOverlayState, action: PayloadAction<boolean>) => {
    state.showSettings = action.payload;
};

const overlaySlice = createSlice({
    name: "overlay",
    initialState: initialOverlayState,
    reducers: {
        setPrintSettingsDisplay: setDisplayPrintAction,
        setShowThirdPartyCopyright: setShowThirdPartyCopyrightAction,
        setShowAboutApplication: setShowAboutApplicationAction,
        setShowConnectorReport: setShowConnectorReportAction,
        setShowLabelScheme: setShowLabelSchemeAction,
        showTriggerManagement: setShowTriggerManagementAction,
        setShowPolarity: setShowPolarityAction,
        setDisablePolarity: setDisablePolarityAction,
        setShowCablePresetSnackbar: setShowCablePresetSnackbarAction,
        setShowConnectorAssignment: setShowConnectorAssignmentAction,
        setShowFiberMapping: setShowFiberMappingAction,
        setShowViewProduct: setShowViewProductAction,
        setShowMoreMenu: setShowMoreMenuAction,
        setShowIdle: setShowIdleAction,
        setShowTimeout: setShowTimeoutAction,
        setShowAnonymousUserWarning: setShowAnonymousUserWarningAction,
        setShowShellEdit: setShowShellEditAction,
        setShowAssemblyTypeEdit: setShowAssemblyTypeEditAction,
        setShowEditTolerances: setShowEditTolerancesAction,
        setShowCollapseOptions: setShowCollapseOptionsAction,
        setShowSettings: setShowSettingsAction,
    },
});

export const {
    setPrintSettingsDisplay,
    setShowThirdPartyCopyright,
    setShowAboutApplication,
    setShowConnectorReport,
    setShowLabelScheme,
    showTriggerManagement,
    setShowPolarity,
    setDisablePolarity,
    setShowCablePresetSnackbar,
    setShowConnectorAssignment,
    setShowFiberMapping,
    setShowViewProduct,
    setShowAnonymousUserWarning,
    setShowMoreMenu,
    setShowIdle,
    setShowTimeout,
    setShowShellEdit,
    setShowAssemblyTypeEdit,
    setShowEditTolerances,
    setShowCollapseOptions,
    setShowSettings,
} = overlaySlice.actions;

export const OverlayReducer = overlaySlice.reducer;

export const setShowPrintDialog = (show: boolean) => {
    return (dispatch: Dispatch<UnknownAction>): void => {
        dispatch(setStatusState(show ? WorkspaceStatus.Reporting : WorkspaceStatus.Ready));
        dispatch(setViewportContext(show ? ViewportStatus.Inactive : ViewportStatus.Active));
        dispatch(setPrintSettingsDisplay(show));
    };
};

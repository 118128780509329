import React from "react";
import { useHorizontalTrunk } from "./horizontal-trunk.hooks";
import { Graphics } from "@pixi/react";
import { SelectFilter } from "../../../decorators/bounds/select-filter";

export const HorizontalTrunk: React.FC = () => {
    const { filters, trunkProps } = useHorizontalTrunk();
    return (
        <SelectFilter adjust={filters.hover} glow={filters.selection}>
            <Graphics {...trunkProps} />
        </SelectFilter>
    );
};

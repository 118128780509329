import { createContext, Dispatch } from "react";
import { Action } from "redux";

export interface ILabelCustomizationState {
    rackNumber: number;
    reverseOrder: boolean;
    allowPasting: boolean;
    pastedContent: string;
}

export const initialLabelCustomizationState: ILabelCustomizationState = {
    rackNumber: 1,
    reverseOrder: false,
    allowPasting: false,
    pastedContent: "",
};

export interface ILabelCustomizationContext {
    state: ILabelCustomizationState;
    dispatch: Dispatch<Action>;
}

export const initialLabelCustomizationContext: ILabelCustomizationContext = {
    state: initialLabelCustomizationState,
    dispatch: () => {},
};

export const LabelCustomizationContext = createContext(initialLabelCustomizationContext);

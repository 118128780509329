import React, { PropsWithChildren } from "react";
import { Divider, Drawer } from "@mui/material";
import { DrawerProps } from "../../../../models/ui/drawer/drawer";
import { DrawerHeader } from "../drawer-header";
import { useDrawer } from "./modal-drawer.hooks";
import { DrawerHeaderProps } from "../../../../models/ui/drawer/drawer-header";

import "../../../../css/ui/drawer/modal-drawer.scss";

export const ModalDrawer: React.FC<PropsWithChildren<DrawerProps>> = (props) => {
    const { display } = useDrawer(props);
    const { id, className, children, onClose } = props;
    return (
        <Drawer id={id} open={display} anchor={"left"} onClose={onClose}>
            <div className={className ? `${props.className} modal-drawer` : `modal-drawer`}>
                <DrawerHeader {...(props as DrawerHeaderProps)} />
                <Divider />
                <div className="children-container">{children}</div>
            </div>
        </Drawer>
    );
};

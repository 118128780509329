import React from "react";
import { ClickAwayListener } from "@mui/material";
import { useMoreMenuContainer } from "./more-menu-container.hooks";
import { MenuItem } from "./menu-item";

import "../../../../css/overlay/header/more-menu-container.scss";

export const MoreMenuContainer = () => {
    const { display, items, onClose } = useMoreMenuContainer();

    return !display ? null : (
        <ClickAwayListener onClickAway={onClose}>
            <div id="more-menu" className="more-menu-container toggle-pointer-events">
                <ul>
                    {items.map((i) => (
                        <MenuItem key={i.id} {...i} />
                    ))}
                </ul>
            </div>
        </ClickAwayListener>
    );
};

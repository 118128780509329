import { IconButton, Menu, MenuItem, TextButton } from "@corning-ctcm/silica-react";
import { UnfoldMore, ZoomIn } from "@mui/icons-material";
import { Fade } from "@mui/material";
import { usePageResize } from "./page-resize-tool.hooks";

export const PageResizeTool = () => {
    const { resize, fit, anchorElement, onMenuClose, options, onOptionClick } = usePageResize();

    return (
        <div className="controls-container page-resize-container background-blur toggle-pointer-events">
            <IconButton {...resize} icon={<ZoomIn />} />
            <div className="tool-vertical-divider" />
            <TextButton {...fit.button} endIcon={<UnfoldMore />}>
                {fit.label}
            </TextButton>
            <Menu
                id="fit-to-menu"
                className="page-resize-menu"
                anchorEl={anchorElement}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                keepMounted
                onClose={onMenuClose}
                open={!!anchorElement}
                TransitionComponent={Fade}
            >
                {options.map((o, i) => (
                    <MenuItem
                        id={`fit-option-${i}`}
                        key={`fit-option-${i}`}
                        className="page-resize-menu-item"
                        disableRipple={true}
                        selected={fit.label === o.label}
                        onClick={(e) => onOptionClick(o.value)}
                        text={o.label} 
                        color={"default"} 
                    />
                ))}
            </Menu>
        </div>
    );
};

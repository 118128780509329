import { Assets, AssetsManifest } from "pixi.js";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InterRegular from "../../../../css/font/resources/Inter-Regular.woff2";
import {
    FiberColors,
    JacketColors,
    LCBootColors,
    MTPBootColors,
    MTPColors,
    SCBootColors,
    TrunkColors,
} from "../../../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";
import { Black, Green, IColor } from "../../../../models/ui/dialog/color-dialog";
import { WorkspaceStatus } from "../../../../store/overlay/header/status-icon/status-icon";
import { setTextureLoaded } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import { currentStatusSelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";
import { ConnectorTypes } from "../../../../store/overlay/wizard/wizard";
import { getPullingGripSVGString } from "../../decorators/markers/pulling-grip/pulling-grip.hooks";
import { getCrosshatchSVGString } from "../../factories/crosshatch";
import { getLCDuplexSVGString } from "../../factories/lc-duplex";
import { getLCSimplexSVGString } from "../../factories/lc-simplex";
import { getLCUnibootSVGString } from "../../factories/lc-uniboot";
import { getMMCSVGString } from "../../factories/mmc";
import { getMTPSVGString } from "../../factories/mtp-24";
import { getMTPProSVGString } from "../../factories/mtp-pro";
import { getSCSimplexSVGString } from "../../factories/sc-simplex";
import {
    CROSSHATCH_TEXTURE,
    MetaSpriteList,
    PULLING_GRIP_TEXTURE,
    getConnectorTypeString,
    getMTPBootColor,
    isLCDuplex,
    isLCSimplex,
    isMMC,
    isMTP,
    isMTP24,
    isPinned,
    isSC,
    isSCSimplex,
} from "../../factories/texture";
import { getTrunkSprite } from "../../factories/trunks";

type TextureAsset = {
    alias: string;
    src: string;
};

const texturePathPrefix = "data:image/svg+xml;utf8,";

export const useResourceManager = () => {
    const status = useSelector(currentStatusSelector);
    const dispatch = useDispatch();

    const loadTextures = useCallback(async () => {
        await Assets.init({ manifest: getManifest() });
        await Assets.loadBundle(["textures", "fonts"]);
        dispatch(setTextureLoaded());
    }, [dispatch]);

    useEffect(() => {
        if (status === WorkspaceStatus.InitialLoad) {
            loadTextures();
        }
    }, [status, loadTextures]);
};

const getManifest = (): AssetsManifest => {
    const metaSpriteAssets: TextureAsset[] = [];
    for (const metaSprite of MetaSpriteList) {
        for (let trunkColor of TrunkColors) {
            const trunkSprite = getTrunkSprite(metaSprite.name, trunkColor);
            if (trunkSprite) {
                const encodedSVG = encodeURIComponent(trunkSprite);
                const texturePath = `data:image/svg+xml;utf8,${encodedSVG}`;
                const textureName = `${metaSprite.name}_${trunkColor.id}`;
                metaSpriteAssets.push({ alias: textureName, src: texturePath });
            }
        }
    }
    const connectorTypeStrings = ConnectorTypes.map((c) => getConnectorTypeString(c.type ?? ""))
        .filter((c, i, self) => self.indexOf(c) === i)
        .filter((c) => !isLCDuplex(c) && !isMTP(c) && !isMMC(c) && !isSC(c));

    const duplexConnectorTypeStrings = ConnectorTypes.map((c) => getConnectorTypeString(c.type ?? ""))
        .filter((c, i, self) => self.indexOf(c) === i)
        .filter((c) => isLCDuplex(c));

    const mtpConnectorTypeStrings = ConnectorTypes.map((c) => getConnectorTypeString(c.type ?? ""))
        .filter((c, i, self) => self.indexOf(c) === i)
        .filter((c) => isMTP(c));

    const mmcConnectorTypeStrings = ConnectorTypes.map((c) => getConnectorTypeString(c.type ?? ""))
        .filter((c, i, self) => self.indexOf(c) === i)
        .filter((c) => isMMC(c));

    const scConnectorTypeStrings = ConnectorTypes.map((c) => getConnectorTypeString(c.type ?? ""))
        .filter((c, i, self) => self.indexOf(c) === i)
        .filter((c) => isSC(c));

    const connectorAssets = generateConnectorAssets(connectorTypeStrings, FiberColors, LCBootColors);
    const scAssets = generateConnectorAssets(scConnectorTypeStrings, FiberColors, SCBootColors);
    const duplexAssets = generateConnectorAssets(duplexConnectorTypeStrings, FiberColors, LCBootColors, JacketColors);
    const mtpAssets = generateConnectorAssets(mtpConnectorTypeStrings, MTPColors, MTPBootColors);
    const mmcAssets = generateConnectorAssets(mmcConnectorTypeStrings, [Green], [Black]);

    const mesh = generateMeshTexture();
    const meshAsset: TextureAsset = { alias: mesh.textureName, src: mesh.texturePath };

    const textures: TextureAsset[] = [...metaSpriteAssets, ...connectorAssets, ...scAssets, ...duplexAssets, ...mtpAssets, ...mmcAssets, meshAsset];
    const fonts = { Inter: InterRegular };

    return {
        bundles: [
            {
                name: "textures",
                assets: textures,
            },
            {
                name: "fonts",
                assets: fonts,
            },
        ],
    };
};

function generateConnectorAssets(
    connectorTypeStrings: string[],
    colors: IColor[],
    bootColors: IColor[],
    jacketColors?: IColor[]
): TextureAsset[] {
    const assets: TextureAsset[] = [];
    for (let color of colors) {
        connectorTypeStrings.forEach((connectorType) => {
            for (let bootColor of bootColors) {
                if (jacketColors) {
                    for (let jacketColor of jacketColors) {
                        const { textureName, texturePath } = generateConnectorTexture(
                            connectorType,
                            color,
                            bootColor,
                            jacketColor
                        );
                        assets.push({ alias: textureName, src: texturePath });
                    }
                } else {
                    const { textureName, texturePath } = generateConnectorTexture(connectorType, color, bootColor);
                    assets.push({ alias: textureName, src: texturePath });
                }
            }
        });
    }

    return assets;
}

function generateConnectorTexture(
    connectorType: string,
    { hex: color, name: colorName }: IColor,
    { id: bootId, hex: bootColor }: IColor,
    jacketColor?: IColor
) {
    let svgString = "";
    let textureName = `${connectorType}_${colorName}_${bootId}`;
    if (isMTP(connectorType)) {
        const pinned = isPinned(connectorType);
        svgString = isMTP24(connectorType)
            ? getMTPSVGString(color, getMTPBootColor(connectorType), pinned)
            : getMTPProSVGString(color, pinned);
    } else if (isMMC(connectorType)) {
        svgString = getMMCSVGString(color, bootColor, isPinned(connectorType));
    } else if (isSCSimplex(connectorType)) {
        svgString = getSCSimplexSVGString(color, bootColor);
    } else if (isLCDuplex(connectorType) && jacketColor) {
        svgString = getLCDuplexSVGString(color, bootColor, jacketColor.hex);
        textureName = `${connectorType}_${colorName}_${jacketColor.name}-jacket_${bootId}`;
    } else if (isLCSimplex(connectorType)) {
        svgString = getLCSimplexSVGString(color, bootColor);
    } else {
        svgString = getLCUnibootSVGString(color, bootColor);
    }

    return generateSVGTexture(textureName, svgString);
}

function generateMeshTexture() {
    return generateSVGTexture(CROSSHATCH_TEXTURE, getCrosshatchSVGString());
}

export const generatePullingGripTexture = () => {
    return generateSVGTexture(PULLING_GRIP_TEXTURE, getPullingGripSVGString());
};

function generateSVGTexture(textureName: string, svgString: string) {
    const encodedSVG = encodeURIComponent(svgString);
    const texturePath = `${texturePathPrefix}${encodedSVG}`;

    return {
        textureName,
        texturePath,
    };
}

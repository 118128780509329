import { IBuildData } from "../../workspace/build/build";
import { IProjectManagerData } from "../../workspace/project-manager/project-manager";

export interface IProjectManagerState {
    display: boolean;
    data: IProjectManagerData;
    showDetails: boolean;
    showInfo: boolean;
    recentBuild?: IBuildData;
    selectedBuildId: number;
    selectedBuild?: IBuildData;
}

export interface ISortData {
    type: Sort;
    order: boolean;
}

export const Sorts = {
    Name: "name",
    Description: "description",
    DateModified: "date modified",
    Manual: "manual",
} as const;

export type Sort = (typeof Sorts)[keyof typeof Sorts];

export const initialState: IProjectManagerState = {
    display: false,
    data: {
        sortType: Sorts.DateModified,
        isSortAscending: false,
        projectIds: [],
        builds: [],
    },
    showDetails: false,
    showInfo: false,
    selectedBuildId: -1,
};
import {
    PolarityConfig,
    PolarityMap,
    LCToLCMapList,
    TypeBMTP8Map,
    TypeAMTP8Map,
    TypeBMTP12Map,
    TypeAMTP12Map,
    TypeUMTP12Map,
    TypeUMTP8Map,
    TypeAMTP24Map,
    Mesh4x4Map,
    TypeAMMC16Map,
    TypeBMMC16Map,
    getPolarityMap,
    findPolarityConfig,
} from "../../store/workspace/build/connector/polarity/polarity";
import { IBuildData } from "../../store/workspace/build/build";
import {
    ConnLC,
    IConnectorType,
    getConnectorFamilyString,
    ConnMMC16_NP,
    getConnectorTypeFromConnectorData,
} from "../../store/overlay/wizard/wizard";
import { TFunction } from "i18next";
import { LocalizationKeys } from "../../locales/keys";

export function matchPolarityConfig(source: PolarityConfig[], target: PolarityConfig[]): PolarityConfig[] {
    const match: PolarityConfig[] = [];
    for (let i = 0; i < source.length; i++) {
        const s = source[i];
        if (
            s.from &&
            s.to &&
            target.find(
                (t) =>
                    t.from && t.to && t.from!.fiberCount === s.from!.fiberCount && t.to!.fiberCount === s.to!.fiberCount
            )
        ) {
            match.push(s);
        }
    }

    return match;
}

export function getBuildPolarityConfig(build: IBuildData, polarityMapList: PolarityMap[]) {
    const [source, ...destinations] = build.drops;
    const buildSrcConnectors: IConnectorType[] = source.groups
        .flatMap((g) => g.connectors.map((c) => getConnectorTypeFromConnectorData(c)))
        .filter((c, i, self) => self.findIndex((s) => s.type === c.type) === i);

    const buildDstConnectors: IConnectorType[] = destinations
        .flatMap((d) => d.groups.flatMap((g) => g.connectors.map((c) => getConnectorTypeFromConnectorData(c))))
        .filter((c, i, self) => self.findIndex((s) => s.type === c.type) === i);

    const filteredPolarityMaps = getPolarityMap(buildSrcConnectors, buildDstConnectors, polarityMapList);
    const polarityConfigsList: PolarityConfig[] = polarityMapsToConfig(filteredPolarityMaps);
    const combinations = buildSrcConnectors.flatMap((s) =>
        buildDstConnectors.map((d) => ({ source: s, destination: d }))
    );

    const polarityConfigs = combinations.flatMap(({ source, destination }) => {
        const filteredConfigs: PolarityConfig[] = polarityConfigsList.filter(
            (c) => {
                return c.from 
                    && c.to 
                    && c.from.fiberCount === source.fiberCount 
                    && c.to.fiberCount === destination.fiberCount;
            }
        );
        const configs: PolarityConfig[] = filteredConfigs.map((c) => {
            if (c.from && c.to && c.from.type !== source.type && c.to.type !== destination.type) {
                const prev = getConnectorFamilyString(c.from);
                const curr = getConnectorFamilyString(source);
                const description = c.description?.includes(prev) ? c.description.replace(prev, curr) : c.description;
                return { ...c, description, from: source, to: destination };
            }

            return c;
        });

        return configs.length ? configs : { polarityMaps: [], from: source, to: destination };
    });

    return polarityConfigs.sort((a, b) => {
        const lengthA = a.polarityMaps ? a.polarityMaps.length : 0;
        const lengthB = b.polarityMaps ? b.polarityMaps.length : 0;
        return lengthB - lengthA;
    });
}

export function polarityMapsToConfig(polarityMaps: PolarityMap[]) {
    const polarityConfigs: PolarityConfig[] = [];
    for (let i = 0; i < polarityMaps.length; i++) {
        const polarityMap = polarityMaps[i];
        const destinationConnectors = polarityMap.destinationConnectors ?? [];
        const sourceConnectors = polarityMap.sourceConnectors ?? [];
        const uniformMap = isUniformPolarityMap(sourceConnectors, destinationConnectors);

        const config = findPolarityConfig<PolarityConfig>(
            sourceConnectors[0],
            destinationConnectors[0],
            polarityConfigs
        );
        if (config) {
            config.polarityMaps = config.polarityMaps ? [...config.polarityMaps, polarityMap] : [polarityMap];
        } else if (uniformMap) {
            const newConfig: PolarityConfig = {
                polarityMaps: [polarityMap],
                from: sourceConnectors[0],
                to: destinationConnectors[0],
            };

            polarityConfigs.push(newConfig);
        }
    }

    return polarityConfigs;
}

/**
 * checks if source and destination connectors have a uniform fiber count distribution on their respective sides
 */
function isUniformPolarityMap(sourceConnectors: IConnectorType[], destinationConnectors: IConnectorType[]) {
    const uniformSource =
        sourceConnectors.length && sourceConnectors.every((c) => c.fiberCount === sourceConnectors[0].fiberCount);
    const uniformDestination =
        destinationConnectors.length &&
        destinationConnectors.every((c) => c.fiberCount === destinationConnectors[0].fiberCount);
    return uniformSource && uniformDestination;
}

export function getPolarityConfigString(sourceType: IConnectorType, destinationType: IConnectorType, t?: TFunction) {
    const source = getConnectorFamilyString(sourceType);
    const destination = getConnectorFamilyString(destinationType);

    return t ? t(LocalizationKeys.PolarityConfig, { source, destination }) : `${source} to ${destination}`;
}

export function getConfigMapping(polarityConfig?: PolarityConfig): PolarityMap[] {
    let polarityMaps: PolarityMap[] = [];
    if (polarityConfig && polarityConfig.from && polarityConfig.to) {
        const isFromLC = polarityConfig.from.key === ConnLC.key;
        const isToLC = polarityConfig.to.key === ConnLC.key;
        const mtpFiberCount = isFromLC ? polarityConfig.to.fiberCount : polarityConfig.from.fiberCount;
        const mmc16FiberCount = ConnMMC16_NP.fiberCount;
        if (isFromLC && isToLC) {
            polarityMaps = LCToLCMapList;
        } else if (isToLC) {
            polarityMaps = getMTPToLCMaps(mtpFiberCount);
        } else if (
            polarityConfig.from.fiberCount === mmc16FiberCount &&
            polarityConfig.to.fiberCount === mmc16FiberCount
        ) {
            polarityMaps = getMMCToMMCMaps(mmc16FiberCount);
        } else if (polarityConfig.from.fiberCount === polarityConfig.to.fiberCount) {
            polarityMaps = getMTPtoMTPMaps(mtpFiberCount);
        } else {
            polarityMaps = [];
        }
    } else {
        polarityMaps = [];
    }

    return polarityMaps;
}

function getMTPToLCMaps(fiberCount: number) {
    let polarityMaps: PolarityMap[] = [];
    switch (fiberCount) {
        case 12:
            polarityMaps = [TypeUMTP12Map];
            break;
        case 24:
            polarityMaps = [TypeAMTP24Map];
            break;
        case 8:
            polarityMaps = [TypeUMTP8Map];
            break;
        default:
            break;
    }
    return polarityMaps;
}

function getMTPtoMTPMaps(fiberCount: number) {
    let polarityMaps: PolarityMap[] = [];
    switch (fiberCount) {
        case 12:
            polarityMaps = [TypeBMTP12Map, TypeAMTP12Map];
            break;
        case 8:
            polarityMaps = [TypeBMTP8Map, TypeAMTP8Map, Mesh4x4Map];
            break;
        default:
            break;
    }

    return polarityMaps;
}

function getMMCToMMCMaps(fiberCount: number) {
    let polarityMaps: PolarityMap[] = [];
    switch (fiberCount) {
        case 16:
            polarityMaps = [TypeAMMC16Map, TypeBMMC16Map];
            break;
        default:
            break;
    }

    return polarityMaps;
}

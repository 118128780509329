import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./page-resize-legacy";
import { setResizeReducer } from "./page-resize-legacy.actions";

const slice = createSlice({
    name: "resize",
    initialState,
    reducers: {
        setResize: setResizeReducer,
    },
});

export const PageResizeReducer = slice.reducer;
export const { setResize } = slice.actions;

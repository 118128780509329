export interface IPropagationCheckboxState {
    triggerChecked: boolean;
    labelTextChecked: boolean;
    labelColorChecked: boolean;
}

export const initialPropagationCheckboxState: IPropagationCheckboxState = {
    triggerChecked: false,
    labelTextChecked: false,
    labelColorChecked: false,
};

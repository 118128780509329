import { Container } from "@pixi/react";
import { Drop } from "../build/drop/drop";
import { DropProps } from "../../../models/pixi/build/build";
import { DimensionLineContext, dropReportStyle, feederReportStyle } from "../../../models/pixi/decorators/dimension-lines/dimension-lines";

export const DropGraphic: React.FC<DropProps> = (props) => {
    const dimensionLineContext = props.context.side === "feeder" ? feederReportStyle : dropReportStyle;
    return (
        <DimensionLineContext.Provider value={dimensionLineContext}>
            <Container name={props.context.containerName}>
                <Drop {...props} />
            </Container>
        </DimensionLineContext.Provider>
    );
};

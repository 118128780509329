import { IPointEmpty } from "../../pixi";
import { defaultStyle, IBezierPath, IPath } from "./dimension-lines";

interface IBaseLineMarkerProps {
    key?: string;
    orientation?: Orientation;
    direction?: Direction;
    textGap?: number;
    textScale?: number;
    thickness: number;
    color: number;
    hideUnits?: boolean;
    maskLengths?: boolean;
}

export interface ILineMarkerProps extends IBaseLineMarkerProps {
    path: IPath;
}

export interface IBezierLineMarkerProps extends IBaseLineMarkerProps {
    path: IBezierPath;
}

export const Directions = {
    Start: "start",
    End: "end",
    Bidirectional: "bidirectional",
    None: "none",
} as const;

export type Direction = (typeof Directions)[keyof typeof Directions];

export const Orientations = {
    Horizontal: "horizontal",
    Vertical: "vertical",
} as const;

export type Orientation = (typeof Orientations)[keyof typeof Orientations];

export const Sides = {
    Left: "left",
    Top: "top",
    Right: "right",
    Bottom: "bottom",
} as const;

export type Side = (typeof Sides)[keyof typeof Sides];

export const initialState: ILineMarkerProps = {
    path: { start: IPointEmpty, end: IPointEmpty },
    orientation: Orientations.Vertical,
    ...defaultStyle,
};

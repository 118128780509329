import { TextField } from "@corning-ctcm/silica-react";
import React from "react";
import { useBLengthInput } from "./b-length-input.hooks";
import { IBLengthInputProps } from "../../../../../../models/overlay/wizard/setup/b-length-section/b-length-input";

export const BLengthInput: React.FC<IBLengthInputProps> = (props) => {
    const { inputField, label } = useBLengthInput(props);
    return (
        <div key={label} className="custom-b-length-field">
            <TextField
                palette="primary"
                disabled={inputField.disabled}
                label={inputField.label}
                helperText={inputField.helperText}
                error={!inputField.isValid}
                value={inputField.value}
                onChange={(e: React.ChangeEvent<any>) => inputField.onChange(e)}
            />
        </div>
    );
};

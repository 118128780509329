import { INavigationBarProps } from "../../../models/ui/navigation-bar";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../locales/keys";
import { useCallback, useMemo } from "react";
import { setCurrentIndex } from "../../../store/ui/navigation-bar/navigation-bar.reducer";
import { IconButtonProps } from "@corning-ctcm/silica-react";

export const useNavigationBar = (props: INavigationBarProps) => {
    const { context, displayThreshold, onFirstClick, onPreviousClick, buttons, onNextClick, onLastClick } = props;
    const { state, dispatch } = context;
    const { currentIndex, disabled } = state;
    const { t } = useTranslation();

    const onFirstCallback = useCallback(() => {
        if (onFirstClick) {
            onFirstClick();
        }
        dispatch(setCurrentIndex(0));
    }, [onFirstClick, dispatch]);

    const onPreviousCallback = useCallback(() => {
        if (onPreviousClick) {
            onPreviousClick();
        }
        dispatch(setCurrentIndex(currentIndex - 1));
    }, [onPreviousClick, dispatch, currentIndex]);

    const onNextCallback = useCallback(() => {
        if (onNextClick) {
            onNextClick();
        }
        dispatch(setCurrentIndex(currentIndex + 1));
    }, [onNextClick, dispatch, currentIndex]);

    const onLastCallback = useCallback(() => {
        if (onLastClick) {
            onLastClick();
        }
        if (buttons && buttons.length > 0) {
            dispatch(setCurrentIndex(buttons.length - 1));
        }
    }, [onLastClick, buttons, dispatch]);

    const firstPreviousDisabled = currentIndex === 0 || disabled;
    const firstProps: Omit<IconButtonProps, "icon"> = {
        className: "first-button",
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.First),
        placement: "bottom",
        disabled: firstPreviousDisabled,
        onClick: onFirstCallback,
    };
    const previousProps: Omit<IconButtonProps, "icon"> = {
        className: "previous-button",
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.Previous),
        placement: "bottom",
        disabled: firstPreviousDisabled,
        onClick: onPreviousCallback,
    };
    const nextLastDisabled = buttons ? currentIndex + 1 === buttons.length : disabled;
    const nextProps: Omit<IconButtonProps, "icon"> = {
        className: "next-button",
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.Next),
        placement: "bottom",
        disabled: nextLastDisabled,
        onClick: onNextCallback,
    };
    const lastProps: Omit<IconButtonProps, "icon"> = {
        className: "last-button",
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.Last),
        placement: "bottom",
        disabled: nextLastDisabled,
        onClick: onLastCallback,
    };
    const thresholdProps: IconButtonProps = {
        className: "threshold-button",
        palette: "primary",
        mode: "main",
        placement: "bottom",
        icon: null,
        disabled: true,
    };

    let showPreviousThreshold = false;
    let showNextThreshold = false;
    if (displayThreshold && buttons && buttons.length > displayThreshold) {
        showPreviousThreshold = currentIndex + 1 > displayThreshold;
        showNextThreshold = currentIndex < displayThreshold;
    }

    const displayedButtons = useMemo(() => {
        if (buttons && buttons.length > 0) {
            let displayedButtons = buttons;
            if (displayThreshold && buttons.length > displayThreshold) {
                const firstChildIndex = Math.floor(currentIndex / displayThreshold) * displayThreshold;
                const lastChildIndex = firstChildIndex + displayThreshold;
                displayedButtons = buttons.slice(firstChildIndex, lastChildIndex);
            }
            return displayedButtons.map((b, i) => {
                b.buttonProps.className = i === currentIndex ? "selected" : undefined;
                return b;
            })
        }
    }, [buttons, currentIndex, displayThreshold]);

    return {
        firstProps,
        previousProps,
        buttons: displayedButtons,
        nextProps,
        lastProps,
        showPreviousThreshold,
        showNextThreshold,
        thresholdProps,
    };
};

import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";

type BuildToleranceRow = {
    label: string;
    value: string;
};

export type BuildTolerancesTableProps = {
    rows: BuildToleranceRow[];
};

export const BuildTolerancesTable: React.FC<BuildTolerancesTableProps> = ({ rows }) => {
    if (rows.length <= 1) return null;

    return (
        <Table id="tolerances-table">
            <TableBody>
                {rows.map((r, i) => (
                    <TableRow key={"tolerances-column" + i}>
                        <TableCell key={"tolerance-label" + i}>{r.label}</TableCell>
                        <TableCell key={"tolerance-value" + i}>{r.value}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

import { t } from "i18next";
import { LocalizationKeys } from "../../../locales/keys";
import { IConnectorType } from "../../../store/overlay/wizard/wizard";
import { useSelector } from "react-redux";
import { unitsOfMeasureContainerUnitNameSelector } from "../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { DropSide } from "../../../store/workspace/build/drop";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

type DropLabelRow = {
    tapPosition: number;
    connectorId: number;
    groupNumber: number;
    b: string;
    label: string | undefined;
    labelColorIndex: number;
    colorIndex: number;
    connectorType: IConnectorType;
    comment: string;
};

export type DropLabelTableProps = {
    id: string;
    side: DropSide;
    rows: DropLabelRow[];
};

export const DropLabelTable: React.FC<DropLabelTableProps> = ({ id, side, rows }: DropLabelTableProps) => {
    const unitName = useSelector(unitsOfMeasureContainerUnitNameSelector);
    if (rows.length === 0) return null;

    return (
        <Table id={id}>
            <TableHead>
                <TableRow>
                    <th colSpan={5}>{t(LocalizationKeys.AccessPoint)}</th>
                </TableRow>
                <TableRow>
                    <TableCell>
                        {side === "distribution"
                            ? t(LocalizationKeys.ManufTapNumber)
                            : t(LocalizationKeys.ConfigConnNumber)}
                    </TableCell>
                    <TableCell>{t(LocalizationKeys.ManufConnNumber)}</TableCell>
                    <TableCell>{t(LocalizationKeys.Label)}</TableCell>
                    <TableCell>{t(LocalizationKeys.ReportLenghtBx, { units: unitName })}</TableCell>
                    <TableCell>{t(LocalizationKeys.LabelColor)}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((r, i) => {
                    return (
                        <TableRow id={`${i}`} key={`d${i}`}>
                            <TableCell>{r.tapPosition}</TableCell>
                            <TableCell>{r.connectorId}</TableCell>
                            <TableCell>{r.label}</TableCell>
                            <TableCell>{r.b}</TableCell>
                            <TableCell>{r.labelColorIndex}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

import { AboutThisApplicationButton } from "./about-application-button/about-application-button";
import { ExportButton } from "./export-button/export-button";
import { FullscreenButton } from "./fullscreen-button/fullscreen-button";
import { useToolsContainer } from "./tools-container.hooks";
import { PolarityButton } from "../polarity-button/polarity-button";
import { ViewProduct } from "../view-product/view-product";
import WarningIcon from "../../../../resources/svgs/overlay/header/icon_warnings.svg";
import { EditTolerances } from "../tolerances/edit-tolerances";
import { SettingsButton } from "./settings-button/settings-button";
import { IconButton } from "@corning-ctcm/silica-react";
import { AddBox, MoreVert } from "@mui/icons-material";
import { Unit, isImperial } from "../../../../models/overlay/header/units-of-measure";
import i18next from "i18next";
import IconImperial from "../../../../resources/svgs/overlay/header/icon_imperial.svg";
import IconMetric from "../../../../resources/svgs/overlay/header/icon_metric.svg";
import { LocalizationKeys } from "../../../../locales/keys";

export const ToolsContainer = () => {
    const {
        isCustomer,
        unitsProps,
        warningProps,
        warningCount,
        badgePalette,
        newCableProps,
        moreMenuProps,
        currentUnits,
    } = useToolsContainer();
    const { measurementIcon, measurementTitle } =  getMeasurementInfo(currentUnits);
    return (
        <div id="header-actions" className="button-container background-blur tools-container toggle-pointer-events">
            <ViewProduct />
            <PolarityButton />
            <div className="vertical-divider" />
            <IconButton 
                { ...warningProps } 
                icon={(
                    <div>
                        {warningCount > 0 ? (
                            <div key={`badge${badgePalette}`} className={`badge ${badgePalette}`}>
                                <div className="badge-number-container">
                                    <div className="badge-number">{warningCount}</div>
                                </div>
                            </div>
                        ) : null}
                        <img className="warning-icon" src={WarningIcon} alt="" />
                    </div>
                )}
            />
            <ExportButton />
            <EditTolerances />
            <IconButton { ...unitsProps } title={measurementTitle} icon={measurementIcon} />
            <FullscreenButton />
            <SettingsButton />
            <AboutThisApplicationButton />
            <div className="vertical-divider" />
            <IconButton { ...newCableProps } icon={<AddBox />} />
            {isCustomer ? null : (
                <>
                    <div className="vertical-divider" />
                    <IconButton {...moreMenuProps} icon={<MoreVert />} />
                </>
            )}
        </div>
    );
};

const getMeasurementInfo = (currentUnits: Unit): {measurementIcon: JSX.Element, measurementTitle: string} => {
    let measurementIcon = null;
    let measurementTitle = "";
    let uom = currentUnits;
    if (isImperial(uom)) {
        measurementTitle = i18next.t(LocalizationKeys.Imperial);
        measurementIcon = <img src={IconImperial} alt="" />;
    } else {
        measurementTitle = i18next.t(LocalizationKeys.Metric);
        measurementIcon = <img src={IconMetric} alt="" />;
    }
    return {measurementIcon, measurementTitle};
};

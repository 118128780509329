import i18next from "i18next";

import { LocalizationKeys } from "../locales/keys";
import { ITolerances } from "../models/overlay/header/tolerances/tolerances";
import { convertTo, convertTolerances, IUnitOfMeasure, Units } from "../models/overlay/header/units-of-measure";
import { IPropagationOptions, Propagation, PropagationResult } from "../store/overlay/polarity/propagation/propagation";
import { initialProductLabel } from "../models/overlay/reports/report-settings/labels-section";
import { IFiberMapData } from "../store/workspace/build/connector/polarity/fiber-map";
import { BuildPolarity, BuildPolarityPair } from "../store/workspace/build/connector/polarity/polarity";
import { IConnectorData, IConnectorGroupData } from "../store/workspace/build/connector/connector";
import { IDrop } from "../store/workspace/build/drop";
import { IBuildData, IBuildInfo } from "../store/workspace/build/build";
import { SecuredService } from "./abstract-secured-v2.service";
import {
    BuildDTO,
    ConnectorDTO,
    ConnectorGroupDTO,
    ConnectorTypeDTO,
    DestinationDTO,
    DropDTO,
    IAddConnectorAssignmentsResponse,
    IConnectorsUpdateResponse,
} from "../models/services/services";
import { getDateNowString } from "../components/ui/input/input.hooks";
import { IConnectorAssignment } from "../store/overlay/polarity/polarity";
import { getConnectorType } from "../store/overlay/wizard/wizard";

export class BuildService extends SecuredService {
    private readonly baseUrl = "/api/build";

    public constructor() {
        super();
    }

    public async getBuild(id: number): Promise<IBuildData | undefined> {
        const dto = await this.get<BuildDTO>(`${this.baseUrl}/${id}`);
        if (dto) {
            const buildData = ToBuildData(dto);
            return buildData;
        }
        return dto;
    }

    public async getRecentBuild(id: number): Promise<IBuildData | undefined> {
        const dto = await this.get<BuildDTO>(`${this.baseUrl}/recent/${id}`);
        if (dto) {
            const buildData = ToBuildData(dto);
            return buildData;
        }
        return dto;
    }

    public async getAllBuildsForUser(): Promise<IBuildInfo | undefined> {
        return this.get(`${this.baseUrl}/all/user`);
    }

    public async getAllBuildsForGroup(): Promise<IBuildInfo[] | undefined> {
        return this.get(`${this.baseUrl}/all/group`);
    }

    public async updateBuild(build: IBuildData): Promise<BuildDTO | undefined> {
        const buildDto = ToBuildDTO(build);
        return this.put(this.baseUrl, buildDto);
    }

    public async updateBuildCatalogCode(buildId: number, catalogCode: string) {
        return this.put<void>(`${this.baseUrl}/catalogCode`, { buildId, catalogCode });
    }

    public async deleteBuild(id: number): Promise<void> {
        return this.delete<void>(`${this.baseUrl}/${id}`);
    }

    public async updateBuildPolarityDefinitions(
        buildId: number,
        buildPolarity: BuildPolarity[]
    ): Promise<BuildPolarity[] | undefined> {
        return this.put(`${this.baseUrl}/polarity/${buildId}`, buildPolarity);
    }

    public async getUserBuildPolarityDefinitionsByGroupId(): Promise<BuildPolarityPair[] | undefined> {
        return this.get(`${this.baseUrl}/polarity/group`);
    }

    public async applyPropagation(propagation: Propagation): Promise<PropagationResult | undefined> {
        return this.post(`${this.baseUrl}/polarity/propagation`, propagation);
    }

    public async addConnectorAssignments(
        buildId: number,
        connectorAssignments: IConnectorAssignment[],
        propagationOptions: IPropagationOptions
    ): Promise<IAddConnectorAssignmentsResponse | undefined> {
        return this.post(`${this.baseUrl}/polarity/assignment/build/${buildId}`, {
            connectorAssignments,
            propagationOptions,
        });
    }

    public async deleteAllConnectorAssignments(buildId: number): Promise<boolean | undefined> {
        return this.delete(`${this.baseUrl}/polarity/assignment/all/${buildId}`);
    }

    public async deleteConnectorAssignment(
        buildId: number,
        connectorAssignmentIndex: number
    ): Promise<boolean | undefined> {
        return this.delete(`${this.baseUrl}/polarity/assignment/${buildId}/${connectorAssignmentIndex}`);
    }

    public async getPolarityFiberMaps(): Promise<IFiberMapData[] | undefined> {
        return this.get(`${this.baseUrl}/polarity/fiberMaps`);
    }

    public async updateConnectors(
        connectors: IConnectorData[],
        propagation?: Propagation
    ): Promise<IConnectorsUpdateResponse | undefined> {
        return this.post(`${this.baseUrl}/connectors`, {
            connectors: connectors.map((c) => ToConnectorDTO(c)),
            propagation,
        });
    }

    public async updatePrimaryUnit(buildId: number, primaryUnit: string, unit: string): Promise<string | undefined> {
        return this.post(`${this.baseUrl}/current/primaryUnit`, { buildId, primaryUnit, unit });
    }

    public async updateUnit(buildId: number, unit: string): Promise<string | undefined> {
        return this.post(`${this.baseUrl}/current/unit`, { buildId, unit });
    }
}

export function ToUnitofMeasure(valueIn: number | undefined): IUnitOfMeasure {
    return {
        value: valueIn ?? 0,
        unit: Units.UoMInches,
    };
}

function ToConnectorGroupData(dto: ConnectorGroupDTO): IConnectorGroupData {
    const { staggerIn, connectors, lengthBIn } = dto;
    return {
        ...dto,
        lengthB: ToUnitofMeasure(lengthBIn),
        stagger: ToUnitofMeasure(staggerIn),
        connectors: connectors!
            .slice()
            .sort((a, b) => (a.position! > b.position! ? 1 : -1))!
            .map((c) => ToConnectorData(c)),
    };
}

function ToConnectorData(dto: ConnectorDTO): IConnectorData {
    const { staggerIn } = dto;
    return {
        ...dto,
        stagger: ToUnitofMeasure(staggerIn),
    };
}

export function ToDropData(dto: DropDTO): IDrop {
    const { lengthA, lengthB, meshOffset, groups } = dto;
    const fiberCountInUse = dto.fiberCountInUse ?? getConnectorType(groups[0].type).fiberCount;
    const groupsData = groups!
        .slice()
        .sort((a, b) => (a.position! > b.position! ? 1 : -1))!
        .map((c) => ToConnectorGroupData(c))
        .map((g) => ({ ...g, connectors: g.connectors.map((c) => ({ ...c, fiberCountInUse })) }));
    let reverseStaggering = false;
    if (!dto.customBLength) {
        if (groups.length > 1) {
            reverseStaggering = (groupsData[0].lengthB?.value ?? 0) > (groupsData[1].lengthB?.value ?? 0);
        }
    }
    return {
        ...dto,
        lengthA: ToUnitofMeasure(lengthA),
        lengthB: ToUnitofMeasure(lengthB),
        meshOffset: ToUnitofMeasure(meshOffset),
        fiberCountInUse,
        groups: groupsData,
        groupsCollapsed: false,
        connectorsCollapsed: false,
        reverseStaggering,
    };
}

export function ToBuildData(dto: BuildDTO): IBuildData {
    const {
        lastModified,
        drops,
        isAsymmetric,
        pullingGrip,
        overviewNotes,
        productLabels,
        location,
        drawnBy,
        revisionNumber,
        approvalDate,
        inServiceDate,
        footerNotes,
        maskLengths,
        connectorAssignments,
        ...rest
    } = dto;

    let formattedLastModified: string;
    if (lastModified && lastModified === i18next.t(LocalizationKeys.DefaultDate)) {
        formattedLastModified = i18next.t(LocalizationKeys.Unknown);
    } else {
        let lastModifiedUTCString = lastModified!;
        if (lastModifiedUTCString.charAt(lastModifiedUTCString.length - 1) !== i18next.t(LocalizationKeys.Zulu)) {
            lastModifiedUTCString = lastModified!.concat(i18next.t(LocalizationKeys.Zulu)); // Backend date is not specified as UTC, we need to interpret it ourselves
        }

        // Provide the local date time in 24 hours format
        formattedLastModified = new Date(lastModifiedUTCString).toString();
    }
    const [feederDrop, ...distributionDrops] = drops!;
    const tolerances = rest.tolerances ? (JSON.parse(rest.tolerances) as ITolerances) : undefined;
    const data: IBuildData = {
        ...rest,
        autoAccessPoints: isAsymmetric,
        lastModified: formattedLastModified,
        drops: [feederDrop, ...distributionDrops!]
            .sort((a, b) => (a.position! > b.position! ? 1 : -1))!
            .map((d, i) => ({ ...ToDropData(d), side: i ? "distribution" : "feeder" })),
        tolerances,
        pullingGrip: pullingGrip ?? "none",
        productLabels: productLabels ? JSON.parse(productLabels) : [initialProductLabel],
        overviewNotes: overviewNotes ?? "",
        location: location ?? "",
        drawnBy: drawnBy ?? "",
        revisionNumber: revisionNumber ?? "",
        approvalDate: approvalDate
            ? getDateNowString(new Date(approvalDate).toLocaleDateString(undefined, { timeZone: "UTC" }))
            : getDateNowString(),
        inServiceDate: inServiceDate
            ? getDateNowString(new Date(inServiceDate).toLocaleDateString(undefined, { timeZone: "UTC" }))
            : getDateNowString(),
        footerNotes: footerNotes ?? "",
        maskLengths: !!maskLengths,
        connectorAssignments: connectorAssignments ?? [],
    };

    return data;
}

export function ToBuildDTO(data: IBuildData): BuildDTO {
    const { autoAccessPoints, tolerances, productLabels, ...rest } = data;
    return {
        ...rest,
        customTag: "",
        partNumber: "",
        isAsymmetric: autoAccessPoints,
        lastModified: getCurrentISODate(),
        drops: [...data.drops!].sort((a, b) => (a.position! > b.position! ? 1 : -1))!.map((d) => ToDropDTO(d)),
        tolerances: tolerances ? JSON.stringify(convertTolerances(tolerances, Units.UoMInches)) : undefined,
        productLabels: productLabels ? JSON.stringify(productLabels) : undefined,
    };
}

export function getCurrentISODate() {
    let date = new Date();
    return date.toISOString();
}

export function ToConnectorGroupDTO(data: IConnectorGroupData): ConnectorGroupDTO {
    const { lengthB, stagger, connectors, ...props } = data;

    return {
        ...props,
        lengthBIn: convertTo(lengthB).value,
        staggerIn: convertTo(stagger).value,
        connectors: connectors.map((c) => ToConnectorDTO(c)),
    };
}

export function ToConnectorDTO(data: IConnectorData): ConnectorDTO {
    const { stagger, ...props } = data;

    return {
        ...props,
        staggerIn: convertTo(stagger).value,
    };
}

export function ToConnectorTypeDTO(data: IConnectorData): ConnectorTypeDTO {
    return {
        type: data.type,
    };
}

export function ToDestinationDTO(data: IDrop): DestinationDTO {
    const { lengthA, lengthB, groups, ...props } = data;

    return {
        ...props,
        lengthAIn: convertTo(lengthA).value,
        lengthBIn: convertTo(lengthB).value,
        groups: groups.map((g) => ToConnectorGroupDTO(g)),
    };
}

export function ToDropDTO(drop: IDrop): DropDTO {
    const { lengthA, lengthB, meshOffset, groups, fiberCountInUse, ...props } = drop;
    return {
        ...props,
        lengthA: convertTo(lengthA).value,
        lengthB: convertTo(lengthB).value,
        fiberCountInUse: fiberCountInUse ?? getConnectorType(groups[0].type).fiberCount,
        meshOffset: meshOffset !== undefined ? convertTo(meshOffset).value : undefined,
        groups: groups.map((g) => ToConnectorGroupDTO(g)),
    };
}

export function ExtractGroupProjectIds(builds: IBuildInfo[], groupId: number): number[] {
    if (groupId === -1) return [];

    return builds
        .map((b) => {
            if (b.groupId && b.groupId === groupId) {
                return b.buildId ? b.buildId : -1;
            }
            return -1;
        })
        .filter((b) => b !== -1);
}

export function ExtractProjectIds(builds: IBuildInfo[]): number[] {
    return builds
        .map((b) => {
            return b.buildId ? b.buildId : -1;
        })
        .filter((b) => b !== -1);
}

import { ContainedButton, Typography } from "@corning-ctcm/silica-react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import { IPolarityDiagramProps } from "../../../../../models/overlay/polarity/polarity-diagram/polarity-diagram";
import { Canvas } from "./canvas/canvas";
import { PanAndZoomToolbar } from "./pan-and-zoom-toolbar/pan-and-zoom-toolbar";
import { usePolarityDiagram } from "./polarity-diagram.hooks";

import "../../../../../css/overlay/polarity/polarity-diagram.scss";

export const PolarityDiagram: React.FC<IPolarityDiagramProps> = (props) => {
    const { canvasProps, toolbarProps, title, applyButton, containerRef, canvasRef } = usePolarityDiagram(props);
    const { t } = useTranslation();

    return (
        <div className="polarity-graphic" ref={containerRef}>
            <div className="assign-polarity-graphic-item assign-polarity-apply-container">
                <Typography variant="h6" color="text.secondary" className="assign-polarity-title">
                    {title}
                </Typography>
                <ContainedButton {...applyButton}>{t(LocalizationKeys.SetAsDefault)}</ContainedButton>
            </div>
            {canvasProps.src ? (
                <>
                    <Canvas ref={canvasRef} {...canvasProps} />
                    <PanAndZoomToolbar {...toolbarProps} />
                </>
            ) : null}
        </div>
    );
};

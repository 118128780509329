import { DocumentFields } from "../../../../store/workspace/document/document";

export type ReportSettings = {
    cableDrawing?: IReportSection;
    feeder?: IReportSection;
    drops?: DropSection;
    cableOverview?: IDiagramSection;
    tolerances?: IDiagramSection;
    connectorLabel?: IDiagramSection;
    fiberMap?: IDiagramSection;
    buildPlan?: IDiagramSection;
} & DocumentFields;

export enum ReportType {
    TwoPage = "2page",
    Detailed = "detailed",
}

export enum ReportSectionOption {
    Workspace = "workspace",
    Collapsed = "collapsed",
    Expanded = "expanded",
}

export interface IReportSection {
    option: string;
    include: boolean;
    disabled?: boolean;
}

export type DropSection = IReportSection & {
    dropPositions?: number[];
    includeAll: boolean;
};

export interface IDiagramSection {
    include: boolean;
    disabled: boolean;
}

export const initialReportSettings: ReportSettings = {
    siteLocation: "",
    drawn: "",
    drawingNotes: "",
    footerNotes: "",
    revision: "",
    cableDrawing: {
        option: "workspace",
        include: true,
    },
    feeder: {
        option: "workspace",
        include: true,
        disabled: false,
    },
    drops: {
        dropPositions: [1],
        includeAll: true,
        option: "workspace",
        include: true,
        disabled: false,
    },
    connectorLabel: {
        include: true,
        disabled: false,
    },
    fiberMap: {
        include: true,
        disabled: false,
    },
    buildPlan: {
        include: true,
        disabled: false,
    },
    tolerances: {
        include: true,
        disabled: false,
    },
    cableOverview: {
        include: true,
        disabled: false,
    },
    userProvidedImages: [],
};

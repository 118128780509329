import { Aqua, Black, Blue, Green, IColor, Mustard, Yellow } from "../../../models/ui/dialog/color-dialog";
import { MTP24BootColor, MTPBootColor, MTPSVG } from "./mtp-24";
import SOURCE_TRUNK_IMG from "../../../resources/svgs/pixi/build/image_cable_trunk.svg";
import SOURCE_TRUNK_EXTERNAL_IMG from "../../../resources/svgs/pixi/build/image_cable_trunk_external.svg";
import MTP_IMG from "../../../resources/svgs/pixi/build/image_8-12F_MTP.svg";
import MTP24F_IMG from "../../../resources/svgs/pixi/build/image_24F_MTP.svg";
import LC_IMG from "../../../resources/svgs/pixi/build/image_2F_LC.svg";
import { LCUnibootSVG } from "./lc-uniboot";
import {
    FiberColors,
    MTPColors,
} from "../../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";
import { ISize } from "../../../models/pixi/pixi";
import { MMCSVG } from "./mmc";
import { LCDuplexSVG } from "./lc-duplex";
import { LCSimplexSVG } from "./lc-simplex";
import { SCSimplexSVG } from "./sc-simplex";

type Texture = {
    name: string;
    path: string;
    img: string;
} & ISize;

export const SOURCE_TRUNK = "SourceTrunk";
export const SOURCE_TRUNK_IMGPATH = "image_cable_trunk.svg";
export const SOURCE_TRUNK_EXTERNAL = "SourceTrunkExternal";
export const SOURCE_TRUNK_EXTERNAL_IMGPATH = "image_cable_trunk_external.svg";

export const TAP_TRUNK = "TAPTrunk";
export const TAP_TRUNK_IMGPATH = "image_TAP_trunk.svg";
export const TAP_VERTICAL_TRUNK = "TAPVerticalTrunk";
export const AP = "AP";

export const MMC16F = "16 F, MMC";
export const MMC16F_NP = "16 F, MMC, non-pinned";
export const MMC16F_P = "16 F, MMC, pinned";
export const MMC24F = "24 F, MMC";
export const MMC24F_NP = "24 F, MMC, non-pinned";
export const MMC24F_P = "24 F, MMC, pinned";

export const MTP = "12 F, MTP"; // default mtp
export const MTP8F = "8 F, MTP";
export const MTP8F_NP = "8 F, MTP, non-pinned";
export const MTP8F_P = "8 F, MTP, pinned";
export const MTP12F = "12 F, MTP";
export const MTP12F_NP = "12 F, MTP, non-pinned";
export const MTP12F_P = "12 F, MTP, pinned";
export const MTP12F_FP_NP = "12 F, MTP Ferrule Push, non-pinned";
export const MTP12F_FP_P = "12 F, MTP Ferrule Push, pinned";
export const MTP24F = "24 F, MTP";
export const MTP24F_NP = "24 F, MTP, non-pinned";
export const MTP24F_P = "24 F, MTP, pinned";
export const MTP24F_FP_NP = "24 F, MTP Ferrule Push, non-pinned";
export const MTP24F_FP_P = "24 F, MTP Ferrule Push, pinned";
export const MPO12F = "12 F, MPO";
export const MPO12F_NP = "12 F, MPO, non-pinned";
export const MPO12F_P = "12 F, MPO, pinned";

export const MTP_IMGPATH = "image_8-12F_MTP.svg";
export const MTP24F_IMGPATH = "image_24F_MTP.svg";

export const SC_APC_SIMPLEX = "1 F, SC APC Simplex";

export const LC = "2 F, LC Uniboot";
export const LC_DUPLEX = "2 F, LC Duplex";
export const LC_SIMPLEX = "1 F, LC Simplex";
export const LC_IMGPATH = "image_2F_LC.svg";

export const CROSSHATCH_TEXTURE = "CROSSHATCH";
export const PULLING_GRIP_TEXTURE = "PullingGrip";

export const PinnedString = "pinned";
export const SCSimplexTypeString = "SCSimplex";
export const LCUnibootTypeString = "LCUniboot";
export const LCSimplexTypeString = "LCSimplex";
export const LCDuplexTypeString = "LCDuplex";
export const MTPTypeString = "MTP";
export const MTP24TypeString = "MTP24";
export const MMCTypeString = "MMC";
export const MPOTypeString = "MPO";

export const MetaSCSimplexConnectorTexture: Texture = { name: SC_APC_SIMPLEX, path: "", img: "", width: 12, height: 56 };
export const RestrictedConnectorTypes: string[] = [LC, LC_DUPLEX, LC_SIMPLEX];

export const MetaLCConnectorTexture: Texture = { name: LC, path: LC_IMGPATH, img: LC_IMG, width: 14, height: 70 };
export const MetaLCSimplexConnectorTexture: Texture = { name: LC_SIMPLEX, path: "", img: "", width: 14, height: 47 };
export const MetaLCDuplexConnectorTexture: Texture = { name: LC_DUPLEX, path: "", img: "", width: 19, height: 86.8 };
export const MetaMTPConnectorTexture: Texture = {
    name: "8-12F MTP",
    path: MTP_IMGPATH,
    img: MTP_IMG,
    width: 26,
    height: 100,
};
export const MetaMTP24FConnectorTexture: Texture = {
    name: "24F MTP",
    path: MTP24F_IMGPATH,
    img: MTP24F_IMG,
    width: 26,
    height: 100,
};
export const MetaMMCConnectorTexture: Texture = { name: "16-24F MMC", path: "", img: "", width: 14, height: 68 };

export const MetaSourceTrunkTexture: Texture = {
    name: SOURCE_TRUNK,
    path: SOURCE_TRUNK_IMGPATH,
    img: SOURCE_TRUNK_IMG,
    width: 288.619,
    height: 271.989,
};
export const MetaSourceTrunkExternalTexture: Texture = {
    name: SOURCE_TRUNK_EXTERNAL,
    path: SOURCE_TRUNK_EXTERNAL_IMGPATH,
    img: SOURCE_TRUNK_EXTERNAL_IMG,
    width: 292.594,
    height: 279,
};

export type MetaSprite = typeof MetaSourceTrunkTexture | typeof MetaSourceTrunkExternalTexture;

export const MetaSprites = {
    MetaSourceTrunkTexture,
    MetaSourceTrunkExternalTexture,
} as const;

export const MetaSpriteList = [MetaSourceTrunkTexture, MetaSourceTrunkExternalTexture];

export function getConnectorTexture(connector: string): MetaSprite {
    switch (connector) {
        case MMC16F_NP:
        case MMC16F_P:
        case MMC24F_NP:
        case MMC24F_P:
            return MetaMMCConnectorTexture;
        case MTP:
        case MTP8F_NP:
        case MTP8F_P:
        case MTP12F_NP:
        case MTP12F_P:
        case MTP12F_FP_NP:
        case MTP12F_FP_P:
        case MTP_IMGPATH:
        case MPO12F_NP:
        case MPO12F_P:
            return MetaMTPConnectorTexture;
        case MTP24F_NP:
        case MTP24F_P:
        case MTP24F_FP_NP:
        case MTP24F_FP_P:
        case MTP24F_IMGPATH:
            return MetaMTP24FConnectorTexture;
        case SC_APC_SIMPLEX:
            return MetaSCSimplexConnectorTexture;
        case LC:
        case LC_IMGPATH:
            return MetaLCConnectorTexture;
        case LC_DUPLEX:
            return MetaLCDuplexConnectorTexture;
        case LC_SIMPLEX:
            return MetaLCSimplexConnectorTexture;
    }

    // Catch connector values of the type 'LCUniboot_Blue', 'MTP_pinned_Green', etc.
    if (isLCUniboot(connector)) {
        return MetaLCConnectorTexture;
    } else if (isMTP24(connector)) {
        // Check for MTP24 first because both "MTP" and "MTP24" contain the substring "MTP"
        return MetaMTP24FConnectorTexture;
    } else if (isMTP(connector)) {
        return MetaMTPConnectorTexture;
    }

    return MetaMTPConnectorTexture;
}

export const getConnectorTypeString = (connectorType: string) => {
    switch (connectorType) {
        case MMC16F_NP:
        case MMC24F_NP:
            return MMCTypeString;
        case MMC16F_P:
        case MMC24F_P:
            return `${MMCTypeString}_${PinnedString}`;
        case MTP:
        case MTP8F_P:
        case MTP12F_P:
        case MPO12F_P:
        case MTP12F_FP_P:
        case MTP_IMGPATH:
            return `${MTPTypeString}_${PinnedString}`;
        case MTP8F_NP:
        case MTP12F_NP:
        case MTP12F_FP_NP:
        case MPO12F_NP:
            return MTPTypeString;
        case MTP24F_P:
        case MTP24F_FP_P:
        case MTP24F_IMGPATH:
            return `${MTP24TypeString}_${PinnedString}`;
        case MTP24F_NP:
        case MTP24F_FP_NP:
            return MTP24TypeString;
        case SC_APC_SIMPLEX:
            return SCSimplexTypeString;
        case LC:
        case LC_IMGPATH:
            return LCUnibootTypeString;
        case LC_DUPLEX:
            return LCDuplexTypeString;
        case LC_SIMPLEX:
            return LCSimplexTypeString;
        default:
            return "Unknown";
    }
};

export const getConnectorDefaultColor = (connectorType?: string) => {
    switch (connectorType) {
        case MMC16F_NP:
        case MMC24F_NP:
        case MMC16F_P:
        case MMC24F_P:
        case MTP:
        case MTP8F_NP:
        case MTP8F_P:
        case MTP12F_NP:
        case MTP12F_P:
        case MTP12F_FP_NP:
        case MTP12F_FP_P:
        case MTP_IMGPATH:
            return Green;
        case MPO12F_NP:
        case MPO12F_P:
            return Aqua;
        case MTP24F_NP:
        case MTP24F_P:
        case MTP24F_FP_NP:
        case MTP24F_FP_P:
        case MTP24F_IMGPATH:
            return Mustard;
        case SC_APC_SIMPLEX:
        case LC:
        case LC_DUPLEX:
        case LC_SIMPLEX:
        case LC_IMGPATH:
        default:
            return Blue;
    }
};

export const getConnectorTypeColors = (connectorType: string): IColor[] => {
    switch (connectorType) {
        case MTP:
        case MTP8F_NP:
        case MTP8F_P:
        case MTP12F_NP:
        case MTP12F_P:
        case MTP12F_FP_NP:
        case MTP12F_FP_P:
        case MTP24F_NP:
        case MTP24F_P:
        case MTP24F_FP_NP:
        case MTP24F_FP_P:
            return MTPColors;
        default:
            return FiberColors;
    }
};

export const getConnectorColor = (connectorType: string, color: string) => {
    const colors = getConnectorTypeColors(connectorType);
    return colors.find((c) => color === c.name) || getConnectorDefaultColor(connectorType);
};

export function getMTPBootColor(connectorType: string) {
    return isMTP24(connectorType) ? MTP24BootColor : MTPBootColor;
}

export const isSCSimplex = (connectorType: string): boolean => {
    return connectorType.includes(SCSimplexTypeString);
};

export const isSC = (connectorType: string): boolean => {
    return isSCSimplex(connectorType);
}

export const isLCUniboot = (connectorType: string): boolean => {
    return connectorType.includes(LCUnibootTypeString);
};

export const isLCDuplex = (connectorType: string): boolean => {
    return connectorType.includes(LCDuplexTypeString);
};

export const isLCSimplex = (connectorType: string): boolean => {
    return connectorType.includes(LCSimplexTypeString);
};

export const isSimplex = (connectorType: string): boolean => {
    return isLCSimplex(connectorType) || isSCSimplex(connectorType);
}

export const isMTP = (connectorType: string) => {
    return connectorType.includes(MTPTypeString) || connectorType.includes(MPOTypeString);
};

export const isMPO = (connectorType: string) => {
    return connectorType.includes(MPOTypeString);
};

export const isMTP24 = (connectorType: string) => {
    return connectorType.includes(MTP24TypeString);
};

export const isMMC = (connectorType: string) => {
    return connectorType.includes(MMCTypeString);
};

export const isMMC16 = (connectorType: string) => {
    return connectorType === MMC16F_NP || connectorType === MMC16F_P;
};

export const isMMC24 = (connectorType: string) => {
    return connectorType === MMC24F_P || connectorType === MMC24F_NP;
};

export const isPinned = (connectorType: string) => {
    return connectorType.length > 0 ? connectorType.includes(PinnedString) : false;
};

export function getConnnectorSVG(connectorType: string, hex: string, scale?: number) {
    if (isMTP(connectorType)) {
        return MTPSVG(hex, getMTPBootColor(connectorType), isPinned(connectorType), scale);
    } else if (isMMC(connectorType)) {
        return MMCSVG(hex, Black.hex, isPinned(connectorType), scale);
    } else if (isSCSimplex(connectorType)) {
        return SCSimplexSVG(hex, Black.hex, scale);
    } else if (isLCDuplex(connectorType)) {
        return LCDuplexSVG(hex, Yellow.hex, scale);
    } else if (isLCSimplex(connectorType)) {
        return LCSimplexSVG(hex, Blue.hex, scale);
    } else {
        return LCUnibootSVG(hex, scale, Blue.hex);
    }
}

import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { useSelector, useDispatch } from "react-redux";
import { showFiberMappingSelector } from "../../../../store/overlay/overlay.selectors";
import { ChangeEvent, Dispatch, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { setShowConnectorAssignment, setShowFiberMapping } from "../../../../store/overlay/overlay.reducers";
import { IDialogHeaderProps } from "../../../../models/ui/dialog/dialog-header";
import { ICollapsibleDialogProps } from "../../../../models/ui/dialog/collapsible-dialog";
import { IGenericDialogProps } from "../../../../models/ui/dialog/generic-dialog";
import {
    FiberMappingReducer,
    setFeederItem,
    setFeederNavigation,
    setTAPConnectorsItem,
    setTAPConnectorsNavigation,
    setTAPItem,
    setTAPNavigation,
    setSelectedPinIndex,
    resetNavigation,
    clearFiberMap,
    setFiberMapping,
} from "../../../../store/overlay/polarity/fiber-mapping-save/fiber-mapping-save.reducer";
import { IFiberMappingContext, initialFiberMappingState, IFiberMappingNavigation, IFiberMap } from "../../../../store/overlay/polarity/fiber-mapping-save/fiber-mapping-save";
import { IConnectorAssignmentData, PolarityContext } from "../../../../store/overlay/polarity/polarity";
import { ButtonProps, SelectProps } from "@corning-ctcm/silica-react";
import { Action } from "@reduxjs/toolkit";
import { indexedBuildConnectorIdsSelector } from "../../../../store/workspace/build.selectors";
import { IHighlight } from "../../../../store/pixi/connector-highlights/connector-highlights";
import { setHighlights } from "../../../../store/pixi/connector-highlights/connector-highlights.reducers";
import { fiberMapsSelector } from "../../../../store/workspace/build/connector/polarity/polarity.selectors";
import { ConnLC, getConnectorTypeFromConnectorData } from "../../../../store/overlay/wizard/wizard";

export const useFiberMappingDialog = () => {
    const display = useSelector(showFiberMappingSelector);
    const [localState, localDispatch] = useReducer(FiberMappingReducer, initialFiberMappingState);
    const {
        navigation
    } = localState;
    const fiberMappingContext: IFiberMappingContext = { state: localState, dispatch: localDispatch };
    const { 
        state: { 
            fromConnectorId,
            connectorAssignment
        }, 
        dispatch: polarityDispatch
    } = useContext(PolarityContext);
    
    const { t } = useTranslation();
    const storeDispatch = useDispatch();

    useEffect(() => {
        if (!display) {
            localDispatch(clearFiberMap());
            localDispatch(resetNavigation());
        }
    }, [display, localDispatch, storeDispatch, polarityDispatch]);

    const headerProps: IDialogHeaderProps = {
        title: t(LocalizationKeys.FiberPosition),
        collapsible: true,
    };

    const dialog: ICollapsibleDialogProps = {
        id: "fiber-mapping",
        display,
        className: "fiber-mapping-dialog",
        headerProps,
    };

    useHighlights(display, connectorAssignment);
    const { fiberMapName } = useFiberMappingData(connectorAssignment, localDispatch);

    const clearAll = useClearAll(localDispatch);
    const data = useConnectorAssignment(
        fromConnectorId, 
        connectorAssignment,
        navigation,
        localDispatch
    );
    const actions = useActions(
        navigation.selectedPinIndex,
        display,
        localDispatch
    );

    return {
        dialog,
        fiberMapName,
        clearAll,
        fiberMappingContext,
        data,
        actions,
    };
};

const useHighlights = (
    display: boolean,
    connectorAssignment: IConnectorAssignmentData,
) => {
    const storeDispatch = useDispatch();

    useEffect(() => {
        if (display) {
            const connectors = [...connectorAssignment.feederConnectors, ...connectorAssignment.distributionConnectors];
            const connectorIds = connectors.map(c => c.id).filter((id): id is number => id !== undefined);
            const highlights: IHighlight[] = connectorIds.map(id => ({ connectorId: id, assigned: true, selected: true }));    
            if (highlights.length > 0) {
                storeDispatch(setHighlights(highlights));
            }
        }
    }, [display, connectorAssignment, storeDispatch]);
}

const useFiberMappingData = (
    {
        fiberMapId,
        feederConnectors,
        distributionConnectors
    }: IConnectorAssignmentData,
    localDispatch: Dispatch<Action>
) => {
    const fiberMaps = useSelector(fiberMapsSelector);
    const fiberMap = fiberMaps.find(m => m.key === fiberMapId);

    useEffect(() => {
        if (fiberMap) {
            const { 
                sourceIndices,
                destinationIndices
            } = fiberMap;
            const mapping: IFiberMap[] = [];
            for (const distributionPin of destinationIndices) {
                if (distributionPin.assignedIndex > -1) {
                    const distributionConnector = distributionConnectors[distributionPin.connector.index];
                    const feederPin = sourceIndices[distributionPin.assignedIndex];
                    const feederConnector = feederConnectors[feederPin.connector.index];
                    if (feederConnector?.id && distributionConnector?.id) {
                        mapping.push({
                            feederPin: { 
                                index: feederPin.index,
                                connectorId: feederConnector.id
                            },
                            distributionPin: {
                                index: distributionPin.index,
                                connectorId: distributionConnector.id
                            }
                        });
                    }
                }
            }
            localDispatch(setFiberMapping(mapping));
        }
    }, [
        fiberMap,
        feederConnectors,
        distributionConnectors,
        localDispatch
    ]);

    return {
        fiberMapName: fiberMap?.name
    }
}

const useClearAll = (localDispatch: Dispatch<Action>) => {
    const [showClearDialog, setShowClearDialog] = useState(false);
    const { t } = useTranslation();

    const onClearAllClick = useCallback(() => {
        setShowClearDialog(true);
    }, []);

    const clearAllButtonProps: ButtonProps = {
        id: "fiber-mapping-clear-button",
        className: "clear-button",
        palette: "error",
        disabled: true,
        onClick: onClearAllClick
    };

    const onClearClose = useCallback(() => {
        setShowClearDialog(false);
    }, []);

    const onClearConfirm = useCallback(() => {
        localDispatch(clearFiberMap());
        onClearClose();
    }, [localDispatch, onClearClose]);

    const clearAllDialogProps: IGenericDialogProps = {
        id: "clear-fiber-mapping",
        title: t(LocalizationKeys.ClearFiberMapping),
        display: showClearDialog,
        message: t(LocalizationKeys.ClearFiberMappingMessage),
        onClose: onClearClose,
        confirmText: t(LocalizationKeys.Clear),
        onConfirm: onClearConfirm,
        closable: true,
        critical: true,
    };

    return {
        button: clearAllButtonProps,
        label: t(LocalizationKeys.ClearAll),
        dialog: clearAllDialogProps
    }
}

const useConnectorAssignment = (
    fromConnectorId: number,
    connectorAssignment: IConnectorAssignmentData,
    navigation: IFiberMappingNavigation,
    localDispatch: Dispatch<Action>
) => {
    const buildConnectors = useSelector(indexedBuildConnectorIdsSelector);
    const { t } = useTranslation();

    const feederConnectorType = connectorAssignment.feederConnectors.length > 0 
        ? getConnectorTypeFromConnectorData(connectorAssignment.feederConnectors[0]) 
        : ConnLC;
    const distributionConnectorType = connectorAssignment.distributionConnectors.length > 0 
        ? getConnectorTypeFromConnectorData(connectorAssignment.distributionConnectors[0])
        : ConnLC;

    const {
        assignmentFeederConnectors,
        distributionPositions,
        assignmentDistributionConnectors
    } = useMemo(() => {
        const assignmentFeederConnectors = buildConnectors.feederConnectors
            .filter(({ connectorId }) => connectorAssignment.feederConnectors.some(c => c.id === connectorId));
        if (assignmentFeederConnectors.length > 0) {
            const assignmentFeederConnector = assignmentFeederConnectors[0];
            let feederConnectorId = assignmentFeederConnector.connectorId;
            if (fromConnectorId > -1) {
                feederConnectorId = fromConnectorId;
            }
            localDispatch(setFeederItem(feederConnectorId));
            localDispatch(setFeederNavigation(assignmentFeederConnectors.map(c => c.connectorId)));
        }

        const assignmentDistributionConnectors = buildConnectors.distributionConnectors
            .filter(({ connectorId }) => connectorAssignment.distributionConnectors.some(c => c.id === connectorId));
        const distributionPositions = assignmentDistributionConnectors.map(c => c.tapPosition);
        if (assignmentDistributionConnectors.length > 0) {
            const distributionConnector = assignmentDistributionConnectors[0];
            localDispatch(setTAPNavigation(distributionPositions));
            localDispatch(setTAPItem(distributionConnector.tapPosition));
            localDispatch(setTAPConnectorsNavigation(assignmentDistributionConnectors.map(c => c.connectorId)));
            localDispatch(setTAPConnectorsItem(distributionConnector.connectorId));
        }

        return {
            assignmentFeederConnectors,
            distributionPositions,
            assignmentDistributionConnectors
        }
    }, [
        buildConnectors,
        connectorAssignment.feederConnectors,
        fromConnectorId,
        connectorAssignment.distributionConnectors,
        localDispatch
    ]);
    
    const onFeederConnectorChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const connectorId = Number(e.currentTarget.value);
        localDispatch(setFeederItem(connectorId));
    }, [localDispatch]);

    const feederSelectProps: SelectProps = {
        id: "feeder-connector-dropdown",
        className: "selection-dropdown source",
        palette: "primary",
        value: navigation.feeder.currentItem,
        onChange: onFeederConnectorChange
    }

    const feeder = {
        select: feederSelectProps,
        options: assignmentFeederConnectors.map(c => ({
            value: c.connectorId,
            label: t(LocalizationKeys.FeederConnector, { connectorIndex: c.index + 1 })
        }))
    }

    const onDistributionChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const tapIndex = Number(e.currentTarget.value);
        localDispatch(setTAPItem(tapIndex));
        localDispatch(setTAPConnectorsItem(0));
    }, [localDispatch]);

    const distributionDropsSelectProps: SelectProps = {
        id: "distribution-drops-dropdown",
        className: "selection-dropdown tap",
        palette: "primary",
        value: navigation.distribution.taps.currentItem,
        onChange: onDistributionChange
    }

    const distributionDropsSelect = {
        select: distributionDropsSelectProps,
        options: distributionPositions.map(p => ({
            value: p,
            label: t(LocalizationKeys.TapNumber, { tapNumber: p + 1 })
        }))
    }

    const onDistributionConnectorChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const connectorId = Number(e.currentTarget.value);
        localDispatch(setTAPConnectorsItem(connectorId));
    }, [localDispatch]);

    const distributionConnectorsSelectProps: SelectProps = {
        id: "distribution-connectors-dropdown",
        className: "selection-dropdown tap",
        palette: "primary",
        value: navigation.distribution.connectors.currentItem,
        onChange: onDistributionConnectorChange
    }

    const distributionConnectorsSelect = {
        select: distributionConnectorsSelectProps,
        options: assignmentDistributionConnectors.map(c => ({
            value: c.connectorId,
            label: t(LocalizationKeys.ConnectorNumber, { connectorIndex: c.index + 1 })
        }))
    }
    
    return {
        polarityType: "",
        feederConnectorType,
        distributionConnectorType,
        feeder,
        distribution: {
            drop: distributionDropsSelect,
            connectors: distributionConnectorsSelect
        }
    }
}

const useActions = (
    selectedPinIndex: number,
    open: boolean,
    localDispatch: Dispatch<Action>
) => {
    const { t } = useTranslation();
    const storeDispatch = useDispatch();

    const onKeyPress = useCallback((e: any) => {
        if (e.keyCode === 27) {
            if (selectedPinIndex !== -1) {
                localDispatch(setSelectedPinIndex(-1));
            }
        }
    }, [selectedPinIndex, localDispatch]);

    useEffect(() => {
        if (open) {
            document.addEventListener("keydown", onKeyPress, false);
        }
        return () => {
            if (open) {
                document.removeEventListener("keydown", onKeyPress, false);
            }
        };
    }, [open, onKeyPress]);

    const onBack = useCallback(() => {
        storeDispatch(setShowConnectorAssignment(true));
        storeDispatch(setShowFiberMapping(false));
    }, [storeDispatch]);

    const backButtonProps: ButtonProps = {
        id: "fiber-mapping-back-button",
        className: "back-button",
        palette: "primary",
        onClick: onBack
    };

    return {
        back: {
            button: backButtonProps,
            label: t(LocalizationKeys.Back)
        }
    }
}
import { IDialogHeaderProps } from "../../../../models/ui/dialog/dialog-header";
import { useCallback, useContext } from "react";
import { collapseDialog, DialogContext } from "../../../../store/workspace/dialog/dialog.reducer";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { IconButtonProps } from "@corning-ctcm/silica-react";

export const useDialogHeader = (props: IDialogHeaderProps) => {
    const { id, icon, title, collapsible, closable, onClose } = props;
    const { state, dispatch } = useContext(DialogContext);
    const { collapsed } = state.props;
    const { t } = useTranslation();
    const collapseTooltip = collapsed ? t(LocalizationKeys.Expand) : t(LocalizationKeys.Collapse);
    const onCollapse = useCallback(() => {
        if (dispatch) {
            dispatch(collapseDialog(!collapsed));
        }
    }, [dispatch, collapsed]);
    const closeTooltip = t(LocalizationKeys.Close);

    const collapseProps: Omit<IconButtonProps, "icon"> = {
        id: `${id}-collapse-icon`,
        className: "collapse-button",
        palette: "primary",
        mode: "main",
        title: collapseTooltip,
        placement: "bottom",
        onClick: onCollapse,
    };

    const closeProps: Omit<IconButtonProps, "icon"> = {
        id: `${id}-close-icon`,
        palette: "primary",
        mode: "main",
        title: closeTooltip,
        placement: "bottom",
        onClick: onClose,
    };

    return { id, icon, title, collapsible, collapsed, closable, collapseProps, closeProps };
};

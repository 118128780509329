import { useContext } from "react";
import { useSelector } from "react-redux";
import { DEFAULT_TOLERANCE } from "../../../../models/overlay/header/tolerances/tolerances";
import {
    unitsOfMeasureContainerUnitSelector,
} from "../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import {
    convertToleranceToDisplay,
    Units,
} from "../../../../models/overlay/header/units-of-measure";
import {
    currentMaskLengthsSelector,
    currentMeasurementsPositionSelector,
    currentOverallLengthTypeSelector,
    overallLengthMarkerTextSelector,
    overallLengthToleranceSelector,
} from "../../../../store/workspace/build.selectors";
import { getConnectorTexture } from "../../factories/texture";
import { IPoint, IPointEmpty } from "../../../../models/pixi/pixi";
import { ICurvedLengthProps } from "../../../../models/pixi/decorators/dimension-lines/curved-length";
import { ILengthProps } from "../../../../models/pixi/decorators/dimension-lines/length";
import {
    IBezierLineMarkerProps,
    ILineMarkerProps,
} from "../../../../models/pixi/decorators/dimension-lines/line-marker";
import { DimensionLineContext, DIMENSION_LINE_START_OFFSET, IPath } from "../../../../models/pixi/decorators/dimension-lines/dimension-lines";
import { formatTolerance } from "../../../../models/pixi/decorators/text";
import { DropProps, OverallLengthContainer } from "../../../../models/pixi/build/build";
import { BEND_RADIUS } from "../../../../models/pixi/build/cable";
import { STAGGER_OFFSET_Y } from "../../../../models/pixi/build/connectors/connector-furcation";
import { AP_BORDER_WIDTH, AP_SIZE } from "../../../../models/pixi/build/drop-base/access-point";
import { OverallLengthProps } from "../../../../models/pixi/build/overall-length";

export const useOverallLength = ({ drops }: OverallLengthProps) => {
    const overallLengthType = useSelector(currentOverallLengthTypeSelector);

    const overallLengthFurcationProps: ILengthProps = useOverallLengthFurcation(drops);
    const overallLengthConnectorProps: ICurvedLengthProps = useOverallLengthConnector(drops);

    return { overallLengthConnectorProps, overallLengthFurcationProps, overallLengthType };
};

const useOverallLengthFurcation = (drops: DropProps[]) => {
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const overallLengthTolerance = useSelector(overallLengthToleranceSelector);
    const measurementsPosition = useSelector(currentMeasurementsPositionSelector);
    const maskLengths = useSelector(currentMaskLengthsSelector);
    const { primary, secondary } = useSelector(overallLengthMarkerTextSelector);
    const { thickness, color: markerColor } = useContext(DimensionLineContext);

    const [feeder, ...distribution] = drops;
    const lastDistribution: DropProps | undefined =
        distribution.length > 0 ? distribution[distribution.length - 1] : undefined;
    const lastPosition: IPoint = lastDistribution ? { x: lastDistribution.x, y: lastDistribution.y } : IPointEmpty;
    const lastShell = lastDistribution?.context.shell ?? "soft";

    const apOffset = AP_SIZE.width * 0.5;
    const x1 = measurementsPosition === "interior" ? feeder.x + apOffset : feeder.x - apOffset;
    let x2 = lastPosition.x;
    if (lastShell === "soft") {
        x2 = measurementsPosition === "interior" ? lastPosition.x - apOffset : lastPosition.x + apOffset;
    }

    const y1 = drops.length > 2 ? feeder.y - AP_SIZE.height * 3 : feeder.y - AP_SIZE.height * 2;

    const length = x2 - x1;
    const leftExtentionLine: IPath = {
        start: {
            x: x1,
            y: y1 + DIMENSION_LINE_START_OFFSET,
        },
        end: {
            x: x1,
            y: y1 - DIMENSION_LINE_START_OFFSET,
        },
    };

    const tolerance = convertToleranceToDisplay(overallLengthTolerance, Units.UoMInches, unit);
    const defaultTolerance = convertToleranceToDisplay(DEFAULT_TOLERANCE, Units.UoMInches, unit);
    const dimensionLine: IPath = {
        start: {
            x: x1 + AP_BORDER_WIDTH * 1.5,
            y: y1,
        },
        end: {
            x: x1 + length - AP_BORDER_WIDTH * 1.5,
            y: y1,
        },
        text: {
            primary,
            secondary,
            position: "middle",
            tolerance: {
                min: formatTolerance(tolerance.min),
                max: formatTolerance(tolerance.max),
                minColor: tolerance.min === defaultTolerance.min || isNaN(tolerance.min) ? undefined : 0xe11725,
                maxColor: tolerance.max === defaultTolerance.max || isNaN(tolerance.max) ? undefined : 0xe11725,
            },
        },
    };

    const rightExtensionLine: IPath = {
        start: {
            x: x1 + length,
            y: y1 + DIMENSION_LINE_START_OFFSET,
        },
        end: {
            x: x1 + length,
            y: y1 - DIMENSION_LINE_START_OFFSET,
        },
    };

    const start: ILineMarkerProps = {
        path: leftExtentionLine,
        orientation: "vertical",
        thickness,
        color: markerColor,
    };

    const line: ILineMarkerProps = {
        path: dimensionLine,
        orientation: "horizontal",
        direction: "bidirectional",
        thickness,
        color: markerColor,
        hideUnits: maskLengths,
        maskLengths
    };

    const end: ILineMarkerProps = {
        path: rightExtensionLine,
        orientation: "vertical",
        thickness,
        color: markerColor,
    };

    const overallLengthFurcationProps: ILengthProps = {
        name: OverallLengthContainer,
        position: 0,
        start,
        line,
        end,
    };

    return overallLengthFurcationProps;
};

const useOverallLengthConnector = (drops: DropProps[]) => {
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const overallLengthTolerance = useSelector(overallLengthToleranceSelector);
    const maskLengths = useSelector(currentMaskLengthsSelector);
    const { primary, secondary } = useSelector(overallLengthMarkerTextSelector);
    const { thickness, color: markerColor } = useContext(DimensionLineContext);

    const [feeder, ...distribution] = drops;
    const lastDrop = distribution[distribution.length - 1];

    // Points labeled left to right, top to bottom.
    // X Values:
    // x1 Left vertical segment, start of left curve
    // x2 End of left curve, start of full length horizontal segment
    // x3 End of horizontal segment, start of right curve
    // x4 End of right curve, right vertical segment
    // Y Values:
    // y1 Long horizontal line and start of both curves
    // y2 End of curves, start of vertical segments
    // y3 End of vertical segments (can be different on left and right depending on how many groups are in feeder vs longest distribution)

    const x2 = feeder.x + Math.min(...feeder.legProps.map((leg) => leg.location.x));
    let x3 = lastDrop.x + Math.max(...lastDrop.legProps.map((l) => l.location.x));
    const y1 = drops.length > 2 ? feeder.y - AP_SIZE.height * 3 : feeder.y - AP_SIZE.height * 2;
    const x1 = x2 - BEND_RADIUS;
    const x4 = x3 + BEND_RADIUS;
    const y2 = y1 + BEND_RADIUS;
    const { height: connectorHeight } = getConnectorTexture(feeder.legProps[0].connectorType ?? "");
    const y3Left =
        feeder.y +
        Math.max(...feeder.legProps.map((l) => l.location.y)) +
        STAGGER_OFFSET_Y * Math.max(...feeder.legProps.map((l) => l.staggerPosition)) +
        connectorHeight;
    const y3Right =
        Math.max(...distribution.map((d) => d.y)) +
        Math.max(...distribution.flatMap((d) => d.legProps.map((l) => l.location.y))) +
        STAGGER_OFFSET_Y * Math.max(...distribution.flatMap((d) => d.legProps.map((l) => l.staggerPosition))) +
        connectorHeight;
    const tolerance = convertToleranceToDisplay(overallLengthTolerance, Units.UoMInches, unit);
    const defaultTolerance = convertToleranceToDisplay(DEFAULT_TOLERANCE, Units.UoMInches, unit);
    const dimensionLine: IPath = {
        start: {
            x: x2,
            y: y1,
        },
        end: {
            x: x3,
            y: y1,
        },
        text: {
            primary,
            secondary,
            position: "middle",
            tolerance: {
                min: formatTolerance(tolerance.min),
                max: formatTolerance(tolerance.max),
                minColor: tolerance.min === defaultTolerance.min || isNaN(tolerance.min) ? undefined : 0xe11725,
                maxColor: tolerance.max === defaultTolerance.max || isNaN(tolerance.max) ? undefined : 0xe11725,
            },
        },
    };

    const start: ILineMarkerProps = {
        path: {
            start: {
                x: x1 + DIMENSION_LINE_START_OFFSET,
                y: y3Left,
            },
            end: {
                x: x1 - DIMENSION_LINE_START_OFFSET,
                y: y3Left,
            },
        },
        orientation: "vertical",
        thickness,
        color: markerColor,
    };

    const left: ILineMarkerProps = {
        path: {
            start: {
                x: x1,
                y: y3Left,
            },
            end: {
                x: x1,
                y: y2,
            },
        },
        orientation: "vertical",
        direction: "end",
        thickness,
        color: markerColor,
    };

    const leftCurve: IBezierLineMarkerProps = {
        path: {
            start: {
                x: x1,
                y: y2,
            },
            control: {
                x: x1,
                y: y1,
            },
            end: {
                x: x2,
                y: y1,
            },
        },
        orientation: "vertical",
        direction: "end",
        thickness,
        color: markerColor,
    };

    const line: ILineMarkerProps = {
        path: dimensionLine,
        orientation: "horizontal",
        direction: "none",
        thickness,
        color: markerColor,
        hideUnits: maskLengths,
        maskLengths
    };

    const rightCurve: IBezierLineMarkerProps = {
        path: {
            start: {
                x: x4,
                y: y2,
            },
            control: {
                x: x4,
                y: y1,
            },
            end: {
                x: x3,
                y: y1,
            },
        },
        orientation: "vertical",
        direction: "end",
        thickness,
        color: markerColor,
    };

    const right: ILineMarkerProps = {
        path: {
            start: {
                x: x4,
                y: y3Right,
            },
            end: {
                x: x4,
                y: y2,
            },
        },
        orientation: "vertical",
        direction: "end",
        thickness,
        color: markerColor,
    };

    const end: ILineMarkerProps = {
        path: {
            start: {
                x: x4 + DIMENSION_LINE_START_OFFSET,
                y: y3Right,
            },
            end: {
                x: x4 - DIMENSION_LINE_START_OFFSET,
                y: y3Right,
            },
        },
        orientation: "vertical",
        thickness,
        color: markerColor,
    };

    const overallLengthConnectorProps: ICurvedLengthProps = {
        name: OverallLengthContainer,
        position: 0,
        start,
        left,
        leftCurve,
        line,
        rightCurve,
        right,
        end,
    };

    return overallLengthConnectorProps;
};
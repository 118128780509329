import { IEditToolbarState } from "./edit-toolbar";
import { PayloadAction } from "@reduxjs/toolkit";

export const setToolbarDisplayReducer = (state: IEditToolbarState, action: PayloadAction<boolean>) => {
    state.display = action.payload;
};

export const setHoveredPositionReducer = (state: IEditToolbarState, action: PayloadAction<number>) => {
    state.selection.hovered = action.payload;
};

export const setSelectedPositionReducer = (state: IEditToolbarState, action: PayloadAction<number>) => {
    state.selection.selected = action.payload;
};

export const setSideReducer = (state: IEditToolbarState, action: PayloadAction<string | undefined>) => {
    state.selection.side = action.payload;
};

export const setSelectionReducer = (
    state: IEditToolbarState,
    action: PayloadAction<{ position: number; side?: string }>
) => {
    const { position, side } = action.payload;
    state.selection.hovered = position;
    state.selection.selected = position;
    state.selection.side = side;
    state.display = position > -1;
};

export const clearSelectionReducer = (state: IEditToolbarState) => {
    state.selection.hovered = -1;
    state.selection.selected = -1;
    state.selection.side = undefined;
    state.display = false;
};

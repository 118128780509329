import { Dispatch, SetStateAction } from "react";

export interface ProductLabel {
    id?: number;
    type: string;
    description: string;
    size?: string;
    oneBarcode: boolean;
    note: string;
}

export interface LabelsSectionProps {
    labels: ProductLabel[];
    setLabels: Dispatch<SetStateAction<ProductLabel[]>>;
}

export interface LabelFieldsProps {
    index: number;
    label: ProductLabel;
    labelsLength: number;
    setLabel: (index: number, newLabel: ProductLabel) => void;
}

export const initialProductLabel: ProductLabel = {
    type: "",
    description: "",
    oneBarcode: false,
    note: "",
};

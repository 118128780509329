import React from "react";
import { renderToString } from "react-dom/server";

export const MTPProSVG = (color: string, pinned: boolean, scale?: number) => {
    let width = 260;
    let height = 1000;
    if (scale) {
        width *= scale;
        height *= scale;
    }
    return (
        <svg
            version="1.1"
            id="Component_251_1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 26 100"
        >
            <polygon
                points="18,16 18,12 17,12 17,1 9,1 9,12 8,12 8,16 9,16 9,20 8,20 8,24 9,24 9,28 8,28 8,32 9,32 9,36 17,36 
            17,32 18,32 18,28 17,28 17,24 18,24 18,20 17,20 17,16 "
                fill="#484646"
                stroke="#484646"
            />
            <g id="Union_16" transform="translate(3682.001 1223)">
                <path
                    d="M-3661.9-1129.5h-14.2v-3.5v-0.5h-0.5h-2.8v-10.5v-0.5h-0.5h-1.7v-28.4l5.3-14.6h6.2h0.5v-0.5v-25.5v-0.5h-0.5
                h-3.5v-2v-0.1l0-0.1l-1.1-4l-0.1-0.4h-0.4h-0.4v-2h13v2h-0.4h-0.4l-0.1,0.4l-1.1,4l0,0.1v0.1v2h-3.5h-0.5v0.5v25.5v0.5h0.5h6.2
                l5.3,14.6v28.4h-1.7h-0.5v0.5v10.5h-2.8h-0.5v0.5V-1129.5z"
                    fill="#606060"
                />
                <path
                    d="M-3662.4-1130v-4h3.3v-11h2.2v-27.8l-5.1-14.2h-13.8l-5.1,14.2v27.8h2.2v11h3.2v4H-3662.4 M-3665-1214.5v-1.7
                l1.4-4.9h0.6v-1h-12v1h0.6l1.4,4.9v1.7H-3665 M-3661.4-1129h-15.2v-4h-3.2v-11h-2.2v-29l5.4-15h6.6v-25.5h-4v-2.5l-1.1-4h-0.9v-3
                h14v3h-0.9l-1.1,4v2.5h-4v25.5h6.6l5.4,15v29h-2.2v11h-3.3V-1129z"
                    fill="#484646"
                />
            </g>
            {pinned && (
                <>
                    <g id="Rectangle_1355" transform="translate(8.001 93)">
                        <rect x="0" width="3" height="7" />
                        <rect x="0.5" y="0.5" width="2" height="6" fill="none" stroke="#484646" />
                    </g>
                    <g id="Rectangle_1356" transform="translate(15.001 93)">
                        <rect x="0" width="3" height="7" />
                        <rect x="0.5" y="0.5" width="2" height="6" fill="none" stroke="#484646" />
                    </g>
                </>
            )}
            <g id="Union_17" transform="translate(3682.001 1279)">
                <path
                    d="M-3656.5-1199.5h-25v-18h10.5h0.5v-0.5v-5c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5v5v0.5h0.5h10.5
                V-1199.5z"
                    fill={color}
                />
                <path
                    d="M-3657-1200v-17h-11v-6c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v6h-11v17H-3657 M-3656-1199h-26v-19h11v-5
                c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2v5h11V-1199z"
                    fill="#484646"
                />
            </g>
            <path id="Path_466" d="M23,77H3" fill="none" stroke="#484646" />
            <g id="Group_1528" transform="translate(-27.999 -501)">
                <g id="Group_1520">
                    <g id="Rectangle_755" transform="translate(29 574)">
                        <rect x="0" width="2" height="2" fill="none" />
                        <rect x="0.5" y="0.5" width="1" height="1" fill="none" stroke="#484646" />
                    </g>
                    <g id="Rectangle_754" transform="translate(29 570)">
                        <rect x="0" width="2" height="2" fill="none" />
                        <rect x="0.5" y="0.5" width="1" height="1" fill="none" stroke="#484646" />
                    </g>
                    <g id="Rectangle_753" transform="translate(29 566)">
                        <rect x="0" width="2" height="2" fill="none" />
                        <rect x="0.5" y="0.5" width="1" height="1" fill="none" stroke="#484646" />
                    </g>
                </g>
                <rect id="Rectangle_628" x="31" y="564" width="1" height="14" fill="#484646" />
            </g>
            <g id="Group_1531" transform="translate(-27.999 -501)">
                <g id="Group_1518">
                    <g id="Rectangle_750" transform="translate(51 574)">
                        <rect x="0" width="2" height="2" fill="none" />
                        <rect x="0.5" y="0.5" width="1" height="1" fill="none" stroke="#484646" />
                    </g>
                    <g id="Rectangle_751" transform="translate(51 570)">
                        <rect x="0" width="2" height="2" fill="none" />
                        <rect x="0.5" y="0.5" width="1" height="1" fill="none" stroke="#484646" />
                    </g>
                    <g id="Rectangle_752" transform="translate(51 566)">
                        <rect x="0" width="2" height="2" fill="none" />
                        <rect x="0.5" y="0.5" width="1" height="1" fill="none" stroke="#484646" />
                    </g>
                </g>
                <rect id="Rectangle_629" x="50" y="564" width="1" height="14" fill="#484646" />
            </g>
            <rect id="Rectangle_1345" x="4" y="50" width="18" height="1" fill="#484646" />
            <rect id="Rectangle_1346" x="3" y="50" width="1" height="10.4" fill="#484646" />
            <rect id="Rectangle_1353" x="22" y="50" width="1" height="10.4" fill="#484646" />
            <rect id="Rectangle_1354" x="3" y="89" width="20" height="1" fill="#484646" />
        </svg>
    );
};

export const getMTPProSVGString = (color: string, pinned: boolean): string => {
    const mtpProSVG = MTPProSVG(color, pinned);
    return renderToString(mtpProSVG);
};

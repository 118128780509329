import { createSlice } from "@reduxjs/toolkit";
import { MutableRefObject } from "react";
import { createAsyncAction } from "../../workspace/workspace.actions";
import { addBuilds, setBuilds, setCurrentBuild, updateSpecificConnectors } from "../../workspace/build/build.reducers";
import { IBuildData } from "../../workspace/build/build";
import {
    addProjectAction,
    removeProjectAction,
    setProjectManagerAction,
    setProjectsAction,
    setRecentProjectAction,
    setRecentProjectIdAction,
} from "../../workspace/project-manager/project-manager.actions";
import { PAGE_SIZE } from "../../workspace/project-manager/project-manager";
import { ProjectManagerService } from "../../../services/project-manager.service";
import { createdLoadedBuild } from "../../../components/overlay/header/status/status.hooks";
import { LabelCustomizationOptions } from "../../../models/overlay/reports/label-scheme/label-scheme";
import {
    closeAllAction,
    setDrawerDisplayAction,
    setSelectedBuildAction,
    setSelectedBuildIdAction,
    setSortOrderAction,
    setSortTypeAction,
    setTotalBuildCountAction,
    showCableDetailsAction,
    showCableInfoAction,
} from "./project.actions";
import { initialState } from "./project";
import { IPropagationOptions } from "../../../store/overlay/polarity/propagation/propagation";
import { setNotification, setPropagationNotification } from "../../../store/overlay/notification/notification.reducers";
import { AlertPalettes } from "@corning-ctcm/silica-react";

const projectManagerSlice = createSlice({
    name: "projectManager",
    initialState,
    reducers: {
        setDrawerDisplay: setDrawerDisplayAction,
        setProjects: setProjectsAction,
        setTotalBuildCount: setTotalBuildCountAction,
        setRecentProject: setRecentProjectAction,
        setRecentProjectId: setRecentProjectIdAction,
        setProjectManager: setProjectManagerAction,
        removeProject: removeProjectAction,
        addProject: addProjectAction,
        showCableDetails: showCableDetailsAction,
        showCableInfo: showCableInfoAction,
        closeAll: closeAllAction,
        setSelectedBuild: setSelectedBuildAction,
        setSelectedBuildId: setSelectedBuildIdAction,
        setSortType: setSortTypeAction,
        setSortOrder: setSortOrderAction,
    },
});

export const ProjectManagerReducer = projectManagerSlice.reducer;
export const {
    setDrawerDisplay,
    setProjects,
    setRecentProject,
    setRecentProjectId,
    setProjectManager,
    removeProject,
    showCableDetails,
    showCableInfo,
    closeAll,
    setSelectedBuildId,
    setSelectedBuild,
    setSortType,
    setSortOrder,
    addProject,
    setTotalBuildCount,
} = projectManagerSlice.actions;

export const duplicateProject = (
    buildId: number,
    duplicateName: string,
    sscCallback: (build: IBuildData, applyDefaultLabels?: boolean, saveDefaultColors?: boolean) => Promise<void>,
    pageIndex: number,
    search?: string
) => {
    return createAsyncAction(async (dispatch) => {
        const service = new ProjectManagerService();
        const projectBuildResponse = await service.duplicateProject(buildId, duplicateName, pageIndex, search);
        if (!projectBuildResponse) return;
        const { build: duplicatedBuild, builds, totalBuildCount } = projectBuildResponse;
        if (duplicatedBuild) {
            const loadedBuild = createdLoadedBuild(duplicatedBuild);
            dispatch(setCurrentBuild(loadedBuild));
            dispatch(setBuilds(builds ?? []));
            dispatch(setTotalBuildCount(totalBuildCount ?? 0));
            sscCallback(loadedBuild);
        }
    });
};

export const deleteProject = (buildId: number, pageIndex: number, search?: string) => {
    return createAsyncAction(async (dispatch) => {
        const projectBuildResponse = await new ProjectManagerService().deleteBuild(buildId, pageIndex, search);
        if (projectBuildResponse) {
            const { build, builds, totalBuildCount } = projectBuildResponse;
            dispatch(setTotalBuildCount(totalBuildCount ?? 0));
            dispatch(setBuilds(builds ?? []));
            return build;
        }

        return undefined;
    });
};

export const updateSearch = (
    search: string,
    currentActiveRequest: MutableRefObject<ProjectManagerService | undefined>
) => {
    return createAsyncAction(async (dispatch) => {
        currentActiveRequest.current?.cancel(); // Cancel currently pending search request
        currentActiveRequest.current = new ProjectManagerService();

        const service = currentActiveRequest.current;

        const buildInfoList = await service.getBuildsForGroupByPage(0, search);
        if (buildInfoList) {
            dispatch(setBuilds(buildInfoList));
        }

        // Check if it is still the current pending request (i.e. didn't get cancelled)
        if (currentActiveRequest.current === service) {
            currentActiveRequest.current = undefined;
        }
    });
};

export const updateSortType = (sortType: string, pageIndex: number, search?: string) => {
    return createAsyncAction(async (dispatch) => {
        dispatch(setSortType(sortType));
        const service = new ProjectManagerService();
        const buildInfoList = await service.updateSortType(sortType, pageIndex, search);
        if (buildInfoList) {
            dispatch(setBuilds(buildInfoList));
        }
    });
};

export const updateSortOrder = (isSortAscending: boolean, pageIndex: number, search?: string) => {
    return createAsyncAction(async (dispatch) => {
        dispatch(setSortOrder(isSortAscending));
        const service = new ProjectManagerService();
        const buildInfoList = await service.updateSortOrder(isSortAscending, pageIndex, search);
        if (buildInfoList) {
            dispatch(setBuilds(buildInfoList));
        }
    });
};

export const applyLabelScheme = (
    buildId: number,
    schemeId: number,
    side: string,
    dropPosition: number,
    options: LabelCustomizationOptions,
    propagationOptions: IPropagationOptions
) => {
    return createAsyncAction(async (dispatch) => {
        const service = new ProjectManagerService();
        const labelSchemeResponse = await service.applyLabelScheme(
            buildId,
            schemeId,
            side,
            dropPosition,
            options,
            propagationOptions
        );
        if (labelSchemeResponse) {
            const { connectors, statusMessage, propagationResult } = labelSchemeResponse;
            if (connectors.length > 0) {
                const updatedConnectors = connectors;
                if (propagationResult) {
                    dispatch(setPropagationNotification(propagationResult));
                    updatedConnectors.push(...propagationResult.connectors);
                }
                dispatch(updateSpecificConnectors(updatedConnectors));
                dispatch(setNotification({ palette: AlertPalettes.info, message: statusMessage }));
            } else {
                dispatch(setNotification({ palette: AlertPalettes.error, message: statusMessage }));
            }
        }
    });
};

export const getProjectsPage = (page: number, search?: string) => {
    return createAsyncAction(async (dispatch) => {
        const service = new ProjectManagerService();
        const buildInfoList = await service.getBuildsForGroupByPage(page, search);
        let isLastPage = false;
        if (buildInfoList) {
            if (buildInfoList.length > 0) {
                dispatch(addBuilds(buildInfoList));
            }
            // A page that is not full indicates that it is the last page
            isLastPage = buildInfoList.length < PAGE_SIZE;
        }
        return isLastPage;
    });
};

import React from "react";
import { GenericSnackbar } from "../../../../ui/generic-snackbar/generic-snackbar";
import { useWarningSnackbar } from "./warning-snackbar.hooks";

export const WarningSnackbar = () => {
    const { type, open, message, onClose } = useWarningSnackbar();

    return (
        <GenericSnackbar
            id="warning-snackbar"
            type={type}
            open={open}
            message={message}
            hideCancelButton={true}
            hideActionButton={true}
            timeout={-1}
            onClose={onClose}
        />
    );
};

import { useReports } from "./reports.hooks";
import { ConnectorReportContext } from "../../../store/overlay/reports/reports.reducers";
import { ConnectorReport } from "./connector-report/connector-report";
import { LabelScheme } from "./label-scheme/label-scheme";
import { TriggerColorManagement } from "./trigger-color-management/trigger-color-management";
import { ReportSettings } from "./report-settings/report-settings";

export const Reports = () => {
    const { context } = useReports();
    return (
        <>
            <ConnectorReportContext.Provider value={context}>
                <ConnectorReport />
                <LabelScheme />
                <TriggerColorManagement />
            </ConnectorReportContext.Provider>
            <ReportSettings />
        </>
    );
};

export const FlameRatings = {
    Plenum: "Plenum",
    Riser: "RISER",
    PlenumOFNP: "PLENUM",
    RiserLSZH: "RISER-LSZH",
    LSZH: "LSZH",
} as const;

export type FlameRating = (typeof FlameRatings)[keyof typeof FlameRatings];

export interface IFlameRating {
    key: string;
    description: string;
    value: FlameRating;
    code: string;
}
export const PlenumLegacy: IFlameRating = { key: "0", description: "Plenum", value: FlameRatings.Plenum, code: "PN" };
export const RiserLegacy: IFlameRating = {
    key: "1",
    description: "Riser/LS, CPR",
    value: FlameRatings.Riser,
    code: "RZ",
};
export const PlenumOFNPLegacy: IFlameRating = {
    key: "2",
    description: "Plenum (OFNP)",
    value: FlameRatings.PlenumOFNP,
    code: "PN",
};
export const RiserLSZHLegacy: IFlameRating = {
    key: "3",
    description: "Riser-LSZH (OFNR-LS)",
    value: FlameRatings.RiserLSZH,
    code: "RZ",
};
export const LSZHLegacy: IFlameRating = { key: "4", description: "LSZH", value: FlameRatings.LSZH, code: "LZ" };

export const LegacyFlameRatingCodes: { [k: string]: string } = {};
LegacyFlameRatingCodes[PlenumLegacy.value] = PlenumLegacy.code;
LegacyFlameRatingCodes[RiserLegacy.value] = RiserLegacy.code;
LegacyFlameRatingCodes[PlenumOFNPLegacy.value] = PlenumOFNPLegacy.code;
LegacyFlameRatingCodes[RiserLSZHLegacy.value] = RiserLSZHLegacy.code;
LegacyFlameRatingCodes[LSZHLegacy.value] = LSZHLegacy.code;

export const LegacyFlameRatings: IFlameRating[] = [PlenumLegacy, RiserLegacy, PlenumOFNPLegacy, RiserLSZHLegacy];

export function getLegacyFlameRating(flameRating: string, sessionFlameRatings?: IFlameRating[]): IFlameRating {
    // Keep displaying LSZH flameRating
    const flameRatings = sessionFlameRatings ?? [];
    if (flameRating === LSZHLegacy.value) return LSZHLegacy;

    let res = LegacyFlameRatings.find((fr) => fr.value === flameRating);

    if (res) {
        if (res.value === PlenumLegacy.value || res.value === PlenumOFNPLegacy.value)
            return flameRatings.find((f) => f.key === "PLENUM") ?? PlenumOFNPLegacy;
        if (res.value === RiserLegacy.value || res.value === RiserLSZHLegacy.value)
            return flameRatings.find((f) => f.key === "RISER-LSZH") ?? RiserLSZHLegacy;
    } else if (flameRatings) {
        res = flameRatings.find((f) => f.value === flameRating);
    }

    return res ?? flameRatings.find((f) => f.key === "PLENUM") ?? PlenumOFNPLegacy;
}
export function getSscFlameRatingFromLegacy(flameRating: string, sessionFlameRatings: IFlameRating[]): IFlameRating {
    return (
        sessionFlameRatings.find((f) => flameRating === f.value || getFlameRatingSscValueId(flameRating) === f.value) ??
        sessionFlameRatings[0] ?? { code: "RISER", description: "Riser", key: "RISER", value: "RISER" }
    );
}
export function getFlameRatingSscValueId(flameRating: string): string {
    if (flameRating.length > 0) {
        if (flameRating.includes(FlameRatings.Plenum)) {
            return "PLENUM";
        } else if (flameRating.includes(FlameRatings.Riser)) {
            return "RISER-LSZH";
        }
    }
    return "RISER";
}

import axios, { AxiosError, AxiosInstance, AxiosResponse, CancelTokenSource, InternalAxiosRequestConfig } from "axios";
import { ProxyConfigHelper } from "../helpers/proxy-config.helper";
import { store } from "../store/workspace/workspace.reducers";
import { setNotification } from "../store/overlay/notification/notification.reducers";
import { AlertPalettes } from "@corning-ctcm/silica-react";

interface IHeader {
    name: string;
    value?: string;
    delegate?: () => string | null;
}

export enum WebServiceErrorHandlingBehavior {
    defaultNotifyOnError,
    rethrowError
}

export abstract class WebService {
    protected isUnstableApi: boolean = true;
    protected client: AxiosInstance;
    private headers: IHeader[] = [];
    private errorHandlingBehavior = WebServiceErrorHandlingBehavior.defaultNotifyOnError;
    private CancelToken = axios.CancelToken;
    private cancelTokenSource: CancelTokenSource;

    constructor() {
        const baseURL = ProxyConfigHelper.getEndpoint();
        this.cancelTokenSource = this.CancelToken.source();
        this.client = axios.create({ baseURL });
        this.client.interceptors.request.use((config) => this.setHeaders(config));
        this.cancelRequestHandler();
    }

    public setErrorHandlingBehavior(errorHandlingBehavior: WebServiceErrorHandlingBehavior): void {
        this.errorHandlingBehavior = errorHandlingBehavior;
    }

    protected async get<T = unknown>(url: string): Promise<T | undefined> {
        const req = this.client.get<T>(url);
        return await this.wrap(req);
    }

    protected async post<T = unknown>(url: string, data?: unknown): Promise<undefined | T> {
        const req = this.client.post<T>(url, data);
        return await this.wrap(req);
    }

    protected async put<T = unknown>(url: string, data: unknown): Promise<T | undefined> {
        const req = this.client.put<T>(url, data);
        return await this.wrap(req);
    }

    protected async patch<T = unknown>(url: string, data: unknown): Promise<T | undefined> {
        const req = this.client.post<T>(url, data);
        return await this.wrap(req);
    }

    protected async delete<T = unknown>(url: string, data?: unknown): Promise<T | undefined> {
        const req = this.client.delete<T>(url, { data });
        return await this.wrap(req);
    }

    public cancel = () => {
        this.cancelTokenSource.cancel();
    };

    public cancelRequestHandler() {
        // We can add listeners if needed
        window.addEventListener("beforeunload", () => {
            this.cancel();
        });
    }

    private async wrap<T>(call: Promise<AxiosResponse<T>>): Promise<T | undefined> {
        try {
            const result = await call;
            return result.data;
        }
        catch (err) {
            const _err = err as AxiosError;
            switch (this.errorHandlingBehavior) {
                case WebServiceErrorHandlingBehavior.defaultNotifyOnError:
                    if (_err?.response?.data) {
                        const responseData = _err.response.data;
                        if (responseData && Object.prototype.toString.call(responseData) === "[object String]") {
                            const message = `${responseData}`;
                            store.dispatch(setNotification({ palette: AlertPalettes.error, message }));
                        }
                    }
                    return;
                case WebServiceErrorHandlingBehavior.rethrowError:
                    throw _err;
            }
        }
    }

    protected addHeader(name: string, value: string): void {
        if (this.isHeaderAlreadyAdded(name)) {
            console.warn(`WebService.addHeader: Duplicate header name ${name}`);
            return;
        }
        this.headers.push({ name, value });
    }

    protected addDynamicHeader(name: string, delegate: () => string | null): void {
        if (this.isHeaderAlreadyAdded(name)) {
            console.warn(`WebService.addDynamicHeader: Duplicate header name ${name}`);
            return;
        }
        this.headers.push({ name, delegate });
    }

    private isHeaderAlreadyAdded(name: string): boolean {
        return this.headers.find((h) => h.name === name) !== undefined;
    }

    private setHeaders(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
        for (const header of this.headers) {
            const name = header.name;
            const value = header.value || (header.delegate && header.delegate());
            if (value === null) {
                console.debug(`WebService.setHeaders: Skipping header '${name}' since value is null`);
                continue;
            }
            console.debug(
                `WebService.setHeaders: Setting header N='${
                    header.name
                }', V='${value}', D=${!!header.delegate} for request '${config.url}'`
            );
            if (config.headers && value) config.headers[name] = value;
        }

        return config;
    }
}

import { BuildDTO } from "../../../models/services/services";
import { IBuildData, IBuildInfo } from "../build/build";

export interface IProjectIdData {
    id?: number;
    groupId?: string;
    projectId: number;
    editDisabled?: boolean;
    deleteDisabled?: boolean;
    rowClassName?: string;
}

export interface IProjectManagerData {
    recentProjectId?: number;
    projectIds?: number[];
    sortType?: string;
    isSortAscending?: boolean;
    builds?: IBuildInfo[];
    totalBuildCount?: number;
    editableConnectors?: boolean;
}

export interface IProjectBuildResponse {
    build?: BuildDTO;
    builds?: IBuildInfo[];
    totalBuildCount?: number;
}

export interface IParsedProjectBuildResponse {
    build?: IBuildData;
    builds?: IBuildInfo[];
    totalBuildCount?: number;
}

export const PAGE_SIZE = 10;

import React from "react";

import { IconButton } from "@corning-ctcm/silica-react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { ConnectorTemplateProps } from "../../../../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";
import { getConnectorTypeString, isMMC, isMTP, isSimplex } from "../../../../pixi/factories/texture";
import { LCTemplate } from "./fiber-mapping-connector-templates/lc-template";
import { MMCTemplate } from "./fiber-mapping-connector-templates/mmc-template";
import { MTPTemplate } from "./fiber-mapping-connector-templates/mtp-template";
import { SimplexTemplate } from "./fiber-mapping-connector-templates/simplex-template";
import { useFiberMappingConnector } from "./fiber-mapping-connector.hooks";

import "../../../../../css/overlay/polarity/fiber-mapping/fiber-mapping-connector.scss";

export const FiberMappingConnector: React.FC<ConnectorTemplateProps> = (props) => {
    const { type, template, previousProps, nextProps } = useFiberMappingConnector(props);
    const connectorTypeString = getConnectorTypeString(type);

    let connectorTemplate = <LCTemplate {...template} />;
    if (isMMC(connectorTypeString)) {
        connectorTemplate = <MMCTemplate {...template} />;
    } else if (isMTP(connectorTypeString)) {
        connectorTemplate = <MTPTemplate {...template} />;
    } else if (isSimplex(connectorTypeString)) {
        connectorTemplate = <SimplexTemplate {...template} />;
    }

    return (
        <div className="connector-container">
            <IconButton {...previousProps} icon={<ChevronLeft />} />
            {connectorTemplate}
            <IconButton {...nextProps} icon={<ChevronRight />} />
        </div>
    );
};

import { INavigationBarState } from "./navigation-bar";
import { PayloadAction } from "@reduxjs/toolkit";

export const setCurrentIndexAction = (state: INavigationBarState, action: PayloadAction<number>) => {
    state.currentIndex = action.payload;
};

export const setDisabledAction = (state: INavigationBarState, action: PayloadAction<boolean>) => {
    state.disabled = action.payload;
};

import { INavigationBarProps } from "../../../ui/navigation-bar";
import { IDrop } from "../../../../store/workspace/build/drop";
import { IConnectorAssignmentData } from "../../../../store/overlay/polarity/polarity";

export interface IConnectorAssignmentRow {
    buildId: number;
    side: string;
    dropPosition: number;
    groupPosition: number;
    connectorId: number;
    connectorIndex: number;
    connectorPosition: number;
    connectorType: string;
    fiberCount: number;
    fiberCountInUse: number;
    unassignedFibers: number;
    blockedFibers: number;
    assignment?: IConnectorAssignmentData
}

export const initialConnectorAssignmentRow: IConnectorAssignmentRow = {
    buildId: -1,
    side: "",
    dropPosition: -1,
    groupPosition: -1,
    connectorId: -1,
    connectorIndex: -1,
    connectorPosition: -1,
    connectorType: "",
    fiberCount: 0,
    fiberCountInUse: 0,
    unassignedFibers: 0,
    blockedFibers: 0
};

export interface ITAPNavigationMap {
    leftTAP?: IDrop;
    rightTAP?: IDrop;
    label: string;
}

export interface ITAPNavigationBarProps {
    navigationBarProps: INavigationBarProps;
    leftTAP?: IDrop;
    rightTAP?: IDrop;
    previousDisabled: boolean;
    nextDisabled: boolean;
}

export const PolarityAssignments = {
    Standard: "Standard (L to R)",
    Distributed: "Distributed",
};
export type PolarityAssignment = (typeof PolarityAssignments)[keyof typeof PolarityAssignments];

import React from "react";
import { Checkbox } from "@corning-ctcm/silica-react";
import classNames from "classnames";
import { CollapsibleDialog } from "../../../../ui/dialog/collapsible-dialog/collapsible-dialog";
import { WarningSeverity } from "../../../../../store/overlay/header/warnings/warnings";
import { useWarningsCard } from "./warnings-card.hooks";

import "../../../../../css/overlay/header/warnings-card.scss";

export const WarningsCard = () => {
    const { display, headerProps, errorsCheckboxProps, warningsCheckboxProps, filteredWarnings } = useWarningsCard();

    return (
        <CollapsibleDialog id="warnings" className="warnings-card" headerProps={headerProps} display={display}>
            <div className="filter-container">
                <div id="errors-checkbox">
                    <Checkbox palette="primary" {...errorsCheckboxProps} />
                </div>
                <div id="warnings-checkbox">
                    <Checkbox palette="primary" {...warningsCheckboxProps} />
                </div>
            </div>
            <ul className="warnings-list">
                {filteredWarnings.map((w, i) => (
                    <li
                        key={i}
                        className={classNames("warning-item", {
                            error: w.severity === WarningSeverity.Error,
                            warning: w.severity === WarningSeverity.Warning,
                        })}
                    >
                        <table>
                            <tbody>
                                <tr>
                                    <td className="warnings-icon-cell">
                                        <span className="material-icons">
                                            {w.severity === WarningSeverity.Error ? "cancel" : "warning"}
                                        </span>
                                    </td>
                                    <td className="problem-text">{w.problemText}</td>
                                </tr>
                                <tr>
                                    <td />
                                    <td className="solution-text">{w.solutionText}</td>
                                </tr>
                            </tbody>
                        </table>
                    </li>
                ))}
            </ul>
        </CollapsibleDialog>
    );
};

export interface IOverlayState {
    printSettings: IPrintSettings;
    showConnectorReport: boolean;
    showLabelScheme: boolean;
    showTriggerManagement: boolean;
    showPolarity: boolean;
    disablePolarity: boolean;
    showThirdPartyCopyright: boolean;
    showAboutApplication: boolean;
    showCablePresetSnackbar: boolean;
    showConnectorAssignment: boolean;
    showFiberMapping: boolean;
    showViewProduct: boolean;
    showMoreMenu: boolean;
    showIdle: boolean;
    showTimeout: boolean;
    showAnonymousUserWarning: boolean;
    showShellEdit: boolean;
    showAssemblyTypeEdit: boolean;
    showEditTolerances: boolean;
    showCollapseOptions: boolean;
    showSettings: boolean;
}

export interface IPrintSettings {
    showPrintDialog: boolean;
}

export interface SnackbarPayload {
    open: boolean;
    message: string;
}

export const initialSnackbarPayload: SnackbarPayload = {
    open: false,
    message: "",
};

export const initialPrintSettings: IPrintSettings = {
    showPrintDialog: false
};

export const initialOverlayState: IOverlayState = {
    printSettings: initialPrintSettings,
    showThirdPartyCopyright: false,
    showAboutApplication: false,
    showConnectorReport: false,
    showLabelScheme: false,
    showTriggerManagement: false,
    showPolarity: false,
    disablePolarity: true,
    showCablePresetSnackbar: false,
    showConnectorAssignment: false,
    showFiberMapping: false,
    showViewProduct: false,
    showMoreMenu: false,
    showIdle: false,
    showTimeout: false,
    showAnonymousUserWarning: false,
    showShellEdit: false,
    showAssemblyTypeEdit: false,
    showEditTolerances: false,
    showCollapseOptions: false,
    showSettings: false,
};

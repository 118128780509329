import {
    CircularProgressIndeterminate,
    ContainedButton,
    Tabs,
    TextButton,
    TextField,
} from "@corning-ctcm/silica-react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tab } from "@mui/material";
import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../locales/keys";
import { isLockedSelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";
import { DialogHeader } from "../../../ui/dialog/dialog-header/dialog-header";
import { DrawingSection } from "./drawing-section/drawing-section";
import { DropSubSection } from "./drop-settings/drop-settings";
import { FileUploadDialog } from "./file-upload-dialog";
import { FooterFields } from "./footer-fields/footer-fields";
import { LabelsSection } from "./labels-section/labels-section";
import { useReportSettings } from "./report-settings.hooks";
import { ReportTypeSection } from "./report-type-section";

import "../../../../css/overlay/reports/report-settings/report-settings.scss";

export const ReportSettings = () => {
    const {
        dialogProps,
        footerFields,
        drawingFields,
        diagramFields,
        showPrintDialog,
        dropSection,
        reportTypeSection,
        drawingNotes,
        labelsSection,
        headerProps,
        actions,
        setDrawingNotes,
        fileUploadDialog,
        isPrinting,
    } = useReportSettings();
    const { confirmText, disableConfirm, cancelText, onCancelClick, onConfirmClick } = actions;
    const locked = useSelector(isLockedSelector);
    const { t } = useTranslation();

    const [overviewSection, feederSection] = drawingFields;
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTabIndex(newValue);
    };
    // We are currently using the native MUI Dialog due to a bug with TextFields in the Silica component.
    // This should be changed once the bug is fixed in the library.
    // Refer to PLAT-8 for more details.
    return showPrintDialog ? (
        <div className={"dialog-container"}>
            <Dialog {...dialogProps}>
                <DialogTitle>
                    <DialogHeader {...headerProps} />
                </DialogTitle>
                <DialogContent>
                    <div className="report-content">
                        <Tabs value={currentTabIndex} onChange={handleTabChange} variant="fullWidth">
                            <Tab label={t(LocalizationKeys.Pages)} />
                            <Tab label={t(LocalizationKeys.Setup)} />
                            <Tab label={t(LocalizationKeys.Labels)} />
                            <Tab label={t(LocalizationKeys.Footer)} />
                        </Tabs>
                        <Divider />
                        {currentTabIndex === 0 && (
                            <div>
                                <ReportTypeSection {...reportTypeSection} diagramFields={diagramFields} />
                            </div>
                        )}
                        {currentTabIndex === 1 && (
                            <div className="drawing-panel">
                                <DrawingSection {...overviewSection} />
                                <DrawingSection {...feederSection} />
                                <DropSubSection {...dropSection} />
                                <div className="setup-notes">
                                    <TextField
                                        palette="primary"
                                        type="text"
                                        label={t(LocalizationKeys.Notes)}
                                        helperText={t(LocalizationKeys.ShownOnFirstPage)}
                                        value={drawingNotes}
                                        onChange={(e: { target: { value: SetStateAction<string> } }) =>
                                            setDrawingNotes(e.target.value)
                                        }
                                        maxLength={500}
                                        rows={3}
                                        multiline
                                        disabled={locked}
                                    />
                                </div>
                            </div>
                        )}
                        {currentTabIndex === 2 && (
                            <div>
                                <LabelsSection {...labelsSection} />
                            </div>
                        )}
                        {currentTabIndex === 3 && (
                            <div className="footer-panel">
                                <FooterFields fields={footerFields} />
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <TextButton onClick={onCancelClick}>{cancelText}</TextButton>
                    <ContainedButton
                        startIcon={isPrinting && <CircularProgressIndeterminate palette="primary" size="18px" />}
                        disabled={disableConfirm}
                        onClick={onConfirmClick}
                    >
                        {confirmText}
                    </ContainedButton>
                </DialogActions>
            </Dialog>
            <FileUploadDialog {...fileUploadDialog} />
        </div>
    ) : null;
};

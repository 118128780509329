import { useSelector } from "react-redux";
import { IBundleCountProps } from "../../../../../models/overlay/wizard/setup/bundle-count";
import {
    bundleCountOptionsSelector,
    bundleCountRangeSelector,
} from "../../../../../store/workspace/boundaries/boundaries.selectors";
import { useCallback, useState } from "react";
import { IAutocompleteProps } from "../../../../../models/ui/generic-autocomplete";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import { AutocompleteChangeReason } from "@mui/material";

export const useBundleCount = ({ value, inputValue, onChange, isValid, error, disabled, inputRef }: IBundleCountProps) => {
    const bundleCountRange = useSelector(bundleCountRangeSelector);
    const bundleCountOptions = useSelector(bundleCountOptionsSelector);
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const onOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const onClose = useCallback(() => {
        setOpen(false);
    }, []);

    const onInputKeyDown = useCallback((e: any) => {
        if (e.keyCode === 13) {
            setOpen(false);
        }
    }, []);

    const onBundleInputChange = useCallback(
        (e: React.ChangeEvent<any>, value: string) => {
            onChange(e, value, bundleCountRange);
        },
        [onChange, bundleCountRange]
    );

    const onBundleValueChange = useCallback(
        (e: React.ChangeEvent<any>, value: (string | number)[], reason: AutocompleteChangeReason) => {
            const valueStr = value as unknown as string; // Bug with autocomplete in silica library, value type is string.
            onChange(e, valueStr, bundleCountRange);
        },
        [onChange, bundleCountRange]
    );

    const autocompleteProps: IAutocompleteProps = {
        id: "bundle-count",
        type: "text",
        open,
        label: t(LocalizationKeys.BundleCount),
        value,
        inputValue,
        options: bundleCountOptions,
        isInputValid: isValid,
        inputErrorText: error,
        disabled,
        onOpen,
        onClose,
        onInputKeyDown,
        onInputChanged: onBundleInputChange,
        onValueChanged: onBundleValueChange,
        inputRef
    };

    return { autocompleteProps };
};

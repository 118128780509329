import React from "react";
import { useCustomBLength } from "./custom-b-length.hooks";
import { BLengthInput } from "../b-length-input/b-length-input";
import { Typography } from "@corning-ctcm/silica-react";

import "../../../../../../css/overlay/wizard/setup/b-length-section/custom-b-length.scss";

export const CustomBLength = (props: { disabled: boolean }) => {
    const { bLengths, display, lengthBString } = useCustomBLength();
    return (
        <div className="blength-custom-section" hidden={!display}>
            <Typography variant="body1" className="blength-custom-title" hidden={!display}>
                {lengthBString}
            </Typography>
            
            {bLengths.map((b) => (
                <BLengthInput {...b} key={`${b.position}`} id={`g${b.position}`} disabled={props.disabled} />
            ))}
        </div>
    );
};

import useResizeObserver from "@react-hook/resize-observer";
import {
    ForwardedRef,
    MouseEvent,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
    WheelEvent,
} from "react";
import { ICanvasHandle, ICanvasProps, ICanvasState, initializeCanvasState } from "../../../../../../models/overlay/polarity/polarity-diagram/canvas";
import { FitOption } from "../../../../../../models/overlay/polarity/polarity-diagram/pan-and-zoom-toolbar";

const SCALE_FACTOR = 0.02;
const SCALE_BASE = 1.1;
const ZOOM_CLICKS = 200;
const PIXEL_RATIO = 2;
const PADDING_TOP = 30;
const MIN_ZOOM = 0.01;
const MAX_ZOOM = 4;

export const useCanvas = ({ src, onZoom }: ICanvasProps, ref: ForwardedRef<ICanvasHandle>) => {
    const state = useRef<ICanvasState>(initializeCanvasState());
    const stateContainer = useRef<HTMLDivElement | null>();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const containerRef = useCallback((container: HTMLDivElement | null) => {
        stateContainer.current = container;
        setWidth(container?.offsetWidth ?? 0);
        setHeight(container?.offsetHeight ?? 0);
    }, []);

    const canvasRef = useCallback((canvas: HTMLCanvasElement | null) => {
        state.current.canvas = canvas ?? undefined;
        state.current.context = canvas?.getContext("2d") ?? undefined;

        if (state.current.context) {
            state.current.context.imageSmoothingQuality = "high";
        }
    }, []);

    const draw = useCallback(() => {
        const { canvas, context, scale, origin, image } = state.current;
        if (canvas && context && image) {
            context.resetTransform();
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.setTransform(scale, 0, 0, scale, origin.x, origin.y);
            context.drawImage(image, 0, 0, image.width, image.height);
        }
    }, []);

    const setScale = useCallback(
        (scale: number) => {
            state.current.scale = scale;

            if (onZoom) {
                onZoom(scale);
            }

            draw();
        },
        [draw, onZoom]
    );

    const zoom = useCallback(
        (clicks: number, zoomX?: number, zoomY?: number) => {
            const { canvas, origin, scale } = state.current;
            if (canvas) {
                const x = zoomX ?? canvas.width * 0.5;
                const y = zoomY ?? canvas.height * 0.5;

                const maxFactor = MAX_ZOOM / scale;
                const minFactor = MIN_ZOOM / scale;

                const factor = Math.pow(SCALE_BASE, clicks * SCALE_FACTOR);
                const clampedFactor = Math.max(Math.min(factor, maxFactor), minFactor);

                const offset = { x: x - origin.x, y: y - origin.y };
                state.current.origin.x = x - offset.x * clampedFactor;
                state.current.origin.y = y - offset.y * clampedFactor;

                setScale(scale * clampedFactor);
            }
        },
        [setScale]
    );

    const zoomIn = useCallback(() => {
        zoom(ZOOM_CLICKS);
    }, [zoom]);

    const zoomOut = useCallback(() => {
        zoom(-ZOOM_CLICKS);
    }, [zoom]);

    const getScale = useCallback((option: FitOption) => {
        const { canvas, image } = state.current;
        if (canvas && image) {
            const scaleX = canvas.width / image.width;
            const scaleY = (canvas.height - PADDING_TOP) / image.height;

            switch (option) {
                case "page":
                    return Math.min(scaleX, scaleY);
                case "height":
                    return scaleY;
                case "width":
                    return scaleX;
            }
        }

        return SCALE_BASE;
    }, []);

    const setFitOption = useCallback(
        (option: FitOption) => {
            const scale = getScale(option);

            // center image
            const { canvas, image } = state.current;
            if (canvas && image) {
                state.current.origin.x = (canvas.width - image.width * scale) * 0.5;
                state.current.origin.y = (canvas.height + PADDING_TOP - image.height * scale) * 0.5;
            }

            setScale(scale);
        },
        [getScale, setScale]
    );

    const onWheel = useCallback(
        (e: WheelEvent<HTMLCanvasElement>) => {
            const { pointer } = state.current;
            zoom(-e.deltaY, pointer.x * PIXEL_RATIO, pointer.y * PIXEL_RATIO);
        },
        [zoom]
    );

    const onMouseDown = useCallback((e: MouseEvent<HTMLCanvasElement>) => {
        e.preventDefault();
        state.current.isDragging = true;
    }, []);

    const onMouseUp = useCallback(() => {
        state.current.isDragging = false;
    }, []);

    const onMouseMove = useCallback(
        (e: MouseEvent<HTMLCanvasElement>) => {
            const { pointer, isDragging } = state.current;
            if (isDragging) {
                const deltaX = e.nativeEvent.offsetX - pointer.x;
                const deltaY = e.nativeEvent.offsetY - pointer.y;
                state.current.origin.x += deltaX * PIXEL_RATIO;
                state.current.origin.y += deltaY * PIXEL_RATIO;
                draw();
            }
            state.current.pointer.x = e.nativeEvent.offsetX;
            state.current.pointer.y = e.nativeEvent.offsetY;
        },
        [draw]
    );

    useEffect(() => {
        const image = new Image();
        image.src = src;
        image.onload = () => {
            state.current.image = image;
            setFitOption("page");
        };
    }, [src, width, height, setFitOption]);

    useResizeObserver(stateContainer.current!, (entry) => {
        const width = entry.contentRect.width;
        const height = entry.contentRect.height;
        setWidth(width);
        setHeight(height);
    });

    useImperativeHandle(ref, () => {
        return {
            zoomIn,
            zoomOut,
            setFitOption,
        };
    });

    return {
        canvasRef,
        containerRef,
        width,
        height,
        canvasWidth: width * PIXEL_RATIO,
        canvasHeight: height * PIXEL_RATIO,
        onWheel,
        onMouseMove,
        onMouseDown,
        onMouseUp,
    };
};

import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../locales/keys";
import { setViewportContext } from "../../../../../store/pixi/viewport/viewport.reducers";
import { ViewportStatus } from "../../../../../store/pixi/viewport/viewport";
import { setShowAboutApplication } from "../../../../../store/overlay/overlay.reducers";
import { showConnectorReportSelector } from "../../../../../store/overlay/overlay.selectors";
import { disabledUISelector } from "../../../../../store/overlay/header/status-icon/status-icon.selectors";
import { IconButtonProps } from "@corning-ctcm/silica-react";

export const useAboutThisApplicationButton = () => {
    const { t } = useTranslation();
    const connectorReportDisplay = useSelector(showConnectorReportSelector);
    const disabledUI = useSelector(disabledUISelector)
    const dispatch = useDispatch();

    const onClick = useCallback(() => {
        dispatch(setViewportContext(ViewportStatus.Inactive));
        dispatch(setShowAboutApplication(true));
    }, [dispatch]);

    const disabled = disabledUI || connectorReportDisplay;
    const buttonProps: Omit<IconButtonProps, "icon"> = {
        id: "about-icon",
        palette: "primary",
        mode: "main",
        disabled,
        title: t(LocalizationKeys.AboutThisApplication),
        placement: "bottom",
        onClick,
    };

    return { buttonProps };
};

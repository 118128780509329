import { Checkbox, Dialog } from "@corning-ctcm/silica-react";
import { usePropagation } from "./propagation.hooks";

import "../../../../css/overlay/polarity/propagation.scss";

export const Propagation: React.FC = () => {
    const { dialogProps, triggerCheckbox, labelTextCheckbox, labelColorCheckbox } = usePropagation();

    return (
        <Dialog {...dialogProps}>
            <Checkbox {...triggerCheckbox} />
            <Checkbox {...labelColorCheckbox} />
            <Checkbox {...labelTextCheckbox} />
        </Dialog>
    );
};

import { DialogProps } from "@corning-ctcm/silica-react";
import { useDispatch, useSelector } from "react-redux";
import { showShellEditSelector } from "../../../../store/overlay/overlay.selectors";
import { useRadioInput, useRadioGroup } from "../../../ui/radio/radio.hooks";
import { currentShellTypeSelector } from "../../../../store/workspace/build.selectors";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { useCallback, useEffect, useState } from "react";
import { setShowShellEdit } from "../../../../store/overlay/overlay.reducers";
import { updateShellType } from "../../../../store/workspace/build/build.reducers";
import { useWebSave } from "../status/status.hooks";
import { setStatusState } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import { isLockedSelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";

export const useShellEdit = () => {
    const open = useSelector(showShellEditSelector);
    const shellType = useSelector(currentShellTypeSelector);
    const locked = useSelector(isLockedSelector);
    const { saveBuild } = useWebSave();
    const [shellTypeChanged, setShellTypeChanged] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { onRadioGroupChange, ...radioGroup } = useRadioGroup("shell-type", shellType, locked);
    const tJunction = useRadioInput("hard", t(LocalizationKeys.TJunction), shellType, locked);
    const heatShrink = useRadioInput("soft", t(LocalizationKeys.HeatShrink), shellType, locked);

    const onSave = useCallback(async () => {
        await saveBuild();
        dispatch(setStatusState("saved"));
    }, [dispatch, saveBuild]);

    const onShellTypeChange = useCallback(
        (e: any) => {
            const shellType = e.currentTarget.value;
            onRadioGroupChange(e);
            dispatch(updateShellType(shellType));
            setShellTypeChanged(true);
        },
        [onRadioGroupChange, dispatch]
    );

    const onClose = useCallback(() => {
        dispatch(setShowShellEdit(false));
    }, [dispatch]);

    const dialogProps: DialogProps = {
        id: "shell-edit",
        open,
        modal: false,
        className: "shell-edit",
        title: t(LocalizationKeys.EditShellType),
        header: {
            id: "shell-edit-header",
            closeable: true,
            onClose,
        },
        onClose,
    };

    useEffect(() => {
        if (open && shellTypeChanged) {
            dispatch(setStatusState("saving"));
            onSave();
            setShellTypeChanged(false);
        }
    }, [open, shellTypeChanged, dispatch, onSave]);

    return {
        dialogProps,
        radioGroup: {
            props: { ...radioGroup, onRadioGroupChange: onShellTypeChange },
            options: [tJunction, heatShrink],
        },
    };
};

import { Graphics } from "pixi.js";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { _ReactPixi } from "@pixi/react";

import { setShowShellEdit } from "../../../../../store/overlay/overlay.reducers";
import { showShellEditSelector } from "../../../../../store/overlay/overlay.selectors";
import { DropContext } from "../../../../../store/workspace/build/build";
import { glowFilter, hoverFilter } from "../../../decorators/bounds/select-filter";
import { viewportContextSelector } from "../../../../../store/pixi/viewport/viewport.selectors";
import { BORDER_COLOR } from "../../../../../models/pixi/build/connectors/connector-furcation";
import { AP_BORDER_WIDTH, AP_SHELL_BOTTOM_SIZE, AP_SIZE_SHELL, AP_SHELL_TOP_SIZE } from "../../../../../models/pixi/build/drop-base/access-point";
import { ShellContainer } from "../../../../../models/pixi/build/build";
import { currentBuildFinalizedSelector } from "../../../../../store/workspace/build.selectors";
import { getInteractiveEventMode } from "../../../../../models/pixi/pixi";

export const useAccessPoint = () => {
    const { side, shell, nbDrops, containerName } = useContext(DropContext);
    const { eventMode, filters, actions } = useSelection(side === "distribution" && nbDrops > 2);

    const draw = useCallback(
        (grfx: Graphics) => {
            let { width, height } = AP_SIZE_SHELL;

            const x = -width * 0.5;
            const y = -height * 0.5;

            // Rectangle
            grfx.clear();
            grfx.beginFill(BORDER_COLOR);
            grfx.lineStyle(AP_BORDER_WIDTH, BORDER_COLOR);
            grfx.moveTo(x, y);
            grfx.drawRect(x, y, width, height);
            grfx.endFill();

            const isHardShell = shell === "hard";
            if (isHardShell) {
                drawAPTop(grfx);
                drawAPBottom(grfx);
            }
        },
        [shell]
    );

    const apProps: _ReactPixi.IGraphics = {
        name: `${containerName}-${ShellContainer}`,
        draw,
        cursor: "pointer",
        eventMode,
        mouseout: actions.onMouseOut,
        mouseover: actions.onMouseOver,
        click: actions.onClick,
    };

    return { filters, apProps };
};

const drawAPTop = (grfx: Graphics) => {
    let { width, height } = AP_SHELL_TOP_SIZE;

    const left = -width * 0.5;
    const bottom = -height * 0.5;

    const right = width * 0.5;
    const top = -height * 0.75;

    const curveRight = width * 0.215;
    const curveLeft = -width * 0.215;

    grfx.beginFill(BORDER_COLOR);
    grfx.lineStyle(AP_BORDER_WIDTH, BORDER_COLOR);
    grfx.moveTo(left, bottom);
    grfx.lineTo(right, bottom);
    grfx.moveTo(right, bottom);
    grfx.bezierCurveTo(right, bottom, curveRight, bottom, curveRight, top);
    grfx.lineTo(curveLeft, top);
    grfx.bezierCurveTo(curveLeft, top, curveLeft, bottom, left, bottom);
    grfx.endFill();
};

const drawAPBottom = (grfx: Graphics) => {
    let { width, height } = AP_SHELL_BOTTOM_SIZE;

    const left = -width * 0.45;
    const top = height * 0.39;

    const right = width * 0.45;
    const bottom = height * 1.05;

    const curveRight = width * 0.17;
    const curveMidRight = width * 0.17;
    const curveLeft = -width * 0.17;
    const curveMidLeft = -width * 0.17;

    grfx.beginFill(BORDER_COLOR);
    grfx.lineStyle(AP_BORDER_WIDTH, BORDER_COLOR);
    grfx.moveTo(left, top);
    grfx.lineTo(right, top);
    grfx.bezierCurveTo(right, top, curveMidRight, top, curveRight, bottom);

    grfx.lineTo(curveLeft, bottom);
    grfx.bezierCurveTo(curveLeft, bottom, curveMidLeft, top, left, top);
    grfx.endFill();
};

const useSelection = (interactive: boolean) => {
    const { context: viewportContext } = useSelector(viewportContextSelector);
    const showShellEdit = useSelector(showShellEditSelector);
    const buildFinalized = useSelector(currentBuildFinalizedSelector);
    const [selectionFilters, setSelectionFilters] = useState({ ...glowFilter, enabled: false });
    const [hoverFilters, setHoverFilters] = useState({ ...hoverFilter, enabled: false });
    const dispatch = useDispatch();

    const allowInteractions = interactive && viewportContext === "active" && !buildFinalized;
    const onMouseOver = useCallback(() => {
        if (allowInteractions) {
            setHoverFilters({ ...hoverFilter, enabled: true });
        }
    }, [allowInteractions]);

    const onMouseOut = useCallback(() => {
        if (allowInteractions) {
            setHoverFilters({ ...hoverFilter, enabled: false });
        }
    }, [allowInteractions]);

    useEffect(() => {
        if (allowInteractions && showShellEdit) {
            setSelectionFilters({ ...glowFilter, enabled: true });
        } else {
            setSelectionFilters({ ...glowFilter, enabled: false });
        }
    }, [allowInteractions, showShellEdit]);

    const onClick = useCallback(() => {
        if (allowInteractions) {
            dispatch(setShowShellEdit(true));
        }
    }, [allowInteractions, dispatch]);

    return {
        eventMode: getInteractiveEventMode(allowInteractions),
        filters: {
            selection: selectionFilters,
            hover: hoverFilters,
        },
        actions: {
            onMouseOver,
            onMouseOut,
            onClick,
        },
    };
};

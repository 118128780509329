import React from "react";
import { ITemplateProps } from "../../../../models/overlay/reports/label-scheme/template";
import { Typography } from "@corning-ctcm/silica-react";

import "../../../../css/overlay/reports/label-scheme/template.scss";

export const Template: React.FC<ITemplateProps> = ({ id, name, desc, selected, onClick }) => {
    const className = selected ? "template selected" : "template";
    return (
        <div id={`${name}-template`} className={className} onClick={() => onClick(id)}>
            <div className="info">
                <div className="name">{name}</div>
                <Typography variant="caption">
                    {desc}
                </Typography>
            </div>
        </div>
    );
};

import { IBuildData } from "../build/build";
import { IDrop } from "../build/drop";
import { ProductLabel } from "../../../models/overlay/reports/report-settings/labels-section";

type ReportClassifier = Partial<{
    name: string;
    class: string;
    description: string;
}>;

export interface IUserProvidedImage {
    fileName: string;
    objectUrl: string;
}

export type DocumentFields = Partial<{
    reportType: string;
    siteLocation: string;
    drawn: string;
    drawingNotes: string;
    footerNotes: string;
    inServiceDate: string;
    approvalDate: string;
    revision: string;
    draft: boolean;
    catalogCode: string;
    productLabels: ProductLabel[];
    userProvidedImages: IUserProvidedImage[];
}>;

export type DocumentOverview = Partial<IBuildData> & ReportClassifier;
export type DocumentFeeder = IDrop & ReportClassifier;
export type DocumentDrop = ReportClassifier & {
    drop: IDrop;
};

export interface Polarities {
    accessPoints: number[];
}

export type DocumentPolarity = ReportClassifier & {
    fiberMapNames: string[];
};

export type DocumentFiberMap = Partial<{ include: boolean, type: string }> 
    & ReportClassifier;

export type DocumentConnector = Partial<{
    include: boolean;
}>;

export type DocumentBuildPlan = Partial<{
    include: boolean;
    summary: DocumentBuildPlanSummary;
}>;

export type DocumentCableOverView = Partial<{
    include: boolean;
}>;

export type DocumentTolerances = Partial<{
    include: boolean;
}>;

type DocumentBuildPlanSummary = Partial<{
    bundleCount: string;
    fiberCount: string;
    feederConnectorType: string;
    tapsConnectorTypes: string;
    tapsCount: string;
    overallLength: string;
}>;

interface IDocumentDrawing {
    fileName: string;
    description: string;
    units: string;
    overview: DocumentOverview;
    feeder: DocumentFeeder;
    drops: DocumentDrop[];
}

interface IDocumentDiagram {
    fiberMap: DocumentFiberMap;
    polarity: DocumentPolarity;
    connector: DocumentConnector;
    buildPlan: DocumentBuildPlan;
    tolerances: DocumentTolerances;
    cableOverview: DocumentCableOverView;
}

export type BuildDocument = Partial<DocumentFields & IDocumentDrawing & IDocumentDiagram>;

export const initialBuildDocState: BuildDocument = {};

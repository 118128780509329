import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setStatusState } from "../../store/overlay/header/status-icon/status-icon.reducer";
import { BoundariesService } from "../../services/boundaries.service";
import {
    addBoundariesOptions,
    removeBoundariesOptions,
    setBoundariesOptions,
} from "../../store/workspace/boundaries/boundaries.reducers";
import { Boundaries } from "../../store/workspace/boundaries/boundaries";
import { getCurrentISODate } from "../../services/build.service";

export const useBoundaries = () => {
    const dispatch = useDispatch();

    const loadBoundaries = useCallback(async () => {
        const boundariesList = await new BoundariesService().getAllBoundaries();
        if (boundariesList) {
            dispatch(setBoundariesOptions(boundariesList));
            dispatch(setStatusState("loading"));
        }
    }, [dispatch]);

    const addOrUpdateBoundaries = useCallback(
        async (boundariesToUpdate: Boundaries) => {
            dispatch(setStatusState("saving"));
            const boundariesResponse = await new BoundariesService().addOrUpdateBoundaries(boundariesToUpdate);
            let boundaries: Boundaries = { ...boundariesToUpdate, lastModified: getCurrentISODate() };
            if (boundariesResponse) {
                boundaries = boundariesResponse;
                dispatch(addBoundariesOptions(boundaries));
            }
            dispatch(setStatusState("saved"));
            return boundaries;
        },
        [dispatch]
    );

    const deleteBoundaries = useCallback(
        async (id: number) => {
            dispatch(setStatusState("saving"));
            await new BoundariesService().deleteBoundaries(id);
            dispatch(removeBoundariesOptions(id));
            dispatch(setStatusState("saved"));
        },
        [dispatch]
    );

    return {
        loadBoundaries,
        addOrUpdateBoundaries,
        deleteBoundaries,
    };
};

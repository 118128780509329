import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../../locales/keys";
import { setAllCablesTied, setCablesTied } from "../../../../../../store/workspace/build/build.reducers";
import { selectedDropSelector } from "../../../../../../store/workspace/build.selectors";
import { currentBuildSelector } from "../../../../../../store/workspace/root.selectors";
import { toolbarSelectionSelector } from "../../../../../../store/overlay/footer/toolbar/toolbar.selectors";
import { IconButtonProps } from "@corning-ctcm/silica-react";

export const useTieCablesButton = () => {
    const { t } = useTranslation();

    const { selected, side } = useSelector(toolbarSelectionSelector);
    const currentDrop = useSelector(selectedDropSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const dispatch = useDispatch();
    const [showUntie, setShowUntie] = useState<boolean | undefined>();
    const [disabled, setDisabled] = useState(false);
    const [tooltip, setTooltip] = useState("");

    useEffect(() => {
        let hasMesh = false;
        let isTied = false;
        if (currentDrop) {
            hasMesh = !!currentDrop.mesh;
            isTied = !!currentDrop.cablesTied;
        } else if (currentBuild) {
            hasMesh = currentBuild.drops.some((drop) => drop.mesh);
            isTied = currentBuild.drops.some((drop) => drop.cablesTied);
        } else {
            return;
        }

        if (hasMesh) {
            setShowUntie(false);
            setDisabled(true);
            setTooltip(t(LocalizationKeys.TieButtonDisabled));
        } else {
            setShowUntie(isTied);
            setDisabled(false);
            setTooltip(t(isTied ? LocalizationKeys.TieButtonUntie : LocalizationKeys.TieButtonTie));
        }
    }, [t, currentBuild, currentDrop]);

    const triggerCablesTied = useCallback(() => {
        if (currentDrop) {
            if (side) {
                dispatch(setCablesTied({ position: selected, side: side }));
            }

            return;
        }

        if (!currentBuild) return;

        dispatch(setAllCablesTied(!showUntie));
    }, [dispatch, currentDrop, selected, side, currentBuild, showUntie]);

    const buttonProps: Omit<IconButtonProps, "icon"> = {
        id: "ties-icon",
        palette: "primary",
        mode: "main",
        disabled,
        title: tooltip,
        placement: "top",
        onClick: triggerCablesTied,
    };

    return {
        showUntie,
        disabled,
        buttonProps,
    };
};

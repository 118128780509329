import proxyConfig from "../config.json";

export interface ProxyConfig {
    endpoint: string;
    clientId: string;
    clientSecret: string;
}

export const devProxyConfig: ProxyConfig = {
    endpoint: "https://rab.ctcmdev.com/",
    clientId: "",
    clientSecret: "",
};

export const hybrisProxyConfig: ProxyConfig = {
    endpoint: "https://api-dev.corning.com/design-tool-proxy-api-dev/v1/proxy/",
    clientId: "f9f039258722440cabeb9dfe556da9fb",
    clientSecret: "eE636e5Cb05c4f438EB431452F11A988",
};

export class ProxyConfigHelper {
    static getProxyConfig = (): ProxyConfig => {
        const endpoint = ProxyConfigHelper.getmulesoftClientUrl();
        const clientId = ProxyConfigHelper.getMulesoftClientId();
        const clientSecret = ProxyConfigHelper.getMulesoftClientSecret();
        return {
            endpoint: endpoint?.length ? endpoint : proxyConfig.mulesoftEndPoint,
            clientId: clientId?.length ? clientId : proxyConfig.mulesoftId,
            clientSecret: clientSecret?.length ? clientSecret : proxyConfig.mulesoftSecret,
        };
    };

    static getImplementationEnvironment = (): string | undefined => {
        return (window as any).implementationEnvironment;
    };

    static getMulesoftClientId = (): string | undefined => {
        return (window as any).mulesoftClientId;
    };

    static getMulesoftClientSecret = (): string | undefined => {
        return (window as any).mulesoftClientSecret;
    };

    static getmulesoftClientUrl = (): string | undefined => {
        return (window as any).mulesoftClientUrl;
    };

    static getEndpoint = (): string => {
        const environment = ProxyConfigHelper.getImplementationEnvironment();
        if (!environment) return "";

        return ProxyConfigHelper.getProxyConfig().endpoint;
    };
}

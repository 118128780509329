import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { StatusState, WorkspaceStatus, WorkspaceStatusTypes, initialStatusState } from "./status-icon";

const setStatusStateAction = (state: StatusState, action: PayloadAction<WorkspaceStatusTypes>) => {
    state.currentStatus = action.payload;
};

const setTextureLoadedAction = (state: StatusState) => {
    state.textureStatus = WorkspaceStatus.Ready;
};

const setIsLockedAction = (state: StatusState, action: PayloadAction<boolean>) => {
    state.isLocked = action.payload;
};

const StatusSlice = createSlice({
    name: "status",
    initialState: initialStatusState,
    reducers: {
        setStatusState: setStatusStateAction,
        setTextureLoaded: setTextureLoadedAction,
        setIsLocked: setIsLockedAction,
    },
});

export const StatusReducer = StatusSlice.reducer;
export const { setStatusState, setTextureLoaded, setIsLocked } = StatusSlice.actions;

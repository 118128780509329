import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { LabelSchemeService } from "../../../../services/label-scheme.service";
import { setOrgBundles } from "../../../../store/workspace/build/connector/label-scheme/label-scheme.reducers";

export const useLabelSchemes = () => {
    const storeDispatch = useDispatch();

    const loadOrgSchemes = useCallback(async () => {
        const orgBundles = await new LabelSchemeService().getOrgBundles();
        if (orgBundles) {
            storeDispatch(setOrgBundles(orgBundles));
        }
    }, [storeDispatch]);

    return { loadOrgSchemes };
};

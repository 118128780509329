import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentBuildAutoAccessPoints } from "../../../../../store/workspace/build/build.reducers";
import { viewportSelector } from "../../../../../store/pixi/viewport/viewport.selectors";
import { ViewportStatus } from "../../../../../store/pixi/viewport/viewport";
import { CheckboxProps } from "@corning-ctcm/silica-react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import { autoAccessPointsSelector } from "../../../../../store/workspace/build.selectors";

export const useDropCheckbox = () => {
    const autoAccessPoints= useSelector(autoAccessPointsSelector);
    const { context } = useSelector(viewportSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            let checked = e.currentTarget.checked;
            dispatch(setCurrentBuildAutoAccessPoints(!checked));
        },
        [dispatch]
    );

    const disabled = context === ViewportStatus.Editing;
    const checked = !autoAccessPoints && !disabled;
    const checkboxProps: CheckboxProps = {
        palette: "primary",
        className: "ap-checkbox",
        label: t(LocalizationKeys.EnableCustomAccesPoints),
        disabled,
        checked,
        onChange: onCheckboxChange,
    };

    return { checkboxProps };
};

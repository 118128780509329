import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { setFiberMaps } from "../../../../../store/workspace/build/connector/polarity/polarity.reducer";
import { BuildService } from "../../../../../services/build.service";
import { setStatusState } from "../../../../../store/overlay/header/status-icon/status-icon.reducer";

export const useFiberMaps = () => {
    const storeDispatch = useDispatch();

    const loadFiberMaps = useCallback(async () => {
        const fiberMapData = await new BuildService().getPolarityFiberMaps();
        if (fiberMapData) {
            storeDispatch(setFiberMaps(fiberMapData));
            storeDispatch(setStatusState("loading"));
        }
    }, [storeDispatch]);

    return {
        loadFiberMaps,
    };
};

import CorningLogo from "../../resources/svgs/overlay/Corning_Logo_301Grey.svg";
import { useReportTables } from "./off-screen.hooks";
import { IsReportReady } from "../overlay/header/tools-container/export-button/export-button.hooks";
import { BuildPlanTable } from "./build-plan-table/build-plan-table";
import { FiberMapTable } from "./fiber-map-table/fiber-map-table";
import { ColorChip } from "../ui/color-chip";
import { BuildTolerancesTable } from "./build-tolerances-table/build-tolerances-table";
import { CableTrunkLengthTable } from "./cable-trunk-length-table/cable-trunk-length-table";
import { DropConnectorTable } from "./drop-connector-table/drop-connector-table";
import { DropLabelTable } from "./drop-label-table/drop-label-table";
import { AllColors } from "../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";

import "../../css/off-screen/off-screen.scss";

export const OffScreen = () => {
    const {
        currentStatus,
        buildTolerancesTable,
        cableTrunkLengthTable,
        dropConnectorTables,
        dropLabelTables,
        polarityDiagrams,
        fiberColors,
        buildPlanTables,
    } = useReportTables();

    return (
        <div id="offscreen-container">
            {IsReportReady(currentStatus) ? (
                <div id="report-table-container">
                    <BuildTolerancesTable {...buildTolerancesTable} />
                    <CableTrunkLengthTable {...cableTrunkLengthTable} />
                    {dropConnectorTables.map((d) => (
                        <DropConnectorTable key={d.id} id={d.id} rows={d.rows} />
                    ))}
                    {dropLabelTables.map((d) => (
                        <DropLabelTable key={d.id} {...d} />
                    ))}
                    <BuildPlanTable {...{ buildPlanTables }} />
                    <FiberMapTable />
                </div>
            ) : null}
            <img id="corning-secondary-logo" src={CorningLogo} alt={"a corning logo"} width={200}></img>
            <div id="polarity-container">
                {polarityDiagrams
                    ?.filter((d) => d.url)
                    .map(({ key, url }) => (
                        <img id={key} key={key} src={url} alt="polarity diagram" />
                    ))}
            </div>
            <div id="fiber-chip-container">
                { fiberColors.map((c) => (
                    <ColorChip 
                        key={`fiber-chip-${c.name}`} 
                        id={`fiber-chip-${c.name}`} 
                        color={c} 
                        scale={1} />
                ))}
            </div>
            <div id="connector-chip-container">
                {AllColors.map((c, i) => (
                    <ColorChip 
                        key={`connector-chip-${c.name}`} 
                        id={`connector-chip-${c.name}`} 
                        color={c} 
                        scale={1} />
                ))}
            </div>
        </div>
    );
};

import { IAutocompleteProps } from "../../../models/ui/generic-autocomplete";
import { useState, useEffect } from "react";

export const useGenericAutocomplete = (props: IAutocompleteProps) => {
    const {
        id,
        open,
        disabled,
        options,
        value,
        inputValue,
        type,
        onOpen,
        onClose,
        onValueChanged,
        onInputChanged,
        label,
        isInputValid,
        inputErrorText,
        onInputKeyDown,
        units,
        freeSolo,
        inputRef,
    } = props;
    const [containerClassName, setContainerClassName] = useState("generic-autocomplete-container");

    useEffect(() => {
        if (isInputValid) {
            setContainerClassName("generic-autocomplete-container");
        } else {
            setContainerClassName("generic-autocomplete-container-invalid");
        }
    }, [isInputValid]);

    return {
        id,
        open,
        disabled,
        options,
        value,
        inputValue,
        type,
        onOpen,
        onClose,
        onValueChanged,
        onInputChanged,
        label,
        isInputValid,
        inputErrorText,
        onInputKeyDown,
        containerClassName,
        units,
        freeSolo,
        inputRef,
    };
};

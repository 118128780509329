import { createContext } from "react";
import { LocalizationKeys } from "../../../locales/keys";
import { IDimensionLines } from "../../../models/pixi/decorators/dimension-lines/dimension-lines";
import { IPoint } from "../../../models/pixi/pixi";
import { getName } from "../../../components/overlay/project-drawer/project-manager-row/build-info/build-info.hooks";
import { initialProductLabel, ProductLabel } from "../../../models/overlay/reports/report-settings/labels-section";
import { FlameRatings } from "../../../models/overlay/wizard/setup/flame-rating";
import { ConfigurationType, Fiber84, IConnectorType, Stagger0 } from "../../../store/overlay/wizard/wizard";
import { IConnectorDataWithGroupIndex, IConnectorGroupData, IConnectorGroupDataWithIndex } from "./connector/connector";
import { DropSide, IDrop, initialDrops, initialMeshColor, initialMeshOffset } from "./drop";
import { ITolerances, DEFAULT_TOLERANCES } from "../../../models/overlay/header/tolerances/tolerances";
import {
    IUnitOfMeasure,
    PrimaryUnit,
    Unit,
    PUoMImperial,
    UoMInches,
} from "../../../models/overlay/header/units-of-measure";
import { PullingGrip } from "../../../models/pixi/decorators/markers/pulling-grip";
import { IConnectorAssignment } from "../../../store/overlay/polarity/polarity";
import { Sorts } from "../../overlay/project/project";

export const DEFAULT_CABLE_OUTER_DIAMETER = 2.0;

export interface IBuildsState {
    currentBuild?: IBuildData;
    builds: IBuildInfo[];
    loaded: boolean;
}

export interface IBuildData {
    id?: number;
    userId?: number;
    groupId?: number;
    configurationType?: string;
    modeId?: number;
    fiberCount?: number;
    bundleCount?: number;
    cableOuterDiameter?: number;
    flameRating?: string;
    fiberType?: string;
    name?: string;
    description?: string;
    partNumber?: string;
    ownerEmail?: string;
    catalogCode?: string;
    lastModified?: string;
    lockedById?: number;
    lockedByFullName?: string;
    autoAccessPoints?: boolean;
    primaryUnit?: string; // Primary measurement system
    unit?: string;
    drops: IDrop[];
    availability?: IEnableData;
    isCollapsed?: boolean;
    tolerances?: ITolerances;
    measurementsPosition?: string;
    overallLengthType?: string;
    assemblyType?: string;
    mainMeshColor?: string;
    pullingGrip: PullingGrip;
    productLabels: ProductLabel[];
    overviewNotes: string;
    location: string;
    drawnBy: string;
    revisionNumber: string;
    approvalDate: string;
    inServiceDate: string;
    footerNotes: string;
    maskLengths: boolean;
    connectorAssignments: IConnectorAssignment[];
}

export interface IBuildInfo {
    buildId?: number;
    userId?: number;
    groupId?: number;
    name?: string;
    description?: string;
    partNumber?: string;
    ownerEmail?: string;
    lockedById?: number;
    catalogCode?: string;
    lastModified?: string;
    fiberCount?: number;
    cableOuterDiameter?: number;
    fiberType?: string;
    flameRating?: string;
    customTag?: string;
    pullingGrip?: PullingGrip;
    nbConnectorsSrc?: number;
    connectorTypeSrc?: string;
    nbTAPs?: number;
    nbConnectorsDst?: number;
    connectorTypeDst?: string;
    fiberMapKeys?: number[];
}

export type DropData = {
    id?: number;
    position: number;
    lengthA: IUnitOfMeasure;
    lengthB: IUnitOfMeasure;
    customBLength: boolean;
    groups: IConnectorGroupData[];
    mesh?: boolean;
    meshOffset?: IUnitOfMeasure;
    meshColor?: string;
    fiberCountInUse?: number;
    side: DropSide;
    cablesTied?: boolean;
    groupsCollapsed: boolean;
    connectorsCollapsed: boolean;
} & DropDrawState;

export type DropDrawState = Partial<{
    enabled: boolean;
    apLocation: IPoint;
    shell: string;
}>;

export interface IDropContext {
    index: number;
    position: number;
    containerName: string;
    primaryUnit: PrimaryUnit;
    unit: Unit;
    lengthA: IUnitOfMeasure;
    lengthB: IUnitOfMeasure;
    customBLength: boolean;
    groups: IConnectorGroupDataWithIndex[];
    connectors: IConnectorDataWithGroupIndex[];
    cablesTied?: boolean;
    groupsCollapsed: boolean;
    connectorsCollapsed: boolean;
    enabled: boolean;
    connectorType: string;
    stagger: IUnitOfMeasure;
    nbConnectors: number;
    nbGroups: number;
    nbGroupsHidden?: number;
    nbDrops: number;
    dimensionLines: IDimensionLines;
    furcationPoint?: IPoint;
    connectorAnchorPoint?: IPoint;
    mesh?: boolean;
    meshOffset?: IUnitOfMeasure;
    meshColor?: string;
    fiberCount: number;
    fiberCountInUse?: number;
    side: DropSide;
    shell?: string;
    isReportDrop?: boolean;
    isReportOverviewDrop?: boolean;
}

export const initialDropContext: IDropContext = {
    index: 0,
    position: 0,
    containerName: "",
    primaryUnit: PUoMImperial,
    unit: UoMInches,
    lengthA: Stagger0.value,
    lengthB: Stagger0.value,
    customBLength: false,
    groups: [],
    connectors: [],
    groupsCollapsed: false,
    connectorsCollapsed: false,
    enabled: false,
    stagger: Stagger0.value,
    connectorType: "",
    nbConnectors: 0,
    nbGroups: 0,
    nbDrops: 0,
    dimensionLines: {
        lengthA: {},
        lengthB: {},
    },
    mesh: true,
    meshOffset: initialMeshOffset,
    meshColor: initialMeshColor,
    fiberCount: 0,
    side: "feeder",
    shell: "soft",
};

export interface IPositionContext {
    anchorHeight: number;
    furcationPoint: IPoint;
    connectorAnchorPoint: IPoint;
    wamLocation: IPoint;
}

export const DropContext = createContext<IDropContext>(initialDropContext);

export interface ICableInfoUpdateState {
    buildId: number;
    name: string;
    desc: string;
    partNumber: string;
}

export interface IConnectorLabelUpdateState {
    position: number;
    groupId: number;
    connectorId: number;
    label: string;
}

export interface IEnableData {
    sourceEnabled: boolean;
    enabledDestinations: number[];
}

export interface IUpdateGroupArgs {
    side: DropSide;
    position: number;
    groups: IConnectorGroupData[];
    groupCount: number;
    connectorsPerGroup: number;
    connectorType: IConnectorType;
    stagger: IUnitOfMeasure;
    lengthB: IUnitOfMeasure;
    fiberCount: number;
    fiberCountInUse: number;
    reverseStaggering: boolean;
    customBLength: boolean;
}

export type CableType = "P2P" | "P2MP";

export function duplicateBuild(build: IBuildData, name?: string): IBuildData {
    const { id, ...buildData } = build;
    const availability: IEnableData = {
        sourceEnabled: true,
        enabledDestinations: buildData.drops.map((d) => d.position),
    };
    return {
        ...buildData,
        availability,
        name: name ?? getName(build) + "-Copy",
    };
}

export function getBuildOwnerByUserId(userId: number, builds: IBuildInfo[]) {
    const ownerEmail = builds.find((b) => b.userId === userId)!.ownerEmail;
    return ownerEmail ? extractOwnerFromEmail(ownerEmail) : LocalizationKeys.Unknown;
}

export const extractOwnerFromEmail = (ownerEmail: string) => {
    return ownerEmail.split("@")[0];
};

export function getBuildUserIdByProjectId(projectId: number, builds: IBuildInfo[]) {
    const build = builds.find((b) => b.buildId === projectId);
    return build ? build.userId : -1;
}

export function isBuildLocked(userId: number, projectId: number, builds: IBuildData[]) {
    const build = builds.find((b) => b.id && b.id === projectId);
    if (build) {
        if (build.lockedById === -1) {
            return false;
        } else if (build.lockedById !== userId) {
            return true;
        }
    }
    return false;
}

export const getGroupsWithIndex = (data: DropData): IConnectorGroupDataWithIndex[] => {
    if (!data?.groups || data.groups.length === 0) return [];

    const allConnectors = data.groups.flatMap((g) => g.connectors);
    return data.groups.slice().map((g, i) => ({
        ...g,
        position: i,
        connectors: g.connectors.map((c) => {
            return {
                ...c,
                groupIndex: i,
                connIndex: allConnectors.findIndex((ic) => ic === c),
            };
        }),
    }));
};

export interface IBuildPrintSettings {
    overviewNotes: string;
    productLabels: ProductLabel[];
    location: string;
    drawnBy: string;
    revisionNumber: string;
    approvalDate: string;
    inServiceDate: string;
    footerNotes: string;
}

export const initialPrintSettings: IBuildPrintSettings = {
    overviewNotes: "",
    productLabels: [initialProductLabel],
    location: "",
    drawnBy: "",
    revisionNumber: "",
    approvalDate: new Date().toLocaleDateString(),
    inServiceDate: new Date().toLocaleDateString(),
    footerNotes: "",
};

export const initialBuildData: IBuildData = {
    // This is temporary and solely for the purpose of testing
    id: 0,
    configurationType: ConfigurationType.Patching,
    fiberCount: Fiber84.count,
    fiberType: "ULTRA",
    cableOuterDiameter: DEFAULT_CABLE_OUTER_DIAMETER,
    flameRating: FlameRatings.Riser,
    primaryUnit: PUoMImperial,
    unit: UoMInches,
    name: "",
    description: "",
    lastModified: new Date().toLocaleDateString(),
    autoAccessPoints: true,
    drops: initialDrops,
    measurementsPosition: "interior",
    overallLengthType: "furcation",
    pullingGrip: "none",
    availability: {
        sourceEnabled: false,
        enabledDestinations: [],
    },
    tolerances: DEFAULT_TOLERANCES,
    maskLengths: false,
    ...initialPrintSettings,
    connectorAssignments: [],
};

export const initialState: IBuildsState = {
    currentBuild: initialBuildData,
    builds: [],
    loaded: false,
};

export const BuildContext = createContext(initialBuildData);

export const sortProject = (
    sortType: string,
    first: IBuildInfo, 
    second: IBuildInfo,
    selector: (arg: IBuildInfo) => string | undefined,
    ascending: boolean = false
) => {
    const a = selector(first);
    const b = selector(second);
    const flip = ascending ? 1 : -1;

    switch (sortType) {
        case Sorts.DateModified:
            return a?.length && b?.length 
                ? flip * (new Date(b).getTime() - new Date(a).getTime()) 
                : 0;
        case Sorts.Description:
        case Sorts.Name:
            return a?.length && b?.length 
                ? flip * b.toLowerCase().localeCompare(a.toLowerCase(), undefined, { numeric: true, sensitivity: "base" })
                : 0;
        default:
            return 0;
    }
};

export const sortProjects = (
    sortType: string,
    first: IBuildInfo,
    second: IBuildInfo,
    ascending: boolean = false
) => {
    switch (sortType) {
        case Sorts.Description:
            return sortProject(sortType, first, second, (b) => b.description, ascending);
        case Sorts.Name:
            return sortProject(sortType, first, second, (b) => b.name, ascending);
        case Sorts.DateModified:
        default:
            return sortProject(sortType, first, second, (b) => b.lastModified, ascending);
    }
}

import { IAutocompleteProps } from "../../../models/ui/generic-autocomplete";
import { useGenericAutocomplete } from "./generic-autocomplete.hooks";
import { AutoComplete } from "@corning-ctcm/silica-react";
import { getUnitsName } from "../../../models/overlay/header/units-of-measure";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";

import "../../../css/ui/generic-autocomplete.scss";

export const GenericAutocomplete = (props: IAutocompleteProps) => {
    const {
        id,
        open,
        disabled,
        options,
        value,
        inputValue,
        type,
        onOpen,
        onClose,
        onValueChanged,
        onInputChanged,
        label,
        isInputValid,
        inputErrorText,
        onInputKeyDown,
        containerClassName,
        units,
        freeSolo,
        inputRef,
    } = useGenericAutocomplete(props);
    return (
        <div className={`generic-auto-complete ${containerClassName}`}>
            <AutoComplete
                id={id}
                disableClearable
                open={open}
                disabled={disabled}
                options={options}
                value={value}
                onOpen={onOpen}
                onClose={onClose}
                onChange={onValueChanged}
                onInputChange={onInputChanged}
                inputValue={inputValue}
                isOptionEqualToValue={(option) => option.toString() === inputValue}
                textFieldProps={{
                    id: `${id}-field`,
                    type,
                    disabled,
                    label,
                    error: !isInputValid,
                    helperText: isInputValid ? undefined : inputErrorText,
                    className: "generic-autocomplete-field-container",
                    onKeyDown: onInputKeyDown,
                    units: '',
                    adornmentPosition: 'end',
                    InputProps: {
                        startAdornment: units ? <div className="units">{getUnitsName(units, false, true)}</div> : '',
                        endAdornment: open ? <ArrowDropUp /> : <ArrowDropDown />,
                    },
                    inputRef,
                }}
                palette="primary"
                freeSolo={freeSolo}
            />
        </div>
    );
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IPropagationOptions, PropagationState, initialPropagationState } from "./propagation";

const showPropagationReducer = (state: PropagationState, action: PayloadAction<boolean>) => {
    state.showDialog = action.payload;
};

const setPropagationReducer = (state: PropagationState, action: PayloadAction<IPropagationOptions>) => {
    const { propagateColors, propagateLabelText, propagateLabelColor } = action.payload;
    state.propagateColors = propagateColors;
    state.propagateLabelText = propagateLabelText;
    state.propagateLabelColor = propagateLabelColor;
};

export const propagationSlice = createSlice({
    name: "propagation",
    initialState: initialPropagationState,
    reducers: {
        showPropagation: showPropagationReducer,
        setPropagation: setPropagationReducer,
    },
});

export const PropagationReducer = propagationSlice.reducer;
export const { showPropagation, setPropagation } = propagationSlice.actions;

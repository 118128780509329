import { createSelector } from "reselect";
import { isLCDuplex, isLCSimplex, isLCUniboot, isMMC, isMTP } from "../../../components/pixi/factories/texture";
import { getBuildPolarityConfig, polarityMapsToConfig } from "../../../helpers/workspace/polarity.helper";
import {
    Unit,
    Units,
    convertTo,
} from "../../../models/overlay/header/units-of-measure";
import { AllColors } from "../../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";
import {
    FlameRating,
    IFlameRating,
} from "../../../models/overlay/wizard/setup/flame-rating";
import { toolbarSelectionSelector } from "../../../store/overlay/footer/toolbar/toolbar.selectors";
import { unitsOfMeasureContainerUnitSelector } from "../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { WarningSeverity } from "../../../store/overlay/header/warnings/warnings";
import {
    ConfigurationType,
    ConnLC,
    ConnectorTypes,
    FiberCounts,
    IConnectorType,
    IFiberCount,
    IFiberCountRange,
} from "../../../store/overlay/wizard/wizard";
import { buildConfigSelector } from "../../../store/workspace/build.selectors";
import { currentBuildSelector } from "../../../store/workspace/root.selectors";
import { WorkspaceState } from "../../../store/workspace/workspace.reducers";
import { ViewportStatus } from "../../pixi/viewport/viewport";
import { viewportContextSelector } from "../../pixi/viewport/viewport.selectors";
import {
    BuildPolarity,
    CustomMap,
    GetPolarityMapSessionImgUrl,
    PolarityMap,
    PolarityMapList,
    findPolarityConfig,
    getPolarityMaps,
    matchConnectorTypeSequence,
} from "../build/connector/polarity/polarity";
import { polarityConfigsSelector } from "../build/connector/polarity/polarity.selectors";
import {
    IConfigSession,
    IPolaritySessionMap,
    IRange,
    IUIConnectorType,
    SessionCharacteristic,
    initialConfigSession,
    initialRABSession,
    initialSessionIds,
} from "./ssc";

export const sscSelector = (state: WorkspaceState) => state.ssc;

const sscSessionSelector = createSelector(currentBuildSelector, sscSelector, (currentBuild, ssc) => {
    const configType = currentBuild?.configurationType ?? ConfigurationType.Patching;
    const defaultConfigSession = Object.values(ssc.configSessions).find((s) => s !== undefined);
    const session = ssc.configSessions[configType] || defaultConfigSession;
    return session ?? initialConfigSession;
});

export const configSessionSelector = createSelector(currentBuildSelector, sscSelector, (currentBuild, ssc) => {
    const configType = currentBuild?.configurationType ?? ConfigurationType.Patching;
    const defaultConfigSession = Object.values(ssc.configSessions).find((s) => s !== undefined);
    const session = ssc.configSessions[configType] ?? defaultConfigSession;
    return session ?? initialConfigSession;
});

const sscFeederConnectorTypesSelector = createSelector(configSessionSelector, (config) => {
    let connectors = config.feederEndConnectorTypes.map((t) => uiConnectorToConnectorType(t));
    return config.configurationType === ConfigurationType.Patching
        ? connectors.filter((c) => c.type && !isMMC(c.type))
        : connectors;
});

const sscFiberTypeOptionsSelector = createSelector(configSessionSelector, (config) => config.fiberTypes);

export const sscCurrentBuildFiberTypeSelector = createSelector(
    currentBuildSelector,
    sscFiberTypeOptionsSelector,
    (currentBuild, fiberTypes) => (currentBuild?.fiberType?.length ? currentBuild?.fiberType : fiberTypes[0]?.id)
);

export const sscFiberTypeTrunkColorSelector = createSelector(sscCurrentBuildFiberTypeSelector, (fiberType) => {
    const color = fiberType === "ULTRA" ? "YL" : "AQ";
    const colorhex: string | undefined = AllColors.find((c) => c.id === color)?.hex;
    return { id: color, colorHex: colorhex ? colorhex.replace("#", "0x") : colorhex };
});

const sscDistributionConnectorTypesSelector = createSelector(configSessionSelector, (config) => {
    let connectors = config.distributionConnectorTypes.map((t) => uiConnectorToConnectorType(t));
    return config.configurationType === ConfigurationType.Patching
        ? connectors.filter((c) => c.type && !isMMC(c.type))
        : connectors;
});

export const sscConnectorTypeRecordSelector = createSelector(
    sscFeederConnectorTypesSelector,
    sscDistributionConnectorTypesSelector,
    (feeder, distribution) => {
        const connectorTypes = new Array<IConnectorType>()
            .concat(feeder)
            .concat(distribution)
            .reduce((acc, cur) => {
                acc[cur.type ?? ""] = cur;
                return acc;
            }, {} as Record<string, IConnectorType>);
        return connectorTypes;
    }
);

export const sscBootColorSelector = (connectorType: string) =>
    createSelector(
        configSessionSelector,
        sscCurrentBuildFiberTypeSelector,
        sscConnectorTypeRecordSelector,
        (config, fiberType, connectors) => getSscBootColor(connectorType, config, fiberType, connectors)
    );

export const getSscBootColor = (
    connectorType: string,
    config: IConfigSession,
    fiberType: string,
    connectors: Record<string, IConnectorType>
) => {
    const connector = connectors[connectorType];
    const defaultColor =
        isLCUniboot(connectorType) || isLCSimplex(connectorType) || isLCDuplex(connectorType) ? "BL" : "BK";
    const color = connector ? config.defaultBootColors[fiberType][connector.key] : defaultColor;
    return color;
};

export const sscDefaultTriggerColorSelector = (connectorType?: string) =>
    createSelector(
        configSessionSelector,
        sscCurrentBuildFiberTypeSelector,
        sscConnectorTypeRecordSelector,
        (config, fiberType, connectors) => getSscDefaultTriggerColor(connectorType ?? "", config, fiberType, connectors)
    );

export const getSscDefaultTriggerColor = (
    connectorType: string,
    config: IConfigSession,
    fiberType: string,
    connectors: Record<string, IConnectorType>
) => {
    const connector = connectors[connectorType ?? ""];
    const color = connector ? config.defaultConnectorColors[fiberType][connector.key].name : "Blue";
    return color;
};

export const sscDefaultTriggersColorSelector = createSelector(
    configSessionSelector,
    sscCurrentBuildFiberTypeSelector,
    (config, fiberType) => {
        const colors = config.defaultConnectorColors[fiberType];
        return colors;
    }
);

export const sscConfigSessionSelector = (configType = ConfigurationType.Patching) => {
    return createSelector(sscSelector, (ssc) => ssc.configSessions[configType] ?? initialRABSession);
};

export const sscAllConfigSessionSelector = (state: WorkspaceState) => state.ssc.configSessions;

export const sscCurrentSessionSelector = createSelector(
    currentBuildSelector,
    sscSelector,
    (build, ssc) => ssc.sessionIds[build?.id ?? 0] ?? initialSessionIds[0]
);

export const sscCurrentSessionIdSelector = createSelector(currentBuildSelector, sscSelector, (build, ssc) => {
    return ssc.sessionIds[build?.id ?? 0]?.sessionId ?? "";
});

export const sscConfigSessionIdSelector = (state: WorkspaceState) => state.ssc.configSession.sessionId ?? "";

export const sscSessionConfigStatusSelector = createSelector(
    currentBuildSelector,
    sscSelector,
    (build, ssc) => (ssc.sessionIds[build?.id ?? 0]?.configStatus ?? "N") === "Y"
);

export const sscAllWarningsSelector = createSelector(sscCurrentSessionSelector, (session) => session.warnings);

export const sscNotificationsSelector = createSelector(sscAllWarningsSelector, (sscAllWarnings) => {
    return {
        warnings: sscAllWarnings.filter((w) => w.severity === WarningSeverity.Warning),
        errors: sscAllWarnings.filter((w) => w.severity === WarningSeverity.Error),
    };
});

export const sscSessionIdsSelector = (state: WorkspaceState) => state.ssc.sessionIds;
export const sscSessionBusySelector = (state: WorkspaceState) => state.ssc.sessionBusy;
export const sscConfigSessionBusySelector = (state: WorkspaceState) => state.ssc.configSessionBusy;

export const sscConnectorColorsSelector = createSelector(
    configSessionSelector,
    sscCurrentBuildFiberTypeSelector,
    (config, fiberType) => config.connectorColors[fiberType] ?? {}
);

export const sscConnectorTypeSelector = (connectorType: string) => {
    return createSelector(sscConnectorTypeRecordSelector, (records) => records[connectorType]);
};

export const sscConfigurationTypeSelector = createSelector(configSessionSelector, (session) => {
    const { configurationType: configurationTypeCode, configurationTypes: configurationTypeOptions } = session;
    const configurationType = configurationTypeOptions.find((o) => o.key === configurationTypeCode) ?? {
        code: "",
        key: "",
        value: "",
        description: "",
    };
    return { configurationType, configurationTypeOptions };
});

export const sscFiberCountSelector = createSelector(sscSessionSelector, (session) => {
    let fiberCountOptions: IFiberCount[] = session.fiberCounts
        .filter((f) => f.id.min === f.id.max)
        .map((f) => {
            return {
                key: f.id.min.toString(),
                description: f.description,
                count: f.id.min,
            };
        });

    var uniqueFiberCount: { [fiberCount: number]: IFiberCount } = {};
    fiberCountOptions.forEach((fiberCountOption) => {
        if (!uniqueFiberCount[fiberCountOption.count]) {
            uniqueFiberCount[fiberCountOption.count] = fiberCountOption;
        }
    });

    fiberCountOptions = Object.values(uniqueFiberCount);

    const fiberCountRanges: IFiberCountRange[] = session.fiberCounts
        .filter((f) => f.id.min < f.id.max)
        .map((f) => {
            return {
                min: f.id.min,
                max: f.id.max,
                description: f.description,
            };
        });

    let minFiberCount = 0;
    let maxFiberCount = 0;
    if (fiberCountRanges.length) {
        const rangeOptions = FiberCounts.filter(
            (f) =>
                fiberCountRanges.some((r) => f.count >= r.min && f.count <= r.max) &&
                !fiberCountOptions.some((sscFiberCount) => sscFiberCount.count === f.count)
        );
        fiberCountOptions.push(...rangeOptions);
        minFiberCount = fiberCountRanges.length
            ? fiberCountRanges.map((f) => f.min).reduce((a, b) => Math.min(a, b))
            : 0;
        maxFiberCount = fiberCountRanges.length
            ? fiberCountRanges.map((f) => f.max).reduce((a, b) => Math.max(a, b))
            : 0;
    }

    fiberCountOptions = fiberCountOptions.sort((a, b) => (a.count > b.count ? 1 : -1));
    return { fiberCountOptions, fiberCountRanges, minFiberCount, maxFiberCount };
});

export const sscGripSelector = createSelector(sscSessionSelector, (session) => {
    const gripOptions = session.grips;
    return { gripOptions };
});

export const sscFlameRatingSelector = createSelector(sscSessionSelector, (session) => {
    const sessionFlameRatingOptions = session.flameRatings ?? {};
    const objectValues = Object.values(sessionFlameRatingOptions);
    const flameRatingOptions: IFlameRating[] = objectValues.length
        ? Object.values(sessionFlameRatingOptions).map((c) => csticToFlameRating(c))
        : [];
    return { flameRatingOptions };
});

export const sscSessionPolarityMapsSelector = createSelector(sscSessionSelector, (ssc) => {
    const uniqueMaps: { [key: string]: PolarityMap } = {};
    for (let i = 0; i < ssc.polarityMaps.length; i++) {
        const polarityMap = ssc.polarityMaps[i];
        if (!uniqueMaps[polarityMap.polarityId]) uniqueMaps[polarityMap.polarityId] = sessionToPolarityMap(polarityMap);
    }

    return getPolarityMaps(Object.values(uniqueMaps));
});

export const sscFeederOptionsSelector = createSelector(
    configSessionSelector,
    sscFeederConnectorTypesSelector,
    sscFiberTypeOptionsSelector,
    unitsOfMeasureContainerUnitSelector,
    (config, connectorTypes, fiberTypes, unit) => {
        const { feederEndLegRange, feederEndStaggerRange, feederEndGroupCountRange, feederEndALengthRange } = config;

        return {
            fiberTypes,
            legRange: convertRangeUnit(feederEndLegRange, unit),
            staggerRange: convertRangeUnit(feederEndStaggerRange, unit),
            connectorTypes: connectorTypes,
            groupCounts: feederEndGroupCountRange,
            aLengthRange: convertRangeUnit(feederEndALengthRange, unit),
        };
    }
);

export const sscDistributionOptionsSelector = createSelector(
    configSessionSelector,
    sscDistributionConnectorTypesSelector,
    unitsOfMeasureContainerUnitSelector,
    (config, connectorTypes, unit) => {
        const {
            distributionLegRange,
            distributionStaggerRange,
            distributionGroupCountRange,
            accessPointRange,
            distributionConnectorCountPerGroupRange,
            distributionDropFiberCountRange,
            distributionALengthRange,
        } = config;

        return {
            legRange: convertRangeUnit(distributionLegRange, unit),
            staggerRange: convertRangeUnit(distributionStaggerRange, unit),
            connectorTypes: connectorTypes,
            accessPointRange,
            groupCounts: distributionGroupCountRange,
            connectorsPerGroupCounts: distributionConnectorCountPerGroupRange,
            dropFiberCountRange: distributionDropFiberCountRange,
            aLengthRange: convertRangeUnit(distributionALengthRange, unit),
        };
    }
);

export const sscDistributionOptionsLegRangeSelector = createSelector(
    sscFeederOptionsSelector,
    sscDistributionOptionsSelector,
    toolbarSelectionSelector,
    viewportContextSelector,
    (feeder, distribution, selection, { context }) => {
        return {
            ...distribution,
            aLengthRange:
                selection.selected <= 1 && context === ViewportStatus.Editing
                    ? feeder.aLengthRange
                    : distribution.aLengthRange,
        };
    }
);

export const sscDropOptionsSelector = (position: number) => {
    return createSelector(sscFeederOptionsSelector, sscDistributionOptionsSelector, (feeder, distribution) =>
        position >= 1 ? distribution : feeder
    );
};

export const sscBuildPolaritySelector = createSelector(
    currentBuildSelector,
    sscSessionPolarityMapsSelector,
    (currentBuild, sscMaps) => {
        const sortedSscMaps = [...sscMaps].sort((a, b) =>
            a.sourceConnectors.length > b.sourceConnectors.length ? 1 : -1
        );
        const uniqueSortedMaps = [...sortedSscMaps].filter((m) =>
            matchConnectorTypeSequence(m.sourceConnectors, m.destinationConnectors)
        );

        return currentBuild ? getBuildPolarityConfig(currentBuild, uniqueSortedMaps) : [];
    }
);

export const sscPolarityConfigSelector = createSelector(sscSessionPolarityMapsSelector, (polarityList) =>
    polarityMapsToConfig(polarityList)
);

export const sscDefaultBuildPolaritiesSelector = createSelector(
    sscPolarityConfigSelector,
    polarityConfigsSelector,
    buildConfigSelector,
    (configs, localConfigs, buildConfigs) => {
        const defaultConfigs = configs.map((c) => {
            let polarityMap: PolarityMap | undefined;
            if (c.polarityMaps) {
                polarityMap =
                    c.polarityMaps.find((m) => m.description === "Type B" || m.description === "Type A-B") ??
                    c.polarityMaps[0];
                const localConfig = findPolarityConfig<BuildPolarity>(c.from, c.to, localConfigs);
                if (localConfig && localConfig.polarityMap) {
                    polarityMap = localConfig.polarityMap;
                } else if (buildConfigs && buildConfigs.polarities) {
                    const buildConfig = findPolarityConfig<BuildPolarity>(c.from, c.to, buildConfigs.polarities);
                    if (buildConfig && buildConfig.polarityMap) {
                        polarityMap = buildConfig.polarityMap;
                    }
                }
            }

            return { ...c, polarityMap };
        });

        return defaultConfigs;
    }
);

const sessionToPolarityMap = (
    {
        polarityId,
        polarityDescription,
    }: IPolaritySessionMap
    ): PolarityMap => {
    const { key, sourceConnectors, destinationConnectors } = PolarityMapList.find(m => m.demoKey === polarityDescription) ?? CustomMap;
    return {
        key,
        customKey: polarityId,
        description: polarityDescription,
        sourceConnectors,
        destinationConnectors,
        imageUri: GetPolarityMapSessionImgUrl(polarityId),
    };
};

function convertRangeUnit(range: IRange | undefined, convertUnit: Unit, sscUnit?: Unit) {
    let unit = sscUnit ?? Units.UoMInches;

    const convertedRange: IRange = {
        min: convertTo({ value: range?.min ?? 0, unit }, convertUnit).value,
        max: convertTo({ value: range?.max ?? 0, unit }, convertUnit).value,
    };
    return convertedRange;
}

function uiConnectorToConnectorType(ui: IUIConnectorType): IConnectorType {
    if (ui.fiberCount === ConnLC.fiberCount) {
        return { ...ConnLC, key: ui.id, description: ui.description };
    }
    let connector = ConnectorTypes.find((c) => c.fiberCount === ui.fiberCount && c.pinned === ui.pinned);
    if (ui.fiberCount === 24) {
        if (isMTP(ui.description)) {
            connector = ConnectorTypes.find(
                (c) => isMTP(c.type!) && c.fiberCount === ui.fiberCount && c.pinned === ui.pinned
            );
        } else if (isMMC(ui.description)) {
            connector = ConnectorTypes.find(
                (c) => isMMC(c.type!) && c.fiberCount === ui.fiberCount && c.pinned === ui.pinned
            );
        }
    }
    return connector
        ? { ...connector, description: ui.description, key: ui.id }
        : { description: ui.description, fiberCount: ui.fiberCount, pinned: ui.pinned, key: ui.id, type: ui.id };
}

function csticToFlameRating(cstic: SessionCharacteristic<string>): IFlameRating {
    const flameRating: IFlameRating = {
        code: cstic.id,
        key: cstic.id,
        value: cstic.id as FlameRating,
        description: cstic.description,
    };

    return flameRating;
}

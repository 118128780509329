import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../locales/keys";
import { flameRatingsSelector } from "../../../../../store/workspace/boundaries/boundaries.selectors";
import { SourceSetupContext, setState } from "../../../../../store/overlay/wizard/setup/source-setup/source-setup.reducers";
import { setupSource } from "../../../../../store/workspace/build/build.reducers";
import { ISourceSetupState } from "../../../../../store/overlay/wizard/setup/source-setup/source-setup";
import { currentBuildFinalizedSelector } from "../../../../../store/workspace/build.selectors";
import { SelectProps } from "@corning-ctcm/silica-react";

export const useFlameRating = (locked: boolean) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(SourceSetupContext);
    const buildFinalized = useSelector(currentBuildFinalizedSelector);
    const options = useSelector(flameRatingsSelector);

    const storeDispatch = useDispatch();

    const onFlameRatingChange = useCallback(
        (e: React.ChangeEvent<any>) => {
            const updatedState: ISourceSetupState = { ...state, flameRating: e.target.value };
            storeDispatch(setupSource(updatedState));
            dispatch(setState(updatedState));
        },
        [state, dispatch, storeDispatch]
    );

    const selectProps: SelectProps = {
        id: "flame-rating-dropdown",
        palette: "primary",
        className: "field",
        label: t(LocalizationKeys.FlameRating),
        value: state.flameRating,
        disabled: buildFinalized || locked,
        helperText: "",
        units: "",
        onChange: onFlameRatingChange,
    };

    return {
        selectProps,
        options,
    };
};

import { IColorDialogProps } from "../../../../models/ui/dialog/color-dialog";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { IDialogHeaderProps } from "../../../../models/ui/dialog/dialog-header";
import { IDialogFooterProps } from "../../../../models/ui/dialog/dialog-footer";
import { showDialog } from "../../../../store/workspace/dialog/dialog.reducer";
import { LocalizationKeys } from "../../../../locales/keys";

export const useColorDialog = (props: IColorDialogProps) => {
    const {
        context,
        preventOutsideDismiss,
        colors,
        currentColor,
        displayFooter,
        onColorButtonClick,
        onResetButtonClick,
        className,
    } = props;
    const { state, dispatch } = context;
    const { open } = state.props;
    const { t } = useTranslation();

    const onClose = useCallback(() => {
        if (open) {
            dispatch(showDialog(false));
        }
    }, [open, dispatch]);

    const headerProps: IDialogHeaderProps = {
        id: "colors",
        title: t(LocalizationKeys.ColorSelection),
        closable: true,
        onClose,
    };

    const footerProps: IDialogFooterProps = {
        onCancel: onClose,
        onAction: onResetButtonClick,
        actionButtonText: t(LocalizationKeys.Reset),
    };

    return {
        context,
        open,
        onClose,
        preventOutsideDismiss,
        headerProps,
        footerProps,
        colors,
        currentColor,
        onColorButtonClick,
        displayFooter,
        className,
    };
};

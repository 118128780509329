import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router";
import { Workspace } from "../components/workspace";
import { getToken, parseJwt, usePlatformService } from "@corning-ctcm/platform-client";
import { setTokenProps, login } from "../store/authentication/authentication.reducers";
import { loggedInSelector } from "../store/authentication/authentication.selectors";
import { AppDispatch } from "../store/workspace/workspace.reducers";
import { canAccessApplication } from "../models/roles/roles";
import { useMemo } from "react";

export const LandingPage = () => {
    const loggedIn = useSelector(loggedInSelector);
    const platformService = usePlatformService();

    const storeDispatch = useDispatch<AppDispatch>();

    const { appAccess, searchParams } = useMemo(() => {
        let appAccess = false;
        let searchParams: string | undefined = undefined;
        const cachedToken = getToken();
        if (cachedToken) {
            const token = parseJwt(cachedToken);
            if (canAccessApplication(token.roles)) { // Checks if user has rab:app_access role
                if (token.exp > Date.now() / 1000) { // We make sure that token is still valid
                    appAccess = true;
                    if (!loggedIn) { // It not logged in, we'll consider that the cached token can be used to login
                        storeDispatch(setTokenProps(token));
                        storeDispatch(login(cachedToken));
                    }
                }
            } else {
                searchParams = "?appAccessError=true"; // Will force the redirection to the login page with an error message
                platformService.logout(); // We force remove the token from the cache
            }
        }
        return { appAccess, searchParams }
    }, [loggedIn, storeDispatch, platformService]);

    return (
        appAccess ?
        <Workspace /> : 
        <Navigate to={{ pathname: "/login", search: searchParams }} />
    )
}
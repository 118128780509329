import i18n from "i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import { useSelector } from "react-redux";
import { IBuildData, IBuildInfo } from "../../../../../store/workspace/build/build";
import {
    buildPolarityDescriptionFactory,
    buildInfoSelectorFactory,
} from "../../../../../store/workspace/build.selectors";
import { useMemo } from "react";
import { getConnectorTypeDescription, getConnectorTypeFromDrop } from "../../../../../store/overlay/wizard/wizard";
import { getNbConnectors } from "../../../../../store/workspace/build/connector/connector";

export const useBuildInfo = (projectId: number) => {
    const buildInfoSelector = useMemo(() => buildInfoSelectorFactory(projectId), [projectId]);
    const buildInfo = useSelector(buildInfoSelector);
    const polarityDescriptionSelector = useMemo(
        () => buildPolarityDescriptionFactory(buildInfo.fiberMapKeys),
        [buildInfo.fiberMapKeys]
    );
    const polarityDescription = useSelector(polarityDescriptionSelector);
    return {
        name: getName(buildInfo),
        catalogCode: buildInfo.catalogCode ?? buildInfo.partNumber ?? "",
        description: getDescription(buildInfo),
        polarity: polarityDescription,
    };
};

export function getName(build: IBuildInfo) {
    return build.name ? build.name : getDefaultName(build.buildId);
}

export function getDefaultName(buildId?: number) {
    return buildId ? i18n.t(LocalizationKeys.CableNumber) + buildId : i18n.t(LocalizationKeys.CableName);
}

export function generateCopyNumber(str: string, list: string[]) {
    var filteredList = list.filter((x) => x.length === str.length);

    for (let i = 0; i < filteredList.length + 1; i++) {
        let copyNumber = i === 0 ? "" : i18n.t(LocalizationKeys.CopyNumber, { value: i });
        let copyStr = str + copyNumber;

        if (!list.find((x) => x === copyStr)) {
            return copyStr;
        }
    }

    return str;
}

export function getDescription(buildInfo: IBuildInfo) {
    return buildInfo.description && buildInfo.description.length > 0
        ? buildInfo.description
        : getDefaultDescription(buildInfo);
}

export function getDefaultDescription(buildInfo: IBuildInfo) {
    const fiberCount = buildInfo.fiberCount!;
    const nbConnectorsSrc = buildInfo.nbConnectorsSrc!;
    const connectorTypeSrc = buildInfo.connectorTypeSrc!;
    const nbTAPs = buildInfo.nbTAPs!;
    const nbConnectorsDst = buildInfo.nbConnectorsDst!;
    const connectorTypeDst = buildInfo.connectorTypeDst!;

    let description = i18n.t(LocalizationKeys.DefaultDescription, {
        fiberCount: fiberCount,
        nbConnectorsSrc: nbConnectorsSrc,
        connectorTypeSrc: connectorTypeSrc,
        nbTAPs: nbTAPs,
        nbConnectorsDst: nbConnectorsDst,
        connectorTypeDst: connectorTypeDst,
    });
    if (description.length > 85) {
        description = description.slice(0, 85) + i18n.t(LocalizationKeys.Ellipsis);
    }

    return description;
}

export function getDefaultBuildDescription(build: IBuildData) {
    const [source, ...destinations] = build.drops;
    const fiberCount = build.fiberCount;
    const nbConnectorsSrc = getNbConnectors(source.groups);
    const connectorTypeSrc = getConnectorTypeDescription(getConnectorTypeFromDrop(source));
    const nbTAPs = destinations.length;
    const nbConnectorsDst = destinations.map((d) => getNbConnectors(d.groups)).reduce((a, b) => a + b, 0);
    const connectorTypeDst = getConnectorTypeDescription(getConnectorTypeFromDrop(destinations[0]));

    let description = i18n.t(LocalizationKeys.DefaultDescription, {
        fiberCount: fiberCount,
        nbConnectorsSrc: nbConnectorsSrc,
        connectorTypeSrc: connectorTypeSrc,
        nbTAPs: nbTAPs,
        nbConnectorsDst: nbConnectorsDst,
        connectorTypeDst: connectorTypeDst,
    });
    if (description.length > 85) {
        description = description.slice(0, 85) + i18n.t(LocalizationKeys.Ellipsis);
    }

    return description;
}

import { ConnectorAssignmentRowProps } from "../../../../../models/overlay/polarity/connector-assignment/connector-assignment-row";
import { useCallback, useContext, useMemo } from "react";
import { resetSelection, selectAssignment, selectRows, setPolarityKey } from "../../../../../store/overlay/polarity/connector-assignment/connector-assignment.reducer";
import { ConnectorAssignmentContext } from "../../../../../store/overlay/polarity/connector-assignment/connector-assignment";
import { useDispatch, useSelector } from "react-redux";
import { connectorAssignmentRowSelectorFactory } from "../../../../../store/workspace/build.selectors";
import { getPropagationStatus } from "../../../../../store/overlay/polarity/propagation/propagation";
import { isPropagationActiveSelector, propagationOptionsSelector } from "../../../../../store/overlay/polarity/propagation/propagation.selectors";
import { LocalizationKeys } from "../../../../../locales/keys";
import { t } from "i18next";
import { IconButtonProps } from "@corning-ctcm/silica-react";
import { DEFAULT_BLUE, ORANGE } from "../../../../../store/pixi/connector-highlights/connector-highlights";
import { IIconProps } from "../../../../../models/icons";
import { setStatusState } from "../../../../../store/overlay/header/status-icon/status-icon.reducer";
import { BuildService } from "../../../../../services/build.service";
import { deleteConnectorAssignment } from "../../../../../store/workspace/build/build.reducers";
import { setNotification } from "../../../../../store/overlay/notification/notification.reducers";
import { buildDisabledSelector } from "../../../../../store/workspace/build/build.selector";
import { setShowConnectorAssignment, setShowFiberMapping } from "../../../../../store/overlay/overlay.reducers";
import { PolarityContext } from "../../../../../store/overlay/polarity/polarity";
import { setConnectorAssignment } from "../../../../../store/overlay/polarity/polarity.reducer";
import { fiberMapsSelector, getDisplayedFiberMap } from "../../../../../store/workspace/build/connector/polarity/polarity.selectors";

export const useConnectorAssignmentRow = ({ connector, editable }: ConnectorAssignmentRowProps) => {
    const data = useSelector(connectorAssignmentRowSelectorFactory(connector));
    const {
        buildId,
        side,
        dropPosition,
        groupPosition,
        connectorId,
        connectorIndex,
        fiberCount,
        fiberCountInUse,
        unassignedFibers,
        blockedFibers,
        assignment
    } = data;

    const assigned = !!assignment; 
    const disabled = useSelector(buildDisabledSelector);
    const fiberMaps = useSelector(fiberMapsSelector);
    const isPropagationActive = useSelector(isPropagationActiveSelector);
    const propagationOptions = useSelector(propagationOptionsSelector);

    const {
        state: { feederSelection, distributionSelection, assignedSelection },
        dispatch: localDispatch,
    } = useContext(ConnectorAssignmentContext);
    const { dispatch: polarityDispatch } = useContext(PolarityContext);
    const storeDispatch = useDispatch();

    const { selected, selectionIndex } = useMemo(() => {
        let selection = side === "feeder" ? feederSelection : distributionSelection;
        if (assigned) {
            selection = assignedSelection
        }
        const selected = selection.includes(connectorId);
        let selectionIndex = -1;
        if (selected && !assigned) {
            selectionIndex = selection.indexOf(connectorId);
        }
        return { selected, selectionIndex };
    }, [connectorId, side, feederSelection, distributionSelection, assigned, assignedSelection]);

    const onRowClick = useCallback(() => {
        if (assignment) {
            const feederConnectorIds = assignment.feederConnectors.map(c => c.id).filter((id): id is number => !!id);
            const distributionConnectorIds = assignment.distributionConnectors.map(c => c.id).filter((id): id is number => !!id);
            localDispatch(selectAssignment([...feederConnectorIds, ...distributionConnectorIds]));
            const displayedFiberMap = getDisplayedFiberMap(assignment.fiberMapId, fiberMaps);
            if (displayedFiberMap && displayedFiberMap.key !== undefined) {
                localDispatch(setPolarityKey(displayedFiberMap.key));
            }
        } else {
            localDispatch(selectRows([connector]));
        }
    }, [assignment, localDispatch, fiberMaps, connector]);

    const onButtonCellClick = useCallback((e: any) => {
        e.stopPropagation();
    }, []);

    const onDeleteAssignment = useCallback(async () => {
        if (!assignment) return;
        
        const { assignmentIndex } = assignment;
        storeDispatch(setStatusState("saving"));
        storeDispatch(deleteConnectorAssignment(assignmentIndex));
        try {
            const deleted = await new BuildService().deleteConnectorAssignment(buildId, assignmentIndex);
            if (deleted) {
                storeDispatch(setNotification({ 
                    palette: "success",
                    message: t(LocalizationKeys.DeleteConnectorAssignmentsSuccess) 
                }));
            }
        } catch {
            storeDispatch(setNotification({ 
                palette: "error",
                message: t(LocalizationKeys.DeleteConnectorAssignmentsError) 
            }));
        } finally {
            localDispatch(resetSelection());
            storeDispatch(setStatusState("saved"));               
        }
    }, [assignment, storeDispatch, buildId, localDispatch]);

    const onViewFiberMap = useCallback(() => {
        if (!assignment) return;
        
        storeDispatch(setShowFiberMapping(true));
        storeDispatch(setShowConnectorAssignment(false));
        polarityDispatch(setConnectorAssignment({ connectorId, assignment }));
    }, [assignment, storeDispatch, polarityDispatch, connectorId]);

    let className = "";
    let fill = ORANGE.fill;
    let stroke = ORANGE.stroke;
    if (assigned) {
        className = "assigned";
        fill = DEFAULT_BLUE.fill;
        stroke = DEFAULT_BLUE.stroke;
    }
    if (selected) {
        className = assigned ? "assigned selected": "selected";
    }
    let fiberAssignmentLabel = `${fiberCountInUse - unassignedFibers}/${fiberCountInUse - blockedFibers}`;
    if (fiberCountInUse !== fiberCount) {
        fiberAssignmentLabel = `${fiberAssignmentLabel} (${fiberCount})`
    }

    const label = `${connectorIndex} | ${fiberAssignmentLabel}`;

    const assignedIcon = {
        className: disabled ? "material-icons disabled" : "material-icons",
        icon: "check_circle",
    };

    const selectionIcon: IIconProps = { 
        text: selectionIndex > -1 ? `${selectionIndex + 1}` : "\u2611",
        fill,
        stroke
    };

    const deleteIconProps: Omit<IconButtonProps, "icon"> = {
        className: "delete-icon",
        palette: "error",
        mode: "main",
        placement: "bottom",
        disabled,
        onClick: onDeleteAssignment,
    };

    const editIconProps: Omit<IconButtonProps, "icon"> = {
        className: "edit-icon",
        palette: "primary",
        mode: "main",
        placement: "bottom",
        disabled,
        onClick: onViewFiberMap,
    };

    const propagationSuccessful = t(LocalizationKeys.PropagationSuccessful);
    const propagationStatus = useMemo(() => {
        if (disabled) {
            return t(LocalizationKeys.PropagationResultPending);
        }
        if (isPropagationActive && assignment) {
            const status = getPropagationStatus(assignment.feederConnectors, assignment.distributionConnectors, propagationOptions);
            return status.length > 0 ? status : propagationSuccessful;
        }
    }, [
        disabled,
        isPropagationActive,
        assignment,
        propagationOptions,
        propagationSuccessful
    ]);

    const propagationIconProps: Omit<IconButtonProps, "icon"> = {
        className: "propagation-icon",
        palette: "primary",
        mode: "main",
        title: propagationStatus,
        placement: "bottom",
    };

    const propagation = {
        active: isPropagationActive,
        successful: propagationStatus === propagationSuccessful,
        iconProps: propagationIconProps,
    };

    const enableButtonClick = assigned && !selected;
    return {
        row: {
            id: `${side}-${dropPosition}-group-${groupPosition}-connector-${data.connectorPosition}`,
            key: connectorId,
            className,
            label,
            editable,
            assigned,
            selected,
            disabled,
            onClick: disabled ? undefined: onRowClick
        },
        assignedIcon, 
        selectionIcon,
        propagation,
        actions: {
            delete: {
                button: {
                    className: disabled ? "delete-button editDisabled" : "delete-button",
                    onClick: enableButtonClick ? onButtonCellClick : undefined
                },
                icon: deleteIconProps
            },
            edit: {
                button: {
                    className: "edit-button",
                    onClick: enableButtonClick ? onButtonCellClick : undefined
                },
                icon: editIconProps
            },
        }
    };
};

import { createSelector } from "reselect";
import { WorkspaceState } from "../.././workspace/workspace.reducers";

export const highlightsSelector = (state: WorkspaceState) => state.connectorHighlights.highlights;
export const hoveredConnectorIdSelector = (state: WorkspaceState) => state.connectorHighlights.hoveredConnectorId;
export const selectedConnectorIdSelector = (state: WorkspaceState) => state.connectorHighlights.selectedConnectorId;

export const getConnectorHighlightSelectorFactory = (connectorId: number) => {
    return createSelector(
        highlightsSelector,
        (highlights) => highlights.find(h => h.connectorId === connectorId)
    )
};

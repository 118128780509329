import { createSelector } from "@reduxjs/toolkit";
import { WorkspaceState } from "../../../workspace/workspace.reducers";
import { connectorAssignmentsSelector } from "../../../workspace/build.selectors";
import { IPropagationOptions, Propagation } from "./propagation";

export const propagationStateSelector = (state: WorkspaceState) => state.propagation;
export const showPropagationSelector = (state: WorkspaceState) => state.propagation.showDialog;
export const isPropagationActiveSelector = (state: WorkspaceState) => state.propagation.propagateColors || state.propagation.propagateLabelText || state.propagation.propagateLabelColor;

export const propagationOptionsSelector = createSelector(
    propagationStateSelector,
    ({ propagateColors, propagateLabelText, propagateLabelColor }): IPropagationOptions => ({ propagateColors, propagateLabelText, propagateLabelColor })
);

export const propagationSelector = createSelector(
    connectorAssignmentsSelector,
    propagationOptionsSelector,
    (connectorAssignments, propagationOptions): Propagation => ({ connectorAssignments, propagationOptions })
)
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { IconButton } from "@corning-ctcm/silica-react";
import { LabelFields } from "./label-fields/label-fields";
import { LabelsSectionProps } from "../../../../../models/overlay/reports/report-settings/labels-section";
import { useLabelsSection } from "./labels-section.hooks";

export const LabelsSection = ({ labels, setLabels }: LabelsSectionProps) => {
    const { setLabel, addIcon, removeIcon } = useLabelsSection({ labels, setLabels });
    return (
        <div className="labels-panel">
            {labels &&
                labels.map((l, i) => (
                    <LabelFields key={i} index={i} label={l} labelsLength={labels.length} setLabel={setLabel} />
                ))}
            <IconButton icon={<AddCircleOutline />} {...addIcon} />
            <IconButton icon={<RemoveCircleOutline />} {...removeIcon} />
        </div>
    );
};

import "./locales/i18n";
import { store } from "./store/workspace/workspace.reducers";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { RapidBundle } from "./rapid-bundle";

import "./index.css";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <Provider store={store}>
        <RapidBundle />
    </Provider>
);

import React, { PropsWithChildren } from "react";
import { Container } from "@pixi/react"
import { useBoundingBox } from "./bounding-box.hooks";
import { BoundingBoxContext, IBoundingBoxProps } from "../../../../models/pixi/decorators/bounds/bounding-box";

export const BoundingBox: React.FC<PropsWithChildren<IBoundingBoxProps>> = (props) => {
    const { context, onMouseOver, onMouseOut } = useBoundingBox(props);

    return (
        <BoundingBoxContext.Provider value={context}>
            <Container eventMode="static" interactiveChildren={true} mouseover={onMouseOver} mouseout={onMouseOut}>
                {props.children}
            </Container>
        </BoundingBoxContext.Provider>
    );
};

import React from "react";
import { Select, SelectItem as Option } from "@corning-ctcm/silica-react";
import { useFlameRating } from "./flame-rating.hooks";

export const FlameRating: React.FC<{ disabled: boolean }> = (props: { disabled: boolean }) => {
    const { selectProps, options } = useFlameRating(props.disabled);

    return (
        <Select {...selectProps}>
            {options.map((fr, i) => (
                <Option id={`flame-rating-option-${i}`} key={fr.value} value={fr.value}>
                    {fr.description}
                </Option>
            ))}
        </Select>
    );
};

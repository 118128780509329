import { createContext } from "react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialSourceSetupContext, initialSourceSetupState, ISourceSetupContext, ISourceSetupState } from "./source-setup";

export const setStateReducer = (state: ISourceSetupState, action: PayloadAction<ISourceSetupState>) => {
    Object.assign(state, action.payload);
};

const sourceSetupSlice = createSlice({
    initialState: initialSourceSetupState,
    name: "source-setup",
    reducers: {
        setState: setStateReducer,
    },
});

export const SourceSetupReducer = sourceSetupSlice.reducer;
export const SourceSetupContext = createContext<ISourceSetupContext>(initialSourceSetupContext);

export const { setState } = sourceSetupSlice.actions;

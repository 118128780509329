import { IconButton } from "@corning-ctcm/silica-react";
import { ControlPointDuplicate, Delete, Edit, ListAlt } from "@mui/icons-material";
import { GenericDialog } from "../../../../ui/dialog/generic-dialog";
import { DuplicateDialog } from "../duplicate-dialog/duplicate-dialog";
import { useBuildToolbar } from "./build-toolbar.hooks";

export const BuildToolbar = (props: { projectId: number; deleteDisabled: boolean; editDisabled: boolean }) => {
    const { projectId, deleteDisabled, editDisabled } = props;
    const {
        dialogContext,
        duplicateProps,
        detailsProps,
        editProps,
        deleteProps,
        triggerDelete,
        displayDialog,
        dialogMessage,
        dialogTitle,
        onDialogClose,
    } = useBuildToolbar(projectId);
    return (
        <div className="build-toolbar">
            <IconButton
                {...duplicateProps}
                icon={<ControlPointDuplicate />}
            />
            <IconButton
                {...detailsProps}
                icon={<ListAlt />}
            />
            <IconButton
                {...editProps}
                icon={<Edit />}
                disabled={editDisabled}
            />
            <IconButton
                {...deleteProps}
                icon={<Delete />}
                disabled={deleteDisabled}
            />
            <GenericDialog
                title={dialogTitle}
                message={dialogMessage}
                display={displayDialog}
                closable
                onClose={onDialogClose}
                critical
                onConfirm={() => {
                    onDialogClose();
                    triggerDelete(projectId);
                }}
            />
            <DuplicateDialog context={dialogContext} projectId={projectId} />
        </div>
    );
};

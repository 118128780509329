import { useInteractions } from "./selection-box.hooks";
import { InteractiveRectangle } from "../rectangle/interactive-rectangle";
import { IRectangleDrawingProps } from "../../../../../models/pixi/decorators/bounds/interactive-rectangle";

export const SelectionBox = () => {
    const { display, name, eventMode, selected, bounds, layout, onMouseClick } = useInteractions();
    const rectangleProps: IRectangleDrawingProps = { name, eventMode, selected, bounds, layout, onMouseClick };
    if (!display) {
        return null;
    }

    return <InteractiveRectangle {...rectangleProps} />;
};

import { usePolarityButton } from "./polarity-button.hooks";
import { Tooltip, TextButton } from "@corning-ctcm/silica-react";

import "../../../../css/overlay/header/header.scss";

export const PolarityButton = () => {
    const { buttonProps, title, text, } = usePolarityButton();
    return (
        <Tooltip placement={"bottom"} title={title} arrow>
            <span>
                <TextButton { ...buttonProps }>
                    {text}
                </TextButton>
            </span>
        </Tooltip>
    );
};

export default PolarityButton;

import { SecuredService } from "./abstract-secured-v2.service";
import { OrgLabelBundle } from "../store/workspace/build/connector/label-scheme/label-scheme";

export class LabelSchemeService extends SecuredService {
    private readonly baseUrl = "/api/labelscheme";

    public async getOrgBundles(): Promise<OrgLabelBundle[] | undefined> {
        return await this.get(`${this.baseUrl}/org`);
    }
}

import React from "react";
import { useCustomization } from "./customization.hooks";
import { FormControlLabel } from "@mui/material";
import { Typography, TextField, TextButton, Checkbox } from "@corning-ctcm/silica-react";

import "../../../../../css/overlay/reports/label-scheme/customization.scss";

export const Customization: React.FC = () => {
    const { title, pasteProps, reverseProps, rackNumberProps, resetProps } = useCustomization();
    return (
        <div className="customization">
            {pasteProps.allowPasting ? (
                <div id="paste-container">
                    <div id="paste-area">
                        <TextField {...pasteProps.textArea} />
                    </div>
                    <Typography variant="caption">{pasteProps.caption}</Typography>
                </div>
            ) : (
                <div id="customization-container">
                    <Typography variant="h6">
                        {title}
                    </Typography>
                    <div className="controls">
                        <FormControlLabel
                            label={reverseProps.label}
                            control={<Checkbox id="reverse-rack-order-checkbox" {...reverseProps.checkboxProps} />}
                        />
                        <TextField
                            id="starting-rack-number-field"
                            palette="primary"
                            label={rackNumberProps.fieldProps.label}
                            value={rackNumberProps.inputProps.value}
                            onChange={rackNumberProps.inputProps.onChange}
                        />
                    </div>
                    <div className="button-container">
                        <TextButton id="label-scheme-reset-button" {...resetProps.buttonProps}>
                            {resetProps.label}
                        </TextButton>
                    </div>
                </div>
            )}
        </div>
    );
};

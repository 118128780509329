import React from "react";
import { ConnectorAssignmentRowProps } from "../../../../../models/overlay/polarity/connector-assignment/connector-assignment-row";
import { useConnectorAssignmentRow } from "./connector-assignment-row.hooks";
import { DataTableCell, DataTableRow } from "@rmwc/data-table";
import { TableRowSelectionIcon } from "../../../../icons/table-row-selection-icon";
import { Cancel, Link, LinkOff, Visibility } from "@mui/icons-material";
import { Typography, IconButton } from "@corning-ctcm/silica-react"

export const ConnectorAssignmentRow: React.FC<ConnectorAssignmentRowProps> = (props) => {
    const { row, assignedIcon, selectionIcon, propagation, actions } = useConnectorAssignmentRow(props);
    return (
        <DataTableRow id={row.id} key={row.key} className={row.className} onClick={row.onClick}>
            <DataTableCell className="assignment-icon">
                {
                    row.assigned && !row.selected ?
                    <i className={assignedIcon.className}>
                        {assignedIcon.icon}
                    </i> : 
                    <TableRowSelectionIcon {...selectionIcon} />
                }
            </DataTableCell>
            <DataTableCell className="connector-info">
                <Typography variant="body2">
                    {row.label}
                </Typography>
            </DataTableCell>
            {
                row.editable ? 
                <>
                    <DataTableCell className={actions.delete.button.className} onClick={actions.delete.button.onClick}>
                        <IconButton {...actions.delete.icon} icon={<Cancel />} />
                    </DataTableCell>
                    <DataTableCell className={actions.edit.button.className} onClick={actions.edit.button.onClick}>
                        <IconButton {...actions.edit.icon} icon={<Visibility />} />
                    </DataTableCell>
                </> : null
            }
            {
                propagation.active ? (
                    <DataTableCell className="propagation-cell">
                        <IconButton {...propagation.iconProps} icon={propagation.successful ? <Link /> : <LinkOff />} />
                    </DataTableCell>
                ) : null
            }
        </DataTableRow>
    );
};

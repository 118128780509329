import { Filters } from "../../factories/filters";
import { DropProps, FurcationLabelContainer } from "../../../../models/pixi/build/build";
import { IFurcationProps, STAGGER_OFFSET_Y } from "../../../../models/pixi/build/connectors/connector-furcation";
import { Rectangle } from "../../../../models/pixi/decorators/interaction";
import { getConnectorTexture, isMTP } from "../../factories/texture";
import { DEFAULT_TEXT_RESOLUTION, IPoint, IPointEmpty } from "../../../../models/pixi/pixi";
import { useMemo } from "react";
import { CONNECTOR_STEP_OFFSET } from "../../../../models/pixi/build/connectors/connectors";
import { ICollapseMarkerProps } from "../../../../models/pixi/decorators/markers/collapse-marker";
import { AP_SIZE } from "../../../../models/pixi/build/drop-base/access-point";
import { useText } from "../../decorators/text/text.hooks";
import { _ReactPixi } from "@pixi/react";
import { getConnectorType, getConnectorTypeDescription } from "../../../../store/overlay/wizard/wizard";

export const useDrop = ({ x, y, context, legProps }: DropProps) => {
    const {
        side,
        connectorType,
        groupsCollapsed,
        connectorsCollapsed,
        nbConnectors,
        furcationPoint,
        enabled,
        containerName,
        fiberCountInUse,
    } = context;
    const greyScaleFilter = Filters.getInstance().greyscaleMatrix;
    greyScaleFilter.enabled = !enabled;

    const furcation: IPoint = furcationPoint ?? IPointEmpty;
    const texture = getConnectorTexture(connectorType);

    const xs = legProps.map((m) => m.location.x);
    const ys = legProps.map((m) => m.location.y);

    const longestStaggerPosition = Math.max(...legProps.map((l) => l.staggerPosition));
    const furcationOffsetY = (longestStaggerPosition + 2) * STAGGER_OFFSET_Y;

    const start: IPoint = { x: Math.min(...xs), y: Math.min(...ys) };
    const end: IPoint = { x: Math.max(...xs), y: Math.max(...ys) };
    const labelOffsetY = getLabelStep(connectorType, connectorsCollapsed);
    const furcationEndY = end.y + furcationOffsetY;

    const width = end.x - start.x + texture.width;
    const height = furcationEndY - furcation.y;
    const buffer: Rectangle = { x: start.x, y: furcation.y, width, height };
    const furcationProps: IFurcationProps = { legProps };

    const furcationLabelPosition: IPoint = useMemo(() => {
        return {
            x: (start.x + end.x) * 0.5 + CONNECTOR_STEP_OFFSET,
            y: end.y + furcationOffsetY + labelOffsetY,
        };
    }, [start.x, end.x, end.y, furcationOffsetY, labelOffsetY]);

    const connectorTypeText = getConnectorTypeDescription({ ...getConnectorType(connectorType), fiberCountInUse })
    const {
        text: furcationLabel,
        textStyle: furcationLabelStyle,
        textSize: furcationLabelSize,
    } = useText({ text: `${nbConnectors} x ${connectorTypeText}` });

    const furcationLabelAnchor: IPoint = { x: 0.5, y: 0 };
    const furcationLabelProps: _ReactPixi.IText = {
        name: `${containerName}-${FurcationLabelContainer}`,
        text: furcationLabel ?? "",
        style: furcationLabelStyle,
        anchor: furcationLabelAnchor,
        resolution: DEFAULT_TEXT_RESOLUTION,
        roundPixels: true,
        ...furcationLabelSize,
        ...furcationLabelPosition,
    };

    const collapseStartY = -AP_SIZE.height * 0.5;
    const collapseMarker: ICollapseMarkerProps = {
        display: groupsCollapsed,
        location: {
            x: side === "feeder" && furcationPoint ? furcationPoint.x : 0,
            y: collapseStartY - 30,
        },
    };

    return {
        x,
        y,
        context,
        greyScaleFilter,
        buffer,
        furcationProps,
        furcationLabelProps,
        collapseMarker,
    };
};

const getLabelStep = (connectorType: string, collapsed: boolean): number => {
    if (isMTP(connectorType)) {
        return collapsed ? 48 : 24;
    }
    return collapsed ? 24 : 0;
};

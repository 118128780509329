import React from "react";
import { useSelector } from "react-redux";
import { unitsOfMeasureContainerUnitNameSelector } from "../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { t } from "i18next";
import { LocalizationKeys } from "../../../locales/keys";

type CableTrunkLengthRow = {
    key: string;
    position: number;
    section: string;
    aLength: string;
    aCumulative: string;
};

export type CableTrunkLengthTableProps = { rows: CableTrunkLengthRow[] };

export const CableTrunkLengthTable: React.FC<CableTrunkLengthTableProps> = ({ rows }) => {
    const unitName = useSelector(unitsOfMeasureContainerUnitNameSelector);
    return (
        <Table sx={{ width: "232px" }} id="cable-trunk-length-table">
            <TableHead>
                <TableRow>
                    <TableCell>{t(LocalizationKeys.Drop)}</TableCell>
                    <TableCell>{t(LocalizationKeys.Section)}</TableCell>
                    <TableCell>{t(LocalizationKeys.ALengthIncremental, { unitName })}</TableCell>
                    <TableCell>{t(LocalizationKeys.ALengthCumulative, { unitName })}</TableCell>
                    <TableCell>{t(LocalizationKeys.Comment)}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((r) => (
                    <TableRow key={r.key}>
                        <TableCell>{r.position}</TableCell>
                        <TableCell>{r.section}</TableCell>
                        <TableCell>{r.aLength}</TableCell>
                        <TableCell>{r.aCumulative}</TableCell>
                        <TableCell>{"                                                  "}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

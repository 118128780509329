import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./wizard";
import {
    clearSavedDataAction,
    enableNextStepAction,
    restartWizardAction,
    saveBuildDataAction,
    saveDestinationSetupAction,
    saveSourceSetupAction,
    setApplyEditChangesAction,
    setCurrentStepAction,
    setDropCheckboxDisabledAction,
    setIsDynamicUpdateDisabledAction,
    setWizardDisplayAction,
    updateConnectorTypeChangedAction,
    setFocusOnErrorAction,
} from "./wizard.actions";

const wizardSlice = createSlice({
    name: "wizard",
    initialState,
    reducers: {
        setWizardDisplay: setWizardDisplayAction,
        setCurrentStep: setCurrentStepAction,
        enableNextStep: enableNextStepAction,
        setApplyEditChanges: setApplyEditChangesAction,
        restartWizard: restartWizardAction,
        saveBuildData: saveBuildDataAction,
        setDropCheckboxDisabled: setDropCheckboxDisabledAction,
        saveSourceSetup: saveSourceSetupAction,
        saveDestinationSetup: saveDestinationSetupAction,
        clearSavedData: clearSavedDataAction,
        updateConnectorTypeChanged: updateConnectorTypeChangedAction,
        setIsDynamicUpdateDisabled: setIsDynamicUpdateDisabledAction,
        setFocusOnError: setFocusOnErrorAction,
    },
});

export const WizardReducer = wizardSlice.reducer;
export const {
    setWizardDisplay,
    setCurrentStep,
    enableNextStep,
    setApplyEditChanges,
    restartWizard,
    saveBuildData,
    setDropCheckboxDisabled,
    saveSourceSetup,
    saveDestinationSetup,
    clearSavedData,
    updateConnectorTypeChanged,
    setIsDynamicUpdateDisabled,
    setFocusOnError,
} = wizardSlice.actions;

import React from "react";
import { useCollapseOptions } from "./collapse-options.hooks";
import { Checkbox, Dialog } from "@corning-ctcm/silica-react";

import "../../../../css/overlay/header/collapse-options.scss";

export const CollapseOptions: React.FC = () => {
    const { dialogProps, checkboxes } = useCollapseOptions();
    return (
        <Dialog {...dialogProps}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Checkbox {...checkboxes.across} />
                <Checkbox {...checkboxes.within} />
            </div>
        </Dialog>
    );
};

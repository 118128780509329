import { IColor } from "../../../../models/ui/dialog/color-dialog";

export interface ITriggerInfo {
    groupPosition: number;
    connectorType: string;
    color: IColor;
}

export interface ITriggerManagementState {
    triggers: ITriggerInfo[];
    selectedTrigger?: ITriggerInfo;
}

export const initialTriggerManagementState: ITriggerManagementState = {
    triggers: [],
};

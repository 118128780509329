import { Dialog, FileUpload } from "@corning-ctcm/silica-react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { FileUploadDialogProps } from "../../../../models/overlay/reports/report-settings/file-upload-dialog";

export const FileUploadDialog = ({
    display,
    files,
    invalidFiles,
    accept,
    onConfirm,
    onClose,
    onUpload,
    onDelete,
}: FileUploadDialogProps) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={display}
            title={t(LocalizationKeys.UploadImage)}
            onClose={onClose}
            actions={{
                confirmText: t(LocalizationKeys.Upload),
                onConfirmClick: onConfirm,
                cancelText: t(LocalizationKeys.Cancel),
                onCancelClick: onClose,
                disableConfirm: !!invalidFiles?.length || !files.length,
            }}
            className="file-upload-dialog"
        >
            <FileUpload
                files={files}
                invalidFiles={invalidFiles}
                title={t(LocalizationKeys.DragYourFileHere)}
                subtitle={t(LocalizationKeys.Or).toLocaleUpperCase()}
                label={t(LocalizationKeys.SelectTheFileFromYourDevice)}
                onUpload={onUpload}
                onDelete={onDelete}
                inputProps={{ accept }}
            />
        </Dialog>
    );
};

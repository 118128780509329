import { t } from "i18next";
import { ICanvasHandle } from "./canvas";
import { LocalizationKeys } from "../../../../locales/keys";

export interface IPanAndZoomToolbarProps {
    currentZoom: string;
    canvas: ICanvasHandle | null;
    container: HTMLDivElement | null;
}

export type FitOption = "page" | "height" | "width";

export const getButtonLabel = (fitOption: FitOption) => {
    switch (fitOption) {
        case "height":
            return t(LocalizationKeys.FitToHeight);
        case "width":
            return t(LocalizationKeys.FitToWidth);
        default:
            return t(LocalizationKeys.FitToPage);
    }
};

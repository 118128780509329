export const JWT_DECODE = "Jwt-decode";
export const AXIOS = "Axios";
export const DETECT_BROWSER = "Detect-browser";
export const PIXI_VIEWPORT = "Pixi-viewport";
export const SWASHBUCKLE_ASPNC = "Swashbucle.AspNetCore";
export const ASPNETCORE = "AspNetCore";
export const EFC = "Entity Framework Core";
export const REACT = "React";
export const MOBILE_DETECT = "Mobile-detect";
export const REACT_PIXI = "React-pixi";
export const MCW_REACT = "Material Components Web React";
export const MATERIAL_UI = "Material-UI";
export const NPGSQL_EFC = "Npgsql Entity Framework Core";
export const PIXI_JS = "Pixi.js";
export const REDUX = "Redux";
export const NODE_SASS = "Node-sass";
export const BOOTSTRAP = "Bootstrap";
export const RESIZE_OBSERVER = "Resize-observer";

export const JWT_DECODE_VERSION = "2.2.0";
export const AXIOS_VERSION = "0.21.1";
export const DETECT_BROWSER_VERSION = "5.0.0";
export const PIXI_VIEWPORT_VERSION = "4.5.0";
export const SWASHBUCKLE_ASPNC_VERSION = "6.3.1";
export const ASPNETCORE_VERSION = "6.0.5";
export const EFC_VERSION = "6.0.5";
export const REACT_VERSION = "16.11.0";
export const MOBILE_DETECT_VERSION = "1.4.4";
export const REACT_PIXI_VERSION = "1.2.10";
export const MCW_REACT_VERSION = "0.15.0";
export const MATERIAL_UI_VERSION = "4.12.3";
export const NPGSQL_EFC_VERSION = "	6.0.4";
export const PIXI_JS_VERSION = "5.2.0";
export const REDUX_VERSION = "4.0.4";
export const NODE_SASS_VERSION = "4.14.1";
export const BOOTSTRAP_VERSION = "4.3.1";
export const RESIZE_OBSERVER_VERSION = "2.0.1";

export const DEFAULT_DISCLAIMER = `THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, 
                                      EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF 
                                      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. 
                                      IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY 
                                      CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, 
                                      TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE 
                                      SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.`;

export const JWT_DECODE_LICENSE: IOpenSourceLicense = {
    key: 1,
    components: JWT_DECODE,
    version: JWT_DECODE_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/auth0/jwt-decode/blob/master/LICENSE",
};
export const AXIOS_LICENSE: IOpenSourceLicense = {
    key: 2,
    components: AXIOS,
    version: AXIOS_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/axios/axios/blob/master/LICENSE",
};
export const DETECT_BROWSER_LICENSE: IOpenSourceLicense = {
    key: 3,
    components: DETECT_BROWSER,
    version: DETECT_BROWSER_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/DamonOehlman/detect-browser/blob/master/LICENSE",
};
export const PIXI_VIEWPORT_LICENSE: IOpenSourceLicense = {
    key: 4,
    components: PIXI_VIEWPORT,
    version: PIXI_VIEWPORT_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/davidfig/pixi-viewport/blob/master/LICENSE",
};
export const SWASHBUCKLE_ASPNC_LICENSE: IOpenSourceLicense = {
    key: 5,
    components: SWASHBUCKLE_ASPNC,
    version: SWASHBUCKLE_ASPNC_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/domaindrivendev/Swashbuckle.AspNetCore/blob/master/LICENSE",
};
export const ASPNETCORE_LICENSE: IOpenSourceLicense = {
    key: 6,
    components: ASPNETCORE,
    version: ASPNETCORE_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/dotnet/aspnetcore/blob/master/LICENSE.txt",
};
export const EFC_LICENSE: IOpenSourceLicense = {
    key: 7,
    components: EFC,
    version: EFC_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/dotnet/efcore/blob/master/LICENSE.txt",
};
export const REACT_LICENSE: IOpenSourceLicense = {
    key: 8,
    components: REACT,
    version: REACT_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/facebook/react/blob/master/LICENSE",
};
export const MOBILE_DETECT_LICENSE: IOpenSourceLicense = {
    key: 9,
    components: MOBILE_DETECT,
    version: MOBILE_DETECT_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/hgoebl/mobile-detect.js/blob/master/LICENSE",
};
export const REACT_PIXI_LICENSE: IOpenSourceLicense = {
    key: 10,
    components: REACT_PIXI,
    version: REACT_PIXI_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/inlet/react-pixi/blob/master/LICENSE",
};
export const MCW_REACT_LICENSE: IOpenSourceLicense = {
    key: 11,
    components: MCW_REACT,
    version: MCW_REACT_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/material-components/material-components-web-react/blob/master/LICENSE",
};
export const MATERIAL_UI_LICENSE: IOpenSourceLicense = {
    key: 12,
    components: MATERIAL_UI,
    version: MATERIAL_UI_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/mui-org/material-ui/blob/master/LICENSE",
};
export const NPGSQL_EFC_LICENSE: IOpenSourceLicense = {
    key: 13,
    components: NPGSQL_EFC,
    version: NPGSQL_EFC_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/npgsql/npgsql/blob/dev/LICENSE",
}; // The github page for this one does not exist anymore, are we still using this ?
export const PIXI_JS_LICENSE: IOpenSourceLicense = {
    key: 14,
    components: PIXI_JS,
    version: PIXI_JS_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/pixijs/pixi.js/blob/dev/LICENSE",
};
export const REDUX_LICENSE: IOpenSourceLicense = {
    key: 15,
    components: REDUX,
    version: REDUX_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/reduxjs/redux/blob/master/LICENSE.md",
};
export const NODE_SASS_LICENSE: IOpenSourceLicense = {
    key: 16,
    components: NODE_SASS,
    version: NODE_SASS_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/sass/node-sass/blob/master/LICENSE",
};
export const BOOTSTRAP_LICENSE: IOpenSourceLicense = {
    key: 17,
    components: BOOTSTRAP,
    version: BOOTSTRAP_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/twbs/bootstrap/blob/master/LICENSE",
};
export const RESIZE_OBSERVER_LICENSE: IOpenSourceLicense = {
    key: 18,
    components: RESIZE_OBSERVER,
    version: RESIZE_OBSERVER_VERSION,
    disclaimer: DEFAULT_DISCLAIMER,
    link: "https://github.com/jaredLunde/react-hook/blob/master/packages/resize-observer/LICENSE",
}

export const OpenSourceLicenses: IOpenSourceLicense[] = [
    JWT_DECODE_LICENSE,
    AXIOS_LICENSE,
    DETECT_BROWSER_LICENSE,
    PIXI_VIEWPORT_LICENSE,
    SWASHBUCKLE_ASPNC_LICENSE,
    ASPNETCORE_LICENSE,
    EFC_LICENSE,
    REACT_LICENSE,
    MOBILE_DETECT_LICENSE,
    REACT_PIXI_LICENSE,
    MCW_REACT_LICENSE,
    MATERIAL_UI_LICENSE,
    NPGSQL_EFC_LICENSE,
    PIXI_JS_LICENSE,
    REDUX_LICENSE,
    NODE_SASS_LICENSE,
    BOOTSTRAP_LICENSE,
    RESIZE_OBSERVER_LICENSE,
];

export interface IOpenSourceLicense {
    key: number;
    components: string;
    version: string;
    disclaimer: string;
    link?: string;
}

export interface IOpenSourceLicenseTableHeaderProps {
    components: string;
    version: string;
    disclaimer: string;
}

export interface IAboutApplicationDialogProps {
    title?: string;
    lastBuild?: string;
    thirdPartyButtonTitle?: string;
    corningCopyright?: string;
    onThirdPartyButtonClick: () => void;
}

import { createSlice } from "@reduxjs/toolkit";
import { setSelectedTriggerAction, setTriggersAction, updateTriggerAction } from "./trigger.actions";
import { initialTriggerManagementState } from "./trigger";

const triggerManagementSlice = createSlice({
    initialState: initialTriggerManagementState,
    name: "triggerManagement",
    reducers: {
        setTriggers: setTriggersAction,
        updateTrigger: updateTriggerAction,
        setSelectedTrigger: setSelectedTriggerAction,
    },
});

export const TriggerManagementReducer = triggerManagementSlice.reducer;
export const { setTriggers, updateTrigger, setSelectedTrigger } = triggerManagementSlice.actions;

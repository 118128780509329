import { Container } from "@pixi/react";
import { PullingGrip } from "../decorators/markers/pulling-grip/pulling-grip";
import { Drop } from "./drop/drop";
import { OverallLength } from "./overall-length/overall-length";
import { OverallLengthProps } from "../../../models/pixi/build/overall-length";
import { CableProps } from "../../../models/pixi/build/cable";
import { IPullingGripProps } from "../../../models/pixi/decorators/markers/pulling-grip";

export const Cable: React.FC<CableProps> = ({ name, display, drops }: CableProps) => {
    const [start, ...distribution] = drops;
    const end = distribution[distribution.length - 1];
    const offsetY = distribution.length > 1 ? 50 : 0;
    const pullingGripProps: IPullingGripProps = {
        feeder: { x: start.x, y: start.y - offsetY },
        distribution: { x: end.x, y: end.y - offsetY },
    };
    const overallLengthProps: OverallLengthProps = { drops };
    if (!display) {
        return null;
    }

    return (
        <Container name={name}>
            <OverallLength {...overallLengthProps} />
            <PullingGrip {...pullingGripProps} />
            {drops.map(({ key, ...d }) => (
                <Drop key={key} {...d} />
            ))}
        </Container>
    );
};

import { isMMC16 } from "../../../../components/pixi/factories/texture";
import { IConnectorType } from "../../../../store/overlay/wizard/wizard";

import {
    IColor,
    Blue,
    Orange,
    Green,
    Brown,
    Slate,
    White,
    Red,
    Black,
    Yellow,
    Violet,
    Rose,
    Aqua,
    Mustard,
    Lime,
    Beige,
    Magenta,
    Olive,
} from "../../../ui/dialog/color-dialog";

export interface IConnectorCasingProps {
    width: number;
    height: number;
    fill: string;
    filter: string;
    opacity?: number;
}

export interface IConnectorTemplateProps {
    side: string;
    disabled?: boolean;
}

export type ConnectorTemplateProps = IConnectorTemplateProps & {
    connectorType: IConnectorType;
};

export interface IConnectorPinProps {
    pinIndex: number;
    side: string;
    connectorType: IConnectorType;
    disabled?: boolean;
}

export interface IConnectorPinDrawingProps {
    fill: string;
    fillOpacity: number;
    stroke: string;
    strokeDashArray: string;
    text: string;
    textColor: string;
}
export const FiberColors: IColor[] = [Blue, Orange, Green, Brown, Slate, White, Red, Black, Yellow, Violet, Rose, Aqua];
export const MMCFiberColors: IColor[] = [...FiberColors, Lime, Beige, Olive, Magenta];
export const MTPExclusiveColors: IColor[] = [Mustard, Lime, Beige];
export const MTPColors: IColor[] = [...FiberColors, ...MTPExclusiveColors];
export const TrunkColors: IColor[] = [Yellow, Aqua];
export const LCBootColors: IColor[] = [Blue, Aqua];
export const MTPBootColors: IColor[] = [Black];
export const SCBootColors: IColor[] = [Black];
export const AllColors = MTPColors;

const DefaultJacketColors = [Yellow];
const MultimodeJacketColors = [Aqua];
export const JacketColors: IColor[] = [...DefaultJacketColors, ...MultimodeJacketColors];

export const getFiberColors = (connectorType: IConnectorType) => {
    return isMMC16(connectorType.type ?? "") ? MMCFiberColors : FiberColors;
};

export const getFillOpacity = (color: IColor) => {
    switch (color) {
        case White:
            return 0.65;
        default:
            return 0.2;
    }
};
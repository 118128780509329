import { renderToString } from "react-dom/server";

export const MMCSVG = (housingColorHex: string, bootColorHex: string, pinned: boolean, scale?: number) => {
    let width = 140;
    let height = 680;
    if (scale) {
        width *= scale;
        height *= scale;
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 68">
            {pinned && (
                <>
                    <path
                        fill="#C4C4C4"
                        d="M6.4,63.1v4.4c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5v-4.4c0-0.3,0.2-0.5,0.5-0.5S6.4,62.8,6.4,63.1z"
                    />
                    <path
                        fill="#C4C4C4"
                        d="M11.7,63.1v4.4c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5v-4.4c0-0.3,0.2-0.5,0.5-0.5
                    C11.5,62.5,11.7,62.8,11.7,63.1z"
                    />
                </>
            )}
            <polygon
                fill={housingColorHex}
                points="3.7,62.6 3.7,57.2 3.7,57.2 3.7,32.5 6.4,32.5 6.4,36.8 10.7,36.8 10.7,32.5 13.4,32.5 13.4,57.2 
            13.4,57.2 13.4,62.6 "
            />
            <path
                fill={bootColorHex}
                d="M4.3,0.9L5.3,3v3.2L4.6,24c-0.1,0-0.2,0.1-0.3,0.2l-4.3,8.1C0,32.3,0,32.4,0,32.5v4.4c0,0.3,0.2,0.5,0.5,0.5
            h0.5v5.8c0,0.3,0.2,0.5,0.5,0.5v12.3l1.1,1.1h0.5v5.9h1.1v2.1h8.6v-2.1h1.1V36.8L14,25.5l0,0c0-0.2-0.1-0.3-0.2-0.4L12.7,24
            c0,0-0.1-0.1-0.2-0.1l-0.2-3.8H8.4v-1.1h3.9l-0.1-3.2H8.4v-1.1h3.7L12,11.6H8.4v-1.1h3.5l-0.2-4.3V3l1.1-2.1V0H4.3V0.9L4.3,0.9z
            M5.5,56.6H4.3l0.5-0.5V41.3h6.4v-3.9h1.6v18.7l-1.2-1l-0.9-1.5H6.6L5.5,56.6L5.5,56.6z M4.8,40.2v-2.8h5.3v2.8L4.8,40.2L4.8,40.2z
            M4.3,62v-4.3h2l1.1-2.9h2.8l0.6,1l2.1,1.7V62L4.3,62L4.3,62z M7.3,20.1v1.1h4l0.1,2.7H5.7l0.2-3.8H7.3L7.3,20.1z M7.3,15.9V17h3.8
            l0,1H7.3v1.1H5.9L6,15.9C6,15.9,7.3,15.9,7.3,15.9z M7.3,11.6v1.1H11l0,1H7.3v1.1H6.1l0.1-3.2C6.2,11.6,7.3,11.6,7.3,11.6z
            M10.7,7.3l0.1,2.2H7.3v1H6.2l0.1-3.2H10.7z"
            />
        </svg>
    );
};

export const getMMCSVGString = (housingColorHex: string, bootColorHex: string, pinned: boolean): string => {
    const mmcSVG = MMCSVG(housingColorHex, bootColorHex, pinned);
    return renderToString(mmcSVG);
};

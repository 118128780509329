import { WorkspaceStatus, WorkspaceStatusTypes } from "../../../../store/overlay/header/status-icon/status-icon";
import { useStatus } from "./status.hooks";
import { t } from "i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { Box, SxProps } from "@mui/material";
import { Typography } from "@corning-ctcm/silica-react";
import { CloudDownload, CloudUpload, EmojiEmotions, Lock } from "@mui/icons-material";
import { Theme } from "@emotion/react";

import "../../../../css/overlay/header/status-icon.scss";

export const Status = () => {
    const { backgroundColor, currentStatus, isLocked, className } = useStatus();
    const props = getStatusProps(currentStatus, isLocked);

    if (!props) return null;
    return (
        <Box component="div" id="app-status">
            <Box component="div" className={className} sx={{ backgroundColor }}>
                <Box component="div" className="status-icon">
                    {props.icon}
                </Box>
                <Box component="div" className="status-label">
                    <Typography color="white" variant="body1-">
                        {props.label}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

type StatusProps = {
    icon: JSX.Element;
    label: string;
};

const getStatusProps = (status: WorkspaceStatusTypes, locked: boolean): StatusProps | undefined => {
    const sx: SxProps<Theme> = { color: "white" };
    if (locked) return { icon: <Lock sx={sx} />, label: t(LocalizationKeys.Locked) };
    switch (status) {
        case WorkspaceStatus.Loading:
        case WorkspaceStatus.InitialLoad:
        case WorkspaceStatus.Busy:
            return { icon: <CloudDownload sx={sx} />, label: t(LocalizationKeys.Loading) };
        case WorkspaceStatus.Printed:
            return { icon: <EmojiEmotions sx={sx} />, label: t(LocalizationKeys.Printed) };
        case WorkspaceStatus.Printing:
            return { icon: <CloudUpload sx={sx} />, label: t(LocalizationKeys.Printing) };
        case WorkspaceStatus.Saved:
            return { icon: <EmojiEmotions sx={sx} />, label: t(LocalizationKeys.Saved) };
        case WorkspaceStatus.Saving:
            return { icon: <CloudUpload sx={sx} />, label: t(LocalizationKeys.Saving) };
    }
};

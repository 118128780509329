import { IWizardState, initialState } from "./wizard";
import { PayloadAction } from "@reduxjs/toolkit";
import { IBuildData } from "../../workspace/build/build";
import { ISourceSetupState } from "../../../store/overlay/wizard/setup/source-setup/source-setup";
import { IDestinationSetupState } from "../../../store/overlay/wizard/setup/destination-setup/destination-setup";

export const setWizardDisplayAction = (state: IWizardState, action: PayloadAction<boolean>) => {
    state.display = action.payload;
};

export const setCurrentStepAction = (state: IWizardState, action: PayloadAction<number>) => {
    state.currentStep = action.payload;
};

export const enableNextStepAction = (state: IWizardState, action: PayloadAction<boolean>) => {
    if (state.isNextEnabled !== action.payload) {
        state.isNextEnabled = action.payload;
    }
};

export const setApplyEditChangesAction = (state: IWizardState, action: PayloadAction<boolean>) => {
    state.applyEditChanges = action.payload;
};

export const restartWizardAction = (state: IWizardState) => {
    state.display = true;
    state.currentStep = initialState.currentStep;
    state.isNextEnabled = initialState.isNextEnabled;
};

export const saveBuildDataAction = (state: IWizardState, action: PayloadAction<IBuildData | undefined>) => {
    state.savedBuildData = action.payload;
};

export const setDropCheckboxDisabledAction = (state: IWizardState, action: PayloadAction<boolean>) => {
    state.dropCheckboxDisabled = action.payload;
};

export const saveSourceSetupAction = (state: IWizardState, action: PayloadAction<ISourceSetupState | undefined>) => {
    state.savedSourceSetup = action.payload;
};

export const updateConnectorTypeChangedAction = (state: IWizardState, action: PayloadAction<boolean>) => {
    if (state.savedSourceSetup) {
        state.savedSourceSetup.connectorTypeChanged = action.payload;
    }
};

export const saveDestinationSetupAction = (
    state: IWizardState,
    action: PayloadAction<IDestinationSetupState | undefined>
) => {
    state.savedDestinationSetup = action.payload;
};

export const clearSavedDataAction = (state: IWizardState) => {
    state.savedSourceSetup = undefined;
    state.savedDestinationSetup = undefined;
    state.savedBuildData = undefined;
};

export const setIsDynamicUpdateDisabledAction = (state: IWizardState, action: PayloadAction<boolean>) => {
    state.isDynamicUpdateDisabled = action.payload;
};

export const setFocusOnErrorAction = (state: IWizardState, action: PayloadAction<boolean>) => {
    state.focusOnError = action.payload;
};
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IConnectorAssignmentData, IPolarityState, initialPolarityState } from "./polarity";

const setConnectorAssignmentAction = (state: IPolarityState, action: PayloadAction<{ connectorId: number, assignment: IConnectorAssignmentData }>) => {
    const { connectorId, assignment } = action.payload;
    state.fromConnectorId = connectorId;
    state.connectorAssignment = assignment;
};

const resetPolarityAction = (state: IPolarityState) => {
    state.fromConnectorId = initialPolarityState.fromConnectorId;
    state.connectorAssignment = initialPolarityState.connectorAssignment;
}

const polaritySlice = createSlice({
    initialState: initialPolarityState,
    name: "polarity",
    reducers: {
        setConnectorAssignment: setConnectorAssignmentAction,
        resetPolarity: resetPolarityAction
    },
});

export const PolarityReducer = polaritySlice.reducer;
export const {
    setConnectorAssignment,
    resetPolarity
} = polaritySlice.actions;

import React from "react";
import { Dialog, Radio, RadioGroup } from "@corning-ctcm/silica-react";
import { useShellEdit } from "./shell-edit.hooks";

import "../../../../css/overlay/header/shell-edit.scss";

export const ShellEdit: React.FC = () => {
    const { dialogProps, radioGroup } = useShellEdit();
    return (
        <Dialog {...dialogProps}>
            <RadioGroup value={radioGroup.props.value} onChange={radioGroup.props.onRadioGroupChange}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {radioGroup.options.map((r, i) => (
                        <Radio id={`shell-option-${i}`} key={`shell-type-${i}`} label={r.label} value={r.sectionName} disabled={r.disabled} />
                    ))}
                </div>
            </RadioGroup>
        </Dialog>
    );
};

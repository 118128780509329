import { EventMode } from "pixi.js";
import { IPoint, ISize } from "./pixi";

export const PRIMARY_MAIN = "#1A45D7";
export const DEFAULT_SIZE: ISize = { width: 200, height: 36 };
export type EventType = "mouseover" | "mousedown";

export interface ICustomTextFieldProps {
    value: string;
    onConfirm: (value: string) => void;
    onCancel: () => void;
}

interface ITextInputProps {
    name: string | null;
    eventType: EventType;
    eventMode: EventMode;
    value: string;
    onConfirm: (value: string) => void;
    onClick?: () => void;
    onCancel?: () => void;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
}

export type TextInputProps = React.PropsWithChildren<ITextInputProps & Partial<IPoint> & Partial<ISize>>;

export const getDefaultProps = (props: TextInputProps) => {
    const { eventType, value, onConfirm, onCancel, onClick, onMouseOver, onMouseOut, ...defaultProps } = props;
    return defaultProps;
};

import { Radio, RadioGroup, TextButton, Typography } from "@corning-ctcm/silica-react";
import { Chip } from "@mui/material";
import { t } from "i18next";
import { ChangeEvent, Dispatch, SetStateAction, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../locales/keys";
import { IDiagramSectionField } from "../../../../models/overlay/reports/report-settings/diagram-section";
import { ReportType } from "../../../../models/overlay/reports/report-settings/report-settings";
import { setUserProvidedImages } from "../../../../store/workspace/document/document.reducers";
import { userProvidedImagesSelector } from "../../../../store/workspace/document/document.selectors";
import { DiagramSectionFields } from "./diagrams-section/diagrams-section";

export const ReportTypeSection = (props: {
    reportType: ReportType;
    setReportType: Dispatch<SetStateAction<ReportType>>;
    diagramFields: IDiagramSectionField[];
    onUploadButtonClick: () => void;
}) => {
    const { reportType, setReportType, diagramFields, onUploadButtonClick } = props;
    const userProvidedImages = useSelector(userProvidedImagesSelector);
    const dispatch = useDispatch();

    const handleDeleteUserProvidedImages = useCallback(
        (index: number) => {
            if (userProvidedImages) {
                dispatch(setUserProvidedImages(userProvidedImages.toSpliced(index, 1)));
            }
        },
        [dispatch, userProvidedImages]
    );

    const handleReportTypeChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>, newValue: string) => {
            setReportType(newValue as ReportType);
        },
        [setReportType]
    );

    return (
        <div>
            <div className="horizontal-field-container" style={{ paddingTop: "16px" }}>
                <div className="radio-group">
                    <RadioGroup value={reportType} onChange={handleReportTypeChange}>
                        <div style={{ display: "flex", flexDirection: "column", marginBottom: "28px", gap: "16px" }}>
                            <div>
                                <Radio
                                    label={t(LocalizationKeys.TwoPage)}
                                    value={ReportType.TwoPage}
                                    checked={reportType === ReportType.TwoPage}
                                />
                                <div style={{ marginLeft: "42px" }}>
                                    <Typography variant="body2">{t(LocalizationKeys.InclusionsTwoPage)}</Typography>
                                </div>
                            </div>
                            <div>
                                <Radio
                                    label={t(LocalizationKeys.DetailedReport)}
                                    value={ReportType.Detailed}
                                    checked={reportType === ReportType.Detailed}
                                />
                                <div style={{ marginLeft: "42px" }}>
                                    <Typography variant="body2">{t(LocalizationKeys.InclusionsDetailed)}</Typography>
                                </div>
                            </div>
                            <div>
                                <Typography sx={{ marginLeft: "9px" }} variant="body1">
                                    {t(LocalizationKeys.SelectionReport)}
                                </Typography>
                                <div style={{ marginLeft: "42px" }}>
                                    <DiagramSectionFields diagrams={diagramFields} />
                                </div>
                            </div>
                        </div>
                        {reportType === ReportType.Detailed ? (
                            <div>
                                <Typography variant="body1" paddingBottom="8px">
                                    {t(LocalizationKeys.UploadAnImageToAppendToTheReport)}
                                </Typography>
                                <TextButton onClick={onUploadButtonClick}>{t(LocalizationKeys.UploadImage)}</TextButton>
                                <div className="user-provided-images">
                                    {userProvidedImages?.map(({ fileName, objectUrl }, index) => (
                                        <Chip
                                            key={objectUrl}
                                            label={fileName}
                                            onDelete={() => handleDeleteUserProvidedImages(index)}
                                        />
                                    ))}
                                </div>
                            </div>
                        ) : null}
                    </RadioGroup>
                </div>
            </div>
        </div>
    );
};

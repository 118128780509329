import React from "react";
import { Checkbox } from "@corning-ctcm/silica-react";
import { IDiagramSectionField } from "../../../../../models/overlay/reports/report-settings/diagram-section";

export const DiagramSectionFields = (props: { diagrams: IDiagramSectionField[] }) => {
    const { diagrams } = props;
    return (
        <div className="diagrams-container">
            {diagrams.map(({ checkbox }, i) => (
                <div className={checkbox.className} key={i}>
                    <Checkbox
                        palette="primary"
                        onChange={checkbox.onCheckBoxChange}
                        checked={checkbox.checked}
                        disabled={checkbox.disabled}
                        label={checkbox.label}
                    />
                </div>
            ))}
        </div>
    );
};

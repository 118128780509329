import { SelectProps } from "@corning-ctcm/silica-react";
import { useCallback, useEffect, useState } from "react";
import { ReportSectionOption } from "../../../models/overlay/reports/report-settings/report-settings";

export interface ISelectOption {
    key: string;
    value: string;
    name: string;
}

export interface ISelect {
    value: string;
    props: SelectProps;
    options: ISelectOption[];
}

export const useSelect = (name: string, label: string, options: ISelectOption[], disabled?: boolean): ISelect => {
    const [value, setValue] = useState<string>(ReportSectionOption.Workspace);

    useEffect(() => {
        if (disabled && value !== ReportSectionOption.Workspace) {
            setValue(ReportSectionOption.Workspace);
        }
    }, [disabled, value]);

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    }, []);

    const selectProps: SelectProps = {
        id: `${name}-dropdown`,
        palette: "primary",
        label,
        value,
        disabled,
        units: "",
        helperText: "",
        onChange,
    };

    return {
        value: value,
        props: selectProps,
        options,
    };
};

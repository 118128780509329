import { useSelector, useDispatch } from "react-redux";
import { useBuildInfo } from "../../../../components/overlay/project-drawer/project-manager-row/build-info/build-info.hooks";
import { useState, useCallback, useEffect, Dispatch, useReducer } from "react";
import { showCableInfo, closeAll } from "../../../../store/overlay/project/project.reducers";
import { setBuildInfo } from "../../../../store/workspace/build/build.reducers";
import { useWebSave } from "../../header/status/status.hooks";
import { useInputField } from "../../../ui/input/input.hooks";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { useSscBuildSession } from "../../../workspace/ssc.hooks";
import { projectManagerSelector } from "../../../../store/workspace/project-manager/project-manager.selectors";
import { isDrawingModeSelector } from "../../../../store/workspace/boundaries/boundaries.selectors";
import { currentBuildSelector } from "../../../../store/workspace/root.selectors";
import { TextFieldProps } from "@corning-ctcm/silica-react";
import {
    CableInfoReducer,
    initialCableInfoState,
    setDescription,
    setName,
    setPartNumber,
} from "../../../../store/overlay/project/project-drawer/cable-info/cable-info.reducer";
import { Action } from "redux";

export const useCableInfo = () => {
    const { showInfo: open, selectedBuildId } = useSelector(projectManagerSelector);
    const currentBuild = useSelector(currentBuildSelector);
    const buildInfo = useBuildInfo(selectedBuildId);
    const [localState, localDispatch] = useReducer(CableInfoReducer, initialCableInfoState);
    const { name, description, partNumber } = localState;
    const partNumberDisabled = currentBuild?.id !== selectedBuildId;

    const { saveBuildById } = useWebSave();
    const { updateSscBuildInfo } = useSscBuildSession();
    const { t } = useTranslation();
    const { nameInputProps, nameChanged, nameIsValid } = useCableName(name, buildInfo.name, localDispatch);
    const { descriptionInputProps, descriptionChanged, descriptionIsValid } = useCableDescription(
        description,
        buildInfo.description,
        localDispatch
    );
    const { partNumberInputProps, partNumberChanged, partNumberIsValid } = useCablePartNumber(
        partNumber,
        buildInfo.catalogCode,
        partNumberDisabled,
        localDispatch
    );
    const polarityInput = useInputField(t(LocalizationKeys.Polarity), buildInfo.polarity ?? "", 30);

    const [executeSave, setExecuteSave] = useState(false);

    const dispatch = useDispatch();

    const reset = useCallback(() => {
        localDispatch(setName(buildInfo.name));
        localDispatch(setDescription(buildInfo.description));
        localDispatch(setPartNumber(buildInfo.catalogCode));
    }, [buildInfo.catalogCode, buildInfo.description, buildInfo.name, localDispatch]);

    useEffect(() => {
        if (open) {
            reset();
        }
    }, [open, reset]);

    useEffect(() => {
        if (executeSave) {
            saveBuildById(selectedBuildId);
            setExecuteSave(false);

            if (currentBuild && currentBuild?.id === selectedBuildId) {
                updateSscBuildInfo(currentBuild);
            }
        }
    }, [executeSave, saveBuildById, selectedBuildId, currentBuild, updateSscBuildInfo]);

    const onReturn = useCallback(() => {
        dispatch(showCableInfo(false));
        reset();
    }, [dispatch, reset]);

    const onClose = useCallback(() => {
        if (open) {
            dispatch(closeAll());
            reset();
        }
    }, [open, dispatch, reset]);

    const onSave = useCallback(async () => {
        dispatch(setBuildInfo({ buildId: selectedBuildId, name, desc: description, partNumber }));
        setExecuteSave(true);
    }, [dispatch, selectedBuildId, name, description, partNumber]);

    const buildInfoChanged = nameChanged || descriptionChanged || partNumberChanged;
    const buildInfoValid = nameIsValid && descriptionIsValid && partNumberIsValid;

    const fields = [nameInputProps, descriptionInputProps, partNumberInputProps];
    const autoFocusInput = fields.find((f) => !!!f.value && !f.disabled) ?? nameInputProps;

    const polarityInputProps: TextFieldProps = {
        id: "cable-polarity-field",
        label: polarityInput.label,
        palette: "primary",
        type: "text",
        value: polarityInput.value,
        onChange: (e) => polarityInput.onChange && polarityInput.onChange(e),
        maxLength: polarityInput.maxLength,
        error: !polarityInput.isValid,
        disabled: true,
        helperText: " ",
    };

    return {
        open,
        nameInputProps,
        descriptionInputProps,
        partNumberInputProps,
        polarityInputProps,
        catalogCode: buildInfo.catalogCode,
        saveDisabled: !buildInfoChanged || !buildInfoValid,
        cancelDisabled: !buildInfoChanged,
        onReturn,
        onClose,
        onSave,
        onCancel: reset,
        autoFocusInput,
    };
};

const useCableName = (name: string, buildInfoName: string, localDispatch: Dispatch<Action>) => {
    const { t } = useTranslation();

    const onNameChanged = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const entry = e.currentTarget.value;
            localDispatch(setName(entry));
        },
        [localDispatch]
    );

    const nameChanged = name !== buildInfoName;
    const nameIsValid = name.length > 0;

    const nameInputProps: TextFieldProps = {
        label: t(LocalizationKeys.CableName),
        value: name,
        maxLength: 20,
        id: "cable-name-field",
        palette: "primary",
        type: "text",
        onChange: onNameChanged,
        error: !nameIsValid,
        helperText: " ",
    };

    return {
        nameInputProps,
        nameChanged,
        nameIsValid,
    };
};

const useCableDescription = (description: string, buildInfoDescription: string, localDispatch: Dispatch<Action>) => {
    const { t } = useTranslation();

    const onDescriptionChanged = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const entry = e.currentTarget.value;
            localDispatch(setDescription(entry));
        },
        [localDispatch]
    );

    const descriptionChanged = buildInfoDescription !== description;
    const descriptionIsValid = description.length > 0;

    const descriptionInputProps: TextFieldProps = {
        label: t(LocalizationKeys.Description),
        value: description,
        maxLength: 80,
        id: "cable-desc-field",
        palette: "primary",
        type: "text",
        onChange: onDescriptionChanged,
        error: !descriptionIsValid,
        helperText: " ",
    };

    return {
        descriptionInputProps,
        descriptionChanged,
        descriptionIsValid,
    };
};

const useCablePartNumber = (
    partNumber: string,
    buildPartNumber: string,
    disabled: boolean,
    localDispatch: Dispatch<Action>
) => {
    const { t } = useTranslation();
    const isDrawingMode = useSelector(isDrawingModeSelector);

    const onPartNumberChanged = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const entry = e.currentTarget.value;
            localDispatch(setPartNumber(entry));
        },
        [localDispatch]
    );

    const partNumberDisabled = isDrawingMode || disabled;
    const partNumberChanged = buildPartNumber !== partNumber;
    const partNumberIsValid = partNumber.length > 0 || partNumberDisabled;

    const partNumberInputProps: TextFieldProps = {
        label: t(LocalizationKeys.ProductConfiguration),
        value: partNumber,
        maxLength: 25,
        disabled: partNumberDisabled,
        id: "cable-part-number-field",
        palette: "primary",
        type: "text",
        onChange: onPartNumberChanged,
        error: !partNumberIsValid,
        helperText: " ",
    };

    return {
        partNumberInputProps,
        partNumberChanged,
        partNumberIsValid,
    };
};

import React from "react";
import { INavigationBarProps } from "../../../models/ui/navigation-bar";
import { useNavigationBar } from "./navigation-bar.hooks";
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from "@mui/icons-material";
import { TextButton, IconButton } from "@corning-ctcm/silica-react";

import "../../../css/ui/navigation-bar.scss";

export const NavigationBar: React.FC<INavigationBarProps> = (props) => {
    const {
        firstProps,
        previousProps,
        buttons,
        nextProps,
        lastProps,
        showPreviousThreshold,
        showNextThreshold,
        thresholdProps,
    } = useNavigationBar(props);
    return (
        <div className="navigation-bar">
            <IconButton {...firstProps} icon={<FirstPage />} />
            <IconButton {...previousProps} icon={<ChevronLeft />} />
            {showPreviousThreshold ? <IconButton {...thresholdProps} /> : null}
            {
                buttons?.map(b => {
                    return (
                        <TextButton key={b.label} {...b.buttonProps}>
                            {b.label}
                        </TextButton>
                    );
                })
            }
            {showNextThreshold ? <IconButton {...thresholdProps} /> : null}
            <IconButton {...nextProps} icon={<ChevronRight />} />
            <IconButton {...lastProps} icon={<LastPage />} />
        </div>
    );
};

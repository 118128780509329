import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import { FiberMappingContext } from "../../../../../store/overlay/polarity/fiber-mapping-save/fiber-mapping-save";
import { setFeederItem, setTAPConnectorsItem } from "../../../../../store/overlay/polarity/fiber-mapping-save/fiber-mapping-save.reducer";
import { SectionContext, Feeder } from "../../../../../models/pixi/build/build";
import { ConnectorTemplateProps } from "../../../../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";
import { IconButtonProps } from "@corning-ctcm/silica-react";

export const useFiberMappingConnector = (template: ConnectorTemplateProps) => {
    const section = useContext(SectionContext);
    const { 
        state: {
            navigation: {
                feeder,
                distribution
            }
        },
        dispatch: localDispatch
    } = useContext(FiberMappingContext);
    const { t } = useTranslation();

    const feederIndex = feeder.items.indexOf(feeder.currentItem);
    const distributionIndex = distribution.connectors.items.indexOf(distribution.connectors.currentItem);
    const previousDisabled = section === Feeder ? 
        feederIndex === 0 : 
        distributionIndex === 0;
    const onPreviousClick = useCallback(() => {
        if (section === Feeder) {
            const previousItem = feeder.items[feederIndex - 1];
            localDispatch(setFeederItem(previousItem));
        } else {
            const previousItem = distribution.connectors.items[distributionIndex - 1];
            localDispatch(setTAPConnectorsItem(previousItem));
        }
    }, [
        section,
        feeder,
        feederIndex,
        distribution.connectors,
        distributionIndex,
        localDispatch
    ]);

    const nextDisabled = section === Feeder ? 
        feederIndex === feeder.items.length - 1 : 
        distributionIndex === distribution.connectors.items.length - 1;
    const onNextClick = useCallback(() => {
        if (section === Feeder) {
            const nextItem = feeder.items[feederIndex + 1];
            localDispatch(setFeederItem(nextItem));
        } else {
            const nextItem = distribution.connectors.items[distributionIndex + 1];
            localDispatch(setTAPConnectorsItem(nextItem));
        }
    }, [
        section,
        feeder,
        feederIndex,
        distribution.connectors,
        distributionIndex,
        localDispatch
    ]);

    const previousProps: Omit<IconButtonProps, "icon"> = {
        id: `${template.side}-previous-connector-button`,
        palette: "primary",
        mode: "main",
        disabled: previousDisabled,
        title: t(LocalizationKeys.Previous),
        placement: "bottom",
        onClick: onPreviousClick,
    };

    const nextProps: Omit<IconButtonProps, "icon"> = {
        id: `${template.side}-next-connector-button`,
        palette: "primary",
        mode: "main",
        disabled: nextDisabled,
        title: t(LocalizationKeys.Next),
        placement: "bottom",
        onClick: onNextClick,
    };

    return {
        type: template.connectorType.type ?? "",
        template,
        previousProps,
        nextProps,
    };
};

import { Container, withFilters } from "@pixi/react"
import { GlowFilter, AdjustmentFilter } from "pixi-filters"

export const SelectFilter = withFilters(Container, {
    adjust: AdjustmentFilter,
    glow: GlowFilter,
});

export const selectFilter = { alpha: 1, green: 86 / 51, blue: 146 / 51, brightness: 1, gamma: 0.5, enabled: true };
export const hoverFilter = { alpha: 1, gamma: 2, enabled: true };
export const glowFilter = { innerStrength: 0, outerStrength: 2, color: 0x0098db, knockout: false, enabled: true };
export const outlineFilter = { thickness: 2, color: 0x0098db, alpha: 1, knockout: true, enabled: true }
export const defaultFilter = { enabled: false };

import React from "react";
import { usePolarityToolbar } from "./polarity-toolbar.hooks";
import { styled, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { IconButton, Typography } from "@corning-ctcm/silica-react";
import { Close } from "@mui/icons-material";

import "../../../../css/overlay/polarity/polarity-toolbar.scss";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    gap: "6px",
    "& .MuiToggleButtonGroup-grouped": {
        borderColor: "transparent",
        borderRadius: "6px",
        "&.Mui-disabled": {
            borderColor: "transparent",
        },
        "&:not(:first-of-type)": {
            borderRadius: theme.shape.borderRadius,
        },
        "&:first-of-type": {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

export const PolarityToolbar: React.FC = () => {
    const { 
        display,
        progress,
        actions
    } = usePolarityToolbar();
    if (!display) {
        return null;
    }
    return (
        <div className="polarity-toolbar toggle-pointer-events">
            <div className="progress-container">
                <div className="assigned-fibers">
                    <Typography variant="caption" className="title">
                        {progress.title}
                    </Typography>
                    <Typography variant="caption" className="percentage">
                        {progress.percentageText}
                    </Typography>
                </div>
                <div className="progress-bar">
                    <span style={{ width: `${progress.percentage}%` }} />
                </div>
            </div>
            <div className="vertical-divider" />
            <StyledToggleButtonGroup exclusive value={actions.selected}>
                <ToggleButton {...actions.connectorAssignment.button}>
                    {actions.connectorAssignment.label}
                </ToggleButton>
                <ToggleButton {...actions.fiberMapping.button}>
                    {actions.fiberMapping.label}
                </ToggleButton>
            </StyledToggleButtonGroup>
            <div className="vertical-divider" />
            <IconButton {...actions.close} icon={<Close />} />
        </div>
    );
};

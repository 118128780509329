import { Typography } from "@corning-ctcm/silica-react";
import { ToggleButton } from "@mui/material";
import { DataTable, DataTableBody, DataTableContent } from "@rmwc/data-table";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { GenericDialog } from "../../../ui/dialog/generic-dialog";
import { useAssignPolarity } from "./assign-polarity.hooks";
import { EditPolarityNameDialog } from "./edit-polarity-dialog/edit-polarity-name-dialog";
import { PolarityAssignmentRow } from "./polarity-assignment-row/polarity-assignment-row";
import { PolarityDiagram } from "./polarity-diagram/polarity-diagram";

import "../../../../css/overlay/polarity/assign-polarity.scss";

export const AssignPolarity = () => {
    const { t } = useTranslation();
    const {
        polarityAssignmentRowProps,
        editPolarityDialogProps,
        deletePolarityDialogProps,
        selectedMap,
        polarityButtons,
        selectedConfig,
    } = useAssignPolarity();

    return (
        <div className="assign-polarity-container">
            <div className="polarity-button-container">
                {polarityButtons.map((p, i) => {
                    return (
                        <div key={i}>
                            <ToggleButton
                                id={p.id}
                                className={p.className}
                                selected={p.selected}
                                value={p.description ?? ""}
                                size="small"
                                key={i}
                                onChange={p.onClick}
                            >
                                <i className="material-icons">
                                    {p.applied ? "check_circle" : "radio_button_unchecked"}
                                </i>
                                {p.description}
                            </ToggleButton>
                        </div>
                    );
                })}
            </div>
            <div className="assign-polarity-content">
                {polarityAssignmentRowProps.length > 0 ? (
                    <DataTable className="polarity-map-container">
                        <DataTableContent>
                            <DataTableBody>
                                {
                                    polarityAssignmentRowProps.map(({ key, ...m }) =>
                                        <PolarityAssignmentRow key={key} {...m} />
                                    )
                                }
                            </DataTableBody>
                        </DataTableContent>
                    </DataTable>
                ) : null}
                <div className="assign-polarity-graphic-container">
                    {polarityAssignmentRowProps.length > 0 ? (
                        selectedMap.key !== -1 ? (
                            <PolarityDiagram polarityMap={selectedMap} polarityConfig={selectedConfig} />
                        ) : (
                            <div className="polarity-instructions">
                                <Typography variant="h6">
                                    {t(LocalizationKeys.PolarityInstructionsTitle)}
                                </Typography>
                                <br />
                                <Typography>
                                    {t(LocalizationKeys.PolarityInstructionsDescription)}
                                </Typography>
                                <br />
                                <div className="polarity-important">
                                    <Typography variant="body1-">
                                        <i>
                                            {`${t(LocalizationKeys.Important)}: ${t(
                                            LocalizationKeys.PolarityImportantDescription
                                            )}`}
                                        </i>
                                    </Typography>
                                </div>
                            </div>
                        )
                    ) : (
                        <div className="polarity-unavailable">
                            <i className="material-icons">error</i>
                            <div className="polarity-unavailable-text">{t(LocalizationKeys.PolarityUnavailable)}</div>
                        </div>
                    )}
                </div>
            </div>
            <GenericDialog {...deletePolarityDialogProps} />
            <EditPolarityNameDialog {...editPolarityDialogProps} />
        </div>
    );
};

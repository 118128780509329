import { Container } from "@pixi/react";
import { ILengthProps } from "../../../../models/pixi/decorators/dimension-lines/length";
import { LineMarker } from "./line-marker/line-marker";

export const Length: React.FC<ILengthProps> = ({ name, start, line, end, mesh }: ILengthProps) => {
    return (
        <Container name={name}>
            {start && <LineMarker {...start} />}
            {line && <LineMarker {...line} />}
            {mesh?.marker && <LineMarker {...mesh.marker} />}
            {mesh?.extension && <LineMarker {...mesh.extension} />}
            {end && <LineMarker {...end} />}
        </Container>
    );
};

import { ContainedButton, IconButton, TextField, Typography } from "@corning-ctcm/silica-react";
import { Clear, Search } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useId } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import IconSortAsc from "../../../../resources/svgs/overlay/project-drawer/icon_sort_asc.svg";
import IconSortDesc from "../../../../resources/svgs/overlay/project-drawer/icon_sort_desc.svg";
import { Sorts } from "../../../../store/overlay/project/project";
import { useDrawerSubHeader } from "./drawer-sub-header.hooks";

import "../../../../css/overlay/project-drawer/drawer-sub-header.scss";

export const DrawerSubHeader = () => {
    const { t } = useTranslation();
    const { cableLabel,
        sortType,
        isSortAscending,
        searchTextFieldProps,
        onSelectChange,
        sortAscProps,
        sortDesProps,
        triggerCreate,
        onClear
    } = useDrawerSubHeader();

    const labelId = useId();

    return (
        <div className="drawer-sub-header">
            <Typography variant="subtitle1" className="cable-label">
                {cableLabel}
            </Typography>
            <div className="sort-container">
                <FormControl>
                    <InputLabel id={labelId}>{t(LocalizationKeys.SortBy)}</InputLabel>
                    <Select labelId={labelId} label={t(LocalizationKeys.SortBy)} value={sortType} onChange={onSelectChange} className="sort-select">
                        <MenuItem value={Sorts.DateModified}>
                            {t(LocalizationKeys.DateModified).toUpperCase()}
                        </MenuItem>
                        <MenuItem value={Sorts.Name}>
                            {t(LocalizationKeys.Name).toUpperCase()}
                        </MenuItem>
                        <MenuItem id="desc-sort-option" value={Sorts.Description}>
                            {t(LocalizationKeys.Description).toUpperCase()}
                        </MenuItem>
                    </Select>
                </FormControl>
                <div className="sort-button">
                    {isSortAscending ? (
                        <IconButton {...sortAscProps} icon={<img alt="" src={IconSortAsc} />} />
                    ) : (
                        <IconButton {...sortDesProps} icon={<img alt="" src={IconSortDesc} />} />
                    )}
                </div>
            </div>
            <TextField
                id="sort-search-field"
                className="search-field"
                {...searchTextFieldProps}
                InputProps={{ endAdornment: <IconButton palette="primary" mode="main" placement="top" onClick={onClear} icon={searchTextFieldProps.value ? <Clear /> : <Search />} /> }}
            />
            <ContainedButton
                id="new-cable-button"
                className="new-cable-button"
                onClick={triggerCreate}
                size="large"
            >
                {t(LocalizationKeys.NewCable)}
            </ContainedButton>
        </div>
    );
};

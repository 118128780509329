import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import { LabelCustomizationContext } from "../../../../../store/overlay/reports/label-customization/customization";
import { resetCustomization, setPastedContent, setRackNumber, setReverseOrder } from "../../../../../store/overlay/reports/label-customization/customization.reducer";
import { TextFieldProps } from "@corning-ctcm/silica-react";

export const useCustomization = () => {
    const {
        state: { rackNumber, reverseOrder, allowPasting, pastedContent },
        dispatch,
    } = useContext(LabelCustomizationContext);
    const { t } = useTranslation();

    const [rackNumberInput, setRackNumberInput] = useState(rackNumber.toString());

    const onPastedContentChanged = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const pastedContent = e.currentTarget.value;
            dispatch(setPastedContent(pastedContent));
        },
        [dispatch]
    );

    const pasteTextArea: TextFieldProps = {
        id: "paste-text-area",
        palette: "primary",
        value: pastedContent,
        autoComplete: "off",
        spellCheck: false,
        multiline: true,
        onChange: onPastedContentChanged,
    };

    const pasteInfo = t(LocalizationKeys.CustomPasteInfo);

    const onReverseOrderChange = useCallback((e: any, checked: boolean) => {
        dispatch(setReverseOrder(checked));
    }, [dispatch]);

    const onRackNumberChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const rackNumberInput = e.currentTarget.value;
            const rackNumberValue = Number(rackNumberInput);
            if (rackNumberInput.length === 0 || rackNumberValue > 0) {
                setRackNumberInput(rackNumberInput);
                if (rackNumberValue > 0) {
                    dispatch(setRackNumber(rackNumberValue));
                }
            }
        },
        [dispatch]
    );

    const onReset = useCallback(() => {
        dispatch(resetCustomization());
        setRackNumberInput("1");
    }, [dispatch]);

    const resetButtonProps = {
        disabled: rackNumberInput.length === 0,
        onClick: onReset,
    };

    return {
        title: t(LocalizationKeys.CustomizeLabelScheme),
        pasteProps: {
            allowPasting,
            textArea: pasteTextArea,
            caption: pasteInfo,
        },
        reverseProps: {
            label: t(LocalizationKeys.ReverseRackOrder),
            checkboxProps: {
                value: reverseOrder,
                checked: reverseOrder,
                onChange: onReverseOrderChange,
            },
        },
        rackNumberProps: {
            fieldProps: {
                label: t(LocalizationKeys.RackStartNumber),
            },
            inputProps: {
                id: "rack-number-input",
                value: rackNumberInput,
                onChange: onRackNumberChange,
                autoComplete: "off",
                spellCheck: false,
            },
        },
        resetProps: {
            buttonProps: resetButtonProps,
            label: t(LocalizationKeys.Reset),
        },
    };
};

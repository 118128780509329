import { useSelector, useDispatch } from "react-redux";
import { setIncrement } from "../../../store/pixi/viewport/viewport.reducers";
import { viewportSelector } from "../../../store/pixi/viewport/viewport.selectors";
import { LocalizationKeys } from "../../../locales/keys";
import { useTranslation } from "react-i18next";
import { Add, Remove } from "@mui/icons-material";
import { Typography, IconButton, IconButtonProps } from "@corning-ctcm/silica-react";
import { disabledUISelector } from "../../../store/overlay/header/status-icon/status-icon.selectors";

export const ZoomTool = () => {
    const { zoom } = useZoomText();
    return (
        <div className="controls-container zoom-container background-blur toggle-pointer-events">
            <div className="zoom-actions">
                <IconButton {...zoom.in} icon={<Add />} />
                <IconButton {...zoom.out} icon={<Remove />} />
            </div>
            <div className="tool-vertical-divider" />
            <Typography variant="body1">
                {zoom.percentage}
            </Typography>
        </div>
    );
};

function displayZoom(zoom: number) {
    const zoomPercentage = zoom * 100;
    return zoomPercentage.toFixed().toString();
}

const useZoomText = () => {
    const { scale } = useSelector(viewportSelector);
    const disabled = useSelector(disabledUISelector);
    const { t } = useTranslation(); 
    const dispatch = useDispatch();

    const percentage = `${displayZoom(scale.x)}%`

    const onZoomIn = () => dispatch(setIncrement(0.1));
    const onZoomOut = () => dispatch(setIncrement(-0.1));

    const zoomInProps: Omit<IconButtonProps, "icon"> = {
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.ZoomIn),
        placement: "top",
        disabled,
        onClick: onZoomIn
    };

    const zoomOutProps: Omit<IconButtonProps, "icon"> = {
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.ZoomOut),
        placement: "top",
        disabled,
        onClick: onZoomOut
    };

    return {
        zoom: {
            in: zoomInProps,
            out: zoomOutProps,
            percentage
        }
    };
}

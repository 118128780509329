import { useState, useEffect, useCallback } from "react";
import { getDateNowString, useInputField } from "../../../../ui/input/input.hooks";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import { useSelector } from "react-redux";
import { currentPrintSettingsSelector } from "../../../../../store/workspace/build.selectors";
import { InputField } from "../../../../../models/ui/input";
import { showPrintDialogSelector } from "../../../../../store/overlay/overlay.selectors";
import { isLockedSelector } from "../../../../../store/overlay/header/status-icon/status-icon.selectors";

export const useFooterFields = () => {
    const showDialog = useSelector(showPrintDialogSelector);
    const printSettings = useSelector(currentPrintSettingsSelector);
    const locked = useSelector(isLockedSelector);
    const { t } = useTranslation();

    const [siteLocation, setSiteLocation] = useState(printSettings.location);
    const [drawnBy, setDrawnBy] = useState(printSettings.drawnBy);
    const [revisionNumber, setRevisionNumber] = useState(printSettings.revisionNumber);
    const initialApprovalDate =
        printSettings.approvalDate.length > 0 ? printSettings.approvalDate : getDateNowString();
    const [approvalDate, setApprovalDate] = useState(initialApprovalDate);
    const initialInServiceDate =
        printSettings.inServiceDate.length > 0 ? printSettings.inServiceDate : getDateNowString();
    const [inServiceDate, setInServiceDate] = useState(initialInServiceDate);
    const [notes, setNotes] = useState(printSettings.footerNotes);

    const siteLocationField = useInputField(t(LocalizationKeys.Location), siteLocation, 18);
    const drawnByField = useInputField(t(LocalizationKeys.DrawingBy), drawnBy, 15);
    const revisionNumberField = useInputField(t(LocalizationKeys.RevisionNumber), revisionNumber, 10);
    const approvalDateField = useInputField(t(LocalizationKeys.DateApproval), approvalDate);
    const inServiceDateField = useInputField(t(LocalizationKeys.DateInService), inServiceDate);
    const notesField = useInputField(t(LocalizationKeys.Notes), notes, 60);

    useEffect(() => {
        if (!showDialog) {
            if (siteLocation !== printSettings.location) {
                setSiteLocation(printSettings.location);
            }
            if (drawnBy !== printSettings.drawnBy) {
                setDrawnBy(printSettings.drawnBy);
            }
            if (revisionNumber !== printSettings.revisionNumber) {
                setRevisionNumber(printSettings.revisionNumber);
            }
            if (approvalDate !== printSettings.approvalDate) {
                setApprovalDate(printSettings.approvalDate);
            }
            if (inServiceDate !== printSettings.inServiceDate) {
                setInServiceDate(printSettings.inServiceDate);
            }
            if (notes !== printSettings.footerNotes) {
                setNotes(printSettings.footerNotes);
            }
        }
    }, [showDialog, siteLocation, drawnBy, revisionNumber, approvalDate, inServiceDate, notes, printSettings]);

    const onSiteLocationChange = useCallback(
        (e: any) => {
            setSiteLocation(e.target.value);
            if (siteLocationField.onChange) {
                siteLocationField.onChange(e);
            }
        },
        [siteLocationField]
    );

    const onDrawnByChange = useCallback(
        (e: any) => {
            setDrawnBy(e.target.value);
            if (drawnByField.onChange) {
                drawnByField.onChange(e);
            }
        },
        [drawnByField]
    );

    const onRevisionNumberChange = useCallback(
        (e: any) => {
            setRevisionNumber(e.target.value);
            if (revisionNumberField.onChange) {
                revisionNumberField.onChange(e);
            }
        },
        [revisionNumberField]
    );

    const onApprovalDateChange = useCallback(
        (e: any) => {
            setApprovalDate(e.target.value);
            if (approvalDateField.onChange) {
                approvalDateField.onChange(e);
            }
        },
        [approvalDateField]
    );

    const onInServiceDateChange = useCallback(
        (e: any) => {
            setInServiceDate(e.target.value);
            if (inServiceDateField.onChange) {
                inServiceDateField.onChange(e);
            }
        },
        [inServiceDateField]
    );

    const onNotesChange = useCallback(
        (e: any) => {
            setNotes(e.target.value);
            if (notesField.onChange) {
                notesField.onChange(e);
            }
        },
        [notesField]
    );

    const footerFields: InputField[] = [
        { ...siteLocationField, onChange: onSiteLocationChange, disabled: locked },
        { ...drawnByField, onChange: onDrawnByChange, disabled: locked },
        { ...revisionNumberField, onChange: onRevisionNumberChange, disabled: locked },
        { ...approvalDateField, onChange: onApprovalDateChange, disabled: locked },
        { ...inServiceDateField, onChange: onInServiceDateChange, disabled: locked },
        { ...notesField, onChange: onNotesChange, disabled: locked },
    ];

    return {
        footerFields,
        fieldValues: { siteLocation, drawnBy, revisionNumber, approvalDate, inServiceDate, notes },
    };
};

import React from "react";
import { useFooter } from "./footer.hooks";
import { ZoomTool } from "./zoom-tool";
import { PageResizeTool } from "./page-resize-tool/page-resize-tool";
import { EditToolbar } from "./toolbar/edit-toolbar";
import CurrentUnitsTool from "./current-units-tool";
import { ActionToolbar } from "./toolbar/action-toolbar/action-toolbar";
import { RepresentativeExample } from "./representative-example/representative-example";

import "../../../css/overlay/footer/footer.scss";

export const Footer: React.FC = () => {
    const { footerClassName } = useFooter();
    return (
        <div id="footer" className={footerClassName}>
            <ZoomTool />
            <PageResizeTool />
            <EditToolbar />
            <CurrentUnitsTool />
            <ActionToolbar />
            <RepresentativeExample />
        </div>
    );
};

import i18next from "i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { IPoint } from "../../pixi";

export interface IPullingGripProps {
    feeder: IPoint;
    distribution: IPoint;
}

export type PullingGrip = "none" | "feeder" | "distribution" | "both";

export const getPullingGripText = (pullingGrip: PullingGrip) => {
    switch (pullingGrip) {
        case "feeder": 
            return `${i18next.t(LocalizationKeys.Feeder)} ${i18next.t(LocalizationKeys.Side)}`
        case "distribution": 
            return `${i18next.t(LocalizationKeys.Distribution)} ${i18next.t(LocalizationKeys.Side)}`
        case "both":
            return i18next.t(LocalizationKeys.BothEnds)
        default:
            return i18next.t(LocalizationKeys.None)
    }
}
import React from "react";
import { IDuplicateDialogProps } from "../../../../../models/overlay/project-drawer/project-manager-row/duplicate-dialog";
import { useDuplicateDialog } from "./duplicate-dialog.hooks";
import { InputDialog } from "../input-dialog/input-dialog";
import { DialogContext } from "../../../../../store/workspace/dialog/dialog.reducer";

import "../../../../../css/overlay/project-drawer/project-manager-row/duplicate-dialog.scss";

export const DuplicateDialog: React.FC<IDuplicateDialogProps> = (props) => {
    const { open, dialogContext, inputDialogProps } = useDuplicateDialog(props);

    if (!open) {
        return null;
    }
    return (
        <DialogContext.Provider value={dialogContext}>
            <InputDialog {...inputDialogProps} />
        </DialogContext.Provider>
    );
};

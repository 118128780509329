import * as Pixi from "pixi.js";

export class Filters {
    private static filters: Filters;

    public greyscaleMatrix = new Pixi.ColorMatrixFilter();
    private constructor() {
        this.greyscaleMatrix.greyscale(0.4, false);
    }

    public static getInstance(): Filters {
        if (this.filters === undefined) {
            this.filters = new Filters();
        }

        return this.filters;
    }
}

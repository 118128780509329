import { AlertPalettes, Mode, Palette, useTheme } from "@corning-ctcm/silica-react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getDefaultBuildDescription } from "../../../../components/overlay/project-drawer/project-manager-row/build-info/build-info.hooks";
import { ITolerances } from "../../../../models/overlay/header/tolerances/tolerances";
import { PrimaryUnit, Unit, Units, UoMInches, convertTo } from "../../../../models/overlay/header/units-of-measure";
import { setUserId } from "../../../../store/authentication/authentication.reducers";
import { authenticationSelector } from "../../../../store/authentication/authentication.selectors";
import { setIsLocked, setStatusState } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import {
    currentStatusSelector,
    isLockedSelector,
} from "../../../../store/overlay/header/status-icon/status-icon.selectors";
import { setNotification } from "../../../../store/overlay/notification/notification.reducers";
import { StaggerLength } from "../../../../store/overlay/wizard/wizard";
import {
    restartWizard,
    setWizardDisplay,
    updateConnectorTypeChanged,
} from "../../../../store/overlay/wizard/wizard.reducers";
import { wizardSelector } from "../../../../store/overlay/wizard/wizard.selectors";
import { ViewportStatus } from "../../../../store/pixi/viewport/viewport";
import { viewportSelector } from "../../../../store/pixi/viewport/viewport.selectors";
import { defaultBoundariesValuesSelector } from "../../../../store/workspace/boundaries/boundaries.selectors";
import { IBuildData, IEnableData } from "../../../../store/workspace/build/build";
import { getGroupBLength } from "../../../../store/workspace/build/build.actions";
import {
    setBuildLoaded,
    setCurrentBuild,
    updateBuild,
    updateBuildInfo,
} from "../../../../store/workspace/build/build.reducers";
import { IConnectorGroupData } from "../../../../store/workspace/build/connector/connector";
import { IDrop, initialDistributionLengthB, initialFeederLengthB } from "../../../../store/workspace/build/drop";
import { setResize, setViewportContext } from "../../../../store/pixi/viewport/viewport.reducers";
import { useCallback, useEffect, useState } from "react";
import { AppDispatch } from "../../../../store/workspace/workspace.reducers";
import { LocalizationKeys } from "../../../../locales/keys";
import { UserService } from "../../../../services/user.service";
import { WorkspaceStatus, WorkspaceStatusTypes } from "../../../../store/overlay/header/status-icon/status-icon";
import { buildStateSelector } from "../../../../store/workspace/root.selectors";
import { setSessionBusy, setSessionWarnings } from "../../../../store/workspace/ssc/ssc.reducer";
import { sscSessionIdsSelector } from "../../../../store/workspace/ssc/ssc.selectors";
import { useSscBuildSession } from "../../../workspace/ssc.hooks";

export interface IDefaultValuesArgs {
    defaultPrimaryMeasurementSystem: PrimaryUnit;
    defaultUnit: Unit;
    defaultShellType: string;
    defaultMeasurementsPosition: string;
    defaultOverallLengthType: string;
    defaultTolerances: ITolerances;
    defaultMesh: boolean;
    defaultMeshValue: number;
    defaultMeshColor: string;
}

export const createdLoadedBuild = (build: IBuildData, args?: IDefaultValuesArgs) => {
    const unit = (build.unit ?? args?.defaultUnit) as Unit;
    let drops: IDrop[] = [];
    // There has been legacy cases where blength might have been undefined. We'll do a best effort to fix them
    if (build.drops.some((d) => d.groups.some((g) => g.lengthB === undefined || g.lengthB.value === -1))) {
        for (const drop of build.drops) {
            const groups: IConnectorGroupData[] = [];
            for (let groupIndex = 0; groupIndex < drop.groups.length; groupIndex++) {
                let group = drop.groups[groupIndex];
                if (group.lengthB === undefined || group.lengthB.value === -1) {
                    const id = group.lengthB?.id ?? 0;
                    const initialBLength =
                        drop.side === "feeder" ? initialFeederLengthB.value : initialDistributionLengthB.value;
                    const initialStagger = drop.side === "feeder" ? 0 : StaggerLength.Stagger24.value.value;
                    const stagger = group.stagger ? group.stagger.value : initialStagger;
                    const bLength = getGroupBLength(initialBLength, stagger, drop.groups.length, groupIndex);
                    group = { ...group, lengthB: { id, value: bLength, unit: UoMInches } };
                }
                groups.push(group);
            }
            const shell = drop.shell ?? args?.defaultShellType;
            const defaultMeshOffset = convertTo({ value: args?.defaultMeshValue ?? 0, unit: Units.UoMInches }, unit);
            const meshOffset = drop.meshOffset ? drop.meshOffset : defaultMeshOffset;
            const meshColor = drop.meshColor ? drop.meshColor : args?.defaultMeshColor;
            drops.push({
                ...drop,
                meshOffset: drop.mesh ? meshOffset : undefined,
                meshColor: drop.mesh ? meshColor : undefined,
                shell,
                groups,
            });
        }
    } else {
        drops = build.drops;
    }

    const availability: IEnableData = {
        sourceEnabled: true,
        enabledDestinations: build.drops.filter((d) => d.side === "distribution").map((d) => d.position),
    };

    const primaryUnit = (build.primaryUnit ?? args?.defaultPrimaryMeasurementSystem) as PrimaryUnit;
    const measurementsPosition = build.measurementsPosition ?? args?.defaultMeasurementsPosition;
    const overallLengthType = build.overallLengthType ?? args?.defaultOverallLengthType;
    const tolerances = build.tolerances ?? args?.defaultTolerances;
    const loadedBuild: IBuildData = {
        ...build,
        primaryUnit,
        unit,
        measurementsPosition,
        overallLengthType,
        tolerances,
        drops,
        availability,
    };

    return loadedBuild;
};

export const useWorkspace = () => {
    const dispatch = useDispatch();
    const defaultValues = useSelector(defaultBoundariesValuesSelector);

    const setWorkspace = useCallback(
        (build?: IBuildData) => {
            if (build) {
                dispatch(setCurrentBuild({ ...createdLoadedBuild({ ...build }, defaultValues) }));
                dispatch(setWizardDisplay(false));
                dispatch(setViewportContext("active"));
            } else {
                dispatch(restartWizard());
                dispatch(setWizardDisplay(true));
                dispatch(setResize({ update: true, value: "fit-to-source" }));
            }
        },
        [dispatch, defaultValues]
    );

    return { setWorkspace };
};

export const useWebLoad = () => {
    const { user, customer } = useSelector(authenticationSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setWorkspace } = useWorkspace();

    const dispatchBuild = useCallback(
        async (build: IBuildData | undefined, skipSscUpdate = false) => {
            dispatch(setSessionWarnings({ buildId: build?.id, warnings: [] }));
            dispatch(setStatusState(WorkspaceStatus.Ready));
            setWorkspace(build);
            dispatch(setBuildLoaded(true));
            dispatch(setIsLocked(false));
            if (build) {
                let currentUserId = user.platformId;
                if (customer && !user.platformId) {
                    const userIdResponse = await new UserService().getUserId();
                    if (userIdResponse) {
                        currentUserId = userIdResponse;
                        dispatch(setUserId(currentUserId));
                    }
                }
                if (build.lockedById !== -1 && build.lockedById !== currentUserId) {
                    dispatch(setIsLocked(true));
                    const message = build.lockedByFullName
                        ? t(LocalizationKeys.CableLockedWarning, { fullName: build.lockedByFullName })
                        : t(LocalizationKeys.Unknown);
                    dispatch(setNotification({ palette: AlertPalettes.info, message }));
                }
            }
        },
        [user.platformId, customer, t, dispatch, setWorkspace]
    );

    return { setWorkspace, dispatchBuild };
};

export const useWebSave = () => {
    const { currentBuild, builds } = useSelector(buildStateSelector);
    const { t } = useTranslation();
    const { updateBuildSession } = useSscBuildSession();
    const dispatch = useDispatch<AppDispatch>();
    const sessionIds = useSelector(sscSessionIdsSelector);
    const { context } = useSelector(viewportSelector);
    const { currentStep, savedSourceSetup } = useSelector(wizardSelector);

    const saveBuildById = useCallback(
        async (projectId: number) => {
            dispatch(setSessionBusy(true));
            dispatch(setStatusState(WorkspaceStatus.Saving));
            let build = builds.find((x) => x.buildId === projectId);
            if (build) {
                dispatch(updateBuildInfo(build));
            }
            dispatch(setSessionBusy(false));
            dispatch(setStatusState(WorkspaceStatus.Saved));
        },
        [dispatch, builds]
    );

    const saveBuild = useCallback(
        async (build?: IBuildData) => {
            const updatedBuild = build ?? currentBuild;
            if (updatedBuild) {
                if (sessionIds[updatedBuild.id ?? 0]?.sessionId.length || updatedBuild.id) {
                    // If the build exists, we just want to update it
                    dispatch(updateBuild(updatedBuild));
                    updateBuildSession(updatedBuild);
                } else {
                    // We will make sure to push the build to the database
                    const build = { ...updatedBuild, description: getDefaultBuildDescription(updatedBuild) };
                    updateBuildSession(build, true, true);
                }

                if (context === ViewportStatus.Editing && currentStep === 1 && savedSourceSetup?.connectorTypeChanged) {
                    const message = t(LocalizationKeys.TAPConnectorsUpdatedSnackBarMessage);
                    dispatch(setNotification({ palette: AlertPalettes.info, message }));
                    dispatch(updateConnectorTypeChanged(false));
                }
            }
        },
        [currentBuild, sessionIds, savedSourceSetup, context, currentStep, dispatch, updateBuildSession, t]
    );

    return { saveBuild, saveBuildById, currentBuild };
};

export const useStatus = () => {
    const currentStatus = useSelector(currentStatusSelector);
    const isLocked = useSelector(isLockedSelector);
    const [className, setClassName] = useState("");
    const { color: backgroundColor } = useTheme(getStatusThemeOptions(currentStatus, isLocked));

    useEffect(() => {
        if (isLocked) {
            setClassName("status locked");
        }

        if (
            currentStatus === WorkspaceStatus.Loading ||
            currentStatus === WorkspaceStatus.Printing ||
            currentStatus === WorkspaceStatus.Saving ||
            currentStatus === WorkspaceStatus.Busy ||
            currentStatus === WorkspaceStatus.InitialLoad
        ) {
            setClassName("status fadeloop");
        }

        if (
            currentStatus === WorkspaceStatus.Ready ||
            currentStatus === WorkspaceStatus.Saved ||
            currentStatus === WorkspaceStatus.Printed
        ) {
            setClassName("status fadeout");
        }
    }, [currentStatus, isLocked]);

    return { backgroundColor, currentStatus, isLocked, className };
};

const getStatusThemeOptions = (status: WorkspaceStatusTypes, locked: boolean): { palette: Palette; mode: Mode } => {
    if (locked) return { palette: "secondary", mode: "main" };
    switch (status) {
        case WorkspaceStatus.Loading:
        case WorkspaceStatus.InitialLoad:
        case WorkspaceStatus.Busy:
            return { palette: "secondary", mode: "dark" };
        case WorkspaceStatus.Printing:
        case WorkspaceStatus.Saving:
            return { palette: "warning", mode: "light" };
        case WorkspaceStatus.Printed:
        case WorkspaceStatus.Saved:
            return { palette: "success", mode: "main" };
        default:
            return { palette: "primary", mode: "main" };
    }
};

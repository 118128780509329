import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./zoom-legacy";
import { setZoomReducer } from "./zoom-legacy.actions";

const slice = createSlice({
    name: "zoom",
    initialState,
    reducers: {
        setZoom: setZoomReducer,
    },
});

export const ZoomReducer = slice.reducer;
export const { setZoom } = slice.actions;

import React, { PropsWithChildren } from "react";
import { useBLengthSection } from "./b-length-section.hooks";
import { RadioGroup, Radio } from "@corning-ctcm/silica-react";
import { BLengthContext, IBLengthProps } from "../../../../../models/overlay/wizard/setup/b-length-section/b-length-section";

import "../../../../../css/overlay/wizard/setup/b-length-section/b-length-section.scss";

export const BLengthSection: React.FC<PropsWithChildren<IBLengthProps>> = ({ children, ...props }) => {
    const { bLengthRadioGroup, radioOptions, isCustom } = useBLengthSection(props);
    return (
        <div className="blength-section-container">
            <BLengthContext.Provider value={{ drop: props.drop, isCustom }}>
                <div className="radio-group">
                    <RadioGroup value={bLengthRadioGroup.value} onChange={bLengthRadioGroup.onRadioGroupChange}>
                        <div className="radio-group-container">
                            {radioOptions.map((r, i) => (
                                <Radio
                                    id={`b-length-type-option-${i}`}
                                    key={r.label}
                                    label={r.label}
                                    value={r.sectionName}
                                    checked={r.sectionName === bLengthRadioGroup.value}
                                    disabled={props.disabled}
                                />
                            ))}
                        </div>
                    </RadioGroup>
                </div>
                {children}
            </BLengthContext.Provider>
        </div>
    );
};

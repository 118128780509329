import { createSelector } from "reselect";
import { selectedSideSelector } from "../../../../overlay/footer/toolbar/toolbar.selectors";
import { WorkspaceState } from "../../../workspace.reducers";
import { customScheme } from "./label-scheme";

export const orgBundlesSelector = (state: WorkspaceState) => state.labelScheme.orgBundles;
export const appBundleSelector = (state: WorkspaceState) => state.labelScheme.appBundle;
export const userBundleSelector = (state: WorkspaceState) => state.labelScheme.userBundle;

export const orgSchemesSelector = createSelector(orgBundlesSelector, (orgBundles) =>
    orgBundles?.length ? orgBundles.flatMap((o) => o.schemeBundle).flatMap((b) => b.labelSchemes) : []
)

export const appSchemesSelector = (state: WorkspaceState) => state.labelScheme.appBundle.labelSchemes;
export const userSchemesSelector = (state: WorkspaceState) => state.labelScheme.userBundle.labelSchemes;

export const defaultSchemesSelector = createSelector(
    orgSchemesSelector,
    appSchemesSelector,
    userSchemesSelector,
    (orgSchemes, appSchemes, userSchemes) => {
        return [...orgSchemes, ...appSchemes, ...userSchemes];
    }
);

export const schemesSelector = createSelector(
    selectedSideSelector,
    defaultSchemesSelector,
    (side, defaultSchemes) => {
        const sideSchemes =
            side === "feeder"
                ? defaultSchemes.filter((s) => s.side === "feeder")
                : defaultSchemes.filter((s) => s.side === "drop");
        return [...sideSchemes, customScheme];
    }
);

export const feederSchemesSelector = createSelector(defaultSchemesSelector, (defaultSchemes) =>
    defaultSchemes.filter((s) => s.side === "feeder")
);

export const destinationSchemesSelector = createSelector(
    defaultSchemesSelector,
    (defaultSchemes) => defaultSchemes.filter(s => s.side === "drop")
);
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { INotificationManagerState, INotificationMessage, initialNotificationManagerState } from "./notification";
import { PropagationResult, getPropagationNotification } from "../../../store/overlay/polarity/propagation/propagation";

const showNotificationReducer = (state: INotificationManagerState, action: PayloadAction<boolean>) => {
    state.display = action.payload;
};

const setNotificationReducer = (state: INotificationManagerState, action: PayloadAction<INotificationMessage>) => {
    state.display = true;
    state.notification = action.payload;
};

const setPropagationNotificationReducer = (
    state: INotificationManagerState,
    action: PayloadAction<PropagationResult>
) => {
    state.display = true;
    state.notification = getPropagationNotification(action.payload);
};

const notificationManagerSlice = createSlice({
    initialState: initialNotificationManagerState,
    name: "notification-manager",
    reducers: {
        showNotification: showNotificationReducer,
        setNotification: setNotificationReducer,
        setPropagationNotification: setPropagationNotificationReducer,
    },
});

export const NotificationManagerReducer = notificationManagerSlice.reducer;
export const { showNotification, setNotification, setPropagationNotification } = notificationManagerSlice.actions;

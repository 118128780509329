import { PayloadAction } from "@reduxjs/toolkit";
import { IProjectDrawerState } from "./project-drawer";

export const setSearchAction = (state: IProjectDrawerState, action: PayloadAction<string>) => {
    state.search = action.payload;
};

export const setReachedLastPageAction = (state: IProjectDrawerState, action: PayloadAction<boolean>) => {
    state.reachedLastPage = action.payload;
};

export const setLoadingMoreAction = (state: IProjectDrawerState, action: PayloadAction<boolean>) => {
    state.loadingMore = action.payload;
};

export const setReloadingAction = (state: IProjectDrawerState, action: PayloadAction<boolean>) => {
    state.reloading = action.payload;
};

import { Assets, Graphics, Texture } from "pixi.js";
import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { trunkColorSelector } from "../../../../../store/workspace/boundaries/boundaries.selectors";
import { DropContext } from "../../../../../store/workspace/build/build";
import { CROSSHATCH_TEXTURE } from "../../../factories/texture";
import { VerticalTrunkContainer, getMaxNumberOfCablesPerGroup } from "../../../../../models/pixi/build/build";
import { BORDER_COLOR, DROP_CABLE_BORDER_WIDTH, getConnectorCableDrawWidth } from "../../../../../models/pixi/build/connectors/connector-furcation";
import { AP_BORDER_WIDTH, AP_SIZE } from "../../../../../models/pixi/build/drop-base/access-point";
import {
    getCableStartOffset,
    getLineMeshTextureOptions,
    getMeshColor,
    TRUNK_LENGTH_ADJUSTMENT,
    TRUNK_WIDTH,
} from "../../../../../models/pixi/build/drop-base/drop-base";
import { VERTICAL_TRUNK_HEIGHT } from "../vertical-trunk/vertical-trunk.hooks";
import { viewportSelector } from "../../../../../store/pixi/viewport/viewport.selectors";
import { IPoint } from "../../../../../models/pixi/pixi";

const CURVE_END = 50.0;

export const useCurvedTrunk = () => {
    const { index, mesh, meshColor, nbConnectors, connectorType, containerName } = useContext(DropContext);
    const { colorHex } = useSelector(trunkColorSelector);
    const { scale } = useSelector(viewportSelector);

    const draw = useCallback(
        async (grfx: Graphics) => {
            grfx.clear();
            if (mesh) {
                const meshTexture = Assets.get<Texture>(CROSSHATCH_TEXTURE);
                drawCurvedTrunkWithMesh(grfx, index, getMeshColor(meshColor), meshTexture, scale);
            } else {
                const cableColor = Number(colorHex);
                const cableWidth = getConnectorCableDrawWidth(connectorType);
                const numberOfCablesToDraw = Math.min(nbConnectors, getMaxNumberOfCablesPerGroup(connectorType));
                drawCurvedTrunkNoMesh(grfx, index, cableColor, cableWidth, numberOfCablesToDraw, connectorType);
            }
        },
        [index, colorHex, mesh, meshColor, nbConnectors, connectorType, scale]
    );

    return {
        name: `${containerName}_${VerticalTrunkContainer}`,
        draw,
    };
};

const drawCurvedTrunkWithMesh = (
    grfx: Graphics,
    index: number,
    meshColor: number,
    meshTexture: Texture,
    scale: IPoint
) => {
    const m = index === 0 ? -1 : 1;
    const x1 = m * AP_SIZE.width;
    const x2 = x1 + m * AP_SIZE.width * 0.5;
    const hOffset = m * TRUNK_LENGTH_ADJUSTMENT;

    grfx.clear();

    // Horizontal
    // Border
    grfx.moveTo(0, 0);
    grfx.lineStyle(TRUNK_WIDTH + AP_BORDER_WIDTH, BORDER_COLOR);
    grfx.lineTo(x1 + hOffset, 0);

    // Trunk
    grfx.moveTo(0, 0);
    grfx.lineStyle(TRUNK_WIDTH, meshColor);
    grfx.lineTo(x1 + hOffset, 0);

    const y = VERTICAL_TRUNK_HEIGHT;

    // Curve
    // Border
    grfx.moveTo(x1, 0);
    grfx.lineStyle(TRUNK_WIDTH + AP_BORDER_WIDTH, BORDER_COLOR);
    grfx.bezierCurveTo(x1, 0, x2, 0, x2, CURVE_END);

    // Trunk
    grfx.moveTo(x1, 0);
    grfx.lineStyle(TRUNK_WIDTH, meshColor);
    grfx.bezierCurveTo(x1, 0, x2, 0, x2, CURVE_END);

    // Vertical
    // Border
    grfx.moveTo(x2, CURVE_END - TRUNK_LENGTH_ADJUSTMENT);
    grfx.lineStyle(TRUNK_WIDTH + AP_BORDER_WIDTH, BORDER_COLOR);
    grfx.lineTo(x2, y);

    // Trunk
    grfx.moveTo(x2, CURVE_END - TRUNK_LENGTH_ADJUSTMENT);
    grfx.lineStyle(TRUNK_WIDTH, meshColor);
    grfx.lineTo(x2, y);

    // Texture
    if (meshTexture) {
        grfx.lineTextureStyle(getLineMeshTextureOptions(TRUNK_WIDTH, meshTexture, scale));
    }

    // Horizontal
    grfx.moveTo(0, 0);
    grfx.lineTo(x1 + hOffset, 0);

    // Curve
    grfx.moveTo(x1, 0);
    grfx.bezierCurveTo(x1, 0, x2, 0, x2, CURVE_END);

    // Vertical
    grfx.moveTo(x2, CURVE_END - TRUNK_LENGTH_ADJUSTMENT);
    grfx.lineTo(x2, y);
};

const drawCurvedTrunkNoMesh = (
    grfx: Graphics,
    index: number,
    cableColor: number,
    cableWidth: number,
    numberOfCables: number,
    connectorType: string
) => {
    const m = index === 0 ? -1 : 1;
    const inverseM = m * -1; // inverseM is to align the y offset to the x offset correctly
    const x1 = m * AP_SIZE.width;
    const x2 = x1 + m * AP_SIZE.width * 0.5;
    const hOffset = m * TRUNK_LENGTH_ADJUSTMENT;
    const cableAndBorderWidth = cableWidth + DROP_CABLE_BORDER_WIDTH;

    const start = getCableStartOffset(numberOfCables, cableAndBorderWidth, connectorType);
    grfx.clear();
    for (let c = 0; c < numberOfCables; c++) {
        const offset = start + cableAndBorderWidth * c;

        // Horizontal
        // Border
        grfx.moveTo(0, offset);
        grfx.lineStyle(cableAndBorderWidth, BORDER_COLOR);
        grfx.lineTo(x1 + hOffset, offset);

        // Trunk
        grfx.moveTo(0, offset);
        grfx.lineStyle(cableWidth, cableColor);
        grfx.lineTo(x1 + hOffset, offset);

        const y = VERTICAL_TRUNK_HEIGHT;

        // Curve
        // Border
        grfx.moveTo(x1, 0 + offset);
        grfx.lineStyle(cableAndBorderWidth, BORDER_COLOR);
        grfx.bezierCurveTo(x1, 0 + offset, x2 + offset * inverseM, 0 + offset, x2 + offset * inverseM, CURVE_END);

        // Trunk
        grfx.moveTo(x1, 0 + offset);
        grfx.lineStyle(cableWidth, cableColor);
        grfx.bezierCurveTo(x1, 0 + offset, x2 + offset * inverseM, 0 + offset, x2 + offset * inverseM, CURVE_END);

        // Vertical
        // Border
        grfx.moveTo(x2 + offset, CURVE_END - TRUNK_LENGTH_ADJUSTMENT);
        grfx.lineStyle(cableAndBorderWidth, BORDER_COLOR);
        grfx.lineTo(x2 + offset, y);

        // Trunk
        grfx.moveTo(x2 + offset, CURVE_END - TRUNK_LENGTH_ADJUSTMENT);
        grfx.lineStyle(cableWidth, cableColor);
        grfx.lineTo(x2 + offset, y);
    }
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ICableInfoState {
    name: string;
    description: string;
    partNumber: string;
    polarity: string;
}

export const initialCableInfoState: ICableInfoState = {
    name: "",
    description: "",
    partNumber: "",
    polarity: ""
}

const setNameReducer = (state: ICableInfoState, action: PayloadAction<string>) => {
    state.name = action.payload;
}

const setDescriptionReducer = (state: ICableInfoState, action: PayloadAction<string>) => {
    state.description = action.payload;
}

const setPartNumberReducer = (state: ICableInfoState, action: PayloadAction<string>) => {
    state.partNumber = action.payload;
}

const setPolarityReducer = (state: ICableInfoState, action: PayloadAction<string>) => {
    state.polarity = action.payload;
}

const cableInfoSlice = createSlice({
    name: "cable-info",
    initialState: initialCableInfoState,
    reducers: {
        setName: setNameReducer,
        setDescription: setDescriptionReducer,
        setPartNumber: setPartNumberReducer,
        setPolarity: setPolarityReducer
    }
});

export const CableInfoReducer = cableInfoSlice.reducer;
export const {
    setName,
    setDescription,
    setPartNumber,
    setPolarity
} = cableInfoSlice.actions;
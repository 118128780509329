import { Canvg } from "canvg";
import i18next, { TFunction } from "i18next";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { chipHeight, chipOriginalHeight, chipOriginalWidth, chipWidth } from "../components/ui/color-chip";
import { InterRegular } from "../css/font/resources/Inter-Regular-normal";
import { LocalizationKeys } from "../locales/keys";
import { ProductLabel } from "../models/overlay/reports/report-settings/labels-section";
import { ReportType } from "../models/overlay/reports/report-settings/report-settings";
import { BuildDocument, DocumentDrop, IUserProvidedImage } from "../store/workspace/document/document";
import { getImage } from "../components/pixi/canvas-extractor/canvas-extractor";
import { getElementById, getElements, hasElement } from "../components/off-screen/extractor/offscreen-extractor";
import { isMMC16 } from "../components/pixi/factories/texture";

const pageSize = { width: 2000, height: 1294 };
const pixelConversionFactor = 1;
const padding = 60;
const cellPadding = 10;
const tableMargin = 70;

const topLeft = { x: padding, y: padding };
const boxWidth = pageSize.width - 2 * padding;

const fontScale = 4.76;
const fontSize = 16 * fontScale;
const sectionFontSize = 12 * fontScale;
const footerFontSize = 11.5 * fontScale;
const footerContentFontSize = 11 * fontScale;
const labelDescriptionFontSize = 10 * fontScale;
const boxHeight = pageSize.height * 0.051;
const boxCompanyWidth = boxWidth / 8;
const boxPageWidth = boxWidth / 12;
const boxPageStart = topLeft.x + boxWidth - boxPageWidth;

const boxTitleStart = topLeft.x + boxCompanyWidth;
const boxTitleWidth = boxWidth - boxCompanyWidth - boxPageWidth;

const footerStart = { x: padding, y: pageSize.height - padding - boxHeight };

const pageYContentStart = topLeft.y + boxHeight + 65;
const pageYContentHeight = footerStart.y - pageYContentStart - padding;

const labelHeight = 200;
const labelWidth = boxWidth / 3;
const labelRectWidth = labelWidth * (5 / 8);
const labelRectHeight = labelHeight / 2;
const totalLabelHeight = labelHeight + 100;

const textColor = "#1A45D7";


type ChipsRecord = Record<number, string>;
type ChipsData = {
    connectors: ChipsRecord;
    fibers: ChipsRecord;
}

export const createPDF = async (settings: BuildDocument, translate: TFunction) => {
    var doc = new jsPDF();
    doc.deletePage(1);
    doc.addFileToVFS("Inter-Regular-normal.ttf", InterRegular);
    doc.addFont("Inter-Regular-normal.ttf", "Inter-Regular", "normal");
    doc.setFont("Inter-Regular");

    const chipsData = await generateChipsData();

    if (settings.overview) {
        createOverviewPage(doc, settings, translate);
    }

    createLabelsLengthsSection(doc, settings, translate);

    if (settings.reportType === ReportType.Detailed) {
        if (settings.feeder) {
            createFeederPage(doc, settings, translate, chipsData);
        }
        if (settings.drops?.length) {
            createDropPages(doc, settings, translate, chipsData);
        }

        if (settings.connector?.include) {
            createLabelSummaryPage(doc, translate, chipsData);
        }

        if (settings.fiberMap?.include) {
            createFiberMapPages(doc, settings, chipsData);
        }

        if (settings.buildPlan?.include) {
            createBuildPlanPages(doc, settings, translate);
        }
    }

    let fileName = "RAB-Report";

    if (settings.fileName) {
        fileName += "-" + settings.fileName;
    }

    if (settings.catalogCode) {
        fileName += "-" + settings.catalogCode;
    }

    if (settings.revision) {
        fileName += "-" + settings.revision;
    }

    if (settings.userProvidedImages) {
        await addUserProvidedImages(doc, settings.userProvidedImages);
    }

    for (let i = 0; i < doc.internal.pages.length - 1; i++) {
        doc.setPage(i + 1);
        addHeaderFooter(doc, i + 1, doc.internal.pages.length - 1, settings, translate);
    }

    fileName = fileName.replace(".", "_");

    if (settings.draft) {
        fileName += translate(LocalizationKeys.ReportDraft).replace(/ /g, "").toLocaleUpperCase();
    }

    doc.save(fileName + ".pdf");
};

async function addUserProvidedImages(pdf: jsPDF, images: IUserProvidedImage[]) {
    const uploads = images.map(({ objectUrl }) => {
        const image = new Image();
        image.src = objectUrl;
        const loaded = new Promise<void>((resolve) => (image.onload = () => resolve()));
        return { image, loaded };
    });

    for (const upload of uploads) {
        await upload.loaded;
        addImage(pdf, upload.image);
    }
}

function addImage(pdf: jsPDF, image: HTMLImageElement) {
    const { t } = i18next;
    let { imageWidth, imageHeight } = fitImageToPage(image, { width: boxWidth, height: pageYContentHeight });
    const x = (pageSize.width - imageWidth) * 0.5;
    const y = pageYContentStart;
    pdf.addPage([pageSize.width * pixelConversionFactor, pageSize.height * pixelConversionFactor], "l");
    pdf.addImage(image, "SVG", x, y, imageWidth, imageHeight);
    pdf.setFontSize(labelDescriptionFontSize);
    const wrappedNote = pdf.splitTextToSize(t(LocalizationKeys.ReportUserProvidedImagesNote), imageWidth);
    pdf.text(wrappedNote, x, y + imageHeight + 10, { baseline: "top" });
}

function fitImageToPage(image: { width: number; height: number }, page: { width: number; height: number }) {
    let imageWidth = page.width;
    let ratio = image.width / imageWidth;
    let imageHeight = image.height / ratio;
    if (imageHeight > page.height) {
        imageHeight = page.height;
        ratio = image.height / imageHeight;
        imageWidth = image.width / ratio;
    }
    return { imageWidth, imageHeight };
}

function getPolarityDiagram(settings: BuildDocument) {
    const polarityDiagramName = settings.polarity?.fiberMapNames[0]; // We only show the first diagram
    const polarityDiagramData: {
        polarityDiagram: HTMLImageElement | undefined;
        polarityDiagramName: string | undefined;
    } = {
        polarityDiagram: undefined,
        polarityDiagramName,
    };

    if (polarityDiagramName) {
        polarityDiagramData.polarityDiagram = getElementById(polarityDiagramName) as HTMLImageElement;
    }

    return polarityDiagramData;
}

export function createOverviewPage(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    doc.addPage([pageSize.width * pixelConversionFactor, pageSize.height * pixelConversionFactor], "l");

    const yOverviewContentEnd = createCableOverview(doc, settings, translate, pageYContentStart);
    const sectionWidth = boxWidth / 3;

    // Calculate how big the polarity diagram will be
    const maxImageHeight = 500;
    const maxImageWidth = sectionWidth - 50;
    const { polarityDiagram, polarityDiagramName } = getPolarityDiagram(settings);
    const { imageWidth, imageHeight } = polarityDiagram
        ? fitImageToPage(polarityDiagram, { width: maxImageWidth, height: maxImageHeight })
        : { imageWidth: 0, imageHeight: 0 };

    // Calculate the height the notes/diagram/tolerances section
    const minSectionHeight = 280;
    const sectionHeight = Math.max(imageHeight + 115, minSectionHeight);

    // Calculate the height of the drawing section
    const drawingSectionHeight = footerStart.y - yOverviewContentEnd - sectionHeight;

    // Create the cable drawing
    const yDrawingContentEnd =
        createCableDrawing(doc, settings, translate, drawingSectionHeight, yOverviewContentEnd) + 15;

    // Create the notes/diagram/tolerances section
    const xFirstSection = topLeft.x;
    const xSecondSection = topLeft.x + sectionWidth;
    const xThirdSection = topLeft.x + 2 * sectionWidth;

    createDrawingNotesSection(doc, settings, translate, xFirstSection, yDrawingContentEnd);
    createPolarityDiagramSection(
        doc,
        translate,
        xSecondSection,
        yDrawingContentEnd,
        { width: imageWidth, height: imageHeight },
        polarityDiagram,
        polarityDiagramName
    );

    if (settings.tolerances) {
        createTolerancesSection(doc, translate, xThirdSection, yDrawingContentEnd);
    }
}

export const addHeaderFooter = (
    doc: jsPDF,
    pageNumber: number,
    pageCount: number,
    settings: BuildDocument,
    translate: TFunction
) => {
    doc.setTextColor("black");
    createHeader(doc, pageNumber, pageCount, settings, translate);
    createFooter(doc, settings, translate);
};

export function createFeederPage(doc: jsPDF, settings: BuildDocument, translate: TFunction, chipsData: ChipsData) {
    doc.addPage([pageSize.width * pixelConversionFactor, pageSize.height * pixelConversionFactor], "l");
    createFeederSection(doc, settings, translate);
    doc.addPage([pageSize.width * pixelConversionFactor, pageSize.height * pixelConversionFactor], "l");
    createFeederTablePage(doc, translate, chipsData.fibers);
}

async function createLabelSummaryPage(doc: jsPDF, translate: TFunction, chipsData: ChipsData) {
    doc.addPage([pageSize.width * pixelConversionFactor, pageSize.height * pixelConversionFactor], "l");
    createLabelTablePage(doc, translate, chipsData.connectors);
}

export async function createDropPages(
    doc: jsPDF,
    settings: BuildDocument,
    translate: TFunction,
    chipsData: ChipsData
) {
    if (settings.drops) {
        for (const drop of settings.drops) {
            doc.addPage([pageSize.width * pixelConversionFactor, pageSize.height * pixelConversionFactor], "l");
            createDropSection(doc, drop, translate);
            doc.addPage([pageSize.width * pixelConversionFactor, pageSize.height * pixelConversionFactor], "l");
            createDropTablePage(doc, drop, translate, chipsData.connectors);
        }
    }
}

function createCableOverview(doc: jsPDF, settings: BuildDocument, translate: TFunction, textStart: number) {
    doc.setFontSize(fontSize);
    doc.setTextColor(textColor);
    doc.text(translate(LocalizationKeys.CableDrawing), topLeft.x, textStart);

    textStart = textStart + 30;

    if (settings.cableOverview) {
        doc.setFontSize(sectionFontSize);
        doc.setTextColor("black");
        doc.text(settings.overview?.description ?? "", topLeft.x, textStart);
    }

    return textStart;
}

function createCableDrawing(
    doc: jsPDF,
    settings: BuildDocument,
    translate: TFunction,
    maxHeight: number,
    textStart: number
) {
    // image
    const rabImage = getImage("report-rapid-bundle");
    if (!rabImage) return textStart;

    textStart += 30;

    const { imageWidth, imageHeight } = fitImageToPage(rabImage, { width: boxWidth, height: maxHeight - 60 });

    const imageX = (pageSize.width - imageWidth) * 0.5;
    doc.addImage(rabImage, "JPEG", imageX, textStart, imageWidth, imageHeight);

    const yContentEnd = textStart + imageHeight + 30;
    createRepresentativeExample(doc, yContentEnd, translate);

    return yContentEnd;
}

function createDrawingNotesSection(
    doc: jsPDF,
    settings: BuildDocument,
    translate: TFunction,
    xContentStart?: number,
    yContentStart?: number
) {
    const xTextStart = xContentStart ?? topLeft.x;
    let yTextStart = yContentStart ?? pageYContentStart;
    const maxWidth = boxWidth / 3;
    const textWidth = maxWidth - 50;

    doc.setFontSize(fontSize);
    doc.setTextColor(textColor);
    doc.text(translate(LocalizationKeys.Notes), xTextStart, yTextStart + 20);

    yTextStart = yTextStart + 50;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor("black");
    doc.text(settings.drawingNotes ?? "", xTextStart, yTextStart, { maxWidth: textWidth });
}

function createPolarityDiagramSection(
    doc: jsPDF,
    translate: TFunction,
    xContentStart: number,
    yContentStart: number,
    polarityDiagramSize: { width: number; height: number },
    polarityDiagram?: HTMLImageElement,
    polarityDiagramName?: string
) {
    doc.setFontSize(fontSize);
    doc.setTextColor(textColor);
    doc.text(translate(LocalizationKeys.PolarityDiagram), xContentStart, yContentStart + 20);

    doc.setFontSize(sectionFontSize);
    doc.setTextColor("black");

    if (polarityDiagramName) {
        doc.text(polarityDiagramName, xContentStart, yContentStart + 50); // Polarity description above diagram

        if (polarityDiagram) {
            doc.addImage({
                imageData: polarityDiagram,
                format: "PNG",
                x: xContentStart,
                y: yContentStart + 70,
                width: polarityDiagramSize.width,
                height: polarityDiagramSize.height,
                compression: "FAST",
            });
        }
    } else {
        doc.text(translate(LocalizationKeys.NA), xContentStart, yContentStart + 50);
    }
}

function createTolerancesSection(doc: jsPDF, translate: TFunction, xContentStart?: number, yContentStart?: number) {
    const xTextStart = xContentStart ?? topLeft.x;
    const yTextStart = yContentStart ?? pageYContentStart;
    doc.setFontSize(fontSize);
    doc.setTextColor(textColor);
    doc.text(translate(LocalizationKeys.Tolerances), xTextStart, yTextStart + 20);

    const { width } = pageSize;
    const maxWidth = width - xTextStart - padding;
    // Pattern: The tolerances table use an html table in the Offscreen component as a base.
    //          The Offscreen tolerances table is generated by using selectors of build data from the redux state
    //          The tables are pre-extracted and then reused when needed
    const table = getElementById("tolerances-table") as HTMLTableElement;
    autoTable(doc, {
        html: table,
        tableLineColor: 0x000000,
        tableLineWidth: 1,
        startY: yTextStart + 40,
        tableWidth: maxWidth,
        head: [
            [
                translate(LocalizationKeys.MeasurementsPosition) as string,
                translate(LocalizationKeys.Tolerances) as string,
            ],
        ],
        styles: {
            fontSize: sectionFontSize,
            fillColor: 0xffffff,
            textColor: 0x000000,
            lineColor: 0x000000,
            lineWidth: 1,
            halign: "left",
            cellPadding: {
                left: cellPadding,
                right: cellPadding,
                vertical: cellPadding,
                horizontal: cellPadding,
            },
        },
        alternateRowStyles: {
            fillColor: 0xffffff,
        },
        margin: [0, 0, 0, xTextStart],
    });
}

function createLabel(doc: jsPDF, t: TFunction, label: ProductLabel, posX: number, posY: number) {
    const textY = posY + 20;
    doc.rect(posX, posY, labelRectWidth, labelRectHeight);
    doc.rect(posX, posY + labelRectHeight, labelRectWidth, labelRectHeight);
    doc.setTextColor("black");
    doc.setFontSize(fontSize);
    doc.text(label.type || t(LocalizationKeys.LabelReportDefaultType), posX, posY - 10);
    doc.setFontSize(labelDescriptionFontSize);
    doc.text(label.description ?? t(LocalizationKeys.LabelReportDefaultType), posX + 5, textY, {
        maxWidth: labelRectWidth,
    });
    if (label.note.length) {
        doc.text(t(LocalizationKeys.LabelReportNote) + label.note ?? "", posX, posY + labelRectHeight * 2 + 25, {
            maxWidth: labelWidth,
        });
    }
    if (label.size) {
        doc.text(t(LocalizationKeys.LabelReportSize) + label.size ?? "", posX + labelRectWidth + 20, textY, {
            maxWidth: labelWidth - labelRectWidth - 20,
        });
    }
}

function createLabels(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    const { productLabels } = settings;

    let labelY = pageYContentStart;
    let labelX = topLeft.x;

    if (!productLabels) return labelY;

    let labelIndex = 0;
    let atLeastOne = false;
    while (labelIndex < productLabels.length) {
        const label = productLabels[labelIndex];
        if (label.type || label.description || label.size || label.note) {
            atLeastOne = true;
            createLabel(doc, translate, label, labelX, labelY);
            labelX += labelWidth;
            if (
                (labelX >= boxWidth ||
                    label.oneBarcode ||
                    (labelIndex + 1 < productLabels.length && productLabels[labelIndex + 1].oneBarcode)) &&
                labelIndex < productLabels.length
            ) {
                labelY += totalLabelHeight;
                labelX = topLeft.x;
            }
            if (labelY + labelHeight >= footerStart.y) {
                doc.addPage([pageSize.width * pixelConversionFactor, pageSize.height * pixelConversionFactor], "l");
                labelY = pageYContentStart;
            }
        }
        labelIndex++;
    }
    labelY += atLeastOne ? totalLabelHeight : 0; // Accounts for the last label row's height

    return labelY;
}

function createLabelsLengthsSection(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    doc.addPage([pageSize.width * pixelConversionFactor, pageSize.height * pixelConversionFactor], "l");

    let textStart = createLabels(doc, settings, translate);

    if (textStart >= footerStart.y) {
        doc.addPage([pageSize.width * pixelConversionFactor, pageSize.height * pixelConversionFactor], "l");
        textStart = pageYContentStart;
    }

    doc.setFontSize(sectionFontSize);
    doc.setTextColor(textColor);
    doc.text(translate(LocalizationKeys.CableTrunkLengths), topLeft.x, textStart);

    const cableTrunkLengthsTable = getElementById("cable-trunk-length-table") as HTMLTableElement;
    const vertical = boxHeight * 3;
    autoTable(doc, {
        html: cableTrunkLengthsTable,
        tableLineColor: 0x000000,
        tableLineWidth: 1,
        startY: textStart + 75,
        styles: {
            fontSize: sectionFontSize,
            fillColor: 0xffffff,
            textColor: 0x000000,
            lineColor: 0x000000,
            lineWidth: 1,
            halign: "left",
            cellPadding: { left: cellPadding, right: cellPadding },
        },
        alternateRowStyles: {
            fillColor: 0xffffff,
        },
        willDrawCell: (data) => {
            const columnIndex = data.column.index;
            if (data.cell.section === "head" && (columnIndex === 0 || columnIndex === 2 || columnIndex === 3)) {
                data.cell.styles.halign = "right";
            }
        },
        columnStyles: {
            0: { halign: "right" },
            1: { halign: "left" },
            2: { halign: "right" },
            3: { halign: "right" },
            4: { cellWidth: boxWidth * 0.5 },
        },
        margin: { left: tableMargin, right: tableMargin, bottom: vertical, top: vertical },
    });
}

function createDropSection(doc: jsPDF, documentDrop: DocumentDrop, translate: TFunction) {
    const { drop } = documentDrop;
    const dropString = translate(LocalizationKeys.TapNumber, { tapNumber: drop.position + 1 });
    let textStart = pageYContentStart;
    // title
    doc.setFontSize(fontSize);
    doc.setTextColor(textColor);
    doc.text(dropString, topLeft.x, textStart);

    textStart = textStart + 30;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor("black");
    doc.text(documentDrop.description!, topLeft.x + 5, textStart);

    // sub
    textStart = textStart + 40;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(textColor);
    doc.text(translate(LocalizationKeys.TrunkDrawing, { trunk: dropString }), topLeft.x, textStart);

    const dropImage = getImage(`distribution-report-drop-${drop.position}`);
    if (dropImage) {
        addDropImage(doc, dropImage, textStart, translate);
    }
}

function addDropImage(doc: jsPDF, dropImage: HTMLImageElement, textStart: number, translate: TFunction) {
    const maxHeight = footerStart.y - textStart - 2 * padding;
    const { imageWidth, imageHeight } = fitImageToPage(dropImage, { width: boxWidth, height: maxHeight });
    const imageX = (pageSize.width - imageWidth) / 2;
    doc.addImage({
        imageData: dropImage,
        format: "PNG",
        x: imageX,
        y: textStart + 0.5 * padding,
        width: imageWidth,
        height: imageHeight,
        compression: "FAST",
    });
    createRepresentativeExample(doc, footerStart.y - 0.5 * padding, translate);
}

function createDropTablePage(doc: jsPDF, documentDrop: DocumentDrop, translate: TFunction, chipsRecord: ChipsRecord) {
    const { drop } = documentDrop;
    const dropString = translate(LocalizationKeys.TapNumber, { tapNumber: drop.position + 1 });

    let textStart = pageYContentStart;
    const tableId = `drop-connector-table-${drop.position}`;
    // title
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(textColor);
    doc.text(translate(LocalizationKeys.TrunkConnectorTable, { trunk: dropString }), topLeft.x, textStart);
    textStart = textStart + 20;
    createDropTable(doc, tableId, textStart, chipsRecord);
}

function createDropTable(doc: jsPDF, tableId: string, textStart: number, chipsRecord: ChipsRecord) {
    const table = getElementById(tableId) as HTMLTableElement;

    autoTable(doc, {
        html: table,
        tableLineColor: 0x000000,
        tableLineWidth: 1,
        startY: textStart,
        styles: {
            fontSize: sectionFontSize,
            fillColor: 0xffffff,
            textColor: 0x000000,
            lineColor: 0x000000,
            lineWidth: 1,
            valign: "middle",
            cellPadding: {
                left: cellPadding,
                right: cellPadding,
                top: cellPadding / 4,
                bottom: cellPadding / 4,
            },
        },
        headStyles: {},
        alternateRowStyles: {
            fillColor: 0xffffff,
        },
        willDrawCell: (data) => {
            const columnIndex = data.column.index;
            if (data.cell.section === "head" && (columnIndex === 0 || columnIndex === 1 || columnIndex === 3)) {
                data.cell.styles.halign = "right";
            }
        },
        didDrawCell: function (data) {
            const columnIndex = data.column.index;
            if (data.cell.section === "body" && columnIndex === 3) {
                const colorIndex = Number(data.cell.text[0]);
                const imageData = chipsRecord[colorIndex];
                if (imageData) {
                    const chipOffsetY = (data.cell.height - chipOriginalHeight) * 0.5;
                    doc.addImage({
                        imageData,
                        format: "PNG",
                        x: data.cell.x + 8,
                        y: data.cell.y + chipOffsetY,
                        width: chipOriginalWidth,
                        height: chipOriginalHeight,
                        compression: "FAST",
                    });
                }
            }
        },
        columnStyles: {
            0: { halign: "right" },
            1: { halign: "right" },
            2: { halign: "left", cellWidth: boxWidth * 0.25 },
            3: { halign: "left", cellWidth: boxWidth * 0.1 },
            4: { halign: "right", cellWidth: boxWidth * 0.1 },
            5: { halign: "left" },
            6: { cellWidth: boxWidth * 0.295 },
        },
        margin: { left: tableMargin, right: tableMargin, bottom: boxHeight * 3, top: textStart },
    });
}

function createLabelTable(
    doc: jsPDF,
    tableId: string,
    textStart: number,
    leftMargin: number,
    rightMargin: number,
    chipsRecord: ChipsRecord
) {
    const table = getElementById(tableId) as HTMLTableElement;
    const tableWidth = boxWidth * 0.48;
    autoTable(doc, {
        html: table,
        tableLineColor: 0x000000,
        tableLineWidth: 1,
        startY: textStart,
        tableWidth: tableWidth,
        styles: {
            fontSize: sectionFontSize,
            fillColor: 0xffffff,
            textColor: 0x000000,
            lineColor: 0x000000,
            lineWidth: 1,
            valign: "middle",
            cellPadding: {
                left: cellPadding,
                right: cellPadding,
                top: cellPadding / 4,
                bottom: cellPadding / 4,
            },
        },
        headStyles: {},
        alternateRowStyles: {
            fillColor: 0xffffff,
        },
        willDrawCell: (data) => {
            const columnIndex = data.column.index;
            const rowIndex = data.row.index;

            if (data.cell.section === "head") {
                data.cell.styles.valign = "bottom";
                if (columnIndex === 0 && rowIndex === 0) {
                    data.row.height = 40;
                    data.cell.height = 40;
                    data.cell.styles.valign = "middle";
                }
            }
        },
        didDrawCell: function (data) {
            const columnIndex = data.column.index;
            if (data.cell.section === "body" && columnIndex === 4) {
                const labelColorIndex = Number(data.cell.text[0]);
                const imageData = chipsRecord[labelColorIndex];
                if (imageData) {
                    const chipOffsetY = (data.cell.height - chipOriginalHeight) * 0.5;
                    doc.addImage({
                        imageData,
                        format: "PNG",
                        x: data.cell.x + 8,
                        y: data.cell.y + chipOffsetY,
                        width: chipOriginalWidth,
                        height: chipOriginalHeight,
                        compression: "FAST",
                    });
                }
            }
        },
        columnStyles: {
            0: { halign: "right", cellWidth: tableWidth * 0.11 },
            1: { halign: "right", cellWidth: tableWidth * 0.11 },
            2: { halign: "left", cellWidth: tableWidth * 0.31 },
            3: { halign: "left", cellWidth: tableWidth * 0.145 },
            4: { halign: "left", cellWidth: tableWidth * 0.325 },
        },
        margin: { left: leftMargin, right: rightMargin, bottom: boxHeight * 3, top: textStart },
    });
}

function createFeederTablePage(doc: jsPDF, translate: TFunction, chipsRecord: ChipsRecord) {
    const tableId = "feeder-connector-table";
    let textStart = pageYContentStart;
    // title
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(textColor);
    doc.text(
        translate(LocalizationKeys.TrunkConnectorTable, { trunk: translate(LocalizationKeys.Feeder) }),
        topLeft.x,
        textStart
    );

    textStart = textStart + 20;
    createDropTable(doc, tableId, textStart, chipsRecord);
}

function createFeederSection(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    let textStart = pageYContentStart;
    // title
    doc.setFontSize(fontSize);
    doc.setTextColor(textColor);
    doc.text(
        translate(LocalizationKeys.TrunkSide, { trunk: translate(LocalizationKeys.Feeder) }),
        topLeft.x,
        textStart
    );

    textStart = textStart + 30;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor("black");
    doc.text(settings.feeder!.description!, topLeft.x + 5, textStart);

    // sub
    textStart = textStart + 40;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(textColor);
    doc.text(
        translate(LocalizationKeys.TrunkDrawing, { trunk: translate(LocalizationKeys.Feeder) }),
        topLeft.x,
        textStart
    );

    const sourceImage = getImage("feeder-report-drop-0");
    if (sourceImage) {
        addDropImage(doc, sourceImage, textStart, translate);
    }
}

function createLabelTablePage(doc: jsPDF, translate: TFunction, chipsRecord: ChipsRecord) {
    const feederTableId = "feeder-label-table";
    const dropTableId = "drop-label-table";
    let textStart = pageYContentStart;
    // title
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(textColor);
    doc.text(translate(LocalizationKeys.ConnectorAndLabelSummary), topLeft.x, textStart);

    textStart = textStart + 20;
    const pageStart = doc.getCurrentPageInfo().pageNumber;

    createLabelTable(doc, feederTableId, textStart, tableMargin, pageSize.width * 0.5 + tableMargin, chipsRecord);
    const feederPageEnd = doc.getCurrentPageInfo().pageNumber;
    doc.setPage(pageStart);

    createLabelTable(doc, dropTableId, textStart, pageSize.width * 0.5, tableMargin, chipsRecord);
    const dropPageEnd = doc.getCurrentPageInfo().pageNumber;
    const pageEnd = feederPageEnd > dropPageEnd ? feederPageEnd : dropPageEnd;
    doc.setPage(pageEnd);
}

function createFooter(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    const { siteLocation, drawn, footerNotes: notes, inServiceDate, approvalDate, revision, units } = settings;
    const colon = translate(LocalizationKeys.Colon);
    // footer
    doc.rect(footerStart.x, footerStart.y, boxWidth, boxHeight);
    const textStartY = footerStart.y + 25;

    let sectionStart = footerStart.x;
    doc.setFontSize(footerFontSize);

    // siteLocation
    const siteLocationStart = sectionStart;
    const siteLocationWidth = boxWidth / 8;
    doc.rect(sectionStart, footerStart.y, siteLocationWidth, boxHeight);
    doc.setTextColor("black");
    doc.text(translate(LocalizationKeys.Location).toLocaleUpperCase() + colon, sectionStart + 5, textStartY);
    sectionStart = sectionStart + siteLocationWidth;

    //drawn
    const drawStart = sectionStart;
    const drawBoxWidth = boxWidth / 9;
    doc.rect(sectionStart, footerStart.y, drawBoxWidth, boxHeight);
    doc.setTextColor("black");
    doc.text(translate(LocalizationKeys.DrawingBy).toLocaleUpperCase() + colon, sectionStart + 5, textStartY);
    sectionStart = sectionStart + drawBoxWidth;

    // approval date
    const approvalDateStart = sectionStart;
    const formattedDate = new Date(approvalDate!.replace(/-/g, "/"));
    const month = String(formattedDate.getMonth() + 1).padStart(2, "0");
    const day = String(formattedDate.getDate()).padStart(2, "0");
    const year = formattedDate.getFullYear();
    const dateText = `${month}/${day}/${year}`;
    doc.rect(sectionStart, footerStart.y, drawBoxWidth, boxHeight);
    doc.text(translate(LocalizationKeys.DateApproval).toLocaleUpperCase() + colon, sectionStart + 5, textStartY);
    sectionStart = sectionStart + drawBoxWidth;

    // in service date
    const inServiceStart = sectionStart;
    const formattedInServiceDate = new Date(inServiceDate!.replace(/-/g, "/"));
    const month2 = String(formattedInServiceDate.getMonth() + 1).padStart(2, "0");
    const day2 = String(formattedInServiceDate.getDate()).padStart(2, "0");
    const year2 = formattedInServiceDate.getFullYear();
    const inServiceDateText = `${month2}/${day2}/${year2}`;
    doc.rect(sectionStart, footerStart.y, drawBoxWidth, boxHeight);
    doc.text(translate(LocalizationKeys.DateInService).toLocaleUpperCase() + colon, sectionStart + 5, textStartY);
    sectionStart = sectionStart + drawBoxWidth;

    // revision
    const revisionWidth = boxWidth / 9;
    const revisionStart = sectionStart;
    doc.rect(sectionStart, footerStart.y, revisionWidth, boxHeight);
    doc.text(translate(LocalizationKeys.Revision).toLocaleUpperCase() + colon, sectionStart + 5, textStartY);
    sectionStart = sectionStart + revisionWidth;

    //units
    const unitsWidth = boxWidth / 9;
    const unitsStart = sectionStart;
    doc.rect(sectionStart, footerStart.y, unitsWidth, boxHeight);
    doc.text(translate(LocalizationKeys.Units).toLocaleUpperCase() + colon, sectionStart + 5, textStartY);
    sectionStart = sectionStart + unitsWidth;

    //notes
    const notesWidth = boxWidth - 4 * drawBoxWidth - unitsWidth - siteLocationWidth; // calculate total width taken by footer boxes prior to notes section
    const notesStart = sectionStart;
    doc.rect(sectionStart, footerStart.y, notesWidth, boxHeight);
    doc.text(translate(LocalizationKeys.Notes).toLocaleUpperCase() + colon, sectionStart + 5, textStartY);

    // sections content
    doc.setFontSize(footerContentFontSize);

    doc.text(siteLocation ?? "", siteLocationStart + 5, textStartY + 20);
    doc.text(drawn ?? "", drawStart + 5, textStartY + 20);
    doc.text(dateText, approvalDateStart + 5, textStartY + 20);
    doc.text(inServiceDateText, inServiceStart + 5, textStartY + 20);
    doc.text(`${revision}`, revisionStart + 5, textStartY + 20);
    doc.text(units ?? "", unitsStart + 5, textStartY + 20);
    doc.text(notes ?? "", notesStart + 5, textStartY + 20);
}

function createHeader(
    doc: jsPDF,
    pageNumber: number,
    pageCount: number,
    settings: BuildDocument,
    translate: TFunction
) {
    doc.rect(topLeft.x, topLeft.y, boxWidth, boxHeight);
    const textStart = topLeft.y + 45;

    // Company
    doc.rect(topLeft.x, topLeft.y, boxCompanyWidth, boxHeight);
    const logo = getElementById("corning-secondary-logo") as HTMLImageElement;
    if (logo) {
        const width = boxCompanyWidth - padding;
        const height = (width / logo.width) * logo.height;
        const y = topLeft.y + (boxHeight - height) / 2;
        const x = topLeft.x + padding / 2;
        let canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        if (ctx) {
            ctx.drawImage(logo, 0, 0);
            doc.addImage(canvas.toDataURL(), "PNG", x, y, width, height, "", "FAST");
        }
        canvas.remove();
    }

    // PageCount
    const pageCountText = `${pageNumber}/${pageCount}`;
    const pageCountTextWidth = doc.getTextWidth(pageCountText);
    let pageCountStart = boxPageStart + (boxPageWidth - pageCountTextWidth - 30) / 2;
    doc.rect(boxPageStart, topLeft.y, boxPageWidth, boxHeight);
    doc.setFontSize(sectionFontSize);
    doc.text(pageCountText, pageCountStart, textStart);

    // Page Title
    doc.setFontSize(fontSize);
    const pageTitle =
        translate(LocalizationKeys.ReportPageTitle) + (settings.draft ? translate(LocalizationKeys.ReportDraft) : "");
    const pageTitleWdith = doc.getTextWidth(pageTitle);
    const pageTitleStart = boxTitleStart + (boxTitleWidth - pageTitleWdith) / 2;
    doc.text(pageTitle, pageTitleStart, textStart);
}

function createBuildPlanPages(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    doc.addPage([pageSize.width * pixelConversionFactor, pageSize.height * pixelConversionFactor], "l");
    createBuildPlanSummaryPage(doc, settings, translate);
    createBuildPlanTables(doc);
    createBundleLengthReportRepresentation(doc, translate);
}

function createBuildPlanSummaryPage(doc: jsPDF, settings: BuildDocument, translate: TFunction) {
    let textStart = pageYContentStart;
    doc.setFontSize(fontSize);
    doc.setTextColor(textColor);
    doc.text(translate(LocalizationKeys.BuildPlan), topLeft.x, textStart);

    textStart += 60;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(textColor);
    doc.text(translate(LocalizationKeys.Summary), topLeft.x + 15, textStart);

    const { bundleCount, tapsConnectorTypes, tapsCount, overallLength, feederConnectorType, fiberCount } =
        settings.buildPlan!.summary!;

    const fieldsText = [bundleCount, fiberCount, feederConnectorType, tapsConnectorTypes, tapsCount, overallLength]
        .map((f) => "\u2022    " + f! + "\n")
        .reduce((a, b) => a + b, "");

    textStart += 30;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor("black");
    doc.text(fieldsText, topLeft.x + 45, textStart);

    textStart += 170;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(textColor);
    doc.text(translate(LocalizationKeys.Table), topLeft.x + 15, textStart);
}

function createBuildPlanTables(doc: jsPDF) {
    const tableWidth = boxWidth * 0.985;
    createBuildPlanTable(doc, "build-plan-table1", pageSize.height / 2 - 140, tableWidth, {
        top: 0,
        bottom: 0,
        right: tableMargin,
        left: tableMargin,
    });

    if (hasElement("build-plan-table2")) {
        doc.addPage([pageSize.width * pixelConversionFactor, pageSize.height * pixelConversionFactor], "l");

        createBuildPlanTable(doc, "build-plan-table2", pageYContentStart, tableWidth, {
            top: pageYContentStart,
            bottom: 2.5 * boxHeight,
            right: tableMargin,
            left: tableMargin,
        });
    }
}

function createBuildPlanTable(
    doc: jsPDF,
    tableSelector: string,
    textStart: number,
    tableWidth: number,
    margin: { top: number; bottom: number; left: number; right: number }
) {
    const table = getElementById(tableSelector) as HTMLTableElement;
    const sectionFillColor: [number, number, number] = [224, 224, 224];
    autoTable(doc, {
        html: table,
        tableLineColor: 0x000000,
        tableLineWidth: 1,
        startY: textStart,
        tableWidth: tableWidth,
        styles: {
            fontSize: sectionFontSize,
            fillColor: 0xffffff,
            textColor: 0x000000,
            lineColor: 0x000000,
            lineWidth: 1,
            valign: "middle",
            cellPadding: { left: cellPadding, right: cellPadding, top: cellPadding / 4, bottom: cellPadding / 4 },
        },
        headStyles: {
            halign: "left",
        },
        alternateRowStyles: {
            fillColor: 0xffffff,
        },
        willDrawCell: (data) => {
            if ((data.cell.raw as HTMLTableCellElement).className.includes("subsection")) {
                data.cell.styles.fillColor = sectionFillColor;
            }
        },
        didParseCell: (data) => {
            if ((data.cell.raw as HTMLTableCellElement).className.includes("subsection")) {
                data.cell.styles.fillColor = sectionFillColor;
            }
        },
        columnStyles: {
            0: { halign: "left", cellWidth: tableWidth * 0.34 },
            1: { halign: "left", cellWidth: tableWidth * 0.33 },
            2: { halign: "left", cellWidth: tableWidth * 0.33 },
        },
        margin,
    });
}

function createFiberMapPages(doc: jsPDF, settings: BuildDocument, chipsData: ChipsData) {
    doc.addPage([pageSize.width * pixelConversionFactor, pageSize.height * pixelConversionFactor], "l");
    createFiberMapTable(doc, settings, chipsData);
}

function createFiberMapTable(doc: jsPDF, settings: BuildDocument, chipsData: ChipsData) {
    doc.setFontSize(sectionFontSize);
    doc.setTextColor(textColor);
    doc.text(i18next.t(LocalizationKeys.FiberMap), topLeft.x, pageYContentStart);

    const marginTop = topLeft.y + boxHeight + 20;
    const table = getElementById("fiber-map-table") as HTMLTableElement;
    autoTable(doc, {
        html: table,
        tableLineColor: 0x000000,
        tableLineWidth: 0,
        startY: pageYContentStart + 20,
        showHead: "everyPage",
        showFoot: "never",
        pageBreak: "auto",
        styles: {
            fontSize: sectionFontSize,
            fillColor: 0xffffff,
            textColor: 0x000000,
            lineColor: 0x000000,
            lineWidth: 1,
            valign: "middle",
            cellPadding: {
                left: cellPadding,
                right: cellPadding,
                top: cellPadding / 4,
                bottom: cellPadding / 4,
            },
        },
        willDrawCell: (data) => {
            const columnIndex = data.column.index;
            if (data.cell.section === "head" && (columnIndex === 1 || columnIndex === 5 || columnIndex === 7)) {
                data.cell.styles.halign = "right";
            }
        },
        didDrawCell: function (data) {
            const columnIndex = data.column.index;
            if (data.cell.section === "body" && (columnIndex === 4 || columnIndex === 10)) {
                const type = settings.fiberMap?.type ?? "";
                let colorIndex = Number(data.cell.text[0]) - 1;
                if (columnIndex === 4) {
                    colorIndex = isMMC16(type) ? colorIndex : colorIndex % 12;
                } else if (colorIndex === 10) {
                    colorIndex = isMMC16(type) ? colorIndex : colorIndex % 12;
                }

                const imageData = chipsData.fibers[colorIndex];
                if (imageData) {
                    const chipOffsetY = (data.cell.height - chipOriginalHeight) * 0.5;
                    doc.addImage({
                        imageData,
                        format: "PNG",
                        x: data.cell.x + 8,
                        y: data.cell.y + chipOffsetY,
                        width: chipOriginalWidth,
                        height: chipOriginalHeight,
                        compression: "FAST",
                    });
                }
            }
        },
        didParseCell: (data) => {
            const columnIndex = data.column.index;
            if (columnIndex === 5) {
                data.cell.styles.fillColor = [224, 224, 224];
            }
        },
        columnStyles: {
            0: { halign: "left", cellWidth: boxWidth * 0.06 },
            1: { halign: "right", cellWidth: boxWidth * 0.06 },
            2: { halign: "left" },
            3: { halign: "left" },
            4: { halign: "right" },
            5: { halign: "right", cellWidth: boxWidth * 0.06 },
            6: { halign: "left", cellWidth: boxWidth * 0.06 },
            7: { halign: "right", cellWidth: boxWidth * 0.06 },
            8: { halign: "left" },
            9: { halign: "left" },
            10: { halign: "right" },
        },
        margin: { left: tableMargin, right: tableMargin, bottom: boxHeight * 2, top: marginTop },
    });
}

function createRepresentativeExample(doc: jsPDF, y: number, translate: TFunction) {
    const { width } = pageSize;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor("black");
    doc.text(translate(LocalizationKeys.ReportRepresentativeExample), width - padding, y, { align: "right" });
}

function createBundleLengthReportRepresentation(doc: jsPDF, translate: TFunction) {
    const { width } = pageSize;
    doc.setFontSize(sectionFontSize);
    doc.setTextColor("black");
    doc.text(translate(LocalizationKeys.BundleLengthReportRepresentation), width - padding, footerStart.y - 25, {
        align: "right",
    });
}

async function generateChipsData(): Promise<ChipsData> {
    const elements = Object.values(getElements()).filter((c) => c.id.includes("chip")) as SVGSVGElement[];
    const connectorSVGs = elements.filter((c) => c.id.includes("connector"));
    const fiberSVGs = elements.filter((c) => c.id.includes("fiber"));

    const canvas = document.createElement("canvas");
    canvas.width = chipWidth;
    canvas.height = chipHeight;
    const ctx = canvas.getContext("2d")!;

    const connectors = await generateChipsRecord(ctx, connectorSVGs);
    const fibers = await generateChipsRecord(ctx, fiberSVGs);

    return {
        connectors,
        fibers
    }
}

async function generateChipsRecord(ctx: CanvasRenderingContext2D, svgs: SVGSVGElement[]): Promise<ChipsRecord> {
    const record: ChipsRecord = {};
    for (let i = 0; i < svgs.length; i++) {
        const svg = svgs[i];
        const image = await Canvg.from(ctx, svg.innerHTML);
        image.start();
        record[i] = ctx.canvas.toDataURL("image/png");
    }

    return record;
}

import { TableRow, TableCell, InputAdornment } from "@mui/material";
import { Checkbox, TextField } from "@corning-ctcm/silica-react";
import { useConnectorRow } from "./connector-row.hooks";
import { ColorChip } from "../../../../ui/color-chip";
import { IConnectorRowProps } from "../../../../../models/overlay/reports/connector-row";
import { styled } from "@mui/system";
import { Edit } from "@mui/icons-material";

const StyledTextField = styled(TextField)(({ theme }) => ({
    Input: {
        color: theme.palette.primary.main,
        padding: "17.5px 0 17.5px 3px",
    },
})) as typeof TextField;

export const ConnectorRow = (props: IConnectorRowProps) => {
    const { data, onFocus, onBlur, checkboxProps, editableLabelProps } = useConnectorRow(props);

    return (
        <TableRow id={`connector-${data.id}`} key={data.id} onFocus={onFocus} onBlur={onBlur}>
            <TableCell align="center" className="row-checkbox">
                <Checkbox id={`connector-${data.id}-checkbox`} palette="primary" {...checkboxProps} />
            </TableCell>
            <TableCell align="right" component="th" scope="row">
                {data.id}
            </TableCell>
            <TableCell align="right">{data.group}</TableCell>
            <TableCell align="left">
                <ColorChip color={data.color} scale={0.2} />
            </TableCell>
            <TableCell align="left" className="label-cell">
                <StyledTextField
                    id={`connector-${data.id}-label-edit`}
                    variant="standard"
                    className="label-field"
                    palette="primary"
                    value={editableLabelProps.value}
                    disabled={editableLabelProps.disabled}
                    onChange={editableLabelProps.onChange}
                    onBlur={editableLabelProps.onBlur}
                    onFocus={editableLabelProps.onFocus}
                    inputProps={{ maxLength: 20 }} // DOM element input props
                    InputProps={{
                        // MUI component props
                        endAdornment: editableLabelProps.disabled ? null : (
                            <InputAdornment position="end">
                                <Edit />
                            </InputAdornment>
                        ),
                        disableUnderline: true,
                    }}
                />
            </TableCell>
            <TableCell align="left">
                <ColorChip color={data.labelColor} scale={0.2} />
            </TableCell>
            <TableCell align="right">{data.totalLength}</TableCell>
        </TableRow>
    );
};

import { IUnitsOfMeasureContainerState } from "./units-of-measure-container";
import { PayloadAction } from "@reduxjs/toolkit";
import { PrimaryUnit, Unit } from "../../../../models/overlay/header/units-of-measure";

export const setUnitsOfMeasureDisplayReducer = (
    state: IUnitsOfMeasureContainerState,
    action: PayloadAction<boolean>
) => {
    state.display = action.payload;
};

export const setPrimaryUnitReducer = (state: IUnitsOfMeasureContainerState, action: PayloadAction<PrimaryUnit>) => {
    state.primaryUnit = action.payload;
};

export const setUnitReducer = (state: IUnitsOfMeasureContainerState, action: PayloadAction<Unit>) => {
    state.unit = action.payload;
};

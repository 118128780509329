import * as PIXI from "pixi.js";
import { useCallback, useEffect, useState } from "react";
import { useText } from "../../text/text.hooks";
import { IBezierLineMarkerProps } from "../../../../../models/pixi/decorators/dimension-lines/line-marker";
import { DEFAULT_TEXT_RESOLUTION } from "../../../../../models/pixi/pixi";
import { _ReactPixi } from "@pixi/react";

export const useBezierMarker = ({
    path: { start, control, end, text: textPath },
    thickness,
    color,
}: IBezierLineMarkerProps) => {
    const { text, textStyle, textSize } = useText({ text: textPath?.primary ?? "", scale: textPath?.scale });
    const [textPosition, setTextPosition] = useState({ x: start.x, y: end.y });

    useEffect(() => {
        const x = control.x - textSize.width / 2;
        const y = control.y;
        setTextPosition({ x, y });
    }, [control.x, control.y, textSize.width]);

    const drawBezierMarker = useCallback(
        (g: PIXI.Graphics) => {
            g.clear();

            g.lineStyle(thickness, color);
            g.moveTo(start.x, start.y);
            g.bezierCurveTo(start.x, start.y, control.x, control.y, end.x, end.y);
        },
        [color, control.x, control.y, end.x, end.y, start.x, start.y, thickness]
    );

    const textProps: _ReactPixi.IText = {
        text,
        style: textStyle,
        resolution: DEFAULT_TEXT_RESOLUTION,
        ...textPosition,
    };

    return {
        textProps,
        drawBezierMarker,
    };
};

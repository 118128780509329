import * as PIXI from "pixi.js";
import { viewportContextSelector } from "../../../../../store/pixi/viewport/viewport.selectors";
import { wizardDisplaySelector } from "../../../../../store/overlay/wizard/wizard.selectors";
import {
    DEFAULT_TEXT_RESOLUTION,
    IPoint,
    IPointEmpty,
    ISize,
    ISizeEmpty,
    getInteractiveEventMode,
} from "../../../../../models/pixi/pixi";
import { _ReactPixi } from "@pixi/react";
import { useContext, useState, useEffect, useCallback } from "react";
import { getUnitsName } from "../../../../../models/overlay/header/units-of-measure";
import { unitsOfMeasureContainerUnitSelector } from "../../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import {
    currentBuildFinalizedSelector,
    showTolerancesSelector,
} from "../../../../../store/workspace/build.selectors";
import { useText } from "../../text/text.hooks";
import { drawLine, drawArrow } from "../dimension-lines.hooks";
import { DimensionLineContext, DIMENSION_LINE_TEXT_OFFSET, DIMENSION_LINE_START_OFFSET } from "../../../../../models/pixi/decorators/dimension-lines/dimension-lines";
import { ILineMarkerProps, Orientations, Sides } from "../../../../../models/pixi/decorators/dimension-lines/line-marker";
import { useDispatch, useSelector } from "react-redux";
import { outlineFilter } from "../../bounds/select-filter";
import { setViewportContext } from "../../../../../store/pixi/viewport/viewport.reducers";
import { TextInputProps } from "../../../../../models/pixi/text-input";
import { getContainerName } from "../../../../../models/pixi/build/build";
import { showSettingsSelector } from "../../../../../store/overlay/overlay.selectors";
import { isLockedSelector } from "../../../../../store/overlay/header/status-icon/status-icon.selectors";

const TOLERANCE_SCALE = 0.5;
const minScale: IPoint = { x: 0.5, y: 0.5 };
const maxScale: IPoint = { x: 1.25, y: 1.25 };

export const useLineMarker = (props: ILineMarkerProps) => {
    const displayWizard = useSelector(wizardDisplaySelector);
    const toleranceDisplay = useSelector(showTolerancesSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const { isDragging, scale } = useSelector(viewportContextSelector);
    const { thickness, color } = useContext(DimensionLineContext);
    const { path, orientation, direction, textScale, hideUnits, maskLengths } = props;
    const { text: textPath, dashed } = path;
    const { lineSide } = useLineSide(orientation!);

    const showTolerances = toleranceDisplay && !maskLengths;
    const isValidScale = scale.x > minScale.x && scale.x <= maxScale.x && scale.y > minScale.y && scale.y <= maxScale.y;
    const interactive = !isDragging && !!textPath?.inputProps && !displayWizard && isValidScale;
    const { eventMode, filters, actions } = useSelection(interactive, !!maskLengths);

    const dispatch = useDispatch();

    const { text, textStyle, textSize } = useText({ text: textPath?.primary ?? "", scale: textPath?.scale });

    const {
        text: min,
        textStyle: minStyle,
        textSize: minSize,
    } = useText({
        text: textPath?.tolerance?.min ?? "",
        fill: textPath?.tolerance?.minColor,
        scale: TOLERANCE_SCALE,
    });

    const {
        text: max,
        textStyle: maxStyle,
        textSize: maxSize,
    } = useText({
        text: textPath?.tolerance?.max ?? "",
        fill: textPath?.tolerance?.maxColor,
        scale: TOLERANCE_SCALE,
    });
    const {
        text: primary,
        textStyle: primaryStyle,
        textSize: primarySize,
    } = useText({ text: getUnitsName(unit, false, true), scale: textPath?.scale });
    const {
        text: secondary,
        textStyle: secondaryStyle,
        textSize: secondarySize,
    } = useText({ text: textPath?.secondary ?? "", scale: textPath?.scale });

    const [startPosition, setStartPosition] = useState<IPoint>(IPointEmpty);
    const [startPath, setStartPath] = useState<IPoint[]>([IPointEmpty, IPointEmpty]);
    const [endPath, setEndPath] = useState<IPoint[]>([IPointEmpty, IPointEmpty]);
    const [textPosition, setTextPosition] = useState(IPointEmpty);
    const [minPosition, setMinPosition] = useState(IPointEmpty);
    const [maxPosition, setMaxPosition] = useState(IPointEmpty);
    const [primaryPosition, setPrimaryPosition] = useState(IPointEmpty);
    const [secondaryPosition, setSecondaryPosition] = useState(IPointEmpty);

    useEffect(() => {
        const { start: initialStart, end: initialEnd } = path;
        const startPath: IPoint[] = [initialStart, initialEnd];
        const endPath: IPoint[] = [];

        if (textPath) {
            const toleranceSize: ISize = showTolerances
                ? { width: Math.max(minSize.width, maxSize.width), height: minSize.height + maxSize.height }
                : ISizeEmpty;

            const totalSize: ISize = {
                width: maskLengths 
                    ? textSize.width + toleranceSize.width 
                    : Math.max(textSize.width + toleranceSize.width + primarySize.width, secondarySize.width),
                height: Math.max(textSize.height, toleranceSize.height, primarySize.height) + secondarySize.height,
            };

            const lineSize: ISize = {
                width: initialEnd.x - initialStart.x,
                height: Math.abs(initialEnd.y - initialStart.y),
            };

            let startX = initialStart.x;
            let startY = initialStart.y;
            if (orientation === "horizontal") {
                if (textPath.position === "start") {
                    startX -= totalSize.width + DIMENSION_LINE_TEXT_OFFSET;
                } else if (textPath.position === "middle") {
                    startX += (lineSize.width - totalSize.width) * 0.5;

                    startPath[1] = { x: startX - DIMENSION_LINE_TEXT_OFFSET, y: startY };
                    endPath.push({ ...initialEnd, x: startX + totalSize.width + DIMENSION_LINE_TEXT_OFFSET });
                    endPath.push(initialEnd);
                } else {
                    startX += lineSize.width + totalSize.width + DIMENSION_LINE_TEXT_OFFSET;
                }
            } else if (orientation === "vertical") {
                if (textPath.position === "start") {
                    startY = initialEnd.y - DIMENSION_LINE_TEXT_OFFSET;
                } else if (textPath.position === "middle" || textPath.position === "middle-out") {
                    startY -= lineSize.height * 0.5;
                    startX += DIMENSION_LINE_START_OFFSET * 0.5;
                }
            }

            const textStart: IPoint = IPointEmpty;
            let textPositionX = textStart.x + (totalSize.width - (textSize.width + toleranceSize.width + primarySize.width)) * 0.5;
            let textPositionOffsetY = textSize.height * 0.75;
            if (maskLengths) {
                textPositionX = textStart.x + (totalSize.width - (textSize.width + toleranceSize.width)) * 0.5;
                textPositionOffsetY = textSize.height * 0.5;
            }
            const textPosition: IPoint = {
                x: textPositionX,
                y: textStart.y - textPositionOffsetY,
            };

            const minOffsetX = minSize.width > maxSize.width ? 0 : maxSize.width - minSize.width;
            const minOffsetY = minSize.height * 0.35;
            const minPosition: IPoint = {
                x: textPosition.x + textSize.width + minOffsetX,
                y: textPosition.y + (textSize.height - minSize.height) * 0.5 + minOffsetY,
            };

            const maxOffsetX = maxSize.width > minSize.width ? 0 : minSize.width - maxSize.width;
            const maxOffsetY = maxSize.height * 0.35;
            const maxPosition: IPoint = {
                x: textPosition.x + textSize.width + maxOffsetX,
                y: textPosition.y + (textSize.height - maxSize.height) * 0.5 - maxOffsetY,
            };

            const primaryPosition: IPoint = {
                x: textPosition.x + textSize.width + toleranceSize.width,
                y: textPosition.y,
            };

            const secondaryPosition: IPoint = {
                x: textStart.x + (totalSize.width - secondarySize.width) * 0.5,
                y: textStart.y,
            };

            setStartPosition({ x: startX, y: startY });
            setTextPosition(textPosition);
            setMinPosition(minPosition);
            setMaxPosition(maxPosition);
            setPrimaryPosition(primaryPosition);
            setSecondaryPosition(secondaryPosition);
        }

        setStartPath(startPath);
        setEndPath(endPath);
    }, [
        path,
        direction,
        textPath,
        orientation,
        textSize,
        maxSize,
        minSize,
        primarySize,
        secondarySize,
        showTolerances,
        textScale,
        maskLengths,
        text
    ]);

    const drawStartLine = useCallback(
        (g: PIXI.Graphics) => {
            g.clear();
            drawLine(g, startPath, thickness, color, dashed);

            const first: IPoint = orientation === "horizontal" ? startPath[0] : startPath[1];
            const second: IPoint = orientation === "horizontal" ? startPath[1] : startPath[0];
            if (endPath.length === 0 && direction === "bidirectional") {
                drawArrow(g, first, lineSide.start, thickness, color);
                drawArrow(g, second, lineSide.end, thickness, color);
            } else if (direction === "start" || direction === "bidirectional") {
                drawArrow(g, first, lineSide.start, thickness, color);
            } else if (direction === "end") {
                drawArrow(g, second, lineSide.end, thickness, color);
            }
        },
        [startPath, endPath, lineSide, orientation, direction, color, thickness, dashed]
    );

    const startLine = {
        display: startPath.length === 2,
        draw: drawStartLine,
    };

    const drawEndLine = useCallback(
        (g: PIXI.Graphics) => {
            g.clear();
            drawLine(g, endPath, thickness, color);
            if (direction !== "none") {
                drawArrow(g, orientation === "horizontal" ? endPath[1] : endPath[0], lineSide.end, thickness, color);
            }
        },
        [endPath, thickness, color, direction, orientation, lineSide.end]
    );

    const endLine = {
        display: endPath.length === 2,
        draw: drawEndLine,
    };

    const textProps: _ReactPixi.IText = {
        text,
        style: textStyle,
        ...textPosition,
        ...textSize,
        resolution: DEFAULT_TEXT_RESOLUTION,
        roundPixels: true,
    };

    const minProps: _ReactPixi.IText = {
        text: min,
        ...minPosition,
        ...minSize,
        style: minStyle,
        resolution: DEFAULT_TEXT_RESOLUTION,
        roundPixels: true,
    };

    const maxProps: _ReactPixi.IText = {
        text: max,
        ...maxPosition,
        ...maxSize,
        style: maxStyle,
        resolution: DEFAULT_TEXT_RESOLUTION,
        roundPixels: true,
    };

    const primaryProps: _ReactPixi.IText = hideUnits
        ? { text: "" }
        : {
              text: primary,
              ...primaryPosition,
              ...primarySize,
              style: primaryStyle,
              resolution: DEFAULT_TEXT_RESOLUTION,
              roundPixels: true,
          };

    const secondaryProps: _ReactPixi.IText = hideUnits
        ? { text: "" }
        : {
              text: secondary,
              ...secondaryPosition,
              ...secondarySize,
              style: secondaryStyle,
              resolution: DEFAULT_TEXT_RESOLUTION,
              roundPixels: true,
          };

    const onCancel = useCallback(() => {
        dispatch(setViewportContext("active"));
        actions.onCancel();
    }, [actions, dispatch]);

    const onConfirm = useCallback(
        async (value: string) => {
            if (!textPath?.name || !textPath?.inputProps?.onConfirm) return;
            await textPath.inputProps.onConfirm(value);
        },
        [textPath?.inputProps, textPath?.name]
    );

    const container: TextInputProps = {
        name: textPath?.name ? getContainerName(textPath.name, filters.enabled) : null,
        eventType: "mousedown",
        eventMode,
        value: textPath?.inputProps?.value ?? "",
        ...startPosition,
        onConfirm,
        onCancel,
        onClick: actions.onClick,
        onMouseOver: actions.onHover,
        onMouseOut: actions.onCancel,
    };

    return {
        display: !!text,
        startLine,
        endLine,
        showTolerances,
        filters,
        container,
        textProps,
        minProps,
        maxProps,
        primaryProps,
        secondaryProps,
    };
};

export const useLineSide = (orientation: string) => {
    const [lineSide, setLineSide] = useState(getLineSide(orientation));

    useEffect(() => {
        if (orientation === Orientations.Horizontal) {
            setLineSide({ start: Sides.Left, end: Sides.Right });
        } else {
            setLineSide({ start: Sides.Top, end: Sides.Bottom });
        }
    }, [orientation]);

    return { lineSide };
};

const getLineSide = (orientation: string) => {
    if (orientation === Orientations.Horizontal) {
        return { start: Sides.Left, end: Sides.Right };
    } else {
        return { start: Sides.Top, end: Sides.Bottom };
    }
};

const useSelection = (interactive: boolean, maskLengths: boolean) => {
    const { context: viewportContext } = useSelector(viewportContextSelector);
    const finalized = useSelector(currentBuildFinalizedSelector);
    const settingsOpen = useSelector(showSettingsSelector);
    const locked = useSelector(isLockedSelector);
    const dispatch = useDispatch();

    const [filters, setFilters] = useState({ ...outlineFilter, enabled: false });

    const allowInteractions = interactive && viewportContext === "active" && !finalized && !maskLengths && !settingsOpen && !locked;
    const onHover = useCallback(() => {
        if (allowInteractions) {
            document.body.style.cursor = "pointer";
            setFilters((prev) => ({ ...prev, enabled: true }));
        }
    }, [allowInteractions]);

    const onCancel = useCallback(() => {
        if (allowInteractions) {
            document.body.style.cursor = "default";
            setFilters((prev) => ({ ...prev, enabled: false }));
        }
    }, [allowInteractions]);

    const onClick = useCallback(() => {
        if (allowInteractions) {
            dispatch(setViewportContext("editing"));
        }
    }, [allowInteractions, dispatch]);

    useEffect(() => {
        if (!allowInteractions) {
            document.body.style.cursor = "default";
            setFilters((prev) => ({ ...prev, enabled: false }));
        }
    }, [allowInteractions]);

    return {
        eventMode: getInteractiveEventMode(allowInteractions),
        filters,
        actions: {
            onHover,
            onCancel,
            onClick,
        },
    };
};

import { CurvedLength } from "../../decorators/dimension-lines/curved-length";
import { Length } from "../../decorators/dimension-lines/length";
import { useOverallLength } from "./overall-length.hooks";
import { OverallLengthProps } from "../../../../models/pixi/build/overall-length";

export const OverallLength: React.FC<OverallLengthProps> = (props: OverallLengthProps) => {
    const { overallLengthFurcationProps, overallLengthConnectorProps, overallLengthType } = useOverallLength(props);

    return (
        <>
            {overallLengthType === "furcation" && <Length {...overallLengthFurcationProps} />}
            {overallLengthType === "connector" && <CurvedLength {...overallLengthConnectorProps} />}
        </>
    );
};

import { ICollapsibleDialogProps } from "../../../../models/ui/dialog/collapsible-dialog";
import { useReducer, useCallback, useEffect } from "react";
import { DialogReducer, showDialog } from "../../../../store/workspace/dialog/dialog.reducer";
import { initialDialogState, IDialogContext } from "../../../../store/workspace/dialog/dialog";

export const useCollapsibleDialog = (props: ICollapsibleDialogProps) => {
    const { id, display, className, headerProps, children } = props;
    const { onClose: dialogHeaderOnClose } = headerProps;
    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);
    const context: IDialogContext = { state, dispatch };
    const { open, collapsed } = state.props;
    const dialogClassName = collapsed ? className + "-collapsed" : className;

    const onClose = useCallback(() => {
        dispatch(showDialog(false));
        if (dialogHeaderOnClose) {
            dialogHeaderOnClose();
        }
    }, [dispatch, dialogHeaderOnClose]);

    useEffect(() => {
        if (display) {
            dispatch(showDialog(true));
        } else {
            if (open) {
                onClose();
            }
        }
    }, [display, dispatch, open, onClose]);

    return { context, id, dialogClassName, open, onClose, headerProps, collapsed, children };
};

import CorningLogo from "../../../../resources/imgs/Corning_Logo_Grey.png";
import { ReactComponent as MenuIcon } from "../../../../resources/svgs/overlay/header/icon_menu.svg";
import { useProjectButton } from "./project-button.hooks";
import { LocalizationKeys } from "../../../../locales/keys";
import { Lock } from "@mui/icons-material";
import { IconButton } from "@corning-ctcm/silica-react";
import i18next from 'i18next';

export const ProjectButton = () => {
    const { name, partNumber, lock, menuProps } = useProjectButton();
    return (
        <div id="project-button" className="button-container background-blur current-project toggle-pointer-events">
            <IconButton {...menuProps} icon={<MenuIcon />} />
            <img id="corning-logo" src={CorningLogo} alt={"a corning logo"} width={110} height={16}></img>
            <label id="app-title" className="rab-title">
                {i18next.t(LocalizationKeys.RABTitle)}
            </label>
            <>
                <div className="vertical-divider" />
                <label id="cable-name" className="title">
                    {name}
                </label>
                {partNumber.length ? (
                    <>
                        <label id="catalog-code" className="sub-title">
                            {partNumber}
                        </label>
                        {lock.display && <IconButton {...lock.icon} icon={<Lock />} />}
                    </>
                ) : null}
            </>
        </div>
    );
};

import { showTimeoutSelector } from "../../../store/overlay/overlay.selectors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../locales/keys";
import { useCallback } from "react";
import { IGenericDialogProps } from "../../../models/ui/dialog/generic-dialog";
import { usePlatformService } from "@corning-ctcm/platform-client";
import { useLogout } from "../../../store/authentication/authentication.reducers";

export const useTimeoutDialog = () => {
    const { t } = useTranslation();
    const display = useSelector(showTimeoutSelector);
    const platformService = usePlatformService();
    const logoutCallback = useLogout();

    const onLoginAgainClick = useCallback(() => {
        platformService.logout(logoutCallback);
    }, [platformService, logoutCallback]);

    const dialogProps: IGenericDialogProps = {
        id: "timeout",
        display,
        title: t(LocalizationKeys.TimeoutTitle),
        message: t(LocalizationKeys.TimeoutMessage),
        closable: false,
        onConfirm: onLoginAgainClick,
        confirmText: t(LocalizationKeys.LoginAgain),
    };

    return { dialogProps };
};

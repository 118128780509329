import React from "react";
import { useCollapseButton } from "./collapse-button.hooks";
import { IconButton } from "@corning-ctcm/silica-react";
import IconExpandDisabled from "../../../../../../resources/svgs/overlay/footer/icon_expand_disabled.svg";
import IconExpand from "../../../../../../resources/svgs/overlay/footer/icon_expand.svg";

export const CollapseButton: React.FC = () => {
    const { buttonProps, open } = useCollapseButton();
    return <IconButton {...buttonProps} icon={<img src={open ? IconExpandDisabled : IconExpand} alt="" />} />;
};

import { ColorDialog } from "../../../../ui/dialog/color-dialog/color-dialog";
import { GenericDialog } from "../../../../ui/dialog/generic-dialog";
import { CollapseButton } from "../buttons/collapse-buttons/collapse-button";
import { TieCablesButton } from "../buttons/tie-cables-button/tie-cables-button";
import { useActionToolbar } from "./action-toolbar.hooks";
import { IconButton } from "@corning-ctcm/silica-react";
import { Palette } from "@mui/icons-material";

import "../../../../../css/overlay/footer/footer.scss";
import "../../../../../css/overlay/footer/action-toolbar.scss";

export const ActionToolbar = () => {
    const { showToolbar, buttonProps, colorDialogProps, confirmResetDialogProps } = useActionToolbar();
    return (
        <>
            {showToolbar && (
                <div className="controls-container toolbar-container background-blur toggle-pointer-events">
                    <IconButton { ...buttonProps } icon={<Palette />} />
                    <CollapseButton />
                    <TieCablesButton />
                    <ColorDialog {...colorDialogProps} />
                    <GenericDialog {...confirmResetDialogProps} />
                </div>
            )}
        </>
    );
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IToken } from "@corning-ctcm/platform-client";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { createAsyncAction } from "../workspace/workspace.actions";
import { IAuthenticationState, User, initialUser, IUserSettings, initialState } from "./authentication";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useCallback } from "react";

const setTokenReducer = (state: IAuthenticationState, action: PayloadAction<IToken>) => {
    const { exp, roles } = action.payload;
    state.tokenExpiration = exp;
    state.roles = roles;
    state.loggedIn = true;
}

const setUserReducer = (state: IAuthenticationState, action: PayloadAction<User>) => {
    state.user = action.payload;
}

const logoutReducer = (state: IAuthenticationState) => {
    state.tokenExpiration = undefined;
    state.loggedIn = false;
    state.user = initialUser;
}

const setUserIdReducer = (state: IAuthenticationState, action: PayloadAction<number>) => {
    state.user.id = action.payload;
};

const setTokenExpirationReducer = (state: IAuthenticationState, action: PayloadAction<number | undefined>) => {
    state.tokenExpiration = action.payload;
}

const setUserSettingsReducer = (state: IAuthenticationState, action: PayloadAction<IUserSettings>) => {
    state.user.settings = action.payload;
};

const setShowPullingGripReducer = (state: IAuthenticationState, action: PayloadAction<boolean>) => {
    state.user.settings.showPullingGrip = action.payload;
};

const authenticationSlice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setTokenProps: setTokenReducer,
        logout: logoutReducer,
        setUser: setUserReducer,
        setUserId: setUserIdReducer,
        setTokenExpiration: setTokenExpirationReducer,
        setUserSettings: setUserSettingsReducer,
        setShowPullingGrip: setShowPullingGripReducer
    },
});

export const AuthenticationReducer = authenticationSlice.reducer;
export const { 
    setTokenProps,
    logout,
    setUser,
    setUserId,
    setTokenExpiration,
    setUserSettings,
    setShowPullingGrip
} = authenticationSlice.actions;

export const login = (token: string) => {
    return createAsyncAction(async (dispatch) => {
        const service = new AuthenticationService();
        const user = await service.login(token);
        if (user) {
            dispatch(setUser(user));
        }
    });
}

export const getUserSettings = (userId: number) => {
    return createAsyncAction(async (dispatch) => {
        const service = new AuthenticationService();
        const userSettings = await service.getUserSettings(userId);
        if (userSettings) {
            dispatch(setUserSettings(userSettings));
        }
    });
}

export const setShowPullingGripForUser = (userId: number, showPullingGrip: boolean) => {
    return createAsyncAction(async (dispatch) => {
        dispatch(setShowPullingGrip(showPullingGrip));
        const service = new AuthenticationService();
        await service.setShowPullingGripForUser(userId, showPullingGrip);
    });
}

export const useLogout = () => {
    const navigate = useNavigate();
    const storeDispatch = useDispatch();

    const logoutCallback = useCallback(() => {
        navigate("/login");
        storeDispatch(logout());
    }, [navigate, storeDispatch]);
    
    return logoutCallback;
}
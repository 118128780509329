import { getNbConnectors, getNbConnectorsFromDrops, IConnectorData, IConnectorGroupData } from "./connector/connector";
import { DropInfo, DropSide, IDrop } from "./drop";
import {
    IBuildData,
    IBuildInfo,
    IBuildPrintSettings,
    IBuildsState,
    ICableInfoUpdateState,
    IUpdateGroupArgs,
} from "./build";
import { PayloadAction } from "@reduxjs/toolkit";
import { getConnectorDefaultColor } from "../../../components/pixi/factories/texture";
import { DEFAULT_TOLERANCES, ITolerance, ITolerances } from "../../../models/overlay/header/tolerances/tolerances";
import { IUnitOfMeasure } from "../../../models/overlay/header/units-of-measure";
import { getDefaultDescription } from "../../../components/overlay/project-drawer/project-manager-row/build-info/build-info.hooks";
import { FlameRating } from "../../../models/overlay/wizard/setup/flame-rating";
import { IDestinationSetupState } from "../../../store/overlay/wizard/setup/destination-setup/destination-setup";
import { ISourceSetupState } from "../../../store/overlay/wizard/setup/source-setup/source-setup";
import {
    getConnectorType,
    getConnectorTypeFromDrop,
    IConnectorType,
    Stagger0,
} from "../../../store/overlay/wizard/wizard";
import { ProductLabel } from "../../../models/overlay/reports/report-settings/labels-section";
import { IConnectorAssignment } from "../../../store/overlay/polarity/polarity";
import {
    FEEDER_BUNDLES_COLLAPSE_THRESHOLD,
    TAPS_BUNDLES_COLLAPSE_THRESHOLD,
} from "../../../models/overlay/header/collapse-options";

export const getBuildReducer = (state: IBuildsState, action: PayloadAction<IBuildData>) => {
    state.currentBuild = action.payload;
    const buildInfo = extractBuildInfo(action.payload);
    state.builds.push(buildInfo);
};

export const addBuildReducer = (state: IBuildsState, action: PayloadAction<IBuildData>) => {
    const build = action.payload;
    const buildInfo = extractBuildInfo(build);
    state.builds.push(buildInfo);
};

export const addBuildsReducer = (state: IBuildsState, action: PayloadAction<IBuildInfo[]>) => {
    const newBuilds = [...action.payload].filter((n) => !state.builds.some((b) => b.buildId === n.buildId));

    state.builds = [...state.builds, ...newBuilds];
};

export const setBuildsReducer = (state: IBuildsState, action: PayloadAction<IBuildInfo[]>) => {
    state.builds = action.payload;
};

export const updatedBuildInfoReducer = (state: IBuildsState, action: PayloadAction<IBuildInfo>) => {
    const buildInfo = action.payload;
    updateBuildInfo(state, buildInfo);
};

export const updateBuildReducer = (state: IBuildsState, action: PayloadAction<IBuildData>) => {
    const build = action.payload;
    updateBuild(state, build);
};

export const updateBuildListEntryReducer = (state: IBuildsState, action: PayloadAction<IBuildData>) => {
    const build = action.payload;
    updatedBuildListEntry(state, build);
};

export function updateBuildInfo(state: IBuildsState, buildInfo: IBuildInfo) {
    if (buildInfo.buildId) {
        const index = state.builds.findIndex((b) => b.buildId === buildInfo.buildId);
        if (index < 0) {
            state.builds.push(buildInfo);
        } else {
            const updatedBuildInfo: IBuildInfo = {
                ...state.builds[index],
                description: buildInfo.description,
                name: buildInfo.name,
                partNumber: buildInfo.partNumber,
                lastModified: new Date().toLocaleDateString(),
            };
            state.builds[index] = updatedBuildInfo;

            if (state.currentBuild!.id === buildInfo.buildId) {
                const updatedBuild: IBuildData = {
                    ...state.currentBuild!,
                    name: updatedBuildInfo.name,
                    description: updatedBuildInfo.description,
                    partNumber: updatedBuildInfo.partNumber,
                    lastModified: updatedBuildInfo.lastModified,
                };

                state.currentBuild = updatedBuild;
            }
        }
    }
}

export function updatedBuildListEntry(state: IBuildsState, build: IBuildData) {
    if (build.id) {
        const updatedBuild = { ...build, lastModified: new Date().toLocaleDateString() };
        const updatedBuildInfo = extractBuildInfo(updatedBuild);

        const index = state.builds.findIndex((b) => b.buildId === updatedBuildInfo.buildId);
        if (index < 0) {
            state.builds.push(updatedBuildInfo);
        } else {
            state.builds[index] = updatedBuildInfo;
        }
    }
}

export function updateBuild(state: IBuildsState, build: IBuildData) {
    if (build.id) {
        const updatedBuild: IBuildData = { ...build, lastModified: new Date().toLocaleDateString() };
        const updatedBuildInfo = extractBuildInfo(build);

        const index = state.builds.findIndex((b) => b.buildId === updatedBuild.id);
        if (index < 0) {
            state.builds.push(updatedBuildInfo);
        } else {
            state.builds[index] = updatedBuildInfo;

            if (state.currentBuild && state.currentBuild.id === build.id) {
                const { drops, availability } = state.currentBuild;

                // Since these properties aren't persisted in the backend, we need to preserve them
                const updatedDrops = build.drops.map((ud) => {
                    const prevDrop = drops.find((d) => d.side === ud.side && d.position === ud.position);
                    if (prevDrop) {
                        return {
                            ...ud,
                            cablesTied: prevDrop.cablesTied,
                            groupsCollapsed: prevDrop.groupsCollapsed,
                            connectorsCollapsed: prevDrop.connectorsCollapsed,
                        };
                    }
                    return ud;
                });

                state.currentBuild = {
                    ...updatedBuild,
                    drops: updatedDrops,
                    availability,
                };
            }
        }
    }
}

export const updateDrop = (state: IBuildsState, drop: IDrop) => {
    if (state.currentBuild && drop.id) {
        const index = state.currentBuild.drops.findIndex((d) => d.id === drop.id);
        if (index > -1) {
            state.currentBuild.drops[index] = drop;
        } else {
            state.currentBuild.drops.push(drop);
        }
    }
};

export const updateDropsReducer = (state: IBuildsState, action: PayloadAction<IDrop[]>) => {
    if (state.currentBuild) {
        state.currentBuild.drops = action.payload;
    }
};

export const deleteBuildReducer = (state: IBuildsState, action: PayloadAction<number>) => {
    state.builds = state.builds.filter((x) => x.buildId !== action.payload);
};

export const setCurrentBuildReducer = (state: IBuildsState, action: PayloadAction<IBuildData>) => {
    const currentBuild = collapseLargeBundlesBuild(action.payload);
    state.currentBuild = currentBuild;
    updatedBuildListEntry(state, currentBuild);
};

export const setSourceEnabledReducer = (state: IBuildsState, action: PayloadAction<boolean>) => {
    state.currentBuild!.availability!.sourceEnabled = action.payload;
};

export const setDestinationsEnabledReducer = (state: IBuildsState, action: PayloadAction<boolean>) => {
    if (state.currentBuild) {
        const enable = action.payload;
        state.currentBuild.availability = state.currentBuild.availability
            ? state.currentBuild.availability
            : { sourceEnabled: true, enabledDestinations: [] };
        if (enable) {
            state.currentBuild.availability.enabledDestinations.push(
                ...state.currentBuild.drops.slice(1).map((d) => d.position)
            );
        } else {
            state.currentBuild.availability.enabledDestinations = [];
        }
    }
};

export const setTolerancesReducer = (state: IBuildsState, action: PayloadAction<ITolerances>) => {
    if (state.currentBuild) {
        state.currentBuild.tolerances = action.payload;
    }
};

export const setOverallLengthToleranceReducer = (state: IBuildsState, action: PayloadAction<ITolerance>) => {
    if (state.currentBuild) {
        if (!state.currentBuild.tolerances) {
            state.currentBuild.tolerances = { ...DEFAULT_TOLERANCES };
        }

        state.currentBuild.tolerances.overallLengthTolerance = action.payload;
    }
};

export const setLegLengthToleranceReducer = (state: IBuildsState, action: PayloadAction<ITolerance>) => {
    if (state.currentBuild) {
        if (!state.currentBuild.tolerances) {
            state.currentBuild.tolerances = { ...DEFAULT_TOLERANCES };
        }

        state.currentBuild.tolerances.legLengthTolerance = action.payload;
    }
};

export const setMeshLengthToleranceReducer = (state: IBuildsState, action: PayloadAction<ITolerance>) => {
    if (state.currentBuild) {
        if (!state.currentBuild.tolerances) {
            state.currentBuild.tolerances = { ...DEFAULT_TOLERANCES };
        }

        state.currentBuild.tolerances.meshLengthTolerance = action.payload;
    }
};

export const setLabelDistanceToleranceReducer = (state: IBuildsState, action: PayloadAction<ITolerance>) => {
    if (state.currentBuild) {
        if (!state.currentBuild.tolerances) {
            state.currentBuild.tolerances = { ...DEFAULT_TOLERANCES };
        }

        state.currentBuild.tolerances.labelDistanceTolerance = action.payload;
    }
};

export const addEnabledDestinationReducer = (state: IBuildsState, action: PayloadAction<number>) => {
    state.currentBuild!.availability!.enabledDestinations.push(action.payload);
};

export const removeEnabledDestinationReducer = (state: IBuildsState, action: PayloadAction<number>) => {
    state.currentBuild!.availability!.enabledDestinations =
        state.currentBuild!.availability!.enabledDestinations.filter((x) => x !== action.payload);
};

export const updateSourceReducer = (state: IBuildsState, action: PayloadAction<ISourceSetupState>) => {
    const { currentBuild } = state;
    if (currentBuild) {
        const [feeder, ...distribution] = currentBuild.drops;
        const {
            fiberType,
            fiberCountPerConnector,
            flameRating,
            pullingGrip,
            cableOuterDiameter,
            overallLengthType,
            aLength,
            bLength,
            sLength,
            connectorType,
            groupCount,
            bundleCount,
            mesh,
            meshOffset,
            meshColor,
            reverseStaggering,
            modeId,
            primaryUnit,
            unit,
            shell,
        } = action.payload;
        const dropMeshOffset: IUnitOfMeasure | undefined = feeder.meshOffset
            ? meshOffset && { ...meshOffset, id: feeder.meshOffset.id }
            : meshOffset;
        const lengthA: IUnitOfMeasure = { ...aLength, id: feeder.lengthA.id };
        const lengthB: IUnitOfMeasure = { ...bLength, id: feeder.lengthB.id };
        const stagger = sLength ?? Stagger0.value;
        const fiberCountInUse = fiberCountPerConnector ?? connectorType.fiberCount;
        const fiberCount = fiberCountInUse * bundleCount;

        let groups: IConnectorGroupData[] = [];
        if (groupCount && bundleCount) {
            const validGroupCount = bundleCount % groupCount === 0 ? groupCount : groupCount + 1;
            const cpg = Math.floor(bundleCount / groupCount);
            groups = updateGroups({
                side: feeder.side,
                position: feeder.position,
                groups: feeder.groups,
                groupCount: validGroupCount,
                connectorsPerGroup: cpg,
                connectorType,
                stagger,
                fiberCount,
                fiberCountInUse,
                lengthB,
                reverseStaggering,
                customBLength: feeder.customBLength,
            });
        }

        let updatedFeeder: IDrop = {
            ...feeder,
            fiberCountInUse,
            mesh,
            meshOffset: mesh ? dropMeshOffset : undefined,
            meshColor: mesh ? meshColor : undefined,
            lengthA: lengthA,
            lengthB: lengthB,
            groups,
            shell: !!shell ? shell : feeder.shell,
            reverseStaggering,
        };

        if ((mesh || currentBuild.fiberType !== fiberType) && meshColor) {
            setAllMeshColors(state, meshColor!);
        }
        let updatedDistribution: IDrop[] = [...distribution];
        let updatedDrops: IDrop[] = [updatedFeeder, ...updatedDistribution];
        
        const availability = currentBuild.availability ?? { sourceEnabled: true, enabledDestinations: [] };
        const feederConnectorType = getConnectorTypeFromDrop(feeder);
        const feederFiberCountInUse = feeder.fiberCountInUse ?? feederConnectorType.fiberCount;
        const fiberCountInUseChanged = feederFiberCountInUse !== fiberCountInUse;
        const connectorTypeChanged = feederConnectorType.type !== connectorType.type;
        if (
            (connectorTypeChanged && fiberCountInUseChanged) ||
            (connectorTypeChanged && availability.enabledDestinations.length)
        ) {
            // Propagate connector type changes to destinations
            updatedDistribution = updatedDistribution.map((d) => updateConnectorType(connectorType, d));
            updatedDrops = [updatedFeeder, ...updatedDistribution];
        }

        const drop = updatedDistribution[0];
        const dropConnectorType = getConnectorTypeFromDrop(drop);
        const dropFiberCountInUse = dropConnectorType.fiberCountInUse ?? dropConnectorType.fiberCount;

        const prevBundleCount = getNbConnectors(feeder.groups);
        const bundleCountChanged = prevBundleCount !== bundleCount;
        if (bundleCountChanged && groupCount) {
            // Bundle count changes needs to update distribution drops
            updatedDistribution = updateDistributionBundleCount(fiberCount, updatedDistribution);
            updatedDrops = [updatedFeeder, ...updatedDistribution];
            updatedDrops = collapseLargeBundlesDrops(updatedDrops, bundleCount);
        }

        const fiberTypeChanged = currentBuild.fiberType ? currentBuild.fiberType !== fiberType : false;
        if (fiberTypeChanged) {
            // Fiber type changes can change mesh color, propagate to distribution drops
            // Only propagate mesh color on fiber type change on cable creation
            updatedDistribution = updatedDistribution.map((d) => (d.id === undefined && d.mesh ? { ...d, meshColor } : d))
            updatedDrops = [updatedFeeder, ...updatedDistribution];
        }

        if (fiberCountInUseChanged) {
            updatedDistribution = updatedDistribution.map((d) => updateFiberCounInUse(d, dropFiberCountInUse));
            updatedDistribution = updateDistributionBundleCount(fiberCount, updatedDistribution)
            updatedDrops = [updatedFeeder, ...updatedDistribution];
        }

        const shellTypeChanged = !!updatedFeeder.shell && !!shell && updatedFeeder.shell !== shell;
        if (shellTypeChanged) {
            // Mode change on cable creating can change the shell type depending on the
            // boundaries configuration that is chosen
            updatedDrops = [updatedFeeder, ...updatedDistribution.map((d) => ({ ...d, shell }))];
        }

        currentBuild.modeId = modeId;
        currentBuild.fiberCount = fiberCount;
        currentBuild.bundleCount = bundleCount;
        currentBuild.flameRating = flameRating;
        currentBuild.pullingGrip = pullingGrip;
        currentBuild.cableOuterDiameter = cableOuterDiameter;
        currentBuild.fiberType = fiberType;
        currentBuild.overallLengthType = overallLengthType;
        currentBuild.drops = updatedDrops;
        currentBuild.description = connectorTypeChanged
            ? getDefaultDescription(extractBuildInfo(currentBuild))
            : currentBuild.description;
        currentBuild.primaryUnit = primaryUnit;
        currentBuild.unit = unit;

        if (
            availability.sourceEnabled &&
            availability.enabledDestinations.length > 0 &&
            availability.enabledDestinations.length < updatedDrops.length
        ) {
            const start = availability.enabledDestinations.length + 1;
            const destinations = updatedDrops.slice(start).map((d) => d.position);
            availability.enabledDestinations.push(...destinations);
        }
        currentBuild.availability = availability;

        updateBuild(state, currentBuild);
    }
};

export const updateDestinationReducer = (state: IBuildsState, action: PayloadAction<IDestinationSetupState>) => {
    const { currentBuild } = state;
    if (currentBuild) {
        const { drops } = currentBuild;
        const {
            position,
            aLength,
            bLength,
            sLength,
            fiberCountPerConnector,
            connectorType,
            groupCount,
            nbConnectorsPerGroup,
            fiberCount,
            mesh,
            meshOffset,
            meshColor,
            reverseStaggering,
            shell,
        } = action.payload;

        if (position !== undefined) {
            const drop = drops.find((d) => d.position === position && d.side === "distribution");
            if (drop) {
                const dropMeshOffset: IUnitOfMeasure | undefined = drop.meshOffset
                    ? meshOffset && { ...meshOffset, id: drop.meshOffset.id }
                    : meshOffset;
                const lengthA: IUnitOfMeasure | undefined = drop.lengthA &&
                    aLength && { ...aLength, id: drop.lengthA.id };
                const lengthB: IUnitOfMeasure | undefined = drop.lengthB &&
                    bLength && { ...bLength, id: drop.lengthB.id };
                const stagger = sLength ?? Stagger0.value;
                const fiberCountInUse = fiberCountPerConnector ?? connectorType.fiberCount;

                let groups: IConnectorGroupData[] = [];
                if (groupCount && nbConnectorsPerGroup) {
                    groups = updateGroups({
                        side: drop.side,
                        position: drop.position,
                        groups: drop.groups,
                        groupCount,
                        connectorsPerGroup: nbConnectorsPerGroup,
                        connectorType,
                        stagger,
                        fiberCount,
                        fiberCountInUse,
                        lengthB,
                        reverseStaggering,
                        customBLength: drop.customBLength,
                    });
                }

                const meshProps = {
                    hasMesh: mesh,
                    offset: mesh ? dropMeshOffset : undefined,
                    color: mesh ? meshColor : undefined,
                };

                let updatedDrop: IDrop = {
                    ...drop,
                    fiberCountInUse,
                    mesh: meshProps.hasMesh,
                    meshOffset: meshProps.offset,
                    meshColor: meshProps.color,
                    lengthA,
                    lengthB,
                    groups,
                    shell: shell ?? drop.shell ?? "soft",
                    reverseStaggering,
                };

                if (meshProps.hasMesh) {
                    setAllMeshColors(state, meshColor!);
                }

                const meshChanged = drop.mesh !== meshProps.hasMesh;
                const meshOffsetChanged = drop.meshOffset?.value !== meshProps.offset?.value;
                const meshColorChanged = drop.meshColor !== meshProps.color;

                if (meshChanged || meshOffsetChanged || meshColorChanged) {
                    // Mesh changes needs to be applied to all distribution drops
                    let [feeder, ...distribution] = drops;
                    const updatedDrops: IDrop[] = [];
                    for (let drop of distribution) {
                        let dropToUpdate: IDrop = {
                            ...drop,
                            mesh: meshProps.hasMesh,
                            meshOffset: meshProps.offset,
                            meshColor: meshProps.color,
                            reverseStaggering,
                            groups: drop.groups,
                        };
                        if (drop.position === position) {
                            dropToUpdate = updatedDrop;
                        }
                        updatedDrops.push(dropToUpdate);
                    }

                    currentBuild.drops = [feeder, ...updatedDrops];
                } else {
                    // Update the specific drop
                    const dropIndex = currentBuild.drops.findIndex(
                        (d) => d.position === position && d.side === "distribution"
                    );
                    currentBuild.drops[dropIndex] = updatedDrop;
                }

                updateBuild(state, currentBuild);
            }
        }
    }
};

const removeConnectors = (count: number, drop: IDrop) => {
    let updatedDrop: IDrop = { ...drop };
    let groups: IConnectorGroupData[] = [];
    let remaining = count;
    for (let j = drop.groups.length - 1; j >= 0; j--) {
        const group = drop.groups[j];
        const nbConnectors = group.connectors.length;
        const nbRemovedConnectors = remaining > nbConnectors ? nbConnectors : remaining;
        const connectors = group.connectors.slice(0, group.connectors.length - nbRemovedConnectors);
        group.connectors = connectors;
        groups.push(group);
        remaining = remaining - nbRemovedConnectors > 0 ? remaining - nbRemovedConnectors : 0;
    }

    groups = groups.reverse();
    updatedDrop.groups = groups;

    return {
        remaining,
        drop: updatedDrop,
    };
};

const removeConnectorsFromDrops = (start: number, drops: IDrop[]) => {
    let updatedDrops: IDrop[] = [];
    let count = start;
    for (let i = drops.length - 1; i >= 0; i--) {
        const { remaining, drop } = removeConnectors(count, drops[i]);
        count = remaining;
        updatedDrops.push(drop);
    }

    return updatedDrops.reverse();
};

const addDrops = (remaining: number, drops: IDrop[]) => {
    if (remaining === 0) return drops;

    // Create entirely new drops if we're missing some after completing already existing drops
    let dropPosition = drops.length;
    while (remaining > 0) {
        let groups: IConnectorGroupData[] = [];

        const reference = drops[dropPosition - 1];
        const {
            lengthB,
            lengthA,
            customBLength,
            mesh,
            meshOffset,
            meshColor,
            connectorsCollapsed,
            groupsCollapsed,
            cablesTied,
            shell,
            reverseStaggering,
        } = reference;

        const connectorType = getConnectorTypeFromDrop(reference);
        const color = reference.groups[0].connectors[reference.groups[0].connectors.length - 1].color;
        const defaultColor = getConnectorDefaultColor(connectorType.type).name;

        const stagger = reference.groups[0].stagger ?? Stagger0.value;
        const nbGroups = reference.groups.length;
        const nbConnectors = getNbConnectors(reference.groups);
        const cpg = nbConnectors / nbGroups;
        const fiberCountInUse = reference.fiberCountInUse ?? connectorType.fiberCount;

        for (let i = 0; i < nbGroups; i++) {
            const nbNewConnectors = cpg < remaining ? cpg : remaining;
            const newConnectors: IConnectorData[] = Array.from({ length: nbNewConnectors }, (v, k) => ({
                position: k,
                label: (k + 1).toString(),
                type: connectorType.type,
                color,
                defaultColor,
                stagger,
                side: "distribution",
                groupPosition: i,
                fiberCountInUse,
            }));

            remaining = remaining - nbNewConnectors > 0 ? remaining - nbNewConnectors : 0;

            const bLengthValue = getGroupBLength(lengthB.value, stagger.value, nbGroups, i, reverseStaggering);
            groups.push({
                position: i,
                type: connectorType.type,
                stagger,
                lengthB: { value: bLengthValue, unit: lengthB.unit },
                connectors: newConnectors,
                tapPosition: dropPosition,
            });
        }

        drops.push({
            position: dropPosition,
            lengthA,
            lengthB,
            customBLength,
            mesh,
            meshOffset,
            meshColor,
            cablesTied,
            shell,
            groups,
            connectorsCollapsed,
            groupsCollapsed,
            side: "distribution",
            reverseStaggering,
            fiberCountInUse,
        });

        dropPosition++;
    }

    return drops;
};

const addConnectors = (count: number, nbGroups: number, nbConnectorsPerGroup: number, drop: IDrop) => {
    const dropConnectorType = getConnectorTypeFromDrop(drop);
    const dropReverseStaggering = drop.reverseStaggering;
    const dropLengthB = drop.lengthB;
    const dropStagger = drop.groups[0].stagger ?? Stagger0.value;
    const dropNbGroups = drop.groups.length;
    const dropPosition = drop.position;
    const dropFiberCountInUse = drop.fiberCountInUse ?? dropConnectorType.fiberCount;

    let remaining = count;
    let groups: IConnectorGroupData[] = [...drop.groups];
    if (dropNbGroups < nbGroups) {
        const nbNewGroups = nbGroups - dropNbGroups;
        const newGroups: IConnectorGroupData[] = Array.from({ length: nbNewGroups }, (v, k) => {
            const position = drop.groups.length + k;
            const bLengthValue = getGroupBLength(
                dropLengthB.value,
                dropStagger.value,
                dropNbGroups,
                position,
                dropReverseStaggering
            );
            return {
                position,
                type: dropConnectorType.type,
                stagger: dropStagger,
                lengthB: { value: bLengthValue, unit: dropLengthB.unit },
                connectors: [],
            };
        });

        groups.push(...newGroups);
    }

    const color = drop.groups[0].connectors[drop.groups[0].connectors.length - 1].color;
    const defaultColor = getConnectorDefaultColor(dropConnectorType.type).name;
    // Complete groups with the right number of connectors
    for (let j = 0; j < groups.length; j++) {
        const group = groups[j];
        const nbConnectors = group.connectors.length;
        if (nbConnectors < nbConnectorsPerGroup) {
            const nbNewConnectors =
                nbConnectorsPerGroup - nbConnectors > remaining ? remaining : nbConnectorsPerGroup - nbConnectors;
            const newConnectors: IConnectorData[] = Array.from({ length: nbNewConnectors }, (v, k) => ({
                position: k,
                label: (k + 1).toString(),
                type: dropConnectorType.type,
                color,
                defaultColor,
                side: "distribution",
                tapPosition: dropPosition,
                stagger: dropStagger,
                groupPosition: j,
                fiberCountInUse: dropFiberCountInUse,
            }));

            group.connectors.push(...newConnectors);
            remaining = remaining - nbNewConnectors > 0 ? remaining - nbNewConnectors : 0;
        }
    }
    return {
        remaining,
        drop: {
            ...drop,
            groups,
        },
    };
};

export const updateDistributionBundleCount = (fiberCount: number, distribution: IDrop[]) => {
    const drop = distribution[0];
    const dropConnectorType = getConnectorTypeFromDrop(drop);
    const dropFiberCount = dropConnectorType.fiberCountInUse ?? dropConnectorType.fiberCount;

    const previous = getNbConnectorsFromDrops(distribution);
    const current = fiberCount / dropFiberCount;

    return updateBundleCount(previous, current, distribution);
};

export const updateBundleCount = (prev: number, curr: number, drops: IDrop[]) => {
    let updatedDrops: IDrop[] = [...drops];

    if (curr < prev) {
        updatedDrops = removeConnectorsFromDrops(prev - curr, updatedDrops);
    } else {
        const reference = updatedDrops[0];
        const nbGroups = reference.groups.length;
        const nbConnectorsPerGroup = reference.groups[0].connectors.length;
        const lastDrop = updatedDrops[updatedDrops.length - 1];
        const { remaining, drop: updatedLastDrop } = addConnectors(
            curr - prev,
            nbGroups,
            nbConnectorsPerGroup,
            lastDrop
        );

        updatedDrops[updatedDrops.length - 1] = updatedLastDrop;
        updatedDrops = addDrops(remaining, updatedDrops);
    }

    updatedDrops = updatedDrops.map((d) => ({ ...d, groups: d.groups.filter((g) => g.connectors.length > 0) }));
    updatedDrops = updatedDrops.filter((d) => d.groups.length > 0);
    updatedDrops = updatedDrops.map((d) => ({ 
        ...d, 
        groups: d.groups.length > 1 
            ? d.groups 
            : d.groups.map((g => ({ 
                ...g, 
                stagger: { value: 0, unit: g.stagger!.unit, id: g.stagger?.id }, 
                connectors: g.connectors.map(c => ({ 
                    ...c, 
                    stagger: { value: 0, unit: c.stagger!.unit, id: c.stagger?.id } 
                })) 
            }))) 
        }))

    return updatedDrops;
};

export const updateConnectorType = (
    { type, fiberCount }: IConnectorType,
    drop: IDrop,
    fiberCountInUse?: number
): IDrop => {
    return {
        ...drop,
        fiberCountInUse: fiberCountInUse ?? fiberCount,
        groups: drop.groups.map((g) => ({
            ...g,
            type,
            connectors: g.connectors.map((c) => ({ ...c, fiberCountInUse, type })),
        })),
    };
};

export const updateFiberCounInUse = (drop: IDrop, fiberCountInUse: number): IDrop => {
    return {
        ...drop,
        fiberCountInUse,
        groups: drop.groups.map((g) => ({ ...g, connectors: g.connectors.map((c) => ({ ...c, fiberCountInUse })) })),
    };
};

export const updateGroups = (args: IUpdateGroupArgs): IConnectorGroupData[] => {
    const {
        fiberCountInUse,
        fiberCount,
        groupCount,
        position,
        connectorType,
        connectorsPerGroup,
        stagger,
        lengthB,
        reverseStaggering,
        side,
        groups: previousGroups,
        customBLength,
    } = args;

    let groups: IConnectorGroupData[] = [];
    let remainingFiberCount = fiberCount;
    for (let i = 0; i < groupCount; i++) {
        const previousGroup = previousGroups[i];
        let groupId = i < previousGroups.length ? previousGroup.id : -1;
        let connectors: IConnectorData[] = [];
        let cpg = i === groupCount - 1 ? remainingFiberCount / fiberCountInUse : connectorsPerGroup;
        for (let j = 0; j < cpg && remainingFiberCount >= fiberCountInUse; j++) {
            let connectorId =
                groupId && groupId !== -1 && j < previousGroup.connectors.length ? previousGroup.connectors[j].id : -1;
            
            const connectorStagger = groupCount > 1 
                ? { value: stagger.value, unit: stagger.unit }
                : { value: 0, unit: stagger.unit }
            let connector: IConnectorData = {
                position: j,
                type: connectorType.type,
                stagger: connectorStagger,
                side,
                tapPosition: position,
                groupPosition: i,
                fiberCountInUse,
            };

            if (connectorId && connectorId !== -1) {
                let previousConnector = previousGroup.connectors[j];
                connector.id = connectorId;
                connector.position = previousConnector.position;
                connector.stagger = { ...connectorStagger, id: previousConnector.stagger!.id }
                connector.label = previousConnector.label;
                connector.labelColor = previousConnector.labelColor;
                connector.color = previousConnector.color;
                connector.side = side;
                connector.tapPosition = position;
                connector.groupPosition = i;
            }

            connectors.push(connector);
            remainingFiberCount -= fiberCountInUse;
        }

        if (connectors.length) {
            const groupBLengthValue = getGroupBLength(lengthB.value, stagger.value, groupCount, i, reverseStaggering);
            const defaultLengthBValue = previousGroup?.lengthB?.value ?? groupBLengthValue;
            const dropLengthB: IUnitOfMeasure = {
                value: customBLength ? defaultLengthBValue : groupBLengthValue,
                unit: lengthB.unit,
            };

            const groupStagger = groupCount > 1 
                ? { value: stagger.value, unit: stagger.unit }
                : { value: 0, unit: stagger.unit }
            let group: IConnectorGroupData = {
                position: i,
                type: connectorType.type,
                stagger: groupStagger,
                lengthB: dropLengthB,
                connectors: connectors,
                tapPosition: position,
            };

            if (groupId && groupId !== -1) {
                group.id = groupId;
                group.position = previousGroup.position;
                group.stagger = { ...groupStagger, id: previousGroup.stagger!.id }
                group.tapPosition = position;
            }

            groups.push(group);
        }
    }

    return groups;
};

export const getGroupBLength = (
    bLength: number,
    stagger: number,
    nbGroups: number,
    groupIndex: number,
    reverseStaggering = false
) => {
    return reverseStaggering ? bLength - stagger * groupIndex : bLength + stagger * groupIndex;
};

export const updateAllDestinationsReducer = (state: IBuildsState, action: PayloadAction<IDestinationSetupState>) => {
    const { currentBuild } = state;
    if (currentBuild) {
        const { drops, availability, fiberCount } = currentBuild;
        const {
            fiberCountPerConnector,
            accessPoints,
            groupCount,
            nbConnectorsPerGroup,
            connectorType,
            aLength,
            bLength,
            sLength,
            mesh,
            meshOffset,
            meshColor,
            position,
            reverseStaggering,
            shell,
        } = action.payload;
        const [feeder, ...distribution] = drops;
        const drop = distribution.find((d) => d.position === position);
        const fiberCountInUse = fiberCountPerConnector ?? connectorType.fiberCount;
        if (drop && fiberCount && accessPoints && groupCount && nbConnectorsPerGroup && availability) {
            let updatedDistribution: IDrop[] = [];
            let availabilities: number[] = [];
            let nbDrops = accessPoints;
            let remainingFiberCount = fiberCount;
            const maxDropFiberCount = groupCount * nbConnectorsPerGroup * fiberCountInUse;
            let nbGroups = groupCount;
            for (let i = 0; i < nbDrops; i++) {
                let dropFiberCount = Math.min(maxDropFiberCount, remainingFiberCount);
                if (dropFiberCount < fiberCountInUse) {
                    break;
                }
                if (i === nbDrops - 1 && nbDrops > accessPoints) {
                    dropFiberCount = remainingFiberCount;
                    nbGroups = 1;
                }

                const stagger = sLength ?? Stagger0.value;
                const previousDrop = drops.find((d) => d.side === "distribution" && d.position === i) ?? drops[1];
                const groups = updateGroups({
                    side: drop.side,
                    position: i,
                    groups: previousDrop.groups,
                    groupCount: nbGroups,
                    connectorsPerGroup: nbConnectorsPerGroup,
                    connectorType,
                    stagger,
                    fiberCount: dropFiberCount,
                    fiberCountInUse,
                    lengthB: bLength,
                    reverseStaggering,
                    customBLength: drop.customBLength,
                });

                updatedDistribution.push({
                    id: previousDrop.id,
                    position: i,
                    lengthA: aLength,
                    lengthB: bLength,
                    fiberCountInUse,
                    mesh,
                    meshOffset: mesh ? meshOffset : undefined,
                    meshColor: mesh ? meshColor : undefined,
                    groups,
                    customBLength: drop.customBLength,
                    side: "distribution",
                    shell: shell ?? "soft",
                    groupsCollapsed: previousDrop.groupsCollapsed,
                    connectorsCollapsed: previousDrop.connectorsCollapsed,
                    reverseStaggering,
                });
                remainingFiberCount -= dropFiberCount;
                if (i === nbDrops - 1 && remainingFiberCount > 0) {
                    nbDrops++;
                }
                availabilities.push(i);
            }

            const connectorTypeChanged = drop.groups[0].type !== connectorType.type;

            let updatedDrops: IDrop[] = [feeder, ...updatedDistribution];
            if (connectorTypeChanged) {
                // Only propagate connector type changes, fibercount will also be updated
                updatedDistribution = updatedDistribution.map(d => updateConnectorType(connectorType, d))
                updatedDrops = [feeder, ...updatedDistribution]
            }

            const fiberCountInUseChanged = drop.fiberCountInUse && drop.fiberCountInUse !== fiberCountInUse;
            if (fiberCountInUseChanged) {
                updatedDistribution = updatedDistribution.map(d => updateFiberCounInUse(d, fiberCountInUse))
                updatedDrops = [feeder, ...updatedDistribution];
            }

            if (mesh && meshColor) {
                setAllMeshColors(state, meshColor);
            }

            currentBuild.drops = updatedDrops;
            currentBuild.description = connectorTypeChanged
                ? getDefaultDescription(extractBuildInfo(currentBuild))
                : currentBuild.description;
            currentBuild.availability = currentBuild.availability
                ? { ...currentBuild.availability, enabledDestinations: availabilities }
                : { sourceEnabled: true, enabledDestinations: availabilities };

            updateBuild(state, currentBuild);
        }
    }
};

export const moveDestinationLeftReducer = (state: IBuildsState, action: PayloadAction<number>) => {
    let destinations = [...state.currentBuild!.drops.filter((d) => d.side === "distribution")];
    let rightPosition = action.payload;
    let rightDestination = destinations.find((x) => x.position === rightPosition);
    let rightIndex = destinations.indexOf(rightDestination!);

    let leftPosition = action.payload - 1;
    let leftDestination = destinations.find((x) => x.position === leftPosition);
    let leftIndex = destinations.indexOf(leftDestination!);
    let leftALength = { ...leftDestination!.lengthA! };

    let newLeftDestination = { ...rightDestination! };
    let newRightDestination = { ...leftDestination! };

    if (leftPosition === 1) {
        newLeftDestination.lengthA = { ...newLeftDestination.lengthA!, value: leftDestination!.lengthA!.value };
        newRightDestination.lengthA = { ...newRightDestination.lengthA!, value: rightDestination!.lengthA!.value };
    } else if (leftPosition > 1 && rightPosition - 2 > 0) {
        // not swapping with first drop
        const previousLeftPosition = action.payload - 2;
        const previousLeftDestination = destinations.find((x) => x.position === previousLeftPosition);
        const previousLeftALength = { ...previousLeftDestination!.lengthA! };

        newRightDestination!.lengthA = { ...newRightDestination!.lengthA!, value: newLeftDestination!.lengthA!.value };
        previousLeftDestination!.lengthA = { ...previousLeftDestination!.lengthA!, value: leftALength.value };
        newLeftDestination!.lengthA = { ...newLeftDestination!.lengthA!, value: previousLeftALength.value };
    }

    newLeftDestination!.position--;
    newRightDestination!.position++;

    destinations[leftIndex] = newLeftDestination!;
    destinations[rightIndex] = newRightDestination!;
};

export const moveDestinationRightReducer = (state: IBuildsState, action: PayloadAction<number>) => {
    let destinations = [...state.currentBuild!.drops.filter((d) => d.side === "distribution")];
    let leftPosition = action.payload;
    let leftDestination = destinations.find((x) => x.position === leftPosition);
    let leftIndex = destinations.indexOf(leftDestination!);
    let leftALength = { ...leftDestination!.lengthA! };

    let rightPosition = action.payload + 1;
    let rightDestination = destinations.find((x) => x.position === rightPosition);
    let rightIndex = destinations.indexOf(rightDestination!);

    let newLeftDestination = { ...rightDestination! };
    let newRightDestination = { ...leftDestination! };

    if (leftPosition === 1) {
        // tap 1 case , won't modify a Lengths
        newLeftDestination.lengthA = { ...newLeftDestination.lengthA!, value: leftDestination!.lengthA!.value };
        newRightDestination.lengthA = { ...newRightDestination.lengthA!, value: rightDestination!.lengthA!.value };
    } else {
        const previousLeftPosition = leftPosition - 1;
        const previousLeftDestination = destinations.find((x) => x.position === previousLeftPosition);
        const previousLeftALength = { ...previousLeftDestination!.lengthA! };

        newRightDestination!.lengthA = { ...newRightDestination!.lengthA!, value: newLeftDestination!.lengthA!.value };
        previousLeftDestination!.lengthA = { ...previousLeftDestination!.lengthA!, value: leftALength.value };
        newLeftDestination!.lengthA = { ...newLeftDestination!.lengthA!, value: previousLeftALength.value };
    }

    newRightDestination!.position++;
    newLeftDestination!.position--;

    destinations[rightIndex] = newRightDestination!;
    destinations[leftIndex] = newLeftDestination!;
};

export const removeDestinationByPositionReducer = (state: IBuildsState, action: PayloadAction<number>) => {
    const feederDrop = state.currentBuild!.drops.find((d) => d.side === "feeder")!;
    const distributionDrops = state.currentBuild!.drops.filter((d) => d.side === "distribution");
    let deletedPosition = action.payload;
    let deletedDestination = distributionDrops.find((x) => x.position === deletedPosition)!;
    let beforeDestination = distributionDrops.find((x) => x.position === deletedPosition - 1);
    let filteredDestinations = [...distributionDrops.filter((x) => x.position !== deletedPosition)];

    if (deletedPosition > 1) {
        filteredDestinations = shiftALengths(distributionDrops, deletedPosition);
        if (beforeDestination) {
            beforeDestination.lengthA = { ...beforeDestination.lengthA!, value: deletedDestination.lengthA!.value };
        }

        state.currentBuild!.drops = [feederDrop, ...filteredDestinations];
    } else {
        let aLengths = distributionDrops.map((d) => d.lengthA!);
        const [, ...destinations] = distributionDrops;
        destinations.forEach((d, i) => (d.lengthA = { ...d.lengthA!, value: aLengths[i].value }));
        filteredDestinations = destinations;
    }

    state.currentBuild!.availability!.enabledDestinations.pop();
};

const shiftALengths = (destinations: IDrop[], deletedPosition: number) => {
    const filteredDestinations = [...destinations.filter((x) => x.position !== deletedPosition)];
    filteredDestinations.forEach((x) => {
        if (x.position > 0) {
            const destination = destinations.find((x) => x.position === x.position - 1);
            if (destination) {
                x.lengthA = { ...x.lengthA!, value: destination.lengthA!.value };
            }
        }
    });

    return filteredDestinations;
};

export const setBuildLoadedReducer = (state: IBuildsState, action: PayloadAction<boolean>) => {
    state.loaded = action.payload;
};

export const setCablesTiedReducer = (state: IBuildsState, action: PayloadAction<DropInfo>) => {
    const { position, side } = action.payload;
    const drop = state.currentBuild?.drops.find((d) => d.position === position && d.side === side);
    if (drop) {
        drop.cablesTied = !drop.cablesTied;
    }
};

export const setAllCablesTiedReducer = (state: IBuildsState, action: PayloadAction<boolean | undefined>) => {
    state.currentBuild!.drops.forEach((d) => {
        d.cablesTied = !!action.payload;
    });
};

export const setDropsGroupsCollapseAction = (
    state: IBuildsState,
    action: PayloadAction<{ specificDrop: DropInfo | undefined; collapsed: boolean }>
) => {
    const { specificDrop, collapsed } = action.payload;
    if (specificDrop) {
        const { side, position } = specificDrop;
        const drop = state.currentBuild?.drops.find((d) => d.side === side && d.position === position);
        if (drop) {
            drop.groupsCollapsed = collapsed;
        }
    } else {
        state.currentBuild!.drops.forEach((d) => {
            d.groupsCollapsed = collapsed;
        });
    }
};

export const setDropsConnectorsCollapseAction = (
    state: IBuildsState,
    action: PayloadAction<{ specificDrop: DropInfo | undefined; collapsed: boolean }>
) => {
    const { specificDrop, collapsed } = action.payload;
    if (specificDrop) {
        const { side, position } = specificDrop;
        const drop = state.currentBuild?.drops.find((d) => d.side === side && d.position === position);
        if (drop) {
            drop.connectorsCollapsed = collapsed;
        }
    } else {
        state.currentBuild!.drops.forEach((d) => {
            d.connectorsCollapsed = collapsed;
        });
    }
};

export const autoCollapseAction = (state: IBuildsState) => {
    state.currentBuild = state.currentBuild ? collapseLargeBundlesBuild(state.currentBuild) : state.currentBuild;
};

export const expandAllAction = (state: IBuildsState) => {
    state.currentBuild!.drops.forEach((d) => {
        d.groupsCollapsed = false;
        d.connectorsCollapsed = false;
    });
};

export const setFlameRatingAction = (state: IBuildsState, action: PayloadAction<FlameRating>) => {
    state.currentBuild!.flameRating = action.payload;
    updateBuild(state, state.currentBuild!);
};

export const setConfigurationTypeAction = (
    state: IBuildsState,
    action: PayloadAction<{ configurationType: string; fiberCount?: number }>
) => {
    const { configurationType, fiberCount } = action.payload;
    if (!state.currentBuild) return;
    state.currentBuild.configurationType = configurationType;
    if (fiberCount) state.currentBuild.fiberCount = fiberCount;
    updateBuild(state, state.currentBuild);
};

export const setBuildInfoAction = (state: IBuildsState, action: PayloadAction<ICableInfoUpdateState>) => {
    const { buildId, name, desc, partNumber } = action.payload;
    const buildInfo = state.builds.find((x) => x.buildId === buildId)
        ? state.builds.find((x) => x.buildId === buildId)!
        : extractBuildInfo(state.currentBuild!);

    buildInfo.name = name;
    buildInfo.description = desc;
    buildInfo.partNumber = partNumber;
    updateBuildInfo(state, buildInfo);
};

export const setConnectorAction = (
    state: IBuildsState,
    action: PayloadAction<{
        position: number;
        groupPosition: number;
        connectorPosition: number;
        connector: IConnectorData;
    }>
) => {
    const { currentBuild } = state;
    if (!currentBuild) {
        return;
    }

    const { position, groupPosition, connectorPosition, connector } = action.payload;
    const group = currentBuild.drops
        .find((d) => d.position === position)
        ?.groups.find((g) => g.position === groupPosition);
    if (!group) {
        return;
    }

    if (connectorPosition in group.connectors) {
        group.connectors[connectorPosition] = connector;
    }

    const buildInfo = extractBuildInfo(currentBuild);
    updateBuildInfo(state, buildInfo);
};

export const updateAllConnectorsAction = (state: IBuildsState, action: PayloadAction<IConnectorData[]>) => {
    const updatedConnectors = action.payload;
    const { currentBuild } = state;
    let index = 0;
    if (currentBuild) {
        currentBuild.drops = currentBuild.drops.map((d) => {
            return {
                ...d,
                groups: d.groups.map((g) => {
                    return {
                        ...g,
                        stagger: g.stagger,
                        lengthB: g.lengthB,
                        connectors: g.connectors.map((c) => {
                            const updatedConnector = updatedConnectors[index++];
                            return updateConnector(c, updatedConnector);
                        }),
                    };
                }),
            };
        });

        const buildInfo = extractBuildInfo(currentBuild);
        updateBuildInfo(state, buildInfo);
    }
};

export const updatePositionedConnectorsAction = (state: IBuildsState, action: PayloadAction<IConnectorData[]>) => {
    const updatedConnectors = action.payload;
    if (state.currentBuild) {
        const connectors = state.currentBuild.drops.flatMap((d) =>
            d.groups.flatMap((g) => g.connectors.map((c) => ({ ...c, tapPosition: d.position })))
        );
        for (const { id, side, color, label, labelColor } of updatedConnectors) {
            const connector = connectors.find((c) => c.id === id);
            if (connector) {
                connector.color = color;
                connector.label = label;
                connector.labelColor = labelColor;

                const dropPosition = side === "distribution" ? connector.tapPosition + 1 : connector.tapPosition;
                const groupPosition = connector.groupPosition ?? 0;
                const position = connector.position ?? 0;
                state.currentBuild.drops[dropPosition].groups[groupPosition].connectors[position] = connector;
            }
        }
    }
};

const updateConnector = (connector: IConnectorData, updatedConnector?: IConnectorData): IConnectorData => {
    if (!updatedConnector) return connector;

    return {
        id: updatedConnector.id ?? connector.id,
        position: updatedConnector.position ?? connector.position,
        type: updatedConnector.type ?? connector.type,
        stagger: updatedConnector.stagger ?? connector.stagger,
        label: updatedConnector.label ?? connector.label,
        color: updatedConnector.color,
        labelColor: updatedConnector.labelColor,
        defaultColor: updatedConnector.defaultColor ?? connector.defaultColor,
        side: updatedConnector.side ?? connector.side,
        tapPosition: updatedConnector.tapPosition ?? connector.tapPosition,
        groupPosition: updatedConnector.groupPosition ?? connector.groupPosition,
    };
};

export const updateConnectorGroupsAction = (state: IBuildsState, action: PayloadAction<Partial<IBuildData>>) => {
    const { drops } = action.payload;
    const currentBuild = state.currentBuild;
    if (currentBuild && drops) {
        currentBuild.drops = drops;
    }
};

export const setCurrentBuildAutoAccessPointsAction = (state: IBuildsState, action: PayloadAction<boolean>) => {
    const { currentBuild } = state;
    currentBuild!.autoAccessPoints = action.payload;
};

export const setGroupBLengthAction = (
    state: IBuildsState,
    action: PayloadAction<{
        dropSide: DropSide;
        dropPosition: number;
        groupPosition: number;
        length?: Omit<IUnitOfMeasure, "id">;
        applyToAllDst?: boolean;
    }>
) => {
    const { dropSide, dropPosition, groupPosition, length, applyToAllDst } = action.payload;
    const { currentBuild } = state;
    if (currentBuild) {
        const { drops } = currentBuild;
        let [source, ...destinations] = drops;
        if (dropSide === "feeder" && groupPosition < source.groups.length) {
            source.groups[groupPosition].lengthB = length
                ? { ...source.groups[groupPosition].lengthB, ...length }
                : undefined;
        } else if (groupPosition < destinations[dropPosition].groups.length) {
            if (applyToAllDst) {
                const currentDestination = destinations[dropPosition];
                destinations = destinations.map((d) => {
                    if (d.groups.length === currentDestination.groups.length) {
                        d.groups[groupPosition].lengthB = length
                            ? { ...destinations[dropPosition].groups[groupPosition].lengthB, ...length }
                            : undefined;
                    }

                    return d;
                });
            } else {
                destinations[dropPosition].groups[groupPosition].lengthB = length
                    ? { ...destinations[dropPosition].groups[groupPosition].lengthB, ...length }
                    : undefined;
            }
        }
        currentBuild.drops = [source, ...destinations];
    }
};

export const setCustomBLengthAction = (
    state: IBuildsState,
    action: PayloadAction<{ dropSide: DropSide; dropPosition: number; customBLength: boolean; applyToAllDst?: boolean }>
) => {
    const { currentBuild } = state;
    if (currentBuild) {
        const { drops } = currentBuild;
        const { dropSide, dropPosition, customBLength, applyToAllDst } = action.payload;
        let [source, ...destinations] = drops;
        if (dropSide === "feeder") {
            source.customBLength = customBLength;
        } else {
            if (applyToAllDst) {
                destinations = destinations.map((d) => ({ ...d, customBLength }));
            } else {
                const destination = destinations.find((d) => d.position === dropPosition);
                if (destination) {
                    destination.customBLength = customBLength;
                }
            }
        }

        currentBuild.drops = [source, ...destinations];
    }
};

export const setCatalogCodeAction = (state: IBuildsState, action: PayloadAction<string | undefined>) => {
    if (state.currentBuild) {
        state.currentBuild.catalogCode = action.payload;
        updateBuild(state, state.currentBuild);
    }
};

export const setFiberTypeAction = (state: IBuildsState, action: PayloadAction<string>) => {
    if (state.currentBuild) state.currentBuild.fiberType = action.payload;
};

export const extractBuildInfo = (build: IBuildData) => {
    const buildInfo: IBuildInfo = {
        buildId: build.id!,
        userId: build.userId!,
        groupId: build.groupId!,
        description: build.description!,
        partNumber: build.partNumber!,
        name: build.name!,
        ownerEmail: build.ownerEmail,
        lockedById: build.lockedById,
        catalogCode: build.catalogCode,
        lastModified: build.lastModified,
        fiberCount: build.fiberCount!,
        cableOuterDiameter: build.cableOuterDiameter!,
        fiberType: build.fiberType!,
        nbConnectorsSrc: getNbConnectors(build.drops[0].groups!),
        connectorTypeSrc: getConnectorType(build.drops[0].groups[0].type).type,
        nbConnectorsDst: getNbConnectors(build.drops[1].groups),
        connectorTypeDst: getConnectorType(build.drops[1].groups[0].type).type,
        nbTAPs: build.drops.length,
    };
    return buildInfo;
};

export const updateShellTypeAction = (state: IBuildsState, action: PayloadAction<string>) => {
    if (state.currentBuild) {
        const [feederDrop, ...distributionDrops] = state.currentBuild.drops;
        const updatedDistributionDrops = distributionDrops.map((d) => ({ ...d, shell: action.payload }));
        state.currentBuild.drops = [feederDrop, ...updatedDistributionDrops];
    }
};

export const updateMeasurementsPositionAction = (state: IBuildsState, action: PayloadAction<string>) => {
    if (state.currentBuild) {
        state.currentBuild.measurementsPosition = action.payload;
    }
};

export const setAssemblyTypeReducer = (state: IBuildsState, action: PayloadAction<string>) => {
    state.currentBuild!.assemblyType = action.payload;
};
export const setMeshColorsReducer = (state: IBuildsState, action: PayloadAction<string>) => {
    setAllMeshColors(state, action.payload);
};

export const setAllMeshColors = (state: IBuildsState, colorName: string) => {
    const { currentBuild } = state;
    if (currentBuild) {
        currentBuild.mainMeshColor = colorName;
        currentBuild.drops.forEach((x) => {
            if (x.mesh) x.meshColor = colorName;
        });
    }
};

export const setAllLabelsReducer = (state: IBuildsState, action: PayloadAction<ProductLabel[]>) => {
    if (state.currentBuild) {
        state.currentBuild.productLabels = action.payload;
    }
};

export const setPrintSettingsAction = ({ currentBuild }: IBuildsState, action: PayloadAction<IBuildPrintSettings>) => {
    if (currentBuild) {
        const printSettings = action.payload;
        currentBuild.overviewNotes = printSettings.overviewNotes;
        currentBuild.location = printSettings.location;
        currentBuild.drawnBy = printSettings.drawnBy;
        currentBuild.revisionNumber = printSettings.revisionNumber;
        currentBuild.approvalDate = printSettings.approvalDate;
        currentBuild.inServiceDate = printSettings.inServiceDate;
        currentBuild.footerNotes = printSettings.footerNotes;
    }
};

export const setMaskLengthsAction = (state: IBuildsState, action: PayloadAction<boolean>) => {
    if (state.currentBuild) {
        state.currentBuild.maskLengths = action.payload;
    }
};

export const addConnectorAssignmentsAction = (state: IBuildsState, action: PayloadAction<IConnectorAssignment[]>) => {
    if (state.currentBuild) {
        state.currentBuild.connectorAssignments.push(...action.payload);
    }
};

export const deleteConnectorAssignmentAction = (state: IBuildsState, action: PayloadAction<number>) => {
    if (state.currentBuild) {
        const assignmentIndex = action.payload;
        state.currentBuild.connectorAssignments.splice(assignmentIndex, 1);
    }
};

export const deleteAllConnectorAssignmentAction = (state: IBuildsState) => {
    if (state.currentBuild) {
        state.currentBuild.connectorAssignments = [];
    }
};

const collapseLargeBundlesBuild = (currentBuild: IBuildData): IBuildData => {
    const { bundleCount, drops } = currentBuild;
    return {
        ...currentBuild,
        drops: collapseLargeBundlesDrops(drops, bundleCount),
    };
};

const collapseLargeBundlesDrops = (drops: IDrop[], bundleCount?: number): IDrop[] => {
    if (bundleCount && bundleCount >= TAPS_BUNDLES_COLLAPSE_THRESHOLD) {
        if (bundleCount >= FEEDER_BUNDLES_COLLAPSE_THRESHOLD) {
            drops = drops.map((drop) => ({
                ...drop,
                groupsCollapsed: true,
                connectorsCollapsed: true,
            }));
        } else {
            drops = drops.map((drop) =>
                drop.side === "distribution"
                    ? {
                          ...drop,
                          groupsCollapsed: true,
                          connectorsCollapsed: true,
                      }
                    : {
                          ...drop,
                          groupsCollapsed: false,
                          connectorsCollapsed: false,
                      }
            );
        }
    } else {
        drops = drops.map((drop) => ({
            ...drop,
            groupsCollapsed: false,
            connectorsCollapsed: false,
        }));
    }
    return drops;
};

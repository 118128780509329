export const SchemeTabs = {
    Examples: "examples",
    Customization: "customization",
} as const;

export type SchemeTab = (typeof SchemeTabs)[keyof typeof SchemeTabs];

export const DefaultSequences = {
    Connector: "\\Conn",
    AccessPoint: "\\AP",
    Rack: "\\Rack",
    LegABCD: "\\LegABCD",
    LegAC: "\\LegAC",
    LegBD: "\\LegBD",
};

export const Sequences = Object.values(DefaultSequences);

export interface LabelCustomizationOptions {
    applyAll: boolean;
    rackNumber: number;
    reverseOrder: boolean;
    content?: string;
}

export function getCharFromNumber(value: number) {
    const ordA = "A".charCodeAt(0);
    const ordZ = "Z".charCodeAt(0);
    const len = ordZ - ordA + 1;

    let s = "";
    while (value >= 0) {
        s = String.fromCharCode((value % len) + ordA) + s;
        value = Math.floor(value / len) - 1;
    }
    return s;
}

export function lettersToNumber(letters: string) {
    return letters.split("").reduce((r, a) => r * 26 + parseInt(a, 36) - 9, 0);
}

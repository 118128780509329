import { IReportSection, ReportSectionOption } from "../../../../../models/overlay/reports/report-settings/report-settings";
import { useCheckBoxInput } from "../../../../ui/checkbox/checkbox.hooks";
import { LocalizationKeys } from "../../../../../locales/keys";
import { t } from "i18next";
import { ISelectOption, useSelect } from "../../../../ui/select/select.hooks";

const getSelectOption = (option: string) => {
    switch (option) {
        case ReportSectionOption.Expanded:
            return t(LocalizationKeys.ExpandedTether);
        case ReportSectionOption.Collapsed:
            return t(LocalizationKeys.CollapsedTether);
        case ReportSectionOption.Workspace:
        default:
            return t(LocalizationKeys.AsShownOnWorkspace);
    }
};

export const useDrawingSection = (name: string, label: string, section: IReportSection, disabled?: boolean) => {
    const checkbox = useCheckBoxInput(name, label, section.include && !disabled, section.disabled || disabled);

    const options: ISelectOption[] = Object.values(ReportSectionOption).map((c, i) => ({
        key: `${name}-option-${c}`,
        value: c,
        name: getSelectOption(c),
    }));

    const selectDisabled = !checkbox.checked || section.disabled || disabled;
    const select = useSelect(name, "", options, selectDisabled);

    return {
        checkbox,
        select,
        disabled,
    };
};

import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import { setShowCollapseOptions } from "../../../../../../store/overlay/overlay.reducers";
import { showCollapseOptionsSelector } from "../../../../../../store/overlay/overlay.selectors";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../locales/keys";
import { IconButtonProps } from "@corning-ctcm/silica-react";

export const useCollapseButton = () => {
    const open = useSelector(showCollapseOptionsSelector);
    const { t } = useTranslation();
    const storeDispatch = useDispatch();

    const onClick = useCallback(() => {
        storeDispatch(setShowCollapseOptions(true));
    }, [storeDispatch]);

    const buttonProps: Omit<IconButtonProps, "icon"> = {
        id: "collapse-icon",
        palette: "primary",
        mode: "main",
        disabled: open,
        title: t(LocalizationKeys.CollapseOptions),
        placement: "top",
        onClick,
    };

    return { buttonProps, open };
};

import { ITolerance, ITolerances } from "./tolerances/tolerances";

export const UoMMeters = "m";
export const UoMCentimeters = "cm";
export const UoMMillimeters = "mm";
export const UoMFeet = "ft";
export const UoMInches = '"';

export const unitsIndex = [UoMMeters, UoMCentimeters, UoMFeet, UoMInches];
export const metricIndex = [UoMMeters, UoMCentimeters, UoMMillimeters];
export const imperialIndex = [UoMFeet, UoMInches];

export const PUoMImperial = "imperial";
export const PUoMMetric = "metric";

export const ImperialUnitsRecord: Record<string, Unit> = {
    [UoMFeet]: UoMMeters,
    [UoMInches]: UoMMillimeters,
};

export const MetricUnitsRecord: Record<string, Unit> = {
    [UoMMeters]: UoMFeet,
    [UoMMillimeters]: UoMInches,
};

export const getUnits = (primary: PrimaryUnit) => (primary === "imperial" ? ImperialUnits : MetricUnits);
export const getPrimaryUnit = (secondary: Unit): PrimaryUnit => {
    switch (secondary) {
        case "mm":
        case "cm":
        case "m":
            return "metric";
        case '"':
        case "ft":
        default:
            return "imperial";
    }
};
export const getSecondaryUnit = (primary: PrimaryUnit, secondary: Unit) =>
    primary === "imperial" ? ImperialUnitsRecord[secondary] : MetricUnitsRecord[secondary];
export const getSecondaryText = (primary: PrimaryUnit, secondary: Unit, value: string) => {
    const secondaryUnit = getSecondaryUnit(primary, secondary);
    const valueInt = Number(value);
    const secondaryValue = isNaN(valueInt) ? "" : convertToDisplay({ value: valueInt, unit: secondary }, secondaryUnit);

    return secondary.length > 0 ? `(${secondaryValue}${getUnitsName(secondaryUnit, false, true)})` : secondary;
};

export const PrimaryUnits = {
    PUoMImperial,
    PUoMMetric,
} as const;

export const ImperialUnits = {
    UoMInches,
    UoMFeet,
} as const;

export type ImperialUnit = (typeof ImperialUnits)[keyof typeof ImperialUnits];

export const MetricUnits = {
    UoMMeters,
    // UoMCentimeters,
    UoMMillimeters,
} as const;

export type MetricUnit = (typeof MetricUnits)[keyof typeof MetricUnits];

export const Units = {
    ...MetricUnits,
    UoMCentimeters,
    ...ImperialUnits,
} as const;

export type PrimaryUnit = (typeof PrimaryUnits)[keyof typeof PrimaryUnits];
export type Unit = (typeof Units)[keyof typeof Units];

export const isMetric = (x: string): x is Unit => metricIndex.includes(x);
export const isImperial = (x: string): x is Unit => imperialIndex.includes(x);

export interface IUnitOfMeasure {
    id?: number;
    value: number;
    unit: Unit;
}

export const measureEqual = (m1: IUnitOfMeasure | undefined, m2: IUnitOfMeasure | undefined) => {
    return m1 && m2
        ? m1.id === m2.id && m1.unit === m2.unit && m1.value === m2.value
        : m1 === undefined && m2 === undefined;
};

export const getUnitsName = (unit: Unit, capitalize: boolean = false, abbreviate: boolean = false): string => {
    let name = "";
    switch (unit) {
        case UoMMeters:
            name = abbreviate ? "m" : "meters";
            break;
        case UoMCentimeters:
            name = abbreviate ? "cm" : "centimeters";
            break;
        case UoMMillimeters:
            name = abbreviate ? "mm" : "millimeters";
            break;
        case UoMFeet:
            name = abbreviate ? "ft" : "feet";
            break;
        case UoMInches:
            name = abbreviate ? "in" : "inches";
            break;
        default:
            name = "";
            break;
    }
    return capitalize === true ? toCapitalize(name) : name;
};

export const getUnitFromName = (unitName: string): Unit | undefined => {
    const name = unitName.toLowerCase();
    let unit: Unit | undefined = undefined;
    switch (name) {
        case "m":
        case "meters":
            unit = Units.UoMMeters;
            break;
        case "cm":
        case "centimeters":
            unit = Units.UoMCentimeters;
            break;
        case "mm":
        case "millimeters":
            unit = Units.UoMMillimeters;
            break;
        case "ft":
        case "feet":
            unit = Units.UoMFeet;
            break;
        case "in":
        case "inches":
            unit = Units.UoMInches;
            break;
    }

    return unit;
};

export const toCapitalize = (value: string) => (value.length > 0 ? value[0].toUpperCase() + value.slice(1) : "");

const millimetersConversionFactors: Record<Unit, number> = {
    [UoMMeters]: 1000,
    [UoMCentimeters]: 10,
    [UoMMillimeters]: 1,
    [UoMFeet]: 304.8,
    [UoMInches]: 25.4,
};

export const convertTolerance = (tolerance: ITolerance, from: Unit, to?: Unit): ITolerance => {
    const toUnit = to ?? Units.UoMInches;

    const convertedMin = convertTo({ value: tolerance.min, unit: from }, toUnit).value;
    const min = parseFloat(roundToDecimalPoint(convertedMin, 4, false)); // keeping 4 decimals for conversion precision

    const convertedMax = convertTo({ value: tolerance.max, unit: from }, toUnit).value;
    const max = parseFloat(roundToDecimalPoint(convertedMax, 4, false)); // keeping 4 decimals for conversion precision

    return { min, max };
};

export const convertTolerances = (tolerances: ITolerances, from: Unit, to?: Unit): ITolerances => {
    const { showTolerances, overallLengthTolerance, legLengthTolerance, meshLengthTolerance, labelDistanceTolerance } =
        tolerances;

    return {
        showTolerances,
        overallLengthTolerance: convertTolerance(overallLengthTolerance, from, to),
        legLengthTolerance: convertTolerance(legLengthTolerance, from, to),
        meshLengthTolerance: convertTolerance(meshLengthTolerance, from, to),
        labelDistanceTolerance: convertTolerance(labelDistanceTolerance, from, to),
    };
};

export const convertToleranceToDisplay = (tolerance: ITolerance, from: Unit, to?: Unit): ITolerance => {
    const toUnit = to ?? Units.UoMInches;
    const min = parseFloat(convertToDisplay({ value: tolerance.min, unit: from }, toUnit));
    const max = parseFloat(convertToDisplay({ value: tolerance.max, unit: from }, toUnit));

    return { min, max };
};

export const convertTolerancesToDisplay = (tolerances: ITolerances, from: Unit, to?: Unit): ITolerances => {
    const showTolerances = tolerances.showTolerances;
    const overallLengthTolerance: ITolerance = convertToleranceToDisplay(tolerances.overallLengthTolerance, from, to);
    const legLengthTolerance: ITolerance = convertToleranceToDisplay(tolerances.legLengthTolerance, from, to);
    const meshLengthTolerance: ITolerance = convertToleranceToDisplay(tolerances.meshLengthTolerance, from, to);
    const labelDistanceTolerance: ITolerance = convertToleranceToDisplay(tolerances.labelDistanceTolerance, from, to);

    return {
        showTolerances,
        overallLengthTolerance,
        legLengthTolerance,
        meshLengthTolerance,
        labelDistanceTolerance,
    };
};

// converts value to desired unit
export const convertTo = (from: IUnitOfMeasure | undefined, to?: Unit): IUnitOfMeasure => {
    if (!from) {
        return { value: -1, unit: Units.UoMInches };
    }

    const toUnit = to ?? Units.UoMInches;
    const conversionFactor = millimetersConversionFactors[from.unit] / millimetersConversionFactors[toUnit];

    return { unit: toUnit, value: conversionFactor * from.value };
};

// converts value to desired unit and rounds to the 2nd decimal point if needed
export const convertToDisplay = (from: IUnitOfMeasure, to: Unit): string => {
    return roundToDecimalBasedOnUnit(convertTo(from, to).value, to);
};

export const decimalPlacesBasedOnUnit = (unit: Unit): number => {
    switch (unit) {
        case Units.UoMMeters:
            return 2;
        case Units.UoMFeet:
            return 1;
        case Units.UoMCentimeters:
        case Units.UoMMillimeters:
        case Units.UoMInches:
        default:
            return 0;
    }
};

export const getDecimalPlaces = (value: string): number => {
    return value.split(".")[1]?.length ?? 0;
};

// converts value to desired decimal point based on unit
export const roundToDecimalBasedOnUnit = (value: number, unit: Unit): string => {
    if (value === 0) {
        return value.toString();
    }

    const decimalPlaces = decimalPlacesBasedOnUnit(unit);
    return roundToDecimalPoint(value, decimalPlaces, false);
};

// returns a string value to desired decimal point with/without trailing zeros
export const roundToDecimalPoint = (value: number, decimalPoint: number, trailingZeros: boolean): string => {
    if (trailingZeros) {
        let pow = Math.pow(10, decimalPoint);
        return (Math.ceil(value * pow) / pow).toFixed(decimalPoint);
    } else {
        return Number(value.toFixed(decimalPoint)).toString();
    }
};

export function duplicateLength(length: IUnitOfMeasure): IUnitOfMeasure {
    const { id, ...lengthData } = length;
    return { ...lengthData };
}

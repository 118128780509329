import { Graphics, RoundedRectangle } from "pixi.js";
import { useCallback } from "react";
import { IRectangleDrawingProps, IRectangleGraphics } from "../../../../../models/pixi/decorators/bounds/interactive-rectangle";

export const useInteractiveRectangle = ({
    name,
    bounds,
    layout: { color, borderColor, alpha },
    eventMode,
    onMouseClick,
    onMouseOut,
    onMouseOver,
}: IRectangleDrawingProps) => {
    const rectangleGraphics: IRectangleGraphics = {
        name,
        cursor: "pointer",
        preventRedraw: false,
        bounds,
        alpha,
        eventMode,
        click: onMouseClick,
        mouseout: onMouseOut,
        mouseover: onMouseOver,
    };

    const drawRectangle = useCallback(
        (grfx: Graphics) => {
            grfx.clear();

            if (color) {
                grfx.beginFill(color, 0.33);
                grfx.lineStyle(1, borderColor);
                grfx.drawRoundedRect(bounds.x, bounds.y, bounds.width, bounds.height, 8);
                grfx.endFill();
            } else {
                grfx.lineStyle(1, borderColor);
                grfx.drawRoundedRect(bounds.x, bounds.y, bounds.width, bounds.height, 8);
            }

            grfx.alpha = alpha;
            grfx.hitArea = new RoundedRectangle(bounds.x, bounds.y, bounds.width, bounds.height, 8);
        },
        [color, alpha, borderColor, bounds]
    );

    return { rectangleGraphics, bounds, borderColor, alpha, color, drawRectangle };
};

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowMoreMenu } from "../../../../store/overlay/overlay.reducers";
import { showMoreMenuSelector } from "../../../../store/overlay/overlay.selectors";
import { LocalizationKeys } from "../../../../locales/keys";
import { useTranslation } from "react-i18next";
import { IMenuItemProps } from "../../../../models/overlay/header/menu-item";
import { showBoundariesSettings } from "../../../../store/workspace/boundaries/boundaries.reducers";
import { usePlatformService } from "@corning-ctcm/platform-client";
import { useLogout } from "../../../../store/authentication/authentication.reducers";
import { rolesSelector } from "../../../../store/authentication/authentication.selectors";
import { canEditBoundaries } from "../../../../models/roles/roles";

export const useMoreMenuContainer = () => {
    const { t } = useTranslation();
    const platformService = usePlatformService();
    const logoutCallback = useLogout();
    const roles = useSelector(rolesSelector);
    const display = useSelector(showMoreMenuSelector);
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        dispatch(setShowMoreMenu(false));
    }, [dispatch]);

    const onBoundariesClick = useCallback(() => {
        dispatch(showBoundariesSettings(true));
        onClose();
    }, [onClose, dispatch]);

    const boundaries: IMenuItemProps | undefined =
        canEditBoundaries(roles)
            ? {
                  id: "boundaries-button",
                  icon: "crop_free",
                  label: t(LocalizationKeys.BoundariesSettings),
                  onClick: onBoundariesClick,
              }
            : undefined;

    const logout: IMenuItemProps = {
        id: "logout-button",
        icon: "close",
        label: t(LocalizationKeys.LogOut),
        onClick: () => platformService.logout(logoutCallback),
    };

    return {
        display,
        items: boundaries ? [boundaries, logout] : [logout],
        onClose,
    };
};

import { createSlice } from "@reduxjs/toolkit";
import { initialConnectorReportContext, initialState } from "./reports";
import {
    setSelectedRowsAction,
    toggleRowSelectionAction,
    clearSelectionAction,
    setLabelChangedAction,
    setTriggerColorsChangedAction,
} from "./reports.actions";
import { createContext } from "react";

const connectorReportSlice = createSlice({
    name: "reports",
    initialState,
    reducers: {
        setSelectedRows: setSelectedRowsAction,
        toggleRowSelection: toggleRowSelectionAction,
        clearSelection: clearSelectionAction,
        setLabelChanged: setLabelChangedAction,
        setTriggerColorsChanged: setTriggerColorsChangedAction,
    },
});

export const ConnectorReportReducer = connectorReportSlice.reducer;
export const { setSelectedRows, toggleRowSelection, clearSelection, setLabelChanged, setTriggerColorsChanged } =
    connectorReportSlice.actions;

export const ConnectorReportContext = createContext(initialConnectorReportContext);

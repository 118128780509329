import React from "react";
import { WarningSnackbar } from "./warning-snackbar/warning-snackbar";
import { WarningsCard } from "./warnings-card/warnings-card";

export const Warnings = () => {
    return (
        <>
            <WarningsCard />
            <WarningSnackbar />
        </>
    );
};

import { renderToString } from "react-dom/server";
import { BORDER_COLOR_HEX } from "../../../models/pixi/build/connectors/connector-furcation";

const CROSSHATCH_PATTERN = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M0 0L60 60M60 0L0 60" style={{ stroke: BORDER_COLOR_HEX, strokeWidth: 8 }} />
            </g>
        </svg>
    );
};

export const getCrosshatchSVGString = (): string => {
    const crosshatchSvg = CROSSHATCH_PATTERN();
    return renderToString(crosshatchSvg);
};

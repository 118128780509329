import { useMemo } from "react";
import { useSelector } from "react-redux";
import { White } from "../../models/ui/dialog/color-dialog";
import { defaultTriggersColorSelector } from "../../store/workspace/boundaries/boundaries.selectors";
import { DropData } from "../../store/workspace/build/build";
import { sscSessionPolarityMapsSelector } from "../../store/workspace/ssc/ssc.selectors";
import { currentStatusSelector } from "../../store/overlay/header/status-icon/status-icon.selectors";
import { unitsOfMeasureContainerUnitSelector } from "../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import {
    convertTo,
    convertToleranceToDisplay,
    getUnitsName,
    roundToDecimalBasedOnUnit,
    Unit,
    Units,
} from "../../models/overlay/header/units-of-measure";
import { AllColors, getFiberColors } from "../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";
import { getConnectorType, getConnectorTypeFromDrop, Stagger0 } from "../../store/overlay/wizard/wizard";
import { useBuildPlanTable } from "./build-plan-table/build-plan-table.hooks";
import {
    currentMaskLengthsSelector,
    currentOverallLengthTypeSelector,
    currentTolerancesSelector,
    dropsSelector,
} from "../../store/workspace/build.selectors";
import { t } from "i18next";
import { LocalizationKeys } from "../../locales/keys";
import { ITolerance } from "../../models/overlay/header/tolerances/tolerances";
import { DropSide } from "../../store/workspace/build/drop";

export const useReportTables = () => {
    const currentStatus = useSelector(currentStatusSelector);
    const dropConnectorTables = useDropTables();
    const polarityDiagrams = usePolarityDiagrams();
    const cableTrunkLengthTable = useCableTrunkLengthTable();
    const buildTolerancesTable = useBuildTolerancesTable();
    const dropLabelTables = useDropLabelTable();
    const fiberColors = useFiberColors();
    const { buildPlanTables } = useBuildPlanTable();

    return {
        currentStatus,
        buildTolerancesTable,
        cableTrunkLengthTable,
        dropConnectorTables,
        polarityDiagrams,
        dropLabelTables,
        fiberColors,
        buildPlanTables,
    };
};

const useDropTables = () => {
    const drops = useSelector(dropsSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const defaultTriggerColors = useSelector(defaultTriggersColorSelector);

    const dropTables = useMemo(() => {
        return drops.map((d) => {
            const id = d.side === "distribution" ? `drop-connector-table-${d.position}` : "feeder-connector-table";
            const connectorType = d.groups[0]?.type ?? "";
            const defaultColor = defaultTriggerColors[connectorType]?.name ?? "";
            const rows = createDropTableRows(d, unit, defaultColor);
            return { id, side: d.side, rows };
        });
    }, [drops, unit, defaultTriggerColors]);

    return dropTables;
};

const createDropTableRows = (drop: DropData, unit: Unit, defaultColor: string) => {
    const { groups, customBLength } = drop;
    const stagger = groups[0].stagger ?? Stagger0.value;
    const staggerValue = convertTo(stagger, unit).value;

    let groupStagger = 0;
    let connectorId: number = 1;

    const rows = [];
    for (let i = 0; i < groups.length; i++) {
        const connectorGroup = groups[i];
        const lengthB =
            customBLength && connectorGroup.lengthB
                ? convertTo(connectorGroup.lengthB, unit)
                : convertTo(drop.lengthB, unit);

        const connectorType = getConnectorType(connectorGroup.type!);
        for (let j = 0; j < connectorGroup.connectors.length; j++) {
            const b = roundToDecimalBasedOnUnit(lengthB.value + groupStagger, unit);
            const { label, labelColor, color } = connectorGroup.connectors[j];
            const colorIndex = color
                ? AllColors.findIndex((c) => c.name === color)
                : AllColors.findIndex((c) => c.name === defaultColor);
            const labelColorIndex = labelColor
                ? AllColors.findIndex((c) => c.name === labelColor)
                : AllColors.findIndex((c) => c.name === White.name);

            rows.push({
                tapPosition: drop.position,
                connectorId,
                groupNumber: connectorGroup.position! + 1,
                b,
                label,
                labelColorIndex,
                colorIndex,
                connectorType,
                comment: "",
            });

            connectorId++;
        }
        if (!customBLength) {
            groupStagger += staggerValue;
        }
    }

    return rows;
};

const usePolarityDiagrams = () => {
    const polarityMaps = useSelector(sscSessionPolarityMapsSelector);
    const polarityDiagrams: { key: string; url: string }[] = polarityMaps
        .filter((p) => p.imageUri !== undefined)
        .map((p, i) => ({ key: p.customKey ?? p.demoKey ?? `polarity-image-${i}`, url: p.imageUri ?? "" }));

    return polarityDiagrams;
};

const useFiberColors = () => {
    const drops = useSelector(dropsSelector);
    const fiberColors = useMemo(() => {
        return getFiberColors(getConnectorTypeFromDrop(drops[0]));
    }, [drops]);

    return fiberColors;

};

const useDropLabelTable = () => {
    const [feeder, ...distribution] = useSelector(dropsSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const defaultTriggerColors = useSelector(defaultTriggersColorSelector);

    const dropLabelTables = useMemo(() => {
        const feederConnectorType = feeder.groups[0].type ?? "";
        const feederDefaultColor = defaultTriggerColors[feederConnectorType]?.name ?? "";
        const feederLabelTable = {
            id: "feeder-label-table",
            side: "feeder" as DropSide,
            rows: createDropTableRows({ ...feeder }, unit, feederDefaultColor),
        };

        const distributionLabelRows = distribution.flatMap((d) => {
            const connectorType = d.groups[0].type ?? "";
            const defaultColor = defaultTriggerColors[connectorType]?.name ?? "";
            return createDropTableRows(d, unit, defaultColor);
        });
        const distributionLabelTable = {
            id: "drop-label-table",
            side: "distribution" as DropSide,
            rows: distributionLabelRows,
        };

        return [feederLabelTable, distributionLabelTable];
    }, [feeder, distribution, unit, defaultTriggerColors]);

    return dropLabelTables;
};

const useCableTrunkLengthTable = () => {
    const [feeder, ...distribution] = useSelector(dropsSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const maskLengths = useSelector(currentMaskLengthsSelector);
    const feederA = feeder.lengthA!;
    const dropA = distribution.map((d) => d.lengthA!);
    dropA.pop();
    const alengths = [feederA, ...dropA];

    const aCumulative = [...alengths.map((a) => a.value)];

    for (let i = 1; i < aCumulative.length; i++) {
        aCumulative[i] = aCumulative[i] + aCumulative[i - 1];
    }

    const rows = alengths.map((a, i) => {
        let position = i + 1;
        let section = `A${position}`;
        let convertedAlength = roundToDecimalBasedOnUnit(convertTo(a, unit).value, unit);
        let convertedACumulative = roundToDecimalBasedOnUnit(
            convertTo({ unit: a.unit, value: aCumulative[i] }, unit).value,
            unit
        );
        let key = section;
        if (maskLengths) {
            convertedAlength = "";
            convertedACumulative = "";
        }
        return {
            key,
            position,
            section,
            aLength: convertedAlength,
            aCumulative: convertedACumulative,
        };
    });

    return { rows };
};

const useBuildTolerancesTable = () => {
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const overalLLengthType = useSelector(currentOverallLengthTypeSelector);
    const tolerances = useSelector(currentTolerancesSelector);

    const overallLengthTypeLabel =
        overalLLengthType === "connector"
            ? t(LocalizationKeys.OverallLengthTypeConnector)
            : t(LocalizationKeys.OverallLengthTypeFurcation);

    const rows = tolerances
        ? [
              {
                  label: t(LocalizationKeys.OverallLengthToleranceLabel, {
                      type: overallLengthTypeLabel.toLowerCase(),
                  }),
                  value: createTableToleranceString(tolerances.overallLengthTolerance, unit),
              },
              {
                  label: t(LocalizationKeys.MeshLengthToleranceLabel),
                  value: createTableToleranceString(tolerances.meshLengthTolerance, unit),
              },
              {
                  label: t(LocalizationKeys.LegLengthToleranceLabel),
                  value: createTableToleranceString(tolerances.legLengthTolerance, unit),
              },
              {
                  label: t(LocalizationKeys.LabelDistanceToleranceLabel),
                  value: createTableToleranceString(tolerances.labelDistanceTolerance, unit),
              },
          ]
        : [];

    return { rows };
};

const createTableToleranceString = (tolerance: ITolerance, unit: Unit) => {
    const { min, max } = convertToleranceToDisplay(tolerance, Units.UoMInches, unit);
    const minString = min > 0 ? `+${min}` : `${min}`;
    const maxString = max > 0 ? `+${max}` : `${max}`;

    return `${minString} / ${maxString} ${getUnitsName(unit, false, true)}`;
};

import { useEffect } from "react";
import { useApp } from "@pixi/react";
import { initializeCanvasExtractor } from "../canvas-extractor/canvas-extractor";

export const useInitializer = () => {
    const app = useApp();
    useEffect(() => {
        if (app) {
            initializeCanvasExtractor(app);
        }
    }, [app]);
};

import { Sorts } from "../../../store/overlay/project/project";
import { WorkspaceState } from "../../../store/workspace/workspace.reducers";

export const projectManagerSelector = (state: WorkspaceState) => state.projectManager;
export const totalBuildCountSelector = (state: WorkspaceState) => state.projectManager.data.totalBuildCount ?? 0;

export const projectManagerSortTypeSelector = (state: WorkspaceState) => (
    { 
        sortType: state.projectManager.data.sortType && state.projectManager.data.sortType.length ? state.projectManager.data.sortType : Sorts.DateModified, 
        ascending: state.projectManager.data.isSortAscending ?? true 
    }
)

export const displayProjectManagerSelector = (state: WorkspaceState) => state.projectManager.display;
export const showCableDetailsSelector = (state: WorkspaceState) => state.projectManager.showDetails;
export const showCableInfoSelector = (state: WorkspaceState) => state.projectManager.showInfo;
export const selectedBuildSelector = (state: WorkspaceState) => state.projectManager.selectedBuild;
export const selectedBuildIdSelector = (state: WorkspaceState) => state.projectManager.selectedBuildId;
export const isConnectorEditabledSelector = (state: WorkspaceState) => state.projectManager.data.editableConnectors;

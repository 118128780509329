import { useCallback, useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import { IDialogHeaderProps } from "../../../../../models/ui/dialog/dialog-header";
import { setWarningsCardDisplay } from "../../../../../store/overlay/header/warnings/warnings.reducer";
import { warningsCardDisplaySelector } from "../../../../../store/overlay/header/warnings/warnings.selectors";
import { sscNotificationsSelector } from "../../../../../store/workspace/ssc/ssc.selectors";

export const useWarningsCard = () => {
    const display = useSelector(warningsCardDisplaySelector);
    const { warnings, errors } = useSelector(sscNotificationsSelector);
    const [showErrors, setShowErrors] = useState(true);
    const [showWarnings, setShowWarnings] = useState(true);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onClose = useCallback(() => {
        dispatch(setWarningsCardDisplay(false));
    }, [dispatch]);

    const headerProps: IDialogHeaderProps = {
        title: t(LocalizationKeys.ErrorsAndWarnings),
        collapsible: true,
        closable: true,
        onClose,
    };

    const onErrorsCheckboxChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const { checked } = e.currentTarget;
            setShowErrors(checked);
        },
        [setShowErrors]
    );

    const errorsCheckboxProps = {
        label: `${t(LocalizationKeys.Errors)} (${errors.length})`,
        checked: showErrors,
        className: showErrors ? "checked" : undefined,
        onChange: onErrorsCheckboxChange,
    };

    const onWarningsCheckboxChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const { checked } = e.currentTarget;
            setShowWarnings(checked);
        },
        [setShowWarnings]
    );

    const warningsCheckboxProps = {
        label: `${t(LocalizationKeys.Warnings)} (${warnings.length})`,
        checked: showWarnings,
        className: showWarnings ? "checked" : undefined,
        onChange: onWarningsCheckboxChange,
    };

    const filteredWarnings = [...(showErrors ? errors : []), ...(showWarnings ? warnings : [])];

    return {
        display,
        headerProps,
        errorsCheckboxProps,
        warningsCheckboxProps,
        filteredWarnings,
    };
};

import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageResizeArgs, PageResizeEnum, PageResizeValue } from "../../../../models/overlay/footer/page-resize";
import { setResize } from "../../../../store/pixi/viewport/viewport.reducers";
import { pageResizeSelector } from "../../../../store/pixi/viewport/viewport.selectors";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { disabledUISelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";
import { ButtonProps, IconButtonProps } from "@corning-ctcm/silica-react";

export function usePageResize() {
    const pageResize = useSelector(pageResizeSelector);
    const disabled = useSelector(disabledUISelector);
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const options = Object.values(PageResizeEnum).map((o) => ({ label: t(o), value: o }));
    const buttonText = t(pageResize);

    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget);
    };

    const onMenuClose = () => {
        setAnchorElement(null);
    };

    const onOptionClick = (value: PageResizeValue) => {
        onMenuClose();
        dispatch(setResize({ update: true, value: value }));
    };

    const onResize = useCallback(() => {
        const pageResizeArgs: PageResizeArgs = {
            update: true,
            value: pageResize,
        };
        dispatch(setResize(pageResizeArgs));
    }, [pageResize, dispatch]);

    const resize: Omit<IconButtonProps, "icon"> = {
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.PageResize),
        placement: "top",
        disabled,
        onClick: onResize
    }

    const fitButton: ButtonProps = {
        id: "fit-to-button",
        className: "page-resize-button",
        disabled,
        onClick: onButtonClick
    }

    return { 
        resize,
        fit: {
            button: fitButton,
            label: buttonText
        },
        anchorElement,
        onMenuClose,
        options,
        onOptionClick
    };
}

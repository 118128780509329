import { IUserSettings, User } from "../../store/authentication/authentication";
import { WebService } from "../abstract-web-v2.service";

export class AuthenticationService extends WebService {
    private readonly baseUrl = "/api/auth";

    constructor() {
        super();
        this.isUnstableApi = false;
    }

    public login(token: string): Promise<User | undefined> {
        return this.post(`${this.baseUrl}/login`, { token });
    }

    public logout(): Promise<void> {
        return this.post(`${this.baseUrl}/logout`, {});
    }

    public async getUserSettings(userId: number): Promise<IUserSettings | undefined> {
        return await this.get<IUserSettings | undefined>(`${this.baseUrl}/user/settings?userId=${userId}`);
    }

    public setShowPullingGripForUser(userId: number, showPullingGrip: boolean): Promise<void> {
        return this.post(`${this.baseUrl}/user/pullingGrip`, { userId, showPullingGrip });
    }
}
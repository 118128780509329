import { TFunction } from "i18next";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../../locales/keys";
import { DropData, IBuildData } from "../../../../../../store/workspace/build/build";
import { unitsOfMeasureContainerUnitSelector } from "../../../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import {
    getUnitsName,
    Unit,
    Units,
} from "../../../../../../models/overlay/header/units-of-measure";
import { BLengthContext } from "../../../../../../models/overlay/wizard/setup/b-length-section/b-length-section";
import { IBLengthInputProps } from "../../../../../../models/overlay/wizard/setup/b-length-section/b-length-input";
import { IRange } from "../../../../../../store/workspace/boundaries/boundaries";
import { IDrop } from "../../../../../../store/workspace/build/drop";

export const useCustomBLength = () => {
    const { t } = useTranslation();
    const { drop, isCustom } = useContext(BLengthContext);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const lengthBString = getLengthBString(drop, unit, t);
    const bLengths: IBLengthInputProps[] = useMemo(() => {
        if (isCustom && drop) {
            let bLengths = [];
            const groups = drop.groups ?? [];
            const dropSide = drop.side;
            const dropPosition = drop.position ?? 0;
            bLengths = groups.map((g) => {
                const groupLengthB = g.lengthB ?? { unit: Units.UoMInches, value: 0 };
                const position = g.position ?? 0;
                return {
                    key: `${position}`,
                    position: position,
                    length: groupLengthB,
                    dropSide,
                    dropPosition,
                };
            });
            return bLengths;
        }

        return [];
    }, [drop, isCustom]);

    return { bLengths, display: isCustom, lengthBString };
};

function getLengthBString(drop: DropData | undefined, unit: Unit, translate: TFunction) {
    const lengthB = drop
        ? translate(LocalizationKeys.LengthBWithUnit, { position: drop.position!, unit: getUnitsName(unit) })
        : "";
    return lengthB;
}

export function hasCustomLengthChanges(drop: DropData | IDrop, savedBuildData: IBuildData | undefined) {
    const drops = savedBuildData?.drops ?? [];
    const previousGroups = drops.find((d) => d.side === drop?.side && d.position === drop?.position)?.groups ?? [];
    const currentGroups = drop?.groups ?? [];

    if (previousGroups.length !== currentGroups.length) return false;

    return currentGroups.some((g, i) => {
        const previous = previousGroups[i].lengthB?.value;
        const current = g.lengthB?.value;
        return current && previous ? current !== previous : false;
    });
}

export function isValidBLengths(drop: DropData | IDrop, range: IRange) {
    if (!drop) return false;

    return drop.groups.every((g) => {
        const lengthBValue = g.lengthB?.value ?? 0;
        return lengthBValue > range.min && lengthBValue < range.max && lengthBValue > 0;
    });
}

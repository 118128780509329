import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { setShowConnectorAssignment } from "../../../../store/overlay/overlay.reducers";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { showConnectorAssignmentSelector, showConnectorReportSelector } from "../../../../store/overlay/overlay.selectors";
import { disabledUISelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";
import { expandAll } from "../../../../store/workspace/build/build.reducers";
import { ButtonProps } from "@corning-ctcm/silica-react";

export const usePolarityButton = () => {
    const { t } = useTranslation();
    const disableUI = useSelector(disabledUISelector);
    const showConnectorReport = useSelector(showConnectorReportSelector);
    const showConnectorAssignment = useSelector(showConnectorAssignmentSelector);
    const dispatch = useDispatch();

    const onClick = useCallback(() => {
        dispatch(setShowConnectorAssignment(!showConnectorAssignment));
        dispatch(expandAll());
    }, [dispatch, showConnectorAssignment]);

    const text = t(LocalizationKeys.Polarity).toLocaleUpperCase();
    const disabled = disableUI || showConnectorReport;

    const buttonProps: ButtonProps = {
        id: "polarity-button",
        palette: "primary",
        mode: "main",
        disabled,
        onClick,
    };

    return {
        buttonProps,
        title: t(LocalizationKeys.SetPolarity),
        text,
    };
};

import React, { useReducer } from "react";
import { PolarityReducer } from "../../../store/overlay/polarity/polarity.reducer";
import { PolarityContext, initialPolarityState } from "../../../store/overlay/polarity/polarity";
import { PolarityToolbar } from "./polarity-toolbar/polarity-toolbar";
import { ConnectorAssignmentDialog } from "./connector-assignment/connector-assignment-dialog";
import { FiberMappingDialog } from "./fiber-mapping/fiber-mapping-dialog";
import { PolarityDialog } from "./polarity-dialog";

export const Polarity: React.FC = () => {
    const [state, dispatch] = useReducer(PolarityReducer, initialPolarityState);
    return (
        <PolarityContext.Provider value={{ state, dispatch }}>
            <PolarityToolbar />
            <ConnectorAssignmentDialog />
            <FiberMappingDialog />
            <PolarityDialog />
        </PolarityContext.Provider>
    );
};

import { CSSProperties } from "react";
import { DrawerHeaderProps } from "./drawer-header";

interface IDrawerContextProps {
    className: string;
    open: boolean;
    anchor?: "ltr" | "rtl";
    onClose: () => void;
}

export const initialDrawerContextProps: IDrawerContextProps = {
    className: "modal-drawer",
    open: false,
    onClose: () => {},
};

export type DrawerProps = {
    style?: CSSProperties;
} & DrawerContextProps & DrawerHeaderProps;

export const DrawerActionTypes = {
    SetDisplay: "setDisplay",
} as const;

export type CommonProjectManagerActionTypes = (typeof DrawerActionTypes)[keyof typeof DrawerActionTypes];

export type setDisplay = { type: typeof DrawerActionTypes.SetDisplay; payload: boolean };
export type DrawerActions = setDisplay;

export type DrawerContextProps = Partial<typeof initialDrawerContextProps>;
export type DrawerContext = [DrawerContextProps, React.Dispatch<DrawerActions>?];
export const initialDrawerContext: DrawerContext = [initialDrawerContextProps];

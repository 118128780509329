import { useMemo } from "react";
import { Container, DisplayObject } from "pixi.js";
import {
    BoundingBoxContainer,
    DropContainer,
    HorizontalTrunkContainer,
    InteractiveComponents,
    ShellContainer,
    getContainerName,
    isActiveContainer,
} from "../../../../models/pixi/build/build";
import { IPoint, ISize } from "../../../../models/pixi/pixi";
import { useSelector } from "react-redux";
import { toolbarSelectionSelector } from "../../../../store/overlay/footer/toolbar/toolbar.selectors";
import {
    showAssemblyTypeEditSelector,
    showShellEditSelector,
} from "../../../../store/overlay/overlay.selectors";

type PixiReference = {
    name: string;
    location: IPoint;
    size: ISize;
    active: boolean;
};

const interactableElements = Object.values(InteractiveComponents);

type GetNameArgs = {
    initialName: string | null;
    side: string | undefined;
    selected: number;
    shellActive: boolean;
    trunkActive: boolean;
};

const getName = ({ initialName, side, selected, shellActive, trunkActive }: GetNameArgs) => {
    if (initialName === null) return "";

    let name = initialName;
    let active = false;
    if (name.includes(BoundingBoxContainer)) {
        active = name === `${side}-${DropContainer}-${selected}-${BoundingBoxContainer}`;
    } else if (name.includes(ShellContainer)) {
        active = shellActive;
    } else if (name.includes(HorizontalTrunkContainer)) {
        active = trunkActive;
    }

    return getContainerName(name, active);
};

const useStorageManager = ({ displayObjects }: { displayObjects: DisplayObject[] }) => {
    const { side, selected } = useSelector(toolbarSelectionSelector);
    const shellActive = useSelector(showShellEditSelector);
    const trunkActive = useSelector(showAssemblyTypeEditSelector);

    const pixiReferences = useMemo(() => {
        const pixiReferences: { [elementName: string]: PixiReference[] } = {};
        const filteredObjects = displayObjects.filter((c) => c.name && c.name?.length > 0);
        for (const elementName of interactableElements) {
            const elements = filteredObjects.filter((o) => o.name?.includes(elementName));
            for (const element of elements) {
                const name = getName({ initialName: element.name, side, selected, shellActive, trunkActive });
                const { x: topLeftX, y: topLeftY, width, height } = element.getBounds();
                const reference: PixiReference = {
                    name,
                    location: {
                        x: topLeftX + width * 0.5,
                        y: topLeftY + height * 0.5,
                    },
                    size: { width, height },
                    active: isActiveContainer(name),
                };
                if (pixiReferences[elementName]) {
                    pixiReferences[elementName].push(reference);
                } else {
                    pixiReferences[elementName] = [reference];
                }
            }
        }
        return pixiReferences;
    }, [side, selected, shellActive, trunkActive, displayObjects]);

    for (const elementKey in pixiReferences) {
        const references = pixiReferences[elementKey];
        window.localStorage.setItem(elementKey, JSON.stringify(references));
    }
};

export const StorageManager = (props: { displayObjects: DisplayObject[] }) => {
    useStorageManager(props);
    return <></>;
};

export const getChildrenRecursive = (container: Container, specificContainer?: string) => {
    if (specificContainer && container.name === specificContainer) {
        return [container];
    }

    let children: Container[] = [];
    if (container.children.length > 0) {
        for (const child of container.children) {
            const childContainer = child as Container;
            if (childContainer) {
                children.push(childContainer, ...getChildrenRecursive(childContainer, specificContainer));
            }
        }
    }

    return children;
};

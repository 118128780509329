import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { useCableInfo } from "./cable-info.hooks";
import { DrawerPageTitle } from "../drawer-page-title";
import { OutlinedButton, ContainedButton, TextButton, TextField } from "@corning-ctcm/silica-react";
import { Drawer } from "../../../ui/drawer/drawer";
import CorningLogo from "../../../../resources/svgs/overlay/Corning_Logo_301Grey.svg";
import ChevronLeft from "@mui/icons-material/ChevronLeft";

import "../../../../css/overlay/project-drawer/cable-info.scss";

export const CableInfo = () => {
    const { t } = useTranslation();
    const {
        open,
        nameInputProps,
        descriptionInputProps,
        partNumberInputProps,
        polarityInputProps,
        catalogCode,
        saveDisabled,
        cancelDisabled,
        onReturn,
        onClose,
        onSave,
        onCancel,
        autoFocusInput,
    } = useCableInfo();
    return (
        <Drawer id="cable-info" className="cable-info" open={open} onClose={onClose} logo={CorningLogo}>
            <DrawerPageTitle title="Cable Information" catalogCode={catalogCode} />
            <div className="cable-name">
                <TextField {...nameInputProps} autoFocus={nameInputProps === autoFocusInput}/>
            </div>
            <div className="cable-desc">
                <TextField {...descriptionInputProps} autoFocus={descriptionInputProps === autoFocusInput}/>
            </div>
            <div className="cable-part-number">
                <TextField {...partNumberInputProps} autoFocus={partNumberInputProps === autoFocusInput}/>
            </div>
            <div className="cable-polarity">
                <TextField {...polarityInputProps} />
            </div>
            <div className="info-buttons">
                <OutlinedButton
                    id="cable-info-back-button"
                    className="back-button"
                    onClick={onReturn}
                    startIcon={<ChevronLeft />}
                >
                    {t(LocalizationKeys.Back)}
                </OutlinedButton>
                <div className="right-info-buttons">
                    <TextButton
                        id="cable-info-cancel-button"
                        className="cancel-button"
                        disabled={cancelDisabled}
                        onClick={onCancel}
                    >
                        {t(LocalizationKeys.Cancel)}
                    </TextButton>
                    <ContainedButton
                        id="cable-info-save-button"
                        className="save-button"
                        disabled={saveDisabled}
                        onClick={onSave}
                    >
                        {t(LocalizationKeys.Save)}
                    </ContainedButton>
                </div>
            </div>
        </Drawer>
    );
};

import * as Pixi from "pixi.js";
import { ComponentName } from "../../../models/pixi/build/build";

class _CanvasExtractor {
    public static _canvasExtractor: _CanvasExtractor | null = null;

    private app: Pixi.Application;
    private extract: Pixi.IExtract;
    private canvasContainer: Pixi.Container | null = null;
    private images: { [name: string]: HTMLImageElement } = {};

    private constructor(app: Pixi.Application) {
        this.app = app;
        this.extract = app.renderer.extract;
    }

    public getImage = (componentName: string) => this.images[componentName];
    public getImages = () => Object.values(this.images);

    public extractImages = (...componentNames: ComponentName[]) => {
        this.setCanvasContainer();

        this.images = {}; // Make sure to clear before extracting images for a new report
        const displayObjects = this.getDisplayObjects(this.canvasContainer!, ...componentNames);
        for (const [name, displayObject] of Object.entries(displayObjects)) {
            const bounds = displayObject.getBounds();
            const canvas = this.extract.canvas(displayObject, bounds);
            this.images[name] = new Image();
            this.images[name].src = canvas.toDataURL ? canvas.toDataURL("png") : "";
            this.images[name].className = name;
            this.images[name].setAttribute("style", "background-color:white");
        }

        if (componentNames.every((c) => this.images[c] === undefined || this.images[c] === null))
            throw new Error("No images were extracted");
    };

    private getDisplayObjects = (container: Pixi.Container, ...componentNames: ComponentName[]) => {
        let displayObjects: { [name: string]: Pixi.DisplayObject } = {};
        let componentName = componentNames.find((c) => container.name?.includes(c));
        if (componentName) {
            displayObjects[container.name ?? componentName] =  container;
        } else if (container.children.length > 0 && componentNames.some((c) => displayObjects[c] === undefined)) {
            for (const child of container.children) {
                displayObjects = {
                    ...displayObjects,
                    ...this.getDisplayObjects(child as Pixi.Container, ...componentNames),
                };
            }
        }

        return displayObjects;
    };

    private setCanvasContainer = () => {
        if (!this.canvasContainer) {
            const documentGraphics = this.app.stage.children[1] as Pixi.Container;
            this.canvasContainer = documentGraphics;
        }
    };

    public static Initialize(app: Pixi.Application) {
        if (this._canvasExtractor === null) {
            this._canvasExtractor = new _CanvasExtractor(app);
        }

        return this._canvasExtractor;
    }

    public static GetInstance() {
        return this._canvasExtractor;
    }
}
export const CanvasExtractor = _CanvasExtractor.GetInstance()!;
export const initializeCanvasExtractor = (app: Pixi.Application) => _CanvasExtractor.Initialize(app);
export const extractImages = (...componentNames: ComponentName[]) =>
    _CanvasExtractor.GetInstance()!.extractImages(...componentNames);
export const getImages = () => _CanvasExtractor.GetInstance()!.getImages();
export const getImage = (componentName: string) => _CanvasExtractor.GetInstance()!.getImage(componentName);

export const removeOffscreenElements = () => {
    let imageContainer = document.querySelector("#rab-report-images");
    if (imageContainer) {
        for (let i = imageContainer.children.length - 1; i > -1; i--) {
            let image = imageContainer.children[i] as Node;
            if (imageContainer.children[i].id !== "cable-trunk-length-table" || "") imageContainer.removeChild(image);
        }
    }
};

import { forwardRef } from "react";
import { ICanvasHandle, ICanvasProps } from "../../../../../../models/overlay/polarity/polarity-diagram/canvas";
import { withMemoization } from "../../../../../pixi/memoization/memoization";
import { useCanvas } from "./canvas.hooks";

export const Canvas = withMemoization(
    forwardRef<ICanvasHandle, ICanvasProps>((props, ref) => {
        const { canvasRef, width, height, canvasWidth, canvasHeight, containerRef, onWheel, onMouseMove, onMouseDown, onMouseUp } = useCanvas(
            props,
            ref
        );
        return (
            <div className="canvas-container" ref={containerRef}>
                <canvas
                    ref={canvasRef}
                    width={canvasWidth}
                    height={canvasHeight}
                    onWheel={onWheel}
                    onMouseMove={onMouseMove}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    style={{ width, height }}
                />
            </div>
        );
    })
);

import React from "react";
import { useTriggerColorManagement } from "./trigger-color-management.hooks";
import { CollapsibleDialog } from "../../../ui/dialog/collapsible-dialog/collapsible-dialog";
import ReactDOM from "react-dom";
import { ColorDialog } from "../../../ui/dialog/color-dialog/color-dialog";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { getConnnectorSVG } from "../../../pixi/factories/texture";
import { ColorButton } from "../../../ui/color-button";
import { GenericDialog } from "../../../ui/dialog/generic-dialog";
import { OutlinedButton, ContainedButton } from "@corning-ctcm/silica-react";

import "../../../../css/overlay/reports/trigger-color-management.scss";

export const TriggerColorManagement: React.FC = () => {
    const {
        dialogProps,
        colorDialogProps,
        tableProps,
        selectedTrigger,
        onTriggerClick,
        cancelProps,
        applyProps,
        warningDialogProps,
    } = useTriggerColorManagement();
    if (!dialogProps.display) {
        return null;
    }
    return (
        <CollapsibleDialog {...dialogProps}>
            {ReactDOM.createPortal(<ColorDialog {...colorDialogProps} />, document.body)}
            <div className="table-container">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {tableProps.header.map((c, i) => (
                                <TableCell key={`column-${i}`} className={c.className} align="right">
                                    {c.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableProps.body.map((r, i) => (
                            <TableRow key={`row-${i}`}>
                                <TableCell align="right">{r.groupPosition}</TableCell>
                                <TableCell className="connector-cell" align="right">
                                    {getConnnectorSVG(r.connectorType, r.color.hex, 0.1)}
                                </TableCell>
                                <TableCell align="right">
                                    <div id={`group-${r.groupPosition}-color-button`}>
                                        <ColorButton
                                            color={r.color}
                                            selected={selectedTrigger?.groupPosition === r.groupPosition}
                                            onClick={() => onTriggerClick(r)}
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <div className="trigger-footer">
                <OutlinedButton id="trigger-management-cancel-button" {...cancelProps.buttonProps}>
                    {cancelProps.label}
                </OutlinedButton>
                <ContainedButton id="trigger-management-apply-button" {...applyProps.buttonProps}>
                    {applyProps.label}
                </ContainedButton>
            </div>
            <GenericDialog {...warningDialogProps} />
        </CollapsibleDialog>
    );
};

import { AlertPalettes, SelectProps, TextFieldProps } from "@corning-ctcm/silica-react";
import { AutocompleteChangeReason } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../locales/keys";
import { WARNING_BUNDLE_COUNT_COLLAPSE_THRESHOLD } from "../../../../../models/overlay/header/collapse-options";
import {
    IUnitOfMeasure,
    Units,
    convertTo,
    decimalPlacesBasedOnUnit,
    getDecimalPlaces,
    getUnitsName,
    roundToDecimalBasedOnUnit,
    roundToDecimalPoint,
} from "../../../../../models/overlay/header/units-of-measure";
import {
    BLengthType,
    IBLengthProps,
} from "../../../../../models/overlay/wizard/setup/b-length-section/b-length-section";
import { IBundleCountProps } from "../../../../../models/overlay/wizard/setup/bundle-count";
import { IMeshProps, MeshColors } from "../../../../../models/overlay/wizard/setup/mesh-properties";
import {
    SourceFieldProps,
    getValidationErrorField,
    isValidSourceSetup,
} from "../../../../../models/overlay/wizard/setup/source-setup";
import { IStaggerAutoCompleteProps } from "../../../../../models/overlay/wizard/setup/stagger-autocomplete";
import { IColor, Yellow } from "../../../../../models/ui/dialog/color-dialog";
import { unitsOfMeasureContainerUnitSelector } from "../../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { setNotification } from "../../../../../store/overlay/notification/notification.reducers";
import {
    ISourceSetupState,
    hasSourceChanges,
} from "../../../../../store/overlay/wizard/setup/source-setup/source-setup";
import {
    SourceSetupContext,
    setState,
} from "../../../../../store/overlay/wizard/setup/source-setup/source-setup.reducers";
import {
    getConnectorFamilyString,
    getConnectorTypeFromDrop,
    validateStaggerValue,
    validateWholeNumber,
} from "../../../../../store/overlay/wizard/wizard";
import {
    enableNextStep,
    saveSourceSetup,
    setFocusOnError,
    setIsDynamicUpdateDisabled,
} from "../../../../../store/overlay/wizard/wizard.reducers";
import { wizardFocusOnErrorSelector, wizardSelector } from "../../../../../store/overlay/wizard/wizard.selectors";
import { ViewportStatus } from "../../../../../store/pixi/viewport/viewport";
import { viewportSelector } from "../../../../../store/pixi/viewport/viewport.selectors";
import { IRange } from "../../../../../store/workspace/boundaries/boundaries";
import {
    boundariesOptionsSelector,
    bundleCountRangeSelector,
    currentBuildBoundariesSelector,
    defaultMeshColorsSelector,
    distributionOptionsSelector,
    feederOptionsSelector,
    meshColorSelector,
    pullingGripsSelector,
} from "../../../../../store/workspace/boundaries/boundaries.selectors";
import {
    currentAvailabilitySelector,
    dropsSelector,
    overallLengthSelector,
    selectedDropSelector,
} from "../../../../../store/workspace/build.selectors";
import { setSourceEnabled, setupSource } from "../../../../../store/workspace/build/build.reducers";
import { buildDisabledSelector } from "../../../../../store/workspace/build/build.selector";
import { initialMeshOffset } from "../../../../../store/workspace/build/drop";
import { validateValue } from "../../wizard.hooks";
import { hasCustomLengthChanges, isValidBLengths } from "../b-length-section/custom-b-length/custom-b-length.hooks";
import { useReverseStaggering } from "../reverse-staggering/reverse-staggering.hooks";
import { RestrictedConnectorTypes } from "../../../../pixi/factories/texture";

export const useSourceSetup = () => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(SourceSetupContext);
    const { meshOffsetRange, staggerRange, legRange, groupCounts } = useSelector(feederOptionsSelector);
    const { savedBuildData } = useSelector(wizardSelector);
    const { context } = useSelector(viewportSelector);
    const distributionOptions = useSelector(distributionOptionsSelector);
    const disabled = useSelector(buildDisabledSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const drops = useSelector(dropsSelector);
    const selectedDrop = useSelector(selectedDropSelector);
    const drop = selectedDrop ?? drops[0];
    const bundleCountRange = useSelector(bundleCountRangeSelector);
    const availability = useSelector(currentAvailabilitySelector);

    const storeDispatch = useDispatch();

    useEffect(() => {
        let isValid = isValidSourceSetup(state);
        let hasChanges = hasSourceChanges(savedBuildData, state);
        if (drop?.customBLength) {
            hasChanges = hasChanges || hasCustomLengthChanges(drop, savedBuildData);
            isValid = isValid && isValidBLengths(drop, legRange);
        }
        storeDispatch(enableNextStep(context === ViewportStatus.Editing ? isValid && hasChanges : isValid));
    }, [context, drop, legRange, savedBuildData, state, storeDispatch]);

    useEffect(() => {
        if (!availability?.sourceEnabled) {
            storeDispatch(setSourceEnabled(true));
        }
    }, [availability?.sourceEnabled, storeDispatch]);

    const validateMeshOffset = useCallback(
        (newState: ISourceSetupState): ISourceSetupState => {
            let isMeshOffsetValid = true;
            let meshOffsetErrorText = "";

            if (!newState.mesh)
                // unchecked Add Mesh checkbox
                return {
                    ...newState,
                    isMeshOffsetValid,
                    meshOffsetErrorText,
                };

            const meshOffsetField = newState.meshOffsetField ?? "";
            const meshOffsetValue = newState.meshOffset?.value ?? 0;
            const reverseStaggering = newState.reverseStaggering;
            const customBLength = drop.customBLength ?? false;
            const groups = drop.groups ?? [];
            const staggerCount = groups.length - 1;
            const staggerValue = newState.sLength.value;
            const staggerTotalLength = staggerValue * staggerCount;
            const lowBLength = customBLength
                ? Math.min(...groups.map((g) => g.lengthB?.value!))
                : newState.bLength.value;
            const lowDropBLength = reverseStaggering ? lowBLength - staggerTotalLength : lowBLength;
            const lowMeshLength = lowDropBLength - meshOffsetValue;
            const maxMeshOffset = Math.min(meshOffsetRange.max, lowDropBLength);
            const minMeshOffset = meshOffsetRange.min;

            if (meshOffsetField.length === 0) {
                meshOffsetErrorText = t(LocalizationKeys.MissingValue);
                isMeshOffsetValid = false;
            } else if (lowMeshLength < 0) {
                meshOffsetErrorText = t(LocalizationKeys.MeshOffsetBLengthError);
                isMeshOffsetValid = false;
            } else if (meshOffsetValue < minMeshOffset) {
                meshOffsetErrorText = `${t(LocalizationKeys.ValueLesserThan, {
                    value: roundToDecimalPoint(minMeshOffset, 4, false),
                })} ${unit}`;
                isMeshOffsetValid = false;
            } else if (meshOffsetValue > maxMeshOffset) {
                meshOffsetErrorText = `${t(LocalizationKeys.ValueGreaterThan, {
                    value: roundToDecimalPoint(maxMeshOffset, 4, false),
                })} ${unit}`;
                isMeshOffsetValid = false;
            }

            return {
                ...newState,
                isMeshOffsetValid,
                meshOffsetErrorText,
            };
        },
        [drop.customBLength, drop.groups, meshOffsetRange.max, meshOffsetRange.min, t, unit]
    );

    const validateSLength = useCallback(
        (newState: ISourceSetupState): ISourceSetupState => {
            const sLengthRange = staggerRange;
            const bLengthRange = legRange;
            const bLengthValue = newState.bLength?.value ?? 0;
            const groupCount = newState.groupCount ?? 1;
            const reverseStaggering = newState.reverseStaggering;
            const isSLengthEnabled = groupCount > 1;

            let sLengthInputValue = newState.sLengthInputValue ?? "";
            sLengthInputValue = isSLengthEnabled ? sLengthInputValue : "0";

            const value = sLengthInputValue ? Number.parseFloat(sLengthInputValue) : 0;
            const staggerArgs = {
                bLengthRange,
                bLengthValue,
                groupCount,
                sLengthRange,
                sLengthValue: value,
                reverseStaggering,
                unit,
            };
            let { valid: isSLengthValid, sLengthErrorText } = validateStaggerValue(staggerArgs);

            const bLengthMin = value * (groupCount - 1);
            if (reverseStaggering && +(newState.bLengthField ?? "") < bLengthMin) {
                sLengthErrorText = `${t(LocalizationKeys.MeshOffsetBLengthError)}`;
                isSLengthValid = false;
            }

            return {
                ...newState,
                isSLengthValid,
                sLengthErrorText,
                sLengthInputValue,
                isSLengthEnabled,
            };
        },
        [legRange, staggerRange, t, unit]
    );

    const validateBLength = useCallback(
        (newState: ISourceSetupState): ISourceSetupState => {
            let isBLengthValid = true;
            let bLengthErrorText = "";

            if (!newState.isGroupCountValid)
                return {
                    ...newState,
                    isBLengthValid,
                    bLengthErrorText,
                };

            const bLengthField = newState.bLengthField ?? "";
            const bLengthValue = newState.bLength.value;
            const mesh = newState.mesh;
            const meshOffsetValue = mesh ? newState.meshOffset?.value ?? 0 : 0;
            const staggerCount = drop.groups.length - 1;
            const staggerValue = newState.sLength.value;
            const staggerTotalLength = staggerValue * staggerCount;
            const legRange = distributionOptions.legRange;
            const reverseStaggering = newState.reverseStaggering;
            const minExpectedValue = reverseStaggering ? staggerTotalLength + meshOffsetValue + 1 : meshOffsetValue + 1;
            const minBLength = Math.max(legRange.min, minExpectedValue);

            if (bLengthField.length === 0) {
                bLengthErrorText = t(LocalizationKeys.MissingValue);
                isBLengthValid = false;
            } else if (bLengthValue < minBLength) {
                bLengthErrorText = `${t(LocalizationKeys.ValueLesserThan, {
                    value: roundToDecimalPoint(minBLength, 4, false),
                })} ${unit}`;
                isBLengthValid = false;
            } else if (bLengthValue > legRange.max) {
                bLengthErrorText = `${t(LocalizationKeys.ValueGreaterThan, {
                    value: roundToDecimalPoint(legRange.max, 4, false),
                })} ${unit}`;
                isBLengthValid = false;
            }

            return {
                ...newState,
                isBLengthValid,
                bLengthErrorText,
            };
        },
        [distributionOptions.legRange, drop.groups.length, unit, t]
    );

    const validateGroupCount = useCallback(
        (newState: ISourceSetupState): ISourceSetupState => {
            const groupCountField = newState.groupCountField ?? "";
            const groupCount = Number(groupCountField);
            const groupCountRange = groupCounts;
            const { bundleCount } = newState;
            let isGroupCountValid = true;
            let groupCountErrorText = "";
            if (groupCountField.length === 0) {
                groupCountErrorText = t(LocalizationKeys.MissingValue);
                isGroupCountValid = false;
            } else if (groupCount < groupCountRange.min) {
                groupCountErrorText = t(LocalizationKeys.ValueLesserThan, { value: groupCountRange.min });
                isGroupCountValid = false;
            } else if (groupCount > bundleCount) {
                groupCountErrorText = t(LocalizationKeys.ValueGreaterThan, { value: bundleCount });
                isGroupCountValid = false;
            }

            return {
                ...newState,
                groupCount,
                isGroupCountValid,
                groupCountErrorText,
                groupCountField,
            };
        },
        [groupCounts, t]
    );

    const validateFiberCountPerConnector = useCallback(
        (newState: ISourceSetupState) => {
            const fiberCountPerConnectorField = newState.fiberCountPerConnectorField ?? "";
            if (!validateWholeNumber(fiberCountPerConnectorField)) return newState;

            const fiberCountPerConnector = Number(fiberCountPerConnectorField);
            let isFiberCountPerConnectorValid = true;
            let fiberCountPerConnectorErrorText = "";
            if (fiberCountPerConnectorField.length === 0 || fiberCountPerConnector === 0) {
                fiberCountPerConnectorErrorText = t(LocalizationKeys.MissingValue);
                isFiberCountPerConnectorValid = false;
            } else if (fiberCountPerConnector > newState.connectorType.fiberCount) {
                fiberCountPerConnectorErrorText = t(LocalizationKeys.ValueGreaterThan, {
                    value: newState.connectorType.fiberCount,
                });
                isFiberCountPerConnectorValid = false;
            }

            return {
                ...newState,
                fiberCountPerConnector,
                fiberCountPerConnectorField,
                isFiberCountPerConnectorValid,
                fiberCountPerConnectorErrorText,
            };
        },
        [t]
    );

    const validateBundleCount = useCallback(
        (newState: ISourceSetupState): ISourceSetupState => {
            const bundleCountInputValue = newState.bundleCountInputValue ?? "";
            if (!validateWholeNumber(bundleCountInputValue)) return newState;
            const [feeder, ...distribution] = drops;
            const feederConnectorType = getConnectorTypeFromDrop(feeder);
            const distributionConnectorType = getConnectorTypeFromDrop(distribution[0]);

            let min = bundleCountRange.min;
            // Because we allow different connector combinations, we have to readjust the min value for the bundle count
            if (feederConnectorType.type !== distributionConnectorType.type) {
                const feederFiberCount = feederConnectorType.fiberCountInUse ?? feederConnectorType.fiberCount;
                const distributionFiberCount = distributionConnectorType.fiberCountInUse 
                ?? distributionConnectorType.fiberCount;
                min = distributionFiberCount > feederFiberCount 
                    ? distributionFiberCount / feederFiberCount
                    : feederFiberCount / distributionFiberCount;
                min = Math.ceil(min);
            }

            const bundleCount = Number(bundleCountInputValue);
            let isBundleCountFieldValid = true;
            let bundleCountErrorText = "";
            if (bundleCountInputValue.length === 0) {
                bundleCountErrorText = t(LocalizationKeys.MissingValue);
                isBundleCountFieldValid = false;
            } else if (bundleCount < min) {
                bundleCountErrorText = t(LocalizationKeys.ValueLesserThan, { value: min });
                isBundleCountFieldValid = false;
            } else if (bundleCount > bundleCountRange.max) {
                bundleCountErrorText = t(LocalizationKeys.ValueGreaterThan, { value: bundleCountRange.max });
                isBundleCountFieldValid = false;
            }

            return {
                ...newState,
                bundleCount,
                bundleCountInputValue,
                isBundleCountFieldValid,
                bundleCountErrorText,
            };
        },
        [bundleCountRange, drops, t]
    );

    const applyChanges = useCallback(
        (newState: ISourceSetupState): ISourceSetupState => {
            if (isValidSourceSetup(newState)) {
                storeDispatch(setupSource(newState));
                storeDispatch(saveSourceSetup(newState));
                storeDispatch(setIsDynamicUpdateDisabled(false));
            } else {
                storeDispatch(setIsDynamicUpdateDisabled(true));
            }

            return newState;
        },
        [storeDispatch]
    );

    const validate = useCallback(
        (newState: ISourceSetupState): ISourceSetupState => {
            newState = validateFiberCountPerConnector(newState);
            newState = validateBundleCount(newState);
            newState = validateGroupCount(newState);
            newState = validateSLength(newState);
            newState = validateBLength(newState);
            newState = validateMeshOffset(newState);
            return { ...newState };
        },
        [
            validateBLength,
            validateFiberCountPerConnector,
            validateBundleCount,
            validateGroupCount,
            validateMeshOffset,
            validateSLength,
        ]
    );

    const { bundleCountRef, groupCountRef, meshOffsetRef, bLengthRef, sLengthRef, fiberCountPerConnectorRef } =
        useFocusOnError(state);

    const fieldProps: SourceFieldProps = { disabled, validate, applyChanges };
    const mode = useMode(fieldProps);
    const connectorType = useConnectorType(fieldProps);
    const fiberCountPerConnectorProps = useFiberCountPerConnector({
        ...fieldProps,
        inputRef: fiberCountPerConnectorRef,
    });
    const bundleCountProps = useBundleCount({ ...fieldProps, inputRef: bundleCountRef });
    const fiberType = useFiberType(fieldProps);
    const cableOuterDiameter = useCableOuterDiameter(fieldProps);
    const groupProps = useGroups({ ...fieldProps, inputRef: groupCountRef });
    const cpgProps = useCPG();
    const meshProps = useMesh({ ...fieldProps, inputRef: meshOffsetRef });
    const overallLengthTypeProps = useOverallLengthTypeProps(fieldProps);
    const overallLengthProps = useOverallLength();
    const { onBLengthTypeChange } = useBLengthType(fieldProps);
    const bLengthTypeProps: IBLengthProps = {
        drop,
        disabled,
        onChange: onBLengthTypeChange,
    };
    const bLengthProps = useBLength({ ...fieldProps, inputRef: bLengthRef });
    const sLengthProps = useSLength({ ...fieldProps, inputRef: sLengthRef });
    const pullingGrip = usePullingGrip(fieldProps);
    const reverseStaggeringProps = useReverseStaggering({
        ...fieldProps,
        dispatch,
        state,
        setState,
    });

    return {
        disabled,
        mode,
        connectorType,
        fiberCountPerConnectorProps,
        bundleCountProps,
        fiberType,
        cableOuterDiameter,
        groupProps,
        cpgProps,
        meshProps,
        overallLengthTypeProps,
        overallLengthProps,
        bLengthTypeProps,
        bLengthProps,
        sLengthProps,
        pullingGrip,
        reverseStaggeringProps,
    };
};

const useMode = ({ validate }: SourceFieldProps) => {
    const { state, dispatch } = useContext(SourceSetupContext);
    const { context } = useSelector(viewportSelector);
    const { t } = useTranslation();
    const { id: currentModeId } = useSelector(currentBuildBoundariesSelector);
    const options = useSelector(boundariesOptionsSelector);

    const storeDispatch = useDispatch();

    useEffect(() => {
        if (currentModeId !== state.modeId) {
            dispatch(setState(validate({ ...state, modeId: currentModeId })));
        }
    }, [state, currentModeId, validate, dispatch, storeDispatch]);

    const onModeChange = useCallback(
        (e: React.ChangeEvent<any>) => {
            const modeId = e.target.value;
            const mode = options.find((d) => d.id === modeId);
            if (mode) {
                const connectorTypes = mode.data.feederEndConnectorTypes;
                const connectorType =
                    connectorTypes.find((c) => c.type === state.connectorType.type) ?? connectorTypes[0];
                dispatch(setState({ ...state, connectorType }));
                storeDispatch(setupSource({ ...state, connectorType, modeId }));
            }
        },
        [state, options, dispatch, storeDispatch]
    );

    const modeDisabled = context === ViewportStatus.Editing;
    const modeProps: SelectProps = {
        id: "mode-type-dropdown",
        palette: "primary",
        className: "field",
        label: t(LocalizationKeys.Mode),
        value: state.modeId,
        units: "",
        helperText: "",
        disabled: modeDisabled,
        onChange: onModeChange,
    };

    return {
        props: modeProps,
        options: options.map((b) => ({ value: b.id, name: b.name })),
    };
};

const useConnectorType = ({ disabled, validate, applyChanges }: SourceFieldProps) => {
    const { state, dispatch } = useContext(SourceSetupContext);
    const { t } = useTranslation();
    const { context } = useSelector(viewportSelector);
    const { connectorTypes } = useSelector(feederOptionsSelector);

    const filterdConnectorTypes = useMemo(() => {
        if (context === "editing") {
            return state.connectorType.fiberCount === state.fiberCountPerConnector
                ? connectorTypes.filter(
                      (c) => getConnectorFamilyString(c) === getConnectorFamilyString(state.connectorType)
                  )
                : [state.connectorType];
        }
        return connectorTypes;
    }, [connectorTypes, context, state.connectorType, state.fiberCountPerConnector]);

    const onConnectorTypeChange = useCallback(
        (e: React.ChangeEvent<any>): void => {
            const target = e.target as HTMLSelectElement | HTMLOptionElement;
            const currentValue = state.connectorType.key;
            if (currentValue === target.value) return;

            const connectorType = connectorTypes.find((x) => x.type === target.value);
            if (connectorType) {
                const newState: ISourceSetupState = validate({
                    ...state,
                    connectorType,
                    fiberCountPerConnector: connectorType.fiberCount,
                    fiberCountPerConnectorField: connectorType.fiberCount.toString(),
                    connectorTypeChanged: true,
                });
                dispatch(setState(applyChanges(newState)));
            }
        },
        [connectorTypes, state, validate, applyChanges, dispatch]
    );

    const connectorTypeDisabled = disabled || filterdConnectorTypes.length <= 1;
    const connectorTypeProps: SelectProps = {
        id: "connector-type-dropdown",
        palette: "primary",
        className: "field",
        label: t(LocalizationKeys.ConnectorType),
        value: state.connectorType.type,
        disabled: connectorTypeDisabled,
        units: "",
        helperText: "",
        onChange: onConnectorTypeChange,
    };

    return {
        props: connectorTypeProps,
        options: filterdConnectorTypes,
    };
};

const useFiberCountPerConnector = ({ disabled, validate, applyChanges, inputRef }: SourceFieldProps) => {
    const { state, dispatch } = useContext(SourceSetupContext);
    const { t } = useTranslation();
    const { context } = useSelector(viewportSelector);

    const onFiberCountPerConnectorChange = useCallback(
        (e: React.FormEvent<any>): void => {
            const fiberCountPerConnectorField = e.currentTarget.value;
            if (!validateWholeNumber(fiberCountPerConnectorField)) return;

            const fiberCountPerConnector = Number(fiberCountPerConnectorField);
            const newState: ISourceSetupState = validate({
                ...state,
                fiberCountPerConnectorField,
                fiberCountPerConnector,
            });
            dispatch(setState(applyChanges(newState)));
        },
        [state, validate, applyChanges, dispatch]
    );

    const isRestricted = RestrictedConnectorTypes.findIndex((type) => type === state.connectorType.type) !== -1;
    const fiberCountPerConnectorDisabled = disabled || context === "editing" || isRestricted;
    const fiberCountPerConnectorProps: TextFieldProps = {
        id: "fiber-count-per-connector-field",
        palette: "primary",
        type: "number",
        disabled: fiberCountPerConnectorDisabled,
        label: t(LocalizationKeys.FiberCountPerConnector),
        helperText: state.fiberCountPerConnectorErrorText,
        error: !state.isFiberCountPerConnectorValid,
        value: state.fiberCountPerConnectorField ?? "",
        inputRef,
        onChange: onFiberCountPerConnectorChange,
    };

    return fiberCountPerConnectorProps;
};

const useBundleCount = ({ disabled, validate, applyChanges, inputRef }: SourceFieldProps) => {
    const { state, dispatch } = useContext(SourceSetupContext);
    const { t } = useTranslation();
    const storeDispatch = useDispatch();

    const onBundleCountChange = useCallback(
        (e: React.ChangeEvent<any>, bundleCountInputValue: string, range: IRange) => {
            if (!validateWholeNumber(bundleCountInputValue)) return;

            const bundleCount = Number(bundleCountInputValue);
            const newState: ISourceSetupState = validate({
                ...state,
                bundleCountInputValue,
                bundleCountField: [bundleCountInputValue],
                bundleCount,
            });

            if (newState.bundleCount > WARNING_BUNDLE_COUNT_COLLAPSE_THRESHOLD) {
                const message = t(LocalizationKeys.KeepAssemblyCollapsedWarning);
                storeDispatch(setNotification({ palette: AlertPalettes.warning, message }));
            }

            dispatch(setState(applyChanges(newState)));
        },
        [validate, state, dispatch, applyChanges, storeDispatch, t]
    );

    const bundleProps: IBundleCountProps = {
        value: state.bundleCountField ?? [state.bundleCount.toString()],
        inputValue: state.bundleCountInputValue ?? state.bundleCount.toString(),
        connectorFiberCount: state.connectorType.fiberCount,
        onChange: onBundleCountChange,
        isValid: !!state.isBundleCountFieldValid,
        error: state.bundleCountErrorText ?? "",
        disabled: !!disabled,
        inputRef,
    };

    return bundleProps;
};

const useFiberType = ({ disabled, applyChanges }: SourceFieldProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(SourceSetupContext);
    const { fiberTypes } = useSelector(feederOptionsSelector);
    const defaultMeshColors = useSelector(defaultMeshColorsSelector);

    useEffect(() => {
        if (state.fiberType?.length === 0) {
            dispatch(setState(applyChanges({ ...state, fiberType: fiberTypes[0].id })));
        }
    }, [applyChanges, dispatch, fiberTypes, state]);

    const onFiberTypesChange = useCallback(
        (e: React.ChangeEvent<any>) => {
            const fiberType = e.target.value;
            const meshColor = MeshColors.find((c) => c.id === defaultMeshColors[fiberType])?.name ?? Yellow.name;
            dispatch(setState(applyChanges({ ...state, fiberType, meshColor })));
        },
        [defaultMeshColors, state, applyChanges, dispatch]
    );

    const fiberTypeDisabled = fiberTypes.length <= 1 || disabled;
    const fiberTypeProps: SelectProps = {
        id: "fiber-type-dropdown",
        palette: "primary",
        className: "field",
        label: t(LocalizationKeys.FiberType),
        value: state.fiberType ?? "",
        disabled: fiberTypeDisabled,
        units: "",
        helperText: "",
        onChange: onFiberTypesChange,
    };

    return {
        props: fiberTypeProps,
        options: fiberTypes,
    };
};

const useCableOuterDiameter = ({ disabled, applyChanges }: SourceFieldProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(SourceSetupContext);
    const { cableOuterDiameters } = useSelector(feederOptionsSelector);

    const onCableOuterDiameterChange = useCallback(
        (e: React.ChangeEvent<any>) => {
            dispatch(setState(applyChanges({ ...state, cableOuterDiameter: e.target.value })));
        },
        [state, applyChanges, dispatch]
    );

    const cableOuterDiameterDisabled = cableOuterDiameters.length <= 1 || disabled;
    const cableOuterDiameterProps: SelectProps = {
        id: "outer-diameter-dropdown",
        palette: "primary",
        className: "field",
        label: t(LocalizationKeys.CableOuterDiameter),
        value: state.cableOuterDiameter ?? "",
        disabled: cableOuterDiameterDisabled,
        units: Units.UoMMillimeters,
        helperText: "",
        onChange: onCableOuterDiameterChange,
    };

    return {
        props: cableOuterDiameterProps,
        options: cableOuterDiameters,
    };
};

const useGroups = ({ disabled, validate, applyChanges, inputRef }: SourceFieldProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(SourceSetupContext);

    const onGroupCountChange = useCallback(
        (e: React.FormEvent<any>): void => {
            const value = e.currentTarget.value;
            if (!validateWholeNumber(value)) return;

            const newState: ISourceSetupState = validate({
                ...state,
                groupCountField: value,
                groupCount: Number(value),
            });
            dispatch(setState(applyChanges(newState)));
        },
        [state, validate, applyChanges, dispatch]
    );

    const groupProps: TextFieldProps = {
        id: "groups-field",
        palette: "primary",
        type: "number",
        disabled,
        label: t(LocalizationKeys.NbGroups),
        helperText: state.groupCountErrorText,
        error: !state.isGroupCountValid,
        value: state.groupCountField ?? "",
        onChange: onGroupCountChange,
        inputRef,
    };

    return groupProps;
};

const useCPG = () => {
    const { t } = useTranslation();
    const { state } = useContext(SourceSetupContext);

    const cpgValue = state.groupCount ? Math.floor(state.bundleCount / state.groupCount) : 0;
    const cpgProps: TextFieldProps = {
        id: "cpg-field",
        palette: "primary",
        disabled: true,
        helperText: "",
        label: t(LocalizationKeys.NbCPG),
        value: cpgValue,
    };

    return cpgProps;
};

const useMesh = ({ disabled, validate, applyChanges, inputRef }: SourceFieldProps) => {
    const { state, dispatch } = useContext(SourceSetupContext);
    const { name: defaultMeshColor } = useSelector(meshColorSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const maxDecimalPlaces = decimalPlacesBasedOnUnit(unit);

    const onMeshCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.currentTarget.checked;
            let newState: ISourceSetupState = { ...state, mesh: checked };
            if (checked) {
                const defaultMeshOffset = convertTo(initialMeshOffset, unit);
                newState = validate({
                    ...newState,
                    mesh: checked,
                    meshOffsetField: roundToDecimalBasedOnUnit(defaultMeshOffset.value, unit),
                    meshOffset: defaultMeshOffset,
                    meshColor: defaultMeshColor,
                });
            } else {
                newState = validate({
                    ...newState,
                    mesh: checked,
                    meshColor: undefined,
                    meshOffset: undefined,
                    meshOffsetErrorText: "",
                    isMeshOffsetValid: true,
                });
            }

            dispatch(setState(applyChanges(newState)));
        },
        [state, unit, defaultMeshColor, validate, applyChanges, dispatch]
    );

    const onMeshOffsetChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;
            if (!validateValue(value, maxDecimalPlaces)) return;

            const newState: ISourceSetupState = validate({
                ...state,
                meshOffsetField: value,
                meshOffset: { value: Number(value), unit },
            });
            dispatch(setState(applyChanges(newState)));
        },
        [maxDecimalPlaces, state, unit, validate, applyChanges, dispatch]
    );

    const onMeshColorChange = useCallback(
        (color: IColor) => {
            dispatch(setState(applyChanges({ ...state, meshColor: color.name })));
        },
        [state, applyChanges, dispatch]
    );

    useEffect(() => {
        if (state.meshOffset && state.meshOffset.unit !== unit) {
            const convertedMeshOffset: IUnitOfMeasure = convertTo(state.meshOffset, unit);
            const newState: ISourceSetupState = validate({
                ...state,
                meshOffsetField: roundToDecimalBasedOnUnit(convertedMeshOffset.value, unit),
                meshOffset: convertedMeshOffset,
            });
            dispatch(setState(newState));
        }
    }, [state, unit, validate, dispatch]);

    const meshProps: IMeshProps = {
        mesh: state.mesh,
        onCheckboxChange: onMeshCheckboxChange,
        offset: {
            value: state.meshOffsetField ?? "",
            helperText: state.meshOffsetErrorText,
            isValid: !!state.isMeshOffsetValid,
            onChange: onMeshOffsetChange,
            units: getUnitsName(unit, false, true),
            inputRef,
        },
        color: {
            value: state.meshColor ?? defaultMeshColor,
            disabled,
            onChange: onMeshColorChange,
        },
        disabled,
    };

    return meshProps;
};

const useOverallLengthTypeProps = ({ disabled, applyChanges }: SourceFieldProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(SourceSetupContext);

    const onOverallLengthTypeChange = useCallback(
        (e: React.ChangeEvent<any>) => {
            dispatch(setState(applyChanges({ ...state, overallLengthType: e.target.value })));
        },
        [state, applyChanges, dispatch]
    );

    const overallLengthTypes = [
        { key: "furcation", description: t(LocalizationKeys.OverallLengthTypeFurcation) },
        { key: "connector", description: t(LocalizationKeys.OverallLengthTypeConnector) },
    ];

    const overallLengthTypeProps: SelectProps = {
        id: "overall-length-type-dropdown",
        palette: "primary",
        className: "field",
        label: t(LocalizationKeys.OverallLengthTypeLabel),
        value: state.overallLengthType ?? "furcation",
        disabled: disabled,
        units: "",
        helperText: "",
        onChange: onOverallLengthTypeChange,
    };

    return {
        props: overallLengthTypeProps,
        options: overallLengthTypes,
    };
};

const useOverallLength = () => {
    const { t } = useTranslation();
    const { value, unit } = useSelector(overallLengthSelector);

    const overallLengthProps: TextFieldProps = {
        id: "a-length-field",
        palette: "primary",
        type: "number",
        disabled: true,
        label: t(LocalizationKeys.OverallLength),
        value: value,
        units: getUnitsName(unit, false, true),
    };

    return overallLengthProps;
};

const useBLengthType = ({ applyChanges }: SourceFieldProps) => {
    const { state, dispatch } = useContext(SourceSetupContext);

    const onBLengthTypeChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const bLengthType = e.currentTarget.value as BLengthType;
            dispatch(setState(applyChanges({ ...state, bLengthType })));
        },
        [dispatch, applyChanges, state]
    );

    return { onBLengthTypeChange };
};

const useBLength = ({ disabled, validate, applyChanges, inputRef }: SourceFieldProps) => {
    const { state, dispatch } = useContext(SourceSetupContext);
    const { t } = useTranslation();
    const { legRange } = useSelector(distributionOptionsSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const maxDecimalPlaces = decimalPlacesBasedOnUnit(unit);

    const onBLengthChange = useCallback(
        (e: React.ChangeEvent<any>): void => {
            const value = e.currentTarget.value;
            const maxCharLength = legRange.max.toString().length;
            if (!validateValue(value, maxDecimalPlaces, maxCharLength)) return;

            const newState: ISourceSetupState = validate({
                ...state,
                bLengthField: value,
                bLength: { value: Number(value), unit },
            });
            dispatch(setState(applyChanges(newState)));
        },
        [maxDecimalPlaces, legRange.max, unit, state, validate, applyChanges, dispatch]
    );

    useEffect(() => {
        if (state.bLength.unit !== unit) {
            const convertedBLength: IUnitOfMeasure = convertTo(state.bLength, unit);
            const newState: ISourceSetupState = validate({
                ...state,
                bLengthField: roundToDecimalBasedOnUnit(convertedBLength.value, unit),
                bLength: convertedBLength,
            });
            dispatch(setState(newState));
        }
    }, [state, unit, validate, dispatch]);

    const bLengthTextField: TextFieldProps = {
        id: "b-length-field",
        palette: "primary",
        type: "number",
        disabled,
        label: t(LocalizationKeys.LengthB, { position: 0 }),
        helperText: state.bLengthErrorText,
        error: !state.isBLengthValid,
        value: state.bLengthField ?? "",
        onChange: onBLengthChange,
        units: getUnitsName(unit, false, true),
        inputRef,
    };

    return {
        textField: bLengthTextField,
    };
};

const useSLength = ({ disabled, validate, applyChanges, inputRef }: SourceFieldProps) => {
    const { state, dispatch } = useContext(SourceSetupContext);
    const { staggerRange, legRange } = useSelector(feederOptionsSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const maxDecimalPlaces = getDecimalPlaces(unit);

    const onStaggerValueChanged = useCallback(
        (e: React.ChangeEvent<any>, value: (string | number)[], reason: AutocompleteChangeReason) => {
            const valueStr = value as unknown as string; // Bug with autocomplete in silica library, value type is string.
            if (state.sLengthField === value || !validateValue(valueStr, maxDecimalPlaces)) return;
            const newState: ISourceSetupState = validate({
                ...state,
                sLengthField: [valueStr],
            });
            dispatch(setState(applyChanges(newState)));
        },
        [state, maxDecimalPlaces, validate, dispatch, applyChanges]
    );

    const onStaggerInputChanged = useCallback(
        (e: React.ChangeEvent<{}>, value: string) => {
            if (state.sLengthInputValue === value || !validateValue(value, maxDecimalPlaces)) return;
            const newState: ISourceSetupState = validate({
                ...state,
                sLengthInputValue: value,
                sLength: { value: Number(value), unit },
            });
            dispatch(setState(applyChanges(newState)));
        },
        [maxDecimalPlaces, unit, state, validate, applyChanges, dispatch]
    );

    useEffect(() => {
        if (state.sLength.unit !== unit) {
            const convertedSLength: IUnitOfMeasure = convertTo(state.sLength, unit);
            const newState: ISourceSetupState = validate({
                ...state,
                sLengthField: [roundToDecimalBasedOnUnit(convertedSLength.value, unit)],
                sLengthInputValue: roundToDecimalBasedOnUnit(convertedSLength.value, unit),
                sLength: convertedSLength,
            });
            dispatch(setState(newState));
        }
    }, [state, unit, validate, dispatch]);

    const staggerOptions = useMemo(() => {
        const sLengthValue = state.sLength.value;
        const sLengthRange = staggerRange;
        const bLengthRange = legRange;
        const bLengthValue = state.bLength.value;
        const groupCount = state.groupCount ?? 0;
        const reverseStaggering = state.reverseStaggering;
        const staggerArgs = {
            bLengthRange,
            bLengthValue,
            groupCount,
            sLengthRange,
            sLengthValue,
            reverseStaggering,
            unit,
        };
        const staggerLengthRange = validateStaggerValue(staggerArgs);
        return staggerLengthRange.options;
    }, [
        legRange,
        staggerRange,
        state.bLength.value,
        state.groupCount,
        state.sLength.value,
        state.reverseStaggering,
        unit,
    ]);

    const sLengthDisabled = disabled || !state.isSLengthEnabled;
    const sLengthProps: IStaggerAutoCompleteProps = {
        type: "text",
        value: state.sLengthField ?? ["0"],
        position: 0,
        disabled: sLengthDisabled,
        isInputValid: !!state.isSLengthValid,
        inputErrorText: state.sLengthErrorText ?? "",
        options: staggerOptions,
        onValueChanged: onStaggerValueChanged,
        onInputChanged: onStaggerInputChanged,
        units: unit,
        inputValue: state.sLengthInputValue ?? "0",
        inputRef,
    };

    return sLengthProps;
};

const usePullingGrip = ({ disabled, applyChanges }: SourceFieldProps) => {
    const { state, dispatch } = useContext(SourceSetupContext);
    const options = useSelector(pullingGripsSelector);
    const { t } = useTranslation();

    const onPullingGripChange = useCallback(
        (e: React.ChangeEvent<any>) => {
            dispatch(setState(applyChanges({ ...state, pullingGrip: e.target.value })));
        },
        [dispatch, state, applyChanges]
    );

    const selectProps: SelectProps = {
        id: "pulling-grip-dropdown",
        className: "field",
        palette: "primary",
        label: t(LocalizationKeys.PullingGrip),
        value: state.pullingGrip,
        disabled,
        helperText: "",
        units: "",
        onChange: onPullingGripChange,
    };

    return {
        selectProps,
        options,
    };
};

const useFocusOnError = (state: ISourceSetupState) => {
    const focusOnError = useSelector(wizardFocusOnErrorSelector);
    const storeDispatch = useDispatch();

    const fiberCountPerConnectorRef = useRef<HTMLInputElement>(null);
    const bundleCountRef = useRef<HTMLInputElement>(null);
    const groupCountRef = useRef<HTMLInputElement>(null);
    const meshOffsetRef = useRef<HTMLInputElement>(null);
    const bLengthRef = useRef<HTMLInputElement>(null);
    const sLengthRef = useRef<HTMLInputElement>(null);

    const errorField = getValidationErrorField(state);

    const errorElement = useMemo(() => {
        switch (errorField) {
            case "isBundleCountFieldValid":
                return bundleCountRef.current;
            case "isGroupCountValid":
                return groupCountRef.current;
            case "isMeshOffsetValid":
                return meshOffsetRef.current;
            case "isBLengthValid":
                return bLengthRef.current;
            case "isSLengthValid":
                return sLengthRef.current;
            case "isFiberCountPerConnectorValid":
                return fiberCountPerConnectorRef.current;
            case "isALengthValid": // No corresponding input element
            case undefined:
                return null;
            default:
                return errorField satisfies never;
        }
    }, [errorField]);

    useEffect(() => {
        if (focusOnError) {
            errorElement?.focus();
            storeDispatch(setFocusOnError(false));
        }
    }, [focusOnError, errorElement, storeDispatch]);

    return { bundleCountRef, groupCountRef, meshOffsetRef, bLengthRef, sLengthRef, fiberCountPerConnectorRef };
};

import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setShowCablePresetSnackbar } from "../../../store/overlay/overlay.reducers";
import { useWizardPreset } from "../../workspace/build/wizard-preset.hooks";
import { isAnonymousUserSelector } from "../../../store/authentication/authentication.selectors";
import { showCablePresetSnackbarSelector } from "../../../store/overlay/overlay.selectors";

export const useCablePresetSnackbar = () => {
    const open = useSelector(showCablePresetSnackbarSelector);
    const isAnonymous = useSelector(isAnonymousUserSelector);

    const { addWizardPreset } = useWizardPreset();
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        dispatch(setShowCablePresetSnackbar(false));
    }, [dispatch]);

    const handleOpen = useCallback(() => {
        if (!open && !isAnonymous) {
            dispatch(setShowCablePresetSnackbar(true));
            setTimeout(() => {
                // Make sure Snackbar is closed at the end
                handleClose();
            }, 6000);
        }
    }, [dispatch, handleClose, open, isAnonymous]);

    const handleSavePreset = useCallback(async () => {
        handleClose();
        await addWizardPreset();
    }, [addWizardPreset, handleClose]);

    return { open, handleOpen, handleClose, handleSavePreset };
};

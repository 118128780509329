import { DialogProps, TooltipPlacement, IconButtonProps } from "@corning-ctcm/silica-react";
import { useCallback, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../locales/keys";
import { IColor, IColorDialogProps } from "../../../../models/ui/dialog/color-dialog";
import { useRadioGroup, useRadioInput } from "../../../ui/radio/radio.hooks";
import { setAssemblyType, setMeshColors } from "../../../../store/workspace/build/build.reducers";
import { DialogReducer, showDialog } from "../../../../store/workspace/dialog/dialog.reducer";
import { initialDialogState } from "../../../../store/workspace/dialog/dialog";
import { currentAssemblyTypeSelector, currentMainMeshColorSelector } from "../../../../store/workspace/build.selectors";
import { setShowAssemblyTypeEdit } from "../../../../store/overlay/overlay.reducers";
import { showAssemblyTypeEditSelector } from "../../../../store/overlay/overlay.selectors";
import { IMeshColorProps, MeshColors } from "../../../../models/overlay/wizard/setup/mesh-properties";
import { useWebSave } from "../status/status.hooks";
import { setStatusState } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import { isLockedSelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";

export const useAssemblyTypeEdit = () => {
    const open = useSelector(showAssemblyTypeEditSelector);
    const mainMeshColor = useSelector(currentMainMeshColorSelector);
    const assemblyType = useSelector(currentAssemblyTypeSelector);
    const locked = useSelector(isLockedSelector);
    const { saveBuild } = useWebSave();
    const [asssemblyTypeChanged, setAssemblyTypeChanged] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { onRadioGroupChange, ...radioGroup } = useRadioGroup("assembly-type", assemblyType, locked);
    const mesh = useRadioInput("mesh", t(LocalizationKeys.AssemblyTypeMesh), assemblyType, locked);
    const jacket = useRadioInput("jacket", t(LocalizationKeys.AssemblyTypeJacket), assemblyType, locked);

    const onSave = useCallback(async () => {
        await saveBuild();
        dispatch(setStatusState("saved"));
    }, [dispatch, saveBuild]);

    const onAssemblyTypeChange = useCallback(
        (e: any) => {
            const assemblyType = e.currentTarget.value;
            onRadioGroupChange(e);
            dispatch(setAssemblyType(assemblyType));
            setAssemblyTypeChanged(true);
        },
        [onRadioGroupChange, dispatch]
    );

    const onClose = useCallback(() => {
        dispatch(setShowAssemblyTypeEdit(false));
    }, [dispatch]);

    const dialogProps: DialogProps = {
        id: "assembly-type-edit",
        open,
        modal: false,
        className: "assembly-type-edit",
        title: t(LocalizationKeys.EditAssemblyType),
        header: {
            id: "assembly-type-edit-header",
            closeable: true,
            onClose,
        },
        onClose,
    };

    useEffect(() => {
        if (open && asssemblyTypeChanged) {
            dispatch(setStatusState("saving"));
            onSave();
            setAssemblyTypeChanged(false);
        }
    }, [open, asssemblyTypeChanged, dispatch, onSave]);

    const color = useMainMeshColor({
        value: mainMeshColor,
        onChange: useCallback(
            (color: IColor) => {
                dispatch(setMeshColors(color.name));
                setAssemblyTypeChanged(true);
            },
            [dispatch]
        ),
        disabled: locked,
    });

    return {
        dialogProps,
        radioGroup: {
            props: { ...radioGroup, onRadioGroupChange: onAssemblyTypeChange },
            options: [mesh, jacket],
        },
        color,
    };
};

const useMainMeshColor = ({ value, onChange, disabled }: IMeshColorProps) => {
    const meshColor = MeshColors.find((c) => c.name === value);
    const [state, dispatch] = useReducer(DialogReducer, initialDialogState);

    const openColorDialog = useCallback(() => {
        dispatch(showDialog(true));
    }, []);

    const mainMeshColorIcon: Omit<IconButtonProps, "icon"> = {
        id: "mesh-color-icon",
        className: "palette-icon",
        palette: "primary",
        mode: "main",
        placement: "bottom" as TooltipPlacement,
        onClick: openColorDialog,
        disabled,
    };

    const onColorButtonClick = useCallback(
        (color: IColor) => {
            onChange(color);
            dispatch(showDialog(false));
        },
        [onChange]
    );

    const colorDialogProps: IColorDialogProps = {
        context: { state, dispatch },
        className: "main-mesh",
        currentColor: meshColor,
        colors: MeshColors,
        preventOutsideDismiss: true,
        displayFooter: false,
        onColorButtonClick,
    };

    return { mainMeshColorIcon, colorDialogProps };
};

import { getConnectorDefaultColor } from "../../components/pixi/factories/texture";
import { IColor } from "../ui/dialog/color-dialog";
import { convertTo } from "../overlay/header/units-of-measure";
import { IWarning } from "../../store/overlay/header/warnings/warnings";
import { FiberColors } from "../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";
import { IPropagationOptions } from "../../store/overlay/polarity/propagation/propagation";
import { getDefaultBuildDescription } from "../../components/overlay/project-drawer/project-manager-row/build-info/build-info.hooks";
import { ConfigurationType, getConnectorType } from "../../store/overlay/wizard/wizard";
import { BuildPolarity } from "../../store/workspace/build/connector/polarity/polarity";
import { getNbConnectors } from "../../store/workspace/build/connector/connector";
import { IBuildData } from "../../store/workspace/build/build";
import { FeederGrip, NoneGrip } from "../../store/workspace/ssc/ssc";
import { BuildDTO } from "./services";

export interface IUpdateConfigurationRequest {
    sessionId: string;
    configurationType: string;
}
export interface IUpdateBuildInfoRequest {
    sessionId: string;
    description: string;
}
export interface IUpdateRABRequest {
    sessionId?: string;
    buildId?: number;
    flameRating?: string;
    cableOuterDiameter: number;
    description: string;
    configurationType: string;
    grip: string;
    fiberCount: number;
    feederEnd?: Partial<IUpdateFeederEndRequest>;
    distribution?: Partial<IUpdateDistributionRequest>;
}
export interface IUpdateFeederEndRequest {
    drop: Partial<IUpdateDropMaterialRequest>;
}

export interface IUpdateDistributionRequest {
    accessPointCount: number;
    accessPoints: Partial<IUpdateAccessPointRequest>[];
    uiConnectorType: IUpdateConnectorTypeRequest;
}

export interface IPolarityConnectorRequest {
    side: string;
    tapPosition: number;
    fiberCount: number;
    groupPosition: number;
    connectorPosition: number;
    setOrderIndex: number;
}

export interface IUpdateAccessPointRequest {
    fiberCountLocal: number;
    distanceFromPrior: number;
    sequenceNumber: number;
    drop: Partial<IUpdateDropMaterialRequest>;
}

export interface IUpdateDropMaterialRequest {
    uiConnectorType: IUpdateConnectorTypeRequest;
    fiberCountLocal: number;
    furcationGroupCount: number;
    furcationGroupConnectorCount: number;
    sequenceNumberAssembly: number;
    furcationGroups: Partial<IUpdateFurcationGroupMaterialRequest>[];
}

export interface IUpdateConnectorTypeRequest {
    fiberCount: number;
    pinned?: boolean;
    id?: string;
}

export interface IUpdateFurcationGroupMaterialRequest {
    connectorCount: number;
    furcationLegLength: number;
    sequenceNumber: number;
    furcationGroupLegs: Partial<IUpdateFurcationGroupLegMaterialRequest>[];
    staggerLength: number;
}

export interface IUpdateFurcationGroupLegMaterialRequest {
    sequenceNumber: number;
    connector: Partial<IUpdateConnectorMaterialRequest>;
}

export interface IUpdateConnectorMaterialRequest {
    color: string;
    labelData: string;
}

export interface IApplyConnectorLabelSchemesRequest {
    build: BuildDTO;
    sessionId?: string;
    feederLabelScheme?: string;
    distributionLabelScheme?: string;
    polarityId?: string;
    polarityConfigs: BuildPolarity[];
    propagationOptions: IPropagationOptions;
}

export interface IApplyConnectorLabelSchemesResponse {
    build: BuildDTO;
    sessionId: string;
    warnings: IWarning[];
    configStatus: string;
    polarityConfigurations: BuildPolarity[];
    connectorColors: Record<string, Record<string, IColor[]>>;
    defaultConnectorColors: Record<string, Record<string, IColor>>;
}

export function buildDataToRABSessionRequest(sessionId: string | undefined, build: IBuildData): IUpdateRABRequest {
    const distribution = buildDataToDistributionRequest(build);
    const feederEnd = buildDataToFeederEndRequest(build);
    return {
        sessionId,
        description: build.description?.length ? build.description : getDefaultBuildDescription(build),
        buildId: build.id,
        fiberCount: build.fiberCount!,
        cableOuterDiameter: build.cableOuterDiameter!,
        flameRating: build.flameRating,
        configurationType: build.configurationType ?? ConfigurationType.Patching,
        grip: build.pullingGrip !== "none" ? FeederGrip : NoneGrip,
        distribution,
        feederEnd,
    };
}

function buildDataToDistributionRequest(build: IBuildData): Partial<IUpdateDistributionRequest> {
    const source = build.drops.find((d) => d.side === "feeder");
    const destinations = build.drops.filter((d) => d.side === "distribution");
    if (!(source && destinations.length)) return {};
    const accessPoints: Partial<IUpdateAccessPointRequest>[] = [...destinations]
        .filter((d) => d.groups.length && d.groups.every((g) => g.connectors.length))
        .map((d, i) => {
            const { type } = d.groups[0].connectors[0];
            const connectorType = type ? getConnectorType(type) : { fiberCount: 0, pinned: false };
            const lengthA =
                d.position === 0 && d.side === "distribution" ? source.lengthA : destinations[d.position - 1].lengthA;
            const distanceFromPrior = convertTo(lengthA).value;
            return {
                distanceFromPrior,
                sequenceNumber: d.position,
                drop: {
                    uiConnectorType: { ...connectorType, id: type },
                    fiberCountLocal: getNbConnectors(d.groups) * connectorType.fiberCount,
                    furcationGroupConnectorCount: d.groups[0].connectors.length,
                    furcationGroupCount: d.groups.length,
                    sequenceNumberAssembly: d.position + 1,
                    furcationGroups: d.groups.map((g) => ({
                        fiberCountLocal: g.connectors.length * connectorType.fiberCount,
                        connectorCount: g.connectors.length,
                        furcationLegLength: d.customBLength
                            ? convertTo(g.lengthB).value
                            : convertTo(d.lengthB).value + convertTo(g.stagger).value * (g.position ?? 0),
                        sequenceNumber: (g.position ?? 0) + 1,
                        furcationGroupLegs: g.connectors.map((c) => ({
                            sequenceNumber: (c.position ?? 0) + 1,
                            connector: {
                                color: getColorId(c.color) ?? getConnectorDefaultColor(c.type ?? "").id,
                                labelData: c.label,
                            },
                        })),
                        staggerLength: d.customBLength ? 0 : convertTo(g.stagger).value,
                    })),
                },
            };
        });
    const { fiberCount, pinned, type } = getConnectorType(destinations[0].groups[0].connectors[0].type!);

    return {
        accessPointCount: destinations.length,
        uiConnectorType: { fiberCount, pinned, id: type },
        accessPoints,
    };
}

function getColorId(color?: string) {
    if (!color) return undefined;

    const index = FiberColors.findIndex((c) => c.name === color);
    return index >= 0 ? FiberColors[index].id : undefined;
}

function buildDataToFeederEndRequest(build: IBuildData): Partial<IUpdateFeederEndRequest> {
    const source = build.drops.find((d) => d.side === "feeder");
    if (!source) return {};
    const { fiberCount, pinned, type } = getConnectorType(source.groups[0].connectors[0].type!);
    return {
        drop: {
            fiberCountLocal: getNbConnectors(source.groups),
            furcationGroupConnectorCount: Math.floor(getNbConnectors(source.groups) / source.groups.length),
            furcationGroupCount: source.groups.length,
            uiConnectorType: { fiberCount, pinned, id: type },
            furcationGroups: source.groups.map((g) => ({
                fiberCountLocal: g.connectors.length * fiberCount,
                connectorCount: g.connectors.length,
                furcationLegLength: source.customBLength
                    ? convertTo(g.lengthB).value
                    : convertTo(source.lengthB).value + convertTo(g.stagger).value * (g.position ?? 0),
                sequenceNumber: (g.position ?? 0) + 1,
                furcationGroupLegs: g.connectors.map((c) => ({
                    sequenceNumber: (c.position ?? 0) + 1,
                    connector: {
                        color: getColorId(c.color) ?? getConnectorDefaultColor(c.type ?? "").id,
                        labelData: c.label,
                    },
                })),
            })),
        },
    };
}

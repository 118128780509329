import { PayloadAction } from "@reduxjs/toolkit";
import { IColor } from "../../../models/ui/dialog/color-dialog";
import {
    initialWarning,
    IWarning,
    WarningSeverity,
} from "../../../store/overlay/header/warnings/warnings";
import { IRABSessionState, IConfigSession } from "./ssc";

export const setConfigurationSessionAction = (state: IRABSessionState, action: PayloadAction<IConfigSession>) => {
    const configSession = action.payload;
    state.configSession = configSession;
    if (configSession.configurationType.length) {
        state.configSessions[configSession.configurationType] = configSession;
    }
};

export const setConfigStatusAction = (
    state: IRABSessionState,
    action: PayloadAction<{ buildId: number | undefined; configStatus: string }>
) => {
    const { buildId = 0, configStatus } = action.payload;
    if (state.sessionIds[buildId]) {
        state.sessionIds[buildId].configStatus = configStatus;
    }
};

export const setBuildSessionAction = (
    state: IRABSessionState,
    action: PayloadAction<{
        buildId: number | undefined;
        sessionId: string;
        configStatus: string;
        warnings: IWarning[];
    }>
) => {
    const { buildId = 0, ...buildSession } = action.payload;
    state.sessionIds[buildId] = { ...buildSession, buildId };
};

export const setSessionWarningsAction = (
    state: IRABSessionState,
    action: PayloadAction<{ buildId: number | undefined; warnings: IWarning[] }>
) => {
    const { buildId = 0, warnings } = action.payload;
    if (state.sessionIds[buildId]) {
        state.sessionIds[buildId].warnings = warnings;
    }
};

export const storeBuildSessionAction = (state: IRABSessionState, action: PayloadAction<number>) => {
    const buildId = action.payload;
    const buildSession = state.sessionIds[buildId];
    if (buildSession && buildSession.sessionId.length) {
        state.storedBuildId = buildSession;
    }
};

export const restoreBuildSessionAction = (state: IRABSessionState) => {
    const storedBuildId = state.storedBuildId;
    if (storedBuildId.sessionId.length) {
        state.sessionIds[storedBuildId.buildId] = storedBuildId;
    }
};

export const setSessionErrorAction = (
    state: IRABSessionState,
    action: PayloadAction<{ buildId: number | undefined; warnings: Partial<IWarning> }>
) => {
    const { buildId = 0, warnings } = action.payload;
    state.sessionIds[buildId] = {
        ...state.sessionIds[buildId],
        warnings: [
            {
                ...initialWarning,
                ...warnings,
                id: "SSC-Model-Error",
                severity: WarningSeverity.Error,
            },
        ],
    };
};

export const setSessionBusyAction = (state: IRABSessionState, action: PayloadAction<boolean>) => {
    state.sessionBusy = action.payload;
};

export const setConfigSessionBusyAction = (state: IRABSessionState, action: PayloadAction<boolean>) => {
    state.configSessionBusy = action.payload;
};

export const setConfigColorsAction = (
    state: IRABSessionState,
    action: PayloadAction<{
        connectorColors: Record<string, Record<string, IColor[]>>;
        defaultConnectorColors: Record<string, Record<string, IColor>>;
    }>
) => {
    const { connectorColors, defaultConnectorColors } = action.payload;
    const configSession = state.configSession;
    configSession.connectorColors = connectorColors;
    configSession.defaultConnectorColors = defaultConnectorColors;

    if (state.configSession.configurationType.length) {
        state.configSessions[state.configSession.configurationType] = state.configSession;
    }
};

import { TFunction } from "i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../../locales/keys";
import { useInputField } from "../../../../../ui/input/input.hooks";
import { InputFieldValue } from "../../../../../../models/ui/input";
import { dropBLengthSelectorFactory } from "../../../../../../store/workspace/boundaries/boundaries.selectors";
import { setGroupBLength } from "../../../../../../store/workspace/build/build.reducers";
import { unitsOfMeasureContainerUnitSelector } from "../../../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import {
    convertTo,
    convertToDisplay,
    IUnitOfMeasure,
    roundToDecimalPoint,
    Unit,
    Units,
} from "../../../../../../models/overlay/header/units-of-measure";
import { IBLengthInputProps } from "../../../../../../models/overlay/wizard/setup/b-length-section/b-length-input";
import { DestinationSetupContext } from "../../../../../../store/overlay/wizard/setup/destination-setup/destination-setup.reducers";
import { wizardSelector } from "../../../../../../store/overlay/wizard/wizard.selectors";

export const useBLengthInput = (props: IBLengthInputProps) => {
    const { length, position: groupPosition, dropSide, dropPosition, disabled } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const label = useBLengthLabel(groupPosition);
    const { lengthString, unit } = useBLengthString(length);
    const { display, currentStep } = useSelector(wizardSelector);
    const [value, setValue] = useState(lengthString);
    const [helperText, setHelperText] = useState("");
    const [isValid, setIsValid] = useState(true);
    const inputField = useInputField(label, value, undefined, parseInput);
    const legRange = useSelector(dropBLengthSelectorFactory(dropPosition));
    const { state } = useContext(DestinationSetupContext);
    const applyToAllDst = display && currentStep === 2 && state.applyToAll;

    useEffect(() => {
        if (isValid) {
            setValue(lengthString);
        }
    }, [lengthString, isValid]);

    const onChange = useCallback(
        (e: React.ChangeEvent<any>) => {
            const inputString = parseInput(e.currentTarget.value);
            const [valid, helperText] = legRange
                ? validateInput(inputString, legRange.min, legRange.max, unit, t)
                : validateInput(inputString, 1, Number.MAX_SAFE_INTEGER, unit, t);
            const lengthValue = Number.parseFloat(inputString);
            const length = !Number.isNaN(lengthValue)
                ? convertTo({ value: lengthValue, unit }, Units.UoMInches)
                : convertTo({ value: 0, unit }, Units.UoMInches);
            dispatch(setGroupBLength({ dropSide, dropPosition, groupPosition, length, applyToAllDst }));
            setHelperText(helperText);
            setValue(inputString);
            setIsValid(valid);
        },
        [groupPosition, dropSide, dropPosition, unit, t, dispatch, legRange, applyToAllDst]
    );

    return { inputField: { ...inputField, helperText, onChange, isValid, value, disabled }, label };
};

const useBLengthLabel = (position: number) => {
    const { t } = useTranslation();
    const [label, setLabel] = useState("");

    useEffect(() => {
        setLabel(t(LocalizationKeys.GroupPosition, { position: position + 1 }));
    }, [position, t]);

    return label;
};

const useBLengthString = (length?: IUnitOfMeasure) => {
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const [convertedLength, setConvertedLength] = useState(length ? convertTo(length, unit) : undefined);
    const [lengthString, setLengthString] = useState(convertedLength ? convertToDisplay(convertedLength, unit) : "");

    useEffect(() => {
        if (convertedLength && length) {
            const roundedConvertedValue = parseFloat(convertedLength.value.toFixed(6));
            const newValue = convertTo(length, unit);
            const roundedLengthValue = parseFloat(newValue.value.toFixed(6));
            if (roundedConvertedValue !== roundedLengthValue) {
                setConvertedLength(newValue);
            }
        }
    }, [convertedLength, length, unit]);

    useEffect(() => {
        const newLengthString = convertedLength ? roundToDecimalPoint(convertedLength.value, 4, false) : "";
        setLengthString(newLengthString);
    }, [convertedLength, unit]);

    return { lengthString, unit };
};

const validateInput = (
    value: InputFieldValue,
    minValue: number,
    maxValue: number,
    unit: Unit,
    t: TFunction
): [boolean, string] => {
    const float = parseFloat(value as string);
    let valid = true;
    let errorMessage = "";
    if (!(value.toString().length || !Number.isNaN(float))) {
        valid = false;
        errorMessage = t(LocalizationKeys.MissingValue);
    }
    if (float < minValue) {
        valid = false;
        errorMessage = t(LocalizationKeys.ValueLesserThan, { value: minValue }) + " " + unit;
    } else if (float > maxValue) {
        valid = false;
        errorMessage = t(LocalizationKeys.ValueGreaterThan, { value: maxValue }) + " " + unit;
    }
    return [valid, errorMessage];
};

const parseInput = (value: InputFieldValue, defaultValue?: InputFieldValue): string => {
    let input = value as string;
    let parsedInput: string[] = [];
    let decimal = false;
    for (let i = 0; i < input.length; i++) {
        const char = input[i];
        if ((char === "." && !decimal) || (char >= "0" && char <= "9")) {
            parsedInput.push(char);
        }

        if (char === "." && !decimal) {
            decimal = true;
        }
    }

    return parsedInput.join("");
};

import {
    PlatformServiceProvider,
    ErrorPage,
    LoginRedirect,
    ILoginRedirectProps,
    IPlatformServiceConfiguration,
    PlatformService,
    getPlatformURLs
} from "@corning-ctcm/platform-client";
import { RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import { LandingPage } from "./pages/landing-page";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./store/workspace/workspace.reducers";
import { login, setTokenProps } from "./store/authentication/authentication.reducers";
import { Authenticator, WorkspaceSetup } from "@corning-ctcm/platform-login";
import { LocalizationKeys } from "./locales/keys";
import { useTranslation } from "react-i18next";

import "@corning-ctcm/platform-login/dist/index.css";

export const RapidBundle = () => {
    const { service, router } = useRapidBundle();
    return (
        <PlatformServiceProvider service={service}>
            <RouterProvider router={router} />
        </PlatformServiceProvider>
    );
};
const platformURLs = await getPlatformURLs();
const useRapidBundle = () => {
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();

    const configuration: IPlatformServiceConfiguration = {
        loginURL: platformURLs.authLogin,
        refreshTokenURL: platformURLs.authRefresh,
        redirectURL: `https://${window.location.host}/login-redirect`,
        loginCallback: (token) => storeDispatch(setTokenProps(token))
    };
    const service = new PlatformService(configuration);

    const setup: WorkspaceSetup = {
        appName: t(LocalizationKeys.RABTitle),
        showCorningLogin: true,
        showIbwaveLogin: true
    };
    const loginRedirectProps: ILoginRedirectProps = {
        loginCallback: (token) => storeDispatch(login(token)),
    };
    const router = createBrowserRouter([
        {
            path: "/",
            element: <LandingPage />,
            errorElement: <ErrorPage />,
        },
        {
            path: "/login",
            element: <Authenticator {...setup} />,
        },
        {
            path: "/login-redirect",
            element: <LoginRedirect {...loginRedirectProps} />,
        },
    ]);

    return {
        service,
        router,
    };
};

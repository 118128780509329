import React from "react";
import { Edit, Delete } from "@mui/icons-material";
import { Typography, IconButton } from "@corning-ctcm/silica-react";
import { ListItem, ListItemButton } from "@mui/material";

export interface IBoundariesRowProps {
    label: string;
    isDefault: boolean;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

export const BoundariesRow: React.FC<IBoundariesRowProps> = ({ label, isDefault, onEditClick, onDeleteClick }) => {
    return (
        <ListItem disablePadding>
            <ListItemButton disableRipple className="boundaries-row">
                <Typography variant="body2">
                    {label}
                </Typography>
                <div className="boundaries-row-action">
                    <IconButton
                        palette="primary"
                        mode="main"
                        placement="bottom"
                        onClick={onEditClick}
                        icon={<Edit />}
                    />
                    <IconButton
                        palette="error"
                        mode="main"
                        placement="bottom"
                        disabled={isDefault}
                        onClick={onDeleteClick}
                        icon={<Delete />}
                    />
                </div>
            </ListItemButton>
        </ListItem>
    );
};

import { CheckboxProps, SelectProps, TextFieldProps } from "@corning-ctcm/silica-react";
import { AutocompleteChangeReason } from "@mui/material";
import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../locales/keys";
import {
    IUnitOfMeasure,
    convertTo,
    decimalPlacesBasedOnUnit,
    getUnitsName,
    roundToDecimalBasedOnUnit,
    roundToDecimalPoint,
} from "../../../../../models/overlay/header/units-of-measure";
import {
    BLengthType,
    IBLengthProps,
} from "../../../../../models/overlay/wizard/setup/b-length-section/b-length-section";
import {
    AccessPointsFieldProps,
    ApplyToAllFieldProps,
    CPGFieldProps,
    ConnectorTypeFieldProps,
    DestinationFieldProps,
    GroupsFieldProps,
    getValidFiberCountInUseValues,
    getValidationErrorField,
    isValidDestinationSetup,
} from "../../../../../models/overlay/wizard/setup/destination-setup";
import { IMeshProps } from "../../../../../models/overlay/wizard/setup/mesh-properties";
import { IStaggerAutoCompleteProps } from "../../../../../models/overlay/wizard/setup/stagger-autocomplete";
import { IColor } from "../../../../../models/ui/dialog/color-dialog";
import { unitsOfMeasureContainerUnitSelector } from "../../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import {
    IDestinationSetupState,
    getAsymmetricAPCount,
    getConnectorsPerGroupCount,
    getMaxAsymmetricAPCount,
    getMaxGroupCount,
    hasDestinationChanges,
} from "../../../../../store/overlay/wizard/setup/destination-setup/destination-setup";
import {
    DestinationSetupContext,
    setState,
} from "../../../../../store/overlay/wizard/setup/destination-setup/destination-setup.reducers";
import {
    Stagger0,
    getConnectorType,
    getStaggerRange,
    validateStaggerValue,
    validateWholeNumber,
    getConnectorFamilyString,
    getConnectorTypeFromDrop,
} from "../../../../../store/overlay/wizard/wizard";
import {
    enableNextStep,
    setFocusOnError,
    setIsDynamicUpdateDisabled,
} from "../../../../../store/overlay/wizard/wizard.reducers";
import { wizardFocusOnErrorSelector, wizardSelector } from "../../../../../store/overlay/wizard/wizard.selectors";
import { ViewportStatus } from "../../../../../store/pixi/viewport/viewport";
import { viewportSelector } from "../../../../../store/pixi/viewport/viewport.selectors";
import {
    distributionOptionsSelector,
    meshColorSelector,
} from "../../../../../store/workspace/boundaries/boundaries.selectors";
import {
    autoAccessPointsSelector,
    bundleCountSelector,
    currentAvailabilitySelector,
    dropsSelector,
    selectedDropSelector,
} from "../../../../../store/workspace/build.selectors";
import { DropData } from "../../../../../store/workspace/build/build";
import { updateConnectorType } from "../../../../../store/workspace/build/build.actions";
import {
    setCurrentBuild,
    setDestinationEnabled,
    setupAllDestinations,
    setupDestination,
} from "../../../../../store/workspace/build/build.reducers";
import { buildDisabledSelector } from "../../../../../store/workspace/build/build.selector";
import { IDrop, initialMeshColor, initialMeshOffset } from "../../../../../store/workspace/build/drop";
import { RestrictedConnectorTypes } from "../../../../pixi/factories/texture";
import { validateValue } from "../../wizard.hooks";
import { hasCustomLengthChanges, isValidBLengths } from "../b-length-section/custom-b-length/custom-b-length.hooks";
import { useReverseStaggering } from "../reverse-staggering/reverse-staggering.hooks";

export const useDestinationSetup = () => {
    const { context } = useSelector(viewportSelector);
    const { state, dispatch } = useContext(DestinationSetupContext);
    const { savedBuildData } = useSelector(wizardSelector);
    const drops = useSelector(dropsSelector);
    const selectedDrop = useSelector(selectedDropSelector);
    const drop = selectedDrop ?? drops[1];
    const distributionOptions = useSelector(distributionOptionsSelector);
    const availability = useSelector(currentAvailabilitySelector);
    const disabled = useSelector(buildDisabledSelector);
    const bundleCount = useSelector(bundleCountSelector);
    const { validate, dropFiberCount, dropMaxFiber } = useDestinationValidation(
        drop,
        drops[0]?.lengthA ?? Stagger0.value
    );

    const storeDispatch = useDispatch();

    const applyChanges = useCallback(
        (newState: IDestinationSetupState): IDestinationSetupState => {
            if (isValidDestinationSetup(newState)) {
                if (context === ViewportStatus.Editing && !newState.applyToAll) {
                    storeDispatch(setupDestination({ ...newState, fiberCount: dropFiberCount }));
                } else {
                    storeDispatch(setupAllDestinations(newState));
                }
                storeDispatch(setIsDynamicUpdateDisabled(false));
            } else {
                storeDispatch(setIsDynamicUpdateDisabled(true));
            }

            return newState;
        },
        [context, dropFiberCount, storeDispatch]
    );

    const resetChanges = useCallback(
        (newState: IDestinationSetupState): IDestinationSetupState => {
            const { side, position } = drop;
            const currentDrop = drops.find((d) => d.side === side && d.position === position);
            if (savedBuildData && currentDrop) {
                const [feeder, ...distribution] = [...savedBuildData.drops];
                const drops: IDrop[] = [feeder];
                const meshColor = newState.mesh ? newState.meshColor : undefined;
                for (const distributionDrop of distribution) {
                    if (distributionDrop.side === side && distributionDrop.position === position) {
                        const connectorType = state.connectorType;
                        const updatedDrop: IDrop =
                            newState.connectorType.type !== connectorType.type
                                ? updateConnectorType(connectorType, currentDrop)
                                : currentDrop;
                        drops.push(updatedDrop);
                        newState.connectorType = connectorType;
                        newState.fiberCount = bundleCount * connectorType.fiberCount;
                    } else {
                        const meshOffset = newState.meshOffset ?? initialMeshOffset;
                        const meshColor = newState.meshColor ?? initialMeshColor;
                        drops.push({
                            ...distributionDrop,
                            mesh: newState.mesh, // mesh configurations need to be persisted
                            meshOffset: newState.mesh ? meshOffset : undefined,
                            meshColor,
                        });
                    }
                }
                storeDispatch(setCurrentBuild({ ...savedBuildData, mainMeshColor: meshColor, drops }));
            }

            return newState;
        },
        [bundleCount, drop, drops, savedBuildData, state, storeDispatch]
    );

    useEffect(() => {
        let isValid = isValidDestinationSetup(state);
        let hasChanges = hasDestinationChanges(savedBuildData, state);
        if (drop?.customBLength) {
            hasChanges = hasChanges || hasCustomLengthChanges(drop, savedBuildData);
            isValid = isValid && isValidBLengths(drop, distributionOptions.legRange);
        }
        storeDispatch(enableNextStep(context === ViewportStatus.Editing ? isValid && hasChanges : isValid));
    }, [context, distributionOptions.legRange, drop, savedBuildData, state, storeDispatch]);

    useEffect(() => {
        if (context === ViewportStatus.Creating && availability?.enabledDestinations.length === 0) {
            storeDispatch(setDestinationEnabled(true));
            applyChanges(validate(state));
        }
    }, [availability?.enabledDestinations.length, context, state, applyChanges, validate, storeDispatch]);

    const enableSLength = useCallback((newState: IDestinationSetupState): IDestinationSetupState => {
        const groupCountFieldValue = newState.groupCountFieldValue ?? "";
        const isSLengthEnabled =
            groupCountFieldValue.length > 0 && groupCountFieldValue !== "1" && groupCountFieldValue !== "0";
        const value = newState.sLength.value;

        let sLength = newState.sLength;
        let sLengthField = newState.sLengthField;
        if (!isSLengthEnabled && value !== 0) {
            sLength = { value: 0, unit: newState.sLength.unit };
            sLengthField = ["0"];
        }

        return {
            ...newState,
            groupCountFieldValue,
            sLength,
            sLengthField,
            isSLengthEnabled,
        };
    }, []);

    const {
        aLengthRef,
        groupCountRef,
        meshOffsetRef,
        bLengthRef,
        sLengthRef,
        accessPointsRef,
        nbConnectorsPerGroupRef,
        fiberCountPerConnectorRef,
    } = useFocusOnError(state);

    const fieldProps: DestinationFieldProps = { disabled, validate, applyChanges };
    const connectorType = useConnectorType({ ...fieldProps, dropMaxFiber });
    const accessPointProps = useAccessPoints({ ...fieldProps, inputRef: accessPointsRef });
    const groupProps = useGroups({ ...fieldProps, enableSLength, inputRef: groupCountRef });
    const fiberCountPerConnectorProps = useFiberCountPerConnector({
        ...fieldProps,
        inputRef: fiberCountPerConnectorRef,
    });
    const cpgProps = useCPG({ ...fieldProps, enableSLength, inputRef: nbConnectorsPerGroupRef });
    const nbDstProps = useDestination();
    const meshProps = useMesh({ ...fieldProps, inputRef: meshOffsetRef });
    const aLengthProps = useALength({ ...fieldProps, inputRef: aLengthRef });
    const { onBLengthTypeChange } = useBLengthType(fieldProps);
    const bLengthTypeProps: IBLengthProps = {
        drop,
        disabled,
        onChange: onBLengthTypeChange,
    };
    const bLengthProps = useBLength({ ...fieldProps, inputRef: bLengthRef });
    const sLengthProps = useSLength({ ...fieldProps, inputRef: sLengthRef });
    const reverseStaggeringProps = useReverseStaggering({
        ...fieldProps,
        dispatch,
        state,
        setState,
    });
    const applyToAll = useApplyToAll({ ...fieldProps, resetChanges });

    return {
        disabled,
        connectorType,
        fiberCountPerConnectorProps,
        accessPointProps,
        groupProps,
        cpgProps,
        nbDstProps,
        meshProps,
        aLengthProps,
        bLengthTypeProps,
        bLengthProps,
        sLengthProps,
        applyToAll,
        reverseStaggeringProps,
    };
};

const useConnectorType = ({ disabled, validate, applyChanges }: ConnectorTypeFieldProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(DestinationSetupContext);
    const { connectorTypes } = useSelector(distributionOptionsSelector);
    const { context } = useSelector(viewportSelector);

    const [feeder] = useSelector(dropsSelector);
    const feederFiberCountInUse = feeder.fiberCountInUse ?? getConnectorTypeFromDrop(feeder).fiberCount;

    const filteredConnectorTypes = useMemo(() => {
        if (context === "editing") {
            return state.connectorType.fiberCount === state.fiberCountPerConnector
                ? connectorTypes.filter(
                      (c) => getConnectorFamilyString(c) === getConnectorFamilyString(state.connectorType)
                  )
                : [state.connectorType];
        }
        return connectorTypes.filter(c => {
            const validValues = getValidFiberCountInUseValues(c.fiberCount, state.fiberCount, feederFiberCountInUse);
            return RestrictedConnectorTypes.some(r => r === c.type) ? validValues.includes(c.fiberCount) : true;
        });
    }, [context, connectorTypes, feederFiberCountInUse, state.connectorType, state.fiberCount, state.fiberCountPerConnector]);

    const onConnectorTypeChange = useCallback(
        (e: React.ChangeEvent<any>) => {
            if (state.connectorType.key === e.target.value) {
                return;
            }

            const connectorType = connectorTypes.find((x) => x.type === e.target.value);
            if (connectorType) {
                const max = connectorType.fiberCount;
                const fiberCountPerConnectorValidValues = getValidFiberCountInUseValues(max, state.fiberCount, feederFiberCountInUse);
                const fiberCountPerConnector = Math.max(...fiberCountPerConnectorValidValues);
                const newState: IDestinationSetupState = validate({
                    ...state,
                    connectorType,
                    fiberCountPerConnector,
                    fiberCountPerConnectorValidValues,
                    fiberCountPerConnectorField: fiberCountPerConnector.toString(),
                });
                dispatch(setState(applyChanges(newState)));
            }
        },
        [connectorTypes, feederFiberCountInUse, state, applyChanges, validate, dispatch]
    );

    const connectorTypeDisabled = !!disabled || filteredConnectorTypes.length <= 1;
    const connectorTypeProps: SelectProps = {
        id: "connector-type-dropdown",
        palette: "primary",
        margin: "dense",
        disabled: connectorTypeDisabled,
        label: t(LocalizationKeys.ConnectorType),
        className: "field",
        helperText: "",
        units: "",
        value: state.connectorType.type,
        onChange: onConnectorTypeChange,
    };

    return {
        options: filteredConnectorTypes,
        props: connectorTypeProps,
    };
};

const useFiberCountPerConnector = ({ disabled, validate, applyChanges, inputRef }: DestinationFieldProps) => {
    const { state, dispatch } = useContext(DestinationSetupContext);
    const { t } = useTranslation();
    const { context } = useSelector(viewportSelector);

    const onFiberCountPerConnectorChange = useCallback(
        (e: React.FormEvent<any>): void => {
            const fiberCountPerConnectorField = e.currentTarget.value;
            if (!validateWholeNumber(fiberCountPerConnectorField)) return;

            const fiberCountPerConnector = Number(fiberCountPerConnectorField);
            const newState: IDestinationSetupState = validate({
                ...state,
                fiberCountPerConnectorField,
                fiberCountPerConnector,
            });
            dispatch(setState(applyChanges(newState)));
        },
        [state, validate, applyChanges, dispatch]
    );

    const isRestricted = RestrictedConnectorTypes.findIndex((type) => type === state.connectorType.type) !== -1;
    const fiberCountPerConnectorDisabled = disabled || context === "editing" || isRestricted;
    const fiberCountPerConnectorProps: TextFieldProps = {
        id: "fiber-count-per-connector-field",
        palette: "primary",
        type: "number",
        disabled: fiberCountPerConnectorDisabled,
        label: t(LocalizationKeys.FiberCountPerConnector),
        helperText: state.fiberCountPerConnectorErrorText,
        error: !state.isFiberCountPerConnectorValid,
        value: state.fiberCountPerConnectorField ?? "",
        inputRef,
        onChange: onFiberCountPerConnectorChange,
    };

    return fiberCountPerConnectorProps;
};

const useAccessPoints = ({ validate, applyChanges, inputRef }: AccessPointsFieldProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(DestinationSetupContext);
    const { context } = useSelector(viewportSelector);
    const autoAccessPoints = useSelector(autoAccessPointsSelector);

    const onAccessPointsChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = e.currentTarget.value;
            if (!validateWholeNumber(value)) return;

            const newState: IDestinationSetupState = validate({
                ...state,
                accessPointsFieldValue: value,
                accessPoints: Number(value),
            });
            dispatch(setState(applyChanges(newState)));
        },
        [state, validate, applyChanges, dispatch]
    );

    const accessPointsDisabled = context === ViewportStatus.Editing || autoAccessPoints;
    const accessPointProps: TextFieldProps = {
        id: "access-point-field",
        palette: "primary",
        type: "number",
        disabled: accessPointsDisabled,
        label: t(LocalizationKeys.NbAccessPoints),
        helperText: state.accessPointsErrorText,
        error: !state.isAccessPointsValid,
        value: state.accessPointsFieldValue ?? "",
        onChange: onAccessPointsChange,
        inputRef,
    };

    return accessPointProps;
};

const useGroups = ({ disabled, enableSLength, validate, applyChanges, inputRef }: GroupsFieldProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(DestinationSetupContext);

    const onGroupCountChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = e.currentTarget.value;
            if (!validateWholeNumber(value)) return;

            let newState = validate({ ...state, groupCountFieldValue: value, groupCount: Number(value) });
            if (newState.isGroupCountValid && newState.isNbConnectorsPerGroupValid) {
                newState = enableSLength(newState);
            }

            dispatch(setState(applyChanges(newState)));
        },
        [state, enableSLength, validate, applyChanges, dispatch]
    );

    const groupProps: TextFieldProps = {
        id: "groups-field",
        palette: "primary",
        type: "number",
        disabled,
        label: t(LocalizationKeys.NbGroups),
        helperText: state.groupCountErrorText,
        error: !state.isGroupCountValid,
        value: state.groupCountFieldValue ?? "",
        onChange: onGroupCountChange,
        inputRef,
    };

    return groupProps;
};

const useCPG = ({ disabled, validate, enableSLength, applyChanges, inputRef }: CPGFieldProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(DestinationSetupContext);
    const { context } = useSelector(viewportSelector);

    const onNbConnectorPerGroupChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = e.currentTarget.value;
            if (!validateWholeNumber(value)) return;

            let newState = validate({
                ...state,
                nbConnectorsPerGroupFieldFieldValue: value,
                nbConnectorsPerGroup: Number(value),
            });
            if (newState.groupCountFieldValue && newState.isGroupCountValid && newState.isNbConnectorsPerGroupValid) {
                newState = validate(newState);
                newState = enableSLength(newState);
            }

            dispatch(setState(applyChanges(newState)));
        },
        [state, validate, enableSLength, applyChanges, dispatch]
    );

    const cpgDisabled = context === ViewportStatus.Editing && !state.applyToAll;
    const cpgProps: TextFieldProps = {
        id: "cpg-field",
        palette: "primary",
        type: "number",
        disabled: cpgDisabled || disabled,
        label: t(LocalizationKeys.NbCPG),
        helperText: state.nbConnectorsPerGroupErrorText,
        error: !state.isNbConnectorsPerGroupValid,
        value: state.nbConnectorsPerGroupFieldFieldValue ?? "",
        onChange: onNbConnectorPerGroupChange,
        inputRef,
    };

    return cpgProps;
};

const useDestination = () => {
    const { t } = useTranslation();
    const { state } = useContext(DestinationSetupContext);

    const nbDstValue =
        state.groupCount && state.nbConnectorsPerGroup ? state.groupCount * state.nbConnectorsPerGroup : 0;
    const nbDstProps: TextFieldProps = {
        id: "nb-dst-field",
        palette: "primary",
        disabled: true,
        label: t(LocalizationKeys.NbDstConnectors),
        helperText: "",
        value: nbDstValue,
    };

    return nbDstProps;
};

const useMesh = ({ disabled, validate, applyChanges, inputRef }: DestinationFieldProps) => {
    const { state, dispatch } = useContext(DestinationSetupContext);
    const { name: defaultMeshColor } = useSelector(meshColorSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const maxDecimalPlaces = decimalPlacesBasedOnUnit(unit);

    const onMeshCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.currentTarget.checked;
            let newState: IDestinationSetupState = { ...state, mesh: checked };
            if (checked) {
                const defaultMeshOffset = convertTo(initialMeshOffset, unit);
                newState = validate({
                    ...newState,
                    meshOffsetField: roundToDecimalBasedOnUnit(defaultMeshOffset.value, unit),
                    meshOffset: defaultMeshOffset,
                    mesh: checked,
                    meshColor: defaultMeshColor,
                });
            } else {
                newState = validate({
                    ...newState,
                    mesh: checked,
                    meshColor: undefined,
                    meshOffset: undefined,
                    meshOffsetErrorText: "",
                    isMeshOffsetValid: true,
                });
            }

            dispatch(setState(applyChanges(newState)));
        },
        [state, defaultMeshColor, unit, validate, applyChanges, dispatch]
    );

    const onMeshOffsetChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.currentTarget.value;
            if (!validateValue(value, maxDecimalPlaces)) return;

            const newState: IDestinationSetupState = validate({
                ...state,
                meshOffsetField: value,
                meshOffset: { value: Number(value), unit },
            });
            dispatch(setState(applyChanges(newState)));
        },
        [maxDecimalPlaces, unit, state, validate, applyChanges, dispatch]
    );

    const onMeshColorChange = useCallback(
        (color: IColor) => {
            dispatch(setState(applyChanges({ ...state, meshColor: color.name })));
        },
        [state, applyChanges, dispatch]
    );

    useEffect(() => {
        if (state.meshOffset && state.meshOffset.unit !== unit) {
            const convertedMeshOffset: IUnitOfMeasure = convertTo(state.meshOffset, unit);
            const newState: IDestinationSetupState = validate({
                ...state,
                meshOffsetField: roundToDecimalBasedOnUnit(convertedMeshOffset.value, unit),
                meshOffset: convertedMeshOffset,
            });
            dispatch(setState(newState));
        }
    }, [state, unit, validate, dispatch]);

    const meshProps: IMeshProps = {
        mesh: state.mesh,
        onCheckboxChange: onMeshCheckboxChange,
        offset: {
            value: state.meshOffsetField,
            helperText: state.meshOffsetErrorText,
            isValid: state.isMeshOffsetValid,
            onChange: onMeshOffsetChange,
            units: getUnitsName(unit, false, true),
            inputRef,
        },
        color: {
            value: state.meshColor ?? defaultMeshColor,
            disabled,
            onChange: onMeshColorChange,
        },
        disabled,
    };

    return meshProps;
};

const useALength = ({ disabled, validate: validateAll, applyChanges, inputRef }: DestinationFieldProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(DestinationSetupContext);
    const { context } = useSelector(viewportSelector);
    const { aLengthRange } = useSelector(distributionOptionsSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const maxDecimalPlaces = decimalPlacesBasedOnUnit(unit);

    const onALengthChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = e.target.value;
            const maxCharLength = aLengthRange.max.toString().length;
            if (!validateValue(value, maxDecimalPlaces, maxCharLength)) return;

            const newState: IDestinationSetupState = validateAll({
                ...state,
                aLengthField: value,
                aLength: { value: Number(value), unit },
            });
            dispatch(setState(applyChanges(newState)));
        },
        [aLengthRange, maxDecimalPlaces, unit, state, validateAll, applyChanges, dispatch]
    );

    useEffect(() => {
        if (state.aLength.unit !== unit) {
            const convertedALength: IUnitOfMeasure = convertTo(state.aLength, unit);
            const newState: IDestinationSetupState = validateAll({
                ...state,
                aLengthField: roundToDecimalBasedOnUnit(convertedALength.value, unit),
                aLength: convertedALength,
            });
            dispatch(setState(newState));
        }
    }, [state, unit, validateAll, dispatch]);

    const defaultPosition = state.position ? state.position + 1 : 1;
    const position = context === ViewportStatus.Editing ? defaultPosition : "x";
    const aLengthProps: TextFieldProps = {
        id: "a-length-field",
        palette: "primary",
        type: "number",
        disabled,
        label: t(LocalizationKeys.LengthA, { position }),
        helperText: state.aLengthErrorText,
        error: !state.isALengthValid,
        value: state.aLengthField ?? "",
        onChange: onALengthChange,
        units: getUnitsName(unit, false, true),
        inputRef,
    };

    return aLengthProps;
};

const useBLengthType = ({ applyChanges }: DestinationFieldProps) => {
    const { state, dispatch } = useContext(DestinationSetupContext);

    const onBLengthTypeChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const bLengthType = e.currentTarget.value as BLengthType;
            dispatch(setState(applyChanges({ ...state, bLengthType })));
        },
        [dispatch, applyChanges, state]
    );

    return { onBLengthTypeChange };
};

const useBLength = ({ disabled, validate, applyChanges, inputRef }: DestinationFieldProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(DestinationSetupContext);
    const { context } = useSelector(viewportSelector);
    const { legRange } = useSelector(distributionOptionsSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const maxDecimalPlaces = decimalPlacesBasedOnUnit(unit);

    const onBLengthChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = e.target.value;
            const maxCharLength = legRange.max.toString().length;
            if (!validateValue(value, maxDecimalPlaces, maxCharLength)) return;

            const newState: IDestinationSetupState = validate({
                ...state,
                bLengthField: value,
                bLength: { value: Number(value), unit },
            });
            dispatch(setState(applyChanges(newState)));
        },
        [maxDecimalPlaces, legRange.max, unit, state, validate, applyChanges, dispatch]
    );

    useEffect(() => {
        if (state.bLength.unit !== unit) {
            const convertedBLength: IUnitOfMeasure = convertTo(state.bLength, unit);
            const newState: IDestinationSetupState = validate({
                ...state,
                bLengthField: roundToDecimalBasedOnUnit(convertedBLength.value, unit),
                bLength: convertedBLength,
            });
            dispatch(setState(newState));
        }
    }, [state, unit, validate, dispatch]);

    const defaultPosition = state.position ? state.position + 1 : 1;
    const position = context === ViewportStatus.Editing ? defaultPosition : "x";
    const bLengthTextField: TextFieldProps = {
        id: "b-length-field",
        palette: "primary",
        type: "number",
        disabled,
        label: t(LocalizationKeys.LengthB, { position }),
        helperText: state.bLengthErrorText,
        error: !state.isBLengthValid,
        value: state.bLengthField ?? "",
        onChange: onBLengthChange,
        units: getUnitsName(unit, false, true),
        inputRef,
    };

    return {
        textField: bLengthTextField,
    };
};

const useSLength = ({ disabled, validate, applyChanges, inputRef }: DestinationFieldProps) => {
    const { state, dispatch } = useContext(DestinationSetupContext);
    const { staggerRange, legRange } = useSelector(distributionOptionsSelector);
    const { context } = useSelector(viewportSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const maxDecimalPlaces = decimalPlacesBasedOnUnit(unit);

    const staggerOptions = useMemo(() => {
        const sLengthValue = state.sLength.value;
        const sLengthRange = staggerRange;
        const bLengthRange = legRange;
        const bLengthValue = state.bLength.value;
        const groupCount = state.groupCount ?? 0;
        const reverseStaggering = state.reverseStaggering;
        return getStaggerRange(
            sLengthValue,
            groupCount,
            bLengthValue,
            bLengthRange,
            sLengthRange,
            reverseStaggering,
            unit
        ).options;
    }, [
        legRange,
        staggerRange,
        state.bLength.value,
        state.groupCount,
        state.sLength.value,
        state.reverseStaggering,
        unit,
    ]);

    const onStaggerValueChanged = useCallback(
        (e: React.ChangeEvent<any>, value: (string | number)[], reason: AutocompleteChangeReason) => {
            const valueStr = value as unknown as string; // Bug with autocomplete in silica library, value type is string.
            if (state.sLengthField === value || !validateValue(valueStr, maxDecimalPlaces)) return;
            const newState: IDestinationSetupState = validate({
                ...state,
                sLengthField: [valueStr],
            });
            dispatch(setState(applyChanges(newState)));
        },
        [state, maxDecimalPlaces, validate, dispatch, applyChanges]
    );

    const onStaggerInputChanged = useCallback(
        (e: React.ChangeEvent<{}>, value: string) => {
            if (state.sLengthInputValue === value || !validateValue(value, maxDecimalPlaces)) return;
            const newState: IDestinationSetupState = validate({
                ...state,
                sLengthInputValue: value,
                sLength: { value: Number(value), unit },
            });
            dispatch(setState(applyChanges(newState)));
        },
        [maxDecimalPlaces, unit, state, validate, applyChanges, dispatch]
    );

    useEffect(() => {
        if (state.sLength.unit !== unit) {
            const convertedSLength: IUnitOfMeasure = convertTo(state.sLength, unit);
            const newState: IDestinationSetupState = validate({
                ...state,
                sLengthField: [roundToDecimalBasedOnUnit(convertedSLength.value, unit)],
                sLengthInputValue: roundToDecimalBasedOnUnit(convertedSLength.value, unit),
                sLength: convertedSLength,
            });
            dispatch(setState(newState));
        }
    }, [state, unit, validate, dispatch]);

    const defaultPosition = state.position ? state.position + 1 : 1;
    const position = context === ViewportStatus.Editing ? defaultPosition : "x";
    const sLengthDisabled = !!disabled || !state.isSLengthEnabled;
    const sLengthProps: IStaggerAutoCompleteProps = {
        type: "text",
        value: state.sLengthField ?? ["0"],
        position,
        disabled: sLengthDisabled,
        isInputValid: !!state.isSLengthValid,
        inputErrorText: state.sLengthErrorText ?? "",
        options: staggerOptions,
        onValueChanged: onStaggerValueChanged,
        onInputChanged: onStaggerInputChanged,
        units: unit,
        inputValue: state.sLengthInputValue ?? "0",
        inputRef,
    };

    return sLengthProps;
};

const useApplyToAll = ({ disabled, applyChanges, resetChanges }: ApplyToAllFieldProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(DestinationSetupContext);
    const { context } = useSelector(viewportSelector);
    const bundleCount = useSelector(bundleCountSelector);

    const onApplyToAllCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.currentTarget.checked;
            const updatedState: IDestinationSetupState = { ...state, applyToAll: checked };
            if (updatedState.applyToAll) {
                updatedState.fiberCount = bundleCount * updatedState.connectorType.fiberCount;
                dispatch(setState(applyChanges(updatedState)));
            } else {
                dispatch(setState(resetChanges(updatedState)));
            }
        },
        [bundleCount, state, applyChanges, resetChanges, dispatch]
    );

    const value = !!state.applyToAll;
    const applyToAllDisabled = disabled || !state.applyToAllEnabled;
    const checkboxProps: CheckboxProps = {
        id: "apply-to-all-checkbox",
        palette: "primary",
        placement: "end",
        label: t(LocalizationKeys.ApplyToAllAPs),
        disabled: applyToAllDisabled,
        value,
        checked: value,
        onChange: onApplyToAllCheckboxChange,
    };

    return {
        display: state.accessPoints && state.accessPoints > 1 && context === ViewportStatus.Editing,
        props: checkboxProps,
    };
};

export const useDestinationValidation = (drop: IDrop | DropData, feederALength: IUnitOfMeasure) => {
    const { context } = useSelector(viewportSelector);
    const { t } = useTranslation();
    const distributionOptions = useSelector(distributionOptionsSelector);
    const autoAccessPoints = useSelector(autoAccessPointsSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const dropFiberCount =
        drop.groups
            .flatMap((g) => g.connectors)
            .map((c) => c.fiberCountInUse ?? getConnectorType(c.type).fiberCount)
            .reduce((a, b) => a + b, 0) ?? 0;
    const dropMaxFiber = Math.min(dropFiberCount, distributionOptions.dropFiberCountRange.max);

    const validateSLength = useCallback(
        (newState: IDestinationSetupState): IDestinationSetupState => {
            const value = parseFloat(newState.sLengthInputValue ?? "");
            const sLengthRange = distributionOptions.staggerRange;
            const bLengthRange = distributionOptions.legRange;
            const bLengthValue = parseFloat(newState.bLengthField ?? "");
            const groupCount = drop.groups.length;
            const reverseStaggering = newState.reverseStaggering;

            let { sLengthErrorText, valid: isSLengthValid } = validateStaggerValue({
                bLengthRange,
                bLengthValue,
                groupCount,
                sLengthRange,
                sLengthValue: value,
                reverseStaggering,
                unit,
            });

            const bLengthMin = value * (groupCount - 1);
            if (newState.reverseStaggering && +(newState.bLengthField ?? "") < bLengthMin) {
                sLengthErrorText = `${t(LocalizationKeys.MeshOffsetBLengthError)}`;
                isSLengthValid = false;
            }

            return {
                ...newState,
                sLengthErrorText,
                isSLengthValid,
            };
        },
        [distributionOptions, drop.groups.length, t, unit]
    );

    const validateCPGCount = useCallback(
        (newState: IDestinationSetupState): IDestinationSetupState => {
            let connectorsPerGroup = Number(newState.nbConnectorsPerGroupFieldFieldValue) || 0;
            const { min: minValidAPCount, max: maxValidAPCount } = distributionOptions.accessPointRange;
            const { min: minValidCPGCount, max: maxValidCPGCount } = distributionOptions.connectorsPerGroupCounts;
            const newDestinationState: IDestinationSetupState = {
                ...newState,
                nbConnectorsPerGroup: connectorsPerGroup,
                isNbConnectorsPerGroupValid: true,
                nbConnectorsPerGroupErrorText: "",
            };

            if (!(newDestinationState.isAccessPointsValid && newDestinationState.isGroupCountValid))
                return newDestinationState;

            const isEditing = context === ViewportStatus.Editing;
            const groupCount = newDestinationState.groupCount,
                fiberCount = !isEditing ? newState.fiberCount : dropMaxFiber,
                fiberCountInUse =
                    newDestinationState.fiberCountPerConnector ?? newDestinationState.connectorType.fiberCount,
                accessPoints = !isEditing ? newDestinationState.accessPoints : 1;

            if (fiberCount && groupCount && accessPoints) {
                let maxNbConnectorsPerGroup = getConnectorsPerGroupCount(
                    accessPoints,
                    groupCount,
                    fiberCount,
                    fiberCountInUse
                );
                let minNbConnectorsPerGroup = getConnectorsPerGroupCount(
                    Math.min(accessPoints, maxValidAPCount),
                    groupCount,
                    fiberCount,
                    fiberCountInUse
                );
                if (!isEditing && autoAccessPoints && connectorsPerGroup > 0) {
                    const maxDropFiber = distributionOptions?.dropFiberCountRange.max ?? 1;
                    const accessPoints = getAsymmetricAPCount(
                        groupCount,
                        connectorsPerGroup,
                        fiberCount,
                        fiberCountInUse,
                        minValidAPCount,
                        maxValidAPCount,
                        maxDropFiber
                    );

                    maxNbConnectorsPerGroup = getConnectorsPerGroupCount(
                        Math.max(accessPoints - 1, minValidAPCount),
                        groupCount,
                        fiberCount,
                        fiberCountInUse
                    );
                    minNbConnectorsPerGroup = getConnectorsPerGroupCount(
                        Math.min(accessPoints, maxValidAPCount),
                        groupCount,
                        fiberCount,
                        fiberCountInUse
                    );
                    newDestinationState.accessPoints = accessPoints;
                    newDestinationState.accessPointsFieldValue = accessPoints.toString();
                    newDestinationState.isAccessPointsValid = true;
                    newDestinationState.accessPointsErrorText = "";
                }

                maxNbConnectorsPerGroup = Math.min(maxNbConnectorsPerGroup, maxValidCPGCount);
                minNbConnectorsPerGroup = Math.max(minNbConnectorsPerGroup, minValidCPGCount);

                if (newState.nbConnectorsPerGroupFieldFieldValue === "" || !connectorsPerGroup) {
                    newDestinationState.nbConnectorsPerGroupErrorText = t(LocalizationKeys.MissingValue);
                    newDestinationState.isNbConnectorsPerGroupValid = false;
                } else if (isEditing) {
                    const fiberRemainder = dropMaxFiber - groupCount * fiberCountInUse * connectorsPerGroup;
                    if (fiberRemainder) {
                        newDestinationState.nbConnectorsPerGroupErrorText = t(LocalizationKeys.InvalidEntry);
                        newDestinationState.isNbConnectorsPerGroupValid = false;
                    }
                } else if (connectorsPerGroup > maxNbConnectorsPerGroup) {
                    newDestinationState.nbConnectorsPerGroupErrorText = t(LocalizationKeys.ValueGreaterThan, {
                        value: maxNbConnectorsPerGroup,
                    });
                    newDestinationState.isNbConnectorsPerGroupValid = false;
                } else if (connectorsPerGroup < minNbConnectorsPerGroup) {
                    newDestinationState.nbConnectorsPerGroupErrorText = t(LocalizationKeys.ValueLesserThan, {
                        value: minNbConnectorsPerGroup,
                    });
                    newDestinationState.isNbConnectorsPerGroupValid = false;
                }
            }

            return newDestinationState;
        },
        [t, autoAccessPoints, context, distributionOptions, dropMaxFiber]
    );

    const validateALength = useCallback(
        (newState: IDestinationSetupState): IDestinationSetupState => {
            const aLengthRange = distributionOptions.aLengthRange;
            const aLengthField = newState.aLengthField ?? "";
            const aLengthValue = newState.aLength.value;
            const accessPoints = newState.accessPoints ?? 0;
            const overallLengthRange = distributionOptions.mainFurcationToEndFurcationRange;
            const sourceALength = convertTo(feederALength, unit).value;
            const minALength = aLengthRange.min;
            const maxALength =
                accessPoints > 1
                    ? Math.min(aLengthRange.max, (overallLengthRange.max - sourceALength) / (accessPoints - 1))
                    : aLengthRange.max;

            let isALengthValid = true;
            let aLengthErrorText = "";

            if (aLengthField.length === 0) {
                aLengthErrorText = t(LocalizationKeys.MissingValue);
                isALengthValid = false;
            } else if (aLengthValue < minALength) {
                aLengthErrorText =
                    t(LocalizationKeys.ValueLesserThan, { value: roundToDecimalPoint(minALength, 4, false) }) +
                    " " +
                    unit;
                isALengthValid = false;
            } else if (aLengthValue > maxALength) {
                aLengthErrorText =
                    t(LocalizationKeys.ValueGreaterThan, { value: roundToDecimalPoint(maxALength, 4, false) }) +
                    " " +
                    unit;
                isALengthValid = false;
            }

            return {
                ...newState,
                isALengthValid,
                aLengthErrorText,
            };
        },
        [t, distributionOptions, feederALength, unit]
    );

    const validateBLength = useCallback(
        (newState: IDestinationSetupState): IDestinationSetupState => {
            let isBLengthValid = true;
            let bLengthErrorText = "";

            if (!newState.isGroupCountValid)
                return {
                    ...newState,
                    isBLengthValid,
                    bLengthErrorText,
                };

            const bLengthField = newState.bLengthField ?? "";
            const bLengthValue = newState.bLength.value;
            const mesh = newState.mesh;
            const meshOffsetValue = mesh ? newState.meshOffset?.value ?? 0 : 0;
            const staggerCount = drop.groups.length - 1;
            const staggerValue = newState.sLength.value;
            const staggerTotalLength = staggerValue * staggerCount;
            const legRange = distributionOptions.legRange;
            const reverseStaggering = newState.reverseStaggering;
            const minExpectedValue = reverseStaggering ? staggerTotalLength + meshOffsetValue + 1 : meshOffsetValue + 1;
            const minBLength = Math.max(legRange.min, minExpectedValue);

            if (bLengthField.length === 0) {
                bLengthErrorText = t(LocalizationKeys.MissingValue);
                isBLengthValid = false;
            } else if (bLengthValue < minBLength) {
                bLengthErrorText = `${t(LocalizationKeys.ValueLesserThan, {
                    value: roundToDecimalPoint(minBLength, 4, false),
                })} ${unit}`;
                isBLengthValid = false;
            } else if (bLengthValue > legRange.max) {
                bLengthErrorText = `${t(LocalizationKeys.ValueGreaterThan, {
                    value: roundToDecimalPoint(legRange.max, 4, false),
                })} ${unit}`;
                isBLengthValid = false;
            }

            return {
                ...newState,
                isBLengthValid,
                bLengthErrorText,
            };
        },
        [drop.groups.length, t, unit, distributionOptions]
    );

    const validateGroupCount = useCallback(
        (newState: IDestinationSetupState): IDestinationSetupState => {
            const groupCountFieldValue = newState.groupCountFieldValue ?? "";
            let groupCount = Number(groupCountFieldValue);
            let newDestinationState: IDestinationSetupState = {
                ...newState,
                groupCountFieldValue,
                groupCount,
                groupCountErrorText: "",
                isGroupCountValid: true,
            };

            if (!newDestinationState.isAccessPointsValid) return newDestinationState;

            const isEditing = context === ViewportStatus.Editing;
            const groupCountRange = distributionOptions.groupCounts;
            const accessPoints = newDestinationState.accessPoints ?? 1,
                fiberCount = newDestinationState.fiberCount,
                fiberCountInUse =
                    newDestinationState.fiberCountPerConnector ?? newDestinationState.connectorType.fiberCount;
            const minAccessPoint = Math.max(accessPoints, distributionOptions.accessPointRange.min);
            let maxGroupCount = getMaxGroupCount(minAccessPoint, fiberCount, fiberCountInUse);
            maxGroupCount = Math.min(groupCountRange.max, maxGroupCount);
            if (groupCountFieldValue === "" || !groupCount) {
                newDestinationState.groupCountErrorText = t(LocalizationKeys.MissingValue);
                newDestinationState.isGroupCountValid = false;
            } else if (groupCount < groupCountRange.min) {
                newDestinationState.groupCountErrorText = t(LocalizationKeys.ValueLesserThan, {
                    value: groupCountRange.min,
                });
                newDestinationState.isGroupCountValid = false;
            } else if (groupCount > maxGroupCount) {
                newDestinationState.groupCountErrorText = t(LocalizationKeys.ValueGreaterThan, {
                    value: maxGroupCount,
                });
                newDestinationState.isGroupCountValid = false;
            } else if (isEditing) {
                // On edit, connectors must use all available fiber
                const fiberRemainder = dropMaxFiber % (fiberCountInUse * groupCount);
                if (fiberRemainder) {
                    const connectorCount = Math.floor(dropMaxFiber / fiberCountInUse);
                    newDestinationState.groupCountErrorText = t(LocalizationKeys.AccessPointsConnectorError, {
                        count: connectorCount,
                    });
                    newDestinationState.isGroupCountValid = false;
                } else {
                    const newConnectorsPerGroup = Math.floor(dropMaxFiber / (fiberCountInUse * groupCount));
                    newDestinationState.nbConnectorsPerGroup = newConnectorsPerGroup;
                    newDestinationState.nbConnectorsPerGroupFieldFieldValue = newConnectorsPerGroup.toString();
                }
            }

            return newDestinationState;
        },
        [t, context, distributionOptions, dropMaxFiber]
    );

    const validateFiberCountPerConnector = useCallback(
        (newState: IDestinationSetupState) => {
            const fiberCountPerConnectorField = newState.fiberCountPerConnectorField ?? "";
            if (!validateWholeNumber(fiberCountPerConnectorField)) return newState;

            const max = newState.connectorType.fiberCount;
            const fiberCountPerConnector = Number(fiberCountPerConnectorField);
            let isFiberCountPerConnectorValid = true;
            let fiberCountPerConnectorErrorText = "";
            if (fiberCountPerConnectorField.length === 0 || fiberCountPerConnector === 0) {
                fiberCountPerConnectorErrorText = t(LocalizationKeys.MissingValue);
                isFiberCountPerConnectorValid = false;
            } else if (fiberCountPerConnector > max) {
                fiberCountPerConnectorErrorText = t(LocalizationKeys.ValueGreaterThan, {
                    value: newState.connectorType.fiberCount,
                });
                isFiberCountPerConnectorValid = false;
            } else if (!newState.fiberCountPerConnectorValidValues?.includes(fiberCountPerConnector)) {
                fiberCountPerConnectorErrorText = t(LocalizationKeys.InvalidEntryWithValidEntries, {
                    value: newState.fiberCountPerConnectorValidValues?.join(", "),
                });
                isFiberCountPerConnectorValid = false;
            }

            return {
                ...newState,
                fiberCountPerConnector,
                fiberCountPerConnectorField,
                isFiberCountPerConnectorValid,
                fiberCountPerConnectorErrorText,
            };
        },
        [t]
    );

    const validateAccessPoints = useCallback(
        (newState: IDestinationSetupState) => {
            const accessPointsFieldValue = newState.accessPointsFieldValue ?? "";
            let accessPoints = newState.accessPoints ?? 1;
            let newDestinationState: IDestinationSetupState = {
                ...newState,
                accessPoints,
                accessPointsFieldValue,
                isAccessPointsValid: true,
                accessPointsErrorText: "",
            };

            const { min: minValidAPCount, max: maxValidAPCount } = distributionOptions.accessPointRange;
            const { min: minValidGroupCount } = distributionOptions.groupCounts;
            const { min: minValidCPGCount } = distributionOptions.connectorsPerGroupCounts;
            const groupCount = newDestinationState.groupCount ?? 1;
            const nbConnectorsPerGroup = newDestinationState.nbConnectorsPerGroup ?? 1;
            const maxDropFiber = distributionOptions.dropFiberCountRange.max ?? 1;
            const fiberCount = newState.fiberCount;
            const fiberCountInUse = newState.fiberCountPerConnector ?? newState.connectorType.fiberCount;

            let maxAsymmetricAccessPoints = getMaxAsymmetricAPCount(
                minValidGroupCount,
                minValidCPGCount,
                fiberCount,
                fiberCountInUse,
                maxDropFiber
            );
            let maxAccessPoints = Math.min(maxValidAPCount, maxAsymmetricAccessPoints);
            maxAccessPoints = Math.max(maxAccessPoints, minValidAPCount);

            const isEditing = context === ViewportStatus.Editing || context === ViewportStatus.Creating;
            const isManual = isEditing && !autoAccessPoints;
            if (isManual) {
                if (accessPoints < minValidAPCount) {
                    newDestinationState.accessPointsErrorText = t(LocalizationKeys.ValueLesserThan, {
                        value: minValidAPCount,
                    });
                    newDestinationState.isAccessPointsValid = false;
                } else if (accessPoints > maxAccessPoints) {
                    newDestinationState.accessPointsErrorText = t(LocalizationKeys.ValueGreaterThan, {
                        value: maxAccessPoints,
                    });
                    newDestinationState.isAccessPointsValid = false;
                }

                if (accessPointsFieldValue.length === 0) {
                    newDestinationState.accessPointsErrorText = t(LocalizationKeys.MissingValue);
                    newDestinationState.isAccessPointsValid = false;
                }
            } else if (autoAccessPoints) {
                maxAsymmetricAccessPoints = getMaxAsymmetricAPCount(
                    newDestinationState.groupCount ?? minValidGroupCount,
                    newDestinationState.nbConnectorsPerGroup ?? minValidCPGCount,
                    fiberCount,
                    fiberCountInUse,
                    maxDropFiber
                );
                maxAccessPoints = Math.min(maxValidAPCount, maxAsymmetricAccessPoints);
                maxAccessPoints = Math.max(maxAccessPoints, minValidAPCount);
                accessPoints = getAsymmetricAPCount(
                    groupCount,
                    nbConnectorsPerGroup,
                    fiberCount,
                    fiberCountInUse,
                    minValidAPCount,
                    maxValidAPCount,
                    maxDropFiber
                );

                accessPoints = Math.min(accessPoints, maxAccessPoints);
                newDestinationState.accessPoints = accessPoints;
                newDestinationState.accessPointsFieldValue = accessPoints.toString();
            }

            return newDestinationState;
        },
        [t, autoAccessPoints, context, distributionOptions]
    );

    const validateMeshOffset = useCallback(
        (newState: IDestinationSetupState): IDestinationSetupState => {
            let isMeshOffsetValid = true;
            let meshOffsetErrorText = "";

            if (!newState.mesh)
                // unchecked Add Mesh checkbox
                return {
                    ...newState,
                    isMeshOffsetValid,
                    meshOffsetErrorText,
                };

            const meshOffsetRange = distributionOptions.meshOffsetRange;
            const meshOffsetField = newState.meshOffsetField ?? "";
            const meshOffsetValue = newState.meshOffset?.value ?? 0;
            const reverseStaggering = newState.reverseStaggering;
            const customBLength = drop.customBLength ?? false;
            const groups = drop.groups ?? [];
            const staggerCount = groups.length - 1;
            const staggerTotalLength = newState.sLength.value * staggerCount;
            const lowBLength = customBLength
                ? Math.min(...groups.map((g) => g.lengthB?.value!))
                : newState.bLength.value;
            const lowDropBLength = reverseStaggering ? lowBLength - staggerTotalLength : lowBLength;
            const lowMeshLength = lowDropBLength - meshOffsetValue;
            const maxMeshOffset = Math.min(meshOffsetRange.max, lowDropBLength);
            const minMeshOffset = meshOffsetRange.min;

            if (meshOffsetField.length === 0) {
                meshOffsetErrorText = t(LocalizationKeys.MissingValue);
                isMeshOffsetValid = false;
            } else if (lowMeshLength < 0) {
                meshOffsetErrorText = t(LocalizationKeys.MeshOffsetBLengthError);
                isMeshOffsetValid = false;
            } else if (meshOffsetValue < minMeshOffset) {
                meshOffsetErrorText = `${t(LocalizationKeys.ValueLesserThan, {
                    value: roundToDecimalPoint(minMeshOffset, 4, false),
                })} ${unit}`;
                isMeshOffsetValid = false;
            } else if (meshOffsetValue > maxMeshOffset) {
                meshOffsetErrorText = `${t(LocalizationKeys.ValueGreaterThan, {
                    value: roundToDecimalPoint(maxMeshOffset, 4, false),
                })} ${unit}`;
                isMeshOffsetValid = false;
            }

            return {
                ...newState,
                meshOffsetErrorText,
                isMeshOffsetValid,
            };
        },
        [t, drop.customBLength, drop.groups, distributionOptions, unit]
    );

    const validate = useCallback(
        (newState: IDestinationSetupState): IDestinationSetupState => {
            newState = validateALength(newState);
            newState = validateBLength(newState);
            newState = validateSLength(newState);
            newState = validateFiberCountPerConnector(newState);
            newState = validateAccessPoints(newState);
            newState = validateMeshOffset(newState);
            newState = validateGroupCount(newState);
            newState = validateCPGCount(newState);
            return { ...newState };
        },
        [
            validateALength,
            validateFiberCountPerConnector,
            validateAccessPoints,
            validateBLength,
            validateCPGCount,
            validateGroupCount,
            validateMeshOffset,
            validateSLength,
        ]
    );

    return {
        validate,
        dropFiberCount,
        dropMaxFiber,
    };
};

const useFocusOnError = (state: IDestinationSetupState) => {
    const focusOnError = useSelector(wizardFocusOnErrorSelector);
    const storeDispatch = useDispatch();

    const fiberCountPerConnectorRef = useRef<HTMLInputElement>(null);
    const aLengthRef = useRef<HTMLInputElement>(null);
    const groupCountRef = useRef<HTMLInputElement>(null);
    const meshOffsetRef = useRef<HTMLInputElement>(null);
    const bLengthRef = useRef<HTMLInputElement>(null);
    const sLengthRef = useRef<HTMLInputElement>(null);
    const accessPointsRef = useRef<HTMLInputElement>(null);
    const nbConnectorsPerGroupRef = useRef<HTMLInputElement>(null);

    const errorField = useMemo(() => getValidationErrorField(state), [state]);

    const errorElement = useMemo(() => {
        switch (errorField) {
            case "isGroupCountValid":
                return groupCountRef.current;
            case "isMeshOffsetValid":
                return meshOffsetRef.current;
            case "isALengthValid":
                return aLengthRef.current;
            case "isBLengthValid":
                return bLengthRef.current;
            case "isSLengthValid":
                return sLengthRef.current;
            case "isAccessPointsValid":
                return accessPointsRef.current;
            case "isNbConnectorsPerGroupValid":
                return nbConnectorsPerGroupRef.current;
            case "isFiberCountPerConnectorValid":
                return fiberCountPerConnectorRef.current;
            case undefined:
                return null;
            default:
                return errorField satisfies never;
        }
    }, [errorField]);

    useEffect(() => {
        if (focusOnError) {
            errorElement?.focus();
            storeDispatch(setFocusOnError(false));
        }
    }, [focusOnError, errorElement, storeDispatch]);

    return {
        aLengthRef,
        groupCountRef,
        meshOffsetRef,
        bLengthRef,
        sLengthRef,
        accessPointsRef,
        nbConnectorsPerGroupRef,
        fiberCountPerConnectorRef,
    };
};

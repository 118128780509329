import {
    FiberColors,
    getFillOpacity,
    IConnectorPinDrawingProps,
    IConnectorPinProps,
    ConnectorTemplateProps,
    MMCFiberColors,
} from "../../../../../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";
import { useCallback, useContext, useMemo } from "react";
import { FiberMappingContext, IFiberMap } from "../../../../../../store/overlay/polarity/fiber-mapping-save/fiber-mapping-save";
import { setSelectedPinIndex } from "../../../../../../store/overlay/polarity/fiber-mapping-save/fiber-mapping-save.reducer";
import { getConnectorType } from "../../../../../../store/overlay/wizard/wizard";
import { getTextColor } from "../../../../../../models/ui/dialog/color-dialog";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../locales/keys";
import { SectionContext, Feeder, Drop } from "../../../../../../models/pixi/build/build";
import {
    getConnectorTypeString,
    isLCDuplex,
    isLCSimplex,
    isMMC16,
    isMPO,
    isSCSimplex,
} from "../../../../../pixi/factories/texture";
import { useSelector } from "react-redux";
import { indexedBuildConnectorIdsSelector } from "../../../../../../store/workspace/build.selectors";

const colorDisabled = "#E0E0E0";
const colorUnused = "#E11725";
const grey = "#606065";

const LCFiberCount = 2;
const SimplexStartX = 11.5;
const SimplexStartY = 40;
const LCStartX = 17;
const LCStartY = 40;
const MMCStartX = 32;
const MMCStartY = 68;
const MMC24StartY = 38;
const MTPStartX = 18;
const MTP24StartY = 40;
const MTP12StartY = 70;
const pinStep = 56;

export const useConnectorPin = ({ side, connectorType, pinIndex, ...props }: IConnectorPinProps) => {
    const {
        feederConnectors,
        distributionConnectors
    } = useSelector(indexedBuildConnectorIdsSelector);
    const section = useContext(SectionContext);
    const { 
        state: {
            navigation: {
                feeder,
                distribution,
                selectedPinIndex
            },
            mapping,
            unused
        }
    } = useContext(FiberMappingContext);

    const pinProps = useMemo(() => {
        let className = "";
        let tooltip = "";
        let fiberMap: IFiberMap | undefined;
        let selected = false;
        let assigned = false;
        let isAssignedToTAP = false;
        let isUnused = false;
        let disabled = props.disabled;

        const fiberMapping = [...mapping].sort((a, b) => b.feederPin.index > a.feederPin.index ? -1 : 1);
        let fiberMapIndex = 0;
        if (section === Feeder) {
            fiberMapIndex = fiberMapping.findIndex((m) => 
                m.feederPin.index === pinIndex && m.feederPin.connectorId === feeder.currentItem
            );

            fiberMap = fiberMapIndex === -1 ? undefined : fiberMapping[fiberMapIndex];
            selected = selectedPinIndex === pinIndex;
            if (fiberMap) {
                assigned = fiberMap.feederPin.connectorId === feeder.currentItem;
                if (assigned) {
                    const distributionConnector = distributionConnectors.find(c => c.connectorId === fiberMap?.distributionPin.connectorId);
                    if (distributionConnector) {
                        tooltip = `T${distributionConnector.tapPosition + 1} C${distributionConnector.index + 1}`;
                    }
                }
            }
            isUnused = unused.sourcePins.find((p) => p.index === pinIndex && p.connectorId === feeder.currentItem) !== undefined;
        } else {
            fiberMapIndex = fiberMapping.findIndex(m => 
                m.distributionPin.index === pinIndex 
                && m.distributionPin.connectorId === distribution.connectors.currentItem
            );

            fiberMap = fiberMapIndex === -1 ? undefined : fiberMapping[fiberMapIndex];
            if (fiberMap) {
                assigned = fiberMap.distributionPin.connectorId === distribution.connectors.currentItem;
                if (assigned) {
                    const feederConnector = feederConnectors.find(c => c.connectorId === fiberMap?.feederPin.connectorId);
                    if (feederConnector) {
                        tooltip = `F${feederConnector.index + 1}`;
                    }
                }
            }
            isUnused = unused.destinationPins.some((p) => p.index === pinIndex && p.connectorId === distribution.connectors.currentItem);
        }

        if (fiberMap) {
            isAssignedToTAP = fiberMap.distributionPin.connectorId === distribution.connectors.currentItem;
        }

        // Checking for harnesses
        if (fiberMap && connectorType.fiberCountInUse && connectorType.fiberCountInUse !== connectorType.fiberCount) {
            disabled = disabled || fiberMapIndex > connectorType.fiberCountInUse - 1;
        }

        className = disabled ? "connector-pin disabled" : selected ? "connector-pin selected" : "connector-pin";

        return {
            className,
            tooltip,
            fiberMap,
            selected,
            assigned,
            isAssignedToTAP,
            disabled,
            unused: isUnused,
        };
    }, [
        connectorType,
        section,
        props.disabled,
        mapping,
        selectedPinIndex,
        pinIndex,
        unused.sourcePins,
        unused.destinationPins,
        feeder.currentItem,
        distributionConnectors,
        distribution.connectors.currentItem,
        feederConnectors
    ]);

    const type = connectorType.type ?? "";
    const { color, isBottomPin } = getPinInfo(pinIndex, type);
    const drawingProps: IConnectorPinDrawingProps = useMemo(() => {
        let fill = color.hex;
        let fillOpacity = 0;
        let stroke = color.hex;
        let text = `${pinIndex + 1}`;
        let textColor = grey;
        let strokeDashArray = section === Feeder && isBottomPin ? "4" : "";

        if (pinProps.assigned) {
            fillOpacity = getFillOpacity(color);
            if (pinProps.isAssignedToTAP) {
                fillOpacity = 1;
                textColor = getTextColor(color).hex;
            }
            if (section === Drop && pinProps.fiberMap) {
                const sourcePinIndex = pinProps.fiberMap.feederPin.index;
                const { color: sourceColor, isBottomPin: sourceIsBottomPin } = getPinInfo(
                    sourcePinIndex,
                    type
                );
                const isAssignedToFeeder =
                    pinProps.fiberMap.feederPin.connectorId === feeder.currentItem;
                fill = sourceColor.hex;
                fillOpacity = isAssignedToFeeder ? 1 : 0.2;
                stroke = sourceColor.hex;
                text = `${sourcePinIndex + 1}`;
                textColor = isAssignedToFeeder ? getTextColor(sourceColor).hex : grey;
                strokeDashArray = sourceIsBottomPin ? "4" : "";
            }
        } else {
            if (section === Drop) {
                fillOpacity = 0;
                stroke = grey;
                textColor = "none";
            }
        }

        if (pinProps.disabled) {
            fill = colorDisabled;
            fillOpacity = 1;
            stroke = colorDisabled;
        }

        if (pinProps.unused) {
            fillOpacity = 0;
            stroke = colorUnused;
            strokeDashArray = "";
        }

        return {
            fill,
            fillOpacity,
            stroke,
            strokeDashArray,
            text,
            textColor,
        };
    }, [color, pinIndex, isBottomPin, section, pinProps, feeder.currentItem, type]);

    return {      
        side,
        index: pinIndex,
        tooltip: pinProps.tooltip,
        className: pinProps.className,
        drawingProps,
        unused: pinProps.unused,
        disabled: pinProps.disabled,
    };
};

const getPinInfo = (pinIndex: number, connectorType: string) => {
    let isBottomPin = pinIndex > 12;
    let color = FiberColors[(pinIndex) % 12];
    if (isMMC16(connectorType)) {
        color = MMCFiberColors[pinIndex];
        isBottomPin = false;
    }
    return { color, isBottomPin };
};

export const useTemplate = () => {
    const { state, dispatch } = useContext(FiberMappingContext);

    const onClick = useCallback(() => {
        if (state.navigation.selectedPinIndex !== -1) {
            dispatch(setSelectedPinIndex(-1));
        }
    }, [state.navigation.selectedPinIndex, dispatch]);

    return { onClick };
};

export const useSimplexTemplate = ({ disabled, connectorType, side }: ConnectorTemplateProps) => {
    const { onClick } = useTemplate();
    const { t } = useTranslation();

    let label = t(disabled ? LocalizationKeys.LCSimplexViewOnly : LocalizationKeys.LCSimplex);

    const type = connectorType.type ?? "";
    if (isSCSimplex(getConnectorTypeString(type))) {
        label = t(disabled ? LocalizationKeys.SCSimplexViewOnly : LocalizationKeys.SCSimplex)
    }

    const pin = {
        side,
        position: { x: SimplexStartX, y: SimplexStartY },
        index: 0,
        disabled: false,
    };

    return { label, connectorType, pin, disabled, onClick };
};

export const useLCTemplate = ({ disabled, connectorType, side }: ConnectorTemplateProps) => {
    const { onClick } = useTemplate();
    const { t } = useTranslation();

    const type = connectorType.type ?? "";
    let label = t(disabled ? LocalizationKeys.LCUnibootViewOnly : LocalizationKeys.LCUniboot);
    if (isLCSimplex(getConnectorTypeString(type))) {
        label = t(disabled ? LocalizationKeys.LCSimplexViewOnly : LocalizationKeys.LCSimplex);
    }
    if (isLCDuplex(getConnectorTypeString(type))) {
        label = t(disabled ? LocalizationKeys.LCDuplexViewOnly : LocalizationKeys.LCDuplex);
    }

    const pinIndexes = Array.from(Array(LCFiberCount).keys());
    const pins = pinIndexes.map((i) => {
        const x = LCStartX + i * pinStep;
        const y = LCStartY;
        return {
            position: { x, y },
            index: i,
            disabled: false,
        };
    });

    return { side, onClick, pins, label, disabled, connectorType };
};

export const useMMCTemplate = ({ side, connectorType, disabled }: ConnectorTemplateProps) => {
    const { onClick } = useTemplate();
    const { t } = useTranslation();

    const type = connectorType.type ?? "";
    const fiberCount = getConnectorType(type).fiberCount;
    const isMMC24 = fiberCount === 24;
    const nbPins = isMMC24 ? 24 : 16;

    const label = t(disabled ? LocalizationKeys.MmcFiberCountViewOnly : LocalizationKeys.MmcFiberCount, {
        fiberCount: fiberCount,
    });

    const pinIndexes = Array.from(Array(nbPins).keys());
    const step = isMMC24 ? 76.5 : pinStep;
    const pins = pinIndexes.map((i) => {
        const pinColumnIndex = isMMC24 ? i % 12 : i;
        const x = MMCStartX + pinColumnIndex * step;
        let y = MMCStartY;
        if (isMMC24) {
            y = i >= 12 ? MMC24StartY + pinStep : MMC24StartY;
        }
        return {
            position: { x, y },
            index: i,
            disabled: false,
        };
    });

    return { side, onClick, isMMC24, pins, label, disabled, connectorType };
};

export const useMTPTemplate = ({ side, connectorType, disabled }: ConnectorTemplateProps) => {
    const { onClick } = useTemplate();
    const { t } = useTranslation();

    const type = connectorType.type ?? "";
    const fiberCount = getConnectorType(type).fiberCount;
    const isMTP24 = fiberCount === 24;
    const nbPins = isMTP24 ? 24 : 12;

    let label = t(disabled ? LocalizationKeys.MtpFiberCountViewOnly : LocalizationKeys.MtpFiberCount, {
        fiberCount: fiberCount,
    });
    if (isMPO(type)) {
        label = t(disabled ? LocalizationKeys.MpoFiberCountViewOnly : LocalizationKeys.MpoFiberCount, {
            fiberCount: fiberCount,
        });
    }

    const pinIndexes = Array.from(Array(nbPins).keys());
    const pins = pinIndexes.map((i) => {
        const pinColumnIndex = i % 12;
        const x = MTPStartX + pinColumnIndex * pinStep;
        let y = MTP12StartY;
        if (isMTP24) {
            y = i >= 12 ? MTP24StartY + pinStep : MTP24StartY;
        }
        
        const disabled = fiberCount === 8 && i > 3 && i < 8;
        return {
            position: { x, y },
            index: i,
            disabled,
        };
    });

    return { side, onClick, isMTP24, pins, label, disabled, connectorType };
};

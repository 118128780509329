import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    isPolarityModeSelector,
    showFiberMappingSelector,
} from "../../../../../store/overlay/overlay.selectors";
import { viewportContextSelector } from "../../../../../store/pixi/viewport/viewport.selectors";
import { IConnectorHighlightProps } from "../../../../../models/pixi/build/connectors/connector-highlight";
import {
    IRectangleDrawingProps,
    IRectangleLayout,
    DEFAULT_RECTANGLE_LAYOUT,
} from "../../../../../models/pixi/decorators/bounds/interactive-rectangle";
import {
    setHoveredConnectorId,
    setSelectedConnectorId,
} from "../../../../../store/pixi/connector-highlights/connector-highlights.reducers";
import { getConnectorHighlightSelectorFactory, hoveredConnectorIdSelector, selectedConnectorIdSelector } from "../../../../../store/pixi/connector-highlights/connector-highlights.selectors";
import {
    DEFAULT_BLUE,
    DEFAULT_GREEN,
    IHighlight,
    ORANGE,
    toDecimalHex,
} from "../../../../../store/pixi/connector-highlights/connector-highlights";
import { getInteractiveEventMode } from "../../../../../models/pixi/pixi";

export const useConnectorHighlight = ({ connectorId, bounds }: IConnectorHighlightProps) => {
    const { context } = useSelector(viewportContextSelector);
    const isPolarity = useSelector(isPolarityModeSelector);
    const isFiberMappingUI = useSelector(showFiberMappingSelector);
    const connectorHighlightSelector = useMemo(() => getConnectorHighlightSelectorFactory(connectorId), [connectorId]);
    const highlight = useSelector(connectorHighlightSelector);
    const selectedConnectorId = useSelector(selectedConnectorIdSelector);
    const hoveredConnectorId = useSelector(hoveredConnectorIdSelector);
    const selected = !!highlight?.selected || selectedConnectorId === connectorId;
    const hovered = hoveredConnectorId === connectorId;

    const storeDispatch = useDispatch();

    const onMouseClick = useCallback(() => {
        storeDispatch(setSelectedConnectorId(connectorId));
    }, [storeDispatch, connectorId]);

    const onMouseOver = useCallback(() => {
        storeDispatch(setHoveredConnectorId(connectorId));
    }, [storeDispatch, connectorId]);

    const onMouseOut = useCallback(() => {
        storeDispatch(setHoveredConnectorId(-1));
    }, [storeDispatch]);

    const isHighlighted = !!highlight;
    const display = context !== "inactive" && isHighlighted && isPolarity;
    const interactive = display && !isFiberMappingUI;
    const layout = getConnectorLayout(hovered, highlight);
    const rectangleProps: IRectangleDrawingProps = {
        name: "",
        eventMode: getInteractiveEventMode(interactive),
        selected,
        layout,
        bounds,
        onMouseClick,
        onMouseOver,
        onMouseOut,
    };

    return { display, rectangleProps };
};

export const getConnectorLayout = (hovered: boolean, highlight?: IHighlight): IRectangleLayout => {
    let borderColor: number = DEFAULT_RECTANGLE_LAYOUT.borderColor;
    let color: number | undefined = undefined;
    if (highlight) {
        const { assigned, selected } = highlight;
        if (assigned && selected) {
            const blue = toDecimalHex(DEFAULT_BLUE.fill);
            borderColor = blue;
            color = blue;
        } else if (assigned) {
            const green = toDecimalHex(DEFAULT_GREEN.fill);
            borderColor = green;
            if (hovered) {
                color = green;
            }
        } else if (selected) {
            const orange = toDecimalHex(ORANGE.fill);
            borderColor = orange;
            color = orange;
        }
    }
    return { borderColor, color, alpha: 1 };
}

import { createSlice } from "@reduxjs/toolkit";
import { initialAppLabelScheme } from "./label-scheme";
import { setOrgBundlesAction } from "./label-scheme.actions";

const labelSchemeSlice = createSlice({
    name: "labelScheme",
    initialState: initialAppLabelScheme,
    reducers: {
        setOrgBundles: setOrgBundlesAction,
    },
});

export const LabelSchemeReducer = labelSchemeSlice.reducer;
export const { setOrgBundles } = labelSchemeSlice.actions;

import { createSlice } from "@reduxjs/toolkit";
import { initialBuildDocState } from "./document";
import { setDocumentAction, setUserProvidedImagesAction } from "./document.action";

const reportDocumentSlice = createSlice({
    name: "report",
    initialState: initialBuildDocState,
    reducers: {
        setDocument: setDocumentAction,
        setUserProvidedImages: setUserProvidedImagesAction,
    },
});

export const ReportDocumentReducer = reportDocumentSlice.reducer;
export const { setDocument, setUserProvidedImages } = reportDocumentSlice.actions;

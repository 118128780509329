import { Assets, Graphics, LINE_CAP, LINE_JOIN, Sprite, Text, TextStyle, Texture } from "pixi.js";
import { useCallback, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import {
    CROSSHATCH_TEXTURE,
    getConnectorTexture,
    getConnectorTypeString,
    isLCDuplex,
    isMMC,
} from "../../../../../components/pixi/factories/texture";
import { IUnitOfMeasure } from "../../../../../models/overlay/header/units-of-measure";
import { AllColors } from "../../../../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";
import { getFurcationContainerName, getMaxNumberOfCablesPerGroup } from "../../../../../models/pixi/build/build";
import {
    BORDER_COLOR,
    COLLAPSE_MARKER_OFFSET_Y,
    DROP_CABLE_BORDER_WIDTH,
    DUPLEX_VERTICAL_OFFSET,
    FURCATION_CURVE_END,
    FURCATION_END,
    FURCATION_OFFSET_X,
    IConnectorLegProps,
    IFurcationProps,
    LABEL_BORDER_WIDTH,
    LABEL_COLOR,
    LABEL_OFFSET_Y,
    STAGGER_OFFSET_Y,
    TIED_FURCATION_CURVE_END,
    TIED_GROUP_OFFSET_FROM_FURCATION_END,
    getConnectorCableDrawWidth,
    getLegLabelDimensions,
} from "../../../../../models/pixi/build/connectors/connector-furcation";
import { IConnectorHighlightProps } from "../../../../../models/pixi/build/connectors/connector-highlight";
import { CONNECTOR_STEP_OFFSET } from "../../../../../models/pixi/build/connectors/connectors";
import {
    TRUNK_WIDTH,
    getCableStartOffset,
    getLineMeshTextureOptions,
    getMeshColor,
} from "../../../../../models/pixi/build/drop-base/drop-base";
import { DimensionLineContext } from "../../../../../models/pixi/decorators/dimension-lines/dimension-lines";
import { drawCollapseMarker } from "../../../../../models/pixi/decorators/markers/markers";
import { DEFAULT_TEXT_STYLE } from "../../../../../models/pixi/decorators/text";
import { DEFAULT_TEXT_RESOLUTION, IPoint, IPointEmpty } from "../../../../../models/pixi/pixi";
import { DEFAULT_LABEL_COLOR, hexStringToNumber } from "../../../../../models/ui/dialog/color-dialog";
import { texturesLoadedSelector } from "../../../../../store/overlay/header/status-icon/status-icon.selectors";
import { viewportSelector } from "../../../../../store/pixi/viewport/viewport.selectors";
import {
    bootColorSelectorFactory,
    connectorColorsSelectorFactory,
    defaultTriggerColorSelectorFactory,
    trunkColorSelector,
} from "../../../../../store/workspace/boundaries/boundaries.selectors";
import { getBounds } from "../../../../../store/workspace/build.selectors";
import { DropContext } from "../../../../../store/workspace/build/build";
import { VERTICAL_TRUNK_HEIGHT } from "../../drop-base/vertical-trunk/vertical-trunk.hooks";

export const useConnectorFurcation = ({ legProps }: IFurcationProps) => {
    const { color: markerColor } = useContext(DimensionLineContext);
    const {
        connectorsCollapsed,
        cablesTied,
        connectorType,
        groups,
        nbConnectors,
        furcationPoint,
        mesh,
        meshColor,
        containerName,
    } = useContext(DropContext);

    const connectorColorsSelector = useMemo(() => connectorColorsSelectorFactory(connectorType), [connectorType]);
    const defaultConnectorColorSelector = useMemo(
        () => defaultTriggerColorSelectorFactory(connectorType),
        [connectorType]
    );
    const bootColorSelector = useMemo(() => bootColorSelectorFactory(connectorType), [connectorType]);

    const connectorColors = useSelector(connectorColorsSelector);
    const defaultConnectorColor = useSelector(defaultConnectorColorSelector);
    const connectorBootColor = useSelector(bootColorSelector);

    const display = useSelector(texturesLoadedSelector);
    const { colorHex, name: trunkColor } = useSelector(trunkColorSelector);
    const { scale } = useSelector(viewportSelector);

    const drawFurcation = useCallback(
        (grfx: Graphics) => {
            grfx.clear();
            grfx.removeChildren();

            const cableWidth = getConnectorCableDrawWidth(connectorType);
            const cableWidthWithBorder = cableWidth + DROP_CABLE_BORDER_WIDTH;
            const cableColor = Number(colorHex);
            const maxCablesPerGroup = getMaxNumberOfCablesPerGroup(connectorType);
            const numberOfConnectors = Math.min(nbConnectors, maxCablesPerGroup);
            const start = getCableStartOffset(numberOfConnectors, cableWidthWithBorder, connectorType);
            const drawGrouped = !!mesh || !!cablesTied;
            const furcation = furcationPoint ?? IPointEmpty;
            const { width, height } = getConnectorTexture(connectorType);

            let previousGroupFinalPosition = 0;
            for (const group of groups) {
                const groupLegs = legProps
                    .filter((lp) => lp.groupIndex === group.position)
                    .sort((l) => l.connectorPosition);
                if (groupLegs.length === 0) continue;
                const groupNumberOfLegs = Math.min(groupLegs.length, maxCablesPerGroup);
                const groupEndpoint: IPoint =
                    groupLegs.length === 1
                        ? {
                              x: groupLegs[0].legEndpoint.x,
                              y: groupLegs[0].legEndpoint.y + groupLegs[0].staggerPosition * STAGGER_OFFSET_Y,
                          }
                        : // if multiple legs in group, the group should end at the middle of the legs
                          {
                              x: (groupLegs[0].legEndpoint.x + groupLegs.slice(-1)[0].legEndpoint.x) / 2,
                              y: groupLegs[0].legEndpoint.y + groupLegs[0].staggerPosition * STAGGER_OFFSET_Y,
                          };

                const groupWidth = cableWidthWithBorder * groupNumberOfLegs;
                const groupIndex = group.position ?? 0;
                const groupOffsetPosition = groupWidth * groupIndex;
                let positionOnFurcationPoint = 0;
                // If there are more legs in the group than "available connectors" on the trunk, always start at 0
                if (groupNumberOfLegs < numberOfConnectors) {
                    // If the drop's groups' legs can be evenly distributed on the trunk, position the groups on their offsets
                    if (numberOfConnectors % groupNumberOfLegs === 0) {
                        positionOnFurcationPoint = groupOffsetPosition;
                    } else {
                        // If the legs of the drop's groups can't be evenly distributed, calculate their positions
                        positionOnFurcationPoint =
                            groupOffsetPosition -
                            groupOffsetPosition / (previousGroupFinalPosition || 1) +
                            groupOffsetPosition / 2;
                    }
                }

                // If the group is placed and does not fit on the trunk (number of legs available), calculate and offset to place it correctly at the last available position
                let positionOnFurcationPointOffset = 0;
                if (positionOnFurcationPoint + groupWidth > numberOfConnectors * cableWidthWithBorder) {
                    positionOnFurcationPointOffset =
                        positionOnFurcationPoint + groupWidth - numberOfConnectors * cableWidthWithBorder;
                }

                const offsetFurcation: IPoint = {
                    ...furcation,
                    x: furcation.x + start + positionOnFurcationPoint - positionOnFurcationPointOffset,
                };
                previousGroupFinalPosition = numberOfConnectors % (groupIndex * groupNumberOfLegs);

                const legStart = getCableStartOffset(groupNumberOfLegs, cableWidthWithBorder, connectorType);
                const legsPerPosition = Math.floor(groupLegs.length / groupNumberOfLegs);
                let legInPosition = 0;
                for (let i = 0; i < groupLegs.length; i++) {
                    const leg = groupLegs[i];
                    const legPoint: IPoint = drawGrouped
                        ? {
                              x: groupEndpoint.x + (legStart + cableWidthWithBorder * legInPosition),
                              y: groupEndpoint.y + TIED_FURCATION_CURVE_END + TIED_GROUP_OFFSET_FROM_FURCATION_END,
                          }
                        : {
                              ...offsetFurcation,
                              x: offsetFurcation.x + legInPosition * cableWidthWithBorder,
                          };
                    const staggerOffsetY = leg.staggerPosition * STAGGER_OFFSET_Y;
                    const labelColor = AllColors.find((x) => x.name === leg.labelColor)?.hex;
                    drawLeg(
                        grfx,
                        cableColor,
                        cableWidth,
                        legPoint,
                        leg,
                        staggerOffsetY,
                        hexStringToNumber(labelColor ?? DEFAULT_LABEL_COLOR.hex)
                    );
                    if ((i + 1) % legsPerPosition === 0 && legInPosition < groupNumberOfLegs - 1) {
                        legInPosition++;
                    }
                }

                if (drawGrouped) {
                    drawGroup(
                        grfx,
                        offsetFurcation,
                        groupEndpoint,
                        cableColor,
                        groupNumberOfLegs,
                        cableWidth,
                        connectorType,
                        mesh
                    );
                    if (mesh) {
                        const meshTexture = Assets.get<Texture>(CROSSHATCH_TEXTURE);
                        drawGroupMesh(grfx, furcation, groupEndpoint, getMeshColor(meshColor), meshTexture, scale);
                    }
                }

                if (connectorsCollapsed) {
                    const { location, staggerPosition } = groupLegs[0];
                    const offsetX = groupLegs.length > 1 ? width + CONNECTOR_STEP_OFFSET * 0.5 : width * 0.5;
                    const staggerOffsetY = staggerPosition * STAGGER_OFFSET_Y;
                    const markersPoint: IPoint = {
                        x: location.x + offsetX,
                        y: location.y + height + staggerOffsetY + COLLAPSE_MARKER_OFFSET_Y,
                    };
                    drawCollapseMarker(grfx, markersPoint, markerColor);
                }
            }

            for (const leg of legProps) {
                const staggerOffsetY = leg.staggerPosition * STAGGER_OFFSET_Y;
                const connectorColor =
                    leg.connectorColor && connectorColors.some((c) => c.name === leg.connectorColor)
                        ? leg.connectorColor
                        : defaultConnectorColor;
                const type = getConnectorTypeString(connectorType);

                drawLabel(grfx, leg, staggerOffsetY, width, height);
                drawConnector(
                    grfx,
                    type,
                    connectorColor,
                    connectorBootColor,
                    trunkColor,
                    leg,
                    staggerOffsetY,
                    width,
                    height
                );
            }
        },
        [
            connectorType,
            colorHex,
            nbConnectors,
            mesh,
            cablesTied,
            furcationPoint,
            groups,
            legProps,
            connectorsCollapsed,
            meshColor,
            scale,
            markerColor,
            connectorColors,
            defaultConnectorColor,
            connectorBootColor,
            trunkColor,
        ]
    );

    return {
        display,
        containerName: getFurcationContainerName(containerName),
        drawFurcation,
        highlights: getConnectorHighlights(containerName, connectorType, legProps),
    };
};

const drawGroupMesh = (
    grfx: Graphics,
    startPoint: IPoint,
    endPoint: IPoint,
    meshColor: number,
    meshTexture: Texture,
    scale: IPoint
) => {
    const borderWidth = TRUNK_WIDTH + DROP_CABLE_BORDER_WIDTH;
    const GROUP_Y_OFFSET = 6;

    const draw = () => {
        grfx.moveTo(startPoint.x, startPoint.y - GROUP_Y_OFFSET);
        grfx.lineTo(startPoint.x, startPoint.y)
            .lineTo(endPoint.x, endPoint.y + TIED_FURCATION_CURVE_END)
            .lineTo(endPoint.x, endPoint.y + TIED_FURCATION_CURVE_END + TRUNK_WIDTH / 2);
    };

    // Border
    grfx.lineStyle({
        width: borderWidth,
        color: BORDER_COLOR,
        join: LINE_JOIN.ROUND,
    });
    draw();

    // Color
    grfx.lineStyle({
        width: TRUNK_WIDTH,
        color: meshColor,
        join: LINE_JOIN.ROUND,
    });
    grfx.moveTo(startPoint.x, startPoint.y - GROUP_Y_OFFSET);
    draw();

    // Mesh
    if (meshTexture) {
        grfx.lineTextureStyle(getLineMeshTextureOptions(TRUNK_WIDTH, meshTexture, scale));
    }
    draw();
};

const drawGroup = (
    grfx: Graphics,
    startPoint: IPoint,
    endPoint: IPoint,
    cableColor: number,
    numberOfLegs: number,
    cableWidth: number,
    connectorType: string,
    mesh?: boolean
) => {
    const cableAndBorderWidth = cableWidth + DROP_CABLE_BORDER_WIDTH;
    const GROUP_Y_OFFSET = 3;

    const start = getCableStartOffset(numberOfLegs, cableAndBorderWidth, connectorType);

    const draw = (startOffset: number, endOffset: number) => {
        if (mesh) {
            // If there's a mesh, only draw the last part of the grouped cable
            grfx.moveTo(endPoint.x + endOffset, endPoint.y + TIED_FURCATION_CURVE_END);
            grfx.lineTo(
                endPoint.x + endOffset,
                endPoint.y + TIED_FURCATION_CURVE_END + TIED_GROUP_OFFSET_FROM_FURCATION_END
            );
        } else {
            grfx.moveTo(startPoint.x + startOffset, startPoint.y - GROUP_Y_OFFSET);
            grfx.lineTo(startPoint.x + startOffset, startPoint.y)
                .lineTo(endPoint.x + endOffset, endPoint.y + TIED_FURCATION_CURVE_END)
                .lineTo(
                    endPoint.x + endOffset,
                    endPoint.y + TIED_FURCATION_CURVE_END + TIED_GROUP_OFFSET_FROM_FURCATION_END
                );
        }
    };

    for (let l = 0; l < numberOfLegs; l++) {
        const startOffset = cableAndBorderWidth * l;
        const endOffset = start + startOffset;

        // Border
        grfx.lineStyle({
            width: cableAndBorderWidth,
            color: BORDER_COLOR,
            join: LINE_JOIN.ROUND,
        });
        draw(startOffset, endOffset);

        // Color
        grfx.lineStyle({
            width: cableWidth,
            color: cableColor,
            join: LINE_JOIN.ROUND,
        });
        draw(startOffset, endOffset);
    }
};

export const drawLeg = (
    grfx: Graphics,
    cableColor: number,
    cableWidth: number,
    point: IPoint,
    leg: IConnectorLegProps,
    staggerOffsetY: number,
    labelColor?: number
) => {
    const { legEndpoint, location } = leg;
    const borderWidth = cableWidth + DROP_CABLE_BORDER_WIDTH;
    const labelDimensions = getLegLabelDimensions(borderWidth);

    // Top - Border
    grfx.lineStyle({
        width: borderWidth,
        color: BORDER_COLOR,
        cap: LINE_CAP.ROUND,
    });
    grfx.moveTo(point.x, point.y);
    grfx.lineTo(legEndpoint.x, legEndpoint.y + FURCATION_CURVE_END + staggerOffsetY);

    // Bottom - Border
    grfx.moveTo(legEndpoint.x, legEndpoint.y + FURCATION_CURVE_END + staggerOffsetY);
    grfx.lineTo(
        legEndpoint.x,
        legEndpoint.y +
            FURCATION_END +
            staggerOffsetY -
            (isLCDuplex(getConnectorTypeString(leg.connectorType ?? "")) ? LABEL_OFFSET_Y : 0)
    );

    // Top - Cable
    grfx.lineStyle({
        width: cableWidth,
        color: cableColor,
        cap: LINE_CAP.ROUND,
    });
    grfx.moveTo(point.x, point.y);
    grfx.lineTo(legEndpoint.x, legEndpoint.y + FURCATION_CURVE_END + staggerOffsetY);

    // Bottom - Cable
    grfx.moveTo(legEndpoint.x, legEndpoint.y + FURCATION_CURVE_END + staggerOffsetY);
    grfx.lineTo(
        legEndpoint.x,
        legEndpoint.y +
            FURCATION_END +
            staggerOffsetY -
            (isLCDuplex(getConnectorTypeString(leg.connectorType ?? "")) ? LABEL_OFFSET_Y : 0)
    );

    // Label rectangle
    grfx.lineStyle(LABEL_BORDER_WIDTH, BORDER_COLOR);
    grfx.beginFill(Number(labelColor ?? LABEL_COLOR));
    grfx.drawRect(
        legEndpoint.x - labelDimensions.width / 2,
        location.y + staggerOffsetY - LABEL_OFFSET_Y,
        labelDimensions.width,
        labelDimensions.height
    );
    grfx.endFill();
};

export const drawConnector = (
    grfx: Graphics,
    type: string,
    connectorColor: string,
    connectorBootColor: string,
    jacketColor: string,
    leg: IConnectorLegProps,
    staggerOffsetY: number,
    width: number,
    height: number
) => {
    const { location, connectorType } = leg;
    const isDrawingLCDuplex = isLCDuplex(getConnectorTypeString(connectorType ?? ""));
    const duplexOffset = isDrawingLCDuplex ? DUPLEX_VERTICAL_OFFSET : 0;
    let key = `${type}_${connectorColor}_${connectorBootColor}`;
    if (isDrawingLCDuplex) {
        key = `${type}_${connectorColor}_${jacketColor}-jacket_${connectorBootColor}`;
    }
    let texture = Assets.get<Texture>(key);
    if (texture === undefined) {
        key = `${type}_${connectorColor}`;
        texture = Assets.get<Texture>(key);
    }

    if (texture) {
        const connector = new Sprite(texture);
        const offsetX = isMMC(type) ? -1.5 : 0;
        connector.x = location.x + offsetX;
        connector.y = location.y + staggerOffsetY - duplexOffset;
        connector.width = width;
        connector.height = height;
        grfx.addChild(connector);
    }
};

export const drawLabel = (
    grfx: Graphics,
    leg: IConnectorLegProps,
    staggerOffsetY: number,
    width: number,
    height: number
) => {
    const { location, text, connectorType } = leg;
    const { scale: defaultScale, ...textStyle } = DEFAULT_TEXT_STYLE;
    const duplexOffset = isLCDuplex(getConnectorTypeString(connectorType ?? "")) ? DUPLEX_VERTICAL_OFFSET : 0;
    const style = new TextStyle(textStyle);
    const label = new Text(text, style);
    label.x = location.x + width * 0.5;
    label.y = location.y + staggerOffsetY + height - duplexOffset;
    label.anchor.x = 0.5;
    label.resolution = DEFAULT_TEXT_RESOLUTION;
    label.roundPixels = true;
    label.scale.x = label.scale.x * 0.5;
    label.scale.y = label.scale.y * 0.5;

    grfx.addChild(label);
};

export const getConnectorHighlights = (
    containerName: string,
    connectorType: string,
    legProps: IConnectorLegProps[]
): IConnectorHighlightProps[] => {
    const { width, height } = getConnectorTexture(connectorType);
    const highlights: IConnectorHighlightProps[] = [];
    for (let legIndex = 0; legIndex < legProps.length; legIndex++) {
        const leg = legProps[legIndex];
        if (leg.connectorId) {
            const staggerOffsetY = leg.staggerPosition * STAGGER_OFFSET_Y;
            highlights.push({
                key: `${containerName}_highlight_${leg.connectorPosition}`,
                connectorId: leg.connectorId,
                bounds: getBounds(leg.location.x - 2, leg.location.y + staggerOffsetY - 2, width + 4, height + 4),
            });
        }
    }
    return highlights;
};

export const getLabelText = (enabled: boolean, count: number, type: string) => {
    return enabled ? count.toString() + " X " + type + " connector(s)" : "Source connector(s)";
};

export const getFurcationPoint = (index: number, shell?: string) => {
    let x = shell === "soft" ? FURCATION_OFFSET_X : 0;

    if (index === 0) {
        x = -FURCATION_OFFSET_X;
    }

    return { x, y: VERTICAL_TRUNK_HEIGHT };
};

export const getConnectorAnchorPoint = (furcationPoint: IPoint) => {
    return { x: furcationPoint.x, y: furcationPoint.y + FURCATION_END };
};

export const getStaggerPosition = (
    stagger: IUnitOfMeasure | undefined,
    index: number,
    customBLength: boolean = false
) => {
    return (stagger && stagger.value > 0) || customBLength ? index : 0;
};

import React, { useMemo, useReducer } from "react";
import { BLengthSection } from "../b-length-section/b-length-section";
import { CustomBLength } from "../b-length-section/custom-b-length/custom-b-length";
import { UniformBLength } from "../b-length-section/uniform-b-length";
import { BundleCount } from "../bundle-count/bundle-count";
import { FlameRating } from "../flame-rating/flame-rating";
import { MeshProperties } from "../mesh-properties/mesh-properties";
import { StaggerAutocomplete } from "../stagger/stagger-autocomplete";
import { useSourceSetup } from "./source-setup.hooks";
import {
    SourceSetupContext,
    SourceSetupReducer,
} from "../../../../../store/overlay/wizard/setup/source-setup/source-setup.reducers";
import {
    ISourceSetupContentProps,
    ISourceSetupContext,
} from "../../../../../store/overlay/wizard/setup/source-setup/source-setup";
import { Checkbox, Select, SelectItem as Option, TextField } from "@corning-ctcm/silica-react";
import { PullingGrip, getPullingGripText } from "../../../../../models/pixi/decorators/markers/pulling-grip";

const SourceSetup: React.FC = () => {
    const {
        disabled,
        mode,
        connectorType,
        fiberCountPerConnectorProps,
        bundleCountProps,
        fiberType,
        cableOuterDiameter,
        groupProps,
        cpgProps,
        meshProps,
        overallLengthTypeProps,
        overallLengthProps,
        bLengthTypeProps,
        bLengthProps,
        sLengthProps,
        pullingGrip,
        reverseStaggeringProps,
    } = useSourceSetup();

    return (
        <div className="setup">
            <div className="field-container">
                <Select {...mode.props}>
                    {mode.options.map((o, i) => (
                        <Option id={`mode-type-option-${i}`} key={o.value} value={o.value}>
                            {o.name}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="field-container">
                <Select {...connectorType.props}>
                    {connectorType.options.map((c, i) => (
                        <Option id={`connector-type-option-${i}`} key={c.key} value={c.type}>
                            {c.description}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="field-container">
                <TextField {...fiberCountPerConnectorProps} />
            </div>
            <div className="field-container">
                <BundleCount {...bundleCountProps} />
            </div>
            <div className="field-container">
                <FlameRating disabled={disabled} />
            </div>
            <div className="field-container">
                <Select {...pullingGrip.selectProps}>
                    {pullingGrip.options.map((pg, i) => (
                        <Option id={`pulling-grip-option-${i}`} key={`pulling-grip-${pg}`} value={pg}>
                            {getPullingGripText(pg as PullingGrip)}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="field-container">
                <Select {...cableOuterDiameter.props}>
                    {cableOuterDiameter.options.map((d, i) => (
                        <Option id={`outer-diameter-option-${i}`} key={`diameter-${d}`} value={d}>
                            {d}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="field-container">
                <Select {...fiberType.props}>
                    {fiberType.options.map((f, i) => (
                        <Option id={`fiber-type-option-${i}`} key={f.id} value={f.id}>
                            {f.description}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="field-container horizontal">
                <div className="group-field field">
                    <TextField {...groupProps} />
                </div>
                <div className="connector-separator">X</div>
                <div className="cpg-field field">
                    <TextField {...cpgProps} />
                </div>
            </div>
            <MeshProperties {...meshProps} />
            <div className="field-container">
                <Select {...overallLengthTypeProps.props}>
                    {overallLengthTypeProps.options.map((c, i) => (
                        <Option id={`overall-length-type-option-${i}`} key={c.key} value={c.key}>
                            {c.description}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="field-container">
                <TextField {...overallLengthProps} />
            </div>
            <div className="field-container">
                <BLengthSection {...bLengthTypeProps}>
                    <UniformBLength>
                        <div className="field-container horizontal">
                            <div className="field">
                                <TextField {...bLengthProps.textField} />
                            </div>
                            <StaggerAutocomplete {...sLengthProps} />
                        </div>
                        <div className="field-container reverse-staggering">
                            <Checkbox {...reverseStaggeringProps} />
                        </div>
                    </UniformBLength>
                    <CustomBLength disabled={disabled} />
                </BLengthSection>
            </div>
        </div>
    );
};

export const SourceContent: React.FC<ISourceSetupContentProps> = ({ initialState }) => {
    const [state, dispatch] = useReducer(SourceSetupReducer, initialState);
    const context: ISourceSetupContext = useMemo(() => ({ state, dispatch }), [state, dispatch]);

    return (
        <SourceSetupContext.Provider value={context}>
            <SourceSetup />
        </SourceSetupContext.Provider>
    );
};

import { useCallback } from "react";
import { showSettingsSelector } from "../../../../../store/overlay/overlay.selectors";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setShowSettings } from "../../../../../store/overlay/overlay.reducers";
import { LocalizationKeys } from "../../../../../locales/keys";
import { disabledUISelector } from "../../../../../store/overlay/header/status-icon/status-icon.selectors";
import { currentBuildFinalizedSelector } from "../../../../../store/workspace/build.selectors";
import { IconButtonProps } from "@corning-ctcm/silica-react";

export const useSettingsButton = () => {
    const open = useSelector(showSettingsSelector);
    const disabledUI = useSelector(disabledUISelector);
    const buildFinalized = useSelector(currentBuildFinalizedSelector);
    const { t } = useTranslation();
    const storeDispatch = useDispatch();

    const onClick = useCallback(() => {
        storeDispatch(setShowSettings(true));
    }, [storeDispatch]);

    const buttonProps: Omit<IconButtonProps, "icon"> = {
        id: "settings-icon",
        palette: "primary",
        mode: "main",
        disabled: open || disabledUI || buildFinalized,
        title: t(LocalizationKeys.Settings),
        placement: "bottom",
        onClick,
    };

    return { buttonProps };
};

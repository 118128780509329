import React from "react";
import { IConnectorPinProps } from "../../../../../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";
import { useConnectorPin } from "./fiber-mapping-connector-templates.hooks";
import { Tooltip } from "@corning-ctcm/silica-react";

export const ConnectorPin: React.FC<IConnectorPinProps> = (props) => {
    const { side, index, tooltip, className, drawingProps, unused, disabled } = useConnectorPin(props);
    return (
        <Tooltip arrow title={tooltip} placement="bottom">
            <svg
                id={`${side}-pin-${index}`}
                className={className}
                width="42"
                height="42"
                viewBox="0 0 42 42"
            >
                <defs>
                    <filter id="connectorPinFilter" x="0" y="0" width="42" height="42" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" in="SourceAlpha" />
                        <feGaussianBlur stdDeviation="1.5" result="b" />
                        <feFlood floodOpacity="0.161" />
                        <feComposite operator="in" in2="b" />
                        <feComposite in="SourceGraphic" />
                    </filter>
                </defs>
                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter={disabled ? "" : "url(#connectorPinFilter)"}>
                    <circle
                        className="connector-pin-highlight"
                        transform="translate(7.5 6.5)"
                        cx="13"
                        cy="13"
                        r="20"
                        fillOpacity="0"
                    />
                    <g
                        transform="translate(7.5 6.5)"
                        fill={drawingProps.fill}
                        fillOpacity={drawingProps.fillOpacity}
                        stroke={drawingProps.stroke}
                        strokeWidth="2px"
                        strokeDasharray={drawingProps.strokeDashArray}
                    >
                        <circle cx="13" cy="13" r="13" stroke="none" />
                        <circle cx="13" cy="13" r="14" fill="none" />
                    </g>
                </g>
                {unused ? (
                    <line
                        x1="21"
                        y2="21"
                        transform="translate(10 9)"
                        fill="none"
                        stroke={drawingProps.stroke}
                        strokeWidth="2px"
                    />
                ) : disabled ? (
                    <g transform="translate(8.5 7.5)">
                        <rect width="24" height="24" fill="none" />
                        <path
                            d="M19,6.4,17.6,5,12,10.6,6.4,5,5,6.4,10.6,12,5,17.6,6.4,19,12,13.4,17.6,19,19,17.6,13.4,12Z"
                            fill="#aaa"
                            fillRule="evenodd"
                        />
                    </g>
                ) : (
                    <text
                        transform="translate(20.5 24.5)"
                        fill={drawingProps.textColor}
                        stroke="rgba(0, 0, 0, 0)"
                        fontSize="14px"
                        fontFamily="Inter"
                        fontWeight="bold"
                        textAnchor="middle"
                    >
                        <tspan x="0" y="0">
                            {drawingProps.text}
                        </tspan>
                    </text>
                )}
            </svg>
        </Tooltip>
    );
};

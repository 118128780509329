import { Dispatch } from "react";
import { Action } from "redux";

export interface INavigationBarState {
    currentIndex: number;
    disabled: boolean;
}

export const initialNavigationBarState: INavigationBarState = {
    currentIndex: 0,
    disabled: false,
};

export interface INavigationBarContext {
    state: INavigationBarState;
    dispatch: Dispatch<Action>;
}

export const initialNavigationBarContext: INavigationBarContext = {
    state: initialNavigationBarState,
    dispatch: () => {},
};

import { useFullscreenButton } from "./fullscreen-button.hooks";
import { IconButton } from "@corning-ctcm/silica-react";
import i18n from "i18next";
import { ReactComponent as ExitFullscreenIcon } from "../../../../../resources/svgs/overlay/header/icon_exit_fullscreen.svg";
import { ReactComponent as EnterFullscreenIcon } from "../../../../../resources/svgs/overlay/header/icon_enter_fullscreen.svg";
import { LocalizationKeys } from "../../../../../locales/keys";

export const FullscreenButton = () => {
    const { buttonProps, showButton, isFullscreen } = useFullscreenButton();
    const { icon, label } = getFullscreenInfo(isFullscreen);
    return showButton ? (
        <IconButton {...buttonProps} title={label} icon={icon}/>
    ) : null;
};

const getFullscreenInfo = (isFullscreen: boolean): { icon: JSX.Element; label: string } => {
    let icon = null;
    let label = "";
    if (isFullscreen) {
        icon = <ExitFullscreenIcon />;
        label = i18n.t(LocalizationKeys.ExitFullscreen);
    } else {
        icon = <EnterFullscreenIcon />;
        label = i18n.t(LocalizationKeys.EnterFullscreen);
    }

    return { icon, label };
};
import TagManager from "react-gtm-module";

export enum TagManagerConfigType {
    Create = "createRAB",
    Edit = "editRAB",
    Delete = "deleteRAB",
}

export class GoogleTagManager {
    static initialize() {
        const gtmId = (window as any).googleTagManagerId;
        if (gtmId) {
            TagManager.initialize({ gtmId: gtmId });
        }
    }

    static event(type: TagManagerConfigType, catalogCode: string, accountUid: string, accountName: string) {
        if (!(window as any).googleTagManagerId) return;

        TagManager.dataLayer({
            dataLayer: {
                configType: type,
                build: {
                    id: catalogCode,
                    "sold-to": accountUid,
                    customer: accountName,
                },
            },
        });
    }
}

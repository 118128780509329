import React from "react";
import { useBoundariesSettings } from "./boundaries-settings.hooks";
import { BoundariesRow } from "./boundaries-row";
import { 
    Typography, 
    OutlinedButton, 
    TextButton, 
    InlineText, 
    TextField, 
    List, 
    ContainedButton, 
    Dialog as SilicaDialog
} from "@corning-ctcm/silica-react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DialogHeader } from "../../ui/dialog/dialog-header/dialog-header";

import "../../../css/overlay/boundaries-settings.scss";

export const BoundariesSettings: React.FC = () => {
    const {
        dialogProps,
        dialogHeaders,
        actions,
        deleteConfirmDialog,
        subtitle,
        isModifying,
        createButton,
        editDataProps,
        editNameProps,
        importButton,
        rows,
    } = useBoundariesSettings();
    // We are currently using the native MUI Dialog due to a bug with TextFields in the Silica component.
    // This should be changed once the bug is fixed in the library.
    // Refer to PLAT-8 for more details.
    return (
        <Dialog {...dialogProps}>
            <DialogTitle>
                <DialogHeader {...dialogHeaders}/>
            </DialogTitle>
            <DialogContent>
                <div className={isModifying ? "boundaries-actions modifying" : "boundaries-actions"}>
                    {isModifying ? (
                        <>
                            {editNameProps.isEditable ? (
                                <InlineText {...editNameProps.props} />
                            ) : (
                                <Typography variant="body1">
                                    {editNameProps.props.value}
                                </Typography>
                            )}
                        </>
                    ) : (
                        <TextButton {...createButton.props}>{createButton.label}</TextButton>
                    )}
                    {isModifying && (
                        <OutlinedButton component={"label"}>
                            {importButton.label}
                            <input type="file" hidden onClick={importButton.onClick} onChange={importButton.onChange} />
                        </OutlinedButton>
                    )}
                </div>
                <div className={isModifying ? "boundaries-content modifying" : "boundaries-content"}>
                    <div className={subtitle.className}>
                        <Typography variant={subtitle.variant}>
                            {subtitle.label}
                        </Typography>
                    </div>
                    {isModifying ? (
                        <TextField multiline maxRows={8} {...editDataProps} />
                    ) : (
                        <List disablePadding className="boundaries-list">
                            {rows.map((row) => (
                                <BoundariesRow key={row.label} {...row} />
                            ))}
                        </List>
                    )}
                </div>
            </DialogContent>
            { 
                actions ? (
                    <DialogActions>
                        <TextButton onClick={actions.onCancelClick}>
                            {actions.cancelText}
                        </TextButton>
                        <ContainedButton disabled={actions.disableConfirm} onClick={actions.onConfirmClick}>
                            {actions.confirmText}
                        </ContainedButton>
                    </DialogActions>
                ): null
            }
            <SilicaDialog {...deleteConfirmDialog} />
        </Dialog>
    );
};

import { ChangeEvent, useCallback } from "react";
import { ITolerancesField } from "../../../../../models/overlay/header/tolerances/tolerances-field";
import {
    decimalPlacesBasedOnUnit,
    getDecimalPlaces,
    getUnitsName,
} from "../../../../../models/overlay/header/units-of-measure";
import { useSelector } from "react-redux";
import { unitsOfMeasureContainerUnitSelector } from "../../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { TextFieldProps } from "@corning-ctcm/silica-react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import { currentBuildFinalizedSelector } from "../../../../../store/workspace/build.selectors";
import { isLockedSelector } from "../../../../../store/overlay/header/status-icon/status-icon.selectors";

export const useTolerancesField = ({ id, label, tolerance, onChange }: ITolerancesField) => {
    const { t } = useTranslation();
    const currentUnit = useSelector(unitsOfMeasureContainerUnitSelector);
    const maxDecimalPlaces = decimalPlacesBasedOnUnit(currentUnit);
    const buildFinalized = useSelector(currentBuildFinalizedSelector);
    const locked = useSelector(isLockedSelector);
    const disabled = buildFinalized || locked;

    const onMinChange = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            if (getDecimalPlaces(event.target.value) > maxDecimalPlaces) return;

            const value = parseFloat(event.target.value);
            const min = value * (value > 0 ? -1 : 1);
            const max = tolerance.max;

            onChange({ min, max });
        },
        [tolerance.max, maxDecimalPlaces, onChange]
    );

    const onMaxChange = useCallback(
        (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            if (getDecimalPlaces(event.target.value) > maxDecimalPlaces) return;

            const value = parseFloat(event.target.value);
            const max = value * (value < 0 ? -1 : 1);
            const min = tolerance.min;

            onChange({ min, max });
        },
        [tolerance.min, maxDecimalPlaces, onChange]
    );

    const units = getUnitsName(currentUnit, false, true);
    const minValue = isNaN(tolerance.min) ? "" : tolerance.min.toString();
    const minHelperText = minValue.length === 0 ? t(LocalizationKeys.InvalidEntry) : undefined;
    const minProps: TextFieldProps = {
        id: `${id}-min-field`,
        palette: "primary",
        type: "number",
        label: t(LocalizationKeys.Min),
        value: minValue,
        error: minValue.length === 0,
        helperText: minHelperText,
        units,
        onChange: onMinChange,
        disabled,
    };

    const maxValue = isNaN(tolerance.max) ? "" : tolerance.max.toString();
    const maxHelperText = maxValue.length === 0 ? t(LocalizationKeys.InvalidEntry) : undefined;
    const maxProps: TextFieldProps = {
        id: `${id}-max-field`,
        palette: "primary",
        type: "number",
        label: t(LocalizationKeys.Max),
        value: maxValue,
        error: maxValue.length === 0,
        helperText: maxHelperText,
        units,
        onChange: onMaxChange,
        disabled,
    };

    return {
        label,
        minProps,
        maxProps,
    };
};

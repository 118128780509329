import { IPropagationOptions } from "../store/overlay/polarity/propagation/propagation";
import { LabelCustomizationOptions } from "../models/overlay/reports/label-scheme/label-scheme";
import { IBuildData, IBuildInfo } from "../store/workspace/build/build";
import { IParsedProjectBuildResponse, IProjectBuildResponse, IProjectIdData, IProjectManagerData } from "../store/workspace/project-manager/project-manager";
import { getCurrentISODate, ToBuildData } from "./build.service";
import { SecuredService } from "./abstract-secured-v2.service";
import { BuildDTO, BuildInfoDTO, ILabelSchemeResponse, ProjectDTO, ProjectManagerDTO } from "../models/services/services";

export class ProjectManagerService extends SecuredService {
    private readonly baseUrl = "/api/projectmanager";

    public async getProjectManager(): Promise<IProjectManagerData | undefined> {
        return await this.get<IProjectManagerData>(`${this.baseUrl}`);
    }

    public async getProject(projectId: number): Promise<IBuildData | undefined> {
        const build = await this.get<BuildDTO>(`${this.baseUrl}/project/${projectId}`);
        return build && ToBuildData(build);
    }

    public async updatedBuildInfo(buildInfo: IBuildInfo): Promise<BuildInfoDTO | undefined> {
        return await this.put<BuildInfoDTO>(`${this.baseUrl}/info`, ToBuildInfoDTO(buildInfo));
    }

    public async getBuildsForGroupByPage(pageNumber: number, search?: string): Promise<IBuildInfo[] | undefined> {
        return await this.get(`${this.baseUrl}/all/group/${pageNumber}?search=${search}`);
    }

    public async updateSortType(
        sortType: string,
        pageIndex: number,
        search?: string
    ): Promise<IBuildInfo[] | undefined> {
        return await this.post(`${this.baseUrl}/sorttype`, { sortType, pageIndex, search });
    }

    public async updateSortOrder(
        isSortAscending: boolean,
        pageIndex: number,
        search?: string
    ): Promise<IBuildInfo[] | undefined> {
        return await this.post(`${this.baseUrl}/sortorder`, { isSortAscending, pageIndex, search });
    }

    public async unlockBuild(id: number): Promise<IBuildData | undefined> {
        const dto = await this.post<BuildDTO>(`${this.baseUrl}/unlock/${id}`, id);
        if (dto) {
            return ToBuildData(dto);
        }
        return dto;
    }

    public async lockBuild(id: number): Promise<IBuildData | undefined> {
        const dto = await this.post<BuildDTO>(`${this.baseUrl}/lock/${id}`, id);
        if (dto) {
            return ToBuildData(dto);
        } 
        return dto;
    }

    public async applyLabelScheme(
        buildId: number,
        schemeId: number,
        side: string,
        dropPosition: number,
        options: LabelCustomizationOptions,
        propagationOptions: IPropagationOptions
    ): Promise<ILabelSchemeResponse | undefined> {
        return await this.post(`${this.baseUrl}/labelScheme`, {
            buildId,
            schemeId,
            side,
            dropPosition,
            options,
            propagationOptions,
        });
    }

    public async duplicateProject(
        buildId: number,
        buildName: string,
        pageIndex: number,
        search?: string
    ): Promise<IParsedProjectBuildResponse | undefined> {
        const buildResponse = await this.post<IProjectBuildResponse>(`${this.baseUrl}/duplicate`, { buildId, buildName, pageIndex, search });
        if (buildResponse) {
            const { builds, totalBuildCount } = buildResponse;
            const parsedRes: IParsedProjectBuildResponse = { builds, totalBuildCount};
            if (buildResponse.build) {
                parsedRes.build = ToBuildData(buildResponse.build);
            }
            return parsedRes;
        }
        return buildResponse;
    }

    public async updateRecentProject(recentProjectId: number): Promise<IParsedProjectBuildResponse | undefined> {
        const buildResponse = await this.put<IProjectBuildResponse>(`${this.baseUrl}/update/${recentProjectId}`, { recentProjectId });
        if (buildResponse && buildResponse.build) {
            return { build: ToBuildData(buildResponse.build) };
        }
        return undefined;
    }

    public async deleteBuild(
        buildId: number,
        pageIndex: number,
        search?: string
    ): Promise<IParsedProjectBuildResponse | undefined> {
        const buildResponse = await this.delete<IProjectBuildResponse>(`${this.baseUrl}/project/${buildId}/page/${pageIndex}`, { search });
        if (buildResponse) {
            const { builds, totalBuildCount } = buildResponse;
            const parsedRes: IParsedProjectBuildResponse = { builds, totalBuildCount};
            if (buildResponse.build) {
                parsedRes.build = ToBuildData(buildResponse.build);
            }
            return parsedRes;
        }
        return buildResponse;
    }
}

export function ToProjectManagerDTO(data: IProjectManagerData): ProjectManagerDTO {
    return {
        sortType: data.sortType,
        isSortAscending: data.isSortAscending,
        projectIds: data.projectIds ?? [],
        recentProjectId: data.recentProjectId,
        totalBuildCount: data.totalBuildCount ?? 0,
    };
}

export function ToBuildInfoDTO(data: IBuildInfo): BuildInfoDTO {
    return {
        buildId: data.buildId,
        partNumber: data.partNumber,
        description: data.description,
        name: data.name,
        lastModified: getCurrentISODate(),
    };
}

export function ToProjectDTO(data: IProjectIdData): ProjectDTO {
    return {
        id: data.id,
        projectId: data.projectId,
    };
}

export function ToProjectManagerData(dto: ProjectManagerDTO): IProjectManagerData {
    return {
        projectIds: dto.projectIds!,
        sortType: dto.sortType,
        isSortAscending: dto.isSortAscending,
        builds: dto.builds!,
        recentProjectId: dto.recentProjectId!,
        totalBuildCount: dto.totalBuildCount,
    };
}

export function ToProjectIdsData(dtos: ProjectDTO[]): number[] {
    return dtos.map((b) => b.projectId);
}

export function ToProjectIdData(dto: ProjectDTO): IProjectIdData {
    return {
        id: dto.id,
        projectId: dto.projectId,
    };
}

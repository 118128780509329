import { useViewProduct } from "./view-product.hooks";
import { Tooltip, TextButton } from "@corning-ctcm/silica-react";

export const ViewProduct = () => {
    const { label, tooltip, buttonProps } = useViewProduct();
    return (
        <Tooltip placement={"bottom"} title={tooltip} arrow>
            <span>
                <TextButton { ...buttonProps }>
                    {label}
                </TextButton>
            </span>
        </Tooltip>
    );
};

import { createContext, Dispatch } from "react";

export interface IFiberMappingNavigation {
    feeder: INavigationMap;
    distribution: IDistributionNavigationMap;
    selectedPinIndex: number;
}

export interface INavigationMap {
    currentItem: number;
    items: number[];
}

export interface IDistributionNavigationMap {
    taps: INavigationMap;
    connectors: INavigationMap;
}

export interface IFiberMap {
    feederPin: IPinMap;
    distributionPin: IPinMap;
}

export interface IPinMap {
    index: number; // 1 based
    connectorId: number;
}

export interface IFiberMappingState {
    navigation: IFiberMappingNavigation;
    mapping: IFiberMap[];
    unused: {
        sourcePins: IPinMap[];
        destinationPins: IPinMap[];
    };
    isModified: boolean;
}

export const initialNavigationMap: INavigationMap = {
    currentItem: -1,
    items: [],
};

export const initialFiberMappingState: IFiberMappingState = {
    navigation: {
        feeder: initialNavigationMap,
        distribution: {
            taps: initialNavigationMap,
            connectors: initialNavigationMap,
        },
        selectedPinIndex: -1,
    },
    mapping: [],
    unused: {
        sourcePins: [],
        destinationPins: [],
    },
    isModified: false,
};

export interface IFiberMappingContext {
    state: IFiberMappingState;
    dispatch: Dispatch<any>;
}

export const initialFiberMappingContext: IFiberMappingContext = {
    state: initialFiberMappingState,
    dispatch: () => {},
};

export const FiberMappingContext = createContext(initialFiberMappingContext);
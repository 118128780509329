import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sscCurrentSessionIdSelector, sscSessionBusySelector } from "../../../../store/workspace/ssc/ssc.selectors";
import { LocalizationKeys } from "../../../../locales/keys";
import { disabledUISelector } from "../../../../store/overlay/header/status-icon/status-icon.selectors";
import { useCallback } from "react";
import { rabSessionService } from "../../../../services/rab-session.service";
import {
    buildIdSelector,
    currentBuildFinalizedSelector,
    currentBuildPartNumberSelector,
} from "../../../../store/workspace/build.selectors";
import { showViewProductSelector } from "../../../../store/overlay/overlay.selectors";
import { setShowAnonymousUserWarning, setShowViewProduct } from "../../../../store/overlay/overlay.reducers";
import { hybrisCustomerSelector, isAnonymousUserSelector } from "../../../../store/authentication/authentication.selectors";
import { GoogleTagManager, TagManagerConfigType } from "../../../../tag-manager";
import { BuildService } from "../../../../services/build.service";
import { setCatalogCode } from "../../../../store/workspace/build/build.reducers";
import { setSessionBusy, setSessionWarnings } from "../../../../store/workspace/ssc/ssc.reducer";
import { setStatusState } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import { WorkspaceStatus } from "../../../../store/overlay/header/status-icon/status-icon";
import { setNotification } from "../../../../store/overlay/notification/notification.reducers";
import { AlertPalettes, ButtonProps } from "@corning-ctcm/silica-react";
import { isDrawingModeSelector } from "../../../../store/workspace/boundaries/boundaries.selectors";
import { setSelectedBuildId, showCableInfo } from "../../../../store/overlay/project/project.reducers";

export const useViewProduct = () => {
    const disableUI = useSelector(disabledUISelector);
    const sessionBusy = useSelector(sscSessionBusySelector);
    const isAnonymousUser = useSelector(isAnonymousUserSelector);
    const buildFinalized = useSelector(currentBuildFinalizedSelector);
    const isDrawingMode = useSelector(isDrawingModeSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const label = t(LocalizationKeys.ViewProduct).toLocaleUpperCase();
    const disableButton = disableUI || sessionBusy || buildFinalized || isDrawingMode;
    const tooltip = disableButton ? undefined : t(LocalizationKeys.FinalizeCable);

    const showViewProductDialog = useCallback(async () => {
        if (isAnonymousUser) {
            dispatch(setShowAnonymousUserWarning(true));
        } else {
            dispatch(setShowViewProduct(true));
        }
    }, [dispatch, isAnonymousUser]);

    const buttonProps: ButtonProps = {
        id: "view-product-button",
        palette: "primary",
        mode: "main",
        disabled: disableButton,
        onClick: showViewProductDialog,
    };

    return {
        buttonProps,
        label,
        tooltip,
    };
};

export const useViewProductDialog = () => {
    const sessionId = useSelector(sscCurrentSessionIdSelector);
    const customer = useSelector(hybrisCustomerSelector);
    const showViewProduct = useSelector(showViewProductSelector);
    const currentBuildId = useSelector(buildIdSelector);
    const currentPartNumber = useSelector(currentBuildPartNumberSelector);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const message =
        currentPartNumber.length > 0
            ? t(LocalizationKeys.ViewProductDialogMessage)
            : t(LocalizationKeys.ViewProductErrorMessage);

    const closeDialog = useCallback(() => {
        dispatch(setShowViewProduct(false));
    }, [dispatch]);

    const navigateToPDP = useCallback(
        (catalogCode: string) => {
            if (customer && catalogCode.length) {
                const portals = customer.isAnonymous ? "" : "portals/";
                window.location.href = `/optical-communications/US/en/${portals}p/${catalogCode}`;
            }
        },
        [customer]
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const generateCatalogCodeAnd3C = useCallback(async () => {
        closeDialog();
        if (!sessionId) {
            navigateToPDP(currentPartNumber);
            return;
        }
        dispatch(setSessionBusy(true));
        dispatch(setStatusState(WorkspaceStatus.Saving));
        let catalogCode = currentPartNumber;
        if (!catalogCode.length) {
            const generateCatalogCodeResponse = await rabSessionService.finalizeBuild(sessionId);
            if (generateCatalogCodeResponse && generateCatalogCodeResponse.catalogCode) {
                catalogCode = generateCatalogCodeResponse.catalogCode;
                if (currentBuildId > 0) {
                    const service = new BuildService();
                    await service.updateBuildCatalogCode(currentBuildId, catalogCode);
                }
                dispatch(setCatalogCode(catalogCode));
                dispatch(setSessionWarnings({ buildId: currentBuildId, warnings: [] }));
                if (customer) {
                    GoogleTagManager.event(
                        TagManagerConfigType.Create,
                        catalogCode,
                        customer.account.uid,
                        customer.account.name
                    );
                }
                await rabSessionService.endSession(sessionId);
            } else {
                dispatch(
                    setNotification({
                        palette: AlertPalettes.error,
                        message: t(LocalizationKeys.ErrorRetrievingCatalogCode),
                    })
                );
            }
        }
        dispatch(setSessionBusy(false));
        dispatch(setStatusState(WorkspaceStatus.Saved));
        navigateToPDP(catalogCode);
    }, [dispatch, sessionId, customer, currentBuildId, currentPartNumber, closeDialog, navigateToPDP, t]);

    const generateCatalogCodeNoHybris = useCallback(async () => {
        closeDialog();
        dispatch(setStatusState(WorkspaceStatus.Saving));
        if (currentBuildId > 0) {
            const service = new BuildService();
            await service.updateBuildCatalogCode(currentBuildId, currentPartNumber);
        }
        dispatch(setCatalogCode(currentPartNumber));
        dispatch(setStatusState(WorkspaceStatus.Saved));
    }, [closeDialog, dispatch, currentBuildId, currentPartNumber]);

    const openCableInfo = useCallback(() => {
        closeDialog();
        if (currentBuildId > 0) {
            dispatch(setSelectedBuildId(currentBuildId));
            dispatch(showCableInfo(true));
        }
    }, [closeDialog, currentBuildId, dispatch]);

    const dialogHeaderProps = {
        title: t(LocalizationKeys.ViewProduct),
        closable: true,
    };

    const continueProps = {
        label: t(LocalizationKeys.Continue),
        onClick: !!currentPartNumber ? generateCatalogCodeNoHybris : openCableInfo,
    };

    return {
        showViewProduct,
        dialogHeaderProps,
        message,
        continueProps,
        closeDialog,
    };
};

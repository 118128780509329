import { getUnitsName } from "../../../../models/overlay/header/units-of-measure";
import { WorkspaceState } from "../../../workspace/workspace.reducers";

export const unitsOfMeasureContainerSelector = (state: WorkspaceState) => state.unitsOfMeasureContainer;
export const unitsOfMeasureContainerDisplaySelector = (state: WorkspaceState) => state.unitsOfMeasureContainer.display;
export const unitsOfMeasureContainerUnitSelector = (state: WorkspaceState) => state.unitsOfMeasureContainer.unit;
export const unitsOfMeasureContainerUnitNameSelector = (state: WorkspaceState) =>
    getUnitsName(state.unitsOfMeasureContainer.unit, false, true);
export const unitsOfMeasureContainerPrimaryUnitSelector = (state: WorkspaceState) =>
    state.unitsOfMeasureContainer.primaryUnit;

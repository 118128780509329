import { TextStyleAlign, TextStyleFontWeight } from "pixi.js";

import { ITolerance } from "../../overlay/header/tolerances/tolerances";
import {
    convertToleranceToDisplay,
    Unit,
    Units,
} from "../../overlay/header/units-of-measure";

export const DEFAULT_TEXT_STYLE = {
    align: "center",
    fontSize: 24,
    fontFamily: "Inter",
    fontWeight: "normal",
    fill: 0,
    scale: 1,
    strokeThickness: 0.5,
} as const;

export type TextPosition = "start" | "middle" | "middle-out" | "end";

export interface ITextPathInputProps {
    index: number;
    value: string;
    onConfirm?: (value: string) => Promise<void>;
}
export interface ITextPath {
    name?: string;
    primary: string;
    secondary?: string;
    position: TextPosition;
    tolerance?: ITextTolerance;
    scale?: number;
    hideUnits?: boolean;
    inputProps?: ITextPathInputProps;
    maskLengths?: boolean;
}

export interface ITextTolerance {
    min: string;
    max: string;
    minColor?: number;
    maxColor?: number;
}

export interface ITextProps {
    text: string;
    align: TextStyleAlign;
    fill: number;
    fontFamily: string;
    fontWeight: TextStyleFontWeight;
    fontSize: number;
    strokeThickness: number;
    scale?: number;
}

export type TextProps = Partial<ITextProps>;

export const getToleranceText = (initialTolerance: ITolerance, tolerance: ITolerance, unit?: Unit): ITextTolerance => {
    const toUnit = unit ?? Units.UoMInches;
    const convertedTolerance = convertToleranceToDisplay(tolerance, Units.UoMInches, toUnit);
    const convertedInitialTolerance = convertToleranceToDisplay(initialTolerance, Units.UoMInches, toUnit);

    return {
        min: formatTolerance(convertedTolerance.min),
        max: formatTolerance(convertedTolerance.max),
        minColor:
            convertedTolerance.min === convertedInitialTolerance.min || isNaN(convertedTolerance.min)
                ? undefined
                : 0xe11725,
        maxColor:
            convertedTolerance.max === convertedInitialTolerance.max || isNaN(convertedTolerance.max)
                ? undefined
                : 0xe11725,
    };
};

export const formatTolerance = (value: number | undefined) => {
    if (value === undefined || isNaN(value)) return "0 ";

    return value > 0 ? `+${value.toString()} ` : `${value.toString()} `;
};

import React from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { useConnectorAssignmentDialog } from "./connector-assignment-dialog.hooks";
import { CollapsibleDialog } from "../../../ui/dialog/collapsible-dialog/collapsible-dialog";
import { ConnectorAssignmentContext } from "../../../../store/overlay/polarity/connector-assignment/connector-assignment";
import { DataTable, DataTableBody, DataTableContent } from "@rmwc/data-table";
import { ConnectorAssignmentRow } from "./connector-assignment-row/connector-assignment-row";
import { NavigationBar } from "../../../ui/navigation-bar/navigation-bar";
import { GenericDialog } from "../../../ui/dialog/generic-dialog";
import {
    getPolarityMapDescription,
} from "../../../../store/workspace/build/connector/polarity/polarity";
import { ContainedButton, TextButton, Checkbox, IconButton, Select, SelectItem as Option, Typography } from "@corning-ctcm/silica-react";
import { Propagation } from "../propagation/propagation";
import { ChevronLeft, ChevronRight, OpenInNew } from "@mui/icons-material";

import "@rmwc/data-table/styles";
import "../../../../css/overlay/polarity/connector-assignment-dialog.scss";

export const ConnectorAssignmentDialog: React.FC = () => {
    const {
        dialog,
        assignmentContext,
        selectAll,
        polarityAssignment,
        polarityType,
        configIcon,
        apply,
        sourceProps,
        leftTAPProps,
        rightTAPProps,
        resetAll,
        propagate,
        navigationBarProps,
    } = useConnectorAssignmentDialog();
    const { t } = useTranslation();

    if (!dialog.display) {
        return null;
    }
    return (
        <CollapsibleDialog {...dialog}>
            <div className="horizontal-divider" />
            <ConnectorAssignmentContext.Provider value={assignmentContext}>
                <div className="polarity-container">
                    <div className={selectAll.className}>
                        <Checkbox {...selectAll} />
                    </div>
                    <div className="actions-container">
                        <Select {...polarityAssignment.select}>
                            {
                                polarityAssignment.options.map((o, i) => (
                                    <Option key={i} value={o}>
                                        {o}
                                    </Option>
                                ))
                            }
                        </Select>
                        <Select {...polarityType.select}>
                            {
                                polarityType.options.map((o, i) => (
                                    <Option key={i} value={o.key}>
                                        {getPolarityMapDescription(o, t).toUpperCase()}
                                    </Option>
                                ))
                            }
                        </Select>
                        <div className="icon-button-border">
                            <IconButton {...configIcon} icon={<OpenInNew />} />
                        </div>
                        <ContainedButton {...apply.button}>
                            {apply.label}
                        </ContainedButton>
                    </div>
                </div>
                <div className="assignment-container">
                    <div className="local-container source">
                        <div className="container-header">
                            <Typography variant="body1" className="container-title">
                                {t(LocalizationKeys.Feeder)}
                            </Typography>
                        </div>
                        <DataTable className="assignment-table source" height={496}>
                            <DataTableContent>
                                <DataTableBody>
                                    {
                                        sourceProps.rows.map((r) => (
                                            <ConnectorAssignmentRow key={r.id} connector={r} editable={true} />
                                        ))
                                    }
                                </DataTableBody>
                            </DataTableContent>
                        </DataTable>
                    </div>
                    <div className="vertical-divider" />
                    <div className="local-container destination">
                        <div className={`container-header left-destination ${!rightTAPProps.display && "single-tap"}`}>
                            <IconButton
                                palette="primary"
                                mode="main"
                                icon={<ChevronLeft />}
                                disabled={leftTAPProps.previousDisabled}
                                title={t(LocalizationKeys.Previous)}
                                placement="bottom"
                                onClick={leftTAPProps.onPreviousClick}
                            />
                            <Typography variant="body1" className="container-title">
                                {leftTAPProps.title}
                            </Typography>
                        </div>
                        <DataTable className="assignment-table left-destination">
                            <DataTableContent>
                                <DataTableBody>
                                    {
                                        leftTAPProps.rows.map((r) => (
                                            <ConnectorAssignmentRow key={r.id} connector={r} editable={false} />
                                        ))
                                    }
                                </DataTableBody>
                            </DataTableContent>
                        </DataTable>
                    </div>
                    <div className="local-container destination">
                        {rightTAPProps.display && (
                            <>
                                <div className="container-header right-destination">
                                    <Typography variant="body1" className="container-title">
                                        {rightTAPProps.title}
                                    </Typography>
                                    <IconButton
                                        palette="primary"
                                        mode="main"
                                        icon={<ChevronRight />}
                                        disabled={rightTAPProps.nextDisabled}
                                        title={t(LocalizationKeys.Next)}
                                        placement="bottom"
                                        onClick={rightTAPProps.onNextClick}
                                    />
                                </div>
                                <DataTable className="assignment-table right-destination">
                                    <DataTableContent>
                                        <DataTableBody>
                                            {
                                                rightTAPProps.rows.map((r) => (
                                                    <ConnectorAssignmentRow key={r.id} connector={r} editable={false} />
                                                ))
                                            }
                                        </DataTableBody>
                                    </DataTableContent>
                                </DataTable>
                            </>
                        )}
                    </div>
                </div>
                <div className="reset-button-container">
                    <TextButton {...resetAll.button}>
                        {resetAll.label}
                    </TextButton>
                    <TextButton {...propagate.button}>
                        {propagate.label}
                    </TextButton>
                </div>
            </ConnectorAssignmentContext.Provider>
            <div className="horizontal-divider" />
            <NavigationBar {...navigationBarProps} />
            <GenericDialog {...resetAll.dialog} />
            <Propagation />
        </CollapsibleDialog>
    );
};

import React from "react";

import { Graphics } from "@pixi/react";

import { withMemoization } from "../../../../../components/pixi/memoization/memoization";
import { ConnectorHighlight } from "../connector-highlight/connector-highlight";
import { useConnectorFurcation } from "./connector-furcation.hooks";
import { areFurcationEqual, IFurcationProps } from "../../../../../models/pixi/build/connectors/connector-furcation";

export const ConnectorFurcationGraphics: React.FC<IFurcationProps> = (props) => {
    const { display, containerName, drawFurcation, highlights } = useConnectorFurcation(props);
    if (!display) {
        return null;
    }
    return (
        <>
            <Graphics name={containerName} draw={drawFurcation} />
            { highlights.map(({ key, ...highlight }) => <ConnectorHighlight key={key} {...highlight} />) }
        </>
    );
};

export const ConnectorFurcation = withMemoization(ConnectorFurcationGraphics, areFurcationEqual);

import { Assets, Graphics, Texture } from "pixi.js";
import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { trunkColorSelector } from "../../../../../store/workspace/boundaries/boundaries.selectors";
import { DropContext } from "../../../../../store/workspace/build/build";
import { CROSSHATCH_TEXTURE } from "../../../factories/texture";
import { VerticalTrunkContainer, getMaxNumberOfCablesPerGroup } from "../../../../../models/pixi/build/build";
import { BORDER_COLOR, getConnectorCableDrawWidth } from "../../../../../models/pixi/build/connectors/connector-furcation";
import { AP_SIZE } from "../../../../../models/pixi/build/drop-base/access-point";
import { getCableStartOffset, getLineMeshTextureOptions, getMeshColor } from "../../../../../models/pixi/build/drop-base/drop-base";
import { viewportSelector } from "../../../../../store/pixi/viewport/viewport.selectors";
import { IPoint } from "../../../../../models/pixi/pixi";

export const VERTICAL_TRUNK_HEIGHT = 55.0;

export const useVerticalTrunk = () => {
    const { mesh, meshColor: dropMeshColor, connectorType, containerName } = useContext(DropContext);
    const { colorHex } = useSelector(trunkColorSelector);
    const { scale } = useSelector(viewportSelector);

    const draw = useCallback(
        (grfx: Graphics) => {
            grfx.clear();
            if (mesh) {
                const meshTexture = Assets.get<Texture>(CROSSHATCH_TEXTURE);
                drawVerticalTrunkWithMesh(grfx, getMeshColor(dropMeshColor), meshTexture, scale);
            } else {
                const cableColor = Number(colorHex);
                const cableWidth = getConnectorCableDrawWidth(connectorType);
                const numberOfConnectors = getMaxNumberOfCablesPerGroup(connectorType);
                drawVerticalTrunkNoMesh(grfx, cableColor, cableWidth, numberOfConnectors, connectorType);
            }
        },
        [colorHex, mesh, dropMeshColor, connectorType, scale]
    );

    return {
        name: `${containerName}_${VerticalTrunkContainer}`,
        draw,
    };
};

const drawVerticalTrunkWithMesh = (grfx: Graphics, meshColor: number, meshTexture: Texture, scale: IPoint) => {
    const bottom = VERTICAL_TRUNK_HEIGHT;
    const width = AP_SIZE.width * 0.25;

    grfx.clear();

    grfx.moveTo(0, 0);
    grfx.lineStyle(width + 1, BORDER_COLOR);
    grfx.lineTo(0, bottom);

    grfx.moveTo(0, 0);
    grfx.lineStyle(width, meshColor);
    grfx.lineTo(0, bottom);

    grfx.moveTo(0, 0);
    if (meshTexture) {
        grfx.lineTextureStyle(getLineMeshTextureOptions(width, meshTexture, scale));
    }
    grfx.lineTo(0, bottom);
};

const drawVerticalTrunkNoMesh = (
    grfx: Graphics,
    cableColor: number,
    cableWidth: number,
    numberOfCables: number,
    connectorType: string
) => {
    const bottom = VERTICAL_TRUNK_HEIGHT;
    const cableAndBorderWidth = cableWidth + 1;

    const start = getCableStartOffset(numberOfCables, cableAndBorderWidth, connectorType);
    grfx.clear();
    for (let c = 0; c < numberOfCables; c++) {
        const offset = start + cableAndBorderWidth * c;

        grfx.moveTo(0 + offset, 0);
        grfx.lineStyle(cableAndBorderWidth, BORDER_COLOR);
        grfx.lineTo(0 + offset, bottom);

        grfx.moveTo(0 + offset, 0);
        grfx.lineStyle(cableWidth, cableColor);
        grfx.lineTo(0 + offset, bottom);
    }
};

import { EventMode } from "pixi.js";
import { Rectangle } from "../interaction";

export interface IRectangleDrawingProps {
    display?: boolean;
    name: string;
    eventMode: EventMode;
    selected: boolean;
    bounds: Rectangle;
    layout: IRectangleLayout;
    onMouseClick?: () => void;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
}

export interface IRectangleLayout {
    borderColor: number;
    alpha: number;
    color?: number;
}

export const DEFAULT_RECTANGLE_LAYOUT: IRectangleLayout = {
    borderColor: 0xffffff,
    alpha: 0,
};

export interface IRectangleGraphics {
    bounds: Rectangle;
    alpha: number;
    eventMode: EventMode;
    name: string;
    cursor: string;
    preventRedraw: boolean;
    click?: () => void;
    mouseout?: () => void;
    mouseover?: () => void;
}

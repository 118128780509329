import { PayloadAction } from "@reduxjs/toolkit";
import { ITriggerInfo, ITriggerManagementState } from "./trigger";

export const setTriggersAction = (state: ITriggerManagementState, action: PayloadAction<ITriggerInfo[]>) => {
    state.triggers = action.payload;
};

export const updateTriggerAction = (state: ITriggerManagementState, action: PayloadAction<ITriggerInfo>) => {
    const trigger = action.payload;
    const triggerIndex = state.triggers.findIndex((t) => t.groupPosition === trigger.groupPosition);
    if (triggerIndex > -1) {
        state.triggers[triggerIndex] = trigger;
        if (state.selectedTrigger?.groupPosition === trigger.groupPosition) {
            state.selectedTrigger = trigger;
        }
    }
};

export const setSelectedTriggerAction = (
    state: ITriggerManagementState,
    action: PayloadAction<ITriggerInfo | undefined>
) => {
    state.selectedTrigger = action.payload;
};

import { Assets, Graphics, Texture } from "pixi.js";
import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _ReactPixi } from "@pixi/react";
import { setShowAssemblyTypeEdit } from "../../../../../store/overlay/overlay.reducers";
import { showAssemblyTypeEditSelector } from "../../../../../store/overlay/overlay.selectors";
import { DropContext } from "../../../../../store/workspace/build/build";
import {
    currentAssemblyTypeSelector,
    currentBuildFinalizedSelector,
    currentMainMeshColorSelector,
    specificDropSelectorFactory,
} from "../../../../../store/workspace/build.selectors";
import { getMaxAPToAPDistance } from "../../../../../store/workspace/build.selectors";
import { CROSSHATCH_TEXTURE } from "../../../factories/texture";
import { glowFilter, hoverFilter } from "../../../decorators/bounds/select-filter";
import { viewportContextSelector, viewportSelector } from "../../../../../store/pixi/viewport/viewport.selectors";
import { BORDER_COLOR } from "../../../../../models/pixi/build/connectors/connector-furcation";
import { AP_BORDER_WIDTH, AP_SIZE } from "../../../../../models/pixi/build/drop-base/access-point";
import { IPoint, ISize, getInteractiveEventMode } from "../../../../../models/pixi/pixi";
import { HorizontalTrunkContainer } from "../../../../../models/pixi/build/build";
import { getMeshColor, getLineMeshTextureOptions } from "../../../../../models/pixi/build/drop-base/drop-base";

export const HORIZONTAL_TRUNK_WIDTH = 350;

export const useHorizontalTrunk = () => {
    const { side, index, nbDrops, nbGroups, nbConnectors, connectorType, containerName, isReportDrop, isReportOverviewDrop } =
        useContext(DropContext);
    const nextDrop = useSelector(specificDropSelectorFactory(index + 1, !!isReportOverviewDrop, !!isReportDrop));
    const mainMeshColor = useSelector(currentMainMeshColorSelector);
    const { eventMode, filters, actions } = useSelection();
    const assemblyType = useSelector(currentAssemblyTypeSelector);
    const { scale } = useSelector(viewportSelector);

    const draw = useCallback(
        (grfx: Graphics) => {
            grfx.clear();
            const cableColor = getMeshColor(mainMeshColor);
            const distance = getMaxAPToAPDistance(side, nbGroups, nbConnectors, connectorType, nextDrop);

            const isDrawn = isReportDrop ?? index < nbDrops - 1;
            if (isDrawn) {
                const { width, height } = { width: AP_SIZE.width, height: AP_SIZE.height * 0.65 };
                const start: IPoint = { x: width * 0.5, y: 0 };
                const end: IPoint = { x: distance - width * 0.5, y: 0 };
                const border: ISize = { width: end.x - start.x, height: height + AP_BORDER_WIDTH };

                if (isReportDrop) {
                    start.x = index > 0 ? -distance * 0.5 : start.x;
                    start.x = index < nbDrops - 1 ? start.x : -distance;
                    end.x = index < nbDrops - 1 ? distance * 0.5 : width * 0.5;
                    border.width = end.x - start.x;
                    border.height = height + AP_BORDER_WIDTH * 2;
                }

                // Border
                grfx.beginFill(BORDER_COLOR);
                grfx.drawRect(start.x, start.y - border.height * 0.5, border.width, border.height);
                grfx.endFill();

                // Trunk
                grfx.moveTo(start.x, start.y);
                grfx.lineStyle(height, cableColor);
                grfx.lineTo(end.x, end.y);

                // Texture
                if (assemblyType === "mesh") {
                    const meshTexture = Assets.get<Texture>(CROSSHATCH_TEXTURE);
                    if (meshTexture) {
                        grfx.moveTo(start.x, start.y);
                        grfx.lineTextureStyle(getLineMeshTextureOptions(height, meshTexture, scale));
                        grfx.lineTo(end.x, end.y);
                    }
                }
            }
        },
        [
            mainMeshColor,
            isReportDrop,
            index,
            side,
            nbGroups,
            nbConnectors,
            connectorType,
            nbDrops,
            assemblyType,
            scale,
            nextDrop
        ]
    );

    const trunkProps: _ReactPixi.IGraphics = {
        name: `${containerName}-${HorizontalTrunkContainer}`,
        draw,
        cursor: "pointer",
        eventMode,
        mouseout: actions.onMouseOut,
        mouseover: actions.onMouseOver,
        click: actions.onClick,
    };

    return {
        filters,
        trunkProps,
    };
};

const useSelection = () => {
    const { context: viewportContext } = useSelector(viewportContextSelector);
    const showAssemblyTypeEdit = useSelector(showAssemblyTypeEditSelector);
    const buildFinalized = useSelector(currentBuildFinalizedSelector);
    const [selectionFilters, setSelectionFilters] = useState({ ...glowFilter, enabled: false });
    const [hoverFilters, setHoverFilters] = useState({ ...hoverFilter, enabled: false });
    const dispatch = useDispatch();

    const allowInteractions = viewportContext === "active" && !buildFinalized;
    const onMouseOver = useCallback(() => {
        if (allowInteractions) {
            setHoverFilters({ ...hoverFilter, enabled: true });
        }
    }, [allowInteractions]);

    const onMouseOut = useCallback(() => {
        if (allowInteractions) {
            setHoverFilters({ ...hoverFilter, enabled: false });
        }
    }, [allowInteractions]);

    useEffect(() => {
        if (allowInteractions && showAssemblyTypeEdit) {
            setSelectionFilters({ ...glowFilter, enabled: true });
        } else {
            setSelectionFilters({ ...glowFilter, enabled: false });
        }
    }, [allowInteractions, showAssemblyTypeEdit]);

    const onClick = useCallback(() => {
        if (allowInteractions) {
            dispatch(setShowAssemblyTypeEdit(true));
        }
    }, [allowInteractions, dispatch]);

    return {
        eventMode: getInteractiveEventMode(allowInteractions),
        filters: {
            selection: selectionFilters,
            hover: hoverFilters,
        },
        actions: {
            onMouseOver,
            onMouseOut,
            onClick,
        },
    };
};

import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { IFiberMapInfo, IFiberMapRowProps } from "../../../models/off-screen/fiber-map-table";
import { currentStatusSelector } from "../../../store/overlay/header/status-icon/status-icon.selectors";
import { WorkspaceStatus } from "../../../store/overlay/header/status-icon/status-icon";
import { assignedPolaritySelector } from "../../../store/workspace/build.selectors";
import { IPolarityData } from "../../../store/overlay/polarity/polarity";
import i18n from "../../../locales/i18n";
import { LocalizationKeys } from "../../../locales/keys";

export const useFiberMapTable = () => {
    const status = useSelector(currentStatusSelector);
    const assignedPolarity = useSelector(assignedPolaritySelector);
    const [rows, setRows] = useState<IFiberMapRowProps[]>([]);

    const loadFiberMapRows = useCallback(() => {
        let fiberMapRows: IFiberMapRowProps[] = getFiberMapRows(assignedPolarity);
        fiberMapRows = fiberMapRows.sort((a, b) => {
            if (a.sourceFiber.connectorIndex === b.sourceFiber.connectorIndex) {
                return a.sourceFiber.pinIndex > b.sourceFiber.pinIndex ? 1 : -1;
            }
            return a.sourceFiber.connectorIndex > b.sourceFiber.connectorIndex ? 1 : -1;
        });
        setRows(fiberMapRows);
    }, [assignedPolarity]);

    useEffect(() => {
        if (status === WorkspaceStatus.Reporting) {
            loadFiberMapRows();
        }
    }, [status, loadFiberMapRows]);

    return { rows };
};

const getFiberMapRows = (polarity: IPolarityData[]): IFiberMapRowProps[] => {
    const rows: IFiberMapRowProps[] = [];
    for (const { feeder, distribution } of polarity) {
        const connectorType = feeder.connectors[0].type ?? "";
        for (const distributionIndice of distribution.pins.filter(p => p.assignedIndex > -1)) {
            const feederIndice = feeder.pins[distributionIndice.assignedIndex];
            const feederConnectorIndex = feeder.connectorIndices[feederIndice.connector.index];
            const feederInfo: IFiberMapInfo = {
                location: i18n.t(LocalizationKeys.Feeder),
                connectorIndex: feederConnectorIndex,
                connectorType,
                row: getPinRowLocation(feederIndice.connector.fiberCount, feederIndice.index),
                pinIndex: feederIndice.index + 1
            };
            const distributionConnectorIndex = distribution.connectorIndices[distributionIndice.connector.index];
            const distributionConnector = distribution.connectors[distributionIndice.connector.index];
            const distributionTAPPosition = distributionConnector.tapPosition !== undefined ? distributionConnector.tapPosition + 1 : 0;
            const distributionInfo: IFiberMapInfo = {
                location: i18n.t(LocalizationKeys.TapNumber, { tapNumber: distributionTAPPosition }),
                connectorIndex: distributionConnectorIndex,
                connectorType,
                row: getPinRowLocation(distributionIndice.connector.fiberCount, distributionIndice.index),
                pinIndex: distributionIndice.index + 1 
            };
            rows.push({ 
                sourceFiber: feederInfo,
                destinationFiber: distributionInfo
            });
        }
    }
    return rows;
}

const getPinRowLocation = (fiberCount: number, pinIndex: number) => {
    if (fiberCount === 24) {
        return pinIndex > 12 ? "BOTTOM" : "TOP";
    } else {
        return i18n.t(LocalizationKeys.NA);
    }
}
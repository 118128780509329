import * as PIXI from "pixi.js";
import { useMemo } from "react";
import { ISizeEmpty } from "../../../../models/pixi/pixi";
import { DEFAULT_TEXT_STYLE, TextProps } from "../../../../models/pixi/decorators/text";

export const useText = (props: TextProps) => {
    const { text, textStyle, textSize } = useMemo(() => {
        const scale = props.scale ?? DEFAULT_TEXT_STYLE.scale;
        const fontSize = props.fontSize ?? DEFAULT_TEXT_STYLE.fontSize;
        const textStyle = new PIXI.TextStyle({
            fontSize: fontSize * scale,
            align: props.align ?? DEFAULT_TEXT_STYLE.align,
            fontFamily: props.fontFamily ?? DEFAULT_TEXT_STYLE.fontFamily,
            fontWeight: props.fontWeight ?? DEFAULT_TEXT_STYLE.fontWeight,
            fill: props.fill ?? DEFAULT_TEXT_STYLE.fill,
            strokeThickness: props.strokeThickness ?? DEFAULT_TEXT_STYLE.strokeThickness,
        });

        const textMetrics: PIXI.TextMetrics | undefined = props.text
            ? PIXI.TextMetrics.measureText(props.text, textStyle)
            : undefined;

        return {
            text: props.text,
            textSize: textMetrics ? { width: textMetrics.width, height: textMetrics.height } : ISizeEmpty,
            textStyle,
        };
    }, [
        props.align,
        props.fill,
        props.fontFamily,
        props.fontSize,
        props.fontWeight,
        props.scale,
        props.strokeThickness,
        props.text,
    ]);

    return {
        text,
        textStyle,
        textSize,
    };
};

export const PageResizeEnum = {
    FitToPage: "fit-to-page",
    FitToHeight: "fit-to-height",
    FitToSource: "fit-to-source",
} as const;

export type PageResizeValue = (typeof PageResizeEnum)[keyof typeof PageResizeEnum];

export interface PageResizeArgs {
    update?: boolean;
    value: PageResizeValue;
}

export interface IPageResizeState {
    resize: PageResizeArgs;
}

export const initialState: IPageResizeState = {
    resize: {
        update: false,
        value: "fit-to-source",
    },
};

import { AlertPalettes } from "@corning-ctcm/silica-react";
import { t } from "i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { IConnectorData } from "../../../workspace/build/connector/connector";
import { INotificationMessage } from "../../../../store/overlay/notification/notification";
import { IConnectorAssignment } from "../../../../store/overlay/polarity/polarity";

export interface IPropagationOptions {
    propagateColors: boolean;
    propagateLabelText: boolean;
    propagateLabelColor: boolean;
}

export interface IPropagationState {
    showDialog: boolean;
}

export type PropagationState = IPropagationState & IPropagationOptions;

export type Propagation = {
    connectorAssignments: IConnectorAssignment[];
    propagationOptions: IPropagationOptions;
};

export const PropagationStatuses = {
    Failed: 0,
    Success: 1,
} as const;

export type PropagationStatus = (typeof PropagationStatuses)[keyof typeof PropagationStatuses];

export type PropagationResult = {
    status: PropagationStatus;
    connectors: IConnectorData[];
};

export const initialPropagationState: PropagationState = {
    showDialog: false,
    propagateLabelText: false,
    propagateLabelColor: false,
    propagateColors: false,
};

export const getPropagationNotification = ({ connectors, status }: PropagationResult): INotificationMessage => {
    switch (status) {
        case PropagationStatuses.Failed:
            return { message: t(LocalizationKeys.PropagationErrorMessage), palette: AlertPalettes.error };
        case PropagationStatuses.Success:
        default:
            const message =
                connectors.length > 0
                    ? t(LocalizationKeys.PropagationSuccessfulMessage)
                    : t(LocalizationKeys.PropagationIdenticalMessage);
            return { message, palette: AlertPalettes.info };
    }
};

export const getPropagationStatus = (
    sources: IConnectorData[],
    destinations: IConnectorData[],
    propagationOptions: IPropagationOptions
) => {
    const { propagateColors, propagateLabelText, propagateLabelColor } = propagationOptions;
    const colorStatus = propagateColors ? validateColorPropagation(sources, destinations) : "";
    const labelTextStatus = propagateLabelText ? validateLabelTextPropagation(sources, destinations) : "";
    const labelColorStatus = propagateLabelColor ? validateLabelColorPropagation(sources, destinations) : "";
    return [colorStatus, labelTextStatus, labelColorStatus].join(" ").trim();
};

const validateColorPropagation = (sources: IConnectorData[], destinations: IConnectorData[]) => {
    let status = "";
    if (sources.length === 1) {
        // One-to-One
        const source = sources[0];
        status = destinations.every((d) => d.color === source.color) ? "" : t(LocalizationKeys.PropagationColorError);
    }

    return status;
};

const validateLabelTextPropagation = (sources: IConnectorData[], destinations: IConnectorData[]) => {
    let status = "";
    if (sources.length === 1) {
        // One-to-One
        const source = sources[0];
        status = destinations.every((d) => d.label === source.label)
            ? status
            : t(LocalizationKeys.PropagationLabelTextError);
    }

    return status;
};

const validateLabelColorPropagation = (sources: IConnectorData[], destinations: IConnectorData[]) => {
    let status = "";
    if (sources.length === 1) {
        // One-to-One
        const source = sources[0];
        status = destinations.every((d) => d.labelColor === source.labelColor)
            ? status
            : t(LocalizationKeys.PropagationLabelColorError);
    }

    return status;
};

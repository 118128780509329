import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { CheckboxProps, DialogProps, IDialogActions } from "@corning-ctcm/silica-react";
import { useDispatch, useSelector } from "react-redux";
import { propagationStateSelector } from "../../../../store/overlay/polarity/propagation/propagation.selectors";
import { IPropagationCheckboxState, initialPropagationCheckboxState } from "../../../../models/overlay/polarity/propagation";
import { setPropagation, showPropagation } from "../../../../store/overlay/polarity/propagation/propagation.reducers";
import { connectorAssignmentsSelector } from "../../../../store/workspace/build.selectors";
import { IPropagationOptions } from "../../../../store/overlay/polarity/propagation/propagation";
import { applyPropagation } from "../../../../store/workspace/build/build.reducers";
import { AppDispatch } from "../../../../store/workspace/workspace.reducers";
import { buildDisabledSelector } from "../../../../store/workspace/build/build.selector";

export const usePropagation = () => {
    const { showDialog, propagateColors, propagateLabelText, propagateLabelColor } = useSelector(propagationStateSelector);
    const connectorAssignments = useSelector(connectorAssignmentsSelector);
    const disabled = useSelector(buildDisabledSelector);
    const { t } = useTranslation();

    const dispatch = useDispatch<AppDispatch>();

    const [checkboxState, setCheckboxState] = useState<IPropagationCheckboxState>(initialPropagationCheckboxState);

    useEffect(() => {
        if (showDialog) {
            setCheckboxState({ triggerChecked: propagateColors, labelTextChecked: propagateLabelText, labelColorChecked: propagateLabelColor });
        }
    }, [showDialog, propagateColors, propagateLabelText, propagateLabelColor]);

    const onClose = useCallback(() => {
        dispatch(showPropagation(false));
    }, [dispatch]);

    const onConfirm = useCallback(async () => {
        const { triggerChecked, labelTextChecked, labelColorChecked } = checkboxState;
        const isPropagationActive = triggerChecked || labelTextChecked || labelColorChecked;
        const propagationOptions: IPropagationOptions = {
            propagateColors: triggerChecked,
            propagateLabelText: labelTextChecked,
            propagateLabelColor: labelColorChecked,
        };

        if (isPropagationActive) {
            dispatch(applyPropagation({ connectorAssignments, propagationOptions }));
        }
        dispatch(setPropagation(propagationOptions));
        onClose();
    }, [checkboxState, dispatch, connectorAssignments, onClose]);

    const actions: IDialogActions = {
        cancelText: t(LocalizationKeys.Cancel),
        confirmText: t(LocalizationKeys.Save),
        disableConfirm: disabled,
        onCancelClick: onClose,
        onConfirmClick: onConfirm,
        actionGuidance: {
            button: "confirm",
            severity: "standard",
        },
    };

    const dialogProps: DialogProps = {
        open: showDialog,
        className: "propagation-dialog",
        title: t(LocalizationKeys.PropagateConnectorAttributes),
        message: t(LocalizationKeys.PropagationMessage),
        actions,
        onClose,
    };

    const onTriggerChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setCheckboxState((prev) => ({ ...prev, triggerChecked: e.target.checked }));
    }, []);

    const triggerCheckbox: CheckboxProps = {
        palette: "primary",
        placement: "end",
        label: t(LocalizationKeys.TriggerHousingColor),
        checked: checkboxState.triggerChecked,
        disabled,
        onChange: onTriggerChange,
    };

    const onLabelTextChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setCheckboxState((prev) => ({ ...prev, labelTextChecked: e.target.checked }));
    }, []);

    const labelTextCheckbox: CheckboxProps = {
        palette: "primary",
        placement: "end",
        label: t(LocalizationKeys.LabelText),
        checked: checkboxState.labelTextChecked,
        disabled,
        onChange: onLabelTextChange,
    };

    const onLabelColorChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setCheckboxState((prev) => ({ ...prev, labelColorChecked: e.target.checked }));
    }, []);

    const labelColorCheckbox: CheckboxProps = {
        palette: "primary",
        placement: "end",
        label: t(LocalizationKeys.LabelColor),
        checked: checkboxState.labelColorChecked,
        disabled,
        onChange: onLabelColorChange,
    };

    return {
        dialogProps,
        triggerCheckbox,
        labelTextCheckbox,
        labelColorCheckbox,
    };
};

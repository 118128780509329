import { Dispatch } from "react";

import { Action } from "@reduxjs/toolkit";

import { Yellow } from "../../../../../models/ui/dialog/color-dialog";
import { DEFAULT_CABLE_OUTER_DIAMETER, IBuildData } from "../../../../workspace/build/build";
import { IUnitOfMeasure, PrimaryUnit, Unit } from "../../../../../models/overlay/header/units-of-measure";
import { ConnLC, IConnectorType, Stagger0 } from "../../wizard";
import { FlameRatings } from "../../../../../models/overlay/wizard/setup/flame-rating";
import { PullingGrip } from "../../../../../models/pixi/decorators/markers/pulling-grip";
import { BLengthType } from "../../../../../models/overlay/wizard/setup/b-length-section/b-length-section";

export interface ISourceSetupContentProps {
    initialState: ISourceSetupState;
}

export interface ISourceSetupContext {
    state: ISourceSetupState;
    dispatch: Dispatch<Action>;
}

export const initialSourceSetupState: ISourceSetupState = {
    primaryUnit: "imperial",
    unit: '"',
    flameRating: FlameRatings.Riser,
    cableOuterDiameter: DEFAULT_CABLE_OUTER_DIAMETER,
    connectorType: ConnLC,
    connectorTypes: [],
    connectorTypeChanged: false,
    bundleCount: 6,
    mesh: false,
    meshColor: Yellow.name,
    aLength: Stagger0.value,
    bLength: Stagger0.value,
    sLength: Stagger0.value,
    isFiberCountPerConnectorValid: true,
    isBundleCountFieldValid: true,
    isGroupCountValid: true,
    isALengthValid: true,
    isBLengthValid: true,
    isMeshOffsetValid: true,
    isSLengthEnabled: true,
    isSLengthValid: true,
    bundleCountErrorText: "",
    groupCountErrorText: "",
    meshOffsetErrorText: "",
    aLengthErrorText: "",
    bLengthErrorText: "",
    sLengthErrorText: "",
    reverseStaggering: false,
    pullingGrip: "none",
    bLengthType: "uniform",
};

export const initialSourceSetupContext: ISourceSetupContext = {
    state: initialSourceSetupState,
    dispatch: () => {},
};

export interface ISourceSetupState {
    modeId?: number;
    primaryUnit: PrimaryUnit;
    unit: Unit;
    shell?: string;
    measurementsPosition?: string;
    configurationType?: string;
    flameRating?: string;
    connectorType: IConnectorType;
    connectorTypes: IConnectorType[];
    connectorTypeChanged: boolean;
    fiberType?: string;

    cableOuterDiameter: number;

    fiberCountPerConnector?: number;
    fiberCountPerConnectorField?: string;
    isFiberCountPerConnectorValid?: boolean;
    fiberCountPerConnectorErrorText?: string;

    bundleCount: number; // Bundle count
    bundleCountField?: string[];
    bundleCountInputValue?: string;
    isBundleCountFieldValid?: boolean;
    bundleCountErrorText?: string;

    groupCount?: number;
    groupCountField?: string;
    isGroupCountValid?: boolean;
    groupCountErrorText?: string;

    mesh: boolean;

    meshOffset?: IUnitOfMeasure;
    meshOffsetField?: string;
    isMeshOffsetValid?: boolean;
    meshOffsetErrorText?: string;

    meshColor?: string;

    aLength: IUnitOfMeasure;
    aLengthField?: string;
    isALengthValid?: boolean;
    aLengthErrorText?: string;

    bLength: IUnitOfMeasure;
    bLengthField?: string;
    isBLengthValid?: boolean;
    bLengthErrorText?: string;

    sLength: IUnitOfMeasure;
    sLengthField?: string[];
    sLengthInputValue?: string;
    isSLengthValid?: boolean;
    isSLengthEnabled?: boolean;
    sLengthErrorText?: string;
    reverseStaggering: boolean;

    pullingGrip: PullingGrip;
    unitOfMeasure?: Unit;

    overallLengthType?: string;
    bLengthType: BLengthType;
}

export const hasSourceChanges = (selectedBuild: IBuildData | undefined, setup: ISourceSetupState) => {
    if (!selectedBuild) return false;

    const drop = selectedBuild.drops[0];
    const connectorType = drop.groups[0].connectors[0].type !== setup.connectorType.type;
    const connectorCount = drop.groups[0].connectors.length * drop.groups.length;
    const bundleCount = connectorCount !== setup.bundleCount;
    const flameRating = selectedBuild.flameRating !== setup.flameRating;
    const groupCount = drop.groups.length !== setup.groupCount;
    const mesh = drop.mesh !== setup.mesh;
    const meshColor = setup.mesh && drop.meshColor !== setup.meshColor;
    const meshOffset = setup.mesh && drop.meshOffset?.value !== setup.meshOffset?.value;
    const aLength = drop.lengthA.value !== setup.aLength.value;
    const bLength = drop.lengthB.value !== setup.bLength.value;
    const sLength = drop.groups[0].stagger?.value !== setup.sLength.value;
    const reverseStaggering = drop.reverseStaggering !== setup.reverseStaggering;
    const cableOuterDiameter = selectedBuild.cableOuterDiameter !== setup.cableOuterDiameter;
    const fiberType = selectedBuild.fiberType !== setup.fiberType;
    const overallLengthType = selectedBuild.overallLengthType !== setup.overallLengthType;
    const pullingGrip = selectedBuild.pullingGrip !== setup.pullingGrip;
    const bLengthType =
        (drop.customBLength && setup.bLengthType === "uniform") ||
        (!drop.customBLength && setup.bLengthType === "custom");

    return (
        connectorType ||
        bundleCount ||
        flameRating ||
        groupCount ||
        mesh ||
        meshColor ||
        meshOffset ||
        aLength ||
        bLength ||
        sLength ||
        reverseStaggering ||
        cableOuterDiameter ||
        fiberType ||
        overallLengthType ||
        pullingGrip ||
        bLengthType
    );
};

import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./viewport";
import { IViewportState, ViewportStatusTypes } from "./viewport";
import { PayloadAction } from "@reduxjs/toolkit";
import { ZoomArgs } from "../../../models/overlay/footer/zoom";
import { IPoint } from "../../../models/pixi/pixi";
import { PageResizeArgs } from "../../../models/overlay/footer/page-resize";

const zoomToMouseReducer = (state: IViewportState, action: PayloadAction<ZoomArgs>) => {
    const oldScale = state.scale;
    const viewportPosition = state.position;
    let { newScale, cursor } = action.payload;

    // calculate the oldOffset between the viewport and the cursor
    const oldOffset: IPoint = {
        x: cursor.x - viewportPosition.x,
        y: cursor.y - viewportPosition.y,
    };

    // calculate the new offset by scaling the old one with the newScale
    const newOffset: IPoint = {
        x: (oldOffset.x / oldScale.x) * newScale.x,
        y: (oldOffset.y / oldScale.y) * newScale.y,
    };

    // calculate the viewportPos now that we have our newOffset and scale it
    const newPosition: IPoint = {
        x: cursor.x - newOffset.x,
        y: cursor.y - newOffset.y,
    };

    state.position = newPosition;
    state.scale = newScale;
};

const setIncrementAction = (state: IViewportState, action: PayloadAction<number>) => {
    state.increment = action.payload;
}

const setIsDraggingReducer = (state: IViewportState, action: PayloadAction<boolean>) => {
    state.isDragging = action.payload;
};

const setViewportContextReducer = (state: IViewportState, action: PayloadAction<ViewportStatusTypes>) => {
    state.context = action.payload;
};

const setScaleReducer = (state: IViewportState, action: PayloadAction<IPoint>) => {
    state.scale = action.payload;
};

const setPositionReducer = (state: IViewportState, action: PayloadAction<IPoint>) => {
    state.position = action.payload;
};

const setResizeReducer = (state: IViewportState, action: PayloadAction<PageResizeArgs>) => {
    state.resize = action.payload;
};

const slice = createSlice({
    name: "viewport",
    initialState,
    reducers: {
        setIsDragging: setIsDraggingReducer,
        setViewportContext: setViewportContextReducer,
        setScale: setScaleReducer,
        setPosition: setPositionReducer,
        setResize: setResizeReducer,
        zoomToMouse: zoomToMouseReducer,
        setIncrement: setIncrementAction
    },
});

export const ViewportReducer = slice.reducer;
export const { 
    setIsDragging,
    setViewportContext,
    setScale,
    setPosition,
    setResize,
    zoomToMouse,
    setIncrement
} = slice.actions;

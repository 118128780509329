import { InteractiveRectangle } from "../../../decorators/bounds/rectangle/interactive-rectangle";
import { useConnectorHighlight } from "./connector-highlight.hooks";
import { IConnectorHighlightProps } from "../../../../../models/pixi/build/connectors/connector-highlight";

export const ConnectorHighlight: React.FC<IConnectorHighlightProps> = (props) => {
    const { display, rectangleProps } = useConnectorHighlight(props);
    if (!display) {
        return null;
    }
    return <InteractiveRectangle {...rectangleProps} />;
};

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IUnitOfMeasure } from "../../../models/overlay/header/units-of-measure";
import { currentBuildSelector } from "../../../store/workspace/root.selectors";
import { WizardPresetService } from "../../../services/wizard-preset.service";
import { getGroupBLength } from "../../../store/workspace/build/build.actions";
import { IConnectorData, IConnectorGroupData } from "../../../store/workspace/build/connector/connector";
import { IDrop } from "../../../store/workspace/build/drop";
import { IBuildData, initialBuildData } from "../../../store/workspace/build/build";
import { WizardPreset } from "../../../store/workspace/build/wizard-preset/wizard-preset";
import { userWizardPresetSelector } from "../../../store/workspace/build/wizard-preset/wizard-preset.selector";
import { setCurrentPreset } from "../../../store/workspace/build/wizard-preset/wizard-preset.reducers";
import { IConnectorType, getConnectorType } from "../../../store/overlay/wizard/wizard";

export const useWizardPreset = () => {
    const currentBuild = useSelector(currentBuildSelector);
    const selectedPreset = useSelector(userWizardPresetSelector);
    const dispatch = useDispatch();

    let selectedPresetBuild: IBuildData = { ...initialBuildData };
    if (selectedPreset?.id) {
        selectedPresetBuild = createBuildUsingWizardPreset(selectedPreset);
    }

    const addWizardPreset = useCallback(async () => {
        if (currentBuild) {
            const currentWizardPreset = BuildToWizardPreset(currentBuild);
            let wizardPreset: WizardPreset | undefined;
            if (selectedPreset && selectedPreset.id) {
                const updatedWizardPreset = updateWizardPreset(selectedPreset, currentWizardPreset);
                wizardPreset = await new WizardPresetService().updateWizardPreset(updatedWizardPreset);
            } else {
                wizardPreset = await new WizardPresetService().addWizardPreset(currentWizardPreset);
            }
            if (wizardPreset) {
                dispatch(setCurrentPreset(wizardPreset));
            }
        }
    }, [currentBuild, selectedPreset, dispatch]);

    return { addWizardPreset, selectedPresetBuild };
};

export const useWizardPresetLoad = () => {
    const dispatch = useDispatch();
    const loadWizardPresets = useCallback(async () => {
        const wizardPreset = await new WizardPresetService().getLatestWizardPreset();
        if (wizardPreset) {
            dispatch(setCurrentPreset(wizardPreset));
        }
    }, [dispatch]);

    return { loadWizardPresets };
};

function createConnectorData(
    side: string,
    tapPosition: number,
    groupPosition: number,
    connectorType: IConnectorType,
    stagger: IUnitOfMeasure,
    connectorsPerGroup: number,
    remainingFiberCount: number,
    fiberCountInUse: number
): IConnectorData[] {
    let connectorData: IConnectorData[] = [];
    for (let i = 0; i < connectorsPerGroup && remainingFiberCount >= fiberCountInUse; i++) {
        connectorData.push({
            side,
            tapPosition,
            groupPosition,
            position: i,
            type: connectorType.type,
            fiberCountInUse,
            stagger: { unit: stagger.unit, value: stagger.value },
        });
        remainingFiberCount -= fiberCountInUse;
    }
    return connectorData;
}

function createConnectorGroupData(
    side: string,
    tapPosition: number,
    connectorTypeString: string,
    fiberCount: number,
    fiberCountInUse: number,
    groupCount: number,
    connectorsPerGroup: number,
    bLength: IUnitOfMeasure,
    stagger: IUnitOfMeasure,
    reverseStaggering: boolean
): IConnectorGroupData[] {
    const connectorType = getConnectorType(connectorTypeString);
    let groups: IConnectorGroupData[] = [];
    let remainingFiberCount = fiberCount;
    for (let i = 0; i < groupCount; i++) {
        let cpg = i === groupCount - 1 ? remainingFiberCount / fiberCountInUse : connectorsPerGroup;
        const connectors = createConnectorData(
            side,
            tapPosition,
            i,
            connectorType,
            stagger,
            cpg,
            remainingFiberCount,
            fiberCountInUse
        );
        if (connectors.length) {
            const group: IConnectorGroupData = {
                position: i,
                tapPosition,
                type: connectorTypeString,
                lengthB: {
                    value: getGroupBLength(bLength.value, stagger.value, groupCount, i, reverseStaggering),
                    unit: stagger.unit,
                },
                stagger: { unit: stagger.unit, value: stagger.value },
                connectors,
            };
            groups.push(group);
            remainingFiberCount -= group.connectors.length * fiberCountInUse;
        }
    }
    return groups;
}

const createBuildUsingWizardPreset = (preset: WizardPreset): IBuildData => {
    let build: IBuildData = { ...initialBuildData };
    const [initialFeeder, ...initialDistribution] = build.drops;
    let feeder: IDrop = { ...initialFeeder };
    if (preset.feederPreset) {
        const {
            modeId,
            fiberCount,
            fiberCountInUse,
            bundleCount,
            connectorType,
            flameRating,
            pullingGrip,
            cableOuterDiameter,
            fiberType,
            groups,
            connectorsPerGroup,
            mesh,
            meshOffset,
            meshColor,
            overallLengthType,
            aLength,
            bLength,
            staggerLength,
            reverseStaggering,
        } = preset.feederPreset;
        if (modeId) {
            build.modeId = modeId;
        }
        if (fiberCount) {
            build.fiberCount = fiberCount;
        }
        if (bundleCount) {
            build.bundleCount = bundleCount;
        }
        if (flameRating) {
            build.flameRating = flameRating;
        }
        if (pullingGrip) {
            build.pullingGrip = pullingGrip;
        }
        if (cableOuterDiameter) {
            build.cableOuterDiameter = cableOuterDiameter;
        }
        if (fiberType) {
            build.fiberType = fiberType;
        }
        if (meshColor) {
            build.mainMeshColor = meshColor;
        }
        if (overallLengthType) {
            build.overallLengthType = overallLengthType;
        }
        if (aLength) {
            feeder.lengthA = { value: aLength.value, unit: aLength.unit };
        }
        if (bLength) {
            feeder.lengthB = { value: bLength.value, unit: bLength.unit };
        }
        if (mesh && meshOffset && meshColor) {
            feeder.mesh = mesh;
            feeder.meshOffset = { value: meshOffset.value, unit: meshOffset.unit };
            feeder.meshColor = meshColor;
        } else {
            feeder.mesh = false;
            feeder.meshOffset = undefined;
            feeder.meshColor = undefined;
        }
        if (reverseStaggering) {
            feeder.reverseStaggering = reverseStaggering;
        }

        if (fiberCount && connectorType && groups && connectorsPerGroup && staggerLength) {
            let validGroupCount = groups;
            let validCPG = connectorsPerGroup;
            if (bundleCount) {
                validGroupCount = bundleCount % groups === 0 ? groups : groups + 1;
                validCPG = Math.floor(bundleCount / groups);
            }

            const connectorFiberCount =
                fiberCountInUse && fiberCountInUse > 0 ? fiberCountInUse : getConnectorType(connectorType).fiberCount;
            feeder.fiberCountInUse = connectorFiberCount;
            feeder.groups = createConnectorGroupData(
                feeder.side,
                feeder.position,
                connectorType,
                fiberCount,
                connectorFiberCount,
                validGroupCount,
                validCPG,
                feeder.lengthB,
                staggerLength,
                feeder.reverseStaggering
            );
        }
    }

    let distribution: IDrop[] = [...initialDistribution];
    if (preset.dropPreset) {
        const {
            connectorType,
            accessPointCount,
            calculateAccessPoints,
            connectorsPerGroup,
            groups,
            staggerLength,
            aLength,
            bLength,
            mesh,
            meshColor,
            meshOffset,
            reverseStaggering,
            fiberCountInUse,
        } = preset.dropPreset;
        if (meshColor && build.mainMeshColor !== meshColor) {
            build.mainMeshColor = meshColor;
        }
        if (calculateAccessPoints) {
            build.autoAccessPoints = calculateAccessPoints;
        }
        if (accessPointCount && build.fiberCount && connectorType && groups && connectorsPerGroup && staggerLength) {
            let nbDrops = accessPointCount;
            let nbGroups = groups;
            let remainingFiberCount = build.fiberCount;
            const dropFiberCountInUse =
                fiberCountInUse && fiberCountInUse > 0 ? fiberCountInUse : getConnectorType(connectorType).fiberCount;
            const maxDropFiberCount = nbGroups * connectorsPerGroup * dropFiberCountInUse;
            let drops: IDrop[] = [];
            for (let i = 0; i < nbDrops; i++) {
                let dropFiberCount = Math.min(maxDropFiberCount, remainingFiberCount);
                if (dropFiberCount < dropFiberCountInUse) {
                    break;
                }
                if (i === nbDrops - 1 && nbDrops > accessPointCount) {
                    dropFiberCount = remainingFiberCount;
                    nbGroups = 1;
                }

                const drop: IDrop = { ...distribution[i] };
                if (aLength) {
                    drop.lengthA = { value: aLength.value, unit: aLength.unit };
                }
                if (bLength) {
                    drop.lengthB = { value: bLength.value, unit: bLength.unit };
                }
                if (mesh && meshOffset && meshColor) {
                    drop.mesh = mesh;
                    drop.meshOffset = { value: meshOffset.value, unit: meshOffset.unit };
                    drop.meshColor = meshColor;
                } else {
                    drop.mesh = false;
                    drop.meshOffset = undefined;
                    drop.meshColor = undefined;
                }
                if (reverseStaggering) {
                    drop.reverseStaggering = reverseStaggering;
                }
                drop.fiberCountInUse = dropFiberCountInUse;
                drop.groups = createConnectorGroupData(
                    drop.side,
                    drop.position,
                    connectorType,
                    dropFiberCount,
                    dropFiberCountInUse,
                    nbGroups,
                    connectorsPerGroup,
                    drop.lengthB,
                    staggerLength,
                    drop.reverseStaggering
                );
                drops.push(drop);

                remainingFiberCount -= dropFiberCount;
                if (i === nbDrops - 1 && remainingFiberCount > 0) {
                    nbDrops++;
                }
            }
            distribution = drops;
        }
    }

    build.drops = [feeder, ...distribution];
    return build;
};

export function BuildToWizardPreset(build: IBuildData): WizardPreset {
    const destinations = build.drops.filter((d) => d.side === "distribution");
    const source = build.drops.find((d) => d.side === "feeder")!;
    const sourceGroup = source.groups[0]!;
    const destination = destinations[0]!;
    const destinationGroup = destination.groups[0];

    let srcMeshOffset: IUnitOfMeasure | undefined = undefined;
    let srcMeshColor: string | undefined = undefined;
    if (source.mesh && source.meshOffset && source.meshColor) {
        srcMeshOffset = { value: source.meshOffset.value, unit: source.meshOffset.unit };
        srcMeshColor = source.meshColor;
    }
    let dstMeshOffset: IUnitOfMeasure | undefined = undefined;
    let dstMeshColor: string | undefined = undefined;
    if (destination.mesh && destination.meshOffset && destination.meshColor) {
        dstMeshOffset = { value: destination.meshOffset.value, unit: destination.meshOffset.unit };
        dstMeshColor = destination.meshColor;
    }
    const newPreset: WizardPreset = {
        feederPreset: {
            modeId: build.modeId,
            connectorType: sourceGroup.type,
            fiberCount: build.fiberCount,
            fiberCountInUse: source.fiberCountInUse,
            bundleCount: build.bundleCount,
            flameRating: build.flameRating,
            pullingGrip: build.pullingGrip,
            cableOuterDiameter: build.cableOuterDiameter,
            fiberType: build.fiberType,
            groups: source.groups.every((g) => g.connectors.length === sourceGroup.connectors.length)
                ? source.groups.length
                : source.groups.length - 1,
            connectorsPerGroup: sourceGroup.connectors.length,
            mesh: source.mesh,
            meshOffset: srcMeshOffset,
            meshColor: srcMeshColor,
            overallLengthType: build.overallLengthType,
            aLength: { value: source.lengthA!.value, unit: source.lengthA!.unit },
            bLength: { value: source.lengthB!.value, unit: source.lengthB!.unit },
            staggerLength: { value: sourceGroup.stagger!.value, unit: sourceGroup.stagger!.unit },
            reverseStaggering: source.reverseStaggering,
            customTag: "",
        },
        dropPreset: {
            connectorType: destinationGroup.type,
            accessPointCount: destinations.length,
            calculateAccessPoints: build.autoAccessPoints,
            groups: destination.groups.length,
            connectorsPerGroup: destinationGroup.connectors.length,
            mesh: destination.mesh,
            meshOffset: dstMeshOffset,
            meshColor: dstMeshColor,
            aLength: { value: destination.lengthA!.value, unit: destination.lengthA!.unit },
            bLength: { value: destination.lengthB!.value, unit: destination.lengthB!.unit },
            staggerLength: { value: destinationGroup.stagger!.value, unit: destinationGroup.stagger!.unit },
            reverseStaggering: destination.reverseStaggering,
            fiberCountInUse: destination.fiberCountInUse,
        },
    };
    return newPreset;
}

// This is used to preserve the ids so we can update the proper entities on the backend
const updateWizardPreset = (previousPreset: WizardPreset, newPreset: WizardPreset): WizardPreset => {
    let feederMeshOffset: IUnitOfMeasure | undefined = undefined;
    let feederMeshColor: string | undefined = undefined;
    if (newPreset.feederPreset?.mesh && newPreset.feederPreset.meshOffset && newPreset.feederPreset.meshColor) {
        feederMeshOffset = {
            ...newPreset.feederPreset.meshOffset,
            id: previousPreset.feederPreset?.meshOffset?.id,
        };
        feederMeshColor = newPreset.feederPreset.meshColor;
    }
    let dropMeshOffset: IUnitOfMeasure | undefined = undefined;
    let dropMeshColor: string | undefined = undefined;
    if (newPreset.dropPreset?.mesh && newPreset.dropPreset.meshOffset && newPreset.dropPreset.meshColor) {
        dropMeshOffset = {
            ...newPreset.dropPreset.meshOffset,
            id: previousPreset.dropPreset?.meshOffset?.id,
        };
        dropMeshColor = newPreset.dropPreset.meshColor;
    }
    const updatedPreset: WizardPreset = {
        ...previousPreset,
        feederPreset: {
            ...newPreset.feederPreset,
            id: previousPreset.feederPreset?.id,
            meshOffset: feederMeshOffset,
            meshColor: feederMeshColor,
            aLength: {
                ...newPreset.feederPreset?.aLength!,
                id: previousPreset.feederPreset?.aLength?.id,
            },
            bLength: {
                ...newPreset.feederPreset?.bLength!,
                id: previousPreset.feederPreset?.bLength?.id,
            },
            staggerLength: {
                ...newPreset.feederPreset?.staggerLength!,
                id: previousPreset.feederPreset?.staggerLength?.id,
            },
        },
        dropPreset: {
            ...newPreset.dropPreset,
            id: previousPreset.dropPreset?.id,
            meshOffset: dropMeshOffset,
            meshColor: dropMeshColor,
            aLength: {
                ...newPreset.dropPreset?.aLength!,
                id: previousPreset.dropPreset?.aLength?.id,
            },
            bLength: {
                ...newPreset.dropPreset?.bLength!,
                id: previousPreset.dropPreset?.bLength?.id,
            },
            staggerLength: {
                ...newPreset.dropPreset?.staggerLength!,
                id: previousPreset.dropPreset?.staggerLength?.id,
            },
        },
    };
    return updatedPreset;
};

import { RefObject } from "react";
import { ISourceSetupState } from "../../../../store/overlay/wizard/setup/source-setup/source-setup";

export interface SourceFieldProps {
    disabled: boolean;
    validate: (newState: ISourceSetupState) => ISourceSetupState;
    applyChanges: (state: ISourceSetupState) => ISourceSetupState;
    inputRef?: RefObject<HTMLInputElement>;
}

export const isValidSourceSetup = (state: ISourceSetupState): boolean => {
    return sourceSetupValidationFields.every((k) => state[k]);
};

export const sourceSetupValidationFields = [
    "isALengthValid",
    "isBLengthValid",
    "isBundleCountFieldValid",
    "isGroupCountValid",
    "isMeshOffsetValid",
    "isSLengthValid",
    "isFiberCountPerConnectorValid",
] as const satisfies (keyof ISourceSetupState)[];

type ValidationField = (typeof sourceSetupValidationFields)[number];

export const getValidationErrorField = (state: ISourceSetupState): ValidationField | undefined => {
    return sourceSetupValidationFields.find((k) => !state[k]);
};

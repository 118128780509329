import { SnackbarProps } from "@mui/material";
import { ButtonProps } from "@corning-ctcm/silica-react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../locales/keys";
import { IGenericSnackbarProps, SnackbarType, SnackbarTypes } from "../../../models/ui/generic-snackbar";

const vertical = "bottom";
const horizontal = "right";

export const useGenericSnackbar = ({
    id,
    type,
    hideIcon,
    message,
    hideActionButton,
    hideCancelButton,
    hideCloseButton,
    onClose,
    onCancel,
    onConfirm,
    open,
    timeout,
}: IGenericSnackbarProps) => {
    const [icon, setIcon] = useState("cake");
    const { t } = useTranslation();

    const snackBarButtonProps = useMemo(() => {
        const buttonProps: ButtonProps[] = [];
        if (!hideCancelButton) {
            buttonProps.push({
                id: `${id}-cancel-button`,
                onClick: onCancel,
                children: t(LocalizationKeys.Cancel),
            });
        }

        if (!hideActionButton) {
            buttonProps.push({
                id: `${id}-confirm-button`,
                onClick: onConfirm,
                children: t(LocalizationKeys.Save),
            });
        }

        return buttonProps;
    }, [id, hideCancelButton, hideActionButton, t, onConfirm, onCancel]);

    useEffect(() => {
        setIcon(getSnackbarIcon(type));
    }, [type]);

    const snackbarProps: SnackbarProps = {
        id,
        anchorOrigin: { vertical, horizontal },
        className: type ? `generic-snackbar ${type}` : "generic-snackbar",
        open: open,
        onClose: onClose,
        message: message ?? "",
        autoHideDuration: timeout,
    };

    return {
        type,
        icon,
        hideIcon,
        message,
        hideCloseButton,
        open,
        timeout,
        onClose,
        snackBarButtonProps,
        snackbarProps,
    };
};

function getSnackbarIcon(snackbarType?: SnackbarType) {
    switch (snackbarType) {
        case SnackbarTypes.Information:
            return "info";
        case SnackbarTypes.Warning:
            return "warning";
        case SnackbarTypes.Error:
            return "cancel";
        case SnackbarTypes.Success:
            return "check_circle";
        default:
            return "cake";
    }
}

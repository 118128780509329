import { Alert, TextButton } from "@corning-ctcm/silica-react";
import { SnackbarProps } from "@mui/material";
import { useCallback } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../locales/keys";
import { setFocusOnError } from "../../../../store/overlay/wizard/wizard.reducers";
import { isDynamicUpdateDisabledSelector } from "../../../../store/overlay/wizard/wizard.selectors";

export const WizardErrorSnackbar = () => {
    const display = useSelector(isDynamicUpdateDisabledSelector);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    const snackbarProps: SnackbarProps = {
        open: display,
        message: t(LocalizationKeys.DrawingStoppedUpdating),
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
    };

    const onClick = useCallback(() => {
        dispatch(setFocusOnError(true));
    }, [dispatch]);

    return ReactDOM.createPortal(
        <Alert
            palette="error"
            variant="filled"
            snackbarProps={snackbarProps}
            action={
                <TextButton
                    palette="secondary"
                    size="small"
                    onClick={onClick}
                >
                    {t(LocalizationKeys.SeeError)}
                </TextButton>
            }
        />,
        document.body
    );
}
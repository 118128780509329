import { createSelector } from "@reduxjs/toolkit";

import {
    getConnectorAnchorPoint,
    getFurcationPoint,
} from "../../components/pixi/build/connectors/connector-furcation/connector-furcation.hooks";
import { getConnectorColor, getConnectorTexture } from "../../components/pixi/factories/texture";
import { ICollapseOptions } from "../../models/overlay/header/collapse-options";
import {
    DEFAULT_LABEL_DISTANCE_TOLERANCE,
    DEFAULT_TOLERANCE,
    DEFAULT_TOLERANCES,
    ITolerance,
} from "../../models/overlay/header/tolerances/tolerances";
import {
    IUnitOfMeasure,
    PrimaryUnit,
    Unit,
    convertTo,
    convertToDisplay,
    getSecondaryText,
} from "../../models/overlay/header/units-of-measure";
import {
    IConnectorAssignmentRow,
    initialConnectorAssignmentRow,
} from "../../models/overlay/polarity/connector-assignment/connector-assigment";
import { FlameRatings } from "../../models/overlay/wizard/setup/flame-rating";
import {
    ALengthMarkerContainer,
    DISTANCE_LABEL_TO_CONNECTOR_END,
    DropContainer,
    DropProps,
    GroupContainer,
    LegLengthMarkerContainer,
    ReportDropContainer,
    getDropContainerName,
} from "../../models/pixi/build/build";
import {
    DROP_CABLE_BORDER_WIDTH,
    IConnectorLegProps,
    LABEL_OFFSET_Y,
    STAGGER_OFFSET_Y,
    TIED_FURCATION_CURVE_END,
    getConnectorCableDrawWidth,
    getLegLabelDimensions,
    getSpriteLocation,
} from "../../models/pixi/build/connectors/connector-furcation";
import { CONNECTOR_STEP_OFFSET, GROUP_STEP } from "../../models/pixi/build/connectors/connectors";
import { AP_BORDER_WIDTH, AP_SIZE } from "../../models/pixi/build/drop-base/access-point";
import { TRUNK_WIDTH } from "../../models/pixi/build/drop-base/drop-base";
import { MASKED_VALUE } from "../../models/pixi/build/overall-length";
import {
    DIMENSION_LINE_DEFAULT_FONT_SCALE,
    DIMENSION_LINE_LABEL_OFFSET,
    DIMENSION_LINE_OFFSET,
    DIMENSION_LINE_OFFSET_FROM_NEXT_TAP,
    DIMENSION_LINE_START_OFFSET,
    IHorizontalDimensionLine,
    IPath,
    IVerticalDimensionLine,
} from "../../models/pixi/decorators/dimension-lines/dimension-lines";
import { Rectangle } from "../../models/pixi/decorators/interaction";
import { ITextPath, getToleranceText } from "../../models/pixi/decorators/text";
import { IPoint, IPointEmpty } from "../../models/pixi/pixi";
import { toolbarSelector } from "../overlay/footer/toolbar/toolbar.selectors";
import {
    unitsOfMeasureContainerPrimaryUnitSelector,
    unitsOfMeasureContainerUnitSelector,
} from "../overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { IConnectorAssignment, IConnectorAssignmentData, IPolarityData } from "../overlay/polarity/polarity";
import { ITriggerInfo } from "../overlay/reports/trigger/trigger";
import { ConfigurationType, Fiber84, IConnectorType, Stagger0, getConnectorType, getConnectorTypeFromDrop } from "../overlay/wizard/wizard";
import { highlightsSelector } from "../pixi/connector-highlights/connector-highlights.selectors";
import { IFiberMapData, IFiberMapIndex } from ".//build/connector/polarity/fiber-map";
import { connectorColorsSelector, defaultTriggersColorSelector } from "./boundaries/boundaries.selectors";
import { DropData, IBuildData, IDropContext, getGroupsWithIndex, initialPrintSettings, sortProjects } from "./build/build";
import { extractBuildInfo } from "./build/build.actions";
import {
    IConnectorData,
    IConnectorGroupData,
    IConnectorGroupDataWithIndex,
    getNbConnectors,
    getNbConnectorsCollapsed,
    getNbGroupsCollapsed,
} from "./build/connector/connector";
import { PolarityMapList } from "./build/connector/polarity/polarity";
import {
    buildsConfigsSelector,
    fiberMapsSelector,
    getDisplayedFiberMap,
} from "./build/connector/polarity/polarity.selectors";
import { DropSide, IDrop, getDefaultMeshColor } from "./build/drop";
import { reportDocumentSelector } from "./document/document.selectors";
import { PAGE_SIZE } from "./project-manager/project-manager";
import { buildsSelector, currentBuildSelector } from "./root.selectors";
import { WorkspaceState } from "./workspace.reducers";
import { Sorts } from "../overlay/project/project";

export const buildIdSelector = createSelector(currentBuildSelector, (build) => build?.id ?? 0);

export const buildInfoSelectorFactory = (projectId?: number) => {
    return createSelector(currentBuildSelector, buildsSelector, (currentBuild, builds) => {
        return builds.find((b) => b.buildId === projectId) || extractBuildInfo(currentBuild!);
    });
};

export const currentBuildInfoSelector = createSelector(
    currentBuildSelector,
    (build) => build && extractBuildInfo(build)
);

export const currentSourceSelector = createSelector(currentBuildSelector, (currentBuild) => currentBuild?.drops[0]);

export const currentTAPsSelector = createSelector(currentBuildSelector, (currentBuild) =>
    currentBuild ? currentBuild.drops.filter((d) => d.side === "distribution") : []
);

export const currentTAPsSelectorFactory = (position: number) => {
    return createSelector(currentTAPsSelector, (taps) => taps.find((t) => t.position === position));
};

export const currentConnectorTypeSelector = (state: WorkspaceState) =>
    state.builds.currentBuild?.drops[0].groups[0].type;
export const dropsSelector = (state: WorkspaceState) => state.builds.currentBuild?.drops ?? [];
export const autoAccessPointsSelector = (state: WorkspaceState) => state.builds.currentBuild?.autoAccessPoints ?? false;

export const dropSelectorFactory = (position: number) => {
    return createSelector(dropsSelector, (drops) => drops.find((d) => d.position === position));
};

export const specificDropSelectorFactory = (index: number, isReportOverviewDrop: boolean, isReportDrop: boolean) => {
    return createSelector(dropsSelector, reportDocumentSelector, (currentDrops, { overview, drops: reportDrops }) => {
        let drop = currentDrops[index];

        if (isReportOverviewDrop && overview && overview.drops) {
            drop = overview.drops[index];
        } else if (drop && isReportDrop && reportDrops) {
            index -= 1;
            if (index < reportDrops.length) {
                const { connectorsCollapsed, groupsCollapsed } = reportDrops[index].drop;
                drop = {
                    ...drop,
                    connectorsCollapsed,
                    groupsCollapsed,
                };
            }
        }

        return drop;
    });
};

export const srcDropSelector = (state: WorkspaceState) => state.builds.currentBuild?.drops[0];
export const dstDropsSelector = (state: WorkspaceState) =>
    state.builds.currentBuild?.drops.filter((d) => d.position && d.position > 0);

export const currentAvailabilitySelector = createSelector(
    currentBuildSelector,
    (currentBuild) => currentBuild?.availability
);

export const buildPositionsSelector = createSelector(currentBuildSelector, currentTAPsSelector, (currentBuild, taps) =>
    currentBuild ? [0, ...taps.map((d) => d.position + 1)] : []
);

export const currentBuildPartNumberSelector = (state: WorkspaceState) => {
    const currentBuild = state.builds.currentBuild;
    if (currentBuild) {
        if (currentBuild.catalogCode !== undefined && currentBuild.catalogCode?.length > 0) {
            return currentBuild.catalogCode;
        }
        if (currentBuild.partNumber !== undefined && currentBuild.partNumber?.length > 0) {
            return currentBuild.partNumber;
        }
    }
    return "";
};

export const currentBuildFinalizedSelector = (state: WorkspaceState) => !!state.builds.currentBuild?.catalogCode;

export const currentBuildConfigurationTypeSelector = createSelector(
    currentBuildSelector,
    (build) => build?.configurationType ?? ConfigurationType.Patching
);

export const fiberCountSelector = (state: WorkspaceState) => state.builds.currentBuild?.fiberCount ?? Fiber84.count;
export const fiberTypeSelector = (state: WorkspaceState) => state.builds.currentBuild?.fiberType;
export const flameRatingSelector = (state: WorkspaceState) =>
    state.builds.currentBuild?.flameRating ?? FlameRatings.Riser;
export const bundleCountSelector = (state: WorkspaceState) =>
    state.builds.currentBuild?.bundleCount ?? getNbConnectors(state.builds.currentBuild?.drops[0].groups ?? []);

export const nbDropsSelector = (state: WorkspaceState) => {
    return state.builds.currentBuild ? state.builds.currentBuild.drops.length : 0;
};

export const nbDistributionSelector = (state: WorkspaceState) => {
    return state.builds.currentBuild
        ? state.builds.currentBuild.drops.filter((d) => d.side === "distribution").length
        : 0;
};

export const connectorTypesSelector = createSelector(currentBuildSelector, (currentBuild) => {
    const connectorTypes: IConnectorType[] = [];
    if (currentBuild) {
        const [source, ...destinations] = currentBuild.drops;
        const feederConnectorType = source.groups[0].connectors[0].type;
        if (feederConnectorType) {
            connectorTypes.push(getConnectorType(feederConnectorType));
        }
        const dstConnectors = destinations.map((d) => d.groups[0].connectors[0]);
        for (const dstConnector of dstConnectors) {
            if (dstConnector.type) {
                connectorTypes.push(getConnectorType(dstConnector.type));
            }
        }
    }
    return connectorTypes;
});

export const buildInfoListSelectorFactory = (pinCurrentBuild: boolean = true, sortType: string = Sorts.DateModified, ascending: boolean = false) => {
    return createSelector(buildsSelector, currentBuildInfoSelector, (list, currentBuild) => {
        if (pinCurrentBuild && currentBuild?.buildId) {
            const sortedBuilds = list.filter((b) => b.buildId !== currentBuild.buildId)
                                       .sort((a, b) => sortProjects(sortType, a, b, ascending));
            return [currentBuild, ...sortedBuilds];
        }
        return list;
    });
};

export const buildCountSelector = createSelector(buildsSelector, (builds) => builds.length);

export const buildsPageIndexSelector = createSelector(buildCountSelector, (count) =>
    Math.floor((count - 1) / PAGE_SIZE)
);

export const currentTAPsFibersSelector = createSelector(currentTAPsSelector, (taps) =>
    taps
        .flatMap((t) => t.groups)
        .flatMap((g) => g.connectors)
        .map((c) => c.fiberCountInUse ?? getConnectorType(c.type).fiberCount)
        .reduce((a, b) => a + b, 0)
);

export const totalNbrConnectorsSelector = createSelector(currentSourceSelector, currentTAPsSelector, (source, taps) => {
    const nbrSrcConnectors = source ? getGroupsConnectors(source.groups).length : 0;
    const nbrTapConnectors = taps
        .map((tap) => getGroupsConnectors(tap.groups).length)
        .reduce((prev, curr) => prev + curr);
    return nbrSrcConnectors + nbrTapConnectors;
});

export const getGroupsConnectors = (groups: IConnectorGroupData[]) => {
    return groups.flatMap((g) => g.connectors);
};

export const selectedDropSelector = createSelector(
    currentBuildSelector,
    toolbarSelector,
    (build, toolbar): DropData | undefined => {
        const { selected, side } = toolbar.selection;
        if (selected === -1) {
            return undefined;
        }

        return build?.drops.find((d) => d.position === selected && d.side === side) as DropData;
    }
);

export const colorSelector = createSelector(selectedDropSelector, connectorColorsSelector, (drop, connectorColors) => {
    if (drop) {
        const connectorType = drop.groups.length > 0 ? drop.groups[0].type : undefined;
        if (connectorType) {
            return connectorColors[connectorType];
        } else {
            return [];
        }
    }
    return [];
});

export const triggerColorsInfoSelector = createSelector(
    selectedDropSelector,
    defaultTriggersColorSelector,
    (drop, defaultColors) => {
        const triggerInfos: ITriggerInfo[] = [];
        if (drop) {
            for (const group of drop.groups) {
                const conn = group.connectors[0];
                const defaultColor = defaultColors[conn?.type ?? ""];
                if (conn.groupPosition !== undefined && conn.type) {
                    const color = conn.color ?? defaultColor.name;
                    triggerInfos.push({
                        groupPosition: conn.groupPosition + 1,
                        connectorType: conn.type,
                        color: getConnectorColor(conn.type, color),
                    });
                }
            }
        }
        return triggerInfos;
    }
);

export const buildConnectorTypesSelector = createSelector(
    dropsSelector,
    drops => {
        const connectorTypes = new Set<string>();
        for (const drop of drops) {
            for (const group of drop.groups) {
                for (const connector of group.connectors) {
                    if (connector.type) {
                        connectorTypes.add(connector.type);
                    }
                }
            }
        }
        return connectorTypes;
    }
);

export const buildColorsSelector = createSelector(
    buildConnectorTypesSelector,
    connectorColorsSelector,
    (buildConnectorTypes, connectorColors) => {
        return Object.fromEntries(
            Object.entries(connectorColors).filter(([connectorType, _]) => buildConnectorTypes.has(connectorType))
        )
    }
);

export const commonConnectorColorsSelector = createSelector(buildColorsSelector, (connectorColors) => {
    const colors = Object.values(connectorColors);
    const commonColors =
        colors.length > 0 ? colors[0].filter((color) => colors.every((arr) => arr.includes(color))) : [];
    return commonColors;
});

export const currentOverallLengthTypeSelector = (state: WorkspaceState) =>
    state.builds.currentBuild?.overallLengthType ?? "furcation";

export const overallLengthSelector = createSelector(
    unitsOfMeasureContainerUnitSelector,
    currentOverallLengthTypeSelector,
    dropsSelector,
    (unit, overallLengthType, drops) => {
        const [feeder, ...distribution] = drops;
        let overallLengthValue = distribution
            .map((t) => convertTo(t.lengthA, unit).value)
            .reduce((prev, curr) => prev + curr);
        if (overallLengthType === "connector") {
            overallLengthValue += Math.max(...feeder.groups.map((g) => convertTo(g.lengthB, unit).value ?? 0));
            overallLengthValue += Math.max(
                ...distribution.flatMap((d) => d.groups.map((g) => convertTo(g.lengthB, unit).value ?? 0))
            );
        }

        const overallLength: IUnitOfMeasure = { value: overallLengthValue, unit };
        return { value: convertToDisplay(overallLength, unit), unit };
    }
);

export const dropsSelectorFactory = (initial: IPoint, offset?: IPoint) => {
    return createSelector(
        currentBuildSelector,
        unitsOfMeasureContainerPrimaryUnitSelector,
        unitsOfMeasureContainerUnitSelector,
        (build, primaryUnit, unit) => getDrops(build, primaryUnit, unit, initial, offset)
    );
};

export const currentTolerancesSelector = (state: WorkspaceState) =>
    state.builds.currentBuild?.tolerances ?? DEFAULT_TOLERANCES;
export const overallLengthToleranceSelector = (state: WorkspaceState) =>
    state.builds.currentBuild?.tolerances?.overallLengthTolerance ?? DEFAULT_TOLERANCE;
export const showTolerancesSelector = (state: WorkspaceState) =>
    state.builds.currentBuild?.tolerances?.showTolerances ?? DEFAULT_TOLERANCES.showTolerances;

export const getDrops = (
    build: IBuildData | undefined,
    primaryUnit: PrimaryUnit,
    unit: Unit,
    initial: IPoint,
    offset?: IPoint
) => {
    const drops: DropProps[] = [];
    if (build) {
        const dropsData: DropData[] = [...build.drops];
        if (dropsData.length > 0) {
            let x = offset ? initial.x + offset.x : initial.x;
            let y = offset ? initial.y + offset.y : initial.y;

            const nbDrops = build.drops.length;
            const lengthAs = dropsData.map((t) => t.lengthA ?? Stagger0.value);

            for (let i = 0; i < dropsData.length; i++) {
                const data = dropsData[i];
                const availability = build.availability ?? { sourceEnabled: false, enabledDestinations: [] };

                const { sourceEnabled, enabledDestinations } = availability;
                const enabled =
                    data.side === "distribution" ? enabledDestinations.includes(data.position) : sourceEnabled;
                const context: IDropContext = getDropContext(data, primaryUnit, unit, enabled, nbDrops);

                const legProps: IConnectorLegProps[] = getLegs(context);

                const { legLengthTolerance, labelDistanceTolerance, meshLengthTolerance } =
                    build.tolerances ?? DEFAULT_TOLERANCES;
                let lengthA: IHorizontalDimensionLine | undefined = undefined;

                const distance = getMaxAPToAPDistance(
                    context.side,
                    context.nbGroups,
                    context.nbConnectors,
                    context.connectorType,
                    dropsData[i + 1]
                );
                if (i < dropsData.length - 1) {
                    const nextDropShell = dropsData[i + 1].shell ?? "soft";
                    const measurementsPosition = build.measurementsPosition ?? "interior";
                    lengthA = createAPaths(
                        context,
                        lengthAs,
                        nextDropShell,
                        measurementsPosition,
                        distance,
                        build.maskLengths,
                        legLengthTolerance
                    );
                }
                const lengthB = createBPaths(context, legProps, legLengthTolerance, meshLengthTolerance);
                const staggerMarkerPaths = createStaggerMarkerPaths(context, legProps, legLengthTolerance);
                const staggerLinePaths = createStaggerLinePaths(
                    context,
                    legProps,
                    legLengthTolerance,
                    meshLengthTolerance
                );
                const lengthLabel =
                    data.side === "feeder" ? createLabelPath(context, legProps, labelDistanceTolerance) : undefined;

                context.dimensionLines = { lengthA, lengthB, staggerMarkerPaths, staggerLinePaths, lengthLabel };

                drops.push({ key: `cable-drop-${i}`, x, y, context, legProps });

                x += distance;
            }
        }
    }

    return drops;
};

export const getConnectorStep = (connectorType: string): number => {
    return CONNECTOR_STEP_OFFSET + (getConnectorTexture(connectorType)?.width ?? 0);
};

export const getAPWidth = (side: string, nbGroups: number, nbConnectors: number, connectorType: string) => {
    const dropMinWidth = getDropMinWidth(side);
    const dropWidth = getDropWidth(nbGroups, nbConnectors, connectorType);
    return Math.max(dropWidth, dropMinWidth);
};

export const getMaxAPToAPDistance = (
    side: DropSide,
    nbGroups: number,
    nbConnectors: number,
    connectorType: string,
    nextDrop?: DropData
): number => {
    let distance = getAPWidth(side, nbGroups, nbConnectors, connectorType);
    if (nextDrop) {
        const nextConnectorsCollapsed = nextDrop.connectorsCollapsed;
        const nextGroupsCollapsed = nextDrop.groupsCollapsed;
        const nextNbGroups = nextGroupsCollapsed
            ? getNbGroupsCollapsed(nextDrop.groups.length)
            : nextDrop.groups.length;
        let nextGroups: IConnectorGroupData[];
        if (nextNbGroups > 1) {
            nextGroups = nextGroupsCollapsed
                ? [nextDrop.groups[0], nextDrop.groups[nextDrop.groups.length - 1]]
                : nextDrop.groups;
        } else {
            nextGroups = nextGroupsCollapsed ? [nextDrop.groups[0]] : nextDrop.groups;
        }
        const nextNbConnectors = nextConnectorsCollapsed
            ? getNbConnectorsCollapsed(nextNbGroups)
            : getNbConnectors(nextGroups);
        const nextSide = nextDrop.side;
        const nextShell = nextDrop.shell ?? "soft";
        const nextConnectorType = nextDrop.groups[0].type ?? "";
        const nextDistance = getAPWidth(nextSide, nextNbGroups, nextNbConnectors, nextConnectorType);

        distance = distance * 0.5 + nextDistance * 0.5;

        if (side === "distribution") {
            distance += DIMENSION_LINE_OFFSET_FROM_NEXT_TAP;
        } else {
            distance += DIMENSION_LINE_OFFSET_FROM_NEXT_TAP * 0.25;
        }

        if (side === "feeder" && nextShell === "hard") {
            distance += DIMENSION_LINE_LABEL_OFFSET;
        }
    }

    return distance;
};

export const getDropWidth = (nbGroups: number, nbConnectors: number, connectorType: string) => {
    const connectorStep = getConnectorStep(connectorType);
    return nbGroups * GROUP_STEP * 2 + nbConnectors * connectorStep;
};

export const getDropMinWidth = (side: string) => {
    return side === "feeder" ? AP_SIZE.width * 3 : AP_SIZE.width * 2;
};

export const getReportDistributionDropProps = (
    d1: DropData,
    d2: DropData,
    primaryUnit: PrimaryUnit,
    unit: Unit,
    nbTaps: number
): DropProps => {
    const curr: IDropContext = getDropContext(d1, primaryUnit, unit, true, nbTaps);
    const prev: IDropContext = getDropContext(d2, primaryUnit, unit, true, nbTaps);

    return {
        x: getAPWidth(prev.side, prev.nbGroups, prev.nbConnectors, prev.connectorType),
        y: 0,
        context: curr,
        legProps: getLegs(curr),
    };
};

export const getReportDrop = (
    drop: DropData,
    primaryUnit: PrimaryUnit,
    unit: Unit,
    point: IPoint,
    measurementsPosition: string,
    nbDrops: number,
    maskLengths: boolean,
    nextDrop?: DropData,
    tolerance?: ITolerance
): DropProps => {
    const context: IDropContext = getDropContext(drop, primaryUnit, unit, true, nbDrops, true);
    const legProps: IConnectorLegProps[] = getLegs(context);

    const lengthA = createReportAPath(
        drop,
        nbDrops,
        primaryUnit,
        unit,
        measurementsPosition,
        maskLengths,
        nextDrop,
        tolerance
    );
    const lengthB = createBPaths(context, legProps);
    const staggerLinePaths = createStaggerLinePaths(context, legProps);
    const staggerMarkerPaths = createStaggerMarkerPaths(context, legProps);

    context.dimensionLines = { lengthA, lengthB, staggerLinePaths, staggerMarkerPaths };
    context.isReportDrop = true;

    const index = drop.side === "feeder" ? drop.position : drop.position + 1;
    return { key: `report-drop-${index}`, x: point.x, y: point.y, context, legProps };
};

export const getDropContext = (
    data: DropData,
    primaryUnit: PrimaryUnit,
    unit: Unit,
    enabled: boolean,
    nbDrops: number,
    isReportDrop: boolean = false
): IDropContext => {
    const index = data.side === "distribution" ? data.position + 1 : 0;
    const { groupsCollapsed, connectorsCollapsed, shell, fiberCountInUse } = data;

    const allGroups = getGroupsWithIndex(data);
    let groups: IConnectorGroupDataWithIndex[] = allGroups;
    if (groupsCollapsed && allGroups.length > 2) {
        const firstGroup = allGroups[0];
        const lastGroup = allGroups[groups.length - 1];
        groups = [
            {
                ...firstGroup,
                position: 0,
                connectors: firstGroup.connectors.map((c) => ({ ...c, groupIndex: 0 })),
            },
            {
                ...lastGroup,
                position: 1,
                connectors: lastGroup.connectors.map((c) => ({ ...c, groupIndex: 1 })),
            },
        ];
    }
    const connectors = groups.flatMap((g) => {
        let connectors = g.connectors;
        if (connectorsCollapsed && g.connectors.length > 2) {
            connectors = [g.connectors[0], g.connectors[g.connectors.length - 1]];
        }
        return connectors;
    });

    const type = getConnectorType(groups[0].type);
    const connectorType = type.description ?? "";
    const fiberCount = type.fiberCount;
    const nbConnectors = connectors.length;
    const furcationPoint = getFurcationPoint(index, shell);
    const connectorAnchorPoint = getConnectorAnchorPoint(furcationPoint);
    const defaultLength = Stagger0.value;
    const stagger = groups[0]?.stagger ?? defaultLength;

    return {
        index,
        position: data.position,
        containerName: getDropContainerName(isReportDrop, data.side, data.position),
        primaryUnit,
        unit,
        lengthA: data.lengthA ?? defaultLength,
        lengthB: data.lengthB ?? defaultLength,
        customBLength: data.customBLength ?? false,
        groups,
        nbGroups: groups.length,
        nbConnectors,
        connectors,
        cablesTied: data.cablesTied,
        groupsCollapsed,
        nbGroupsHidden: data.groupsCollapsed && data.groups ? data.groups.length - groups.length : undefined,
        connectorsCollapsed,
        enabled,
        connectorType,
        stagger,
        nbDrops,
        furcationPoint,
        connectorAnchorPoint,
        dimensionLines: {},
        mesh: data.mesh,
        meshColor: data.meshColor,
        meshOffset: data.meshOffset,
        fiberCount,
        fiberCountInUse,
        side: data.side,
        shell,
    };
};

export const createReportAPath = (
    drop: DropData,
    nbDrops: number,
    primaryUnit: PrimaryUnit,
    unit: Unit,
    measurementsPosition: string,
    maskLengths: boolean,
    nextDrop?: DropData,
    tolerance?: ITolerance
): IHorizontalDimensionLine | undefined => {
    if (drop.side === "feeder") return undefined;

    const index = drop.position + 1;
    const side = drop.side;
    const nbGroups = drop.groupsCollapsed ? getNbGroupsCollapsed(drop.groups.length) : drop.groups.length;
    const nbConnectors = drop.connectorsCollapsed ? getNbConnectorsCollapsed(nbGroups) : getNbConnectors(drop.groups);
    const connectorType = drop.groups[0].type ?? "";
    const distance = getMaxAPToAPDistance(side, nbGroups, nbConnectors, connectorType, nextDrop);

    const isSoftShell = drop.shell === "soft";
    const isLastDrop = drop.side === "distribution" && drop.position + 1 === nbDrops - 1;
    const isInterior = measurementsPosition === "interior";
    let x1 = -distance * 0.5;
    let x2 = distance * 0.5;

    if (isSoftShell) {
        if (isLastDrop) {
            x1 = -distance;
            x2 = AP_SIZE.width * 0.5;
        }

        if (!(isLastDrop && !isInterior)) {
            x2 = -AP_SIZE.width * 0.5;
        }

        if (!isLastDrop) {
            x2 += AP_SIZE.width;
        }
    } else {
        if (isLastDrop) {
            x1 = -distance;
        }

        x2 = 0;
    }

    const y = -AP_SIZE.height * 2;
    const leftExtensionLine: IPath = {
        start: {
            x: x1,
            y: y + DIMENSION_LINE_START_OFFSET,
        },
        end: {
            x: x1,
            y: y - DIMENSION_LINE_START_OFFSET,
        },
    };

    const lengthAValue = convertToDisplay(drop.lengthA, unit);
    const { primary, secondary } = getLengthMarkerTexts(primaryUnit, unit, lengthAValue, maskLengths, index);
    const text: ITextPath = {
        primary,
        secondary,
        position: "middle",
        tolerance: getToleranceText(DEFAULT_TOLERANCE, tolerance ?? DEFAULT_TOLERANCE, unit),
        hideUnits: maskLengths,
        maskLengths,
    };

    const dimensionLine: IPath = {
        start: { x: x1, y },
        end: { x: x2, y },
        text,
    };

    const rightExtensionLine: IPath = {
        start: {
            x: x2,
            y: y + DIMENSION_LINE_START_OFFSET,
        },
        end: {
            x: x2,
            y: y - DIMENSION_LINE_START_OFFSET,
        },
    };

    return { leftExtensionLine, dimensionLine, rightExtensionLine };
};

export const createAPaths = (
    curr: IDropContext,
    lengths: IUnitOfMeasure[],
    shell: string,
    measurementsPosition: string,
    distance: number,
    maskLengths: boolean,
    tolerance?: ITolerance
): IHorizontalDimensionLine => {
    const { index, primaryUnit, unit, isReportDrop } = curr;

    let x1 = 0;
    let x2 = distance;
    const isBeforeLast = index + 1 === lengths.length - 1;
    if (measurementsPosition === "interior") {
        if (curr.shell === "soft") {
            x1 = AP_SIZE.width * 0.5;
        }
        if (shell === "soft") {
            x2 = isBeforeLast ? distance - AP_SIZE.width * 0.5 : distance + AP_SIZE.width * 0.5;
        }
    }
    if (measurementsPosition === "exterior") {
        if (curr.shell === "soft") {
            x1 = AP_SIZE.width * 0.5;
        }
        if (index === 0) {
            x1 = -AP_SIZE.width * 0.5;
        }
        if (shell === "soft") {
            x2 = distance + AP_SIZE.width * 0.5;
        }
    }
    const y = -AP_SIZE.height * 2;

    const leftExtensionLine: IPath = {
        start: {
            x: x1,
            y: y + DIMENSION_LINE_START_OFFSET,
        },
        end: {
            x: x1,
            y: y - DIMENSION_LINE_START_OFFSET,
        },
    };

    const dropContainerName = isReportDrop ? ReportDropContainer : DropContainer;
    const dropIndex = index + 1;
    const lengthAValue = convertToDisplay(lengths[dropIndex], unit);
    const { primary, secondary } = getLengthMarkerTexts(primaryUnit, unit, lengthAValue, maskLengths, dropIndex);
    const text: ITextPath = {
        name: `distribution-${dropContainerName}-${dropIndex}-${ALengthMarkerContainer}`,
        primary,
        secondary,
        inputProps: {
            value: lengthAValue,
            index,
        },
        position: "middle",
        tolerance: getToleranceText(DEFAULT_TOLERANCE, tolerance ?? DEFAULT_TOLERANCE, unit),
        hideUnits: maskLengths,
        maskLengths,
    };

    const dimensionLine: IPath = {
        start: { x: x1 + AP_BORDER_WIDTH * 1.5, y },
        end: { x: x2 - AP_BORDER_WIDTH * 1.5, y },
        text,
    };

    const rightExtensionLine: IPath | undefined = isBeforeLast
        ? {
              start: {
                  x: x2,
                  y: y + DIMENSION_LINE_START_OFFSET,
              },
              end: {
                  x: x2,
                  y: y - DIMENSION_LINE_START_OFFSET,
              },
          }
        : undefined;

    return { leftExtensionLine, dimensionLine, rightExtensionLine };
};

export const createBPaths = (
    curr: IDropContext,
    legProps: IConnectorLegProps[],
    legLengthTolerance?: ITolerance,
    meshLengthTolerance?: ITolerance
): IVerticalDimensionLine => {
    const { index, primaryUnit, unit, connectorType, customBLength, groups, mesh, meshOffset, stagger, containerName } =
        curr;

    // If there is a 0 stagger, get the legs from the first group
    const legsAtFirstStagger = [...legProps].filter((m) => m.staggerPosition === 0);
    const firstGroupIndex = Math.min(...legsAtFirstStagger.map((l) => l.groupIndex));
    const group = groups[firstGroupIndex];

    let topExtensionLine: IPath | undefined = undefined;
    let dimensionLine: IPath | undefined = undefined;
    let bottomExtensionLine: IPath | undefined = undefined;
    let meshDimensionLine: IPath | undefined = undefined;
    let meshExtensionLine: IPath | undefined = undefined;
    const maxBLength = Math.max(...groups.map((g) => g.lengthB?.value ?? 0));
    if (group.lengthB) {
        const legs = legsAtFirstStagger.filter((m) => m.groupIndex === firstGroupIndex);

        const x1 = Math.min(...legProps.map((m) => m.location.x));
        const x2 = x1 - DIMENSION_LINE_OFFSET;
        const y1 = (AP_SIZE.height + DIMENSION_LINE_START_OFFSET) * 0.5;

        topExtensionLine = {
            start: {
                x: x2 - DIMENSION_LINE_START_OFFSET,
                y: y1,
            },
            end: {
                x: index === 0 ? x2 + DIMENSION_LINE_START_OFFSET : x1,
                y: y1,
            },
        };

        const texture = getConnectorTexture(connectorType);
        const y2 = legs[0].location.y + texture.height;
        dimensionLine = {
            start: {
                x: x2,
                y: y2 - AP_BORDER_WIDTH * 1.5,
            },
            end: {
                x: x2,
                y: y1 + AP_BORDER_WIDTH * 1.5,
            },
        };

        const lengthB = group.lengthB;
        const lengthBValue = convertToDisplay(lengthB, unit);
        const groupPosition = group.position ?? 0;
        const text: ITextPath = {
            name: `${containerName}-${LegLengthMarkerContainer}-${GroupContainer}-${groupPosition}`,
            primary: groupPosition === 0 && lengthB.value !== maxBLength ? `B${index}: ${lengthBValue}` : lengthBValue,
            secondary: getSecondaryText(primaryUnit, unit, lengthBValue),
            inputProps: {
                value: lengthBValue,
                index: firstGroupIndex,
            },
            position: "start",
            tolerance: getToleranceText(DEFAULT_TOLERANCE, legLengthTolerance ?? DEFAULT_TOLERANCE, unit),
        };

        bottomExtensionLine = {
            start: {
                x: x2 - DIMENSION_LINE_START_OFFSET,
                y: y2,
            },
            end: { x: stagger.value !== 0 || customBLength ? x2 - DIMENSION_LINE_START_OFFSET : x1, y: y2 }, // Only draw line here if no stagger, otherwise the stagger lines will handle it
            text,
        };

        const meshY2 = legs[0].legEndpoint.y + TIED_FURCATION_CURVE_END + TRUNK_WIDTH / 2;
        meshDimensionLine = mesh
            ? {
                  start: {
                      x: x2,
                      y: meshY2 - AP_BORDER_WIDTH * 1.5,
                  },
                  end: {
                      x: x2,
                      y: y1 + AP_BORDER_WIDTH * 1.5,
                  },
              }
            : undefined;

        let meshBValue: string | undefined = undefined;
        if (mesh && meshOffset) {
            meshBValue = convertToDisplay({ ...group.lengthB, value: group.lengthB.value - meshOffset.value }, unit);
        }

        meshExtensionLine = meshBValue
            ? {
                  start: {
                      x: x2 - DIMENSION_LINE_START_OFFSET,
                      y: meshY2,
                  },
                  end: {
                      x:
                          stagger.value !== 0 || customBLength
                              ? x2 - DIMENSION_LINE_START_OFFSET
                              : (legs[0].legEndpoint.x + legs.slice(-1)[0].legEndpoint.x) * 0.5 -
                                (TRUNK_WIDTH + DIMENSION_LINE_START_OFFSET) * 0.5,
                      y: meshY2,
                  },
                  text: {
                      primary: meshBValue,
                      secondary: getSecondaryText(primaryUnit, unit, meshBValue),
                      position: "start",
                      tolerance: getToleranceText(DEFAULT_TOLERANCE, meshLengthTolerance ?? DEFAULT_TOLERANCE, unit),
                  },
              }
            : undefined;
    }

    return { topExtensionLine, meshDimensionLine, meshExtensionLine, dimensionLine, bottomExtensionLine };
};

export const createLabelPath = (
    curr: IDropContext,
    legProps: IConnectorLegProps[],
    tolerance?: ITolerance
): IVerticalDimensionLine => {
    const { primaryUnit, unit, connectorType, groups } = curr;

    const leg = [...legProps]
        .filter((m) => m.groupIndex === groups.length - 1)
        .sort((a, b) => b.connectorPosition - a.connectorPosition)[0];
    if (!leg) return { topExtensionLine: undefined, bottomExtensionLine: undefined, dimensionLine: undefined };

    const cableWidth = getConnectorCableDrawWidth(connectorType);
    const widthWithBorder = cableWidth + DROP_CABLE_BORDER_WIDTH;
    const labelDimensions = getLegLabelDimensions(widthWithBorder);
    const x1 = leg.location.x + labelDimensions.width + DIMENSION_LINE_START_OFFSET;
    const x2 = x1 + DIMENSION_LINE_OFFSET - DIMENSION_LINE_START_OFFSET;
    const y1 = leg.location.y + leg.staggerPosition * STAGGER_OFFSET_Y - LABEL_OFFSET_Y + labelDimensions.height;

    const topExtensionLine: IPath = {
        start: {
            x: x2,
            y: y1,
        },
        end: {
            x: x1,
            y: y1,
        },
    };

    const texture = getConnectorTexture(connectorType);
    const y2 = leg.location.y + leg.staggerPosition * STAGGER_OFFSET_Y + texture.height;

    const labelLengthValue = convertToDisplay(DISTANCE_LABEL_TO_CONNECTOR_END, unit);
    const text: ITextPath = {
        primary: labelLengthValue,
        secondary: getSecondaryText(primaryUnit, unit, labelLengthValue),
        position: "middle-out",
        tolerance: getToleranceText(
            DEFAULT_LABEL_DISTANCE_TOLERANCE,
            tolerance ?? DEFAULT_LABEL_DISTANCE_TOLERANCE,
            unit
        ),
    };

    const dimensionLine: IPath = {
        start: {
            x: x2 - DIMENSION_LINE_START_OFFSET,
            y: y2,
        },
        end: {
            x: x2 - DIMENSION_LINE_START_OFFSET,
            y: y1,
        },
        text,
    };

    const bottomExtensionLine: IPath = {
        start: {
            x: x2,
            y: y2,
        },
        end: { x: x1, y: y2 },
    };

    return { topExtensionLine, bottomExtensionLine, dimensionLine };
};

export const createStaggerMarkerPaths = (
    curr: IDropContext,
    legProps: IConnectorLegProps[],
    tolerance?: ITolerance
): IPath[] => {
    const { connectorType, stagger, customBLength } = curr;
    if (stagger.value === 0 && !customBLength) return [];

    const { height } = getConnectorTexture(connectorType);

    return getStaggerMarkers(height, curr, legProps);
};

export const getStaggerMarkers = (height: number, context: IDropContext, legProps: IConnectorLegProps[]) => {
    const { nbGroups, groups, mesh, groupsCollapsed, customBLength, nbGroupsHidden, stagger, unit } = context;
    if (nbGroups <= 1 && legProps.length === 0) return [];

    const x = Math.min(...legProps.map((m) => m.location.x)) - DIMENSION_LINE_OFFSET;
    let text: ITextPath | undefined = undefined;
    if (groupsCollapsed) {
        text = customBLength
            ? {
                  primary: "...",
                  position: "middle",
                  scale: DIMENSION_LINE_DEFAULT_FONT_SCALE,
                  hideUnits: true,
              }
            : {
                  primary: `${nbGroupsHidden}x${convertToDisplay(stagger, unit)}`,
                  position: "middle",
                  scale: DIMENSION_LINE_DEFAULT_FONT_SCALE,
              };
    }

    const staggerMarkerPaths: IPath[] = [];
    groups.forEach((group) => {
        const groupPosition = group.position ?? 0;
        const groupLegs = legProps.filter((lp) => lp.groupIndex === groupPosition);
        const leg = groupLegs[0];
        if (leg.staggerPosition > 0) {
            const previousStaggerPosition = leg.staggerPosition - 1;

            const y = leg.location.y + height;
            const start: IPoint = {
                x,
                y: y + leg.staggerPosition * STAGGER_OFFSET_Y,
            };
            const end: IPoint = {
                x,
                y: y + previousStaggerPosition * STAGGER_OFFSET_Y,
            };

            staggerMarkerPaths.push({
                start,
                end,
                text,
            });

            if (mesh) {
                const meshY = leg.legEndpoint.y + TIED_FURCATION_CURVE_END + TRUNK_WIDTH * 0.5;
                const start: IPoint = {
                    x,
                    y: meshY + leg.staggerPosition * STAGGER_OFFSET_Y,
                };
                const end: IPoint = {
                    x,
                    y: meshY + previousStaggerPosition * STAGGER_OFFSET_Y,
                };

                staggerMarkerPaths.push({ start, end, text });
            }
        }
    });

    return staggerMarkerPaths;
};

export const createStaggerLinePaths = (
    context: IDropContext,
    legProps: IConnectorLegProps[],
    legLengthTolerance?: ITolerance,
    meshLengthTolerance?: ITolerance
): IPath[] => {
    const { connectorType, stagger, customBLength } = context;
    if (stagger.value === 0 && !customBLength) return [];

    const { height } = getConnectorTexture(connectorType);

    return getStaggerLines(height, context, legProps, legLengthTolerance, meshLengthTolerance);
};

export const getStaggerLines = (
    height: number,
    context: IDropContext,
    legProps: IConnectorLegProps[],
    legLengthTolerance?: ITolerance,
    meshLengthTolerance?: ITolerance
) => {
    if (legProps.length === 0) return [];

    const { primaryUnit, unit, mesh, meshOffset, groups, containerName } = context;

    const legs = [...legProps].filter(
        (m, i, self) => self.findIndex((s) => s.staggerPosition === m.staggerPosition) === i
    );
    const x1 = Math.min(...legs.map((m) => m.location.x)) - (DIMENSION_LINE_OFFSET + DIMENSION_LINE_START_OFFSET);
    const staggerLinePaths: IPath[] = [];

    const maxBLength = Math.max(...groups.map((g) => g.lengthB?.value ?? 0));
    groups.forEach((group, i) => {
        if (group.lengthB) {
            const groupPosition = group.position ?? 0;
            const groupLegs = legProps.filter((lp) => lp.groupIndex === groupPosition);
            const leg = groupLegs[0];

            const staggerOffset = STAGGER_OFFSET_Y * leg.staggerPosition;
            const y1 = leg.location.y + staggerOffset + height;
            if (!staggerLinePaths.some((p) => p.start.y === y1)) {
                const x2 = leg.location.x;

                const start: IPoint = { x: x1, y: y1 };
                const end: IPoint = { x: x2, y: y1 };

                const lengthB = group.lengthB;
                const lengthBValue = convertToDisplay(lengthB, unit);
                const text: ITextPath | undefined =
                    leg.staggerPosition !== 0
                        ? {
                              name: `${containerName}-${LegLengthMarkerContainer}-${GroupContainer}-${groupPosition}`,
                              primary:
                                  groupPosition === 0 && lengthB.value === maxBLength
                                      ? `B${context.index}: ${lengthBValue}`
                                      : lengthBValue,
                              secondary: getSecondaryText(primaryUnit, unit, lengthBValue),
                              position: "start",
                              inputProps: {
                                  value: lengthBValue,
                                  index: i,
                              },
                              tolerance: getToleranceText(
                                  DEFAULT_TOLERANCE,
                                  legLengthTolerance ?? DEFAULT_TOLERANCE,
                                  unit
                              ),
                          }
                        : undefined;

                staggerLinePaths.push({
                    start,
                    end,
                    text,
                    dashed:
                        i === 0
                            ? false
                            : group.lengthB?.value! <=
                              Math.max(...groups.slice(0, i).map((x) => x.lengthB?.value ?? 0)),
                });
            }

            if (mesh && meshOffset) {
                const groupY = leg.legEndpoint.y + staggerOffset + TIED_FURCATION_CURVE_END + TRUNK_WIDTH * 0.5;
                if (!staggerLinePaths.some((p) => p.start.y === groupY)) {
                    const groupX2 =
                        (groupLegs[0].legEndpoint.x + groupLegs.slice(-1)[0].legEndpoint.x) * 0.5 -
                        (TRUNK_WIDTH + DIMENSION_LINE_START_OFFSET) * 0.5;
                    const meshOffsetLength = { ...group.lengthB, value: group.lengthB.value - meshOffset.value };
                    const lengthValue = convertToDisplay(meshOffsetLength, unit);
                    const groupText: ITextPath | undefined =
                        leg.staggerPosition !== 0
                            ? {
                                  primary: lengthValue,
                                  secondary: getSecondaryText(primaryUnit, unit, lengthValue),
                                  position: "start",
                                  tolerance: getToleranceText(
                                      DEFAULT_TOLERANCE,
                                      meshLengthTolerance ?? DEFAULT_TOLERANCE,
                                      unit
                                  ),
                              }
                            : undefined;
                    staggerLinePaths.push({
                        start: { x: x1, y: groupY },
                        end: { x: groupX2, y: groupY },
                        text: groupText,
                        dashed: group.position !== 0,
                    });
                }
            }
        }
    });

    return staggerLinePaths;
};

export const getLegs = (context: IDropContext): IConnectorLegProps[] => {
    const { connectors, connectorType, side, groups } = context;

    const connectorAnchorPoint = context.connectorAnchorPoint ?? IPointEmpty;
    const furcationPoint = context.furcationPoint ?? IPointEmpty;

    const connectorStep = getConnectorStep(connectorType);
    const start = connectors.length * connectorStep * 0.5 - connectorStep / 2;
    const uniqueBLengths: number[] = [];
    for (const group of groups) {
        if (group.lengthB && !uniqueBLengths.includes(group.lengthB.value)) {
            uniqueBLengths.push(group.lengthB.value);
        }
    }
    const sortedBLengths = uniqueBLengths.sort((a, b) => a - b);
    return connectors
        .map((c, i) => {
            const positionOffset = i * connectorStep;
            const groupOffset = c.groupIndex * GROUP_STEP;

            const location: IPoint = {
                x: connectorAnchorPoint.x - start + positionOffset + groupOffset,
                y: connectorAnchorPoint.y,
            };

            const legEndpoint: IPoint = {
                x: furcationPoint.x - start + positionOffset + groupOffset,
                y: furcationPoint.y,
            };
            const groupLengthB = groups[c.groupIndex].lengthB;
            const staggerPosition = sortedBLengths.findIndex((l) => l === groupLengthB?.value);

            return {
                staggerPosition,
                location: getSpriteLocation(connectorType, location),
                legEndpoint,
                text: (c.connIndex + 1).toString(),
                connectorPosition: c.connIndex,
                connectorColor: c.color,
                labelColor: c.labelColor,
                groupIndex: c.groupIndex,
                side,
                connectorType: c.type,
                connectorId: c.id,
            };
        })
        .sort((a, b) => (a.staggerPosition >= b.staggerPosition ? 1 : -1));
};

export const getBounds = (x: number, y: number, width: number, height: number): Rectangle => {
    return { x, y, width, height };
};

export const currentShellTypeSelector = (state: WorkspaceState) => state.builds.currentBuild?.drops[1].shell ?? "soft";
export const currentAssemblyTypeSelector = (state: WorkspaceState) => state.builds.currentBuild?.assemblyType ?? "mesh";
export const currentMainMeshColorSelector = (state: WorkspaceState) =>
    state.builds.currentBuild?.mainMeshColor ?? getDefaultMeshColor(state.builds.currentBuild?.fiberType ?? "").name;
export const currentCollapseOptionsSelector = createSelector(
    selectedDropSelector,
    dropsSelector,
    (selectedDrop, drops) => {
        let collapseOptions: ICollapseOptions;
        if (selectedDrop) {
            collapseOptions = {
                groups: selectedDrop.groupsCollapsed,
                connectors: selectedDrop.connectorsCollapsed,
            };
        } else {
            collapseOptions = {
                groups: drops.some((d) => d.groupsCollapsed),
                connectors: drops.some((d) => d.connectorsCollapsed),
            };
        }
        return collapseOptions;
    }
);

export const allGroupsCollapsedSelector = (state: WorkspaceState) =>
    !!state.builds.currentBuild?.drops.every((d) => d.groupsCollapsed);
export const allConnectorsCollapsedSelector = (state: WorkspaceState) =>
    !!state.builds.currentBuild?.drops.every((d) => d.connectorsCollapsed);
export const currentMeasurementsPositionSelector = (state: WorkspaceState) =>
    state.builds.currentBuild?.measurementsPosition ?? "interior";
export const currentPullingGripSelector = (state: WorkspaceState) => state.builds.currentBuild?.pullingGrip ?? "none";
export const currentMaskLengthsSelector = (state: WorkspaceState) => !!state.builds.currentBuild?.maskLengths;

export const currentPrintSettingsSelector = createSelector(currentBuildSelector, (currentBuild) => {
    let printSettings = initialPrintSettings;
    if (currentBuild) {
        printSettings = {
            overviewNotes: currentBuild.overviewNotes,
            productLabels: currentBuild.productLabels,
            location: currentBuild.location,
            drawnBy: currentBuild.drawnBy,
            revisionNumber: currentBuild.revisionNumber,
            approvalDate: currentBuild.approvalDate,
            inServiceDate: currentBuild.inServiceDate,
            footerNotes: currentBuild.footerNotes,
        };
    }
    return printSettings;
});

export const overallLengthMarkerTextSelector = createSelector(
    unitsOfMeasureContainerPrimaryUnitSelector,
    unitsOfMeasureContainerUnitSelector,
    overallLengthSelector,
    currentMaskLengthsSelector,
    (primaryUnit, unit, overallLength, maskLengths) =>
        getLengthMarkerTexts(primaryUnit, unit, overallLength.value, maskLengths)
);

const getLengthMarkerTexts = (
    primaryUnit: PrimaryUnit,
    unit: Unit,
    lengthValue: string,
    maskLengths: boolean,
    index?: number
): { primary: string; secondary?: string } =>
    maskLengths
        ? {
              primary: index ? `A${index}` : MASKED_VALUE,
              secondary: undefined,
          }
        : {
              primary: index ? `A${index}:${lengthValue}` : lengthValue,
              secondary: getSecondaryText(primaryUnit, unit, lengthValue),
          };

export const connectorAssignmentsSelector = (state: WorkspaceState) =>
    state.builds.currentBuild?.connectorAssignments ?? [];

export const sortedConnectorAssignmentsSelector = createSelector(
    connectorAssignmentsSelector,
    connectorAssignments => [...connectorAssignments].sort((a, b) => {
        const aFirstConnector = a.distributionConnectors.reduce((min, c) => c < min ? c : min);
        const bFirstConnector = b.distributionConnectors.reduce((min, c) => c < min ? c : min);
        return aFirstConnector - bFirstConnector;
    })
)

export const connectorAssignmentSelectorFactory = (side: string, connectorId: number) => {
    return createSelector(connectorAssignmentsSelector, (assignments) =>
        getConnectorAssignment(assignments, side, connectorId)
    );
};

const getConnectorAssignment = (assignments: IConnectorAssignment[], side: string, connectorId: number) => {
    let assignment: IConnectorAssignment | undefined = undefined;
    if (side === "feeder") {
        assignment = assignments.find((a) => a.feederConnectors.includes(connectorId));
    } else {
        assignment = assignments.find((a) => a.distributionConnectors.includes(connectorId));
    }
    return assignment;
};

export const connectorAssignmentRowSelectorFactory = (connector: IConnectorData) => {
    return createSelector(
        buildIdSelector,
        dropsSelector,
        connectorAssignmentsSelector,
        highlightsSelector,
        (buildId, drops, assignments): IConnectorAssignmentRow => {
            const hierarchy = getConnectorHierarchy(drops, connector);
            if (!hierarchy) return initialConnectorAssignmentRow;

            const { drop, group, connectorIndex } = hierarchy;
            let connectorType: string = "";
            let connectorFiberCount = 0;
            let connectorFiberCountInUse = 0;
            if (connector.type) {
                const { fiberCount } = getConnectorType(connector.type);
                connectorType = connector.type;
                connectorFiberCount = fiberCount;
                connectorFiberCountInUse = connector.fiberCountInUse ?? fiberCount;
            }
            const side = connector.side ?? drop.side;
            const connectorId = connector.id ?? -1;
            let unassignedFibers = connectorFiberCount !== connectorFiberCountInUse 
                ? connectorFiberCountInUse 
                : connectorFiberCount;
            let assignmentData: IConnectorAssignmentData | undefined = undefined;
            const assignment = getConnectorAssignment(assignments, side, connectorId);
            if (assignment) {
                const assignmentIndex = assignments.indexOf(assignment);
                const assignmentConnectors = getAssignmentConnectors(drops, assignment);
                assignmentData = {
                    ...assignmentConnectors,
                    assignmentIndex,
                    fiberMapId: assignment.fiberMapId,
                };
                unassignedFibers = 0;
            }
            return {
                buildId,
                side,
                dropPosition: connector.tapPosition ?? drop.position,
                groupPosition: connector.groupPosition ?? group.position ?? -1,
                connectorId,
                connectorIndex,
                connectorPosition: connector.position ?? -1,
                connectorType: connectorType,
                fiberCount: connectorFiberCount,
                fiberCountInUse: connectorFiberCountInUse,
                unassignedFibers,
                blockedFibers: 0,
                assignment: assignmentData,
            };
        }
    );
};

const getConnectorHierarchy = (drops: IDrop[], { id }: IConnectorData) => {
    for (const drop of drops) {
        let connectorIndex = 1;
        for (const group of drop.groups) {
            for (const connector of group.connectors) {
                if (connector.id === id) {
                    return { drop, group, connectorIndex };
                }
                connectorIndex++;
            }
        }
    }
    return undefined;
};

export const assignmentConnectorsSelectorFactory = (assignment: IConnectorAssignment) => {
    return createSelector(dropsSelector, (drops) => getAssignmentConnectors(drops, assignment));
};

export const getAssignmentConnectors = (drops: IDrop[], assignment: IConnectorAssignment) => {
    const buildConnectors = getBuildConnectors(drops);
    const feederConnectors = buildConnectors.feederConnectors.filter(
        (c) => c.id && assignment.feederConnectors.includes(c.id)
    );
    const distributionConnectors = buildConnectors.distributionConnectors.filter(
        (c) => c.id && assignment.distributionConnectors.includes(c.id)
    );
    return {
        feederConnectors,
        distributionConnectors,
    };
};

export const buildConnectorsSelector = createSelector(dropsSelector, (drops) => getBuildConnectors(drops));

export const getBuildConnectors = ([feeder, ...distribution]: IDrop[]) => {
    const feederConnectors = feeder.groups.flatMap((g) => g.connectors);
    const distributionConnectors = distribution.flatMap((d) => d.groups.flatMap((g) => g.connectors));
    return {
        feederConnectors,
        distributionConnectors,
    };
};

export const indexedBuildConnectorIdsSelector = createSelector(dropsSelector, (drops) =>
    getIndexedBuildConnectorIds(drops)
);

type ConnectorInfo = {
    tapPosition: number;
    connectorId: number;
};

export const getIndexedBuildConnectorIds = ([feeder, ...distribution]: IDrop[]) => {
    const feederConnectors = feeder.groups.flatMap((g) =>
        g.connectors
            .map((c) => ({ tapPosition: c.tapPosition, connectorId: c.id }))
            .filter((i): i is ConnectorInfo => i.tapPosition !== undefined && i.connectorId !== undefined)
            .map((info, index) => ({ ...info, index }))
    );
    const distributionConnectors = distribution.flatMap((d) =>
        d.groups.flatMap((g) =>
            g.connectors
                .map((c) => ({ tapPosition: c.tapPosition, connectorId: c.id }))
                .filter((i): i is ConnectorInfo => i.tapPosition !== undefined && i.connectorId !== undefined)
                .map((info, index) => ({ ...info, index }))
        )
    );
    return {
        feederConnectors,
        distributionConnectors,
    };
};

export const unassignedBuildConnectorsSelector = createSelector(
    buildConnectorsSelector,
    connectorAssignmentsSelector,
    (buildConnectors, connectorAssignments) => {
        const assignedFeederConnectorIds = connectorAssignments.flatMap((a) => a.feederConnectors);
        const assignedDistributionConnectorIds = connectorAssignments.flatMap((a) => a.distributionConnectors);
        const feederConnectors = buildConnectors.feederConnectors.filter(
            (c) => c.id && !assignedFeederConnectorIds.includes(c.id)
        );
        const distributionConnectors = buildConnectors.distributionConnectors.filter(
            (c) => c.id && !assignedDistributionConnectorIds.includes(c.id)
        );
        return {
            feederConnectors,
            distributionConnectors,
        };
    }
);

export const nbAssignedFibersSelector = createSelector(
    dropsSelector,
    connectorAssignmentsSelector,
    (drops, connectorAssignments) => {
        let nbAssignedFibers = 0;
        if (drops.length > 1) {
            const connectorType = getConnectorTypeFromDrop(drops[0]);
            const nbAssignedConnectors = connectorAssignments.flatMap((a) => a.feederConnectors).length;
            const fiberCount = connectorType.fiberCountInUse ?? connectorType.fiberCount;
            nbAssignedFibers = nbAssignedConnectors * fiberCount;
        }
        return nbAssignedFibers;
    }
);

export const defaultConnectorAssignmentData = createSelector(
    connectorAssignmentsSelector,
    dropsSelector,
    (connectorAssignments, drops): IConnectorAssignmentData | undefined => {
        if (connectorAssignments.length === 0) return undefined;
        const assignment = connectorAssignments[0];
        const connectors = getAssignmentConnectors(drops, assignment);
        return {
            ...connectors,
            assignmentIndex: 0,
            fiberMapId: assignment.fiberMapId,
        };
    }
);

export const buildPolarityDescriptionFactory = (domainKeys?: number[]) => {
    return createSelector(sortedConnectorAssignmentsSelector, fiberMapsSelector, (connectorAssignments, fiberMaps) =>
        getPolarityDescriptionFromDomainKeys(connectorAssignments, fiberMaps, domainKeys)
    );
};

export const currentBuildPolarityDescriptionSelector = createSelector(
    buildIdSelector,
    buildsSelector,
    sortedConnectorAssignmentsSelector,
    fiberMapsSelector,
    (buildId, buildInfos, connectorAssignments, fiberMaps) => {
        let polarityDescription = "";
        const buildInfo = buildInfos.find((i) => i.buildId === buildId);
        if (buildInfo) {
            polarityDescription = getPolarityDescriptionFromDomainKeys(
                connectorAssignments,
                fiberMaps,
                buildInfo.fiberMapKeys
            );
        }
        return polarityDescription;
    }
);

export const getPolarityDescriptionFromDomainKeys = (
    connectorAssignments: IConnectorAssignment[],
    fiberMaps: IFiberMapData[],
    domainKeys?: number[]
) => {
    let fiberMapNames: string[] = [];
    const fiberMapKeys =
        domainKeys ??
        connectorAssignments.map((a) => a.fiberMapId).filter((id, i, self) => self.indexOf(id) === i) ??
        [];
    for (const key of fiberMapKeys) {
        const { customKey, demoKey } = getDisplayedFiberMap(key, fiberMaps);
        const fiberMapName = customKey ?? demoKey;
        if (fiberMapName) {
            fiberMapNames.push(fiberMapName);
        }
    }
    return fiberMapNames.join(", ");
};

export const assignedPolaritySelector = createSelector(
    dropsSelector,
    connectorAssignmentsSelector,
    fiberMapsSelector,
    (drops, connectorAssignments, fiberMaps) => {
        const polarity: IPolarityData[] = connectorAssignments.map((a) => {
            const { feederConnectors, distributionConnectors } = getAssignmentConnectors(drops, a);
            let name = "";
            let feederIndices: IFiberMapIndex[] = [];
            let distributionIndices: IFiberMapIndex[] = [];
            const domainFiberMap = fiberMaps.find((m) => m.key === a.fiberMapId);
            if (domainFiberMap) {
                name = domainFiberMap.name;
                feederIndices = domainFiberMap.sourceIndices;
                distributionIndices = domainFiberMap.destinationIndices;
                const displayedFiberMap = PolarityMapList.find((m) => m.demoKey === domainFiberMap.name);
                if (displayedFiberMap && displayedFiberMap.description !== undefined) {
                    name = displayedFiberMap.description;
                }
            }
            const buildConnectors = getBuildConnectors(drops);
            return {
                name,
                feeder: {
                    connectorIndices: feederConnectors.map((c) => buildConnectors.feederConnectors.indexOf(c) + 1),
                    connectors: feederConnectors,
                    pins: feederIndices,
                },
                distribution: {
                    connectorIndices: distributionConnectors.map(
                        (c) =>
                            buildConnectors.distributionConnectors
                                .filter((bc) => bc.tapPosition === c.tapPosition)
                                .indexOf(c) + 1
                    ),
                    connectors: distributionConnectors,
                    pins: distributionIndices,
                },
            };
        });
        return polarity;
    }
);

export const buildConfigSelector = createSelector(buildIdSelector, buildsConfigsSelector, (buildId, buildConfigs) => {
    let buildConfig;
    if (buildId) {
        buildConfig = buildConfigs.find((c) => c.buildId === buildId);
    }
    return buildConfig;
});

const AppRoles = {
    appAccess: 'rab:app_access',
    editBoundaries: 'rab:edit_boundaries',
} as const;

export function canAccessApplication(roles?: string[]) {
    return !!roles?.some((r) => r === AppRoles.appAccess);
}

export function canEditBoundaries(roles?: string[]) {
    return !!roles?.some((r) => r === AppRoles.editBoundaries);
}
import { WorkspaceState } from "./workspace.reducers";
import { createSelector } from "reselect";

export const buildStateSelector = (state: WorkspaceState) => state.builds;
export const buildsSelector = (state: WorkspaceState) => state.builds.builds;
export const projectManagerDataSelector = (state: WorkspaceState) => state.projectManager.data;

export const currentBuildSelector = createSelector(
    buildStateSelector,
    ({ currentBuild }) => currentBuild && {...currentBuild, catalogCode: currentBuild.catalogCode ?? ''}
)

import React from "react";
import { IDialogHeaderProps } from "../../../../models/ui/dialog/dialog-header";
import { useDialogHeader } from "./dialog-header.hooks";
import { IconButton, Typography } from "@corning-ctcm/silica-react";
import { Close, ExpandMore, ExpandLess } from "@mui/icons-material";

import "../../../../css/ui/dialog/dialog-header.scss";

export const DialogHeader: React.FC<IDialogHeaderProps> = (props) => {
    const { id, icon, title, collapsible, collapsed, closable, collapseProps, closeProps } =
        useDialogHeader(props);
    return (
        <div id={`${id}-header`} className="dialog-header">
            <div className="dialog-header-title-container">
                {icon ? (
                    <div id={`${id}-header-icon`} className="dialog-header-icon">
                        {icon}
                    </div>
                ) : null}
                <Typography id={`${id}-header-title`} className="dialog-header-title" variant="h6">
                    {title}
                </Typography>
            </div>
            <div className="dialog-header-button-container">
                {collapsible ? (
                    <IconButton {...collapseProps} icon={collapsed ? <ExpandMore /> : <ExpandLess />}/>
                ) : null}
                {closable ? (
                    <IconButton {...closeProps} icon={<Close />}/>
                ) : null}
            </div>
        </div>
    );
};

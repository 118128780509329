import { useTieCablesButton } from "./tie-cables-button.hooks";
import { ReactComponent as IconTieCablesDisabled } from "../../../../../../resources/svgs/overlay/footer/icon_tie_cables_disabled.svg";
import { ReactComponent as IconTieCables } from "../../../../../../resources/svgs/overlay/footer/icon_tie_cables.svg";
import { ReactComponent as IconUntieCables } from "../../../../../../resources/svgs/overlay/footer/icon_untie_cables.svg";
import { ReactComponent as IconUntieCablesDisabled } from "../../../../../../resources/svgs/overlay/footer/icon_untie_cables_disabled.svg";
import { IconButton } from "@corning-ctcm/silica-react";

export const TieCablesButton = () => {
    const { showUntie, disabled, buttonProps } = useTieCablesButton();
    return <IconButton { ...buttonProps } icon={getIcon(showUntie, disabled)} />;
};

const getIcon = (showUntie?: boolean, disabled?: boolean) => {
    if (showUntie) {
        return disabled ? <IconUntieCablesDisabled /> : <IconUntieCables />;
    }

    return disabled ? <IconTieCablesDisabled /> : <IconTieCables />;
};

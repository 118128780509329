import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../locales/keys";
import { IConnectorGroupData, getNbConnectors } from "../../../store/workspace/build/connector/connector";
import { unitsOfMeasureContainerUnitSelector } from "../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import {
    IUnitOfMeasure,
    convertToDisplay,
    convertTo,
    getUnitsName,
    Units,
} from "../../../models/overlay/header/units-of-measure";
import { getConnectorType } from "../../../store/overlay/wizard/wizard";
import { connectorAssignmentsSelector, currentMaskLengthsSelector, currentPullingGripSelector, dropsSelector, getAssignmentConnectors } from "../../../store/workspace/build.selectors";
import { IDrop, initialMeshOffset } from "../../../store/workspace/build/drop";
import { meshColorSelector } from "../../../store/workspace/boundaries/boundaries.selectors";
import { currentStatusSelector } from "../../../store/overlay/header/status-icon/status-icon.selectors";
import { WorkspaceStatus } from "../../../store/overlay/header/status-icon/status-icon";

export const useBuildPlanTable = () => {
    const status = useSelector(currentStatusSelector);
    const connectorAssignments = useSelector(connectorAssignmentsSelector);
    const drops = useSelector(dropsSelector);
    const { name: defaultMeshColor } = useSelector(meshColorSelector);
    const pullingGrip = useSelector(currentPullingGripSelector);
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);
    const maskLengths = useSelector(currentMaskLengthsSelector);
    const unitsName = getUnitsName(unit, false, true);

    const { t } = useTranslation();

    const bundleRows = useMemo(() => {
        if (status !== WorkspaceStatus.Reporting) return [];

        const pce = t(LocalizationKeys.PCE);
        const rows: { [key: string]: { description: string; quantity: number; uom: string } } = {};
        for (const connectorAssignment of connectorAssignments) {
            const {
                feederConnectors,
                distributionConnectors
            } = getAssignmentConnectors(drops, connectorAssignment);
            const feederConnector = feederConnectors[0];
            const connectorType = feederConnectors[0].type;
            const distributionConnector = distributionConnectors[0];
            const dropPosition = distributionConnector.tapPosition !== undefined ? distributionConnector.tapPosition + 1 : 0;
            const length =
                getCurrentBLength(feederConnector.groupPosition ?? 0, drops[0]);
                getDestinationDropLength(drops, dropPosition, distributionConnector.groupPosition ?? 0);
            const value = convertToDisplay({ unit: Units.UoMInches, value: length }, unit);
            if (rows[value]) {
                rows[value].quantity = rows[value].quantity + 1;
            } else {
                rows[value] = {
                    description: `${value} ${unitsName} ${connectorType} ${t(LocalizationKeys.Bundle).toLowerCase()}`,
                    quantity: 1,
                    uom: pce,
                };
            }
        }

        return Object.values(rows).map((data) => ({ className: "", data }));
    }, [connectorAssignments, drops, t, unit, unitsName, status]);

    const meshRows = useMemo(() => {
        const pce = t(LocalizationKeys.PCE);
        const filteredDrops = drops.filter((d) => d.mesh);
        const rows: { [key: string]: { description: string; quantity: number; uom: string } } = {};
        for (const drop of filteredDrops) {
            for (const group of drop.groups) {
                const groupIndex = group.position ?? 0;
                const meshOffset = drop.meshOffset ?? initialMeshOffset;
                const length = getCurrentBLength(groupIndex, drop) - meshOffset.value;
                const value = convertToDisplay({ unit: Units.UoMInches, value: length }, unit);
                const color = (drop.meshColor ?? defaultMeshColor).toLowerCase();

                if (rows[value]) {
                    rows[value].quantity = rows[value].quantity + 1;
                } else {
                    rows[value] = {
                        description: `${value} ${unitsName} ${color} ${t(LocalizationKeys.Mesh).toLowerCase()}`,
                        quantity: 1,
                        uom: pce,
                    };
                }
            }
        }

        return Object.values(rows).map((data) => ({ className: "", data }));
    }, [drops, defaultMeshColor, t, unit, unitsName]);

    const feederRows = useMemo(() => {
        const source = drops[0];
        const connectorType = getConnectorType(source.groups[0].type!).description;
        const connectorTotal = source.groups.map((g) => g.connectors).flat().length;
        const pce = t(LocalizationKeys.PCE);

        const titleRow = {
            className: "subsection",
            data: { description: `${t(LocalizationKeys.Feeder) + t(LocalizationKeys.Colon)}`, quantity: "", uom: "" },
        };
        const connectorRow = {
            className: "",
            data: { description: `${connectorType}`, quantity: `${connectorTotal}`, uom: pce },
        };

        const groupRows = source.groups
            .map((g, i) => {
                const value = getCurrentBLength(i, source);
                const lengthString = `${convertToDisplay({ value, unit: Units.UoMInches }, unit)} ${unitsName}`;
                return {
                    className: "",
                    data: {
                        description: `${lengthString + " " + t(LocalizationKeys.SingleModeDrops)}`,
                        quantity: `${g.connectors.length}`,
                        uom: pce,
                    },
                };
            })
            .sort((a, b) => (a.data.description > b.data.description ? 1 : -1));

        const feederRows = [titleRow, connectorRow, ...groupRows];

        if (pullingGrip === "feeder" || pullingGrip === "both") {
            const gripRow = {
                className: "",
                data: {
                    description: t(LocalizationKeys.PullingGrip),
                    quantity: "1",
                    uom: t(LocalizationKeys.PCE),
                },
            };
            feederRows.push(gripRow);
        }

        return feederRows;
    }, [drops, t, unit, unitsName, pullingGrip]);

    const tapRows = useMemo(() => {
        const spilloutRows = [];
        const pce = t(LocalizationKeys.PCE);

        const destinations = drops.slice(1);
        const titleRow = {
            className: "subsection",
            data: {
                description: `${t(LocalizationKeys.Taps) + t(LocalizationKeys.Colon)}`,
                quantity: destinations.length,
                uom: "",
            },
        };

        let aLengthOffset = 0;
        for (const { position, lengthA } of destinations) {
            const lengthValue = lengthA.value + aLengthOffset;
            const length = convertTo({ value: lengthValue, unit: Units.UoMInches }, unit);
            spilloutRows.push({
                className: "",
                data: {
                    description: t(LocalizationKeys.BuildPlanFurcationSpillout, { position: position + 1 }),
                    quantity: maskLengths ? "": convertToDisplay(length, unit),
                    uom: getUnitsName(unit, true),
                },
            });

            aLengthOffset += lengthA.value;
        }

        const connectorGroupDictionary: {
            [index: string]: { group: IConnectorGroupData; length: IUnitOfMeasure }[];
        } = {};
        for (const drop of destinations) {
            for (const group of drop.groups) {
                if (group.connectors.length > 0) {
                    const connectorType = group.connectors[0].type!;
                    const groupIndex = group.position ?? 0;
                    const lengthValue = getCurrentBLength(groupIndex, drop);
                    const length: IUnitOfMeasure = {
                        value: Number(convertToDisplay({ value: lengthValue, unit: Units.UoMInches }, unit)),
                        unit,
                    };
                    if (!connectorGroupDictionary[connectorType]) {
                        connectorGroupDictionary[connectorType] = [{ group, length }];
                    } else {
                        connectorGroupDictionary[connectorType].push({ group, length });
                    }
                }
            }
        }

        let lengthRowPair: {
            [k: number]: { className: string; data: { description: string; quantity: number; uom: string } };
        } = {};
        const dropSpillOutRows = [];
        const connectorTypes = Object.keys(connectorGroupDictionary);
        for (const connectorType of connectorTypes) {
            const connectorGroups = connectorGroupDictionary[connectorType];
            lengthRowPair = {};
            for (const { group, length } of connectorGroups) {
                if (!lengthRowPair[length.value]) {
                    const lengthString = `${convertToDisplay(length, unit)} ${unitsName}`;
                    lengthRowPair[length.value] = {
                        className: "",
                        data: {
                            description: `${lengthString + " " + t(LocalizationKeys.SingleModeDrops)}`,
                            quantity: group.connectors.length,
                            uom: pce,
                        },
                    };
                } else {
                    lengthRowPair[length.value].data.quantity += group.connectors.length;
                }
            }

            const connectorTitleRow = {
                className: "",
                data: {
                    description: getConnectorType(connectorType).description!,
                    quantity: getNbConnectors(connectorGroups.map((cg) => cg.group)),
                    uom: pce,
                },
            };
            const lengthRows = Object.values(lengthRowPair);
            const spillOutRows = [connectorTitleRow, ...lengthRows].sort((a, b) =>
                a.data.description > b.data.description ? 1 : -1
            );
            dropSpillOutRows.push(...spillOutRows);
        }

        if (pullingGrip === "distribution" || pullingGrip === "both") {
            const gripRow = {
                className: "",
                data: {
                    description: t(LocalizationKeys.PullingGrip),
                    quantity: 1,
                    uom: t(LocalizationKeys.PCE),
                },
            };
            dropSpillOutRows.push(gripRow);
        }

        return [titleRow, ...spilloutRows, ...dropSpillOutRows];
    }, [drops, t, unit, unitsName, pullingGrip, maskLengths]);

    const buildPlanTables = useMemo(() => {
        const tableRows = [...meshRows, ...bundleRows, ...feederRows, ...tapRows];
        const page1rowLimit = 18;
        if (tableRows.length > page1rowLimit) {
            let table1 = tableRows.slice(0, page1rowLimit);
            let table2 = tableRows.slice(page1rowLimit);
            if (table1.length > 0 && table1[table1.length - 1].className === "subsection") {
                const lastRow = table1.pop();
                table2 = [lastRow!, ...table2];
            }
            return [table1, table2];
        } else {
            return [tableRows, []];
        }
    }, [meshRows, bundleRows, feederRows, tapRows]);

    return { buildPlanTables };
};

const getDestinationDropLength = (currentDrops: IDrop[], dropPosition: number, groupPosition: number) => {
    const drop = currentDrops[dropPosition];
    const lengthA = currentDrops
        .slice(1, dropPosition + 1)
        .map((d) => d.lengthA.value)
        .reduce((a, b) => a + b);
    const lengthB = getCurrentBLength(groupPosition, drop);
    return lengthB + lengthA;
};

const getCurrentBLength = (groupIndex: number, drop: IDrop) => {
    const group = drop.groups[groupIndex];

    if (drop.customBLength && group.lengthB) return group.lengthB.value;

    let defaultLengthB = drop.lengthB.value;
    if (group.stagger) {
        const groupIndex = group.position ?? 0;
        const stagger = groupIndex * group.stagger.value;
        defaultLengthB = drop.reverseStaggering ? defaultLengthB - stagger : defaultLengthB + stagger;
    }

    return defaultLengthB;
};

import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { hybrisCustomerSelector } from "../../../../../store/authentication/authentication.selectors";
import { IPolarityAssignmentRowProps } from "../../../../../models/overlay/polarity/polarity-assignment-row";
import { IconButtonProps } from "@corning-ctcm/silica-react";

export const usePolarityAssignmentRow = (props: IPolarityAssignmentRowProps) => {
    const { data, onRowClick, onEditPolarity, onDeletePolarity } = props;
    const hybrisCustomer = useSelector(hybrisCustomerSelector);

    const tableClassName = useMemo(() => {
        if (data) {
            if (data.selected && data.editable) {
                return "selected editable";
            } else if (data.selected && !data.editable) {
                return "selected";
            } else if (!data.selected && data.editable) {
                return "editable";
            } else {
                return "";
            }
        }
    }, [data]);

    const editButtonClassName = classNames("edit-button", { "hybris-button": !!hybrisCustomer });

    const deleteButtonClassName = useMemo(() => {
        if (data) {
            if (data.canDelete) {
                return classNames("delete-button", { "hybris-button": !!hybrisCustomer });
            } else {
                return classNames("delete-button", "disabled", { "hybris-button": !!hybrisCustomer });
            }
        }
    }, [data, hybrisCustomer]);

    const onEdit = useCallback(() => {
        if (data) {
            onEditPolarity(data.id!);
        }
    }, [data, onEditPolarity]);

    const onDelete = useCallback(() => {
        if (data) {
            onDeletePolarity(data.id!);
        }
    }, [data, onDeletePolarity]);

    const onButtonCellClick = useCallback((e: any) => {
        e.stopPropagation();
    }, []);

    const editProps: Omit<IconButtonProps, "icon"> = {
        className: "edit-icon",
        palette: "primary",
        mode: "main",
        placement: "bottom",
        onClick: onEdit,
    };

    const deleteProps: Omit<IconButtonProps, "icon"> = {
        className: "delete-icon",
        palette: "primary",
        mode: "main",
        placement: "bottom",
        disabled: !!!data?.canDelete,
        onClick: onDelete,
    };

    const id = `${data?.description?.replaceAll(" ", "-").toLowerCase()}-scheme`;
    return {
        id,
        data,
        tableClassName,
        editButtonClassName,
        deleteButtonClassName,
        editProps,
        deleteProps,
        onRowClick,
        onButtonCellClick,
    };
};

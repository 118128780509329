import React from "react";
import { useViewProductDialog } from "./view-product.hooks";
import { GenericDialog } from "../../../ui/dialog/generic-dialog";

export const ViewProductDialog = () => {
    const { showViewProduct, dialogHeaderProps, message, continueProps, closeDialog } = useViewProductDialog();

    return (
        <GenericDialog
            id="view-product-dialog"
            display={showViewProduct}
            title={dialogHeaderProps.title}
            closable={dialogHeaderProps.closable}
            message={message}
            onClose={closeDialog}
            onConfirm={continueProps.onClick}
            confirmText={continueProps.label}
        />
    );
};

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../locales/keys";
import { extractOwnerFromEmail } from "../../../../../store/workspace/build/build";
import { buildInfoSelectorFactory } from "../../../../../store/workspace/build.selectors";
import { useMemo } from "react";

export const useCreationInfo = (projectId: number) => {
    const buildInfoSelector = useMemo(() => buildInfoSelectorFactory(projectId), [projectId]);
    const build = useSelector(buildInfoSelector);
    const { t } = useTranslation();
    const date =
        build && build.lastModified ? new Date(build.lastModified).toLocaleDateString() : t(LocalizationKeys.Unknown);
    const owner = build && build.ownerEmail ? extractOwnerFromEmail(build.ownerEmail) : t(LocalizationKeys.Unknown);
    const creationInfoString = `${owner}, ${date}`;
    return { creationInfoString };
};

import { Checkbox, Select, SelectItem as Option } from "@corning-ctcm/silica-react";
import { ICheckboxInput } from "../../../../../models/ui/checkbox";
import { ISelect } from "../../../../ui/select/select.hooks";

interface IDrawingSectionProps {
    checkbox: ICheckboxInput;
    select: ISelect;
    disabled?: boolean;
}

export const DrawingSection = (props: IDrawingSectionProps) => {
    const { checkbox, select, disabled } = props;
    return (
        <div className="report-section">
            <div className={checkbox.className}>
                <Checkbox
                    palette="primary"
                    label={checkbox.label}
                    onChange={checkbox.onCheckBoxChange}
                    checked={checkbox.checked}
                    disabled={disabled}
                />
            </div>
            <div className="horizontal-field-container">
                <div className="section-options">
                    <Select {...select.props}>
                        {select.options.map((o) => (
                            <Option key={o.key} value={o.value}>
                                {o.name}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
        </div>
    );
};

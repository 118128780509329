import { useDropCheckbox } from "./drop-checkbox.hooks";
import { Checkbox } from "@corning-ctcm/silica-react";

export const DropCheckbox = () => {
    const { checkboxProps } = useDropCheckbox();

    return (
        <div className={"ap-checkbox-container"}>
            <Checkbox {...checkboxProps} />
        </div>
    );
};

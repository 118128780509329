import { createSelector } from "reselect";
import { WorkspaceState } from "../../../workspace/workspace.reducers";
import { disabledUISelector } from "../../header/status-icon/status-icon.selectors";
import { isPolarityModeSelector } from "../../overlay.selectors";

export const toolbarSelector = (state: WorkspaceState) => state.toolbar;
export const editToolbarDisplaySelector = (state: WorkspaceState) => state.toolbar.display;
export const toolbarSelectionSelector = (state: WorkspaceState) => state.toolbar.selection;
export const selectedPositionSelector = (state: WorkspaceState) => state.toolbar.selection.selected;
export const selectedSideSelector = (state: WorkspaceState) => state.toolbar.selection.side;

export const showActionToolbarSelector = createSelector(
    editToolbarDisplaySelector,
    disabledUISelector,
    isPolarityModeSelector,
    (editToolbarDisplay, disabledUI, isPolarityMode) => !editToolbarDisplay && !disabledUI && !isPolarityMode
);

import { Checkbox, TextField } from "@corning-ctcm/silica-react";
import { LabelFieldsProps } from "../../../../../../models/overlay/reports/report-settings/labels-section";
import { useLabelFields } from "./label-fields.hooks";
import { Typography } from "@corning-ctcm/silica-react";

import "../../../../../../css/overlay/reports/report-settings/label-fields.scss";

export const LabelFields = ({ index, label, labelsLength, setLabel }: LabelFieldsProps) => {
    const { labelTitle, typeField, descriptionField, sizeCheckbox, sizeField, noteField } =
        useLabelFields(index, label, labelsLength, setLabel);
    return (
        <div className="fields-section">
            {labelTitle && (
                <div className="label-title">
                    <Typography>
                        {labelTitle}
                    </Typography>
                </div>
            )}
            <div className="field-container">
                <TextField
                    palette="primary"
                    className="field"
                    label={typeField.label}
                    helperText={typeField.helperText}
                    value={typeField.value}
                    maxLength={typeField.maxLength}
                    onChange={typeField.onChange}
                    disabled={typeField.disabled}
                />
            </div>
            <div className="field-container">
                <TextField
                    palette="primary"
                    className="field multiline"
                    label={descriptionField.label}
                    helperText={descriptionField.helperText}
                    value={descriptionField.value}
                    maxLength={descriptionField.maxLength}
                    onChange={descriptionField.onChange}
                    multiline
                    rows={3}
                    disabled={descriptionField.disabled}
                />
            </div>
            <div className="field-container">
                <Checkbox
                    palette="primary"
                    label={sizeCheckbox.label}
                    onChange={sizeCheckbox.onChange}
                    checked={sizeCheckbox.checked}
                    disabled={sizeCheckbox.disabled}
                />
                <TextField
                    palette="primary"
                    className="field"
                    label={sizeField.label}
                    helperText={sizeField.helperText}
                    value={sizeField.value}
                    maxLength={sizeField.maxLength}
                    onChange={sizeField.onChange}
                    disabled={sizeField.disabled}
                />
            </div>
            <div className="field-container">
                <TextField
                    palette="primary"
                    className="field multiline"
                    label={noteField.label}
                    helperText={noteField.helperText}
                    value={noteField.value}
                    maxLength={noteField.maxLength}
                    onChange={noteField.onChange}
                    multiline
                    rows={3}
                    disabled={noteField.disabled}
                />
            </div>
        </div>
    );
};

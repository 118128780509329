import { createContext } from "react";

import { IPoint } from "../../pixi";
import { ITextPath } from "../text";

export const DIMENSION_LINE_FILL_COLOR = 0x000000;
export const DIMENSION_LINE_WIDTH = 1;
export const DIMENSION_LINE_FONT_SIZE = 48;
export const DIMENSION_LINE_OFFSET = 50;
export const DIMENSION_LINE_START_OFFSET = 10;
export const DIMENSION_LINE_STAGGER_OFFSET = 36;
export const DIMENSION_LINE_OFFSET_FROM_NEXT_TAP = 200;
export const DIMENSION_LINE_TEXT_OFFSET = 10;
export const DIMENSION_LINE_DASH_SIZE = 10;
export const DIMENSION_LINE_LABEL_OFFSET = 130;
export const DIMENSION_LINE_DEFAULT_FONT_SCALE = 0.5;

export const REPORT_DIMENSION_LINE_FILL_COLOR = 0x000000;
export const REPORT_DIMENSION_LINE_WIDTH = 1.5;
export const REPORT_DIMENSION_LINE_FONT_SIZE = 32;

export interface IPath {
    start: IPoint;
    end: IPoint;
    text?: ITextPath;
    dashed?: boolean;
}

export interface IBezierPath extends IPath {
    control: IPoint;
}

export interface IVerticalDimensionLine {
    topExtensionLine?: IPath;
    dimensionLine?: IPath;
    bottomExtensionLine?: IPath;
    meshDimensionLine?: IPath;
    meshExtensionLine?: IPath;
}

export interface IHorizontalDimensionLine {
    leftExtensionLine?: IPath;
    dimensionLine?: IPath;
    rightExtensionLine?: IPath;
}

export interface IDimensionLines {
    lengthA?: IHorizontalDimensionLine;
    lengthB?: IVerticalDimensionLine;
    staggerLinePaths?: IPath[];
    staggerMarkerPaths?: IPath[];
    lengthLabel?: IVerticalDimensionLine;
}

export interface IDimensionLineContext {
    color: number;
    thickness: number;
    fontSize: number;
    displayA: boolean;
}

export const DimensionLineContext = createContext<IDimensionLineContext>({
    color: DIMENSION_LINE_FILL_COLOR,
    thickness: DIMENSION_LINE_WIDTH,
    fontSize: DIMENSION_LINE_FONT_SIZE,
    displayA: true,
});

export const overviewReportStyle: IDimensionLineContext = {
    color: REPORT_DIMENSION_LINE_FILL_COLOR,
    thickness: REPORT_DIMENSION_LINE_WIDTH,
    fontSize: REPORT_DIMENSION_LINE_FONT_SIZE + 14,
    displayA: true,
};

export const feederReportStyle: IDimensionLineContext = {
    color: REPORT_DIMENSION_LINE_FILL_COLOR,
    thickness: REPORT_DIMENSION_LINE_WIDTH,
    fontSize: REPORT_DIMENSION_LINE_FONT_SIZE + 8,
    displayA: false,
};

export const dropReportStyle: IDimensionLineContext = {
    color: REPORT_DIMENSION_LINE_FILL_COLOR,
    thickness: REPORT_DIMENSION_LINE_WIDTH + 0.5,
    fontSize: REPORT_DIMENSION_LINE_FONT_SIZE + 8,
    displayA: false,
};

export const defaultStyle: IDimensionLineContext = {
    color: DIMENSION_LINE_FILL_COLOR,
    thickness: DIMENSION_LINE_WIDTH,
    fontSize: DIMENSION_LINE_FONT_SIZE,
    displayA: true,
};

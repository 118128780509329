import { Header } from "./header/header";
import { UnitsOfMeasureContainer } from "./header/units-of-measure-container/units-of-measure-container";
import { Polarity } from "./polarity/polarity";
import { Wizard } from "./wizard/wizard";
import { ProjectDrawer } from "./project-drawer/project-drawer";
import { CableDetails } from "./project-drawer/cable-details/cable-details";
import { CableInfo } from "./project-drawer/cable-info/cable-info";
import { CablePresetSnackbar } from "./cable-preset/cable-preset-snackbar";
import { Footer } from "./footer/footer";
import { AboutThisApplication } from "./about-this-application/about-this-application";
import { Warnings } from "./header/warnings/warnings";
import { ViewProductDialog } from "./header/view-product/view-product-dialog";
import { MoreMenuContainer } from "./header/more-menu-container/more-menu-container";
import { TimeoutDialog } from "./timeout-dialog/timeout-dialog";
import { AnonymousUserWarningDialog } from "./header/view-product/anonymous-user-warning-dialog/anonymous-user-warning-dialog";
import { ActivityTracker } from "../tracker/activity-tracker";
import { IdleDialog } from "./idle-dialog/idle-dialog";
import { useWebWorkspace } from "./overlay.hooks";
import { Reports } from "./reports/reports";
import { ShellEdit } from "./header/shell-edit/shell-edit";
import { EditTolerancesDialog } from "./header/tolerances/edit-tolerances-dialog";
import { CollapseOptions } from "./header/collapse-options/collapse-options";
import { SettingsDialog } from "./header/settings-dialog/settings-dialog";
import { AssemblyTypeEdit } from "./header/assembly-type-edit/assembly-type-edit";
import { NotificationSnackbar } from "./notification/notification-snackbar";
import { BoundariesSettings } from "./boundaries/boundaries-settings";

const Overlay = () => {
    useWebWorkspace();
    return (
        <div id="overlay" className="overlay">
            <ActivityTracker />
            <Header />
            <UnitsOfMeasureContainer />
            <Warnings />
            <Reports />
            <AboutThisApplication />
            <AnonymousUserWarningDialog />
            <EditTolerancesDialog />
            <ViewProductDialog />
            <MoreMenuContainer />
            <IdleDialog />
            <TimeoutDialog />
            <Polarity />
            <Wizard />
            <ProjectDrawer />
            <CableDetails />
            <CableInfo />
            <CablePresetSnackbar />
            <AssemblyTypeEdit />
            <ShellEdit />
            <CollapseOptions />
            <SettingsDialog />
            <NotificationSnackbar />
            <BoundariesSettings />
            <Footer />
        </div>
    );
};

export default Overlay;

export interface ITimer {
    min: string;
    sec: string;
}

export const initialTimer: ITimer = { min: "05", sec: "00" };

export const FIVE_MINS = 300;
export const ONE_HOUR = 3600;
export const FOUR_HOURS = 4 * ONE_HOUR;
export const ONE_MIN = 60;
export const TEN_SECS = 10;
export const ONE_SEC = 1000;
export const MS_MULTIPLIER = 1000;
export const FIVE_SEC_MS = 5000;

export const convert = (count: number): ITimer => {
    const minsNbr = Math.floor((count % ONE_HOUR) / ONE_MIN);
    const secNbr = Math.floor((count % ONE_HOUR) % ONE_MIN);

    const min = minsNbr >= TEN_SECS ? `${minsNbr}` : `0${minsNbr}`;
    const sec = secNbr >= TEN_SECS ? `${secNbr}` : `0${secNbr}`;
    return { min, sec };
};

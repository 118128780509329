import React from "react";
import { useHeader } from "./header.hooks";
import { ProjectButton } from "./project-button/project-button";
import { Status } from "./status/status";
import { HeaderActions } from "./header-actions";

import "../../../css/overlay/header/header.scss";

export const Header: React.FC = () => {
    const { headerClassName } = useHeader();
    return (
        <div id="header" className={headerClassName}>
            <div id="header-start" className="header-start">
                <ProjectButton />
                <Status />
            </div>
            <div id="header-end" className="header-end">
                <HeaderActions />
            </div>
        </div>
    );
};

import React from "react";
import { useInputDialog } from "./input-dialog.hooks";
import { IInputDialogProps } from "../../../../../models/overlay/project-drawer/project-manager-row/input-dialog";
import { DialogHeader } from "../../../../ui/dialog/dialog-header/dialog-header";
import { ContainedButton, TextButton, TextField } from "@corning-ctcm/silica-react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import "../../../../../css/overlay/project-drawer/project-manager-row/input-dialog.scss";

export const InputDialog: React.FC<IInputDialogProps> = (props) => {
    const {
        id,
        open,
        inputDisabled,
        maxCharacterCount,
        inputValue,
        onInputChanged,
        cancelProps,
        onCancelClick,
        saveProps,
        onAcceptClick,
        headerProps,
        helperText,
        inputFieldLabel,
    } = useInputDialog(props);

    if (!open) {
        return null;
    }
    // We are currently using the native MUI Dialog due to a bug with TextFields in the Silica component.
    // This should be changed once the bug is fixed in the library.
    // Refer to PLAT-8 for more details.
    return (
        <Dialog
            id={id}
            className="input-dialog toggle-pointer-events"
            open={open}
            onClose={onCancelClick}
        >
            <DialogTitle>
                <DialogHeader {...headerProps} />
            </DialogTitle>
            <DialogContent>
                <div className="dialog-container">
                    <TextField
                        palette="primary"
                        id={`${id}-input-field`}
                        label={inputFieldLabel}
                        maxLength={maxCharacterCount}
                        value={inputValue}
                        helperText={inputDisabled ? helperText : " "}
                        error={inputDisabled}
                        onChange={(e: React.ChangeEvent<any>) => onInputChanged(e)}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <div className="dialog-button-container">
                    <TextButton id={`${id}-cancel-button`} className="cancel-button" onClick={onCancelClick}>
                        {cancelProps.label}
                    </TextButton>
                    <ContainedButton
                        id={`${id}-save-button`}
                        className="save-button"
                        disabled={inputDisabled}
                        onClick={onAcceptClick}
                    >
                        {saveProps.label}
                    </ContainedButton>
                </div>
            </DialogActions>
        </Dialog>
    );
};

import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../../locales/keys";
import { AppDispatch } from "../../../../../store/workspace/workspace.reducers";
import { useSscBuildSession } from "../../../../workspace/ssc.hooks";
import { buildInfoSelectorFactory, buildsPageIndexSelector } from "../../../../../store/workspace/build.selectors";
import { duplicateProject } from "../../../../../store/overlay/project/project.reducers";
import { setReloading } from "../../../../../store/overlay/project/project-drawer/project-drawer.reducers";
import { ProjectDrawerContext } from "../../../../../store/overlay/project/project-drawer/project-drawer";
import { IInputDialogProps } from "../../../../../models/overlay/project-drawer/project-manager-row/input-dialog";
import { IDuplicateDialogProps } from "../../../../../models/overlay/project-drawer/project-manager-row/duplicate-dialog";

export const useDuplicateDialog = (props: IDuplicateDialogProps) => {
    const { context, projectId } = props;
    const open = context.state.props.open;
    const buildInfoSelector = useMemo(() => buildInfoSelectorFactory(projectId), [projectId]);
    const build = useSelector(buildInfoSelector);
    const pageIndex = useSelector(buildsPageIndexSelector);
    const {
        state: { search },
        dispatch,
    } = useContext(ProjectDrawerContext);
    const { updateBuildSession } = useSscBuildSession();
    const buildName =
        build && build.name !== "" && build.name ? build.name + "-Copy" : `Cable #${build.buildId!}-Copy` ?? "";
    const { t } = useTranslation();
    const storeDispatch = useDispatch<AppDispatch>();

    const onSave = useCallback(
        async (name: string) => {
            dispatch(setReloading(true));
            await storeDispatch(duplicateProject(projectId, name, updateBuildSession, pageIndex, search));
            dispatch(setReloading(false));
        },
        [dispatch, storeDispatch, projectId, updateBuildSession, pageIndex, search]
    );

    const inputDialogProps: IInputDialogProps = {
        id: "duplicate-build",
        value: buildName,
        maxCharacterCount: 20,
        cancelProps: {
            label: t(LocalizationKeys.Cancel),
        },
        saveProps: {
            label: t(LocalizationKeys.Save),
            onClick: onSave,
        },
        title: t(LocalizationKeys.DuplicateCable),
        inputFieldLabel: t(LocalizationKeys.CableNewName),
    };

    return { open, dialogContext: context, inputDialogProps };
};

import { createSlice } from "@reduxjs/toolkit";
import {
    restoreBuildSessionAction,
    setBuildSessionAction,
    setConfigColorsAction,
    setConfigSessionBusyAction,
    setConfigStatusAction,
    setConfigurationSessionAction,
    setSessionBusyAction,
    setSessionErrorAction,
    setSessionWarningsAction,
    storeBuildSessionAction,
} from "./ssc.actions";
import { initialRABSessionState } from "./ssc";

const sscSclice = createSlice({
    initialState: initialRABSessionState,
    name: "sscSession",
    reducers: {
        setConfigurationSession: setConfigurationSessionAction,
        setSessionBusy: setSessionBusyAction,
        setConfigSessionBusy: setConfigSessionBusyAction,
        setConfigStatus: setConfigStatusAction,
        setConfigColors: setConfigColorsAction,
        setBuildSession: setBuildSessionAction,
        setSessionWarnings: setSessionWarningsAction,
        setSessionError: setSessionErrorAction,
        storeBuildSession: storeBuildSessionAction,
        restoreBuildSession: restoreBuildSessionAction,
    },
});

export const SSCSessionReducer = sscSclice.reducer;

export const {
    setConfigurationSession,
    setSessionBusy,
    setConfigSessionBusy,
    setConfigStatus,
    setConfigColors,
    setBuildSession,
    setSessionWarnings,
    setSessionError,
    storeBuildSession,
    restoreBuildSession,
} = sscSclice.actions;

import { IProjectManagerState } from "./project";
import { PayloadAction } from "@reduxjs/toolkit";
import { IBuildData } from "../../workspace/build/build";

export function setDrawerDisplayAction(state: IProjectManagerState, action: PayloadAction<boolean>) {
    state.display = action.payload;
}

export function setTotalBuildCountAction(state: IProjectManagerState, action: PayloadAction<number>) {
    state.data.totalBuildCount = action.payload;
}

export function showCableDetailsAction(state: IProjectManagerState, action: PayloadAction<boolean>) {
    state.showDetails = action.payload;
    state.display = !action.payload;
}

export function showCableInfoAction(state: IProjectManagerState, action: PayloadAction<boolean>) {
    state.showInfo = action.payload;
    state.display = !action.payload;
}

export function closeAllAction(state: IProjectManagerState) {
    state.display = false;
    state.showDetails = false;
    state.showInfo = false;
    state.selectedBuildId = -1;
}

export function setSelectedBuildAction(state: IProjectManagerState, action: PayloadAction<IBuildData>) {
    state.selectedBuild = action.payload;
}

export function setSelectedBuildIdAction(state: IProjectManagerState, action: PayloadAction<number>) {
    state.selectedBuildId = action.payload;
}

export function setSortTypeAction(state: IProjectManagerState, action: PayloadAction<string>) {
    state.data.sortType = action.payload;
}

export function setSortOrderAction(state: IProjectManagerState, action: PayloadAction<boolean>) {
    state.data.isSortAscending = action.payload;
}

import React from "react";
import ReactDOM from "react-dom";
import { IMeshProps } from "../../../../../models/overlay/wizard/setup/mesh-properties";
import { useMeshProperties } from "./mesh-properties.hooks";
import { Checkbox, IconButton, TextField } from "@corning-ctcm/silica-react";
import { Palette } from "@mui/icons-material";
import { ColorDialog } from "../../../../ui/dialog/color-dialog/color-dialog";

import "../../../../../css/overlay/wizard/setup/mesh-properties.scss";

export const MeshProperties: React.FC<IMeshProps> = (props) => {
    const { mesh, colorDialogProps } = useMeshProperties(props);
    return (
        <div className="mesh">
            <div className="field-container mesh-checkbox">
                <Checkbox {...mesh.checkbox}/>
            </div>
            {mesh.checkbox.checked && (
                <div className="field-container horizontal">
                    <div className="mesh-offset field">
                        <TextField {...mesh.offset}/>
                    </div>
                    <div className="mesh-color field">
                        <TextField
                            {...mesh.color.field}
                            InputProps={{
                                endAdornment: <IconButton {...mesh.color.icon} icon={<Palette />}/>,
                                readOnly: true,
                            }}
                        />
                    </div>
                </div>
            )}
            {ReactDOM.createPortal(<ColorDialog {...colorDialogProps} />, document.body)}
        </div>
    );
};

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { rabSessionService, RABSessionService } from "../../services/rab-session.service";
import { IUpdateDropMaterialRequest, buildDataToRABSessionRequest } from "../../models/services/rab-session";
import { IBuildData } from "../../store/workspace/build/build";
import {
    setBuildSession,
    setConfigColors,
    setConfigSessionBusy,
    setConfigurationSession,
    setSessionBusy,
    setSessionError,
} from "../../store/workspace/ssc/ssc.reducer";
import {
    sscDefaultBuildPolaritiesSelector,
    sscAllConfigSessionSelector,
    sscConfigSessionSelector,
    sscConfigSessionIdSelector,
    sscSessionIdsSelector,
} from "../../store/workspace/ssc/ssc.selectors";
import { LocalizationKeys } from "../../locales/keys";
import { sscUpdatedEventName, IConfigSession } from "../../store/workspace/ssc/ssc";
import { ConfigurationType, getConnectorTypeFromDrop } from "../../store/overlay/wizard/wizard";
import { ToBuildData, ToBuildDTO } from "../../services/build.service";
import { createdLoadedBuild } from "../overlay/header/status/status.hooks";
import { setCurrentBuild, updateConnectorsAsync } from "../../store/workspace/build/build.reducers";
import { IConnectorData, IConnectorGroupData } from "../../store/workspace/build/connector/connector";
import { getDefaultBuildDescription } from "../overlay/project-drawer/project-manager-row/build-info/build-info.hooks";
import { setStatusState } from "../../store/overlay/header/status-icon/status-icon.reducer";
import { WorkspaceStatus } from "../../store/overlay/header/status-icon/status-icon";
import { setBuildPolarity } from "../../store/workspace/build/connector/polarity/polarity.reducer";
import { totalBuildCountSelector } from "../../store/workspace/project-manager/project-manager.selectors";
import { setTotalBuildCount } from "../../store/overlay/project/project.reducers";
import { currentBuildConfigurationTypeSelector } from "../../store/workspace/build.selectors";
import { AppDispatch } from "../../store/workspace/workspace.reducers";
import { setNotification } from "../../store/overlay/notification/notification.reducers";
import { AlertPalettes } from "@corning-ctcm/silica-react";
import { propagationOptionsSelector } from "../../store/overlay/polarity/propagation/propagation.selectors";
import {
    getPolarityId,
    matchConnectorType,
    PolarityMap,
} from "../../store/workspace/build/connector/polarity/polarity";
import { AxiosError } from "axios";
import { WebServiceErrorHandlingBehavior } from "../../services/abstract-web-v2.service";

const sscUpdatedEvent = new Event(sscUpdatedEventName);

export const useSscConfigSession = () => {
    const presetConfigType = useSelector(currentBuildConfigurationTypeSelector);
    const sscSession = useSelector(sscConfigSessionSelector(presetConfigType));
    const configSessionId = useSelector(sscConfigSessionIdSelector);
    const sscConfigSessions = useSelector(sscAllConfigSessionSelector);
    const dispatch = useDispatch();

    const createConfigSession = useCallback(async () => {
        try {
            const sscService = new RABSessionService();
            dispatch(setConfigSessionBusy(true));
            const configSession = !sscSession.sessionId.length ? await sscService.createConfigSession() : sscSession;
            if (configSession) {
                dispatch(setConfigurationSession(configSession));
                dispatch(setConfigSessionBusy(false));
                dispatch(setStatusState(WorkspaceStatus.Loading));
                return configSession;
            }
        } catch {
            dispatch(setStatusState(WorkspaceStatus.Loading));
            dispatch(setConfigSessionBusy(false));
        }
    }, [dispatch, sscSession]);

    const updateConfigSessionConfigurationType = useCallback(
        async (config = ConfigurationType.Patching) => {
            let session: IConfigSession | undefined = sscConfigSessions[config];
            if (!session) {
                const service = new RABSessionService();
                dispatch(setConfigSessionBusy(true));
                try {
                    const configSession = await service.updateConfigurationType({
                        sessionId: configSessionId,
                        configurationType: config,
                    });
                    if (configSession) {
                        dispatch(setConfigurationSession(configSession));
                        session = configSession;
                    }
                    dispatch(setConfigSessionBusy(false));
                } catch {
                    dispatch(setConfigSessionBusy(false));
                }
            }

            return session;
        },
        [sscConfigSessions, configSessionId, dispatch]
    );

    return { createConfigSession, updateConfigSessionConfigurationType, sscSession };
};

export const useSscBuildSession = () => {
    const sessionIds = useSelector(sscSessionIdsSelector);
    const defaultSSCBuildPolarity = useSelector(sscDefaultBuildPolaritiesSelector);
    const totalBuildCount = useSelector(totalBuildCountSelector);
    const propagationOptions = useSelector(propagationOptionsSelector);
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();

    const updateSscBuildInfo = useCallback(
        async (build: IBuildData) => {
            const sessionId = sessionIds[build.id ?? 0]?.sessionId ?? "";
            if (!sessionId || !build.id) return;

            dispatch(setSessionBusy(true));
            const description = build.description?.length ? build.description : getDefaultBuildDescription(build);
            try {
                await rabSessionService.updateBuildInfo({ sessionId, description });
            } catch (_) {
                dispatch(
                    setNotification({
                        palette: AlertPalettes.error,
                        message: t(LocalizationKeys.ErrorUpdatingBuildInfo),
                    })
                );
            }
            dispatch(setSessionBusy(false));
        },
        [sessionIds, dispatch, t]
    );

    const applyDefaultConnectorLabelSchemes = useCallback(
        async (build: IBuildData) => {
            const feeder = build.drops[0];
            const feederType = getConnectorTypeFromDrop(feeder);
            const distribution = build.drops[1];
            const distributionType = getConnectorTypeFromDrop(distribution);
            const polarityConfigs = defaultSSCBuildPolarity.filter((c) =>
                matchConnectorType(c.from, c.to, feederType, distributionType)
            );


            dispatch(setSessionBusy(true));
            dispatch(setStatusState(WorkspaceStatus.Saving));
            const applyConnectorLabelSchemesResponse = await new RABSessionService().applyConnectorLabelSchemes({
                build: {
                    ...ToBuildDTO(build),
                    description: build.description?.length ? build.description : getDefaultBuildDescription(build),
                },
                feederLabelScheme: "ACC001", // ACC001 is temporarily hardcoded here until the SSC model is updated. Once it is, replace with defaultLabelScheme exported from eds-session-service.ts
                distributionLabelScheme: "ACC001", // ACC001 is temporarily hardcoded here until the SSC model is updated. Once it is, replace with defaultLabelScheme exported from eds-session-service.ts
                polarityId: polarityConfigs.length > 0 ? getPolarityId(polarityConfigs[0].polarityMap) : undefined,
                polarityConfigs: polarityConfigs.map((d) => {
                    const polarityMap: PolarityMap | undefined = d.polarityMap
                        ? { ...d.polarityMap, id: 0 }
                        : undefined;
                    return { ...d, polarityMap };
                }),
                propagationOptions,
            });

            if (applyConnectorLabelSchemesResponse) {
                const {
                    build,
                    sessionId,
                    warnings,
                    configStatus,
                    polarityConfigurations,
                    connectorColors,
                    defaultConnectorColors,
                } = applyConnectorLabelSchemesResponse;
                dispatch(setBuildSession({ buildId: build?.id, sessionId, configStatus, warnings }));
                dispatch(setConfigColors({ connectorColors, defaultConnectorColors }));
                const loadedBuild = createdLoadedBuild(ToBuildData(build));
                dispatch(setTotalBuildCount(totalBuildCount + 1));
                dispatch(setBuildPolarity(polarityConfigurations));
                dispatch(setCurrentBuild(loadedBuild));
                if (loadedBuild.connectorAssignments.length > 0) {
                    dispatch(
                        setNotification({
                            palette: "info",
                            message: t(LocalizationKeys.DefaultPolarityApplied),
                        })
                    );
                }
            } else {
                const warnings = {
                    componentName: t(LocalizationKeys.SscModel),
                    problemText: t(LocalizationKeys.SscModelError),
                };
                dispatch(setSessionError({ buildId: build?.id, warnings }));
            }

            dispatch(setStatusState(WorkspaceStatus.Saved));
            dispatch(setSessionBusy(false));
        },
        [dispatch, defaultSSCBuildPolarity, t, totalBuildCount, propagationOptions]
    );

    const updateBuildSession = useCallback(
        async (build: IBuildData, applyDefaultLabels: boolean = false, saveDefaultColors: boolean = false) => {
            const buildId = build.id ?? 0;
            if (applyDefaultLabels) {
                await applyDefaultConnectorLabelSchemes(build);
            } else if (!build.catalogCode || !build.catalogCode.length) {
                const sessionId = sessionIds[buildId]?.sessionId ?? "";
                dispatch(setSessionBusy(true));
                dispatch(setStatusState(WorkspaceStatus.Saving));
                const updateRABRequest = buildDataToRABSessionRequest(sessionId, {
                    ...build,
                    description: build.description?.length ? build.description : getDefaultBuildDescription(build),
                });
                rabSessionService.setErrorHandlingBehavior(WebServiceErrorHandlingBehavior.rethrowError);
                rabSessionService
                    .updateRABCharacteristic(updateRABRequest)
                    .then((updateSessionResponse) => {
                        dispatch(setSessionBusy(false));
                        dispatch(setStatusState(WorkspaceStatus.Saved));
                        if (updateSessionResponse) {
                            const { session, connectorColors, defaultConnectorColors } = updateSessionResponse;
                            const overlay = document.getElementsByClassName("overlay")[0];
                            if (overlay) {
                                overlay.dispatchEvent(sscUpdatedEvent);
                            }

                            dispatch(setConfigColors({ connectorColors, defaultConnectorColors }));
                            dispatch(
                                setBuildSession({
                                    buildId: buildId,
                                    sessionId: session.sessionId,
                                    configStatus: session.configStatus,
                                    warnings: session.warnings,
                                })
                            );
                            if (saveDefaultColors) {
                                const { feederEnd, distribution } = updateRABRequest;
                                if (feederEnd && distribution) {
                                    const updatedConnectors = getUpdatedConnectors(
                                        build,
                                        feederEnd.drop,
                                        distribution.accessPoints?.flatMap((a) => a.drop)
                                    );
                                    dispatch(updateConnectorsAsync(updatedConnectors));
                                }
                            }
                        }
                    })
                    .catch((e: AxiosError) => {
                        dispatch(setStatusState(WorkspaceStatus.Saved));
                        const message = (e.response?.data as string) ?? e.message;
                        const warnings = {
                            solutionText: message,
                            componentName: t(LocalizationKeys.SscModel),
                            problemText: t(LocalizationKeys.SscModelError),
                        };
                        dispatch(setSessionError({ buildId, warnings }));
                        dispatch(setSessionBusy(false));
                    });
            }
        },
        [applyDefaultConnectorLabelSchemes, dispatch, sessionIds, t]
    );

    return { updateBuildSession, updateSscBuildInfo };
};

function getUpdatedConnectors(
    build: IBuildData,
    feederEndDrop: Partial<IUpdateDropMaterialRequest> | undefined,
    destinationDrops: (Partial<IUpdateDropMaterialRequest> | undefined)[] | undefined
): IConnectorData[] {
    const [source, ...destinations] = build.drops;
    const updatedSrcConnectors = feederEndDrop ? updateDefaultColors(source.groups, feederEndDrop) : [];
    const updatedDstConnectors = destinationDrops
        ? destinations.flatMap((d, i) => updateDefaultColors(d.groups, destinationDrops[i]))
        : [];
    return [...updatedSrcConnectors, ...updatedDstConnectors];
}

function updateDefaultColors(
    groups: IConnectorGroupData[],
    drop: Partial<IUpdateDropMaterialRequest> | undefined
): IConnectorData[] {
    const updatedConnectors: IConnectorData[] = [];
    if (!drop) {
        return updatedConnectors;
    }

    for (let i = 0; i < groups.length; i++) {
        const connectors = groups[i].connectors;
        for (let j = 0; j < connectors.length; j++) {
            const furcationGroup = drop.furcationGroups && drop.furcationGroups[i];
            if (furcationGroup && furcationGroup.furcationGroupLegs) {
                const connector = furcationGroup.furcationGroupLegs[j].connector;
                if (connector && !connectors[j].color) {
                    const updatedConnector: IConnectorData = {
                        ...connectors[j],
                        stagger: connectors[j].stagger,
                        color: connectors[j].color ?? connectors[j].defaultColor,
                    };
                    updatedConnectors.push(updatedConnector);
                }
            }
        }
    }

    return updatedConnectors;
}

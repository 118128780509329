import React, { Fragment } from "react";
import { withDrawerRow } from "../../../ui/drawer/drawer-row";
import { BuildInfo } from "./build-info/build-info";
import { CreationInfo } from "./creation-info/creation-info";
import { DragHandle } from "./drag-handle/drag-handle";
import { BuildToolbar } from "./build-toolbar/build-toolbar";
import { ProjectManagerRowProps } from "../../../../models/overlay/project-drawer/project-manager-row/project-manager-row";

export const ProjectManagerRow = withDrawerRow((props: ProjectManagerRowProps) => {
    const { projectId, deleteDisabled, editDisabled } = props;
    return (
        <Fragment key={`row-${projectId}`}>
            <div className="row">
                <div className="row-start">
                    <DragHandle projectId={projectId} editDisabled={editDisabled} />
                </div>
                <div className="row-middle">
                    <BuildInfo projectId={projectId} />
                </div>
                <div className="row-end">
                    <BuildToolbar projectId={projectId} deleteDisabled={deleteDisabled} editDisabled={editDisabled} />
                    <CreationInfo projectId={projectId} />
                </div>
            </div>
        </Fragment>
    );
});

import React from "react";
import { getTextColor, IColorButtonProps } from "../../models/ui/dialog/color-dialog";
import { Tooltip } from "@corning-ctcm/silica-react";

export const ColorButton: React.FC<IColorButtonProps> = ({ onClick, color, selected }) => {
    const { hex, name: tooltip } = color;
    const checkmarkColor = getTextColor(color).hex;
    return (
        <Tooltip arrow title={tooltip} placement="bottom">
            <svg
                id={`${tooltip.toLowerCase()}-color-button`}
                className="color-button"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                onClick={() => onClick(color)}
            >
                <g fill={hex} stroke="#707070" strokeWidth="2px">
                    <circle stroke="none" cx="20" cy="20" r="20" />
                    <circle fill="none" cx="20" cy="20" r="19" />
                </g>
                {selected ? (
                    <g transform="translate(8 8)">
                        <rect fill="none" width="24" height="24" />
                        <path
                            fill={checkmarkColor}
                            fillRule="evenodd"
                            d="M9,16.2,4.8,12,3.4,13.4,9,19,21,7,19.6,5.6Z"
                        />
                    </g>
                ) : null}
            </svg>
        </Tooltip>
    );
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WizardPreset, WizardPresetState, initialUserWizardPreset } from "./wizard-preset";

const setCurrentPresetReducer = (state: WizardPresetState, action: PayloadAction<WizardPreset>) => {
    state.currentPreset = action.payload;
};

const userWizardPresetSlice = createSlice({
    name: "userWizardPreset",
    initialState: initialUserWizardPreset,
    reducers: {
        setCurrentPreset: setCurrentPresetReducer,
    },
});

export const UserWizardPresetReducer = userWizardPresetSlice.reducer;
export const { setCurrentPreset } = userWizardPresetSlice.actions;

import { ProjectManagerService } from "../../services/project-manager.service";
import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import { setProjectManager, setRecentProject, setRecentProjectId } from "../../store/overlay/project/project.reducers";
import { IProjectManagerData } from "../../store/workspace/project-manager/project-manager";
import { useWebLoad } from "../overlay/header/status/status.hooks";
import { BuildService } from "../../services/build.service";
import { setUserPolarities } from "../../store/workspace/build/connector/polarity/polarity.reducer";
import { projectManagerSelector } from "../../store/workspace/project-manager/project-manager.selectors";
import { setBuilds } from "../../store/workspace/build/build.reducers";
import { updateBuildListEntry } from "../../store/workspace/build/build.reducers";
import { PrimaryUnit, Unit } from "../../models/overlay/header/units-of-measure";
import {
    setPrimaryUnit,
    setUnit,
} from "../../store/overlay/header/units-of-measure-container/units-of-measure-container.reducers";
import { IBuildData } from "../../store/workspace/build/build";
import { defaultBoundariesValuesSelector } from "../../store/workspace/boundaries/boundaries.selectors";

export const useProjectManager = () => {
    const { data: pmData, selectedBuild } = useSelector(projectManagerSelector);
    const { defaultPrimaryMeasurementSystem, defaultUnit } = useSelector(defaultBoundariesValuesSelector);
    const storeDispatch = useDispatch();

    const { dispatchBuild } = useWebLoad();

    // using this callback for initial polarity load
    const getProjectManager = useCallback(async () => {
        new BuildService().getUserBuildPolarityDefinitionsByGroupId().then((polarities) => {
            if (polarities) {
                storeDispatch(setUserPolarities(polarities));
            }
        });

        const projectManagerData = await new ProjectManagerService().getProjectManager();
        if (projectManagerData) {
            storeDispatch(setProjectManager(projectManagerData));
            return projectManagerData;
        }
    }, [storeDispatch]);

    const getProject = useCallback(
        async (projectId?: number) => {
            let project: IBuildData | undefined;
            if (selectedBuild && selectedBuild.id === projectId) {
                project = selectedBuild;
            } else {
                const build = await new ProjectManagerService().getProject(projectId ?? 0);
                if (build) {
                    storeDispatch(setRecentProject(build));
                    project = build;
                }
            }

            return project;
        },
        [selectedBuild, storeDispatch]
    );

    const updateRecentProject = useCallback(
        async (projectId: number) => {
            if (pmData) {
                const buildResponse = await new ProjectManagerService().updateRecentProject(projectId);
                if (buildResponse) {
                    storeDispatch(setRecentProjectId(projectId));
                    const { build: previousBuild } = buildResponse;
                    if (previousBuild) {
                        storeDispatch(updateBuildListEntry(previousBuild));
                    }
                }
            }
        },
        [pmData, storeDispatch]
    );

    const loadProjectManager = useCallback(async () => {
        const projectManagerData: IProjectManagerData | undefined = await getProjectManager();
        if (projectManagerData) {
            const { builds } = projectManagerData;
            if (builds) {
                storeDispatch(setBuilds(builds));
            }
        }
    }, [storeDispatch, getProjectManager]);

    const loadRecentProject = useCallback(
        async (projectId?: number) => {
            const recentBuild: IBuildData | undefined = await getProject(projectId);
            if (recentBuild) {
                const primaryUnit = recentBuild.primaryUnit ?? defaultPrimaryMeasurementSystem;
                const unit = recentBuild.unit ?? defaultUnit;
                storeDispatch(setPrimaryUnit(primaryUnit as PrimaryUnit));
                storeDispatch(setUnit(unit as Unit));
                storeDispatch(updateBuildListEntry(recentBuild));
            }

            await dispatchBuild(recentBuild);
        },
        [defaultPrimaryMeasurementSystem, defaultUnit, dispatchBuild, getProject, storeDispatch]
    );

    return { updateRecentProject, loadProjectManager, loadRecentProject };
};

import { createSelector } from "@reduxjs/toolkit";
import { Blue, Yellow } from "../../../models/ui/dialog/color-dialog";
import { unitsOfMeasureContainerUnitSelector } from "../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { AllColors } from "../../../models/overlay/polarity/fiber-mapping/fiber-mapping-connector-templates";
import { MeshColors } from "../../../models/overlay/wizard/setup/mesh-properties";
import { WorkspaceState } from "../../../store/workspace/workspace.reducers";
import {
    Boundaries,
    DEFAULT_BOUNDARIES_NAME,
    IBoundariesData,
    convertRangeUnit,
    initialBoundaries,
} from "./boundaries";
import { CableType } from "../build/build";
import { IDrop } from "../build/drop";
import { Unit } from "../../../models/overlay/header/units-of-measure";

export const boundariesOptionsSelector = (state: WorkspaceState) => state.boundaries.options;
export const showBoundariesSettingsSelector = (state: WorkspaceState) => state.boundaries.displaySettings;
export const currentBuildModeIdSelector = (state: WorkspaceState) => state.builds.currentBuild?.modeId ?? 0;
export const currentFiberTypeSelector = (state: WorkspaceState) => state.builds.currentBuild?.fiberType;
export const currentMainMeshColorSelector = (state: WorkspaceState) => state.builds.currentBuild?.mainMeshColor;
export const currentDropsSelector = (state: WorkspaceState) => state.builds.currentBuild?.drops ?? [];
export const currentOuterDiameterSelector = (state: WorkspaceState) => state.builds.currentBuild?.cableOuterDiameter;

const getCurrentBoundaries = (modeId: number, options: Boundaries[]) => {
    const defaultBuildMode = options.find((d) => d.name === DEFAULT_BOUNDARIES_NAME) ?? initialBoundaries;
    return options.find((d) => d.id === modeId) ?? defaultBuildMode;
};

export const currentBuildBoundariesSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundaries(modeId, options)
);

const getCurrentBoundariesData = (modeId: number, options: Boundaries[]) => getCurrentBoundaries(modeId, options).data;

export const isDrawingModeSelector = createSelector(
    currentBuildBoundariesSelector,
    ({ name }) => name === DEFAULT_BOUNDARIES_NAME
);

export const meshColorsSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).meshColors
);

export const defaultMeshColorsSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).defaultMeshColors
);

const getBundleCountRanges = (data: IBoundariesData, drops: IDrop[], outerDiameter: number | undefined) => {
    const cableOuterDiameter = outerDiameter ?? data.cableOuterDiameters[0];
    const connectorType = drops[0].groups[0].type ?? "";
    return data.bundleCountRanges[connectorType][cableOuterDiameter];
};

export const bundleCountRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentDropsSelector,
    currentOuterDiameterSelector,
    (modeId, options, drops, cableOuterDiameter) => {
        const data = getCurrentBoundariesData(modeId, options);
        return getBundleCountRanges(data, drops, cableOuterDiameter);
    }
);

export const bundleCountOptionsSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentDropsSelector,
    currentOuterDiameterSelector,
    (modeId, options, drops, cableOuterDiameter) => {
        const data = getCurrentBoundariesData(modeId, options);
        const { max } = getBundleCountRanges(data, drops, cableOuterDiameter);
        return Array.from({ length: max }, (v, k) => (k + 1).toString());
    }
);

export const flameRatingsSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).flameRatings
);

export const pullingGripsSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).pullingGrips
);

export const insertionLossSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).insertionLoss
);

export const cableOuterDiametersSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).cableOuterDiameters
);

export const accessPointRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).accessPointRange
);

export const feederConnectorTypesSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).feederEndConnectorTypes
);

export const distributionConnectorTypesSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).distributionConnectorTypes
);

export const fiberTypesSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).fiberTypes
);

export const feederGroupCountRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).feederEndGroupCountRange
);

export const distributionGroupCountRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).distributionGroupCountRange
);

export const distributionConnectorCountPerGroupRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).distributionConnectorCountPerGroupRange
);

const getCurrentBuildFiberType = (data: IBoundariesData, currentFiberType: string | undefined) => {
    return currentFiberType && currentFiberType.length > 0 ? currentFiberType : data.fiberTypes[0]?.id;
};

export const currentBuildFiberTypeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentFiberTypeSelector,
    (modeId, options, currentFiberType) => {
        const data = getCurrentBoundariesData(modeId, options);
        return getCurrentBuildFiberType(data, currentFiberType);
    }
);

export const fiberCountOptionsSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => {
        const { max } = getCurrentBoundariesData(modeId, options).fiberCountRange;
        return Array.from({ length: max }, (v, k) => k + 1);
    }
);

export const cableOuterDiameterOptionsSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).cableOuterDiameters.map((d) => d.toFixed(1))
);

const getFeederALengthRange = (data: IBoundariesData, drops: IDrop[]) => {
    const [, ...distribution] = drops;
    const type: CableType = distribution.length === 1 ? "P2P" : "P2MP";
    return data.feederALengthRanges[type];
};

export const feederALengthRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentDropsSelector,
    unitsOfMeasureContainerUnitSelector,
    (modeId, options, drops, unit) => {
        const data = getCurrentBoundariesData(modeId, options);
        return convertRangeUnit(getFeederALengthRange(data, drops), unit);
    }
);

const getFeederBLengthRange = (data: IBoundariesData, unit: Unit) => {
    return convertRangeUnit(data.feederEndLegRange, unit);
};

export const feederBLengthRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    unitsOfMeasureContainerUnitSelector,
    (modeId, options, unit) => {
        const data = getCurrentBoundariesData(modeId, options);
        return getFeederBLengthRange(data, unit);
    }
);

export const feederSLengthRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    unitsOfMeasureContainerUnitSelector,
    (modeId, options, unit) => {
        const { feederEndStaggerRange } = getCurrentBoundariesData(modeId, options);
        return convertRangeUnit(feederEndStaggerRange, unit);
    }
);

const getDistributionALengthRange = (data: IBoundariesData, drops: IDrop[]) => {
    const [, ...distribution] = drops;
    const type: CableType = distribution.length === 1 ? "P2P" : "P2MP";
    return data.distributionALengthRanges[type];
};

export const distributionALengthRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentDropsSelector,
    unitsOfMeasureContainerUnitSelector,
    (modeId, options, drops, unit) => {
        const data = getCurrentBoundariesData(modeId, options);
        const range = getDistributionALengthRange(data, drops);
        return convertRangeUnit(range, unit);
    }
);

const getDistributionBLengthRange = (data: IBoundariesData, unit: Unit) => {
    return convertRangeUnit(data.distributionLegRange, unit);
};

export const distributionBLengthRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    unitsOfMeasureContainerUnitSelector,
    (modeId, options, unit) => {
        const data = getCurrentBoundariesData(modeId, options);
        return getDistributionBLengthRange(data, unit);
    }
);

export const distributionSLengthRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    unitsOfMeasureContainerUnitSelector,
    (modeId, options, unit) => {
        const { distributionStaggerRange } = getCurrentBoundariesData(modeId, options);
        return convertRangeUnit(distributionStaggerRange, unit);
    }
);

export const meshOffsetRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    unitsOfMeasureContainerUnitSelector,
    (modeId, options, unit) => {
        const { meshOffsetRange } = getCurrentBoundariesData(modeId, options);
        return convertRangeUnit(meshOffsetRange, unit);
    }
);

export const bootColorSelectorFactory = (connectorType: string) =>
    createSelector(
        currentBuildModeIdSelector,
        boundariesOptionsSelector,
        currentFiberTypeSelector,
        (modeId, options, currentFiberType) => {
            const data = getCurrentBoundariesData(modeId, options);
            const fiberType = getCurrentBuildFiberType(data, currentFiberType);
            return data.defaultBootColors[fiberType]?.[connectorType] ?? Blue.id;
        }
    );

export const trunkColorSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentFiberTypeSelector,
    (modeId, options, currentFiberType) => {
        const data = getCurrentBoundariesData(modeId, options);
        const fiberType = getCurrentBuildFiberType(data, currentFiberType);
        const defaultColor = data.defaultTrunkColors[fiberType];
        const color = AllColors.find((c) => c.id === defaultColor) ?? Yellow;
        return { id: color, colorHex: color.hex.replace("#", "0x"), name: color.name };
    }
);

const getMeshColors = (data: IBoundariesData, currentFiberType: string | undefined) => {
    const fiberType = getCurrentBuildFiberType(data, currentFiberType);
    const color = data.defaultMeshColors[fiberType];
    const meshColor = MeshColors.find((c) => c.id === color);
    return {
        name: meshColor?.name ?? Yellow.name,
        colorHex: (meshColor?.hex ?? Yellow.hex).replace("#", "0x"),
    };
};

export const meshColorSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentFiberTypeSelector,
    (modeId, options, currentFiberType) => {
        const data = getCurrentBoundariesData(modeId, options);
        return getMeshColors(data, currentFiberType);
    }
);

export const cableColorSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentFiberTypeSelector,
    currentMainMeshColorSelector,
    (modeId, options, currentFiberType, mainMeshColor) => {
        const data = getCurrentBoundariesData(modeId, options);
        const fiberType = getCurrentBuildFiberType(data, currentFiberType);
        const color = mainMeshColor ?? data.defaultMeshColors[fiberType];
        return MeshColors.find((c) => c.id === color || c.name === color);
    }
);

export const defaultTriggerColorSelectorFactory = (connectorType?: string) =>
    createSelector(
        currentBuildModeIdSelector,
        boundariesOptionsSelector,
        currentFiberTypeSelector,
        (modeId, options, currentFiberType) => {
            const data = getCurrentBoundariesData(modeId, options);
            const fiberType = getCurrentBuildFiberType(data, currentFiberType);
            const color = data.defaultConnectorColors[fiberType]?.[connectorType ?? ""];
            return AllColors.find((c) => c.id === color)?.name ?? Blue.name;
        }
    );

export const defaultTriggersColorSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentFiberTypeSelector,
    (modeId, options, currentFiberType) => {
        const data = getCurrentBoundariesData(modeId, options);
        const fiberType = getCurrentBuildFiberType(data, currentFiberType);
        const defaultColors = data.defaultConnectorColors[fiberType];
        return defaultColors
            ? Object.fromEntries(
                  Object.entries(defaultColors).map(([connectorType, color]) => [
                      connectorType,
                      AllColors.find((c) => c.id === color) ?? Blue,
                  ])
              )
            : {};
    }
);

export const connectorColorsSelectorFactory = (connectorType?: string) =>
    createSelector(connectorColorsSelector, (connectorColors) => connectorColors[connectorType ?? ""] ?? []);

export const connectorColorsSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentFiberTypeSelector,
    (modeId, options, currentFiberType) => {
        const data = getCurrentBoundariesData(modeId, options);
        const fiberType = getCurrentBuildFiberType(data, currentFiberType);
        const colors = data.connectorColors[fiberType];
        return colors
            ? Object.fromEntries(
                  Object.entries(colors).map(([connectorType, colors]) => [
                      connectorType,
                      AllColors.filter((c) => colors.includes(c.id)) ?? Blue,
                  ])
              )
            : {};
    }
);

export const fiberCountRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).fiberCountRange
);

const getDistributionFiberCountRange = (data: IBoundariesData, drops: IDrop[]) => {
    const { min, max } = data.fiberCountRange;
    const divider = drops.length > 0 ? drops.length - 1 : 1;
    return {
        min,
        max: max / divider,
    };
};

export const distributionDropFiberCountRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentDropsSelector,
    (modeId, options, drops) => {
        const data = getCurrentBoundariesData(modeId, options);
        return getDistributionFiberCountRange(data, drops);
    }
);

export const feederOptionsSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentDropsSelector,
    unitsOfMeasureContainerUnitSelector,
    (modeId, options, drops, unit) => {
        const data = getCurrentBoundariesData(modeId, options);
        const feederALengthRange = getFeederALengthRange(data, drops);
        const {
            fiberTypes,
            cableOuterDiameters,
            feederEndLegRange,
            feederEndStaggerRange,
            feederEndConnectorTypes,
            feederEndGroupCountRange,
            meshOffsetRange,
            mainFurcationToEndFurcationRange,
        } = data;
        return {
            fiberTypes,
            cableOuterDiameters,
            legRange: convertRangeUnit(feederEndLegRange, unit),
            staggerRange: convertRangeUnit(feederEndStaggerRange, unit),
            connectorTypes: feederEndConnectorTypes,
            groupCounts: feederEndGroupCountRange,
            aLengthRange: convertRangeUnit(feederALengthRange, unit),
            meshOffsetRange: convertRangeUnit(meshOffsetRange, unit),
            mainFurcationToEndFurcationRange: convertRangeUnit(mainFurcationToEndFurcationRange, unit),
        };
    }
);

export const distributionOptionsSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentDropsSelector,
    unitsOfMeasureContainerUnitSelector,
    (modeId, options, drops, unit) => {
        const data = getCurrentBoundariesData(modeId, options);
        const distributionALengthRange = getDistributionALengthRange(data, drops);
        const distributionDropFiberCountRange = getDistributionFiberCountRange(data, drops);
        const {
            distributionLegRange,
            distributionStaggerRange,
            distributionConnectorTypes,
            accessPointRange,
            distributionGroupCountRange,
            distributionConnectorCountPerGroupRange,
            meshOffsetRange,
            mainFurcationToEndFurcationRange,
        } = data;
        return {
            legRange: convertRangeUnit(distributionLegRange, unit),
            staggerRange: convertRangeUnit(distributionStaggerRange, unit),
            connectorTypes: distributionConnectorTypes,
            accessPointRange,
            groupCounts: distributionGroupCountRange,
            connectorsPerGroupCounts: distributionConnectorCountPerGroupRange,
            dropFiberCountRange: distributionDropFiberCountRange,
            aLengthRange: convertRangeUnit(distributionALengthRange, unit),
            meshOffsetRange: convertRangeUnit(meshOffsetRange, unit),
            mainFurcationToEndFurcationRange: convertRangeUnit(mainFurcationToEndFurcationRange, unit),
        };
    }
);

export const defaultPrimaryMeasurementSystemSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).defaultPrimaryMeasurementSystem
);

export const defaultUnitSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).defaultUnit
);

export const defaultShellType = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).defaultShellType
);

export const defaultMeasurementsPosition = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).defaultMeasurementsPosition
);

export const defaultOverallLengthType = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).defaultOverallLengthType
);

export const defaultTolerancesSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).defaultTolerances
);

export const defaultMeshSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).defaultMesh
);

export const defaultMeshValue = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    (modeId, options) => getCurrentBoundariesData(modeId, options).defaultMeshValue
);

export const mainFurcationToEndFurcationRangeSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    unitsOfMeasureContainerUnitSelector,
    (modeId, options, unit) => {
        const { mainFurcationToEndFurcationRange } = getCurrentBoundariesData(modeId, options);
        return convertRangeUnit(mainFurcationToEndFurcationRange, unit);
    }
);

export const defaultBoundariesValuesSelector = createSelector(
    currentBuildModeIdSelector,
    boundariesOptionsSelector,
    currentFiberTypeSelector,
    (modeId, options, currentFiberType) => {
        const data = getCurrentBoundariesData(modeId, options);
        const defaultMeshColor = getMeshColors(data, currentFiberType);
        const {
            defaultPrimaryMeasurementSystem,
            defaultUnit,
            defaultShellType,
            defaultMeasurementsPosition,
            defaultOverallLengthType,
            defaultTolerances,
            defaultMesh,
            defaultMeshValue,
        } = data;

        return {
            defaultPrimaryMeasurementSystem,
            defaultUnit,
            defaultShellType,
            defaultMeasurementsPosition,
            defaultOverallLengthType,
            defaultTolerances,
            defaultMesh,
            defaultMeshValue,
            defaultMeshColor: defaultMeshColor.name,
        };
    }
);

export const dropBLengthSelectorFactory = (dropPosition: number) =>
    createSelector(
        currentBuildModeIdSelector,
        boundariesOptionsSelector,
        unitsOfMeasureContainerUnitSelector,
        (modeId, options, unit) => {
            const data = getCurrentBoundariesData(modeId, options);
            const feederBLength = getFeederBLengthRange(data, unit);
            const dropBLength = getDistributionBLengthRange(data, unit);
            return dropPosition === 1 ? feederBLength : dropBLength;
        }
    );

import React from "react";
import { ICollapsibleDialogProps } from "../../../../models/ui/dialog/collapsible-dialog";
import { useCollapsibleDialog } from "./collapsible-dialog.hooks";
import { DialogContext } from "../../../../store/workspace/dialog/dialog.reducer";
import { DialogHeader } from "../dialog-header/dialog-header";

export const CollapsibleDialog: React.FC<ICollapsibleDialogProps> = (props) => {
    const { context, id, dialogClassName, open, onClose, headerProps, collapsed, children } =
        useCollapsibleDialog(props);
    return open ? (
        <DialogContext.Provider value={context}>
            <div id={id} className={dialogClassName + " toggle-pointer-events collapse-dialog"}>
                <DialogHeader id={id} onClose={onClose} {...headerProps} />
                {collapsed ? null : children}
            </div>
        </DialogContext.Provider>
    ) : null;
};

import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../locales/keys";
import { setViewportContext } from "../../../../store/pixi/viewport/viewport.reducers";
import { ViewportStatus } from "../../../../store/pixi/viewport/viewport";
import { IDialogHeaderProps } from "../../../../models/ui/dialog/dialog-header";
import { setShowThirdPartyCopyright } from "../../../../store/overlay/overlay.reducers";
import { showThirdPartyCopyrightSelector } from "../../../../store/overlay/overlay.selectors";
import { OpenSourceLicenses } from "../../../../models/overlay/about-this-application";

export const useThirdPartyCopyrightDialog = () => {
    const { t } = useTranslation();
    const showThirdPartyCopyright = useSelector(showThirdPartyCopyrightSelector);
    const licenses = OpenSourceLicenses;
    const dispatch = useDispatch();

    const closeDialog = useCallback(() => {
        dispatch(setShowThirdPartyCopyright(false));
        dispatch(setViewportContext(ViewportStatus.Active));
    }, [dispatch]);

    const dialogHeaderProps: IDialogHeaderProps = useMemo(() => {
        return {
            id: "third-party-copyright",
            title: t(LocalizationKeys.ThirdPartyOSSCopyright),
            closable: true,
            onClose: closeDialog,
        };
    }, [t, closeDialog]);

    return { showThirdPartyCopyright, licenses, dialogHeaderProps, closeDialog };
};

import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./units-of-measure-container";
import { setUnitsOfMeasureDisplayReducer, setUnitReducer, setPrimaryUnitReducer } from "./units-of-measure-container.actions";

const unitsOfMeasureContainerSlice = createSlice({
    name: "unitsOfMeasureContainer",
    initialState,
    reducers: {
        setUnitsOfMeasureContainerDisplay: setUnitsOfMeasureDisplayReducer,
        setPrimaryUnit: setPrimaryUnitReducer,
        setUnit: setUnitReducer,
    },
});

export const UnitOfMeasureContainerReducer = unitsOfMeasureContainerSlice.reducer;
export const { setUnitsOfMeasureContainerDisplay, setPrimaryUnit, setUnit } = unitsOfMeasureContainerSlice.actions;

import { IconButton, Typography } from "@corning-ctcm/silica-react";
import { Add, Fullscreen, FullscreenExit, Remove, UnfoldMore, ZoomIn } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../locales/keys";
import { IPanAndZoomToolbarProps } from "../../../../../../models/overlay/polarity/polarity-diagram/pan-and-zoom-toolbar";
import { usePanAndZoomToolbar } from "./pan-and-zoom-toolbar.hooks";

export const PanAndZoomToolbar: React.FC<IPanAndZoomToolbarProps> = (props) => {
    const {
        currentZoom,
        onZoomIn,
        onZoomOut,
        fitOption,
        onResizeClick,
        fullscreen,
        container,
        onToggleFullscreen,
        isMenuOpen,
        menuButtonRef,
        onMenuOpen,
        onMenuClose,
        onMenuClick,
        buttonLabel,
    } = usePanAndZoomToolbar(props);

    const { t } = useTranslation();

    return (
        <div className="pan-and-zoom-toolbar">
            <div className="toolbar-group">
                <div className="toolbar-item zoom-control">
                    <IconButton
                        palette="primary"
                        mode="main"
                        title={t(LocalizationKeys.ZoomIn)}
                        placement="top"
                        onClick={onZoomIn}
                        icon={<Add />}
                    />
                    <IconButton
                        palette="primary"
                        mode="main"
                        title={t(LocalizationKeys.ZoomOut)}
                        placement="top"
                        onClick={onZoomOut}
                        icon={<Remove />}
                    />
                    <hr className="vertical-divider" />
                    <Typography variant="body2" className="current-zoom">
                        {currentZoom}
                    </Typography>
                </div>
                <div className="toolbar-item resize-control">
                    <IconButton
                        palette="primary"
                        mode="main"
                        title={t(LocalizationKeys.ZoomIn)}
                        placement="top"
                        onClick={onResizeClick}
                        className="resize-button"
                        icon={<ZoomIn />}
                    />
                    <Button className="menu-button" onClick={onMenuOpen} endIcon={<UnfoldMore />} ref={menuButtonRef}>
                        {buttonLabel}
                    </Button>
                    <Menu
                        open={isMenuOpen}
                        onClose={onMenuClose}
                        anchorEl={menuButtonRef.current}
                        container={fullscreen ? container : undefined}
                        keepMounted
                    >
                        <MenuItem selected={fitOption === "page"} onClick={() => onMenuClick("page")}>
                            {t(LocalizationKeys.FitToPage)}
                        </MenuItem>
                        <MenuItem selected={fitOption === "width"} onClick={() => onMenuClick("width")}>
                            {t(LocalizationKeys.FitToWidth)}
                        </MenuItem>
                        <MenuItem selected={fitOption === "height"} onClick={() => onMenuClick("height")}>
                            {t(LocalizationKeys.FitToHeight)}
                        </MenuItem>
                    </Menu>
                </div>
                <div className="toolbar-item reference-note">
                    <Typography variant="caption">{t(LocalizationKeys.ReferenceFootNote)}</Typography>
                </div>
            </div>
            <IconButton
                palette="primary"
                mode="main"
                title={t(LocalizationKeys.Fullscreen)}
                placement="top"
                className="fullscreen-button"
                onClick={onToggleFullscreen}
                icon={fullscreen ? <FullscreenExit /> : <Fullscreen />}
            />
        </div>
    );
};

import { useContext, useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ConnectorReportContext, toggleRowSelection } from "../../../../../store/overlay/reports/reports.reducers";
import { IConnectorRowProps, IEditableLabelProps } from "../../../../../models/overlay/reports/connector-row";
import { LocalizationKeys } from "../../../../../locales/keys";

export const useConnectorRow = ({ data, disabled, onFocus, onBlur, labelCallback }: IConnectorRowProps) => {
    const { state, dispatch } = useContext(ConnectorReportContext);
    const { selectedRowIds } = state;
    const [initialLabelText, setInitialLabelText] = useState(data.label ?? "");
    const [labelText, setLabelText] = useState(data.label);
    const checked = selectedRowIds.includes(data.id);
    const { t } = useTranslation();

    useEffect(() => {
        setLabelText(data.label);
    }, [data.label]);

    const onCheckboxChanged = useCallback(() => {
        dispatch(toggleRowSelection(data.id));
    }, [dispatch, data.id]);

    const checkboxProps = {
        checked,
        disabled,
        className: checked ? "checked" : "",
        onChange: onCheckboxChanged,
    };

    const onLabelFocus = useCallback(
        (e: React.FocusEvent<any>): void => {
            setInitialLabelText(e.currentTarget.value);
        },
        [setInitialLabelText]
    );

    const onLabelChange = useCallback(
        (e: React.ChangeEvent<any>): void => {
            setLabelText(e.currentTarget.value);
        },
        [setLabelText]
    );

    const onLabelBlur = useCallback(
        (e: React.FocusEvent<any>): void => {
            const { value } = e.currentTarget;
            if (!value.length) {
                setLabelText(initialLabelText);
                return;
            }
            labelCallback(e);
        },
        [initialLabelText, labelCallback, setLabelText]
    );

    const editableLabelProps: IEditableLabelProps = {
        value: labelText ?? t(LocalizationKeys.Loading),
        maxLength: 30,
        onChange: onLabelChange,
        onFocus: onLabelFocus,
        onBlur: onLabelBlur,
        disabled,
    };

    return {
        data,
        onFocus,
        onBlur,
        checkboxProps,
        editableLabelProps,
    };
};

export const validateInputChars = (value: string) => {
    const stringValue = value.trim();
    return stringValue.length > 0;
};

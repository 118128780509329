import { PullingGrip } from "../../../../models/pixi/decorators/markers/pulling-grip";
import { IUnitOfMeasure } from "../../../../models/overlay/header/units-of-measure";
import { Identifiable } from "../../../../models/services/services";

interface IFeederPreset {
    modeId: number;
    connectorType: string;
    fiberCount: number;
    fiberCountInUse: number;
    bundleCount: number;
    flameRating: string;
    pullingGrip: PullingGrip;
    cableOuterDiameter: number;
    fiberType: string;
    groups: number;
    connectorsPerGroup: number;
    mesh: boolean;
    meshOffset: IUnitOfMeasure;
    meshColor: string;
    overallLengthType: string;
    aLength: IUnitOfMeasure;
    bLength: IUnitOfMeasure;
    staggerLength: IUnitOfMeasure;
    reverseStaggering: boolean;
    customTag: string;
}

interface IDropPreset {
    connectorType: string;
    accessPointCount: number;
    calculateAccessPoints: boolean;
    groups: number;
    connectorsPerGroup: number;
    mesh: boolean;
    meshOffset: IUnitOfMeasure;
    meshColor: string;
    aLength: IUnitOfMeasure;
    bLength: IUnitOfMeasure;
    staggerLength: IUnitOfMeasure;
    reverseStaggering: boolean;
    fiberCountInUse: number;
}

interface IWizardPreset {
    feederPreset: FeederPreset;
    dropPreset: DropPreset;
    created: string;
}

export interface WizardPresetState {
    currentPreset: WizardPreset;
}

export type FeederPreset = Partial<IFeederPreset & Identifiable>;
export type DropPreset = Partial<IDropPreset & Identifiable>;
export type WizardPreset = Partial<IWizardPreset & Identifiable>;

export const initialUserWizardPreset: WizardPresetState = { currentPreset: {} };

import { PropsWithChildren } from "react";

import {
    ConnMPO12_NP,
    ConnMPO12_P,
    ConnMTP12_FP_NP,
    ConnMTP12_FP_P,
    ConnMTP12_NP,
    ConnMTP12_P,
    ConnMTP24_FP_NP,
    ConnMTP24_FP_P,
    ConnMTP24_NP,
    ConnMTP24_P,
    ConnMTP8_NP,
    ConnMTP8_P,
} from "../../../../store/overlay/wizard/wizard";
import { getConnectorTexture } from "../../../../components/pixi/factories/texture";
import { IPoint, pointEqual } from "../../pixi";
import { AP_SIZE } from "../drop-base/access-point";

export interface IFurcationProps {
    legProps: IConnectorLegProps[];
}

export interface IConnectorLegProps {
    location: IPoint;
    legEndpoint: IPoint;
    staggerPosition: number;
    text: string;
    side: string;
    connectorPosition: number;
    connectorColor?: string;
    labelColor?: string;
    groupIndex: number;
    connectorType?: string;
    connectorId?: number;
}

export const STAGGER_OFFSET_Y = 50;
export const BORDER_COLOR = 0x484646;
export const BORDER_COLOR_HEX = "#484646";
export const DROP_CABLE_BORDER_WIDTH = 2;
export const DASH_WIDTH = 1;
export const DASH_COLOR = 0xfdfdfd;
export const FURCATION_CURVE_END = 400;
export const FURCATION_END = 525;
export const TIED_FURCATION_CURVE_END = 200;
export const TIED_GROUP_OFFSET_FROM_FURCATION_END = 50;
export const TIED_LEG_FURCATION_CURVE_END = TIED_FURCATION_CURVE_END + TIED_GROUP_OFFSET_FROM_FURCATION_END + 50;
export const FURCATION_OFFSET_X = AP_SIZE.width * 1.5;
export const COLLAPSE_MARKER_OFFSET_Y = 30;
export const LABEL_OFFSET_Y = 50;
export const LABEL_COLOR = 0xffffff;
export const LABEL_BORDER_WIDTH = 2;
const LABEL_WIDTH_OFFSET = 4;
export const LABEL_HEIGHT = 30;
export const DUPLEX_VERTICAL_OFFSET = 19;

export const getConnectorCableDrawWidth = (type: string): number => {
    switch (type) {
        case ConnMTP8_NP.type:
        case ConnMTP8_P.type:
        case ConnMTP12_NP.type:
        case ConnMTP12_P.type:
        case ConnMTP12_FP_NP.type:
        case ConnMTP12_FP_P.type:
        case ConnMPO12_NP.type:
        case ConnMPO12_P.type:
        case ConnMTP24_NP.type:
        case ConnMTP24_P.type:
        case ConnMTP24_FP_NP.type:
        case ConnMTP24_FP_P.type:
            return 8;
        default:
            return 2;
    }
};

const legEqual = (prev: IConnectorLegProps, next: IConnectorLegProps) => {
    const location = pointEqual(prev.location, next.location);
    const legEndPoint = pointEqual(prev.legEndpoint, next.legEndpoint);
    const staggerPosition = prev.staggerPosition === next.staggerPosition;
    const text = prev.text === next.text;
    const connectorPosition = prev.connectorPosition === next.connectorPosition;
    const connectorColor = prev.connectorColor === next.connectorColor;
    const labelColor = prev.labelColor === next.labelColor;

    return location && legEndPoint && staggerPosition && text && connectorPosition && connectorColor && labelColor;
};

export const areFurcationEqual = (
    { legProps: prev }: PropsWithChildren<IFurcationProps>,
    { legProps: next }: PropsWithChildren<IFurcationProps>
) => {
    if (prev.length !== next.length) return false;
    return prev.every((p, i) => legEqual(p, next[i]));
};

export function getSpriteLocation(type: string, location: IPoint) {
    const { width } = getConnectorTexture(type);
    return { x: location.x - width * 0.5, y: location.y };
}

export function getConnectorText(index: number, spriteCount: number, connectorCount: number) {
    if (index + 1 === spriteCount - 1) {
        return "...";
    } else if (index + 1 < spriteCount) {
        return (index + 1).toString();
    } else {
        return connectorCount.toString();
    }
}

export function getLegLabelDimensions(cableWidth: number) {
    return {
        width: cableWidth + LABEL_WIDTH_OFFSET,
        height: LABEL_HEIGHT,
    };
}

import React, { useState, useCallback, useRef } from "react";
import { IconButtonProps, TextFieldProps } from "@corning-ctcm/silica-react";
import { ICustomTextFieldProps } from "../../../models/pixi/text-input";

export const useCustomTextField = (props: ICustomTextFieldProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [value, setValue] = useState(props.value);

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value), []);
    const onConfirm = useCallback(() => props.onConfirm(value), [value, props]);

    const textFieldProps: TextFieldProps = {
        palette: "primary",
        value,
        onChange,
    };

    const cancelProps: Omit<IconButtonProps, "icon"> = {
        palette: "error",
        mode: "main",
        placement: "bottom",
        onClick: props.onCancel,
    };

    const confirmProps: Omit<IconButtonProps, "icon"> = {
        palette: "success",
        mode: "main",
        placement: "bottom",
        disabled: value.length === 0 || value === props.value,
        onClick: onConfirm,
    };

    return {
        ref,
        textFieldProps,
        cancelProps,
        confirmProps,
    };
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    IConnectorHighlightsState,
    initialHighlightsState,
    IHighlight,
} from "./connector-highlights";

const setHighlightsReducer = (state: IConnectorHighlightsState, action: PayloadAction<IHighlight[]>) => {
    state.highlights = action.payload;
};

const addOrUpdateHighlightReducer = (state: IConnectorHighlightsState, action: PayloadAction<IHighlight>) => {
    const highlight = action.payload;
    const highlightIndex = state.highlights.findIndex(h => h.connectorId === highlight.connectorId);
    if (highlightIndex >-1) {
        state.highlights[highlightIndex] = highlight;
    } else {
        state.highlights.push(highlight)
    }
};

const removeHighlightReducer = (state: IConnectorHighlightsState, action: PayloadAction<number>) => {
    const connectorId = action.payload;
    state.highlights = state.highlights.filter(h => h.connectorId !== connectorId);
};

const setHoveredConnectorIdReducer = (
    state: IConnectorHighlightsState,
    action: PayloadAction<number>
) => {
    state.hoveredConnectorId = action.payload;
};

const setSelectedConnectorIdReducer = (
    state: IConnectorHighlightsState,
    action: PayloadAction<number>
) => {
    state.selectedConnectorId = action.payload;
};

const resetHighlightsReducer = (
    state: IConnectorHighlightsState
) => {
    state.highlights = [];
    state.hoveredConnectorId = -1
    state.selectedConnectorId = -1
};

const connectorHighlightsSlice = createSlice({
    name: "connector-highlights",
    initialState: initialHighlightsState,
    reducers: {
        setHighlights: setHighlightsReducer,
        addOrUpdateHighlight: addOrUpdateHighlightReducer,
        removeHighlight: removeHighlightReducer,
        setHoveredConnectorId: setHoveredConnectorIdReducer,
        setSelectedConnectorId: setSelectedConnectorIdReducer,
        resetHighlights: resetHighlightsReducer
    },
});

export const ConnectorHighlightsReducer = connectorHighlightsSlice.reducer;
export const {
    setHighlights,
    addOrUpdateHighlight,
    removeHighlight,
    setHoveredConnectorId,
    setSelectedConnectorId,
    resetHighlights
} = connectorHighlightsSlice.actions;

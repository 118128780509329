import React, { useContext } from "react";
import { withMemoization } from "../../memoization/memoization";
import { HorizontalTrunk } from "./horizontal-trunk/horizontal-trunk";
import { VerticalTrunk } from "./vertical-trunk/vertical-trunk";
import { CurvedTrunk } from "./curved-trunk/curved-trunk";
import { DropContext } from "../../../../store/workspace/build/build";

const DropBaseGraphics: React.FC = () => {
    const { shell } = useContext(DropContext);
    return (
        <>
            {shell === "hard" ? <VerticalTrunk /> : <CurvedTrunk />}
            <HorizontalTrunk />
        </>
    );
};

export const DropBase = withMemoization(DropBaseGraphics);

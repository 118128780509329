import { IPoint } from "../../../../models/pixi/pixi";

export enum ZoomSource {
    ZoomTool,
    WheelEvent,
    PageResize,
}

export interface IZoomState {
    zoom: ZoomData;
    scale: number;
    increment: number;
}

export type ZoomData = {
    source?: ZoomSource;
} & IPoint;

export const initialState: IZoomState = {
    zoom: {
        x: 1,
        y: 1,
    },
    increment: 0,
    scale: 1,
};

import { WorkspaceState } from "../workspace/workspace.reducers";

export const authenticationSelector = (state: WorkspaceState) => state.authentication;
export const loggedInSelector = (state: WorkspaceState) => state.authentication.loggedIn;
export const tokenExpirationSelector = (state: WorkspaceState) => state.authentication.tokenExpiration;
export const isTokenExpiredSelector = (state: WorkspaceState) => 
    state.authentication.tokenExpiration === undefined || state.authentication.tokenExpiration < Date.now() / 1000;
export const userIdSelector = (state: WorkspaceState) => state.authentication.user.platformId;
export const hybrisCustomerSelector = (state: WorkspaceState) => state.authentication.customer;
export const isAnonymousUserSelector = (state: WorkspaceState) => !!state.authentication.customer?.isAnonymous;
export const showPullingGripSelector = (state: WorkspaceState) => state.authentication.user.settings.showPullingGrip;
export const rolesSelector = (state: WorkspaceState) => state.authentication.roles;
import { IconButtonProps, TextFieldProps } from "@corning-ctcm/silica-react";
import { ChangeEvent, KeyboardEvent, useCallback, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationKeys } from "../../../../locales/keys";
import { setViewportContext } from "../../../../store/pixi/viewport/viewport.reducers";
import { ViewportStatus } from "../../../../store/pixi/viewport/viewport";
import { setBuildLoaded, setCurrentBuild } from "../../../../store/workspace/build/build.reducers";
import { useWizardPreset } from "../../../workspace/build/wizard-preset.hooks";
import { totalBuildCountSelector } from "../../../../store/workspace/project-manager/project-manager.selectors";
import { AppDispatch } from "../../../../store/workspace/workspace.reducers";
import { setSessionWarnings, storeBuildSession } from "../../../../store/workspace/ssc/ssc.reducer";
import { buildsPageIndexSelector } from "../../../../store/workspace/build.selectors";
import { currentBuildSelector, projectManagerDataSelector } from "../../../../store/workspace/root.selectors";
import { ProjectManagerService } from "../../../../services/project-manager.service";
import { useWorkspace } from "../../header/status/status.hooks";
import { setIsLocked, setStatusState } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import { WorkspaceStatus } from "../../../../store/overlay/header/status-icon/status-icon";
import { saveBuildData } from "../../../../store/overlay/wizard/wizard.reducers";
import { updateSearch, updateSortOrder, updateSortType } from "../../../../store/overlay/project/project.reducers";
import { useProjectDrawer } from "../project-drawer.hooks";
import {
    setReachedLastPage,
    setReloading,
    setSearch,
} from "../../../../store/overlay/project/project-drawer/project-drawer.reducers";
import { ProjectDrawerContext } from "../../../../store/overlay/project/project-drawer/project-drawer";
import {
    setPrimaryUnit,
    setUnit,
} from "../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.reducers";
import { PrimaryUnit, Unit } from "../../../../models/overlay/header/units-of-measure";
import { defaultBoundariesValuesSelector } from "../../../../store/workspace/boundaries/boundaries.selectors";
import { SelectChangeEvent } from "@mui/material";

const SEARCH_DELAY_MS = 300;

export const useDrawerSubHeader = () => {
    const { cableLabel } = useCableLabel();
    const { searchTextFieldProps, onClear } = useSearchBar();
    const { sortType, isSortAscending, onSelectChange, sortAscProps, sortDesProps } = useCableSorter();
    const { triggerCreate } = useCreateButton();

    return {
        cableLabel,
        sortType,
        isSortAscending,
        searchTextFieldProps,
        onSelectChange,
        sortAscProps,
        sortDesProps,
        triggerCreate,
        onClear
    };
};

export const useSearchBar = () => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(ProjectDrawerContext);
    const [searchField, setSearchField] = useState(state.search);
    const timer = useRef<NodeJS.Timeout | undefined>();
    const storeDispatch = useDispatch<AppDispatch>();
    const currentActiveRequest = useRef<ProjectManagerService | undefined>();

    const onSearch = useCallback(
        async (search: string) => {
            dispatch(setReloading(true));
            await storeDispatch(updateSearch(search, currentActiveRequest));
            if (!currentActiveRequest.current) {
                // Only change loading state when there are no pending search requests
                dispatch(setReloading(false));
                dispatch(setReachedLastPage(false));
                dispatch(setSearch(search));
            }
        },
        [dispatch, storeDispatch]
    );

    const onClear = useCallback(() => {
        setSearchField("");
        clearTimeout(timer.current);
        onSearch("");
    }, [onSearch]);

    const onChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            setSearchField(e.target.value);
            clearTimeout(timer.current);
            timer.current = setTimeout(() => onSearch(e.target.value), SEARCH_DELAY_MS);
        },
        [onSearch, timer]
    );

    const onKeyDown = useCallback(
        async (e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
                await onSearch(searchField);
            }
        },
        [onSearch, searchField]
    );

    const searchTextFieldProps: TextFieldProps = {
        palette: "primary",
        value: searchField,
        placeholder: t(LocalizationKeys.SearchByNameOfDesigner),
        size: "small",
        adornmentPosition: "end",
        autoComplete: "off",
        onChange,
        onKeyDown,
    };

    return { searchTextFieldProps, onSearch, onClear };
};

export const useCableLabel = () => {
    const totalBuildCount = useSelector(totalBuildCountSelector);
    const { t } = useTranslation();
    const cableLabel = `${totalBuildCount} ${t(LocalizationKeys.Cables)}`;
    return { cableLabel };
};

export const useCreateButton = () => {
    const currentBuild = useSelector(currentBuildSelector);
    const { setWorkspace } = useWorkspace();
    const { onDrawerClose } = useProjectDrawer();
    const { selectedPresetBuild } = useWizardPreset();
    const defaultValues = useSelector(defaultBoundariesValuesSelector);
    const dispatch = useDispatch();

    const triggerCreate = useCallback(async () => {
        const primaryUnit = (selectedPresetBuild.primaryUnit ??
            defaultValues.defaultPrimaryMeasurementSystem) as PrimaryUnit;
        const unit = (selectedPresetBuild.unit ?? defaultValues.defaultUnit) as Unit;
        dispatch(storeBuildSession(currentBuild?.id ?? 0));
        dispatch(setSessionWarnings({ buildId: 0, warnings: [] }));
        dispatch(setViewportContext(ViewportStatus.Creating));
        dispatch(saveBuildData(currentBuild));
        dispatch(setCurrentBuild({ ...selectedPresetBuild }));
        dispatch(setPrimaryUnit(primaryUnit));
        dispatch(setUnit(unit));
        dispatch(setStatusState(WorkspaceStatus.Ready));
        dispatch(setIsLocked(false));
        dispatch(setBuildLoaded(true));
        setWorkspace();
        onDrawerClose();
    }, [currentBuild, defaultValues, selectedPresetBuild, setWorkspace, onDrawerClose, dispatch]);

    return { triggerCreate };
};

export const useCableSorter = () => {
    const { sortType, isSortAscending } = useSelector(projectManagerDataSelector);
    const pageIndex = useSelector(buildsPageIndexSelector);
    const {
        state: { search },
        dispatch,
    } = useContext(ProjectDrawerContext);
    const { t } = useTranslation();

    const storeDispatch = useDispatch<AppDispatch>();

    const onSelectChange = useCallback(
        async (e: SelectChangeEvent<string>) => {
            const newType = e.target.value;
            dispatch(setReloading(true));
            await storeDispatch(updateSortType(newType, pageIndex, search));
            dispatch(setReloading(false));
        },
        [dispatch, storeDispatch, pageIndex, search]
    );

    const triggerSort = useCallback(async () => {
        const newOrder = !isSortAscending;
        dispatch(setReloading(true));
        await storeDispatch(updateSortOrder(newOrder, pageIndex, search));
        dispatch(setReloading(false));
    }, [isSortAscending, dispatch, storeDispatch, pageIndex, search]);

    const sortAscProps: Omit<IconButtonProps, "icon"> = {
        id: "sort-ascending-icon",
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.SortAscending),
        placement: "top",
        onClick: triggerSort,
    };

    const sortDesProps: Omit<IconButtonProps, "icon"> = {
        id: "sort-descending-icon",
        palette: "primary",
        mode: "main",
        title: t(LocalizationKeys.SortDescending),
        placement: "top",
        onClick: triggerSort,
    };

    return { sortType, isSortAscending, onSelectChange, sortAscProps, sortDesProps };
};

export function areArraysEquals(a: any[], b: any[]) {
    if (a.length !== b.length) {
        return false;
    }

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}

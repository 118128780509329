import React from "react";
import { useSelector } from "react-redux";
import { getUnitsName } from "../../../models/overlay/header/units-of-measure";
import { unitsOfMeasureContainerUnitSelector } from "../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";

import "../../../css/overlay/footer/footer.scss";

export default function CurrentUnitsTool() {
    const unit = useSelector(unitsOfMeasureContainerUnitSelector);

    return (
        <div className="controls-container current-units-container toggle-pointer-events">
            <p className="unit-label">Unit: {getUnitsName(unit, true)}</p>
        </div>
    );
}

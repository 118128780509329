import { useContext, useCallback, Dispatch, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToolbarDisplay, setSelection } from "../../../../../store/overlay/footer/toolbar/toolbar.reducers";
import { toolbarSelectionSelector } from "../../../../../store/overlay/footer/toolbar/toolbar.selectors";
import {
    setCurrentStep,
    setWizardDisplay,
    enableNextStep,
    saveBuildData,
} from "../../../../../store/overlay/wizard/wizard.reducers";
import { overlaySelector } from "../../../../../store/overlay/overlay.selectors";
import { DropContext, BuildContext, IBuildData } from "../../../../../store/workspace/build/build";
import { useSscConfigSession } from "../../../../workspace/ssc.hooks";
import { sscConfigSessionBusySelector } from "../../../../../store/workspace/ssc/ssc.selectors";
import { setViewportContext } from "../../../../../store/pixi/viewport/viewport.reducers";
import { viewportContextSelector } from "../../../../../store/pixi/viewport/viewport.selectors";
import { ViewportStatus, ViewportStatusTypes } from "../../../../../store/pixi/viewport/viewport";
import {
    IRectangleDrawingProps,
    IRectangleLayout,
} from "../../../../../models/pixi/decorators/bounds/interactive-rectangle";
import { BoundingBoxContext } from "../../../../../models/pixi/decorators/bounds/bounding-box";
import { toDecimalHex, DEFAULT_BLUE, GREY } from "../../../../../store/pixi/connector-highlights/connector-highlights";
import { currentStatusSelector } from "../../../../../store/overlay/header/status-icon/status-icon.selectors";
import { WorkspaceStatus } from "../../../../../store/overlay/header/status-icon/status-icon";
import { currentBuildFinalizedSelector } from "../../../../../store/workspace/build.selectors";
import { wizardDisplaySelector } from "../../../../../store/overlay/wizard/wizard.selectors";
import { BoundingBoxContainer } from "../../../../../models/pixi/build/build";
import { getInteractiveEventMode } from "../../../../../models/pixi/pixi";

export const useInteractions = () => {
    const { bounds, isMouseOver } = useContext(BoundingBoxContext);

    const dispatch = useDispatch();
    const { position, side, containerName } = useContext(DropContext);
    const { selected: stateSelected, side: sideSelected } = useSelector(toolbarSelectionSelector);
    const { showConnectorAssignment: isConnectorAssignment, showFiberMapping: isFiberMapping } =
        useSelector(overlaySelector);
    const currentStatus = useSelector(currentStatusSelector);
    const currentBuild = useContext(BuildContext);
    const sessionBusy = useSelector(sscConfigSessionBusySelector);
    const displayWizard = useSelector(wizardDisplaySelector);
    const { updateConfigSessionConfigurationType } = useSscConfigSession();
    const { isDragging, context } = useSelector(viewportContextSelector);

    const configType = currentBuild.configurationType;
    const getConfigSession = useCallback(async () => {
        await updateConfigSessionConfigurationType(configType);
    }, [updateConfigSessionConfigurationType, configType]);

    const onSingleClick = useCallback(
        () => dispatchSelection(dispatch, stateSelected, position, sideSelected, side, isDragging, context),
        [dispatch, stateSelected, position, sideSelected, side, isDragging, context]
    );
    const onDoubleClick = useCallback(
        () => dispatchEditMode(dispatch, context, position, side, currentBuild, sessionBusy, getConfigSession),
        [dispatch, context, position, side, currentBuild, sessionBusy, getConfigSession]
    );
    const { triggerClick } = useClickEvents(onSingleClick, onDoubleClick);
    const onMouseClick = useCallback(() => triggerClick(), [triggerClick]);

    const selectionBoxProps: IRectangleDrawingProps = useMemo(() => {
        const disabled = context === "editing" && !displayWizard;
        const display =
            context !== ViewportStatus.Creating &&
            !disabled &&
            currentStatus !== WorkspaceStatus.Reporting &&
            currentStatus !== WorkspaceStatus.Printing &&
            !isConnectorAssignment &&
            !isFiberMapping;
        const selected = stateSelected === position && sideSelected === side;
        const name = `${containerName}-${BoundingBoxContainer}`;
        const layout: IRectangleLayout = getSelectionLayout(selected, isMouseOver);

        return { display, name, eventMode: getInteractiveEventMode(display), selected, bounds, layout, onMouseClick };
    }, [
        containerName,
        currentStatus,
        isConnectorAssignment,
        isFiberMapping,
        displayWizard,
        context,
        bounds,
        position,
        isMouseOver,
        stateSelected,
        onMouseClick,
        side,
        sideSelected,
    ]);

    return selectionBoxProps;
};

function getSelectionLayout(selected: boolean, isMouseOver: boolean): IRectangleLayout {
    const color = selected ? toDecimalHex(DEFAULT_BLUE.fill) : toDecimalHex(GREY.fill);
    const alpha = selected || isMouseOver ? 1 : 0;

    return { borderColor: color, color, alpha };
}

function dispatchSelection(
    dispatch: Dispatch<any>,
    selected: number,
    position: number,
    sideSelected: string | undefined,
    side: string,
    isDragging: boolean,
    context: ViewportStatusTypes
) {
    if (!isDragging && context !== ViewportStatus.Editing && context !== ViewportStatus.ConnectorReport) {
        if (selected !== position || sideSelected !== side) {
            dispatch(setSelection({ position, side }));
        } else {
            dispatch(setSelection({ position: -1 }));
        }
    }
}

function dispatchEditMode(
    dispatch: Dispatch<any>,
    context: ViewportStatusTypes,
    position: number,
    side: string,
    currentBuild: IBuildData,
    sessionBusy: boolean,
    getConfigSession: () => Promise<any>
) {
    if (sessionBusy) return;
    getConfigSession().then((_) => {
        if (context !== ViewportStatus.Editing && context !== ViewportStatus.ConnectorReport) {
            dispatch(setSelection({ position, side }));
            if (side === "feeder") {
                dispatch(setCurrentStep!(1));
            } else {
                dispatch(setCurrentStep!(2));
            }
            dispatch(setViewportContext(ViewportStatus.Editing));
            dispatch(setWizardDisplay(true));
            dispatch(enableNextStep(false));
            dispatch(saveBuildData(currentBuild));
            dispatch(setToolbarDisplay(false));
        }
    });
}

const useClickEvents = (triggerSingleClick: () => void, triggerDoubleClick: () => void) => {
    const buildFinalized = useSelector(currentBuildFinalizedSelector);
    let delay = 250;
    let nbClicks = useRef(0);
    const triggerClick = useCallback(() => {
        nbClicks.current++;
        setTimeout(() => {
            if (nbClicks.current === 1) {
                triggerSingleClick();
            } else if (nbClicks.current === 2) {
                if (!buildFinalized) {
                    triggerDoubleClick();
                }
            }
            nbClicks.current = 0;
        }, delay);
    }, [triggerSingleClick, buildFinalized, triggerDoubleClick, delay]);

    return { triggerClick };
};

import { CheckboxProps } from "@corning-ctcm/silica-react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../locales/keys";
import { DestSrcState, IReverseStaggeringProps } from "../../../../../models/overlay/wizard/setup/reverse-staggering";

export const useReverseStaggering = <T extends DestSrcState>({
    disabled,
    applyChanges,
    dispatch,
    validate,
    state,
    setState,
}: IReverseStaggeringProps<T>) => {
    const { sLength, reverseStaggering } = state;
    const { t } = useTranslation();

    const onReverseStaggeringCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.currentTarget.checked;
            const newState = { ...state, customBLength: false, reverseStaggering: checked };
            dispatch(setState(applyChanges(validate(newState))));
        },
        [state, dispatch, setState, validate, applyChanges]
    );

    const reverseStaggeringProps: CheckboxProps = {
        id: "reverse-staggering-checkbox",
        label: t(LocalizationKeys.ReverseStaggering),
        palette: "primary",
        placement: "end",
        value: reverseStaggering,
        checked: reverseStaggering,
        disabled: disabled || sLength.value === 0,
        onChange: onReverseStaggeringCheckboxChange,
    };

    return reverseStaggeringProps;
};

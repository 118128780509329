import { Typography } from "@corning-ctcm/silica-react";
import { useCreationInfo } from "./creation-info.hooks";

import "../../../../../css/overlay/project-drawer/project-manager-row/creation-info.scss";

export const CreationInfo = (props: { projectId: number }) => {
    const { projectId } = props;
    const { creationInfoString } = useCreationInfo(projectId);

    return <Typography variant="caption" color="text.secondary" className="creation-info">{creationInfoString}</Typography>
};

import { Dialog } from "@corning-ctcm/silica-react";
import { useThirdPartyCopyrightDialog } from "./third-party-copyright-dialog.hooks";
import { DialogHeader } from "../../../ui/dialog/dialog-header/dialog-header";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";

import "../../../../css/overlay/about-this-application/third-party-copyright-dialog.scss";

export const ThirdPartyCopyrightDialog = () => {
    const { t } = useTranslation();
    const { showThirdPartyCopyright, licenses, dialogHeaderProps, closeDialog } = useThirdPartyCopyrightDialog();

    return showThirdPartyCopyright ? (
        <Dialog
            id="third-party-copyright"
            open={showThirdPartyCopyright}
            onClose={closeDialog}
            preventOutsideDismiss={true}
            className="third-party-copyright"
        >
            <DialogHeader {...dialogHeaderProps} />
            <div className="third-party-copyright-content">
                <Table>
                    <TableHead>
                        <TableRow className="table-header-font">
                            <TableCell align="left">{t(LocalizationKeys.Components)}</TableCell>
                            <TableCell align="left">{t(LocalizationKeys.Version)}</TableCell>
                            <TableCell align="left">{t(LocalizationKeys.Disclaimer)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {licenses.map((x) => {
                            return (
                                <TableRow key={x.key}>
                                    <TableCell className="component-name-font" align="left">
                                        <a id={`${x.components.replaceAll(" ", "-").toLowerCase()}-link`} href={x.link}>
                                            {x.components}
                                        </a>
                                    </TableCell>
                                    <TableCell align="left">{x.version}</TableCell>
                                    <TableCell align="left">{x.disclaimer}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </div>
        </Dialog>
    ) : null;
};

import { WebService } from './abstract-web-v2.service';
import { Utils } from "../utils";
import { getToken } from '@corning-ctcm/platform-client';

export abstract class SecuredService extends WebService {
    constructor() {
        super();
        if (Utils.isDevelopmentEnvironment()) {
            const soldTo = (window as any).soldTo;
            if (soldTo) {
                this.addDynamicHeader("SoldTo", () => soldTo);
            }
        } else if (Utils.isHybrisEnvironment()) {
            const hybrisCustomer = (window as any).hybriscustomer;
            const { customerUid, accountUid, accountName, soldTo, unitOfMeasure } = hybrisCustomer;
            this.addDynamicHeader("X-Hybris-UID", () => customerUid ?? null);
            this.addDynamicHeader("X-Hybris-AccountName", () => accountName ?? null);
            this.addDynamicHeader("X-Hybris-AccountUid", () => accountUid ?? null);
            this.addDynamicHeader("X-Hybris-SoldTo", () => soldTo ?? null);
            this.addDynamicHeader("X-Hybris-UOM", () => (unitOfMeasure === "FOT" ? "ft" : "m"));
        }

        this.addDynamicHeader("Authorization", () => {
            const token = getToken();
            if (!token) {
                return null;
            }
            return "Bearer " + token;
        });
    }
}

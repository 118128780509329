import {
    DimensionLineContext,
    overviewReportStyle,
} from "../../../models/pixi/decorators/dimension-lines/dimension-lines";
import { BuildContext } from "../../../store/workspace/build/build";
import { Cable } from "../build/cable";
import { Container } from "@pixi/react";
import { DocumentGraphicsContainer } from "../../../models/pixi/document-graphics";
import { useDocumentGraphics } from "./document-graphics.hooks";
import { DropGraphic } from "./drop-graphic";

export const DocumentGraphics = () => {
    const { display, buildContext, overviewGraphics, dropGraphics } = useDocumentGraphics();

    if (!display) return null;

    return (
        <Container name={DocumentGraphicsContainer}>
            <BuildContext.Provider value={buildContext}>
                <DimensionLineContext.Provider value={overviewReportStyle}>
                    <Cable {...overviewGraphics} />
                </DimensionLineContext.Provider>
                {dropGraphics.map(({ key, ...d }) => (
                    <DropGraphic key={key} {...d} />
                ))}
            </BuildContext.Provider>
        </Container>
    );
};

import { Dispatch, createContext } from "react";
import { Action } from "redux";
import { IConnectorData } from "../../workspace/build/connector/connector";
import { IFiberMapIndex } from "../../workspace/build/connector/polarity/fiber-map";

export interface IConnectorAssignment {
    fiberMapId: number;
    feederConnectors: number[];
    distributionConnectors: number[];
}

export interface IConnectorAssignmentData {
    assignmentIndex: number;
    fiberMapId: number;
    feederConnectors: IConnectorData[];
    distributionConnectors: IConnectorData[];
}

const initialConnectorAssignment: IConnectorAssignmentData = {
    assignmentIndex: -1,
    fiberMapId: -1, 
    feederConnectors: [],
    distributionConnectors: []
}

export interface IPolarityData {
    name: string;
    feeder: {
        connectorIndices: number[];
        connectors: IConnectorData[];
        pins: IFiberMapIndex[];
    }
    distribution: {
        connectorIndices: number[];
        connectors: IConnectorData[];
        pins: IFiberMapIndex[];
    }
}

export interface IPolarityState {
    fromConnectorId: number; // The connector id from which we will view the fiber mapping
    connectorAssignment: IConnectorAssignmentData;
}

export const initialPolarityState: IPolarityState = {
    fromConnectorId: -1,
    connectorAssignment: initialConnectorAssignment,
};

export interface IPolarityContext {
    state: IPolarityState;
    dispatch: Dispatch<Action>;
}

export const initialPolarityContext: IPolarityContext = {
    state: initialPolarityState,
    dispatch: () => {},
};

export const PolarityContext = createContext(initialPolarityContext);
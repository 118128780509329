import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IFiberMap, IFiberMappingState, IPinMap, initialFiberMappingState, initialNavigationMap } from "./fiber-mapping-save";
import { Feeder } from "../../../../models/pixi/build/build";

const setFeederNavigationAction = (state: IFiberMappingState, action: PayloadAction<number[]>) => {
    state.navigation.feeder.items = action.payload;
};

const setFeederItemAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    state.navigation.feeder.currentItem = action.payload;
    state.navigation.selectedPinIndex = -1;
};

const setTAPNavigationAction = (state: IFiberMappingState, action: PayloadAction<number[]>) => {
    state.navigation.distribution.taps.items = action.payload;
};

const setTAPItemAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    state.navigation.distribution.taps.currentItem = action.payload;
};

const setTAPConnectorsNavigationAction = (state: IFiberMappingState, action: PayloadAction<number[]>) => {
    state.navigation.distribution.connectors.items = action.payload;
};

const setTAPConnectorsItemAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    state.navigation.distribution.connectors.currentItem = action.payload;
};

const setSelectedPinIndexAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    state.navigation.selectedPinIndex = action.payload;
};

const setFiberMappingAction = (state: IFiberMappingState, action: PayloadAction<IFiberMap[]>) => {
    state.mapping = action.payload;
};

const handleFiberMapAction = (state: IFiberMappingState, action: PayloadAction<number>) => {
    const unusedSourcePin = state.unused.sourcePins.find(
        (p) =>
            p.index === state.navigation.selectedPinIndex && p.connectorId === state.navigation.feeder.currentItem
    );
    if (!unusedSourcePin) {
        const destinationPinIndex = action.payload;
        const fiberMap: IFiberMap = {
            feederPin: {
                index: state.navigation.selectedPinIndex,
                connectorId: state.navigation.feeder.currentItem,
            },
            distributionPin: {
                index: destinationPinIndex,
                connectorId: state.navigation.distribution.connectors.currentItem,
            },
        };
        const existingFiberMap = state.mapping.find(
            (m) =>
                m.feederPin.index === fiberMap.feederPin.index &&
                m.feederPin.connectorId === fiberMap.feederPin.connectorId
        );
        state.isModified = true;
        if (existingFiberMap) {
            existingFiberMap.distributionPin.index = destinationPinIndex;
        } else {
            state.mapping.push(fiberMap);
        }
        state.navigation.selectedPinIndex = -1;
    }
};

const breakFiberMapAction = (state: IFiberMappingState) => {
    const feederConnectorId = state.navigation.feeder.currentItem;
    const distributionConnectorId = state.navigation.distribution.connectors.currentItem;
    const feederPinIndex = state.navigation.selectedPinIndex;
    const existingFiberMap = state.mapping.find(
        (m) =>
            m.feederPin.index === feederPinIndex &&
            m.feederPin.connectorId === feederConnectorId &&
            m.distributionPin.connectorId === distributionConnectorId
    );
    if (existingFiberMap) {
        state.mapping = state.mapping.filter((m) => m !== existingFiberMap);
        state.navigation.selectedPinIndex = -1;
        state.isModified = true;
    }
};

const handleUnusedPinAction = (
    state: IFiberMappingState,
    action: PayloadAction<{ section: string; pinIndex: number }>
) => {
    const { section, pinIndex } = action.payload;
    if (section === Feeder) {
        const pin: IPinMap = {
            index: pinIndex,
            connectorId: state.navigation.feeder.currentItem,
        };
        const existingUnusedPin = state.unused.sourcePins.find(
            (p) => p.index === pin.index && p.connectorId === pin.connectorId
        );
        if (existingUnusedPin) {
            const filteredPins = state.unused.sourcePins.filter((p) => p !== existingUnusedPin);
            state.isModified = filteredPins.length !== state.unused.sourcePins.length;
            state.unused.sourcePins = filteredPins.filter((p) => p !== existingUnusedPin);
        } else {
            state.unused.sourcePins.push(pin);
            state.isModified = true;
        }
    } else {
        const pin: IPinMap = {
            index: pinIndex,
            connectorId: state.navigation.distribution.connectors.currentItem,
        };
        const existingUnusedPin = state.unused.destinationPins.find(
            (p) => p.index === pin.index && p.connectorId === pin.connectorId
        );
        if (existingUnusedPin) {
            const filteredPins = state.unused.destinationPins.filter((p) => p !== existingUnusedPin);
            state.isModified = filteredPins.length !== state.unused.destinationPins.length;
            state.unused.destinationPins = filteredPins;
        } else {
            state.unused.destinationPins.push(pin);
            state.isModified = true;
        }
    }
};

const clearFiberMapAction = (state: IFiberMappingState) => {
    state.navigation.selectedPinIndex = -1;
    state.mapping = [];
    state.unused = {
        sourcePins: [],
        destinationPins: [],
    };
    state.isModified = false;
};

const resetNavigationAction = (state: IFiberMappingState) => {
    state.navigation.feeder = initialNavigationMap;
    state.navigation.distribution.taps = initialNavigationMap;
    state.navigation.distribution.connectors = initialNavigationMap;
};

const fiberMappingSlice = createSlice({
    initialState: initialFiberMappingState,
    name: "fiberMapping",
    reducers: {
        setFeederNavigation: setFeederNavigationAction,
        setFeederItem: setFeederItemAction,
        setTAPNavigation: setTAPNavigationAction,
        setTAPItem: setTAPItemAction,
        setTAPConnectorsNavigation: setTAPConnectorsNavigationAction,
        setTAPConnectorsItem: setTAPConnectorsItemAction,
        setSelectedPinIndex: setSelectedPinIndexAction,
        setFiberMapping: setFiberMappingAction,
        handleFiberMap: handleFiberMapAction,
        breakFiberMap: breakFiberMapAction,
        handleUnusedPin: handleUnusedPinAction,
        clearFiberMap: clearFiberMapAction,
        resetNavigation: resetNavigationAction,
    },
});

export const FiberMappingReducer = fiberMappingSlice.reducer;
export const {
    setFeederNavigation,
    setFeederItem,
    setTAPNavigation,
    setTAPItem,
    setTAPConnectorsNavigation,
    setTAPConnectorsItem,
    setSelectedPinIndex,
    setFiberMapping,
    handleFiberMap,
    breakFiberMap,
    handleUnusedPin,
    clearFiberMap,
    resetNavigation,
} = fiberMappingSlice.actions;

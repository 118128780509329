import { AlertPalettes } from "@corning-ctcm/silica-react";
import { AlertPalette } from "@corning-ctcm/silica-react/build/components/snackbar/snackbar";

export interface INotificationMessage {
    message: string;
    palette: AlertPalette;
}

export interface INotificationManagerState {
    display: boolean;
    notification: INotificationMessage;
}

export const initialNotificationManagerState: INotificationManagerState = {
    display: false,
    notification: {
        message: "",
        palette: AlertPalettes.error,
    },
};

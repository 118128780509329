import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Drawer } from "../../ui/drawer/drawer";
import { LocalizationKeys } from "../../../locales/keys";
import CorningLogo from "../../../resources/svgs/overlay/Corning_Logo_301Grey.svg";
import { DebugMenu } from "./debug-menu/debug-menu";
import { ProjectManagerRow } from "./project-manager-row/project-manager-row";
import { DrawerSubHeader } from "./drawer-sub-header/drawer-sub-header";
import { useProjectDrawer } from "./project-drawer.hooks";
import { ProjectDrawerContext } from "../../../store/overlay/project/project-drawer/project-drawer";
import { CircularProgressIndeterminate } from "@corning-ctcm/silica-react";

import "../../../css/overlay/project-drawer/project-drawer.scss";

export const ProjectDrawer = () => {
    const { t } = useTranslation();
    const {
        open,
        projects,
        loading,
        reachedLastPage,
        reloading,
        drawerStyle,
        context,
        containerRef,
        onDrawerClose,
        onScroll,
    } = useProjectDrawer();
    return (
        <ProjectDrawerContext.Provider value={context}>
            <Drawer id="project-drawer" open={open} onClose={onDrawerClose} logo={CorningLogo} style={drawerStyle}>
                <DrawerSubHeader />
                <Divider />
                <div className="rows-container" onScroll={onScroll} ref={containerRef}>
                    {reloading
                        ? null
                        : projects.map(({ projectId, selected, deleteDisabled, editDisabled }, index) => {
                              return (
                                  <ProjectManagerRow
                                      key={index}
                                      projectId={projectId}
                                      selected={selected}
                                      deleteDisabled={deleteDisabled}
                                      editDisabled={editDisabled}
                                  />
                              );
                          })}
                    {!reachedLastPage && !loading ? (
                        <span className="scroll">{t(LocalizationKeys.LoadMoreCables)}</span>
                    ) : null}
                    {loading ? (
                        <div className="circular-progress-container">
                            <CircularProgressIndeterminate palette="primary" />
                        </div>
                    ) : null}
                    <DebugMenu />
                </div>
            </Drawer>
        </ProjectDrawerContext.Provider>
    );
};

import { createContext } from "react";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
    IDestinationSetupContext,
    IDestinationSetupState,
    initialDestinationSetupContext,
    initialDestinationSetupState,
} from "./destination-setup";

export const setStateReducer = (state: IDestinationSetupState, action: PayloadAction<IDestinationSetupState>) => {
    Object.assign(state, action.payload);
};

const destinationSetupSlice = createSlice({
    initialState: initialDestinationSetupState,
    name: "destination-setup",
    reducers: {
        setState: setStateReducer,
    },
});

export const DestinationSetupReducer = destinationSetupSlice.reducer;
export const DestinationSetupContext = createContext<IDestinationSetupContext>(initialDestinationSetupContext);

export const { setState } = destinationSetupSlice.actions;

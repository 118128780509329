import React from "react";
import { useWizardFooter } from "./wizard-footer.hooks";
import { OutlinedButton, ContainedButton, TextButton } from "@corning-ctcm/silica-react";
import { GenericDialog } from "../../../ui/dialog/generic-dialog";

import "../../../../css/overlay/wizard/wizard-footer.scss";

export const WizardFooter = () => {
    const {
        footerClassName,
        previousClassName,
        previousHidden,
        previousOutlined,
        onPreviousClick,
        previousText,
        nextClassName,
        onNextClick,
        nextText,
        isNextEnabled,
        disabled,
        resetDialogProps,
    } = useWizardFooter();

    return (
        <div id="wizard-footer" className={footerClassName}>
            {!previousHidden ? (
                previousOutlined ? (
                    <OutlinedButton
                        id="wizard-previous-button"
                        className={previousClassName}
                        disabled={!isNextEnabled}
                        onClick={onPreviousClick}
                    >
                        <i className="material-icons">chevron_left</i>
                        {previousText}
                    </OutlinedButton>
                ) : (
                    <TextButton
                        id="wizard-previous-button"
                        palette="error"
                        className={previousClassName}
                        disabled={disabled}
                        onClick={onPreviousClick}
                    >
                        {previousText}
                    </TextButton>
                )
            ) : null}
            <ContainedButton
                id="wizard-next-button"
                className={nextClassName}
                disabled={!isNextEnabled}
                onClick={onNextClick}
            >
                {nextText}
            </ContainedButton>
            <GenericDialog {...resetDialogProps} />
        </div>
    );
};

import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../../../locales/keys";
import { useCheckBoxInput } from "../../../../../ui/checkbox/checkbox.hooks";
import { useInputField } from "../../../../../ui/input/input.hooks";
import { ProductLabel } from "../../../../../../models/overlay/reports/report-settings/labels-section";
import { useSelector } from "react-redux";
import { isLockedSelector } from "../../../../../../store/overlay/header/status-icon/status-icon.selectors";

export const useLabelFields = (
    index: number,
    label: ProductLabel,
    labelsLength: number,
    setLabel: (index: number, newLabel: ProductLabel) => void
) => {
    const { t } = useTranslation();
    const { description, note, type, size } = label;
    const locked = useSelector(isLockedSelector);

    const labelTitle = labelsLength > 1 ? t(LocalizationKeys.LabelTitle, { number: (index + 1).toString() }) : "";

    const typeField = {
        ...useInputField(t(LocalizationKeys.LabelType), type, 20),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => setLabel(index, { ...label, type: e.target.value }),
        helperText: t(LocalizationKeys.LabelTypeHelp),
        disabled: locked
    };
    const descriptionField = {
        ...useInputField(t(LocalizationKeys.LabelDescription), description, 120),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
            setLabel(index, { ...label, description: e.target.value }),
        helperText: t(LocalizationKeys.LabelDescriptionHelp),
        disabled: locked
    };
    const sizeCheckbox = {
        ...useCheckBoxInput("label-size", t(LocalizationKeys.LabelSize), label.size !== undefined),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setLabel(index, { ...label, size: e.currentTarget.checked ? "" : undefined }),
        disabled: locked
    };
    const sizeField = {
        ...useInputField(
            t(LocalizationKeys.LabelSize),
            size ?? t(LocalizationKeys.LabelSizeNotSpecified),
            label.size === undefined ? undefined : 20
        ),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => setLabel(index, { ...label, size: e.target.value }),
        disabled: label.size === undefined || locked,
        helperText: t(LocalizationKeys.LabelSizeHelp),
    };
    const oneBarcodeCheckbox = {
        ...useCheckBoxInput("one-barcode", t(LocalizationKeys.LabelOneBarcode), label.oneBarcode),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setLabel(index, { ...label, oneBarcode: e.currentTarget.checked }),
        disabled: locked
    };
    const noteField = {
        ...useInputField(t(LocalizationKeys.LabelNotes), note, 120),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => setLabel(index, { ...label, note: e.target.value }),
        helperText: " ",
        disabled: locked
    };

    return { labelTitle, typeField, descriptionField, sizeCheckbox, sizeField, oneBarcodeCheckbox, noteField };
};

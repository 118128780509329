import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStatusState } from "../../store/overlay/header/status-icon/status-icon.reducer";
import { currentStatusSelector } from "../../store/overlay/header/status-icon/status-icon.selectors";
import { WorkspaceStatus } from "../../store/overlay/header/status-icon/status-icon";
import { setShowIdle } from "../../store/overlay/overlay.reducers";
import { AppDispatch, store } from "../../store/workspace/workspace.reducers";
import { FIVE_MINS, FOUR_HOURS, MS_MULTIPLIER } from "../../models/timer";
import { usePlatformService } from "@corning-ctcm/platform-client";
import { tokenExpirationSelector } from "../../store/authentication/authentication.selectors";

export const useActivityTracker = () => {
    const status = useSelector(currentStatusSelector);
    const tokenExpiration = useSelector(tokenExpirationSelector);
    const platformService = usePlatformService();
    const expiration = tokenExpiration ? tokenExpiration * MS_MULTIPLIER : FOUR_HOURS * MS_MULTIPLIER;
    const expirationDate = new Date(expiration);
    const timeUntilExpiration = expirationDate.getTime() - Date.now();

    // Listen for changes on the workspace
    const workspace = store.getState();

    const changeRef = useRef(false);
    const clickRef = useRef(false);

    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);
    const storeDispatch = useDispatch<AppDispatch>();

    const onTimeout = useCallback(async () => {
        if (changeRef.current && clickRef.current) {
            changeRef.current = false;
            await platformService.refreshToken();
        } else {
            storeDispatch(setShowIdle(true));
            storeDispatch(setStatusState(WorkspaceStatus.Inactive));
        }
        clickRef.current = false;
        setTimeoutId(undefined);
    }, [platformService, storeDispatch]);

    // Detect user activity
    useEffect(() => {
        window.addEventListener("click", () => {
            clickRef.current = true;
        });
        if (status !== WorkspaceStatus.Inactive) {
            changeRef.current = true;
        }

        return () => {
            window.removeEventListener("click", () => (clickRef.current = true));
        };
    }, [status, workspace]);

    // Handle idling
    useEffect(() => {
        if (status !== WorkspaceStatus.Inactive && !timeoutId) {
            changeRef.current = false;

            const id = setTimeout(onTimeout, timeUntilExpiration - FIVE_MINS * MS_MULTIPLIER);
            setTimeoutId(id);
        }
    }, [status, expiration, timeoutId, onTimeout, timeUntilExpiration]);
};

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import { useProjectManager } from "../../../../workspace/project-manager.hooks";
import { useProjectDrawer } from "../../project-drawer.hooks";
import { setBuildLoaded } from "../../../../../store/workspace/build/build.reducers";
import { setStatusState } from "../../../../../store/overlay/header/status-icon/status-icon.reducer";
import { WorkspaceStatus } from "../../../../../store/overlay/header/status-icon/status-icon";
import { IDragHandleProps } from "../../../../../models/overlay/project-drawer/project-manager-row/project-manager-row";
import { currentBuildSelector } from "../../../../../store/workspace/root.selectors";

export const useDragHandle = ({ projectId, editDisabled }: IDragHandleProps) => {
    const id = `project-${projectId}-load-button`;
    const { className, onLoad } = useLoadProject(projectId, editDisabled);
    return { id, className, onLoad };
};

export const useLoadProject = (projectId: number, editDisabled = false) => {
    const dispatch = useDispatch();
    const { onDrawerClose } = useProjectDrawer();
    const { updateRecentProject, loadRecentProject } = useProjectManager();
    const currentBuild = useSelector(currentBuildSelector);
    const className = editDisabled ? "lock-icon" : "rab-icon";

    const onLoad = useCallback(async () => {
        if (currentBuild && projectId !== currentBuild.id) {
            dispatch(setStatusState(WorkspaceStatus.Busy));
            dispatch(setBuildLoaded(false));
            updateRecentProject(projectId);
            loadRecentProject(projectId);
        }

        onDrawerClose();
    }, [onDrawerClose, updateRecentProject, loadRecentProject, dispatch, currentBuild, projectId]);

    return { className, onLoad };
};

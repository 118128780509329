import { useDispatch, useSelector } from "react-redux";
import { ICollapsibleDialogProps } from "../../../../models/ui/dialog/collapsible-dialog";
import { showEditTolerancesSelector } from "../../../../store/overlay/overlay.selectors";
import React, { useCallback, useEffect, useState } from "react";
import { setShowEditTolerances } from "../../../../store/overlay/overlay.reducers";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../../locales/keys";
import { unitsOfMeasureContainerUnitSelector } from "../../../../store/overlay/header/units-of-measure-container/units-of-measure-container.selectors";
import { ButtonProps, CheckboxProps, IconButtonProps } from "@corning-ctcm/silica-react";
import {
    ITolerances,
    areTolerancesEquals,
    isTolerancesValid,
} from "../../../../models/overlay/header/tolerances/tolerances";
import {
    disabledUISelector,
    isLockedSelector,
} from "../../../../store/overlay/header/status-icon/status-icon.selectors";
import {
    currentOverallLengthTypeSelector,
    currentTolerancesSelector,
} from "../../../../store/workspace/build.selectors";
import { setTolerances } from "../../../../store/workspace/build/build.reducers";
import { setStatusState } from "../../../../store/overlay/header/status-icon/status-icon.reducer";
import { useWebSave } from "../status/status.hooks";
import {
    Unit,
    Units,
    convertTolerances,
    convertTolerancesToDisplay,
} from "../../../../models/overlay/header/units-of-measure";
import { ITolerancesField } from "../../../../models/overlay/header/tolerances/tolerances-field";

export const useEditTolerances = () => {
    const { t } = useTranslation();
    const disabledUI = useSelector(disabledUISelector);
    const dispatch = useDispatch();

    const showEditTolerancesDialog = useCallback(() => {
        dispatch(setShowEditTolerances(true));
    }, [dispatch]);

    const buttonProps: Omit<IconButtonProps, "icon"> = {
        id: "tolerances-icon",
        palette: "primary",
        mode: "main",
        disabled: disabledUI,
        title: t(LocalizationKeys.Tolerances),
        placement: "bottom",
        onClick: showEditTolerancesDialog,
    };

    return { buttonProps };
};

export const useEditTolerancesDialog = () => {
    const { t } = useTranslation();
    const { saveBuild } = useWebSave();
    const overallLengthType = useSelector(currentOverallLengthTypeSelector);
    const currentTolerances = useSelector(currentTolerancesSelector);
    const currentUnit = useSelector(unitsOfMeasureContainerUnitSelector);
    const display = useSelector(showEditTolerancesSelector);
    const locked = useSelector(isLockedSelector);
    const dispatch = useDispatch();

    const [displayedTolerances, setDisplayedTolerances] = useState<ITolerances>(currentTolerances);
    const [initialUnit, setInitialUnit] = useState<Unit>(currentUnit);
    const [initialTolerances, setInitialTolerances] = useState<ITolerances>();

    useEffect(() => {
        if (display && !initialTolerances) {
            setInitialTolerances(currentTolerances);
            setDisplayedTolerances(convertTolerancesToDisplay(currentTolerances, Units.UoMInches, currentUnit));
            setInitialUnit(currentUnit);
        }
    }, [display, currentUnit, initialTolerances, currentTolerances]);

    useEffect(() => {
        if (initialUnit !== currentUnit) {
            setDisplayedTolerances(convertTolerancesToDisplay(currentTolerances, Units.UoMInches, currentUnit));
            setInitialUnit(currentUnit);
        }
    }, [currentTolerances, currentUnit, initialUnit]);

    const onTolerancesChange = useCallback(
        (tolerances: ITolerances) => {
            setDisplayedTolerances(tolerances);
            dispatch(setTolerances(convertTolerances(tolerances, currentUnit)));
        },
        [currentUnit, dispatch]
    );

    const onClose = useCallback(() => {
        if (!initialTolerances) return;

        dispatch(setTolerances(initialTolerances));
        dispatch(setShowEditTolerances(false));
        setInitialTolerances(undefined);
    }, [initialTolerances, dispatch]);

    const dialogProps: ICollapsibleDialogProps = {
        id: "edit-tolerances",
        display,
        className: "edit-tolerances-dialog",
        headerProps: {
            title: t(LocalizationKeys.SetTolerances),
            collapsible: false,
            closable: true,
            onClose,
        },
    };

    const cancelProps: ButtonProps = {
        id: "edit-tolerances-cancel-button",
        palette: "primary",
        mode: "main",
        onClick: onClose,
    };

    const onSave = useCallback(async () => {
        await saveBuild();
        dispatch(setStatusState("saved"));
        setInitialTolerances(undefined);
    }, [saveBuild, dispatch]);

    const overallLengthTypeLabel =
        overallLengthType === "connector"
            ? t(LocalizationKeys.OverallLengthTypeConnector)
            : t(LocalizationKeys.OverallLengthTypeFurcation);

    const overallLength: ITolerancesField = {
        id: "overall-length",
        label: t(LocalizationKeys.OverallLengthToleranceLabel, { type: overallLengthTypeLabel.toLowerCase() }),
        tolerance: displayedTolerances.overallLengthTolerance,
        onChange: (overallLengthTolerance) => onTolerancesChange({ ...displayedTolerances, overallLengthTolerance }),
    };

    const legLength: ITolerancesField = {
        id: "leg-length",
        label: t(LocalizationKeys.LegLengthToleranceLabel),
        tolerance: displayedTolerances.legLengthTolerance,
        onChange: (legLengthTolerance) => onTolerancesChange({ ...displayedTolerances, legLengthTolerance }),
    };

    const meshLength: ITolerancesField = {
        id: "mesh-length",
        label: t(LocalizationKeys.MeshLengthToleranceLabel),
        tolerance: displayedTolerances.meshLengthTolerance,
        onChange: (meshLengthTolerance) => onTolerancesChange({ ...displayedTolerances, meshLengthTolerance }),
    };

    const labelDistance: ITolerancesField = {
        id: "label-distance",
        label: t(LocalizationKeys.LabelDistanceToleranceLabel),
        tolerance: displayedTolerances.labelDistanceTolerance,
        onChange: (labelDistanceTolerance) => onTolerancesChange({ ...displayedTolerances, labelDistanceTolerance }),
    };

    const areEquals = initialTolerances
        ? areTolerancesEquals(
              convertTolerances(currentTolerances, currentUnit),
              convertTolerances(initialTolerances, currentUnit)
          )
        : true;

    const saveDisabled = !isTolerancesValid(currentTolerances) || areEquals;
    const saveProps: ButtonProps = {
        id: "edit-tolerances-save-button",
        palette: "primary",
        mode: "main",
        disabled: saveDisabled,
        onClick: onSave,
    };

    const onToggleTolerances = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onTolerancesChange({ ...displayedTolerances, showTolerances: e.target.checked });
        },
        [displayedTolerances, onTolerancesChange]
    );

    const checkboxProps: CheckboxProps = {
        id: "show-tolerances-checkbox",
        palette: "primary",
        placement: "end",
        label: t(LocalizationKeys.ShowTolerances),
        checked: displayedTolerances.showTolerances,
        onChange: onToggleTolerances,
        disabled: locked,
    };

    return {
        dialogProps,
        helperText: t(LocalizationKeys.TolerancesHelperText),
        overallLength,
        legLength,
        meshLength,
        labelDistance,
        cancel: {
            label: t(LocalizationKeys.Cancel),
            props: cancelProps,
        },
        save: {
            label: t(LocalizationKeys.Save),
            props: saveProps,
        },
        checkboxProps,
    };
};

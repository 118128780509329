import { createSelector } from "reselect";
import { getConnectorType, IConnectorType } from "../../../../../store/overlay/wizard/wizard";
import {
    connectorModelToConnectorType,
    CustomFiberMapData,
    IFiberMapData,
    IFiberMapIndex,
} from "./fiber-map";
import { CUSTOM_MAP_KEY, CustomMap, PolarityMap, PolarityMapList } from "./polarity";
import { WorkspaceState } from "../../../../../store/workspace/workspace.reducers";
import i18n from "../../../../../locales/i18n";
import { LocalizationKeys } from "../../../../../locales/keys";

export const polaritySelector = (state: WorkspaceState) => state.polarity;
export const buildsConfigsSelector = (state: WorkspaceState) => state.polarity.buildConfigList ?? [];
export const fiberMapsSelector = (state: WorkspaceState) => state.polarity.fiberMaps ?? [];

export const polarityConfigsSelector = createSelector(
    polaritySelector,
    ({ configs }) => configs ?? []
);

export const filteredPolarityMapsSelector = createSelector(
    polaritySelector,
    ({ selectedConfig }) => selectedConfig && selectedConfig.polarityMaps ? [...selectedConfig.polarityMaps] : []
)

export function generatePolarityMapList(userMaps: CustomFiberMapData[]) {
    const userPolarity: PolarityMap[] = userMaps.map((u) => {
        const polarityMap: PolarityMap = fiberMapDataToPolarityMap(u);
        return polarityMap;
    });

    return [...userPolarity];
}

export function fiberMapDataToPolarityMap(fiberMapData: CustomFiberMapData) {
    const sourceConnectors = getFiberMapDataConnectorTypes(fiberMapData.sourceIndices);
    const destinationConnectors = getFiberMapDataConnectorTypes(fiberMapData.destinationIndices);
    const polarityMap: PolarityMap = {
        userId: fiberMapData.userId,
        description: fiberMapData.name,
        id: fiberMapData.id,
        key: fiberMapData.key ?? CUSTOM_MAP_KEY,
        sessionId: fiberMapData.key.toString(),
        customKey: fiberMapData.name,
        imageUri: undefined,
        sourceConnectors,
        destinationConnectors,
        versionDate: fiberMapData.versionDate,
    };
    return polarityMap;
}

export function getFiberMapDataConnectorTypes(fiberMapIndices: IFiberMapIndex[]) {
    const uniqueConnectorTypes: { [connectorType: string]: IConnectorType[] } = {};
    let i = 0;
    while (i < fiberMapIndices.length) {
        const index = fiberMapIndices[i];
        const connectorType =
            index.connectorType && index.connectorType.length > 0
                ? getConnectorType(index.connectorType)
                : connectorModelToConnectorType(index.connector);
        if (connectorType && connectorType.type) {
            const fiberCount = connectorType.fiberCount === 8 ? 12 : connectorType.fiberCount;
            if (uniqueConnectorTypes[connectorType.type]) {
                uniqueConnectorTypes[connectorType.type].push({ ...connectorType });
            } else if (!uniqueConnectorTypes[connectorType.type]) {
                uniqueConnectorTypes[connectorType.type] = [{ ...connectorType }];
            }

            i += fiberCount;
        } else {
            i++;
        }
    }

    const connectorTypes = Object.values(uniqueConnectorTypes).flat();
    return connectorTypes;
}

export const fiberMapSelectorFactory = (displayKey: number) => {
    return createSelector(fiberMapsSelector, (fiberMaps) => getDomainFiberMap(displayKey, fiberMaps))
}

export const getDomainFiberMap = (displayKey: number, fiberMaps: IFiberMapData[]) => {
    let fiberMap: IFiberMapData = { 
        key: CUSTOM_MAP_KEY,
        name: i18n.t(LocalizationKeys.Custom),
        fiberCount: 0,
        sourceConnectors: [], sourceIndices: [],
        destinationConnectors: [], destinationIndices: [],
    };
    const displayedFiberMap = PolarityMapList.find(m => m.key === displayKey);
    if (displayedFiberMap && displayedFiberMap.demoKey) {
        const domainFiberMap = fiberMaps.find(m => m.name === displayedFiberMap.demoKey);
        if (domainFiberMap) {
            fiberMap = domainFiberMap;
        }
    }
    return fiberMap;
}

export const getDisplayedFiberMap = (domainKey: number, fiberMaps: IFiberMapData[]) => {
    let fiberMap = CustomMap;
    const domainFiberMap = fiberMaps.find(m => m.key === domainKey);
    if (domainFiberMap) {
        const displayedFiberMap = PolarityMapList.find(m => m.demoKey === domainFiberMap.name);
        if (displayedFiberMap) {
            fiberMap = displayedFiberMap;
        }
    }
    return fiberMap;
}
import React from "react";
import { InputField } from "../../../../../models/ui/input";
import { TextField } from "@corning-ctcm/silica-react";

export const FooterFields = (props: { fields: InputField[] }) => {
    const { fields } = props;
    const [siteLocation, drawnBy, revisionNumber, approvalDate, inServiceDate, notes] = fields;
    return (
        <div className="field-container">
            <TextField
                palette="primary"
                className="field"
                label={siteLocation.label}
                value={siteLocation.value}
                helperText={" "}
                maxLength={siteLocation.maxLength}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => siteLocation.onChange && siteLocation.onChange(e)}
                disabled={siteLocation.disabled}
                />
            <div className="horizontal-field-container">
                <div className="split-fields">
                    <TextField
                        palette="primary"
                        className="field"
                        label={drawnBy.label}
                        helperText={" "}
                        value={drawnBy.value}
                        maxLength={drawnBy.maxLength}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => drawnBy.onChange && drawnBy.onChange(e)}
                        disabled={drawnBy.disabled}
                        />
                    <TextField
                        palette="primary"
                        className="field"
                        label={revisionNumber.label}
                        value={revisionNumber.value}
                        helperText={" "}
                        maxLength={revisionNumber.maxLength}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            revisionNumber.onChange && revisionNumber.onChange(e)
                        }
                        disabled={revisionNumber.disabled}
                        />
                </div>
            </div>
            <div className="horizontal-field-container">
                <div className="split-fields">
                    <TextField
                        palette="primary"
                        className="field"
                        label={approvalDate.label}
                        value={approvalDate.value}
                        helperText={" "}
                        type="date"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            approvalDate.onChange && approvalDate.onChange(e)
                        }
                        disabled={approvalDate.disabled}
                        fullWidth
                    />
                    <TextField
                        palette="primary"
                        className="field"
                        label={inServiceDate.label}
                        value={inServiceDate.value}
                        helperText={" "}
                        type="date"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            inServiceDate.onChange && inServiceDate.onChange(e)
                        }
                        disabled={inServiceDate.disabled}
                        fullWidth
                        />
                </div>
            </div>
            <TextField
                palette="primary"
                className="field"
                label={notes.label}
                helperText={" "}
                maxLength={notes.maxLength}
                minRows={3}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => notes.onChange && notes.onChange(e)}
                value={notes.value}
                multiline
                disabled={notes.disabled}
                />
        </div>
    );
};

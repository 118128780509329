import { PUoMImperial, PrimaryUnit, Unit, UoMInches } from "../../../../models/overlay/header/units-of-measure";

export interface IUnitsOfMeasureContainerState {
    display: boolean;
    primaryUnit: PrimaryUnit;
    unit: Unit;
}

export const initialState: IUnitsOfMeasureContainerState = {
    display: false,
    primaryUnit: PUoMImperial,
    unit: UoMInches,
};

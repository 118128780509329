import { Boundaries } from "../store/workspace/boundaries/boundaries";
import { SecuredService } from "./abstract-secured-v2.service";

export class BoundariesService extends SecuredService {
    private readonly baseUrl = "/api/boundaries";

    public async getAllBoundaries(): Promise<Boundaries[] | undefined> {
        return await this.get(this.baseUrl);
    }

    public async getDefaultBoundaries(): Promise<Boundaries[] | undefined> {
        return await this.get(`${this.baseUrl}/default`);
    }

    public async getBoundariesById(id: number): Promise<Boundaries | undefined> {
        return await this.get(`${this.baseUrl}/${id}`);
    }

    public async addOrUpdateBoundaries(boundaries: Boundaries): Promise<Boundaries | undefined> {
        return await this.put(`${this.baseUrl}`, boundaries);
    }

    public async deleteBoundaries(id: number): Promise<void> {
        return this.delete<void>(`${this.baseUrl}/${id}`);
    }
}

import { DialogHeader } from "../../ui/dialog/dialog-header/dialog-header";
import { useAboutThisApplication } from "./about-this-application.hooks";
import { ThirdPartyCopyrightDialog } from "./third-party-copyright-dialog/third-party-copyright-dialog";
import { Divider } from "@mui/material";
import { TextButton, Typography, Dialog } from "@corning-ctcm/silica-react";
import CorningLogo from "../../../resources/imgs/Corning_301Blue_BoxLockup_WhiteLogo_Solid_1in.png";

import "../../../css/overlay/about-this-application/about-this-application.scss";

export const AboutThisApplication = () => {
    const { showAboutApplication, dialogHeaderProps, dialogBodyProps, closeDialog } = useAboutThisApplication();

    return (
        <>
            {showAboutApplication ? (
                <Dialog
                    id="about-this-application"
                    open={showAboutApplication}
                    onClose={closeDialog}
                    className="about-this-application"
                    preventOutsideDismiss={true}
                >
                    <DialogHeader {...dialogHeaderProps} />
                    <div className="content-container">
                        <div className="left-container">
                            <img width="100px" alt="Corning Logo" height="100px" src={CorningLogo} />
                        </div>
                        <div className="right-container">
                            <Typography variant="h6">
                                {dialogBodyProps.title ?? ""}
                            </Typography>
                            <Typography variant="caption">
                                {dialogBodyProps.lastBuild ?? ""}
                            </Typography>
                            <TextButton
                                id="third-party-button"
                                className="right-container-button"
                                palette="primary"
                                onClick={dialogBodyProps.onThirdPartyButtonClick}
                            >
                                {dialogBodyProps.thirdPartyButtonTitle}
                            </TextButton>
                        </div>
                    </div>
                    <Divider />
                    <div className="corning-copyright-container">
                        <span className="copyright-font">{dialogBodyProps.corningCopyright}</span>
                    </div>
                </Dialog>
            ) : null}
            <ThirdPartyCopyrightDialog />
        </>
    );
};
